/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { StepArrow, StepFlag } from '../../../_shared/style';
import { ExplorationStep } from '../constants';
import './exploration-steps.css';

export const ExplorationSteps = ({ activeStep }: { activeStep?: string }) => {
  return (
    <div id="exploration-steps">
      <ul id="steps">
        <li
          className={`step ${
            activeStep == ExplorationStep.START ? 'active' : ''
          }`}
        >
          <span className="step-flag">
            <StepFlag />
          </span>
          Start
          <span className="step-arrow">
            <StepArrow color={'#1A363F'} opacity={'0.45'} />
          </span>
        </li>
        <li
          className={`step ${
            activeStep == ExplorationStep.GOAL_SECTION ? 'active' : ''
          }`}
        >
          Goal selection
          <span className="step-arrow">
            <StepArrow color={'#1A363F'} opacity={'0.45'} />
          </span>
        </li>
        <li
          className={`step ${
            activeStep == ExplorationStep.NON_NEGOTIABLE_CONTRAIN
              ? 'active'
              : ''
          }`}
        >
          Non-negotiable constrains
          <span className="step-arrow">
            <StepArrow color={'#1A363F'} opacity={'0.45'} />
          </span>
        </li>
        <li
          className={`step ${
            activeStep == ExplorationStep.FORMULATION_DISCOVERY ? 'active' : ''
          }`}
        >
          Formulation discovery
        </li>
      </ul>
    </div>
  );
};
