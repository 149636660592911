/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { boxStyle, titleStyle } from './content-container.styles';

export const ContentContainer = ({
  title,
  children,
}: {
  title: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div css={boxStyle}>
      <div css={titleStyle}>{title}</div>
      <div
        css={css`
          font-size: 13px;
          margin: 8px;
        `}
      >
        {children}
      </div>
    </div>
  );
};
