/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { Modal, Form, Input, Spin, Button, Alert, Popconfirm } from 'antd';
import { centeredHorizontallyFlexStyle } from '../../../_shared/style';
import { useSession } from '../../../_shared/context';
import {
  useduplicateIterationMutation,
  useupdateOneIterationMutation,
} from '../../../../../__generated__/globalTypes';
import { useNavigate } from 'react-router-dom';

export const EditIterationModal = (props: { close: () => void }) => {
  const { close } = props;
  const {
    currentProject: project,
    useFetchProject,
    getSelectedIteration,
    selectedIterationId,
  } = useSession();

  const navigate = useNavigate();
  const [duplicateIterationMutation] = useduplicateIterationMutation();

  const handleWorkspaceDuplicate = async (iterationId: string) => {
    const duplicatedWorkspace = await duplicateIterationMutation({
      variables: {
        iterationId,
      },
    });
    const response = duplicatedWorkspace?.data?.duplicateIteration;
    await fetchProjectById({
      variables: {
        projectId: `${project?.id}`,
      },
    });
    close();
    navigate(`/project/${response?.projectId}/iteration/${response?.id}`);
  };

  const [
    fetchProjectById,
    { loading: projectFetchLoading },
  ] = useFetchProject();
  const [updateIteration, { loading, error }] = useupdateOneIterationMutation();

  const onFinish = async (values: { name: string; description: string }) => {
    try {
      await updateIteration({
        variables: {
          data: {
            id: selectedIterationId!,
            projectId: project!.id,
            name: values.name,
            description: values.description,
          },
        },
      });
      await fetchProjectById({
        variables: {
          projectId: `${project?.id}`,
        },
      });
      close();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  let selectedIteration = getSelectedIteration();
  const selectedFormValues = {
    ...selectedIteration,
    workspaceId: project?.key
      ? `${project?.key}-${selectedIteration?.key}`
      : '',
  };

  const form = (
    <Form
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      initialValues={selectedFormValues}
    >
      <Form.Item label="ID" name="workspaceId">
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Description (optional)" name="description">
        <Input.TextArea placeholder="Add notes about this workspace" />
      </Form.Item>
      <Form.Item>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            gap: 10px;
          `}
        >
          {selectedIterationId && (
            <Popconfirm
              title="Are you sure you want to duplicate the workspace?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => handleWorkspaceDuplicate(selectedIterationId)}
            >
              <Button>Duplicate Workspace</Button>
            </Popconfirm>
          )}
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  let view = form;

  if (loading || projectFetchLoading) {
    view = <Spin css={centeredHorizontallyFlexStyle} />;
  }

  return (
    <Modal closable onCancel={close} title="Edit Workspace" open footer={null}>
      {error && (
        <Alert
          message="Error"
          description="Failed to update workspace"
          type="error"
          showIcon
          closable
        />
      )}
      {view}
    </Modal>
  );
};
