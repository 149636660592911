/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Objective } from '../../../../../../__generated__/globalTypes';
import { ObjectiveEditPanel } from '../../shared/goals/objective-edit-panel.component'; //'./objective-edit-panel.component';
import { PriorityLabel as BasePriorityLabel } from './types';
import { PriorityLabel as AdaptiveLearningPriorityLabel } from '../../adaptive-learning/types';

export type ObjectivesContainterProps = {
  priorityLabel: BasePriorityLabel | AdaptiveLearningPriorityLabel;
  objectives: Objective[];
  updateObjective: (o: Objective) => void;
  changePriority: (e: { action: string; targetVariable: string }) => void;
  setObjectivesAreInEditMode: (o: boolean) => void;
};

export const ObjectivesContainer = ({
  priorityLabel,
  objectives,
  updateObjective,
  changePriority,
  setObjectivesAreInEditMode,
}: ObjectivesContainterProps) => {
  let contents = <div>Use the arrows to move outcomes to this section.</div>;
  if (objectives.length) {
    contents = (
      <div>
        {...objectives.map(obj => (
          <ObjectiveEditPanel
            objective={obj}
            updateObjective={updateObjective}
            changePriority={changePriority}
            setObjectivesAreInEditMode={setObjectivesAreInEditMode}
            key={`${obj.targetVariable}EditPanel`}
          />
        ))}
      </div>
    );
  }

  return (
    <div
      css={css`
        padding: 12px 12px 25px 12px;
      `}
      key={priorityLabel}
    >
      <div
        css={css`
          font-weight: 700;
          padding-bottom: 12px;
        `}
      >
        {priorityLabel}
      </div>
      {contents}
    </div>
  );
};
