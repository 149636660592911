/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

import { Button, Dropdown, Menu, MenuProps, Tooltip } from 'antd';
import { ExperimentTwoTone } from '@ant-design/icons';

import { Colors } from '../../style';

export interface ProductVersionButtonOptions {
  label: string;
  onClick: () => void;
  disabled: boolean;
  toolTipMessage?: string;
}

export type AddProductVersionButtonProps = {
  toolTipMessage?: string;
  onClick?: () => void;
  options?: ProductVersionButtonOptions[];
};

export const AddProductVersionButton = ({
  onClick,
  options,
}: AddProductVersionButtonProps) => {
  const btn = (
    <Button
      {...(options ? {} : { onClick })}
      css={css`
        color: ${Colors['#256EFF']};
        height: 32px;
        width: 44px;
        margin: 0;
        display: flex;
        background: #fff;
        border-radius: 6px;
        padding: 2px 4px;
        cursor: pointer;
        font-size: 16px;

        .plus {
          position: relative;
          top: -1px;
          left: 3px;
        }

        svg {
          position: relative;
          top: 5px;
          left: -4px;
        }
      `}
    >
      <span className="plus">+</span>
      <ExperimentTwoTone />
    </Button>
  );

  if (options) {
    const menuItems: MenuProps['items'] = [];
    options.forEach((option, index) => {
      menuItems.push({
        style: { padding: 0 },
        key: option.label,
        label: (
          <Tooltip title={option?.toolTipMessage}>
            <Button
              disabled={option.disabled}
              css={css`
                border: none;
                background: transparent !important;
                padding: 0 12px;
              `}
              onClick={option.onClick}
            >
              {option.label}
            </Button>
          </Tooltip>
        ),
      });
    });

    return (
      <Dropdown
        placement="bottomRight"
        arrow
        menu={{ items: menuItems, style: { padding: 0 } }}
      >
        {btn}
      </Dropdown>
    );
  }

  return btn;
};
