/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import noop from 'lodash/noop';
import { Row, Col, Radio, RadioChangeEvent } from 'antd';
import { isModelMLAPI } from '../../../../../../../iso/model-ids';
import {
  OptimizationOptimizationType,
  ProjectFeature,
} from '../../../../../../../../__generated__/globalTypes';
import {
  EditableInput,
  InputType,
} from '../../../../../../_shared/components/input/editable-input.component';
import { getTargetVariables } from '../../../../../../_shared/utils/util';
import { useSession } from '../../../../../../_shared/context';

export interface ConstraintProps {
  setOutcomeGoal: (updateConstraint: OptimizationOptimizationType) => void;
  costConstraints: { lowerBounds?: number; upperBounds?: number };
  updateCostConstraintLowerBound: (value: number) => void;
  updateCostConstraintUpperBound: (value: number) => void;
  targetValue?: string;
  setTargetValue: (value?: string) => void;
  outcomeGoal: OptimizationOptimizationType;
  optimizationCalled: boolean;
}

// Once the current optimization endpoint is replaced we can phase this file out
export const LegacyOptimizationOutcomeGoals = ({
  outcomeGoal,
  setOutcomeGoal,
  optimizationCalled,
  targetValue,
  setTargetValue,
}: ConstraintProps) => {
  const { currentProject } = useSession();
  const model = currentProject?.activeModel;
  const modelIsMLAPI = model && isModelMLAPI(model?.id);
  const projectHasOptimizeOnTargetValue = currentProject?.features.some(
    f => f.feature === ProjectFeature.OPTIMIZE_ON_TARGET_VALUE
  );

  const optimizationTypeLabel = (
    optType: OptimizationOptimizationType
  ): string => {
    switch (optType) {
      case OptimizationOptimizationType.MINIMIZE:
        return 'Minimize';
      case OptimizationOptimizationType.MAXIMIZE:
        return 'Maximize';
      case OptimizationOptimizationType.TARGET_VALUE:
        return 'Target';
      default:
        return '';
    }
  };

  let goalsContainer: JSX.Element;

  if (modelIsMLAPI) {
    const onChange = (e: RadioChangeEvent) => {
      setOutcomeGoal(e.target.value as OptimizationOptimizationType);
    };

    const goalsRadioOptions = (
      <Radio.Group
        style={{ width: '100%' }}
        onChange={onChange}
        value={outcomeGoal}
        disabled={optimizationCalled}
      >
        <Row gutter={[16, 16]}>
          {Object.keys(OptimizationOptimizationType)
            .filter(
              _type =>
                // Only show TARGET_VALUE if feature is enabled
                (_type !== OptimizationOptimizationType.TARGET_VALUE ||
                  projectHasOptimizeOnTargetValue) &&
                _type !== OptimizationOptimizationType.IN_RANGE
            )
            .map(_type => (
              <Col span={12} key={_type}>
                <Radio value={_type}>
                  {optimizationTypeLabel(_type as OptimizationOptimizationType)}
                </Radio>
              </Col>
            ))}
        </Row>
      </Radio.Group>
    );

    let goalsMetadata;
    if (
      projectHasOptimizeOnTargetValue &&
      outcomeGoal === OptimizationOptimizationType.TARGET_VALUE
    ) {
      goalsMetadata = (
        <div>
          <div
            css={css`
              color: #373737;
              opacity: 0.6;
            `}
          >
            Enter your target value below
          </div>
          <EditableInput
            type={InputType.NUMERIC}
            onChange={setTargetValue}
            setValue={noop}
            value={targetValue}
            isDisabled={optimizationCalled}
          />
        </div>
      );
    }

    goalsContainer = (
      <div>
        {goalsRadioOptions}
        {goalsMetadata && (
          <div
            css={css`
              background-color: #f2f2f2;
              padding: 12px;
              margin-top: 10px;
            `}
          >
            {goalsMetadata}
          </div>
        )}
      </div>
    );
  } else {
    goalsContainer = <div className="stepContentGrey">Maximize the value</div>;
  }

  const targetVariables = model && getTargetVariables(model, currentProject);

  return (
    <div className="optStepBox">
      <div className="steBoxTitle">Outcome limits and targets</div>
      <div
        css={css`
          padding: 12px 12px 25px 12px;
        `}
      >
        <div
          css={css`
            font-weight: 700;
            padding-bottom: 12px;
          `}
        >
          {targetVariables && targetVariables.join(', ')}
        </div>

        {goalsContainer}
      </div>
    </div>
  );
};
