/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { DisplaySlider } from '../../shared';
import { EllipsisMiddle } from '../../../../../_shared/utils/component';

export const FormulationChangeSlider = ({
  ingredientName,
  benchmarkValue,
  difference,
  textColor,
}: {
  ingredientName: string;
  benchmarkValue?: string;
  difference: string;
  textColor?: string;
}) => {
  const color = textColor ?? '#fff';

  return (
    <div
      css={css`
        border-bottom: 1px solid rgba(204, 204, 204, 0.2);
        color: #515151;
        margin: 5px 0px 20px 0px;
        width: 250px;
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 5px 0px 0px 0px;
          color: ${color};
        `}
      >
        <div>{ingredientName}</div>

        <div
          css={css`
            font-size: 11px;
            margin: 5px 0px 0px 5px;
          `}
        >
          ({difference})%
        </div>
      </div>

      <DisplaySlider benchmarkVal={benchmarkValue} difference={difference} />
    </div>
  );
};

export const FormulationChangeSliderWithValue = ({
  ingredientName,
  benchmarkValue,
  difference,
  textColor,
  ingredientValue,
}: {
  ingredientName: string;
  benchmarkValue?: string;
  difference: string;
  textColor?: string;
  ingredientValue: string;
}) => {
  const color = textColor ?? '#fff';

  return (
    <div
      css={css`
        border-bottom: 1px solid rgba(204, 204, 204, 0.2);
        color: #515151;
        margin: 5px 0px 20px 0px;
        width: 300px;
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 5px 0px 0px 0px;
          color: ${color};
        `}
      >
        <EllipsisMiddle
          style={{
            marginRight: '3px',
            width: '250px',
          }}
          suffixCount={20}
        >
          {`${ingredientName}: `}
        </EllipsisMiddle>
        <div
          css={css`
            display: flex;
            width: 10%;
          `}
        >
          <div
            css={css`
              font-weight: 700;
            `}
          >
            {ingredientValue}
          </div>
          <div
            css={css`
              font-size: 11px;
              margin: 4px 0px 0px 4px;
            `}
          >
            {' '}
            ({difference})%
          </div>
        </div>
      </div>
      <DisplaySlider benchmarkVal={benchmarkValue} difference={difference} />
    </div>
  );
};
