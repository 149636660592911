import { css } from '@emotion/react';
import styled from '@emotion/styled';

// TODO: Remove once this is put in as a component
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export const borderedStyle = css`
  vertical-align: top;
  border: 0.5px solid #f3f3f3;
  padding: 5px;
`;

export const accuracyBorderedStyle = css`
  border: 0.5px solid #f3f3f3;
  padding: 5px;
  opacity: 0.6;
  color: #000000;
`;

export const outcomesBorderedStyle = css`
  border: 0.5px solid #f3f3f3;
  padding: 5px;
  font-size: 13px;
  color: #000000;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th > div {
    font-weight: normal;
    display: flex;
    align-items: center;
  }
`;
