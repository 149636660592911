/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Tooltip, TooltipProps } from 'recharts';
import {
  ValueType,
  NameType,
} from 'recharts/types/component/DefaultTooltipContent';

export const ModalToolTip = ({
  active,
  payload: info,
  label,
}: TooltipProps<ValueType, NameType>) => {
  return (
    <div
      css={css`
        background-color: white;
        border: #cccccc 1px solid;
        padding: 10px 8px 13px 10px;
      `}
    >
      <div>{label}</div>

      {info?.map((inf, idx) => {
        let view = <div />;
        const padTop = idx === 0 ? '6px' : '3px';

        const { name, payload, color } = inf;
        const { outcomes } = payload;

        if (Array.isArray(outcomes)) {
          let outcomesView = <div />;
          const o = outcomes[idx];
          const { value } = o;

          if (active && payload) {
            outcomesView = (
              <div
                css={css`
                  color: ${color};
                  padding-top: ${padTop};
                  padding-bottom: 3px;
                `}
              >
                {name} : {value}
              </div>
            );
          }

          view = <div key={name}>{outcomesView}</div>;
        }

        return view;
      })}
    </div>
  );
};
