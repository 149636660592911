import React, { PureComponent, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { useNavigate } from 'react-router-dom';
import { CustomRecordType } from './experiment-list-layout';
import capitalize from 'lodash/capitalize';
import { Button, Divider } from 'antd';
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { useSession } from '../../_shared/context';
import { useDesign } from '../../_shared/context/design-context';
import { downloadFromS3 } from '@root/_shared/utils/file';
export const ExperimentBarChart = ({
  data,
  columnData,
  keyName,
  moveChart,
}: {
  data: Record<string, any>[];
  columnData: CustomRecordType | undefined;
  keyName: string;
  moveChart: (keyName: string, direction: 'up' | 'down') => void;
}) => {
  const { currentProject, selectedIterationId } = useSession();
  const { latestDesign } = useDesign();
  const navigate = useNavigate();

  const [showGraph, setShowGraph] = useState(true);
  useEffect(() => {
    setShowGraph(columnData?.visible);
  }, [columnData]);
  const [focusBar, setFocusBar] = useState(null);

  const handleClick = (experimentName: string) => {
    if (experimentName) {
      navigate(
        `/project/${
          currentProject?.id
        }/iteration/${selectedIterationId}/design/${
          latestDesign?.id
        }/experiment/${encodeURIComponent(String(experimentName))}`
      );
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <div id={`${keyName}-chart`} style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            type="text"
            size="small"
            icon={<UpOutlined style={{ color: 'grey' }} />}
            onClick={() => moveChart(keyName, 'up')}
          />
          <Button
            type="text"
            size="small"
            icon={<DownOutlined style={{ color: 'grey' }} />}
            onClick={() => moveChart(keyName, 'down')}
          />
        </div>
        <span
          style={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '22px',

            marginLeft: 5,
            marginTop: 15,
          }}
        >
          {capitalize(keyName)}
        </span>{' '}
      </div>
      {showGraph && data.length > 0 && (
        <ResponsiveContainer
          minHeight={400}
          width={data.length * 40 > 500 ? data.length * 40 : 500}
        >
          <BarChart
            {...{
              overflow: 'visible', // Workaround mentioned here: https://github.com/recharts/recharts/issues/1618
            }}
            data={data}
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 20,
            //   bottom: 5,
            // }}
            barSize={20}
            barCategoryGap={5}
            barGap={5}
            onClick={e => e?.activeLabel && handleClick(e?.activeLabel)}
            style={{ cursor: 'pointer' }}
            onMouseMove={state => {
              if (state.isTooltipActive) {
                setFocusBar(state?.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
          >
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#EF4136" />
                <stop offset="100%" stopColor="#FFC8A9" />
              </linearGradient>
              <linearGradient
                id="predictedColorGradient"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor="rgba(239, 65, 54, 0.6)" />{' '}
                {/* #EF4136 with 60% opacity */}
                <stop offset="100%" stopColor="rgba(255, 200, 169, 0.6)" />{' '}
                {/* #FFC8A9 with 60% opacity */}
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              angle={-90}
              textAnchor="end"
              dataKey="name"
              padding={'no-gap'}
              height={200}
              interval={0}
              tick={{ fontWeight: 600 }}
              /*@ts-ignore*/
              onClick={e => e?.value && handleClick(e?.value)}
            />
            <YAxis
              orientation="right"
              unit={columnData?.unit}
              style={{ overflow: 'visible' }}
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              formatter={(value: string, name, props) =>
                `${value}${
                  props.payload.column.unit === '%'
                    ? props.payload.column.unit
                    : ' ' + props.payload.column.unit
                }`
              }
            />
            <Legend />
            <Bar
              dataKey={keyName}
              legendType="none"
              //fill="url(#colorUv)"
            >
              {data.map((entry, index) => (
                <Cell
                  fill={
                    entry.originalData.isGenerated || focusBar === index
                      ? 'url(#colorGradient)'
                      : 'url(#predictedColorGradient)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
