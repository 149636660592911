/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useIngredients } from '../../../../_shared/hooks';
import { InputNumber, Image } from 'antd';
import { useMemo, useState } from 'react';

import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { IngredientCategorySearch } from '../../../../_shared/components/input/ingredient-category-filter.component';
import { ConstraintProp } from './design-constraints.component';
import { emptyConstraint } from '../design-utils';
import {
  ConstraintInputType,
  ConstraintType,
  VariableType,
} from '../../../../../../__generated__/globalTypes';
import {
  addValueBody,
  addValueStyle,
  constraintIngredientSearch,
} from './design-constraints.styles';
import { DesignConstraintInfo } from './design-constraint-info.component';

const emptyCountConstraint: ConstraintInputType = {
  ...emptyConstraint,
  constraintType: ConstraintType.COUNT,
};

export const CountConstraint = ({
  updateConstraint,
  currentConstraint,
}: ConstraintProp) => {
  const { activeNumericIngredients, categories } = useIngredients();
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const templateConstraint = useMemo(
    () => (currentConstraint ? currentConstraint : emptyCountConstraint),
    [currentConstraint]
  );

  const searchableIngredients = useMemo(() => {
    return activeNumericIngredients
      .filter(i => {
        if (filteredCategories.length > 0) {
          return filteredCategories.includes(i.category.name);
        } else {
          return i;
        }
      })
      .filter(
        i =>
          !templateConstraint?.variables?.find(i2 => i.ingredient.name === i2)
      )
      .sort((a, b) => a.ingredient.name.localeCompare(b.ingredient.name));
  }, [currentConstraint, filteredCategories]);

  const updateLowerBounds = (val: number) => {
    const { lowerBounds, ...other } = templateConstraint;
    const newLowerBounds = val ?? 0;
    updateConstraint({ lowerBounds: newLowerBounds, ...other });
  };

  const updateUppperBounds = (val: number) => {
    const { upperBounds, ...other } = templateConstraint;
    const newUpperBounds = val ?? 0;
    updateConstraint({ upperBounds: newUpperBounds, ...other });
  };

  const addIngredient = (i: string) => {
    const { variables, ...other } = templateConstraint;
    updateConstraint({ variables: [...variables!, i], ...other });
  };

  const removeIngredient = (i: string) => {
    const { variables, ...other } = templateConstraint;
    updateConstraint({
      variables: variables?.filter(val => val !== i),
      ...other,
    });
  };

  const addValues = (
    <div css={addValueStyle}>
      <h3>Ingredient category limits</h3>
      <div css={addValueBody}>
        <div>
          <p>Minimum count</p>
          <InputNumber
            precision={0}
            min={0}
            max={activeNumericIngredients.length}
            value={templateConstraint.lowerBounds}
            onChange={updateLowerBounds}
          />
        </div>
        <div>
          <p>Maximum count</p>
          <InputNumber
            precision={0}
            min={
              templateConstraint.lowerBounds
                ? templateConstraint.lowerBounds
                : 0
            }
            max={activeNumericIngredients.length}
            value={templateConstraint.upperBounds}
            onChange={updateUppperBounds}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <DesignConstraintInfo
        title="What is a Category constraint?"
        description="Specify a minimum and maximum count for how many ingredients from a category will be included in recommended formulations."
        type={ConstraintType.COUNT}
      />
      <div css={constraintIngredientSearch}>
        <h3>
          Add Inputs{' '}
          {categories.length > 1 && (
            <IngredientCategorySearch
              onChange={selectedCategories =>
                setFilteredCategories(selectedCategories)
              }
              categories={categories}
            />
          )}
        </h3>
        <IngredientSearch
          onSelect={addIngredient}
          onDeselect={removeIngredient}
          ingredients={searchableIngredients}
          defaultValue={templateConstraint?.variables?.map(c => ({
            label: c,
            value: c,
          }))}
          mode="multiple"
          additionalCss={css`
            .ant-select-selection-item {
              background: #e6f2ff;
              color: #017aff;
              margin: 8px;
            }
          `}
        />
      </div>
      {templateConstraint.variables?.length !== 0 && addValues}
    </div>
  );
};
