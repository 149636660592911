/** @jsxImportSource @emotion/react */
import {
  FormulationItem,
  FormulationItemMetricType,
  FormulationItemType,
  Outcome,
  Simulation,
  VariableType,
} from '../../../../__generated__/globalTypes';
import { Button, Slider, Switch, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {
  confidenceIntervalsStringToArray,
  convertReliabilityPercentage,
  parseNumber,
  safeStrToNum,
} from '../../_shared/utils/util';
import { round } from 'lodash';
import { css } from '@emotion/react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useFormulations } from '../../_shared/context/formulations-context';
import { ConfidenceIntervalText } from './confidence-interval-text';
const { Text } = Typography;

type FormattedResults = {
  name: string;
  value: string;
  reliabilityPercentage: number;
  benchmarkFormulationItem?: FormulationItem;
  outcomeMetadata?: Outcome | null;
  confidenceInterval: number[];
}[];

export const SimulationResultDisplay = ({
  simulation,
}: {
  simulation?: Simulation;
}) => {
  const { projectBenchmarkFormulation } = useFormulations();
  const [formattedResults, setFormattedResults] = useState<FormattedResults>(
    []
  );
  const [showBenchmark, setShowBenchmark] = useState(
    Boolean(projectBenchmarkFormulation)
  );
  const [showAllResults, setShowAllResults] = useState(false);

  const firstFormulation = simulation?.formulations[0];

  useEffect(() => {
    const formattedResults: FormattedResults = [];

    if (simulation) {
      for (const formulation of simulation?.formulations) {
        for (const formulationItem of formulation.items) {
          if (formulationItem.type === FormulationItemType.TARGET_PREDICTED) {
            const reliabilityMetric = formulationItem.metrics.find(
              metric => metric.type === FormulationItemMetricType.RELIABILITY
            );

            const confidenceInterval = formulationItem.metrics.find(
              metric =>
                metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
            );

            const reliabilityPercentage = convertReliabilityPercentage(
              reliabilityMetric?.value
            );

            const benchmarkFormulationItem = projectBenchmarkFormulation?.items.find(
              item => item.variable.name === formulationItem.variable.name
            );

            formattedResults.push({
              name: formulationItem.variable.name,
              value: formulationItem.value,
              reliabilityPercentage: reliabilityPercentage,
              benchmarkFormulationItem,
              outcomeMetadata: formulationItem.variable.outcomeMetadata,
              confidenceInterval: confidenceIntervalsStringToArray(
                confidenceInterval?.value
              ),
            });
          }
        }
      }
    }

    setFormattedResults(formattedResults);
  }, [simulation]);

  const shouldDisplayTruncatedResults =
    formattedResults.length > 4 && !showAllResults;

  const formattedResultsToDisplay = shouldDisplayTruncatedResults
    ? formattedResults.slice(0, 4)
    : formattedResults;

  return (
    <div
      style={{
        minWidth: 400,
        border: '1px solid #cfcfcf',
        padding: 20,
        borderRadius: 10,
        fontFamily: 'Inter',
      }}
    >
      <div
        css={css`
          border-bottom: 1px solid var(--Neutrals-50, #e9ebec);
          padding-bottom: 20px;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          `}
        >
          Simulation #{firstFormulation?.key}
        </div>
        {projectBenchmarkFormulation !== undefined && (
          <div style={{ fontSize: 12, display: 'flex', gap: 6 }}>
            <div>Show benchmark</div>
            <Switch
              size={'small'}
              onChange={checked => setShowBenchmark(checked)}
              checked={showBenchmark}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          color: `var(--Neutrals-Text, var(--Colors-Neutral-Text, #161F26))`,
        }}
      >
        {formattedResultsToDisplay.length === 0 && (
          <Text>No results returned.</Text>
        )}
        {formattedResultsToDisplay.map(result => (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Text>{result.name}</Text>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>
                  <span style={{ fontWeight: 500 }}>{result.value}</span>{' '}
                  <ConfidenceIntervalText
                    confidenceInterval={result.confidenceInterval}
                    reliabilityPercentage={result.reliabilityPercentage}
                  />{' '}
                  {showBenchmark && (
                    <span style={{ opacity: '.6' }}>
                      {' '}
                      vs. {result.benchmarkFormulationItem?.value}
                    </span>
                  )}
                </Text>
              </div>
            </div>
            {showBenchmark &&
              result.benchmarkFormulationItem &&
              result.outcomeMetadata?.type === VariableType.NUMERIC && (
                <Slider
                  styles={{
                    track: {
                      background: '#FF4D4F',
                    },
                    tracks: {
                      background: '#FF4D4F',
                    },
                  }}
                  style={{ width: '100%', margin: '0' }}
                  onChange={() => null}
                  min={Number(result.outcomeMetadata.lower) ?? 0}
                  max={Number(result.outcomeMetadata.upper) ?? 100}
                  range
                  marks={{
                    ...(result.confidenceInterval.length > 0 && {
                      [result.confidenceInterval[0]]: {
                        label: (
                          <div
                            css={css`
                              position: relative;
                              top: -14px;
                              width: 100%;
                              height: 100%;
                              background: #fff;
                              padding: 2px 0px;
                            `}
                          >
                            <div
                              css={css`
                                height: 12px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                              `}
                            >
                              <div
                                css={css`
                                  width: 14px;
                                  height: 4px;
                                  border-right: 2px solid rgb(255, 77, 79);
                                  background: rgb(255, 77, 79);
                                `}
                              />
                            </div>
                          </div>
                        ),
                      },
                    }),
                    [result.benchmarkFormulationItem.value]: {
                      label: (
                        <div
                          css={css`
                            position: relative;
                            top: -14px;
                            width: 100%;
                            height: 100%;
                            background: #fff;
                            padding: 2px 4px;
                          `}
                        >
                          <div
                            css={css`
                              width: 6px;
                              height: 12px;
                              border-radius: 10px;
                              border: 2px solid var(--Neutrals-0, #fff);
                              background: var(--Neutrals-200, #a7adb2);
                            `}
                          />
                        </div>
                      ),
                    },
                  }}
                  value={[
                    Number(result.value),
                    ...result.confidenceInterval,
                    Number(result.benchmarkFormulationItem.value),
                  ]}
                />
              )}
          </>
        ))}
        {formattedResultsToDisplay.length > 0 && (
          <Button
            style={{
              paddingLeft: 0,
              alignSelf: 'flex-start',
            }}
            type="link"
            icon={showAllResults ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            onClick={() => setShowAllResults(!showAllResults)}
          >
            {showAllResults ? 'Show Less' : 'Expand All'}
          </Button>
        )}
      </div>
    </div>
  );
};
