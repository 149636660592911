/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Menu, Dropdown, Input, Divider } from 'antd';
import {
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import {
  KnowledgeDiscoveryIcon,
  SearchIcon,
  CenterIcon,
} from '../../../_shared/style/svg-assets';
import { useGraph, useSession } from '../../../_shared/context';
import { FullscreenButton } from './fullscreen.component';
import { TopDriversGraph } from './top-drivers/top-drivers-graph.component';
import { TopDriversList } from './top-drivers/top-drivers-list.component';
import { IngredientCategories } from './ingredient-categories.component';
import { DownloadFile } from '../../../_shared/components/button/download-file';
import { modalStyle } from './hud.styles';
import { NegativeDriverLabel, PositiveDriverLabel } from './driver-labels';
import { formatFileNameWithKey } from '../../../_shared/utils/util';
import { ProjectFeatureInsightExplorerVersion } from '../../../../../__generated__/globalTypes';

export type HudProps = {
  onClose?: () => void;
  modal?: boolean;
};

export const Hud = (props: HudProps) => {
  const { modal, onClose } = props;
  const { graph, setFullscreen } = useGraph();
  const {
    currentProject,
    insightExplorerVersion,
    getSelectedIteration,
  } = useSession();
  const addArrayToString = (array: any[]) => array.join(',') + '\n';

  const insightExplorerIsV2 =
    insightExplorerVersion == ProjectFeatureInsightExplorerVersion.V2;

  let nodeData = addArrayToString(['Node', 'Weight']);
  if (graph.nodes) {
    graph.nodes.forEach(node => {
      // Header: Node, Weight
      const row = [node.data.name, node.data.weight];
      nodeData += addArrayToString(row);
    });
  }

  let edgeData = addArrayToString(['Parent Node', 'Child Node', 'Weight']);
  if (graph.edges) {
    graph.edges.forEach(edge => {
      // Header: Parent Node, Child Node, Weight
      const row = [edge.data.source, edge.data.target, edge.data.weight];
      edgeData += addArrayToString(row);
    });
  }

  const downloadMenu = (
    <Menu>
      <Menu.Item>
        <DownloadFile
          options={{
            type: 'data:attachment/text',
          }}
          content={nodeData}
          filename={formatFileNameWithKey('nodes', '.csv', currentProject?.key)}
        >
          Nodes
        </DownloadFile>
      </Menu.Item>
      <Menu.Item>
        <DownloadFile
          options={{
            type: 'data:attachment/text',
          }}
          content={edgeData}
          filename={formatFileNameWithKey('edges', '.csv', currentProject?.key)}
        >
          Edges
        </DownloadFile>
      </Menu.Item>
    </Menu>
  );

  const settingsMenu = (
    <div css={modalStyle}>
      <Menu mode="vertical">
        <Menu.ItemGroup
          key="Target"
          className="dropdown-option"
          title={<label>Target</label>}
        >
          {graph.props.outcomes.map(outcome => (
            <Menu.SubMenu
              key={outcome.targetVariable}
              popupOffset={[45, -10]}
              popupClassName="hud-submenu-popup"
              title={outcome.targetVariable}
            >
              <Menu.Item>{outcome.targetVariable}</Menu.Item>
            </Menu.SubMenu>
          ))}
        </Menu.ItemGroup>

        <Menu.ItemGroup
          key="HoverToShow"
          className="dropdown-option"
          title={<label>Hover to Show</label>}
        >
          <Menu.SubMenu
            popupOffset={[45, -10]}
            popupClassName="hud-submenu-popup"
            title="Strongest Relationship"
          >
            <Menu.Item>Strongest Relationship</Menu.Item>
          </Menu.SubMenu>
        </Menu.ItemGroup>

        <Menu.ItemGroup
          key="NodeSizeShows"
          className="dropdown-option"
          title={<label>Node Size Shows</label>}
        >
          <Menu.SubMenu
            popupClassName="hud-submenu-popup"
            popupOffset={[45, -10]}
            title="Impact on Target Variable"
          >
            <Menu.Item>Impact on Target Variable</Menu.Item>
          </Menu.SubMenu>
        </Menu.ItemGroup>
        <Menu.ItemGroup
          key="LineWidthIndicates"
          className="dropdown-option"
          title={<label>Line Width Indicates</label>}
        >
          <Menu.SubMenu
            popupClassName="hud-submenu-popup"
            popupOffset={[45, -10]}
            title="Strength of Relationship"
          >
            <Menu.Item>Strength of Relationship</Menu.Item>
          </Menu.SubMenu>
        </Menu.ItemGroup>

        <Menu.ItemGroup
          key="NodeColorKey"
          title={<label>Node Color Key</label>}
          className="dropdown-option"
        >
          <IngredientCategories />
        </Menu.ItemGroup>
      </Menu>
    </div>
  );

  return (
    <div css={modalStyle}>
      <div
        id="header"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        {/* Leaving this div to render even if logo isn't displayed */}
        {/* Allows inputs to be right justified with justify-content: space-between*/}
        <div
          className="ant-btn-set"
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {modal && (
            <>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Button
                  css={css`
                    padding: 0 8px;
                  `}
                >
                  <KnowledgeDiscoveryIcon />
                </Button>
                Turing Insight Explorer
              </div>
              <Divider type="vertical" />
              <div>{currentProject?.name}</div>
              <Divider type="vertical" />
              <div className="settings-dropdown-trigger">
                <Dropdown overlay={settingsMenu}>
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              </div>
            </>
          )}
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            float: right;
            justify-content: center;
          `}
        >
          <div
            className="ant-btn-set"
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <label>Download</label>
            <div className="">
              <Dropdown overlay={downloadMenu}>
                <Button>
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div
            className="ant-btn-set"
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div className="label-icon">
              <SearchIcon />
            </div>
            <Input
              placeholder="Find an ingredient..."
              onChange={e => graph.search(e.target.value)}
            />
          </div>
          {document.fullscreenEnabled && modal && (
            <div className="ant-btn-set">
              <FullscreenButton />
            </div>
          )}
          {modal && (
            <div className="ant-btn-set">
              <Button
                onClick={() => {
                  setFullscreen(false);
                  onClose?.();
                }}
              >
                <CloseOutlined />
              </Button>
            </div>
          )}
        </div>
      </div>
      {modal && insightExplorerIsV2 && (
        <>
          <NegativeDriverLabel />
          <PositiveDriverLabel />
        </>
      )}
      {modal && insightExplorerIsV2 && (
        <div
          className="ant-btn-set"
          css={css`
            position: absolute;
            right: 16px;
            top: 66px;
          `}
        >
          <TopDriversGraph />
        </div>
      )}
      {modal && insightExplorerIsV2 && (
        <div
          className="dropdown-option"
          css={css`
            position: absolute;
            right: 16px;
            top: 185px;
            width: 230px;
          `}
        >
          <TopDriversList />
        </div>
      )}
      <div
        className="ant-btn-set"
        css={css`
          position: absolute;
          right: 16px;
          bottom: 62px;
        `}
      >
        <Button
          className="vertical"
          onClick={() => graph.cy.zoom(graph.cy.zoom() * 1.1)}
        >
          <div>
            <PlusOutlined />
          </div>
        </Button>
        <Button
          className="vertical"
          onClick={() => graph.cy.zoom(graph.cy.zoom() * 0.9)}
        >
          <div>
            <MinusOutlined />
          </div>
        </Button>
      </div>
      <div
        className="ant-btn-set"
        css={css`
          position: absolute;
          right: 16px;
          bottom: 16px;
        `}
      >
        <Button onClick={() => graph.cy.center()}>
          <div
            css={css`
              padding: 0 1px;
            `}
          >
            <CenterIcon />
          </div>
        </Button>
      </div>
    </div>
  );
};
