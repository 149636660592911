/** @jsxImportSource @emotion/react */

export const GeneratedFormulationIcon = () => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.35937 1.10729C6.71054 0.580528 7.48458 0.580529 7.83575 1.10729L13.2756 9.26702C13.6686 9.85661 13.246 10.6463 12.5374 10.6463H1.65774C0.94914 10.6463 0.526488 9.85661 0.919548 9.26702L6.35937 1.10729Z"
      fill="#FF2459"
    />
  </svg>
);

export const BenchmarkIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12.3308C9.94412 12.3308 12.3308 9.94412 12.3308 7C12.3308 4.05588 9.94412 1.66921 7 1.66921C4.05588 1.66921 1.66921 4.05588 1.66921 7C1.66921 9.94412 4.05588 12.3308 7 12.3308Z"
      fillOpacity="1"
      stroke="#0075FF"
      strokeWidth="2.66159"
    />
  </svg>
);

export const NonBenchmarkExistingIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5.71966" cy="5.94207" r="5.32317" fill="#0075FF" />
  </svg>
);
