/** @jsxImportSource @emotion/react */
// TODO: Rename this file?
import { jsx, css } from '@emotion/react';
import React from 'react';
import {
  ArrowRightOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { List, Button, Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { CreateIterationModal } from '../iteration-modal';
import { useSession } from '../../../_shared/context';
import {
  iterationSidebarStyle,
  newIterationButtonStyle,
} from './iteration-sidebar.styles';
import {
  sidebarSectionStyle,
  sidebarSortStyle,
  sidebarTitleStyle,
} from '../../project/project-sider.styles';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { ProjectFeature } from '@prisma/client';
import { WorkspaceType } from '../../../../../__generated__/globalTypes';

export interface IterationRouteParams {
  projectId: string;
  iterationId: string;
}

export const IterationSidebar = ({
  selected = false,
  onSelect,
}: {
  selected: boolean;
  onSelect: () => void;
}) => {
  const MAX_NUMBER_SHOWN = 10;
  const {
    currentProject,
    selectedIterationId,
    setSelectedIterationId,
  } = useSession();
  const [showModal, setShowModal] = React.useState(false);
  const [showRecent, setShowRecent] = React.useState(true);

  const params = useParams<IterationRouteParams>();

  const iterations = currentProject
    ? currentProject.iterations.filter(i => !i.archived)
    : [];
  const projectId = currentProject
    ? currentProject.id
    : Number(params.projectId);

  const sortedIterations = [...iterations]
    .sort((a, b) => {
      const dateStringA: string = a.simulations?.length
        ? a.simulations[0].createdAt
        : a.createdAt;
      const dateStringB: string = b.simulations?.length
        ? b.simulations[0].createdAt
        : b.createdAt;

      const dateA = new Date(dateStringA).getTime();
      const dateB = new Date(dateStringB).getTime();

      if (showRecent) {
        return dateB - dateA;
      }
      return dateA - dateB;
    })
    .slice(0, MAX_NUMBER_SHOWN);

  const listItems = React.useMemo(
    () =>
      sortedIterations
        .filter(iteration => iteration.type !== WorkspaceType.EXPLORATION)
        .map(i => (
          <List.Item
            key={i.id}
            className={
              selected && i.id === selectedIterationId ? 'selected' : ''
            }
          >
            <Link
              css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              `}
              to={`/project/${projectId}/iteration/${i.id}`}
              onClick={() => {
                logEvent(TrackableEvent.ITERATION_LEFT_NAV_CLICKED, {
                  projectId,
                  iterationId: i.id,
                });
                setSelectedIterationId(i.id);
                onSelect();
              }}
            >
              <Tooltip title={i.name}> {i.name} </Tooltip>
            </Link>
          </List.Item>
        )),
    [selected, showRecent, iterations, selectedIterationId]
  );

  const closeModal = () => {
    setShowModal(false);
  };

  const canRunSimulation = currentProject?.features.some(
    f =>
      f.feature === ProjectFeature.SIMULATION ||
      f.feature === ProjectFeature.ADAPTIVE_LEARNING
  );

  return (
    <React.Fragment>
      <div
        css={sidebarSectionStyle}
        className="border"
        style={{ marginTop: '10px' }}
      >
        <div css={sidebarTitleStyle}>
          <div>Workspaces</div>
        </div>
        <div css={sidebarSortStyle} onClick={() => setShowRecent(!showRecent)}>
          <div className="label">Recent</div>
          {showRecent ? (
            <DownOutlined style={{ fontSize: '10px' }} />
          ) : (
            <UpOutlined style={{ fontSize: '10px' }} />
          )}
        </div>
      </div>
      <List size="small" css={iterationSidebarStyle}>
        <List.Item key="add" className="add-button">
          {currentProject && (
            <Button
              css={newIterationButtonStyle}
              disabled={!canRunSimulation}
              type="primary"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Add a new workspace
              <ArrowRightOutlined />
            </Button>
          )}
        </List.Item>
        {listItems}
        {showModal && currentProject && (
          <CreateIterationModal close={closeModal} />
        )}
      </List>
    </React.Fragment>
  );
};
