import { css } from '@emotion/react';
// Move containerStyle to a more shared location
import { containerStyle } from '../../../../_shared/components/container/content-container-with-header.styles';
import { Colors } from '../../../../_shared/style';

export const designSkeletonModalStyle = [
  containerStyle,
  css`
    z-index: 999;
    position: absolute;
    left: calc(35% + 5%);
    top: 25%;
    padding: 24px;
    background-color: #fff;
    max-width: 400px;
  `,
];

export const designResultsStyle = css`
  width: 100%;
  overflow-x: hidden;
  div {
    .desPanelBody {
      display: flex;
      width: 100%;

      .desDescription {
        border-right: 1px solid ${Colors.ZICRON};
      }

      div {
        width: 50%;

        p {
          display: flex;
          flex-direction: column;
        }

        .desBodyTitle {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`;
