import { FormulationType } from '@root/_shared/context/formulations-context';
import React, { ReactNode, useEffect, useState } from 'react';
import './exploration-expanded-formulation-item.less';
import {
  FormulationFeedbackSubjectType,
  FormulationFeedbackType,
  FormulationItem,
  FormulationItemType,
  ProjectFeature,
  usecreateOneFormulationFeedbackMutation,
} from '../../../../../../__generated__/globalTypes';
import _ from 'lodash';
import { useExploration, useSession } from '../../../../_shared/context';
import { Flex, Tooltip, notification } from 'antd';
import { Info, Like } from '../../../../_shared/style';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const ExplorationExpandedFormulationItem = ({
  formulation,
}: {
  formulation: FormulationType;
}) => {
  const [createFeedbackNote] = usecreateOneFormulationFeedbackMutation();
  const { setFormulationFeedback } = useExploration();

  const { currentProject } = useSession();
  const [sorted, setSorted] = useState(false);
  const [costEnable, setCostEnable] = useState(false);


  const [hasPositiveOutcomeFeedback, setHasPositveOutcomeFeedback] = useState(
    false
  );
  const [hasNegativeOutcomeFeedback, setHasNegativeOutcomeFeedback] = useState(
    false
  );
  const [
    hasPositiveIngredientFeedback,
    setHasPositiveIngredientFeedback,
  ] = useState(false);
  const [
    hasNegativeIngredientFeedback,
    setHasNegativeIngredientFeedback,
  ] = useState(false);

  const [ingredientsExpanded, setIsExpanded] = useState(false);

  const ingredientsExpansion = () => {
    setIsExpanded(!ingredientsExpanded);
  };

  useEffect(() => {
    if (formulation) {
      formulation.items = _.sortBy(
        formulation.items,
        ['variable.name'],
        ['asc']
      );
      setSorted(true);
    }
  }, [formulation]);

  useEffect(() => {
    if (currentProject) {
      let find = false;
      currentProject.features.map(f => {
        if (f.feature === ProjectFeature.PRICING) {
          find = true;
        }
      });
      setCostEnable(find);
    }
  }, [currentProject]);

  useEffect(() => {
    const formulationRecord = formulation

    // for (const feedback of formulationRecord.formulationFeedback) {
    //   setFeedbackStatus(feedback);
    // }
    if (formulationRecord.formulationFeedback) {
      const oFeedback = formulationRecord.formulationFeedback.filter(e => (
        e.subjectType === FormulationFeedbackSubjectType.OUTCOME
      ))
      const iFeedback = formulationRecord.formulationFeedback.filter(e => (
        e.subjectType === FormulationFeedbackSubjectType.INGREDIENT
      ))
      if (iFeedback.length > 0) {
        setFeedbackStatus(iFeedback[iFeedback.length - 1])
      }
      if (oFeedback.length > 0) {
        setFeedbackStatus(oFeedback[oFeedback.length - 1])
      }
    }
  }, [formulation]);

  const setFeedbackStatus = (
    feedback: FormulationType['formulationFeedback'][0]
  ) => {
    const isPositive = feedback.type === FormulationFeedbackType.POSITIVE;
    const isNegative = feedback.type === FormulationFeedbackType.NEGATIVE;

    if (
      isPositive &&
      feedback.subjectType === FormulationFeedbackSubjectType.OUTCOME
    ) {
      if (feedback.isActive) {
        setHasPositveOutcomeFeedback(true);
        setHasNegativeOutcomeFeedback(false);
      } else {
        setHasPositveOutcomeFeedback(false);
        setHasNegativeOutcomeFeedback(false);
      }
    }

    if (
      isPositive &&
      feedback.subjectType === FormulationFeedbackSubjectType.INGREDIENT
    ) {
      if (feedback.isActive) {
        setHasPositiveIngredientFeedback(true);
        setHasNegativeIngredientFeedback(false);
      } else {
        setHasPositiveIngredientFeedback(false);
        setHasNegativeIngredientFeedback(false)
      }
    }

    if (
      isNegative &&
      feedback.subjectType === FormulationFeedbackSubjectType.OUTCOME
    ) {
      if (feedback.isActive) {
        setHasNegativeOutcomeFeedback(true);
        setHasPositveOutcomeFeedback(false);
      } else {
        setHasNegativeOutcomeFeedback(false);
        setHasPositveOutcomeFeedback(false)
      }
    }

    if (
      isNegative &&
      feedback.subjectType === FormulationFeedbackSubjectType.INGREDIENT
    ) {
      if (feedback.isActive) {
        setHasNegativeIngredientFeedback(true);
        setHasPositiveIngredientFeedback(false);
      } else {
        setHasNegativeIngredientFeedback(false);
        setHasPositiveIngredientFeedback(false)
      }
    }
  };

  const handleCreateFeedback = async (
    type: FormulationFeedbackType,
    subjectType: FormulationFeedbackSubjectType,
    isActive?: boolean
  ) => {
    const res = await setFormulationFeedback(formulation, type, subjectType, isActive)
    if (res.data?.createOneFormulationFeedback) {
      setFeedbackStatus(res.data.createOneFormulationFeedback);
    }
  };


  return (
    <>
      {sorted && (
        <div className="formulation-item">
          <div className="formulation-box">
            <div className="header">
              <div className="title">
                {formulation.isBenchmark
                  ? 'Benchmark'
                  : formulation.name
                    ? formulation.name
                    : formulation.key}
              </div>
              <div className={`description ${ingredientsExpanded ? 'expanded' : ''}`}>{formulation.description}</div>
              {!formulation.isBenchmark &&
                <div className="text-toggle" onClick={ingredientsExpansion}>
                  {ingredientsExpanded ? <span>Show less...</span> : <span>Show more...</span>}
                </div>
              }

            </div>
            {formulation.totalCostScore != undefined && formulation.totalCostScore != null && costEnable &&
              <>
                <div className="row cost-row">
                  <div className="cost">Cost</div>
                  <div className="portion">
                    {currentProject?.monetaryUnit} {(formulation.totalCostScore).toFixed(3)}
                  </div>

                </div>
              </>
            }
            <div className="ingredients">
              <Flex justify="space-between" align="center">
                <div className="title">Ingredients:</div>
                {!formulation.isBenchmark && <Tooltip
                  style={{ marginLeft: 5 }}
                  title="If these values meet your expectations, give them a thumbs up. If they are not trending in the right direction, give them a thumbs down."
                >
                  <div className="info">
                    <Info />
                  </div>
                </Tooltip>}
                <Flex gap={10} align="center">
                  <div
                    className={`like ${hasPositiveIngredientFeedback ? 'complete' : ''
                      }`}
                    onClick={() => {
                      handleCreateFeedback(
                        FormulationFeedbackType.POSITIVE,
                        FormulationFeedbackSubjectType.INGREDIENT,
                        !hasPositiveIngredientFeedback
                      )
                    }
                    }
                  >
                    {!formulation.isBenchmark && <Like />}
                  </div>
                  <div
                    className={`dislike ${hasNegativeIngredientFeedback ? 'complete' : ''
                      }`}
                    onClick={() =>
                      handleCreateFeedback(
                        FormulationFeedbackType.NEGATIVE,
                        FormulationFeedbackSubjectType.INGREDIENT,
                        !hasNegativeIngredientFeedback
                      )
                    }
                  >
                    {!formulation.isBenchmark && <Like />}
                  </div>
                </Flex>
              </Flex>
              {formulation.items.map(i => {
                if (i.type == FormulationItemType.INPUT) {
                  return (
                    <div className="row">
                      {i.variable.name}{' '}
                      <span>
                        {isNaN(Number.parseFloat(i.value))
                          ? i.value
                          : Number.parseFloat(i.value).toFixed(3)}
                      </span>
                    </div>
                  );
                }
              })}
            </div>
            <div className="ingredients">
              <Flex justify="space-between" align="center">
                <div className="title">Outcomes:</div>
                <Flex gap={10} align="center">
                  <div
                    className={`like ${hasPositiveOutcomeFeedback ? 'complete' : ''}`}
                    onClick={() =>
                      handleCreateFeedback(
                        FormulationFeedbackType.POSITIVE,
                        FormulationFeedbackSubjectType.OUTCOME,
                        !hasPositiveOutcomeFeedback
                      )
                    }
                  >
                    {!formulation.isBenchmark && <Like />}
                  </div>
                  <div
                    className={`dislike ${hasNegativeOutcomeFeedback ? 'complete' : ''
                      }`}
                    onClick={() =>
                      handleCreateFeedback(
                        FormulationFeedbackType.NEGATIVE,
                        FormulationFeedbackSubjectType.OUTCOME,
                        !hasNegativeOutcomeFeedback
                      )
                    }
                  >
                    {!formulation.isBenchmark && <Like />}
                  </div>
                </Flex>
              </Flex>
              {formulation.items.map(i => {
                if (i.type == FormulationItemType.TARGET_PREDICTED) {
                  return (
                    <div className="row">
                      {i.variable.name}{' '}
                      <span>
                        {isNaN(Number.parseFloat(i.value))
                          ? i.value
                          : Number.parseFloat(i.value).toFixed(3)}
                      </span>
                    </div>
                  );
                }
              })}
              {formulation.isBenchmark &&
                formulation.items.map(i => {
                  if (i.type == FormulationItemType.TARGET_MEASURED) {
                    return (
                      <div className="row">
                        {i.variable.name}{' '}
                        <span>
                          {isNaN(Number.parseFloat(i.value))
                            ? i.value
                            : Number.parseFloat(i.value).toFixed(2)}
                        </span>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
