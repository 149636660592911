import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const askTuringPanelStyle = css`
  width: 350px;
  padding-bottom: 20px;
  background-color: ${Colors.WHITE};
  box-shadow: 0px 2px 10px rgba(29, 46, 52, 0.16);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  h1 {
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.3em;
  }
`;

export const closeIconStyle = css`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  svg path {
    fill: black;
  }
`;

export const headerStyle = css`
  margin-top: 20;
  font-size: 24px;
  font-weight: bolder;
`;

export const subheaderStyle = css`
  font-size: 16;
`;

export const askButtonStyle = css`
  margin-top: 15px;
  border-radius: 20px;
`;
