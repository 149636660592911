/** @jsxImportSource @emotion/react */

import { Role } from '@prisma/client';
import * as React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import { useSession } from '../../../../_shared/context';
import {
  sidebarSectionStyle,
  sidebarTitleStyle,
} from '../../project-sider.styles';
import { ProjectInfoModal } from '../../project-info-modal.compoent';
import { iterationSidebarStyle } from '../../../iteration/iteration-sidebar/iteration-sidebar.styles';

export const AdminSideBar = ({
  selected = false,
  onSelect,
}: {
  selected: boolean;
  onSelect: () => void;
}) => {
  const { currentProject, user } = useSession();
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        css={sidebarSectionStyle}
        className="border"
        style={{ marginTop: '10px' }}
      >
        <div css={sidebarTitleStyle}>
          <div>ADMIN</div>
        </div>
      </div>
      {currentProject && (
        <List size="small" css={iterationSidebarStyle}>
          {user?.role === Role.SUPER_ADMIN && (
            <List.Item
              key="project-management"
              className={selected ? 'selected' : ''}
            >
              <Link
                to={`/project/${currentProject.id}/admin`}
                onClick={() => {
                  onSelect();
                }}
              >
                Admin Project Settings
              </Link>
            </List.Item>
          )}
          <List.Item
            key="user-management"
            className={selected ? 'selected' : ''}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setInfoModalOpen(true)}
          >
            User Settings
          </List.Item>
        </List>
      )}

      {infoModalOpen && currentProject && (
        <ProjectInfoModal
          project={currentProject}
          onCancel={() => setInfoModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
};
