import React from 'react';

import { Button } from 'antd';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';

export const IsolateButton = ({
  onClick,
  disabled,
  numberOfRows,
}: {
  onClick: () => void;
  disabled: boolean;
  numberOfRows: number;
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      icon={<EyeOutlined rev={undefined} />}
    >
      Isolate {numberOfRows > 0 ? `(${numberOfRows})` : ''}
    </Button>
  );
};
