import * as React from 'react';
import { Form, Spin } from 'antd';
import { ApolloError } from '@apollo/client';
import { Store } from 'antd/lib/form/interface';
import { FormInstance } from 'antd/lib/form';

const defaultLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

export interface GraphqlFormProps<Type> {
  data: Type;
  loading: boolean;
  error?: ApolloError;
  formLayout?: 'vertical' | 'horizontal';
  children: (data: Type, form: FormInstance) => React.ReactNode;
  layout?: {
    labelCol: { span: number };
    wrapperCol: { span: number };
  };
  onFinishSuccess?: (data: any) => void;
}

export function GraphqlForm<Data>(props: GraphqlFormProps<Data>) {
  const [form] = Form.useForm();

  const {
    layout,
    children,
    data,
    loading,
    error,
    formLayout,
    onFinishSuccess,
  } = props;

  // TODO:  better placeholder once we figure out what UI should look like
  if (loading) return <Spin />;

  if (error)
    return (
      <div>
        {/* TODO: show error better: */}
        {error.message}
      </div>
    );

  const onFinish = (values: Store) => {
    const newData: Data = {
      ...data,
      ...values,
    };

    onFinishSuccess && onFinishSuccess(newData);
  };

  return (
    <Form
      autoComplete="chrome-off"
      role="presentation"
      {...(layout ?? defaultLayout)}
      layout={formLayout || 'horizontal'}
      form={form}
      requiredMark={false}
      name="basic"
      onFinish={onFinish}
      initialValues={
        {
          ...data,
        } as Store
      }
    >
      {children(data, form)}
    </Form>
  );
}
