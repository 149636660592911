import React, { useId, useState } from 'react';
import {
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Divider,
  Input,
  InputNumber,
  Popover,
  Select,
  Typography,
} from 'antd';
import isNil from 'lodash/isNil';
import {
  CustomRecordType,
  FilterType,
  FilterTypeEnum,
  chartKeyBlacklist,
} from '../experiment-list-layout';
import capitalize from 'lodash/capitalize';
import uniqueId from 'lodash/uniqueId';
import { update } from 'lodash';
const { Text } = Typography;
const Filter = ({
  tableColumns,
  onRemove,
  onChange,
  filter,
}: {
  onChange: (changedFilter: FilterType) => void;
  filter: FilterType;
  tableColumns: CustomRecordType[];
  onRemove: () => void;
}) => {
  const items = tableColumns
    .filter(
      tc =>
        !chartKeyBlacklist.includes(tc.key) &&
        tc?.sourceRecord?.type === 'NUMERIC'
    )
    .map(tc => {
      return { value: tc.key, label: capitalize(tc.key) };
    });
  return (
    <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
      <div style={{ display: 'flex', gap: 10 }}>
        <Select
          style={{ minWidth: 200 }}
          allowClear
          placeholder="Select an option..."
          options={items}
          value={filter.selectedItem}
          onChange={value =>
            onChange({ ...filter, selectedItem: value as FilterTypeEnum })
          }
          status={filter.error ? 'error' : ''}
        />
        <Select
          defaultValue="between"
          style={{ width: 150 }}
          onChange={value =>
            onChange({ ...filter, filterType: value as FilterTypeEnum })
          }
          value={filter.filterType}
          options={[
            { value: FilterTypeEnum.LESS_THAN, label: 'Less Than' },
            { value: FilterTypeEnum.GREATER_THAN, label: 'Greater Than' },
            { value: FilterTypeEnum.BETWEEN, label: 'Between' },
            { value: FilterTypeEnum.EQUAL_TO, label: 'Equal To' },
          ]}
        />
        {[
          FilterTypeEnum.GREATER_THAN,
          FilterTypeEnum.LESS_THAN,
          FilterTypeEnum.EQUAL_TO,
        ].includes(filter.filterType) && (
          <InputNumber
            style={{ width: 233 }}
            value={filter.inputValue}
            onChange={value => onChange({ ...filter, inputValue: value ?? 0 })}
            status={filter.error ? 'error' : ''}
          />
        )}
        {filter.filterType === FilterTypeEnum.BETWEEN && (
          <div style={{ display: 'flex', gap: 5 }}>
            <InputNumber
              style={{ width: 100 }}
              value={filter.startValue}
              onChange={value =>
                onChange({ ...filter, startValue: value ?? 0 })
              }
              status={filter.error ? 'error' : ''}
            />
            <span style={{ marginTop: 5 }}>and</span>
            <InputNumber
              style={{ width: 100 }}
              value={filter.endValue}
              onChange={value => onChange({ ...filter, endValue: value ?? 0 })}
              status={filter.error ? 'error' : ''}
            />
          </div>
        )}
        <Button
          type="text"
          icon={<DeleteOutlined style={{ color: '#c8c8c8' }} />}
          onClick={onRemove}
        />
      </div>

      <Text type="danger">{filter.error}</Text>
    </div>
  );
};

export const FilterDropdown = ({
  filterMenuVisible,
  setFilterMenuVisible,
  tableColumns,
  formulationsToFilter,
  chartData,
  setFormulationsToFilter,
  chartFilters,
  setChartFilters,
}: {
  filterMenuVisible: boolean;
  setFilterMenuVisible: (filterMenuVisible: boolean) => void;
  tableColumns: CustomRecordType[];
  chartData: { name: string; values: Record<string, any>[] }[];
  formulationsToFilter: string[];
  setFormulationsToFilter: (formulationsToFilter: string[]) => void;
  chartFilters: FilterType[];
  setChartFilters: (filters: FilterType[]) => void;
}) => {
  const handleFilterRemove = (idToRemove: string) => {
    const filteredChartFilters = chartFilters.filter(
      filter => idToRemove !== filter.id
    );
    setChartFilters(filteredChartFilters);
  };

  const handleAddFilter = () => {
    setChartFilters([
      ...chartFilters,
      {
        id: uniqueId(String(chartFilters.length)),
        filterType: FilterTypeEnum.GREATER_THAN,
        inputValue: 0,
        startValue: 0,
        endValue: 0,
        selectedItem: null,
      },
    ]);
  };
  const handleFilterChange = (updatedFilter: FilterType) => {
    const updatedData = chartFilters.map(filter => {
      if (filter.id === updatedFilter.id) {
        return updatedFilter;
      }
      return filter;
    });
    setChartFilters(updatedData);
  };

  const applyFilters = (): void => {
    const formulationsToFilter: string[] = [];
    let updatedFilters = chartFilters.map(filter => ({ ...filter })); // Clone the chartFilters array
    let filtersHaveError = false;
    for (let i = 0; i < updatedFilters.length; i++) {
      let filter = updatedFilters[i];
      filter.error = undefined; // Reset previous error

      // Validation
      if (isNil(filter.selectedItem)) {
        filter.error = 'Selected item is required';
        filtersHaveError = true;
        continue;
      }

      // Check for BETWEEN filter specific validations
      if (filter.filterType === FilterTypeEnum.BETWEEN) {
        if (isNil(filter.startValue) || isNil(filter.endValue)) {
          filter.error = 'Start and end values are required for BETWEEN filter';
          filtersHaveError = true;
          continue;
        }
        if (filter.endValue < filter.startValue) {
          filter.error =
            'End value must be greater than start value in BETWEEN filter';
          filtersHaveError = true;
          continue;
        }
      } else if (isNil(filter.inputValue)) {
        // Check for other filters where inputValue is required
        filter.error = 'Input value is required';
        filtersHaveError = true;
        continue;
      }

      let data = chartData.find(data => data.name === filter.selectedItem);
      if (isNil(data)) {
        filter.error = 'Data not found for selected item';
        filtersHaveError = true;
        continue;
      }

      for (const item of data.values) {
        switch (filter.filterType) {
          case FilterTypeEnum.BETWEEN:
            if (
              item[data.name] <= filter.startValue ||
              item[data.name] >= filter.endValue
            ) {
              formulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.EQUAL_TO:
            if (item[data.name] !== filter.inputValue) {
              formulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.GREATER_THAN:
            if (filter.inputValue >= item[data.name]) {
              formulationsToFilter.push(item.name);
            }
            break;
          case FilterTypeEnum.LESS_THAN:
            if (filter.inputValue <= item[data.name]) {
              formulationsToFilter.push(item.name);
            }
            break;
          // ... other filter types
        }
      }
    }

    if (filtersHaveError) {
      setChartFilters(updatedFilters); // Set the errors
    } else {
      setChartFilters(updatedFilters); // Remove errors
      setFormulationsToFilter(formulationsToFilter);
      setFilterMenuVisible(false);
    }
  };

  const content = (
    <div style={{ width: 620 }}>
      <p>Show experiments where...</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        {chartFilters?.map((filter, index) => (
          <Filter
            key={filter.id}
            tableColumns={tableColumns}
            onRemove={() => handleFilterRemove(filter.id)}
            filter={filter}
            onChange={handleFilterChange}
          />
        ))}
      </div>

      <Button
        icon={<PlusOutlined />}
        onClick={handleAddFilter}
        style={{ marginTop: 10 }}
      >
        Filter
      </Button>
      <Divider style={{ marginBottom: 10 }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          danger
          type="default"
          icon={<DeleteOutlined />}
          onClick={() => {
            setChartFilters([]);
            setFormulationsToFilter([]);
          }}
        >
          Clear All
        </Button>
        <div style={{ display: 'flex', gap: 5 }}>
          <Button onClick={() => setFilterMenuVisible(false)}>Cancel</Button>
          <Button type="primary" onClick={applyFilters}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
  return (
    <Popover
      content={content}
      open={filterMenuVisible}
      onOpenChange={setFilterMenuVisible}
      placement="bottom"
      trigger="click"
    >
      <Button icon={<FilterOutlined />}>
        Filters{' '}
        {chartFilters?.length > 0 ? (
          <Badge
            style={{ marginLeft: 5 }}
            shape="square"
            size="small"
            count={chartFilters.length}
          />
        ) : (
          ''
        )}
      </Button>
    </Popover>
  );
};
