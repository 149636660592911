/** @jsxImportSource @emotion/react */
import { InfoCircleFilled } from '@ant-design/icons';
import { css, jsx } from '@emotion/react';
import { Tooltip } from 'antd';
import { isSingleProductOutcome } from '../../../../../_shared/utils/util';
import { SimulationProductVersionResultType } from '../../../../../_shared/hooks';
import { Outcome, SingleProductOutcome } from '../../context';
import { SeverityDot } from '../../shared';
import { SeverityBar } from '../simulation-result-table/severity-bar.component';
import isString from 'lodash/isString';

const getProbability = (
  distribution?: Map<string, number>,
  value?: string | number
) => {
  const dist = Number(distribution?.get(isString(value) ? value : '')) * 100;
  return dist.toFixed();
};

const getOrdinalOutcomeInfo = (
  o: Outcome | SingleProductOutcome,
  s: SimulationProductVersionResultType
) => {
  let value;
  let distribution;

  if (!isSingleProductOutcome(o)) {
    const productOutcome = o.products.get(s.productVersion.name);
    if (productOutcome !== undefined) {
      value = productOutcome.value;
      distribution = productOutcome.distribution;
    }
  } else {
    value = o.outcomeInfo.value;
    distribution = o.outcomeInfo.distribution;
  }
  return { value, distribution, name: o.targetVariable };
};

export const OrdinalOutcomeResult = ({
  outcome,
  sim,
}: {
  outcome: Outcome | SingleProductOutcome;
  sim: SimulationProductVersionResultType;
}) => {
  const { value, distribution } = getOrdinalOutcomeInfo(outcome, sim);
  const probability = getProbability(distribution, value);
  const showDetails = !isNaN(Number(probability));

  return (
    <div
      css={css`
        display: block;
        width: 100%;
      `}
    >
      <div
        css={css`
          float: left;
          font-weight: ${sim.productVersion.isBenchmark ? 400 : 700};
        `}
      >
        {value}
      </div>
      <Tooltip
        color="#03191E"
        placement="bottom"
        title={
          <div>
            <span
              css={css`
                padding-bottom: 6px;
              `}
            >
              Probability for each outcome:
            </span>
            {distribution &&
              [...distribution].map(([key, val]) => {
                return <SeverityBar prob={val} severity={key} key={key} />;
              })}
          </div>
        }
        css={css`
          float: right;
        `}
      >
        {showDetails && (
          <div
            css={css`
              display: flex;
            `}
          >
            <SeverityDot
              severity={Number(
                distribution?.get(isString(value) ? value : '')?.toFixed(2)
              )}
            />
            <span
              css={css`
                color: #6d6d6d;
                border-bottom: 1px dashed #6d6d6d;
              `}
            >
              {probability}
              % <InfoCircleFilled color="#6D6D6D" />
            </span>
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export const OrdinalOutcomeResultFull = ({
  outcome,
  sim,
  color,
}: {
  outcome: Outcome | SingleProductOutcome;
  sim: SimulationProductVersionResultType;
  color: string;
}) => {
  const { distribution, name } = getOrdinalOutcomeInfo(outcome, sim);

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          display: grid;
        `}
      >
        <div>{name}</div>
        <div
          css={css`
            font-size: smaller;
          `}
        >
          {distribution &&
            [...distribution].map(([key, val]) => {
              return (
                <SeverityBar
                  prob={val}
                  severity={key}
                  key={key}
                  color={color}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
