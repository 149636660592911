import * as React from 'react';
import axios, { AxiosInstance } from 'axios';
import { useSession } from './context';

const BASE_URL = '/';

let instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const useApi = () => {
  const { accessToken } = useSession();

  React.useEffect(() => {
    if (accessToken) {
      instance = axios.create({
        baseURL: BASE_URL,
        headers: getAuthHeader(),
      });
    } else {
      instance = axios.create({
        baseURL: BASE_URL,
      });
    }
  }, [accessToken]);

  const getAuthHeader = () =>
    accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

  return {
    getAuthHeader,
  };
};
