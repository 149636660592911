import {
  UrlType,
  getSignedUrlMutationFn,
} from '../../../../__generated__/globalTypes';
import { RcFile } from 'antd/lib/upload';
import axios, { AxiosResponse } from 'axios';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';
import S3 from 'aws-sdk/clients/s3';

interface S3SignedUploadResponse extends ManagedUpload.SendData {}

// TODO: Forcing type since antd doesn't export it anymore. See: https://github.com/ant-design/ant-design/issues/28713
export interface UploadRequestOption {
  onProgress: (event: { percent: number }, file: RcFile) => void;
  onError: (error: Error, response?: any, file?: RcFile) => void;
  onSuccess: (
    response: AxiosResponse<S3SignedUploadResponse>,
    file: RcFile
  ) => void;
  data: object;
  file: RcFile;
  withCredentials: boolean;
  action: string;
  headers: object;
}

export type KeyScopeType =
  | 'project'
  | 'adaptive_learning'
  | 'model-training-data'
  | 'test_plan';

export const getSignedS3Url = async ({
  file,
  projectId,
  keyScope,
  key,
  iterationId,
  hookFn,
  onError = () => {},
  urlType,
}: {
  file?: RcFile;
  projectId: string;
  keyScope?: KeyScopeType;
  iterationId?: string;
  key?: string;
  hookFn: getSignedUrlMutationFn;
  onError?: (e: unknown) => void;
  urlType: UrlType;
}): Promise<string> => {
  if (urlType === UrlType.putObject && !file) {
    throw new Error('File is required when performing a PUT request');
  }

  try {
    const result = await hookFn({
      variables: {
        metadata: {
          ...(urlType === UrlType.putObject && {
            name: file!.name,
            type: file!.type,
            size: file!.size,
          }),
          projectId,
          keyScope,
          key,
          iterationId,
          urlType,
        },
      },
    });

    if (!result?.data?.signedUrl) {
      const message = 'Could not fetch signed S3 URL';
      onError(message);
      throw new Error(message);
    }
    return result.data?.signedUrl;
  } catch (error) {
    Sentry.captureException(error);
    onError(error);
    throw new Error((error as ApolloError)?.message);
  }
};

export const downloadFromS3 = async ({
  action,
  onError,
  onSuccess,
}: {
  action: string;
  onError: (error: Error, response?: any) => void;
  onSuccess: (response: AxiosResponse, fileData: unknown) => void;
}) => {
  try {
    const res = await axios.get<string | undefined>(action);
    const fileData = res.data;

    if (!fileData) {
      onError(new Error('File not returned'));
    } else {
      onSuccess(res, fileData);
    }
  } catch (e) {
    onError(e as Error, null);
  }
};

export const uploadToS3 = async (
  options: Omit<UploadRequestOption, 'data' | 'withCredentials' | 'headers'> & {
    onUploadSuccess?: (res: any, file?: RcFile) => void;
  }
) => {
  const {
    onProgress,
    onSuccess = () => {},
    onUploadSuccess = () => {},
    onError,
    action: url,
    file,
  } = options;
  try {
    const data = await file.arrayBuffer();
    const res = await axios.put<S3SignedUploadResponse>(url, data, {
      headers: { 'Content-Type': file!.type },
      onUploadProgress: ({ loaded }) => {
        const percent = Math.round((loaded * 100) / file.size);
        onProgress({ percent }, file);
      },
    });

    // onSuccess callback handles the antd uploader success state
    onSuccess(res, file);

    // onUploadSuccess is an optional callback specified by the caller of this function
    onUploadSuccess(res, file);
  } catch (e) {
    console.log('Error uploading file', e);
    onError(e as Error, null, file);
  }
};

export const parseS3Url = (url: string) => {
  const bucketName = url.split('.')[0].split('/')[2];
  const splitUrl = url.split('/');
  const key = splitUrl.splice(3, splitUrl.length + 1).join('/');
  return { key, bucketName };
};

export const parseSignedUploadUrl = (
  url: string
): { bucketName: string; key: string } => {
  const parsedUrl = new URL(url);
  const pathComponents = parsedUrl.pathname.split('/');

  const bucketName = parsedUrl.hostname.split('.')[0];
  const key = pathComponents.splice(0).join('/');

  return { bucketName, key };
};

export const stringToFile = (
  str: string,
  fileName: string,
  contentType: string
) => {
  return new File([str], fileName, { type: contentType });
};
