import { Button, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { useExploration, useSession } from '../../../../_shared/context';
import './constraint-composition.less';
import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  IngredientList,
} from '../../../../../../__generated__/globalTypes';

import {
  IngredientComposition,
  IngredientCompositionSetupModal,
} from '../../../../components/ingredient-composition';
import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { IngredientListItem, IngredientType } from '../../../../_shared/hooks';
import { emptyIngredient } from '../../../../components/workspaces/adaptive-learning/design-utils';
import { ConstraintRow } from '../constraint-row/constraint-row';
const { Text } = Typography;

export const ConstraintComposition = ({
  addConstraintToTable,
  constraint,
  editConstraint,
  setConstraintToEdit,
}: {
  addConstraintToTable?: (constraint: ConstraintInputType) => void;
  constraint: ConstraintInputType;
  editConstraint?: (constraint: ConstraintInputType) => void;
  setConstraintToEdit: (c: any) => void;
}) => {
  const [composition, setComposition] = useState<
    IngredientListItem | undefined
  >(undefined);
  const [showIngredientComposition, setShowIngredientComposition] = useState(
    false
  );
  const {
    currentProject,
    user,
    hasFeature,
    ingredientsGroup,
    ingredientCompositionList,
  } = useSession();
  const [formattedCompositions, setFormattedCompositions] = useState<
    IngredientType[]
  >([]);

  useEffect(() => {
    FormatCompositiongroup();
  }, [ingredientCompositionList]);

  useEffect(() => {
    console.log("Composition state:", composition);
  }, [composition]);

  const handleComposition = (name: string) => {
    const composition = ingredientCompositionList.filter(
      (fc: IngredientComposition) => {
        return fc.name == name;
      }
    )[0];

    console.log('composition ----- ', composition);
    if (!composition) {
      return;
    }

    //setIngredientName(group.name)
    const compositionToSet = { ...emptyIngredient };
    compositionToSet.ingredient = {
      id: 1,
      name: composition.name,
    };
    compositionToSet.lowerLimit = 0;
    compositionToSet.upperLimit = 100;

    setConstraintToEdit({
      ...constraint,
      ingredientCompositionId: composition.id,
    });
    setComposition({
      ...compositionToSet,
    });
    //set ingredients from groups
  };

  const FormatCompositiongroup = () => {
    const formatted: IngredientType[] =
      ingredientCompositionList.map((e: IngredientComposition) => {
        const toAdd = { ...emptyIngredient };
        toAdd.ingredient = {
          id: 1,
          name: e.name,
        };
        toAdd.lowerLimit = 0;
        toAdd.upperLimit = 100;
        return toAdd;
      }) ?? [];
    setFormattedCompositions(formatted);
  };

  const handleSaveConstraint = async (c: Constraint) => {
    if (addConstraintToTable) {
    }
  };

  const defaultValue = () => {
    if (constraint.ingredientCompositionId) {
      const composition = ingredientCompositionList.find((i) => {
        if (constraint.ingredientCompositionId === i.id) {
          return true
        }
      })
      return composition?.name
    }

    return constraint?.constraintType == ConstraintType.EQUALITY
      ? constraint?.values?.[0].name
      : constraint?.coefficients?.[0]?.name;
  };

  async function startComponent() {
    const defaultComposition = await defaultValue();
    if (defaultComposition) {
      handleComposition(defaultComposition);
    }
  }

  useEffect(() => {
    startComponent()
  }, []);

  return (
    <div id="constraint-composition" style={{ maxWidth: '100%' }}>
      <Button
        onClick={() => setShowIngredientComposition(true)}
        className="add-constraint-button"
      >
        + Add new Composition
      </Button>
      <div className="title bold">Select ingredient composition</div>
      {showIngredientComposition && (
        <IngredientCompositionSetupModal
          onClose={() => setShowIngredientComposition(false)}
          refeshProject={false}
        />
      )}
      <IngredientSearch
        isDisabled={false}
        className="constraint-select"
        ingredients={formattedCompositions}
        defaultValue={defaultValue()}
        allowClear
        onClear={() => setComposition(undefined)}
        onSelect={(e: string) => handleComposition(e)}
      />
      {composition && (
        <ConstraintRow
          ingredient={composition}
          addConstraint={handleSaveConstraint}
          constraint={constraint}
          setConstraintToEdit={setConstraintToEdit}
          edit={constraint.ingredientCompositionId !== undefined}
        />
      )}
    </div>
  );
};
