import { css } from '@emotion/react';
import { Colors } from './colors';

//https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

export const antCustomTheme = {
  'primary-color': Colors.BRANDEIS_BLUE,
  'font-family': 'Inter',
  'border-radius-base': '4px',
  'modal-header-bg': Colors.WHISPER,
};

export const commonTableCss = css`
  padding: 0px 6px;

  .ant-table-thead tr {
    th {
      background-color: inherit;
      color: ${Colors.STORM_GREY};
      font-weight: 400;
      border-bottom-width: 2px;
    }
  }

  th,
  td {
    font-size: 13px;
  }
`;
