import React, { useEffect, useState } from 'react';
import { IRawDataAggregated } from '../RawDataAggregated.interfaces';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ProjectInputOutcomeTypeDescription } from '../../../../../pages/project-setup/project-setup-constants';
import { ProjectRawDataAggregatedRow } from '../project-raw-data-aggregated-row/project-raw-data-aggregated-row';

import './project-raw-data-aggregated-table.less';
import { useRawDataContext } from '../../../../../_shared/context/raw-data-context';
import {
  useDeleteRaw,
  useGetRawData,
  usePutRawData,
} from '../../../../../network/services/rawdata.service';
import { useSession } from '../../../../../_shared/context';

interface ProjectRawDataAggregatedTableProps {
  enableNextStep: (enable: boolean) => void;
  proj_id?: string;
  projectHasData?: boolean;
}

export const exampleRawData: IRawDataAggregated = {
  id: '0',
  variable: 'Hard Red Spring Flour',
  typeOfVariable: '',
  dataType: 'NUMERIC',
  min: 0,
  max: 2,
  unit: '%/gram',
  category: 'Flour',
  functionalRole: 'Structure',
  description: '',
  cost: 0.21,
  costUnit: 'kg',
  lowerBound: 0,
  upperBound: 2,
  priorityOutcome: 1,
};

export const emptyRawData: IRawDataAggregated = {
  id: '',
  variable: '',
  typeOfVariable: '',
  dataType: 'NUMERIC',
  min: 0,
  max: 0,
  unit: '',
  category: '',
  functionalRole: '',
  description: '',
  cost: 0,
  costUnit: '',
  lowerBound: 0,
  upperBound: 0,
  priorityOutcome: 1,
};

export const ProjectRawDataAggregatedTable: React.FC<ProjectRawDataAggregatedTableProps> = ({
  enableNextStep,
  proj_id,
  projectHasData,
}) => {
  const { user } = useSession();

  const { data, isSuccess, isLoading, refetch } = useGetRawData({
    projectId: proj_id ?? '',
    organizationId: user?.organizationId ?? '',
  });

  const editRawData = usePutRawData();
  const deleteRawData = useDeleteRaw();

  const [dataAggrgatedList, setDataAggregatedList] = useState<
    IRawDataAggregated[]
  >([]);

  const TypeDescription = ProjectInputOutcomeTypeDescription;

  const addNewColumn = () => {
    const element = { ...emptyRawData };
    element.id = dataAggrgatedList.length.toString();
    setDataAggregatedList([...dataAggrgatedList, element]);
  };

  useEffect(() => {
    if (data !== undefined && Array.isArray(data)) {
      const aggragetedFromServer: IRawDataAggregated[] = data.map(
        (o: any) =>
        ({
          ...o,
          values: o.valuesDetected,
        } as IRawDataAggregated)
      );

      setDataAggregatedList(aggragetedFromServer);
    }
  }, [data]);

  useEffect(() => {
    if (dataAggrgatedList && dataAggrgatedList.length) {
      let countIngredients = 0;
      let countOutcomes = 0;
      let countFillerIngredients = 0;
      let lowerAndUpperSame = false;
      dataAggrgatedList.map(rd => {
        if (rd.typeOfVariable === 'FILLER_INGREDIENT') {
          countFillerIngredients++;
        } else if (rd.typeOfVariable?.includes('INGREDIENT')) {
          countIngredients++;
        } else if (rd.typeOfVariable?.includes('OUTCOME')) {
          countOutcomes++;
        }
        if (!lowerAndUpperSame && rd.dataType == 'NUMERIC')
          lowerAndUpperSame = rd.lowerBound >= rd.upperBound
      });
      enableNextStep(
        countIngredients > 0 && countOutcomes > 0 && countFillerIngredients > 0 && !lowerAndUpperSame
      );
    }
  }, [dataAggrgatedList]);

  const deleteColumn = (_id: string) => {
    deleteRawData.mutate({
      projectId: proj_id ?? '',
      organizationId: user?.organizationId ?? '',
      outputId: _id,
    });

    const copy = dataAggrgatedList.filter(rd => rd.id !== _id);
    setDataAggregatedList(copy);
  };

  const editColumn = (editedData: IRawDataAggregated) => {
    editRawData.mutate({
      projectId: proj_id ?? '',
      organizationId: user?.organizationId ?? '',
      outputId: editedData.id,
      output: {
        variable: editedData.variable.trim(),
        typeOfVariable: editedData.typeOfVariable,
        dataType: editedData.dataType,
        min: editedData.min,
        max: editedData.max,
        category: editedData.category,
        functionalRole: editedData.functionalRole,
        description: editedData.description,
        cost: editedData.cost,
        costUnit: editedData.costUnit,
        lowerBound: editedData.lowerBound,
        upperBound: editedData.upperBound,
        priorityOutcome: editedData.priorityOutcome ?? 0,
        unit: editedData.unit,
      },
    });

    const rawDataAggregatedEdited = dataAggrgatedList.map(rd =>
      rd.id === editedData.id ? editedData : rd
    );

    setDataAggregatedList(rawDataAggregatedEdited);
  };

  return (
    <div id="project-inputs-raw">
      {
        <div>
          <div id="project-inputs-table">
            <div id="header" className="row-table">
              <div className="category">Variables</div>
              <div className="name">Type of variable</div>
              <div className="test-condition">
                Data Type
                <Tooltip placement="right" title={TypeDescription}>
                  <InfoCircleOutlined style={{ marginLeft: '0.5rem' }} />
                </Tooltip>
              </div>
              {/* <div className="cost">Min value observed</div>
              <div className="cost">Max value observed</div> */}
              <div className="test-condition">Min - Max Observed values</div>
              <div className="unit">Unit</div>
              <div className="test-condition">Category</div>
              <div className="test-condition">Functional role</div>
              <div className="test-condition">Description</div>
              <div className="cost">Cost</div>
              <div className="cost">Cost Unit</div>
              {/* <div className="cost">Lower bound</div>
              <div className="cost">Upper bound</div> */}
              <div className="test-condition">Values</div>
              <div className="test-condition">Priority of outcome</div>
            </div>
            {dataAggrgatedList?.map((data: IRawDataAggregated) => (
              <ProjectRawDataAggregatedRow
                data={data}
                key={data.id}
                addMode={!data.variable ? true : false}
                editDataListener={editColumn}
                deleteDataListener={deleteColumn}
                projectHasData={projectHasData}
              />
            ))}
          </div>
          {!projectHasData && (
            <div className="add-inputs-box">
              <Button
                icon={<PlusOutlined />}
                onClick={addNewColumn}
                className="add-input-button"
              >
                Add new
              </Button>
            </div>
          )}
        </div>
      }
    </div>
  );
};
