/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { Modal, Input } from 'antd';

type SimpleInputModalProps = {
  //   fieldNames: String[];
  initialValue?: string;
  title?: string;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (val: string) => void;
};

/**
 * Simple modal that takes in a list of parameters and will
 * show a text input for each and return the submitted text.
 *
 * TODO: Right now only supports one field
 *
 * @returns  SimpleInputTextModal
 */
export const SimpleInputTextModal = ({
  onCancel,
  onSubmit,
  title,
  initialValue,
  visible,
}: SimpleInputModalProps) => {
  const [value, setValue] = useState(initialValue ?? '');
  return (
    <Modal
      title={title ?? ''}
      open={visible}
      onCancel={onCancel}
      destroyOnClose
      onOk={() => {
        onSubmit(value);
      }}
    >
      <Input
        onChange={e => setValue(e.currentTarget.value)}
        defaultValue={initialValue}
      />
    </Modal>
  );
};
