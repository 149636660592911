import {
  Model,
  Project,
  Outcome,
  VariableType,
  JobStatus,
} from '@prisma/client';
import {
  MlApiJobStatus,
  MlApiJobStatusMap,
  TargetVariableType,
} from '../types/mlapi.types';
import { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';

export function formatDecimal(num: string | number | undefined = 0): string {
  const parsedNum = Number(num);
  return Number(parsedNum === Number.NaN ? 0 : parsedNum).toFixed(2);
}

/**
 * Returns target variables and it's type from the Outcomes table.
 *  If they are not set, and a project is passed with the model, the dependent variable will be used
 *
 * @param model model to get target variables from
 * @returns Array<TargetVariableType>
 */

export const getTargetVariablesWithType = (
  model: Model & { project?: Project; Outcomes: Outcome[] }
): Array<TargetVariableType> => {
  const targetVariables = [
    ...model.Outcomes?.map((o: Outcome) => ({
      name: o.targetVariable,
      type: o.type,
      levels: o.values,
    })),
  ];

  if (targetVariables.length === 0 && model.project) {
    return [
      {
        name: model.project.dependentVariable ?? '',
        type: VariableType.NUMERIC,
      },
    ];
  }

  return targetVariables;
};

/**
 * Returns target variables from outcomes table.
 *  If they are not set, and a project is passed with the model, the dependent variable will be used
 *
 * @param model model to get target variables from
 * @returns a string array of target variables
 */
export const getTargetVariables = (
  model: Model & { project?: Project; Outcomes: Outcome[] }
): Array<string> => {
  return getTargetVariablesWithType(model).map(tv => tv.name);
};

/**
 * This function is used to format Sleepy Dragon related enums
 * to the format expected by the Lambda function
 *
 * @param string A string that can have multiple words separated by an underscore
 *
 * @returns
 */
export const splitAndCapitalize = (string: string): string => {
  const words = string.toLowerCase().split('_');
  const [firstWord] = words;
  const capitalizedFirstWord =
    firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
  return (
    capitalizedFirstWord +
    (words.length > 1 ? ' ' + words.slice(1).join(' ') : '')
  );
};

/**
 *
 * @param input A string that may or may not include a leading slash
 *
 * @returns a string without a leading slash
 */
export const stripLeadingSlash = (input: string): string => {
  return input.startsWith('/') ? input.substring(1) : input;
};

/**
 * This function is used to format a human readable string into a
 * format safe for a S3 directory name
 *
 * @param input A string that may contain spaces or non-alphanumeric characters
 * @returns a string without any non-alphanumeric characters (except for dashes and underscores)
 * Replaces all spaces with a dash
 */
export const transformStringToDirectoryName = (input: string): string => {
  // Replace spaces with underscores
  let transformedString = input.replace(/ /g, '-');

  // Remove non-alphanumeric characters except dashes and underscores
  transformedString = transformedString.replace(/[^a-zA-Z0-9-_]/g, '');

  // Convert to lowercase
  transformedString = transformedString.toLowerCase();

  return transformedString;
};

/**
 *
 * @param length length of the random string
 * @returns a random alphanumeric string
 */
export const generateRandomString = (length: number): string => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
};

/**
 *
 * @param apiStatus
 * @param jobId
 * @param jobType
 * @returns a JobStatus type converted from a MlApiJobStatus
 */
export const mapMlApiJobStatus = (
  apiStatus: MlApiJobStatus,
  jobId: string,
  jobType: 'DESIGN' | 'OPTIMIZATION' | 'PROJECT_JOB' | 'PRIOR_BUILDING'
): JobStatus => {
  const status = MlApiJobStatusMap.get(apiStatus);
  if (!status) {
    throw new Error(
      `[${jobType}] Failed to map status ${apiStatus} for ID ${jobId}`
    );
  }
  return status;
};

export const getHumanReadableObjectDifferences = (
  objA: Record<string, any>,
  objB: Record<string, any>
) => {
  if (isEqual(objA, objB)) {
    return [];
  }

  return differenceWith(toPairs(objA), toPairs(objB), (pair1, pair2) => {
    return isEqual(pair1[0], pair2[0]) && isEqual(pair1[1], pair2[1]);
  });
};

/**
 *
 * @param input
 * @returns A key up to 4 characters using the first letter of each word in the input
 */
export const createKeyFromString = (input: string): string => {
  // Split the input string into words
  const words = input.split(' ');

  // Extract the first letter of each word and join them into a single string
  const initials = words.map(word => word.charAt(0)).join('');

  // Return the first 4 characters of the initials string
  return initials.substring(0, 4);
};
