import { IFormulationRaw } from '../interfaces/formulationRaw.interfaces';
import {
  IGetProjectOut,
  IProjectCreateOut,
} from '../interfaces/project.interfaces';
import { ProjectRepository } from '../repositories/project.repository';
import { useMutation, useQuery } from 'react-query';

export const useCreateProject = () => {
  const mutation = useMutation((data: IProjectCreateOut) => {
    return ProjectRepository.postCreateProject(data);
  });
  return mutation;
};

export const useFinishProject = () => {
  const mutation = useMutation((data: IProjectCreateOut) => {
    return ProjectRepository.postFinishSetupProject(data);
  });
  return mutation;
};

export const useUploadFormulationRaw = () => {
  const mutation = useMutation((data: IFormulationRaw) => {
    return ProjectRepository.postUploadRawFormulations(data);
  });
  return mutation;
};

export const useDeleteFormulationRaw = () => {
  const mutation = useMutation((data: IFormulationRaw) => {
    return ProjectRepository.deleteRawFormulations(data);
  });
  return mutation;
};

export const useGetProjectById = (
  data: IGetProjectOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectById`, data.organizationId, data.projectId],
    ProjectRepository.getProjectById,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useGetProjectFormulationsRaw = (
  data: IGetProjectOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectFormulationsRaw`, data.organizationId, data.projectId],
    ProjectRepository.getProjectFromulationRaw,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useDuplicateProject = () => {
  const mutation = useMutation((data: any) => {
    return ProjectRepository.postDuplicateProject(data);
  });
  return mutation;
};

export const useGetProjectSummary = (
  data: any
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getProjectSummary`, data.organizationId, data.projectId],
    ProjectRepository.getProjectSummary,
    {
      enabled: !!data.organizationId && !!data.projectId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};