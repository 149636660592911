import { Table, Button } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useSession } from '../../_shared/context';
import React, { useState } from 'react';
import { formatObjectiveText } from '../experiments/experiment-detail/experiment-detail-util';

export const ExplanationObjectivesTable = () => {
  const { currentProject } = useSession();
  const objectives = currentProject?.objectives;
  const outcomes = currentProject?.activeModel?.outcomes;
  const [expanded, setExpanded] = useState(false);

  if (!objectives || !outcomes) {
    return null;
  }

  const columns = [
    { title: 'Formula Targets', dataIndex: 'category', key: 'category' },
    { title: '', dataIndex: 'target', key: 'target' },
    { title: '', dataIndex: 'value', key: 'value' },
  ];

  const dataSource = objectives.map(o => {
    const outcome = outcomes.find(
      outc => outc.targetVariable === o.targetVariable
    );
    return {
      category: outcome?.category?.name,
      target: o.targetVariable,
      value: formatObjectiveText(o),
    };
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ marginLeft: 42, marginBottom: 20 }}>
      <Table
        dataSource={expanded ? dataSource : dataSource.slice(0, 5)}
        columns={columns}
        size="small"
        pagination={false}
      />
      {dataSource.length > 5 && (
        <Button
          size="small"
          onClick={toggleExpand}
          type="link"
          style={{ maxWidth: 100 }}
          icon={expanded ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
        >
          {expanded ? `Show Less` : `Show More (${dataSource.length})`}
        </Button>
      )}
    </div>
  );
};
