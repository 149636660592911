/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Colors } from '../../../../iso/colors';

export const EditTable = styled.table`
  width: 100%;

  th {
    color: #888;
    font-weight: normal;
    font-size: 12px;
    padding: 6px 2px;
    border-bottom: 2px solid #f1eff5;
  }

  td {
    padding: 4px;
    border-bottom: 1px solid #f1eff5;
  }

  tr:nth-child(even) td {
    background-color: ${Colors['#FBFAFD']};
  }

  tr:nth-child(odd) td {
    background-color: ${Colors.WHISPER};
  }
`;
