import { IngredientList } from '@prisma/client';
import { emptyIngredient } from '@root/pages/project-setup/components/project-inputs-table/project-inputs-table';
import {
  Button,
  Divider,
  Input,
  InputNumber,
  message,
  notification,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  IngredientFromGroup,
  IngredientGroupData,
  IngredientGroupFormData,
} from '../ingredients-group.interfaces';
import './ingredient-group-form.less';
import _ from 'lodash';

interface IngredientGroupFormProps {
  ingredientsList: {
    id: number;
    name: string;
  }[];
  addGroup: (element?: IngredientGroupData) => void;
  element: IngredientGroupFormData;
}

export const IngredientGroupForm: React.FC<IngredientGroupFormProps> = ({
  ingredientsList,
  element,
  addGroup,
}) => {
  const [validData, setValidData] = useState(false);
  const [newData, setNewData] = useState<IngredientGroupFormData>({
    ...element,
  });
  const [selectedIngredients, setSelectedIngredients] = useState<
    IngredientFromGroup[]
  >([]);
  const [newIngredientList, SetNewIngredientList] = useState<
    { id: number; name: string }[]
  >([]);

  useEffect(() => {
    if (ingredientsList.length > 0) {
      SetNewIngredientList(ingredientsList);
    }
  }, [ingredientsList]);

  const onInputListener = (prop: keyof IngredientGroupFormData) => (
    value: string | number
  ) => {
    setNewData((newData: IngredientGroupFormData) => ({
      ...newData,
      [prop]: value,
    }));
  };

  const onClickAddIngredientToGroup = () => {
    if (!validateAddIngredient()) {
      return;
    }

    const { ingredientId, ingredientName, percentage } = newData;

    if (!ingredientName || !ingredientId || percentage < 0) {
      message.error('Please select an ingredient')
      return;
    }

    setSelectedIngredients([
      ...selectedIngredients,
      {
        ingredientId,
        ingredientName,
        percentage,
      },
    ]);

    setNewData({
      ...newData,
      ingredientId: 0,
      ingredientName: '',
      percentage: 0,
    });

    SetNewIngredientList(newIngredientList.filter(i => i.id !== ingredientId));
  };

  const validateAddIngredient = () => {

    const percentages = selectedIngredients.map(i => i.percentage);

    if (_.sum(percentages) + newData.percentage > 100) {
      notification.error({
        message: `Error adding ${newData.ingredientName}`,
        description: <>{'Percentage sum is more than 100'}</>,
      });
      return false;
    }

    return true;
  };

  const validateAddGroup = () => {
    if (newData.lowerBound > newData.upperBound) {
      notification.error({
        message: `Error adding ${newData.name}`,
        description: <>{'Lower Bound is higher than Upeer Bound'}</>,
      });
      return false;
    }

    return true;
  };

  return (
    <div className="margin-top-1rem">
      <Input
        placeholder="Group Name"
        value={newData.name}
        onChange={e => onInputListener('name')(e.target.value)}
      />
      {/*<div className="margin-top-1rem percentage-form-container">
        <span>From</span>
        <InputNumber
          value={newData.lowerBound}
          onChange={e => onInputListener("lowerBound")(e ?? 0)}
        />
        <span>To</span>
        <InputNumber
          value={newData.upperBound}
          onChange={e => onInputListener("upperBound")(e ?? 0)}
        />
      </div>*/}
      <div className="margin-top-1rem">
        <div className="percentage-form-container">
          <Select
            value={newData.ingredientName}
            options={newIngredientList.map(i => ({
              value: i.id,
              label: i.name,
            }))}
            onSelect={(e, o) => {
              onInputListener('ingredientId')(e);
              onInputListener('ingredientName')(o.label);
            }}
            style={{
              width: '50%',
            }}
          />
          <InputNumber
            min={0}
            value={newData.percentage}
            onChange={e => onInputListener('percentage')(e ?? 0)}
            max={100}
          />
          <span>% in group</span>
        </div>
        <div className="actions-group-container margin-top-1rem">
          <Button
            onClick={onClickAddIngredientToGroup}
            disabled={newData.percentage <= 0 || newData.ingredientId == 0}
            type="primary"
            className="base-button margin-bottom-1rem"
          >
            {`Add Ingredient to group`}
          </Button>
          {selectedIngredients.length > 0 && <>Ingredients in this Group:</>}
          {selectedIngredients.map(i => (
            <div className="actions-group-container margin-bottom-1rem ">
              {`${i.ingredientName} ${i.percentage} %`}
            </div>
          ))}
          <Button
            onClick={() => {
              if (!validateAddGroup()) {
                return;
              }

              addGroup({
                name: newData.name,
                ingredient: selectedIngredients,
                lowerBound: 0,
                upperBound: 100,
              });
            }}
            disabled={selectedIngredients.length === 0}
            type="primary"
            className="margin-top-1rem base-button"
          >
            Create Group
          </Button>
        </div>
      </div>
      <Divider></Divider>
    </div>
  );
};
