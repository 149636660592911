import {
  InfoCircleOutlined,
  InfoCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import {
  Button,
  Radio,
  Select,
  Space,
  Switch,
  InputNumber,
  Typography,
  Tooltip,
  Checkbox,
  Divider,
  Input,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { useExploration, useSession } from '../../../../_shared/context';
import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { css } from '@emotion/css';
import { useDesign } from '../../../../_shared/context/design-context';
import './constraint-row.less';
import { RadioChangeEvent } from 'antd/lib';
import { IngredientListItem, IngredientType } from '../../../../_shared/hooks';
import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  IngredientList,
} from '../../../../../../__generated__/globalTypes';
import Icon from '@ant-design/icons/lib/components/Icon';
import { EditIcon } from '../../../../_shared/style';
import { IngredientFromGroup } from '../../../../components/project/ingredients-group/ingredients-group.interfaces';
import { emptyIngredient } from '../../../../components/workspaces/adaptive-learning/design-utils';
import { IngredientGroup } from '@root/components/project/ingredients-group/ingredients-group';

const { Text } = Typography;
export const ConstraintRow = ({
  ingredient,
  addConstraint,
  editConstraint,
  constraint,
  ingredientsSelectedGroup,
  setConstraintToEdit,
  edit,
}: {
  ingredient?: IngredientListItem;
  addConstraint?: (c: any) => void;
  editConstraint?: (c: any) => void;
  constraint: ConstraintInputType;
  ingredientsSelectedGroup?: IngredientFromGroup[];
  setConstraintToEdit: (c: any) => void;
  edit: boolean;
}) => {
  const [operator, setOperator] = useState('>');

  const [value, setValue] = useState<number | null | undefined>(undefined);
  const [lowerBound, setLowerBound] = useState<number | null | undefined>(
    undefined
  );
  const [upperBound, setUpperBound] = useState<number | null | undefined>(
    undefined
  );
  const [variables, setVariables] = useState<any>([]);
  const [
    ingredientsFromGroupFormatted,
    setIngredientsFromGroupFormatted,
  ] = useState<IngredientType[] | undefined>(undefined);

  const newConstraintRef = useRef<HTMLDivElement>(null);
  const [operators, setOperators] = useState([
    { value: '<', label: '<' },
    { value: '>', label: '>' },
    { value: '=', label: '=' },
    { value: 'range', label: 'range' },
  ]);

  const validateType = (type: string) => {
    switch (type) {
      case '<':
      case '>':
      case 'range':
        return ConstraintType.RANGE;
      case '=':
        return ConstraintType.EQUALITY;
      case 'some':
        return ConstraintType.COUNT;
      case 'all':
        return ConstraintType.COUNT;
      default:
        return ConstraintType.EQUALITY;
    }
  };

  const handleAddNewConstraint = () => {
    let lowerBounds = undefined;
    let upperBounds = undefined;
    if (addConstraint) {
      const type = validateType(operator);
      if (type !== ConstraintType.RANGE) {
        if (operator == 'some') {
          lowerBounds = 1;
          upperBounds = variables.length;
        } else if (operator == 'all') {
          lowerBounds = variables.length;
          upperBounds = variables.length;
        }
        setLowerBound(undefined);
        setUpperBound(undefined);
      } else {
        if (operator === '<') {
          upperBounds = value;
          lowerBounds = ingredient?.lowerLimit;
        } else if (operator === '>') {
          upperBounds = ingredient?.upperLimit;
          lowerBounds = value;
        } else if (operator === 'range') {
          upperBounds = upperBound;
          lowerBounds = lowerBound;
        }
        setValue(undefined);
      }

      const c: Constraint = {
        id: '',
        name: constraint.name,
        constraintType: type,
        lowerBounds: lowerBounds,
        upperBounds: upperBounds,
        coefficients:
          type == ConstraintType.RANGE || ConstraintType.COUNT
            ? [{ name: ingredient?.ingredient.name, value: 1 }]
            : [],
        values:
          type == ConstraintType.EQUALITY
            ? [{ name: ingredient?.ingredient.name, value: value }]
            : [],
        variables: type === ConstraintType.COUNT ? variables : [],
        ingredientCompositionId: null,
        iterationId: null,
      };
      addConstraint(c);
    }
  };

  const handleEditConstraint = () => {
    let lowerBounds = undefined;
    let upperBounds = undefined;
    if (editConstraint) {
      const type = validateType(operator);
      if (type !== ConstraintType.RANGE) {
        if (type == ConstraintType.COUNT) {
          lowerBounds = constraint?.lowerBounds;
          upperBounds = constraint?.upperBounds;
          if (lowerBound == upperBounds) {
            setOperator('all');
          } else {
            setOperator('some');
          }
        }
      } else {
        if (operator === '<') {
          upperBounds = value;
          lowerBounds = ingredient?.lowerLimit;
        } else if (operator === '>') {
          upperBounds = ingredient?.upperLimit;
          lowerBounds = value;
        } else if (operator === 'range') {
          upperBounds = upperBound;
          lowerBounds = lowerBound;
        }
        setValue(undefined);
      }
      const c: Constraint = {
        id: '',
        name: constraint.name,
        constraintType: type,
        lowerBounds: lowerBounds,
        upperBounds: upperBounds,
        coefficients:
          type == ConstraintType.RANGE
            ? [
              {
                name: ingredient?.ingredient.name,
                value: 1,
                operator: operator,
              },
            ]
            : [],
        values:
          type == ConstraintType.EQUALITY
            ? [{ name: ingredient?.ingredient.name, value: value }]
            : [],
        //variables: type == ConstraintType.COUNT ? [{ name: ingredient.ingredient.name, value: value }] : [],,
        ingredientCompositionId: null,
        iterationId: null,
      };
      editConstraint(c);
    }
  };

  const handleOperator = (e: string) => {
    //setOperator(e);
    if (constraint) {
      if (e == '>' || e == '<' || e == 'range') {
        setConstraintToEdit({
          ...constraint,
          constraintType: ConstraintType.RANGE,
        });
      } else if (e == '=') {
        setConstraintToEdit({
          ...constraint,
          constraintType: ConstraintType.EQUALITY,
        });
      } else if (e == 'some' || e == 'all') {
        setConstraintToEdit({
          ...constraint,
          constraintType: ConstraintType.COUNT,
        });
        setVariables(constraint.variables);
      }
      setOperator(e);
    }
  };

  const handleValue = (e: number) => {
    setValue(e);
  };

  useEffect(() => {
    if (ingredientsSelectedGroup) {
      setOperators([
        ...operators,
        { value: 'some', label: 'some' },
        { value: 'all', label: 'all' },
      ]);
      const formatedIngredients: IngredientType[] =
        ingredientsSelectedGroup?.map((e: IngredientFromGroup) => {
          const toAdd = { ...emptyIngredient };
          toAdd.ingredient = {
            id: e.ingredientId,
            name: e.ingredientName,
          };
          return toAdd;
        }) ?? [];

      setIngredientsFromGroupFormatted(formatedIngredients);
    }
  }, [ingredientsSelectedGroup]);

  useEffect(() => {
    if (edit) {
      switch (constraint.constraintType) {
        case ConstraintType.EQUALITY:
          setOperator('=');
          break;
        case ConstraintType.RANGE:
          setOperator(constraint.coefficients?.[0].operator);
          break;
        case ConstraintType.COUNT:
          if (constraint.lowerBounds == constraint.upperBounds) {
            setOperator('all');
          } else {
            setOperator('some');
          }
          break;
      }
    }
  }, [edit]);

  useEffect(() => {
    if (edit && constraint.constraintType === ConstraintType.RANGE) {
      setLowerBound(constraint.lowerBounds);
      setUpperBound(constraint.upperBounds);
    }
  }, [edit, constraint]);


  return (
    <div id="constraint-row" style={{ maxWidth: '100%' }}>
      <div className="input-name">
        <span>Name</span>
        <Input
          value={constraint.name ?? ''}
          onChange={e =>
            setConstraintToEdit({
              ...constraint,
              name: e.target.value,
            })
          }
        />
      </div>
      <div className="container-row">
        <div className="ingredient-name">{constraint.name}</div>
        <div className="operator">
          <Select
            className="operations"
            value={operator}
            onChange={e => handleOperator(e)}
            options={operators}
          />
        </div>
        <div className="constraint-value">
          {operator == '>' && (
            <InputNumber<number>
              className="constraint-value-input"
              value={constraint.lowerBounds}
              min={ingredient?.lowerLimit!}
              max={ingredient?.upperLimit!}
              formatter={value => `${value}%`}
              parser={value => (value?.replace('%', '') as unknown) as number}
              //onChange={(e: number) => setValue(e)}
              onChange={e => {
                const existingCoefficients =
                  constraint?.coefficients?.[0] ?? {};
                e !== null &&
                  setConstraintToEdit({
                    ...constraint,
                    lowerBounds: Number(e),
                    upperBounds: ingredient?.upperLimit,
                    coefficients: [
                      {
                        ...existingCoefficients,
                        value: 1,
                        name: ingredient?.ingredient.name,
                        operator: '>',
                      },
                    ],
                    values: [],
                  });
              }}
            />
          )}
          {operator == '<' && (
            <InputNumber<number>
              className="constraint-value-input"
              value={constraint.upperBounds}
              min={ingredient?.lowerLimit!}
              max={ingredient?.upperLimit!}
              formatter={value => `${value}%`}
              parser={value => (value?.replace('%', '') as unknown) as number}
              //onChange={(e: number) => setValue(e)}
              onChange={e => {
                const existingCoefficients =
                  constraint?.coefficients?.[0] ?? {};
                e !== null &&
                  setConstraintToEdit({
                    ...constraint,
                    lowerBounds: ingredient?.lowerLimit,
                    upperBounds: Number(e),
                    coefficients: [
                      {
                        ...existingCoefficients,
                        value: 1,
                        name: ingredient?.ingredient.name,
                        operator: '<',
                      },
                    ],
                    values: [],
                  });
              }}
            />
          )}
          {operator == '=' && (
            <InputNumber
              // value={constraintToEdit?.values?.[0]?.value}
              style={{ width: '100%' }}
              placeholder={(() => {
                return ingredient
                  ? `${ingredient?.lowerLimit} - ${ingredient?.upperLimit}`
                  : '';
              })()}
              onChange={e => {
                const existingValue = constraint?.values?.[0] ?? {};
                setConstraintToEdit({
                  ...constraint,
                  values: [
                    {
                      ...existingValue,
                      value: e,
                      name: ingredient?.ingredient.name,
                    },
                  ],
                  coefficients: [],
                });
              }}
            />
          )}
          {operator == 'range' && (
            <>
              <InputNumber<number>
                className="constraint-value-input"
                value={lowerBound}
                min={Math.max(0, ingredient?.lowerLimit!)}
                max={Math.min(100, ingredient?.upperLimit!)}
                formatter={value => `${value}%`}
                parser={value => (value?.replace('%', '') as unknown) as number}
                onChange={e => {
                  const existingCoefficients =
                    constraint?.coefficients?.[0] ?? {};
                  e !== null &&
                    setConstraintToEdit({
                      ...constraint,
                      lowerBounds: Number(e),
                      coefficients: [
                        {
                          ...existingCoefficients,
                          value: 1,
                          name: ingredient?.ingredient.name,
                          operator: 'range',
                        },
                      ],
                      values: [],
                    });
                }}
              />
              to
              <InputNumber<number>
                className="constraint-value-input"
                value={upperBound}
                min={Math.max(0, ingredient?.lowerLimit!)}
                max={Math.min(100, ingredient?.upperLimit!)}
                formatter={value => `${value}%`}
                parser={value => (value?.replace('%', '') as unknown) as number}
                onChange={e => {
                  const existingCoefficients =
                    constraint?.coefficients?.[0] ?? {};
                  e !== null &&
                    setConstraintToEdit({
                      ...constraint,
                      upperBounds: Number(e),
                      coefficients: [
                        {
                          ...existingCoefficients,
                          value: 1,
                          name: ingredient?.ingredient.name,
                          operator: 'range',
                        },
                      ],
                      values: [],
                    });
                }}
              />
            </>
          )}

          {(operator == 'some' || operator == 'all') && (
            <div>
              <IngredientSearch
                className="search-ingredient"
                onSelect={(i: string) => {
                  const variablesToCopy = [...variables, i];
                  setVariables(variablesToCopy);
                  if (operator == 'some') {
                    setConstraintToEdit({
                      ...constraint,
                      lowerBounds: 1,
                      upperBounds: variablesToCopy.length,
                      variables: [...constraint?.variables!, i],
                    });
                  } else {
                    setConstraintToEdit({
                      ...constraint,
                      lowerBounds: variablesToCopy.length,
                      upperBounds: variablesToCopy.length,
                      variables: [...constraint?.variables!, i],
                    });
                  }
                }}
                onDeselect={(i: string) => {
                  const variablesToCopy = variables.filter(
                    (val: any) => val !== i
                  );
                  setVariables(variablesToCopy);
                  if (operator == 'some') {
                    setConstraintToEdit({
                      ...constraint,
                      lowerBounds: 1,
                      upperBounds: variablesToCopy.length,
                      values: [],
                      coefficients: [],
                      variables: constraint?.variables?.filter(
                        val => val !== i
                      ),
                    });
                  } else {
                    setConstraintToEdit({
                      ...constraint,
                      lowerBounds: variablesToCopy.length,
                      upperBounds: variablesToCopy.length,
                      coefficients: [],
                      variables: constraint?.variables?.filter(
                        val => val !== i
                      ),
                    });
                  }
                }}
                ingredients={ingredientsFromGroupFormatted!}
                defaultValue={variables}
                mode="multiple"
                additionalCss={css`
                  .ant-select-selection-item {
                    background: #e6f2ff;
                    color: #017aff;
                    margin: 8px;
                  }
                `}
              />
            </div>
          )}
        </div>
      </div>

      {/* <div className='action'>
        {!constraint && <Button icon={<PlusOutlined />} className="save-constraint" onClick={handleAddNewConstraint}>
          Add
        </Button>}
        {constraint && <Button icon={<Icon component={EditIcon} />} className="save-constraint" onClick={handleEditConstraint}>
          Edit
        </Button>}
      </div> */}
    </div>
  );
};
