/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from 'react';

import Icon, {
  ArrowRightOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { css, jsx } from '@emotion/react';

import { Alert, Button, Spin, Tooltip } from 'antd';
import { ApolloError } from '@apollo/client';
import { SimulationResultTable } from '../../predicted-outcomes/simulation-result-table';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { ContentContainer } from '../content-container/content-container.component';
import { BaseProject, useIngredients } from '../../../../../_shared/hooks';
import {
  AddIcon,
  AlertIcon,
  centerVerticallyFlexStyle,
  Colors,
  rightHorizontallyFlexStyle,
} from '../../../../../_shared/style';
import { projectById_project_ingredientList } from '../../../../../_shared/hooks/__generated__/projectById';
import { FormulationSider } from '../formulation-sider/formulation-sider.component';
import { NullableConstraintType, OutOfBoundAlert } from '../types';
import {
  ProjectFeature,
  SimulationFormulationInputType,
} from '../../../../../../../__generated__/globalTypes';
import { createFormulationDataFromProductVersions } from '../../../../../_shared/utils/util';
import { CertaintyVsOutcome } from '../adaptive-doe';
import { SimulationProductVersionResultType } from '../../context/types';

const cssCircleWithText = css`
  margin-left: 8px;
  width: 1px;
  height: 1px;
  background: #0a2025;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 15px;
  font-size: smaller;
`;

const getOutOfBoundAlerts = (
  optimizedProduct: SimulationProductVersionResultType,
  constraints: Map<string, NullableConstraintType>,
  ingredientById: Map<string, projectById_project_ingredientList>
): Array<OutOfBoundAlert> => {
  const alertMessages: Array<OutOfBoundAlert> = [];

  const ingredientQuantities =
    optimizedProduct.productVersion.formulation?.quantities;

  if (ingredientQuantities)
    Object.keys(ingredientQuantities).forEach(id => {
      const ingredient = ingredientById.get(id);
      const ingredientValue = ingredientQuantities[id];
      const ingredientValueNum = Number(ingredientValue);
      const constraint = constraints.get(ingredient?.ingredient.name ?? '');

      if (ingredient && ingredientValue && constraint) {
        const ingredientName = ingredient.ingredient.name;
        const constraintValue = constraint.value;
        const {
          lowerBounds: constraintLowerBound,
          upperBounds: constraintUpperBound,
        } = constraint.bounds ?? {};

        if (constraintValue && constraintValue !== ingredientValue) {
          // Test condition
          alertMessages.push({
            ingredientName,
            message: `is ${ingredientValue} but expected ${constraintValue}`,
          });
        } else if (constraintLowerBound && constraintUpperBound) {
          let deviationValue: number | undefined;
          let deviationSymbol = '';
          if (ingredientValueNum < constraintLowerBound) {
            deviationValue = Math.abs(
              (ingredientValueNum - constraintLowerBound) / constraintLowerBound
            );
            deviationSymbol = '-';
          } else if (ingredientValueNum > constraintUpperBound) {
            deviationValue =
              (ingredientValueNum - constraintUpperBound) /
              constraintUpperBound;
            deviationSymbol = '+';
          }

          if (deviationValue !== undefined) {
            alertMessages.push({
              ingredientName,
              message: `is ${deviationSymbol}${Number(
                deviationValue * 100
              ).toFixed(2)}% outside of the requested constraints.`,
            });
          }
        }
      }
    });

  return alertMessages;
};

export const CalculateOptimizations = ({
  constraints,
  isRunning,
  optimizedProducts,
  error,
  showModal,
  runSimulationWithPackage,
  isSimulationRunning,
  currentProject,
}: {
  isRunning: boolean;
  optimizedProducts?: Array<SimulationProductVersionResultType>;
  constraints: Map<string, NullableConstraintType>;
  error?: ApolloError;
  showModal: (show: boolean) => void;
  runSimulationWithPackage: (
    simulationPackage: Array<SimulationFormulationInputType>
  ) => void;
  isSimulationRunning: boolean;
  currentProject?: BaseProject;
}) => {
  const {
    simulationProductVersions,
    outcomes,
    setSimulationProductVersions,
  } = useWorkspace();
  const { ingredientById } = useIngredients();

  const [benchmarkProduct] = useState(
    simulationProductVersions?.find(s => s.productVersion.isBenchmark)
  );
  const [removedProducts, setRemovedProducts] = useState<Array<string>>([]);

  const removeProductFromWorkspace = (
    productToRemove: SimulationProductVersionResultType
  ) => {
    if (!removedProducts.includes(productToRemove.productVersion.name))
      setRemovedProducts([
        ...removedProducts,
        productToRemove.productVersion.name,
      ]);
  };

  const addProductToWorkspace = (
    productToRemove: SimulationProductVersionResultType
  ) => {
    const productIndex = removedProducts.indexOf(
      productToRemove.productVersion.name
    );
    if (productIndex !== -1) {
      const newRemovedProducts = [...removedProducts];
      newRemovedProducts.splice(productIndex, 1);
      setRemovedProducts(newRemovedProducts);
    }
  };

  const onGoToWorkspace = async () => {
    if (removedProducts.length > 0) {
      const productsToKeep = simulationProductVersions.filter(
        v => !removedProducts.includes(v.productVersion.name)
      );
      if (productsToKeep.every(p => p.productVersion.isBenchmark === false)) {
        productsToKeep[0].productVersion.isBenchmark = true;
      }
      const simulationPackage = createFormulationDataFromProductVersions(
        productsToKeep
      );
      setSimulationProductVersions(productsToKeep);
      runSimulationWithPackage(simulationPackage);
    }
    showModal(false);
  };

  let View = PlaceholderMessage;
  const numOfOptimizations = optimizedProducts?.length ?? 0;

  if (error) {
    View = ErrorMessage;
  } else if (isRunning) {
    View = IsRunningMessage;
  } else if (outcomes && optimizedProducts && numOfOptimizations > 0) {
    const adaptiveDoeFeatureEnabled = currentProject?.features.some(
      f => f.feature === ProjectFeature.ADAPTIVE_DOE
    );
    const optimizationBoxes: Array<ReactElement> = [];
    const optimizedProductLabelMap = new Map<string, string>();

    optimizedProducts.forEach((optimizedProduct, index) => {
      const productLabel = String.fromCharCode(65 + index);
      optimizedProductLabelMap.set(
        optimizedProduct.productVersion.name,
        productLabel
      );
      const outofBoundAlerts = getOutOfBoundAlerts(
        optimizedProduct,
        constraints,
        ingredientById
      );
      const productIsRemoved = removedProducts.includes(
        optimizedProduct.productVersion.name
      );
      // 1. Can remove all new optimization in an existing workspace
      // 2. Must have at least 1 optimization added to new/blank workspace
      const disableRemoveProduct =
        simulationProductVersions.length - optimizedProducts.length === 0 &&
        removedProducts.length === optimizedProducts.length - 1;

      optimizationBoxes.push(
        <div
          key={optimizedProduct.productVersion.name}
          css={css`
            display: flex;
          `}
        >
          <ContentContainer
            title={
              <div
                css={css`
                  font-weight: normal;
                  display: flex;
                `}
              >
                {optimizedProduct.productVersion.name}
                {adaptiveDoeFeatureEnabled && (
                  <div css={cssCircleWithText}>{productLabel}</div>
                )}
              </div>
            }
          >
            <div
              css={css`
                padding: 20px 0;
              `}
            >
              {outofBoundAlerts.length > 0 && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin: 0 20px 20px;
                    padding: 15px;
                    border: 1px solid #dedede;
                    filter: drop-shadow(0px 0px 1px rgba(7, 7, 7, 0.0001));
                    border-radius: 5px;
                  `}
                >
                  <AlertIcon />
                  <div
                    css={css`
                      padding-left: 15px;
                    `}
                  >
                    {outofBoundAlerts.map(alert => {
                      return (
                        <div key={`Alert-${alert.ingredientName}`}>
                          <b>{alert.ingredientName}</b> {alert.message}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <SimulationResultTable
                outcomes={outcomes}
                product={optimizedProduct}
                benchmark={benchmarkProduct}
                hideExplainImpactLink
              />
              <div
                css={[
                  rightHorizontallyFlexStyle,
                  css`
                    text-align: right;
                    margin-top: 25px;
                  `,
                ]}
              >
                {productIsRemoved ? (
                  <Button
                    css={centerVerticallyFlexStyle}
                    type="link"
                    onMouseUp={() => addProductToWorkspace(optimizedProduct)}
                  >
                    <Icon component={AddIcon} /> Add to workspace
                  </Button>
                ) : (
                  <Tooltip
                    title="Must have at least 1 optimization in workspace"
                    trigger={disableRemoveProduct ? 'hover' : ''}
                  >
                    <Button
                      css={centerVerticallyFlexStyle}
                      type="link"
                      onMouseUp={() =>
                        removeProductFromWorkspace(optimizedProduct)
                      }
                      disabled={disableRemoveProduct}
                    >
                      Remove from workspace x
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
          </ContentContainer>
          {optimizedProduct.productVersion.formulation?.quantities && (
            <FormulationSider
              formulation={
                optimizedProduct.productVersion.formulation?.quantities
              }
              benchmarkFormulation={
                benchmarkProduct?.productVersion.formulation?.quantities
              }
            />
          )}
        </div>
      );
    });

    View = () => (
      <div
        css={css`
          min-width: 50%;
          margin: 19px 0px;
        `}
      >
        {adaptiveDoeFeatureEnabled && (
          <div
            css={css`
              justify-content: center;
              display: flex;
            `}
          >
            <CertaintyVsOutcome
              currentProject={currentProject}
              outcomes={outcomes}
              optimizedProducts={optimizedProducts}
              optimizedProductLabelMap={optimizedProductLabelMap}
            />
          </div>
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fcfcfc;
            margin: 0 -25px;
            padding: 25px;
            border: 1px solid #cccccc20;
            border-left: none;
          `}
        >
          <span>
            {numOfOptimizations} optimization{numOfOptimizations > 1 && 's'}{' '}
            added to workspace
          </span>
          <Button
            type="primary"
            onClick={onGoToWorkspace}
            loading={isSimulationRunning}
          >
            Go to workspace <ArrowRightOutlined />
          </Button>
        </div>
        {optimizationBoxes}
      </div>
    );
  }

  return (
    <div
      className="optModalColumn"
      css={css`
        padding-left: 25px;
        width: 66%;
      `}
    >
      <span className="optimizationStepNumber">2</span>
      <span className="headerText">Recommended formulation</span>
      <View />
    </div>
  );
};

const IsRunningMessage = () => (
  <div
    css={css`
      width: 95%;
      margin: 19px 0px;
    `}
  >
    <p>Calculating the optimal formulations for your goal.</p>
    <Spin
      indicator={
        <Loading3QuartersOutlined
          style={{ fontSize: 200, color: `${Colors.ARSENIC}` }}
          spin
        />
      }
    />
  </div>
);

//TODO Put actual error content
const ErrorMessage = () => (
  <div
    css={css`
      margin-top: 25px;
    `}
  >
    <Alert
      message="Error"
      description="This optimization has failed."
      type="error"
      showIcon
    />
  </div>
);

const PlaceholderMessage = () => (
  <div
    css={css`
      width: 95%;
      margin: 19px 0px;
    `}
  >
    <p>Your optimized formulations would appear here.</p>
    <p>
      Update outcome targets, constraints, and limitations on the left to create
      optimiztations
    </p>
  </div>
);
