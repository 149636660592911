/** @jsxImportSource @emotion/react */
import { Modal, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { useDesign } from '../../../../_shared/context/design-context';
import { useSession } from '../../../../_shared/context';
import { useNavigate } from 'react-router-dom';

type DesignRunningModalProps = {
  open: boolean;
};
export const DesignRunningModal = (props: DesignRunningModalProps) => {
  const { cancelRunningDesign } = useDesign();
  const navigate = useNavigate();
  const { currentProject, setSelectedIterationId } = useSession();

  return (
    <Modal
      title={null}
      footer={null}
      centered
      closable={false}
      open={props.open}
      width={400}
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        <div>
          <span>
            {' '}
            <SettingOutlined
              style={{
                fontSize: '100px',
                backgroundColor: 'grey',
                padding: '20px',
                borderRadius: '50%',
              }}
            />
          </span>
        </div>

        <h2>Adaptive Learning is Running</h2>
        <p>
          Adaptive Learning is running and recommendations are being generated
          by AI.
        </p>
        <p>
          <strong>Did you know? </strong> We will send you an email when
          adaptive learning is ready so you don't have to stick around here.
        </p>
        <div
          css={css`
            margin-bottom: 10px;
          `}
        >
          <Button
            type="primary"
            onClick={() => {
              setSelectedIterationId('');
              navigate(`/project/${currentProject?.id}`);
            }}
          >
            Ok, Close Workspace
          </Button>
        </div>
        <div>
          <Button
            css={css`
              color: red;
            `}
            type="link"
            onClick={async () => await cancelRunningDesign()}
          >
            Cancel Adaptive Learning
          </Button>
        </div>
      </div>
    </Modal>
  );
};
