/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Select } from 'antd';

import { useMemo, useState } from 'react';
import {
  Outcome,
  OutcomeInfo,
  SimulationProductVersionResultType,
} from '../../context';
import { CertaintyVsOutcomeChart } from './certainty-vs-outcome-chart.component';
import {
  TargetVariableWitColorType,
  TargetVariableProductOutcomes,
} from './types';
import {
  CategoryDot,
  TargetVariableColors,
  XAxisArrow,
} from '../../../../../_shared/style';
import { BaseProject } from '../../../../../_shared/hooks';
import { getTargetVariablesWithType } from '../../../../../_shared/utils/util';

const CHART_WIDTH = 600;
const CHART_HEIGHT = 240;

export type ComponentParams = {
  currentProject?: BaseProject;
  outcomes: Outcome[];
  optimizedProducts: Array<SimulationProductVersionResultType>;
  optimizedProductLabelMap: Map<string, string>;
};

export const CertaintyVsOutcome = ({
  currentProject,
  outcomes,
  optimizedProducts,
  optimizedProductLabelMap,
}: ComponentParams) => {
  const [targetOutcomeMap, targetVariables] = useMemo(() => {
    const dataMap = new Map<string, Array<TargetVariableProductOutcomes>>();
    const tmpVariables: Array<TargetVariableWitColorType> = [];

    const lenTargetVariableColors = TargetVariableColors.length;
    if (currentProject && currentProject.activeModel)
      getTargetVariablesWithType(
        currentProject.activeModel,
        currentProject
      ).forEach((tv, index) => {
        tmpVariables.push({
          ...tv,
          color: TargetVariableColors[index % (lenTargetVariableColors - 1)],
        });
      });

    outcomes.forEach((o, index) => {
      const targetVarProducts: Array<TargetVariableProductOutcomes> = [];
      optimizedProducts.forEach(spv => {
        const productName = spv.productVersion.name;
        const outcomeInfo: OutcomeInfo | undefined = o.products.get(
          productName
        );
        const label = optimizedProductLabelMap.get(productName);
        if (label && outcomeInfo) {
          targetVarProducts.push({
            label,
            spv,
            outcomeInfo,
          });
        }
      });

      dataMap.set(o.targetVariable, targetVarProducts);
    });

    return [dataMap, tmpVariables];
  }, outcomes);

  const [selectedTarget, setSelectedTarget] = useState<
    TargetVariableWitColorType
  >(targetVariables[0]);
  const updateSelectedTarget = (name: string) => {
    const newTargetVariable = targetVariables.find(tg => tg.name === name);
    if (newTargetVariable) setSelectedTarget(newTargetVariable);
  };

  const targetOutcomes = targetOutcomeMap.get(selectedTarget.name) ?? [];

  return (
    <div
      css={css`
        margin: 25px 0px;
        background: #fbfbfb;
        box-shadow: 0px 1px 10px rgba(3, 25, 30, 0.15);
        border-radius: 5px;
        padding: 30px 20px;
        width: ${CHART_WIDTH +
        40}px; // Account for the left and right 20px padding
      `}
    >
      <div
        css={css`
          margin-left: 20px;
          margin-bottom: 10px;
        `}
      >
        CERTAINTY VS. OUTCOME
      </div>
      <CertaintyVsOutcomeChart
        targetVariable={selectedTarget}
        outcomes={targetOutcomes}
        chartWidth={CHART_WIDTH}
        chartHeight={CHART_HEIGHT}
      />
      <div
        css={css`
          margin-top: 5px;
          margin-left: 60px;
        `}
      >
        <Select
          defaultValue={selectedTarget.name}
          style={{ width: 200 }}
          onChange={updateSelectedTarget}
        >
          {targetVariables.map(target => {
            return (
              <Select.Option value={target.name} key={target.name}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <CategoryDot color={target.color} />
                  <div>{target.name}</div>
                </div>
              </Select.Option>
            );
          })}
        </Select>
        <span style={{ marginLeft: '5px' }}>
          <XAxisArrow />
        </span>
      </div>
    </div>
  );
};
