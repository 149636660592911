/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { Button, Modal } from 'antd';

import {
  getDisplayDifference,
  limitDecimals,
} from '../../../../../_shared/utils/util';
import { FormulationChange } from './formulation-change.component';
import { useIngredients } from '../../../../../_shared/hooks';
import camelCase from 'lodash/camelCase';

type DisplayInfo = {
  ingredientName: string;
  value: string;
  benchmarkValue?: string;
  difference?: string;
  symbol: string;
};

export const FormulationSider = ({
  formulation,
  benchmarkFormulation,
}: {
  /**
   * formulation component Id to value
   * {12: '5cm', 14: 5.234}
   */
  formulation: { [key: string]: string };
  benchmarkFormulation?: { [key: string]: string };
}) => {
  const [showModal, setShowModal] = useState(false);
  const { ingredientById } = useIngredients();

  const allIngredients: DisplayInfo[] = [];
  Object.keys(formulation).forEach(id => {
    const ing = ingredientById.get(id);
    const symbol = !ing?.isTestCondition ? '%' : '';
    const value = formulation[id];
    const benchmarkValue = benchmarkFormulation?.[id] || '0';
    const difference = getDisplayDifference(value, benchmarkValue);

    if (ing) {
      allIngredients.push({
        ingredientName: ing.ingredient.name,
        value,
        benchmarkValue:
          benchmarkValue === undefined && !ing?.isTestCondition
            ? '0'
            : benchmarkValue,
        difference,
        symbol,
      });
    }
  });

  return (
    <div
      css={css`
        background: #fcfcfc;
        border-radius: 0px 17px 17px 0px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
        padding-left: 10px;
        padding-right: 10px;
        margin: 35px 0px;
        z-index: 0;
      `}
    >
      <span
        css={css`
          font-size: 13px;
          color: #03191e;
          opacity: 0.5;
        `}
      >
        {benchmarkFormulation && <span> Changes based on Benchmark </span>}
      </span>
      <div>
        {allIngredients
          .filter(i => i.difference !== '0')
          .slice(0, 3)
          .map(i => (
            <div key={camelCase(i.ingredientName + 'FormulationSider')}>
              <FormulationChange
                ingredientName={i.ingredientName}
                value={String(limitDecimals(i.value))}
                benchmarkValue={i.benchmarkValue}
                difference={i.difference!}
                symbol={i.symbol}
              />
            </div>
          ))}
        <Button
          onClick={() => setShowModal(true)}
          style={{ padding: '0px', fontSize: '13px' }}
          type="link"
        >{`> View Entire Formulation`}</Button>
      </div>
      {showModal && (
        <Modal
          footer={null}
          onCancel={() => setShowModal(false)}
          closable
          maskClosable={false}
          open
          width="50vw"
          title="List all ingredient changes"
        >
          <span>
            These are all the changes the Turing AI suggests for impact.
          </span>

          <table
            css={css`
              width: 100%;
              td {
                border: 0.5px solid #ecebeb;
              }
              th {
                font-size: 10px;
                background: #fefefe;
                border: 0.5px solid #ecebeb;
              }
              tr:nth-child(even) {
                background: #fefefe;
              }
            `}
          >
            <thead>
              <tr>
                <th>Ingredient</th>
                {benchmarkFormulation && <th>Benchmark value</th>}
                <th>Optimized formulation value</th>
              </tr>
            </thead>
            <tbody>
              {allIngredients.map(ing => {
                return (
                  <tr key={ing.ingredientName}>
                    <td>{ing.ingredientName}</td>
                    {benchmarkFormulation && (
                      <td>
                        {ing.benchmarkValue}
                        {ing.symbol}
                      </td>
                    )}
                    <td>
                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                        `}
                      >
                        <div>
                          {ing.value}
                          {ing.symbol}
                        </div>
                        <div>
                          {ing.difference
                            ? `${ing.difference}${ing.symbol}`
                            : ''}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
};
