/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import {
  ExpertKnowledgeExtremum,
  ExpertKnowledgeHighConfidenceIndicator,
  ExpertKnowledgeLinear,
  ExpertKnowledgeLowConfidenceIndicator,
  ExpertKnowledgeMediumConfidenceIndicator,
  ExpertKnowledgeNegativeDriverArrow,
  ExpertKnowledgePositiveDriverArrow,
  ExpertKnowledgeStrongToWeak,
  ExpertKnowledgeWeakToStrong,
} from '../../../_shared/style';
import { Select, Input, Button } from 'antd';
import React, { useMemo, useState } from 'react';
import {
  ExpertKnowledgeInputType,
  ExpertKnowledgeShape,
  ExpertKnowledgeType,
  Level,
  useupdateExpertKnowledgeMutation,
} from '../../../../../__generated__/globalTypes';
import { useExpertKnowledge } from '../expert-knowledge-context.component';
import { useGraph } from '../../../_shared/context';
import { DefaultOptionType } from 'antd/lib/select';
import { ExcludeRelationshipModal } from './exclude-relationship-modal';
import {
  ContainerStyle,
  DropdownLabelStyle,
  FooterFormContainerStyle,
  FormContainerStyle,
  InputColumnStyle,
  LabelStyle,
  LowerFormContainerStyle,
  OutcomeNameContainerStyle,
  PrimaryTextStyle,
  RelationshipTypeButton,
  TopFormContainerStyle,
  TopFormSvgContainerStyle,
  WhiteSvgContainerStyle,
} from './expert-knowledge-relationship-inputs.styles';
import { ConfidenceInput } from './confidence-input';

const { TextArea } = Input;

export const ExpertKnowledgeRelationshipInputs = () => {
  const {
    expertKnowledgeFormulation,
    relationshipsWithExpertKnowledge,
    selectedRelationshipKey,
    setShowEditRelationshipForm,
    ingredientsWithoutRelationship,
    onAddRelationship,
  } = useExpertKnowledge();
  const { activeOutcome } = useGraph();
  const [updateExpertKnowledgeMutation] = useupdateExpertKnowledgeMutation();

  const selectedRelationship =
    selectedRelationshipKey !== undefined
      ? relationshipsWithExpertKnowledge!.get(selectedRelationshipKey)
      : undefined;

  const [selectedIngredient, setSelectedIngredient] = useState(
    selectedRelationship?.childName
  );
  const [driverType, setDriverType] = useState<ExpertKnowledgeType | undefined>(
    selectedRelationship?.type ?? undefined
  );
  const [confidence, setConfidence] = useState<Level | undefined>(
    selectedRelationship?.confidence
  );
  const [shape, setShape] = useState<ExpertKnowledgeShape | undefined>(
    selectedRelationship?.shape ?? ExpertKnowledgeShape.LINEAR
  );
  const [comment, setComment] = useState<string | undefined>(
    selectedRelationship?.comment as string | undefined
  );
  const [
    removeRelationshipModalOpen,
    setRemoveRelationshipModalOpen,
  ] = useState(false);

  const isExistingRelationship = selectedRelationshipKey !== undefined;

  const ingredientOptions: DefaultOptionType[] = useMemo(() => {
    return ingredientsWithoutRelationship.map(ingredient => ({
      label: ingredient,
      value: ingredient,
    }));
  }, [ingredientsWithoutRelationship]);

  const onSave = async () => {
    //TODO: we'll need some error logic in here eventually
    const knowledgeData: ExpertKnowledgeInputType = {
      childName: selectedIngredient!,
      comment,
      confidence: confidence!,
      expertKnowledgeFormulationId: expertKnowledgeFormulation!.id,
      include: true,
      parentName: activeOutcome!,
      shape: shape!,
      type: driverType!,
    };

    handleOnSave(knowledgeData);
  };

  const onExcludeRelationship = async (excludeConfidence: Level) => {
    const knowledgeData: ExpertKnowledgeInputType = {
      childName: selectedIngredient!,
      comment,
      confidence: excludeConfidence,
      expertKnowledgeFormulationId: expertKnowledgeFormulation!.id,
      include: false,
      parentName: activeOutcome!,
      shape: shape!,
      type: driverType!,
    };

    handleOnSave(knowledgeData);
  };

  const handleOnSave = async (knowledgeData: ExpertKnowledgeInputType) => {
    const { data } = await updateExpertKnowledgeMutation({
      variables: {
        input: knowledgeData,
      },
    });

    onAddRelationship({
      ...knowledgeData,
      id: data?.updateExpertKnowledge.id,
    });
    setShowEditRelationshipForm(false);
    setRemoveRelationshipModalOpen(false);
  };

  return (
    <div css={ContainerStyle}>
      <div css={PrimaryTextStyle}>
        {isExistingRelationship ? 'Review' : 'Add'} Relationship
      </div>
      <div css={FormContainerStyle}>
        <div css={TopFormContainerStyle}>
          <div css={OutcomeNameContainerStyle}>{activeOutcome!}</div>
          <div
            css={css`
              ${TopFormSvgContainerStyle};
              ${WhiteSvgContainerStyle};
            `}
          >
            {driverType === ExpertKnowledgeType.POSITIVE && (
              <ExpertKnowledgePositiveDriverArrow />
            )}
            {driverType === ExpertKnowledgeType.NEGATIVE && (
              <ExpertKnowledgeNegativeDriverArrow />
            )}
          </div>
          <Select
            bordered={false}
            disabled={isExistingRelationship}
            value={selectedIngredient}
            onChange={value => setSelectedIngredient(value)}
            options={ingredientOptions}
          />
          <div css={TopFormSvgContainerStyle}>
            {confidence === Level.LOW && (
              <ExpertKnowledgeLowConfidenceIndicator />
            )}
            {confidence === Level.MEDIUM && (
              <ExpertKnowledgeMediumConfidenceIndicator />
            )}
            {confidence === Level.HIGH && (
              <ExpertKnowledgeHighConfidenceIndicator />
            )}
          </div>
        </div>
        <div css={LowerFormContainerStyle}>
          <div css={LabelStyle}>Type of relationship</div>
          <div
            css={css`
              display: flex;
              margin-bottom: 24px;
            `}
          >
            <RelationshipTypeButton
              selected={shape === ExpertKnowledgeShape.LINEAR}
              onClick={() => setShape(ExpertKnowledgeShape.LINEAR)}
            >
              <ExpertKnowledgeLinear />
            </RelationshipTypeButton>
            <RelationshipTypeButton
              selected={shape === ExpertKnowledgeShape.STRONG_TO_WEAK}
              onClick={() => setShape(ExpertKnowledgeShape.STRONG_TO_WEAK)}
            >
              <ExpertKnowledgeStrongToWeak />
            </RelationshipTypeButton>
            <RelationshipTypeButton
              selected={shape === ExpertKnowledgeShape.WEAK_TO_STRONG}
              onClick={() => setShape(ExpertKnowledgeShape.WEAK_TO_STRONG)}
            >
              <ExpertKnowledgeWeakToStrong />
            </RelationshipTypeButton>
            <RelationshipTypeButton
              selected={shape === ExpertKnowledgeShape.EXTREMA}
              onClick={() => setShape(ExpertKnowledgeShape.EXTREMA)}
            >
              <ExpertKnowledgeExtremum />
            </RelationshipTypeButton>
          </div>
          <div
            css={css`
              display: flex;
              margin-bottom: 24px;
            `}
          >
            <div css={InputColumnStyle}>
              <span css={LabelStyle}>Driver type</span>
              <Select
                bordered={false}
                placeholder="-"
                onChange={value => setDriverType(value)}
                value={driverType}
                options={[
                  {
                    value: ExpertKnowledgeType.POSITIVE,
                    label: (
                      <div
                        css={css`
                          ${DropdownLabelStyle};
                          ${WhiteSvgContainerStyle};
                        `}
                      >
                        <ExpertKnowledgePositiveDriverArrow /> Positive
                      </div>
                    ),
                  },
                  {
                    value: ExpertKnowledgeType.NEGATIVE,
                    label: (
                      <div
                        css={css`
                          ${DropdownLabelStyle};
                          ${WhiteSvgContainerStyle};
                        `}
                      >
                        <ExpertKnowledgeNegativeDriverArrow /> Negative
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <ConfidenceInput confidence={confidence} onChange={setConfidence} />
          </div>
          <span css={LabelStyle}>Additional notes</span>
          <TextArea
            bordered={false}
            rows={6}
            onChange={event => setComment(event.currentTarget.value)}
            value={comment}
          />
        </div>
        <div css={FooterFormContainerStyle}>
          <div>
            {isExistingRelationship && (
              <Button
                css={css`
                  padding-left: 0;
                  color: #fd4f4f !important;
                `}
                type="text"
                onClick={() => setRemoveRelationshipModalOpen(true)}
                danger
              >
                Mark for Removal
              </Button>
            )}
          </div>
          <div>
            <Button
              css={css`
                color: #fff;
                margin-right: 24px;
              `}
              type="text"
              onClick={() => setShowEditRelationshipForm(false)}
            >
              Cancel
            </Button>
            <Button
              css={css`
                width: 120px;
                height: 36px;
              `}
              type="primary"
              onClick={() => onSave()}
              disabled={
                !selectedIngredient || !shape || !confidence || !driverType
              }
            >
              Done
            </Button>
          </div>
        </div>
      </div>
      {selectedIngredient && activeOutcome && (
        <ExcludeRelationshipModal
          confidence={confidence}
          open={removeRelationshipModalOpen}
          onOk={confidence => onExcludeRelationship(confidence)}
          onClose={() => setRemoveRelationshipModalOpen(false)}
          selectedIngredient={selectedIngredient}
          selectedOutcome={activeOutcome}
        />
      )}
    </div>
  );
};
