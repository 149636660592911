/* eslint-disable no-bitwise */
/** @jsxImportSource @emotion/react */ import {
  css,
  jsx,
} from '@emotion/react';
import React from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
  BarChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { NonIndicativeColors } from '../../../../../../iso/colors';
import { SimulationFromIterationType } from '../../context/types';

export const DiptubeGrowthBarChart = ({
  simulation: sim,
}: {
  simulation: SimulationFromIterationType;
}) => {
  const formattedData: { name: string; [key: string]: string | number }[] = [];
  const products = new Map<string, string>();

  const cloneArr = sim?.simulationProductVersion
    ? [...sim.simulationProductVersion]
    : [];

  cloneArr
    .sort((a, b) => a.columnNumber - b.columnNumber)
    .forEach(pv => {
      products.set(
        pv.productVersion.productId?.toString(),
        pv.productVersion.name
      );
    });
  const [headerRow, ...records] = sim.visualizationOutput as string[][];

  const barNames = new Set<string>();
  records.forEach(row => {
    const tmp: { [key: string]: number } = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    for (let i = 1; i < row.length; i++) {
      //value label to amount
      //Will become a bar in the graph
      if (i % 3 === 0) {
        barNames.add(headerRow[i]);
        tmp[headerRow[i]] = Number(row[i]);
      }
    }

    formattedData.push({ name: row[0], ...tmp });
  });

  //Map loop doesn't have an index?
  const bars: JSX.Element[] = [];
  let i = 0;
  barNames.forEach(name => {
    bars.push(<Bar dataKey={name} fill={`${NonIndicativeColors[i]}CC`} />);
    i += 1;
  });

  return (
    <ResponsiveContainer
      width="100%"
      height="85%"
      minHeight={25}
      minWidth={25}
      css={css`
        .recharts-tooltip-wrapper {
          // enable following to make it a fixed height with optional scroll
          //pointer-events: auto !important;
          //overflow-y: auto;
          //max-height: 400px;
          /* z-index: 1000; */
        }
      `}
    >
      <BarChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 4" />
        <XAxis tickLine={false} axisLine={false} dataKey="name" />
        <YAxis
          tickLine={false}
          axisLine={false}
          //TODO: Make more universal, right now is zero based unless number is negative
          domain={[0, (dataMax: number) => (dataMax > 6 ? dataMax : 7)]}
        />
        <Tooltip />
        <Legend />
        <ReferenceLine
          y={1}
          stroke="orange"
          strokeDasharray="6 1"
          //@ts-ignore
          label="1%"
        />
        <ReferenceLine
          y={6}
          stroke="orange"
          strokeDasharray="6 1"
          //@ts-ignore
          label="6%"
        />
        {...bars}
      </BarChart>
    </ResponsiveContainer>
  );
};
