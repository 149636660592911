import { css } from '@emotion/react';
import { Colors } from '../../../../_shared/style';

export const adaptiveLearningPanelStyles = css`
  display: flex;
  flex-direction: column;
  margin: 18px 24px;

  .alTitleBox {
    width: 100%;
    display: flex;
    margin-right: auto;
    justify-content: space-between;
  }

  .addResultsbutton {
    height: 46px;
    display: flex;
    align-self: center;
  }
`;
