/** @jsxImportSource @emotion/react */

import { Button, message } from 'antd';
import * as Sentry from '@sentry/react';
import { DownloadFile } from '../../../_shared/components/button/download-file';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { getDesignTemplateDownload } from './design-utils';
import { useSession } from '../../../_shared/context/session-context';
import { useWorkspace } from '../lab-bench/context';
import { useDesign } from '../../../_shared/context/design-context';

export const AdaptiveLearningTemplateDownload = () => {
  const { currentProject } = useSession();
  const { designResults, latestDesign } = useDesign();
  const { iteration } = useWorkspace();

  const getTemplateData = () => {
    if (designResults && currentProject) {
      try {
        return getDesignTemplateDownload(latestDesign, currentProject);
      } catch (e) {
        Sentry.captureException(e);
        void message.error('Error creating CSV');
      }
    }

    return '';
  };

  const getDownloadOptions = () => {
    return {
      type: 'text/csv;charset=utf-8',
    };
  };

  const trackEvent = () => {
    logEvent(TrackableEvent.ADAPTIVE_LEARNING_EXPERIMENT_TEMPLATE_DOWNLOADED, {
      projectId: currentProject?.id,
    });
  };
  return (
    <DownloadFile
      options={getDownloadOptions()}
      content={getTemplateData()}
      filename={`${currentProject?.key}-${
        iteration?.key
      }-Adaptive-Learning-Results-${new Date().toISOString()}.csv`}
    >
      <Button onClick={trackEvent}>Download Sample CSV</Button>
    </DownloadFile>
  );
};
