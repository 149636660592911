/** @jsxImportSource @emotion/react */
import { Collapse } from 'antd';
import { css } from '@emotion/react';
import React, { PropsWithChildren } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export const DesignConstraintCollapse = ({
  name,
  children,
}: PropsWithChildren<{ name: string | undefined | null }>) => {
  return (
    <Collapse
      defaultActiveKey={name ? '' : '1'}
      css={css`
        width: 100%;
        margin-bottom: 5px;
        .ant-collapse-expand-icon {
          align-self: center;
        }
      `}
      expandIcon={({ isActive }) =>
        isActive ? <MinusOutlined /> : <PlusOutlined />
      }
    >
      {children}
    </Collapse>
  );
};
