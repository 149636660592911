/** @jsxImportSource @emotion/react */
import React from 'react';
import { Layout, Spin } from 'antd';
import { initMixpanel } from '../../_shared/tracking/mixpanel';
import { DashboardHeader } from './dashboard-header.component';
import { DashboardFooter } from './dashboard-footer.component';
import {
  appLayoutStyle,
  appHeaderStyle,
  appFooterStyle,
  appContentLayoutStyle,
} from './dashboard-layout.styles';
import { useSession } from '../../_shared/context/session-context';
import { AppRoutes } from '../app-routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const { Header, Footer } = Layout;

export const DashboardLayout = () => {
  const { user, currentProject } = useSession();
  React.useEffect(() => {
    if (!user) return;
    //Placing this before spiner render because react was not liking the
    //Differing hooks being ran
    initMixpanel(user.email, user.id);
  }, [user]);

  if (!user) return <Spin>Loading user...</Spin>;

  return (
    <DndProvider backend={HTML5Backend}>
      <Layout css={appLayoutStyle}>
        <Layout className="App" style={{ height: 'calc(100vh - 5px)' }}>
          <Header css={appHeaderStyle}>
            <DashboardHeader />
          </Header>
          <Layout css={appContentLayoutStyle}>
            <AppRoutes user={user} />
          </Layout>
          <Footer css={appFooterStyle}>
            <DashboardFooter />
          </Footer>
        </Layout>
      </Layout>
    </DndProvider>
  );
};
