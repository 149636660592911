/** @jsxImportSource @emotion/react */

import { Dropdown, notification, MenuProps } from 'antd';
import { stringify } from 'csv-stringify/sync';
import {
  logEvent,
  TrackableEvent,
} from '../../../../_shared/tracking/usage-tracker';
import { DownloadFile } from '../../../../_shared/components/button/download-file';
import { Ellipsis } from '../../../../_shared/style';
import { isModelMLAPI } from '../../../../../iso/model-ids';
import { ResponsePredictionData } from '../../../../../types/mlapi.types';
import { usegetSimulationInputForIterationLazyQuery } from '../../../../../../__generated__/globalTypes';
import { useEffect, useState } from 'react';
import { useSession } from '../../../../_shared/context';
import {
  IterationWithLatestSimulation,
  SimulationFromIterationType,
} from '../context';

enum DownloadTypes {
  DISPLAY_OUTPUT,
  RAW_OUTPUT,
  INPUT,
}

// TODO: make this not specific since the menu shows up in different places.
//  Let devs add items to a Ellipsis menu component as links so that things \
// look about the same everywhere
export const FormulationMenu = ({
  simulation,
  iteration,
  userId,
  modelId,
  copyCurrentProduct,
  editProductName,
  removeProduct,
  setAsBenchmark,
}: {
  simulation?: SimulationFromIterationType;
  iteration: IterationWithLatestSimulation;
  userId: any;
  modelId: string;
  copyCurrentProduct: () => void;
  editProductName: () => void;
  removeProduct: () => void;
  setAsBenchmark: () => void;
}) => {
  const { currentProject } = useSession();
  const userIdObj = { userId };
  const modelIsMlApi = isModelMLAPI(modelId);

  const rawOutputFileNameSuffix = modelIsMlApi ? 'Output' : 'RawOutput';
  const rawOutputDisplayName = modelIsMlApi
    ? 'Model Output'
    : 'Raw Model Output';

  const [simInput, setSimInput] = useState<string>('');

  const [getSimulationInput] = usegetSimulationInputForIterationLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ simulationInput }) => {
      if (simulationInput) setSimInput(simulationInput);
    },
    onError: err => {
      notification.error({
        message: `Unable to retrieve simulation input for current iteration.`,
      });
    },
  });

  useEffect(() => {
    getSimulationInput({
      variables: {
        iterationId: iteration.id,
        projectId: currentProject?.id || '',
      },
    });
  }, [iteration]);

  const formatDownload = (type: DownloadTypes) => {
    if (!simulation) return '';

    const formatMlApiDownload = (): string[][] => {
      const content: string[][] = [];
      // Webapp only supports 1 algorithm but MLAPI returns a array
      const [response] = simulation.output as Array<ResponsePredictionData>;
      const headerPerTargetVariable = response.results[0].outcomes
        .map(o => [`${o.target_variable}`, 'Applicability', 'Reliability'])
        .reduce((prev, cur) => [...prev, ...cur], []);
      content.push(['Product', ...headerPerTargetVariable]);

      response.results.forEach(result => {
        const toAppend = result.outcomes
          .map(o => [
            String(o.value),
            String(o.applicability),
            String(o.reliability),
          ])
          .reduce((prev, cur) => [...prev, ...cur], []);
        content.push([`${result.name}`, ...toAppend]);
      });

      return content;
    };

    let unescapedData: string[][] | undefined | null;

    switch (type) {
      case DownloadTypes.RAW_OUTPUT:
        unescapedData = modelIsMlApi
          ? formatMlApiDownload()
          : (simulation.output as string[][]);
        break;
      case DownloadTypes.DISPLAY_OUTPUT:
        unescapedData = simulation.visualizationOutput as string[][];
        break;
      case DownloadTypes.INPUT:
        return simInput;
      default:
        break;
    }

    return stringify(unescapedData || []);
  };

  const getDownloadName = (type: string) => {
    return `${currentProject?.key}${iteration.key}-${type}.csv`;
  };

  const downloadCsvChildItems: MenuProps['items'] = [
    {
      key: 'downloadRawOutput',
      label: (
        <DownloadFile
          options={{
            type: 'data:attachment/text',
          }}
          content={formatDownload(DownloadTypes.RAW_OUTPUT)}
          filename={getDownloadName(rawOutputFileNameSuffix)}
          onclick={() =>
            logEvent(TrackableEvent.RAW_OUTPUT_DOWNLOADED, userIdObj)
          }
        >
          {rawOutputDisplayName}
        </DownloadFile>
      ),
    },
    {
      key: 'downloadInput',
      label: (
        <DownloadFile
          options={{
            type: 'data:attachment/text',
          }}
          content={formatDownload(DownloadTypes.INPUT)}
          filename={getDownloadName('Input')}
          onclick={() =>
            logEvent(TrackableEvent.SIMULATION_INPUT_FILE_DOWNLOADED, userIdObj)
          }
        >
          Simulation Input
        </DownloadFile>
      ),
    },
  ];
  if (modelIsMlApi === false) {
    downloadCsvChildItems.push({
      key: 'downloadDisplayedInput',
      label: (
        <DownloadFile
          options={{
            type: 'data:attachment/text',
          }}
          content={formatDownload(DownloadTypes.DISPLAY_OUTPUT)}
          filename={getDownloadName('DisplayedOutput')}
          onclick={() =>
            logEvent(TrackableEvent.DISPLAYED_OUTPUT_DOWNLOADED, userIdObj)
          }
        >
          Displayed Model Output
        </DownloadFile>
      ),
    });
  }

  const formulationMenuItems: MenuProps['items'] = [
    {
      key: 'editName',
      label: 'Edit Name',
      onClick: editProductName,
    },
    {
      key: 'markAsBenchmark',
      label: 'Mark as Benchmark',
      onClick: setAsBenchmark,
    },
    {
      key: 'copy',
      label: 'Make Copy',
      onClick: copyCurrentProduct,
    },
    {
      key: 'remove',
      label: 'Remove Formulation',
      onClick: removeProduct,
    },
    {
      label: 'Download CSV',
      key: 'downloadSubMenu',
      children: downloadCsvChildItems,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: formulationMenuItems,
      }}
      placement="bottomRight"
    >
      <span>
        <Ellipsis />
      </span>
    </Dropdown>
  );
};
