import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { useSession } from '../../_shared/context/session-context';

export const NotFoundComponent = ({
  message,
  showLogoutLink = false,
  showAllProjectsLink = false,
}: {
  message: string | ReactElement;
  showLogoutLink?: boolean;
  showAllProjectsLink?: boolean;
}) => {
  const { logout } = useSession();
  return (
    <div
      style={{
        maxHeight: '100vh',
        paddingTop: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
      }}
    >
      <div>
        <img
          style={{
            marginLeft: -200,
          }}
          src="https://assets.turingsaas.com/404-transparent.png"
          alt="404"
        />
        <p style={{ fontSize: 18, marginTop: 30 }}>{message}</p>
        {showLogoutLink && (
          <Button style={{ fontSize: 18 }} type={'link'} onClick={logout}>
            {' '}
            Log Out
          </Button>
        )}
        {showAllProjectsLink && (
          <Link style={{ fontSize: 18 }} to="/">
            View all Projects
          </Link>
        )}
      </div>
    </div>
  );
};
