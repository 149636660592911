import { css } from '@emotion/react';
import { ONBOARDING_BACKGROUND_GRADIENT } from '../../_shared/style/snippets';
import { Colors } from '../../_shared/style';

export const onboardingContainer = css`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  background: ${ONBOARDING_BACKGROUND_GRADIENT};
`;

export const onboardingForm = css`
  min-width: 35%;
  background: ${Colors.WHITE};
  padding: 30px 30px 20px 30px;
  box-shadow: 0px 1px 1px #dfdfe4, 0px 0px 4px -1px rgba(3, 25, 30, 0.05),
    0px 1px 8px rgba(3, 25, 30, 0.03);
  border-radius: 6px;

  .ant-form-item-label {
    padding: 0;
    label {
      font-size: 13px;
      color: ${Colors.STORM_GREY};
    }
  }
  input.ant-input,
  textarea.ant-input,
  .ant-select {
    border: 1px solid #dfdfe4;
    border-radius: 4px;
    height: 48px;
  }
  .ant-select .ant-select-selector {
    height: 48px;
    padding-top: 7px;

    input.ant-select-selection-search-input {
      padding-top: 13px;
    }
  }
`;

export const formItemsContainer = css`
  margin-top: 30px;
`;

export const onboadingAssetStyle = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1: {
    color: ${Colors.WHITE};
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.3em;
  }
`;

export const assetLearnMoreLinkStyle = css`
  margin-top: 20px;
  font-size: 12px;
  color: ${Colors.WHITE};
  text-decoration: underline;

  &:hover {
    color: ${Colors.WHITE};
  }
`;

export const submitButtonStyle = css`
  border-radius: 24px;
  width: 100%;
  height: 48px;
  font-size: 16px;
`;
