import React, { createContext, useContext, useState } from "react";

interface InputOutputNamesProps {
  stringArray: string[];
  addName: (element: string, use?: boolean) => void;
  deleteName: (index: number, use?: boolean) => void;
  updateEntireArr: (arr: string[], use?: boolean) => void;
  checkDuplicate: (name: string, use?: boolean) => boolean;
}

const InputOutputNames = createContext<InputOutputNamesProps | undefined>(undefined);

export const useInputOutpuNamesContext = () => {
  const context = useContext(InputOutputNames);
  if (!context) {
    throw new Error('useInputOutpuNames must be used within an InputOutputNamesProvider');
  }
  return context;
};

interface InputOutcomeNamesProviderProps {
  children: any,
  initialNames?: string[];
}

export const InputOutcomeNamesProvider: React.FC<InputOutcomeNamesProviderProps> = ({ children, initialNames }) => {
  // const [stringArray, setStringArray] = useState<string[]>(initialNames ?? []);
  const [stringArray, setStringArray] = useState<string[]>([]);
  const [alreadySetArray, setAlreadySetArray] = useState<boolean>(false)

  const addName = (element: string, use?: boolean) => {

    if (!use) { return }

    console.log('************ add', stringArray)
    stringArray.push(element)
    setStringArray(stringArray);
    console.log('************ add', stringArray)
  };

  const deleteName = (index: number, use?: boolean) => {

    if (!use) { return }

    console.log('************  delete', stringArray)
    const newArray = [...stringArray];
    newArray.splice(index, 1);
    setStringArray(newArray);
    console.log('************ delete', stringArray)
  };

  const updateEntireArr = (arr: string[], use?: boolean) => {

    if (!use || alreadySetArray) { return }

    console.log('************ updateEntireArr', stringArray)
    setStringArray(arr)
    setAlreadySetArray(true)
    console.log('************ updateEntireArr', stringArray)
  }

  const checkDuplicate = (name: string, use?: boolean) => {

    if (!use) { return true }

    console.log("Duplicate",
      stringArray,
      name
    )

    return stringArray.includes(name);
  };

  return (
    <InputOutputNames.Provider value={{ stringArray, addName, deleteName, updateEntireArr, checkDuplicate }}>
      {children}
    </InputOutputNames.Provider>
  );
};