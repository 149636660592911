/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

import { Button } from 'antd';
import Icon from '@ant-design/icons';

import { Colors, PageIcon } from '../../style';

export const AddNoteButton = ({ onClick }: { onClick?: () => void }) => (
  <Button
    css={css`
      color: #ffc233;
      height: 28px;
      width: 42px;
      margin: 5px 0;
      display: flex;
      background: #fff;
      box-shadow: 0px 1px 1px ${Colors.FRENCH_GREY},
        0px 0px 4px ${Colors.SWAMP}0D, 0px 1px 8px ${Colors.SWAMP}08;
      border-radius: 6px;
      padding: 0;
      cursor: pointer;
      font-size: 16px;

      .plus {
        position: relative;
        top: -2px;
        left: 3px;
      }

      svg {
        position: relative;
        top: 4px;
      }
    `}
    onClick={onClick}
  >
    <span className="plus">+</span>
    <Icon component={PageIcon} />
  </Button>
);
