/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tooltip } from 'antd';
import truncate from 'lodash/truncate';
/**
 * This could probably be a styled component
 * but creating this in case we want to add on functionality
 */

export const TextContainerComponent = ({
  text,
  truncateLength = 40,
}: {
  text: string;
  truncateLength?: number;
}) => {
  return (
    <div
      css={css`
        box-sizing: border-box;

        /* Auto layout */
        padding-left: 3px;
        padding-right: 3px;
        align-items: center;
        padding: 4px 8px;
        gap: 24px;
        background: #ffffff;
        border: 1px solid #dfdfe4;
        border-radius: 4px;
        display: inline-block;
        margin-left: 3px;
        margin-top: 5px;
      `}
    >
      {text.length > truncateLength ? (
        <Tooltip title={text}>
          {truncate(text, { length: truncateLength })}
        </Tooltip>
      ) : (
        text
      )}
    </div>
  );
};
