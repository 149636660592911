/** @jsxImportSource @emotion/react */

import { Button, Image } from 'antd';

import {
  askTuringPanelStyle,
  closeIconStyle,
  headerStyle,
  subheaderStyle,
  askButtonStyle,
} from './ask-turing-panel.styles';
import { ModalCloseX } from '../../_shared/style/svg-assets';
import { useSession } from '../../_shared/context';

/**
 * No longer used in favor of integrated chat with papercups.
 * Keeping the element around in case we want to reuse the graphics
 */
const AskTuringPanel = () => {
  const { setShowAskTuring } = useSession();

  return (
    <div css={askTuringPanelStyle}>
      <div css={closeIconStyle} onClick={() => setShowAskTuring(false)}>
        <ModalCloseX />
      </div>
      <Image width={224} src="/images/ask-turing.svg" />
      <span css={headerStyle}>Ask Turing</span>
      <span css={subheaderStyle}>Get help from our team of experts</span>
      <Button
        css={askButtonStyle}
        type="primary"
        href="mailto:support@turingsaas.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ask Us
      </Button>
    </div>
  );
};

export default AskTuringPanel;
