/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import {
  Objective,
  ObjectiveType,
} from '../../../../../../__generated__/globalTypes';
import { OutcomeRadioOptions } from './outcome-radio-options.component';
import { useEffect, useState } from 'react';
import {
  ObjectiveHeaderMark,
  ImportanceArrow,
  Colors,
} from '../../../../_shared/style';
import { RadioChangeEvent } from 'antd';
import { useSession } from '../../../../_shared/context';
import { objectiveEditPanelStyle, editPanelContainerEditMode } from './styles';

import {
  limitDecimals,
  validateObjective,
} from '../../../../_shared/utils/util';
import { IMPORTANCE_MAX, NO_IMPORTANCE } from './types';
import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import cloneDeep from 'lodash/cloneDeep';

export type OutcomeRadioOptionProps = {
  objective: Objective;
  updateObjective: (o: Objective) => void;
  changePriority: (e: { action: string; targetVariable: string }) => void;
  setObjectivesAreInEditMode: (o: boolean) => void;
};

export const ObjectiveEditPanel = ({
  objective,
  updateObjective,
  changePriority,
  setObjectivesAreInEditMode,
}: OutcomeRadioOptionProps) => {
  const clonedObjective = cloneDeep(objective);
  const { objectiveType, upper, lower, value, ...otherProps } = objective;

  const [inEditMode, setInEditMode] = useState(false);
  // We don't want to change the objective in global state until we hit the "save" button
  const [localObjective, setLocalObjective] = useState(clonedObjective);
  const [localError, setLocalError] = useState<string>();
  const { currentProject } = useSession();
  const { disableGoalScenario } = useScenarioDetail();
  let summaryText;
  const currentModel = currentProject?.activeModel;
  const outcome = currentModel?.outcomes.find(
    o => o.targetVariable === objective.targetVariable
  );

  const handleObjectiveSave = () => {
    if (inEditMode) {
      const error = handleValidation(localObjective);
      if (!error) {
        // Now that we've properly validated the objective, update the objectives stored in context
        updateObjective(cloneDeep(localObjective));
        setObjectivesAreInEditMode(!inEditMode);
        setInEditMode(!inEditMode);
        setLocalError('');
      }
    } else {
      setObjectivesAreInEditMode(!inEditMode);
      setInEditMode(!inEditMode);
    }
  };

  const handleValidation = (obj: Objective) => {
    const error = validateObjective(obj, outcome);
    if (error) {
      setLocalError(error?.message);
      return error;
    } else {
      setLocalError('');
      return null;
    }
  };

  const outcomeUnit: string | undefined =
    outcome?.unit && outcome?.unit !== 'Not Applicable'
      ? outcome?.unit
      : undefined;

  const formattedOutcomeUnit = outcomeUnit && (
    <span
      css={css`
        color: ${Colors.STORM_GREY};
        margin-left: 5px;
        font-size: 12px;
      `}
    >
      ({outcomeUnit})
    </span>
  );

  switch (objectiveType) {
    case ObjectiveType.MAXIMIZE:
      summaryText = 'Maximize';
      break;
    case ObjectiveType.MINIMIZE:
      summaryText = 'Minimize';
      break;
    case ObjectiveType.IN_RANGE:
      summaryText = (
        <div
          css={css`
            display: block;
          `}
        >
          <div>
            Range:{' '}
            <span
              css={css`
                font-weight: 700;
              `}
            >
              {limitDecimals(lower, 12)}
              {formattedOutcomeUnit}
            </span>
          </div>
          <div
            css={css`
              float: right;
            `}
          >
            to{' '}
            <span
              css={css`
                font-weight: 700;
              `}
            >
              {limitDecimals(upper, 12)}
              {formattedOutcomeUnit}
            </span>
          </div>
        </div>
      );
      break;
    case ObjectiveType.TARGET_VALUE:
      summaryText = (
        <div>
          Target:{' '}
          <span
            css={css`
              font-weight: 700;
            `}
          >
            {limitDecimals(value, 12)}
            {formattedOutcomeUnit}
          </span>
        </div>
      );
      break;
  }

  const handleRadioSelect = (e: RadioChangeEvent) => {
    const selectedType = e.target.value as ObjectiveType;
    setLocalObjective({
      ...otherProps,
      objectiveType: selectedType,
      lower: null,
      upper: null,
      value: null,
    });
    setLocalError('');
  };

  const updateRangeValue = (isLower: boolean, value: string) => {
    if (isLower) {
      localObjective.lower = value;
    } else {
      localObjective.upper = value;
    }
    setLocalObjective(localObjective);
    handleValidation(localObjective);
  };

  const updateTargetValue = (value: string) => {
    localObjective.value = value;
    setLocalObjective(localObjective);
    handleValidation(localObjective);
  };

  const goalsContainer: JSX.Element = (
    <div key={otherProps.targetVariable + 'OutcomeRadioOption-div'}>
      <OutcomeRadioOptions
        key={otherProps.targetVariable + 'OutcomeRadioOption'}
        projectHasOptimizeOnTargetValue={true}
        objective={localObjective}
        handleRadioSelect={handleRadioSelect}
        handleRangeValue={updateRangeValue}
        handleTargetValue={updateTargetValue}
      />
      <div
        css={css`
          font-weight: 700;
          color: #ea291f;
        `}
      >
        {localError}
      </div>
    </div>
  );

  return (
    <div
      css={objectiveEditPanelStyle}
      key={otherProps.targetVariable + 'ObjectivesEditPanel'}
    >
      <div
        className="editPanelContainer"
        css={editPanelContainerEditMode(inEditMode)}
      >
        <div
          css={css`
            display: flex;
            margin-bottom: ${inEditMode ? '15px' : '9px'};
          `}
        >
          <div className="headerMarkStyle">
            <ObjectiveHeaderMark />
          </div>
          <div className="objectiveDetailsStyle">
            <div className="targetVariableStyle">
              {objective.targetVariable}
            </div>
            <div
              css={css`
                margin-right: 12px;
                display: flex;
              `}
            >
              {!inEditMode && (
                <span
                  css={css`
                    font-style: italic;
                  `}
                >
                  {summaryText}
                </span>
              )}
              {!disableGoalScenario && (
                <a
                  css={css`
                    padding-left: ${!inEditMode ? '7px' : '0px'};
                  `}
                  onClick={handleObjectiveSave}
                >
                  {inEditMode ? 'Save' : 'Edit'}
                </a>
              )}

              {inEditMode && (
                <span
                  css={css`
                    padding-left: 5px;
                    padding-right: 5px;
                    color: #d9d9d9;
                  `}
                >
                  |
                </span>
              )}
              {inEditMode && (
                <a
                  onClick={() => {
                    setLocalObjective(cloneDeep(objective));
                    setInEditMode(false);
                    setObjectivesAreInEditMode(false);
                    setLocalError('');
                  }}
                  css={css`
                      color: red;
                      &:hover {
                      color: #a40404;
                    `}
                >
                  Cancel
                </a>
              )}
            </div>
          </div>
        </div>
        {inEditMode && goalsContainer}
      </div>
      {!inEditMode && !disableGoalScenario && (
        <div
          css={css`
            display: flex;
            align-self: flex-start;
          `}
        >
          {objective.importance < IMPORTANCE_MAX && (
            <div
              css={css`
                cursor: pointer;
                width: 20px;
                margin: 0px 2px;
                align-self: center;
                padding-top: 7px;
              `}
              onClick={() =>
                changePriority({
                  action: 'INCREASE',
                  targetVariable: objective.targetVariable,
                })
              }
            >
              <ImportanceArrow direction="UP" />
            </div>
          )}
          {objective.importance > NO_IMPORTANCE && (
            <div
              css={css`
                cursor: pointer;
                width: 20px;
                align-self: center;
                padding-top: 7px;
              `}
              onClick={() =>
                changePriority({
                  action: 'DECREASE',
                  targetVariable: objective.targetVariable,
                })
              }
            >
              <ImportanceArrow direction="DOWN" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
