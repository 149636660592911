/** @jsxImportSource @emotion/react */

import * as React from 'react';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import {
  Button,
  Col,
  Divider,
  List,
  Row,
  Avatar,
  Tooltip,
  Spin,
  Popconfirm,
  Input,
  message,
  notification,
  InputRef,
} from 'antd';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  BaseProject,
  useAddProjectUsers,
  useGetProjectUsers,
  useRemoveProjectUser,
} from '../../_shared/hooks/use-project.hook';
import {
  useResendUserPassword,
  useChangeUserRole,
} from '../../_shared/hooks/use-user.hook';

import { AppModal } from '../../_shared/components/app-modal.component';

import {
  projectInfoModalBodyStyles,
  projectInfoSectionStyles,
  projectInfoModalTitleContainerStyles,
  projectInfoModalTitleStyles,
  userListStyles,
  projectCategorizationDetailsStyles,
  categorizationRowLabelStyles,
  buttonStyle,
  grayButtonStyle,
  categorizationRowDividerStyles,
  categorizationRowValueStyles,
  userMetaStyles,
  addUserActionsContainerStyles,
} from './project-info-modal.styles';
import { useSession } from '../../_shared/context';
import { Role, UserStatus } from '../../../../__generated__/globalTypes';
import { getValidEmails } from '../../_shared/utils/util';
import { updateUser as useUpdateUser, UserType } from '../../_shared/hooks';

type ProjectInfoModalProps = {
  project: BaseProject;
  onCancel: () => void;
};

export const ProjectInfoModal = ({
  project,
  onCancel,
}: ProjectInfoModalProps) => {
  return (
    <AppModal
      title="Additional Project Info"
      visible
      onCancel={onCancel}
      viewOnly
    >
      <div css={projectInfoModalBodyStyles}>
        <ProjectDetailsSection project={project} />
        <ProjectCategorizationSection project={project} />
      </div>
    </AppModal>
  );
};

const ProjectDetailsSection = ({ project }: { project: BaseProject }) => {
  const { user: me } = useSession();
  const [managing, setManaging] = React.useState<boolean>();
  const [showNewUsersField, setShowNewUsersField] = React.useState<boolean>();
  const [users, setUsers] = React.useState<UserType[]>();
  const [editingUserId, setEditingUserId] = React.useState<string>();
  const nameRef = React.useRef<InputRef>(null);
  const positionRef = React.useRef<InputRef>(null);
  const newUsersRef = React.useRef<TextAreaRef>(null);
  const [
    addProjectUsers,
    { loading: savingNewProjectUsers },
  ] = useAddProjectUsers();

  const [
    removeProjectUser,
    { loading: removingProjectUser },
  ] = useRemoveProjectUser();

  const [projectUsers, { loading, refetch }] = useGetProjectUsers({
    onCompleted: ({ project: result }) => {
      if (result?.users) {
        setUsers(result.users.map(u => u.user));
      }
    },
  });

  const [
    updateUserNameAndPosition,
    { loading: savingNameAndPosition },
  ] = useUpdateUser();

  const [changeUserRole, { loading: savingUserRole }] = useChangeUserRole();
  const [resendUserPassword] = useResendUserPassword();

  React.useEffect(() => {
    projectUsers({
      variables: {
        projectId: project.id,
      },
    });
  }, []);

  if (!me) {
    return <Spin />;
  }

  const canManage = me?.role !== Role.USER;

  const removeUser = async (userId: string) => {
    const result = await removeProjectUser({
      variables: {
        projectId: project.id,
        userId,
      },
    });

    if (result?.data?.removeProjectUser) {
      setUsers(result.data.removeProjectUser.map(u => u.user));

      if (refetch) {
        await refetch({ projectId: project.id });
      }
    }
  };

  const changeRole = async (user: UserType) => {
    await changeUserRole({
      variables: {
        userId: user.id,
        role: user.role === 'USER' ? Role.ADMIN : Role.USER,
      },
    });

    if (refetch) {
      await refetch({ projectId: project.id });
    }
  };

  const toggleSuperAdmin = async (user: UserType) => {
    changeUserRole({
      variables: {
        userId: user.id,
        role: user.role === Role.SUPER_ADMIN ? Role.ADMIN : Role.SUPER_ADMIN,
      },
    })
      .then(async () => {
        if (refetch) {
          await refetch({ projectId: project.id });
        }
      })
      .catch((e: Error) => {
        notification.error({
          message: 'Unable to change role',
          description: e.message,
        });
      });
  };

  const resetPassword = async (user: UserType) => {
    await resendUserPassword({
      variables: {
        userId: user.id,
      },
    });

    message.info('This user should get an email with a password reset email.');
  };

  const updateUser = async (userId: string) => {
    const name = (nameRef?.current?.input?.value as string) || '';

    const [firstName, lastName] = name.split(' ');
    const result = await updateUserNameAndPosition({
      variables: {
        data: {
          firstName,
          lastName,
          position: positionRef?.current?.input?.value || '',
        },
        userId,
      },
    });

    if (result.data?.updateUserDetails) {
      const idx = users?.findIndex(
        u => u.id === result.data?.updateUserDetails?.id
      );

      if (idx && idx > -1) {
        if (users) {
          users.slice(0, idx - 1);

          setUsers([
            ...users.slice(0, idx),
            ...[result.data?.updateUserDetails],
            ...users.slice(idx + 1),
          ]);
        } else {
          setUsers([result.data?.updateUserDetails]);
        }

        if (refetch) {
          await refetch({ projectId: project.id });
        }
      }

      setEditingUserId(undefined);
    }
  };

  const userActions = (user: UserType) => {
    if (!managing) {
      return undefined;
    }

    const actions = [];

    if (editingUserId !== user.id && !editingUserId) {
      if (!removingProjectUser) {
        if (user.status === UserStatus.CONFIRMED) {
          actions.push(
            <Tooltip title="Edit">
              <EditOutlined onClick={() => setEditingUserId(user.id)} />
            </Tooltip>
          );
        }

        if (user.id !== me.id) {
          if (removingProjectUser) {
            actions.push(<Spin size="small" />);
          } else {
            if (me.role !== 'USER' && user.status === UserStatus.UNCONFIRMED) {
              actions.push(
                <span>
                  <Tooltip title="Resend Password">
                    <Popconfirm
                      title="Are you sure you want to resend this users password?"
                      okText="YES"
                      cancelText="NO"
                      onConfirm={() => resetPassword(user)}
                    >
                      <UserSwitchOutlined />
                    </Popconfirm>
                  </Tooltip>
                </span>
              );
            }

            if (me.role === 'SUPER_ADMIN') {
              if (user.role === Role.ADMIN || user.role === Role.USER) {
                actions.push(
                  <span>
                    <Tooltip
                      title={
                        user.role === 'USER' ? 'Make Admin' : 'Remove Admin'
                      }
                    >
                      <Popconfirm
                        title={`Are you sure you want to set this user as ${
                          user.role === 'USER' ? 'ADMIN' : 'USER'
                        }`}
                        okText="YES"
                        cancelText="NO"
                        onConfirm={() => changeRole(user)}
                      >
                        {user.role === 'USER' ? (
                          <UserAddOutlined />
                        ) : (
                          <UserDeleteOutlined />
                        )}
                      </Popconfirm>
                    </Tooltip>
                  </span>
                );
              }
              if (user.role === Role.ADMIN || user.role === Role.SUPER_ADMIN) {
                actions.push(
                  <span>
                    <Tooltip
                      title={
                        user.role === Role.ADMIN
                          ? 'Make Super Admin'
                          : 'Remove Super Admin'
                      }
                    >
                      <Popconfirm
                        title={`Are you sure you want to set this user as ${
                          user.role === Role.ADMIN
                            ? Role.SUPER_ADMIN
                            : Role.ADMIN
                        }`}
                        okText="YES"
                        cancelText="NO"
                        onConfirm={() => toggleSuperAdmin(user)}
                      >
                        {user.role === Role.ADMIN ? (
                          <UserAddOutlined style={{ color: 'red' }} />
                        ) : (
                          <UserDeleteOutlined style={{ color: 'red' }} />
                        )}
                      </Popconfirm>
                    </Tooltip>
                  </span>
                );
              }
            }

            actions.push(
              <Tooltip title="Remove">
                <Popconfirm
                  title="Are you sure remove this user from this project?"
                  onConfirm={() => removeUser(user.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            );
          }
        }
      }
    } else if (editingUserId === user.id) {
      if (!savingNameAndPosition) {
        actions.push(
          <Tooltip title="Cancel">
            <CloseCircleOutlined onClick={() => setEditingUserId(undefined)} />
          </Tooltip>
        );

        actions.push(
          <Tooltip title="Save">
            <SaveOutlined onClick={() => updateUser(user.id)} />
          </Tooltip>
        );
      } else {
        actions.push(<Spin size="small" />);
      }
    }

    return actions;
  };

  const username = (user: UserType) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return user.email;
  };

  const usernameField = (user: UserType) => {
    if (editingUserId !== user.id) {
      return username(user);
    }

    return (
      <Input
        placeholder="Name"
        size="small"
        ref={nameRef}
        defaultValue={username(user)}
      />
    );
  };

  const positionField = (user: UserType) => {
    if (editingUserId !== user.id) {
      return user.position;
    }

    return (
      <Input
        placeholder="Position"
        size="small"
        ref={positionRef}
        defaultValue={user.position || ''}
      />
    );
  };

  const onAddNewUsers = async () => {
    const emails = getValidEmails(
      (newUsersRef.current?.resizableTextArea?.textArea?.value as string) || ''
    );

    if (!emails) {
      setShowNewUsersField(false);
      return;
    }

    const result = await addProjectUsers({
      variables: {
        projectId: project.id,
        invites: emails,
      },
    });

    if (result?.data?.addProjectUsers) {
      setUsers(result.data.addProjectUsers.map(u => u.user));
    }

    if (refetch) {
      await refetch({ projectId: project.id });
    }

    setShowNewUsersField(false);
  };

  return (
    <div css={projectInfoSectionStyles}>
      <div css={projectInfoModalTitleContainerStyles}>
        <div css={projectInfoModalTitleStyles}>Project Team</div>
        {!showNewUsersField && canManage && (
          <div>
            <Button
              css={grayButtonStyle}
              onClick={() => setManaging(!managing)}
            >
              {managing ? 'Done' : 'Manage team'}
            </Button>
            {managing && !showNewUsersField && (
              <Button
                css={buttonStyle}
                type="primary"
                onClick={() => setShowNewUsersField(true)}
              >
                Add new users
              </Button>
            )}
          </div>
        )}
      </div>
      {!showNewUsersField && (
        <List
          css={userListStyles}
          loading={loading}
          itemLayout="horizontal"
          dataSource={users}
          renderItem={user => (
            <List.Item actions={userActions(user)}>
              <List.Item.Meta
                css={userMetaStyles}
                avatar={
                  <Avatar
                    size="large"
                    icon={
                      user.status === UserStatus.CONFIRMED ? (
                        <UserOutlined />
                      ) : (
                        <Tooltip title="Unconfirmed">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      )
                    }
                  />
                }
                title={usernameField(user)}
                description={positionField(user)}
              />
            </List.Item>
          )}
        />
      )}
      {!!showNewUsersField && (
        <div>
          <TextArea
            ref={newUsersRef}
            rows={2}
            required
            placeholder="Enter emails of users to add to this project."
          />
          <div css={addUserActionsContainerStyles}>
            {!savingNewProjectUsers && (
              <Button size="small" onClick={() => setShowNewUsersField(false)}>
                Cancel
              </Button>
            )}
            <Button
              size="small"
              onClick={onAddNewUsers}
              type="primary"
              loading={savingNewProjectUsers}
            >
              Add
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const ProjectCategorizationSection = ({
  project,
}: {
  project: BaseProject;
}) => {
  const row = (label: string, value: string) => {
    return (
      <Row gutter={[5, 5]}>
        <Col span={5} css={categorizationRowLabelStyles}>
          {label}
        </Col>
        <Col span={2} css={categorizationRowDividerStyles}>
          <Divider />
        </Col>
        <Col span={17} css={categorizationRowValueStyles}>
          {value}
        </Col>
      </Row>
    );
  };

  const niceTestTypeLabel = project.testType
    .toLowerCase()
    .replace(/^\w/, c => c.toUpperCase());

  return (
    <div css={projectInfoSectionStyles}>
      <div css={projectInfoModalTitleStyles}>Project Categorization</div>
      <div css={projectCategorizationDetailsStyles}>
        {row('Division', project.division || '?')}
        {row('Test Type', niceTestTypeLabel)}
      </div>
    </div>
  );
};
