/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tooltip } from 'antd';
import * as React from 'react';
import { AccuracyIcons, centerInContainer } from '../../style';
import { parseNumber } from '../../utils/util';

export type AccuracyIconProps = {
  reliability: string | number | undefined;
  applicability: string | number | undefined;
  targetVariable: string;
};

export enum AccuracyTextValues {
  GOOD = 'Good',
  OKAY = 'Okay',
  LOW = 'Low',
  UNCERTAIN = 'Uncertain',
}

export const getAccuracyProps = ({
  applicability,
  reliability,
}: {
  applicability: number;
  reliability: number;
}) => {
  const accuracyProps = { accuracyText: '', accuracyDescText: '' };

  const lowestValue = Math.min(applicability, reliability);

  if (lowestValue >= 0.85) {
    /**
     * GOOD : If both applicability and reliabiltiy are above 85%
     */
    accuracyProps.accuracyText = AccuracyTextValues.GOOD;
    accuracyProps.accuracyDescText = 'Use for business decisions.';
  } else if (lowestValue >= 0.8) {
    /**
     * OKAY : If both applicability and reliabiltiy are above 80% and below 85%
     */
    accuracyProps.accuracyText = AccuracyTextValues.OKAY;
    accuracyProps.accuracyDescText = 'Use with caution for business decisions.';
  } else if (lowestValue >= 0.75) {
    /**
     * LOW : If both applicability and reliabiltiy are above 75% and below 80%
     */
    accuracyProps.accuracyText = AccuracyTextValues.LOW;
    accuracyProps.accuracyDescText = 'Use for exploratory purposes.';
  } else {
    /**
     * UNCERTAIN: If either applicability or reliability are below 75%
     */
    accuracyProps.accuracyText = AccuracyTextValues.UNCERTAIN;
    accuracyProps.accuracyDescText =
      'More data may be required to improve the accuracy level.';
  }

  return accuracyProps;
};

export const AccuracyTooltipContent = ({
  targetVariable,
  applicability,
  reliablity,
  accuracyText,
  accuracyDescText,
}: {
  targetVariable: string;
  applicability: number;
  reliablity: number;
  accuracyText: string;
  accuracyDescText: string;
}) => {
  return (
    <div>
      {accuracyText !== AccuracyTextValues.UNCERTAIN && (
        <div
          css={css`
            font-weight: bold;
            padding: 0px;
          `}
        >
          {targetVariable}: {accuracyText}
        </div>
      )}
      <div>{accuracyDescText}</div>
      <br />
      <div>Model applicability: {Number(applicability * 100).toFixed(2)}%</div>
      <div>Simulation reliability: {Number(reliablity * 100).toFixed(2)}%</div>
    </div>
  );
};

export const AccuracyIconVert = ({
  reliability,
  applicability,
  targetVariable,
}: AccuracyIconProps) => {
  const rel = parseNumber(reliability) || 0;
  const app = parseNumber(applicability) || 0;
  const accuracy = { applicability: app, reliability: rel };

  const getView = () => {
    const { accuracyText, accuracyDescText } = getAccuracyProps(accuracy);
    const icon = AccuracyIcons.find(ai => ai.text === accuracyText);

    const viewText =
      accuracyText === AccuracyTextValues.UNCERTAIN ? (
        <div>Uncertain</div>
      ) : (
        icon && (
          <div>
            <p
              css={css`
                margin-bottom: 0px;
              `}
            >
              {icon.text}
            </p>
            {icon.icon()}
          </div>
        )
      );

    return (
      <Tooltip
        title={
          <AccuracyTooltipContent
            applicability={app}
            reliablity={rel}
            targetVariable={targetVariable}
            accuracyText={accuracyText}
            accuracyDescText={accuracyDescText}
          />
        }
      >
        {viewText}
      </Tooltip>
    );
  };

  return <React.Fragment>{getView()}</React.Fragment>;
};

export const AccuracyIconHoriz = ({
  reliability,
  applicability,
}: AccuracyIconProps) => {
  const rel = parseNumber(reliability) || 0;
  const app = parseNumber(applicability) || 0;

  const getView = () => {
    const { accuracyText } = getAccuracyProps({
      applicability: app,
      reliability: rel,
    });

    if (accuracyText === AccuracyTextValues.UNCERTAIN) {
      return (
        <div
          css={css`
            margin-left: 10px;
          `}
        >
          Uncertain
        </div>
      );
    }

    const icon = AccuracyIcons.find(ai => ai.text === accuracyText);

    return (
      icon && (
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              margin-right: 10px;
              font-weight: 600;
            `}
          >
            {' ' + icon.text}
          </div>
          <div
            css={
              css`
                display: flex;
                width: 100%;
                align-items: center;
                svg {
                  height: 2em;
                  width: auto;
                }
              ` && centerInContainer
            }
          >
            {icon.icon()}
          </div>
        </div>
      )
    );
  };

  return <React.Fragment>{getView()}</React.Fragment>;
};
