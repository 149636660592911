/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  useWorkspace,
  spvSorter,
} from '../../../../../_shared/context/workspace-context';
import { VariableType } from '../../../../../../../__generated__/globalTypes';
import cloneDeep from 'lodash/cloneDeep';
import { SimulationFromIterationType } from '../../context/types';

/**
 * Default bar chart to show simulation results
 * Used for all projects unless a customization overrides it
 * @returns
 */
export const PredictedPerformanceBarChart = ({
  simulation,
}: {
  simulation: SimulationFromIterationType;
}) => {
  type ChartRecord = {
    targetVariableName: string;
    [productName: string]: string | number;
  };
  const formattedData: ChartRecord[] = [];
  const { outcomes, productColors } = useWorkspace();
  if (!simulation?.simulationProductVersion) {
    return null;
  }

  const spvs = cloneDeep(simulation.simulationProductVersion).sort(spvSorter);

  /**
   * The Vertical Bar Chart's data is an array of objects with the targetvariable name
   * And the keys of hte object being each product name to the value
   *
   * [
   *  {
   *    targetVariableName: {name of the target variable}
   *    {product 1}: { value for product 1}
   *    {product 2}: {value for product 2}
   *    .... etc
   *   }
   *
   * ]
   */

  /**
   * Each bar is tied to the product, each bar definition will be multiplied for every record found in the dataset
   * so this only defines what each bar template is, then the chart will duplicate it for you
   */
  const bars = spvs.map((s, i) => (
    <Bar
      key={s.productVersion.name}
      dataKey={s.productVersion.name}
      fill={`${productColors[i]}`}
    />
  ));

  outcomes
    ?.filter(o => o.targetVariable && o.outcomeType === VariableType.NUMERIC)
    .forEach(o => {
      const chartRec: ChartRecord = { targetVariableName: o.targetVariable };
      for (const [productName, outcome] of o.products.entries()) {
        chartRec[productName] = outcome.value;
      }
      formattedData.push(chartRec);
    });

  return (
    <ResponsiveContainer
      width="100%"
      height="85%"
      minHeight={25}
      minWidth={25}
      css={css`
        .recharts-tooltip-wrapper {
          // enable following to make it a fixed height with optional scroll
          //pointer-events: auto !important;
          //overflow-y: auto;
          //max-height: 400px;
          /* z-index: 1000; */
        }
      `}
    >
      <BarChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 4" />
        <XAxis tickLine={false} axisLine={false} dataKey="targetVariableName" />
        <YAxis
          tickLine={false}
          axisLine={false}
          //TODO: Make more universal, right now is zero based unless number is negative
          //Why isn't datamax working?????
          domain={[(dataMin: number) => (dataMin < 0 ? dataMin : 0), 'dataMax']}
        />
        <Tooltip cursor={{ fill: '#eeeeee' }} />
        <Legend />
        {...bars}
      </BarChart>
    </ResponsiveContainer>
  );
};
