/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

export const DisplaySlider = ({
  benchmarkVal,
  difference,
}: {
  benchmarkVal: string | undefined;
  difference: string;
}) => {
  const benchmarkNumberVal = parseFloat(benchmarkVal || '0');
  const diffNumberVal = parseFloat(difference || '0');
  const upper = benchmarkNumberVal + benchmarkNumberVal * 0.1;
  const lower = benchmarkNumberVal - benchmarkNumberVal * 0.1;
  const newLocation = benchmarkNumberVal + diffNumberVal;
  const CIRCLE_RADIUS = 8;

  // range = 10% +/- benchmark (50%)

  const circleStyle = css({
    '&:hover': {
      stroke: `#1C64A7AA`,
      strokeWidth: 4,
    },
  });

  let circleXAxis = ((newLocation - lower) / (upper - lower)) * 100;
  let rectXAxis = ((newLocation - lower) / (upper - lower)) * 100;

  if (newLocation < lower) {
    circleXAxis = 0;
    rectXAxis = 0;
  }

  if (newLocation > upper) {
    circleXAxis = 100;
  }

  const rectWidth =
    newLocation > benchmarkNumberVal ? circleXAxis - 50 : 50 - circleXAxis;

  if (newLocation > benchmarkNumberVal) {
    rectXAxis = 50;
  }

  //need to adjust for the space the circle takes up on the line
  let circleXAxisWithOffset: string | number = circleXAxis;

  if (circleXAxis !== 50) {
    const sign = circleXAxis < 50 ? '+' : '-';
    circleXAxisWithOffset = `calc(${circleXAxis}% ${sign} ${CIRCLE_RADIUS}px)`;
  }

  return (
    <div
      css={css`
        width: auto;
      `}
    >
      <svg
        width="100%"
        height="20"
        viewBox="0 0 100% 100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="6" width="95%" height="6" fill="#D2D2D2" />
        <rect
          x={rectXAxis + '%'}
          y="6"
          width={rectWidth + '%'}
          height="6"
          fill="#1C64A7"
        />
        <circle
          css={circleStyle}
          cx={circleXAxisWithOffset}
          cy="9"
          r={`${CIRCLE_RADIUS}`}
          fill="#1C64A7"
        />
      </svg>
    </div>
  );
};
