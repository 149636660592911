/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { CategoryDot } from '../../../../_shared/style';

export const SeverityDot = ({ severity }: { severity: number }) => {
  let color;

  // Grey 0 - 59 %
  // Yellow 60 - 79 %
  // Green 80% +
  if (severity >= 0.8) {
    color = 'green';
  } else if (severity >= 0.6 && severity < 0.8) {
    color = 'yellow';
  } else {
    color = 'grey';
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <CategoryDot color={color} />
    </div>
  );
};
