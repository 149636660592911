import React from 'react';
import { Card, Col, List, Row } from 'antd';
import {
  FormulationItemType,
  FormulationSource,
  useformulationDetailQuery,
  formulationDetailQuery,
} from '../../../../../__generated__/globalTypes';
import { useIngredients } from '../../../_shared/hooks';
import {
  FormulationType,
  useFormulations,
} from '../../../_shared/context/formulations-context';
import { formatSuffix } from '../../../_shared/utils/util';
import { IngredientCompositionCard } from '../../../components/experiments/experiment-detail/ingredient-composition';

import { OutcomesTable } from '../../../components/experiments/experiment-detail/outcomes-table';
import { ScenarioDetailsSection } from '../../../components/experiments/experiment-detail/scenario-details-section';

export const ExperimentDetail = ({
  experiment,
}: {
  experiment: FormulationType;
}) => {
  const { ingredientByName } = useIngredients();
  const { projectBenchmarkFormulation } = useFormulations();
  let benchmark: formulationDetailQuery['formulation'];

  // TODO: At somepoint have a store of "formulation with details already fetched!"
  const { data: formulationWithDesign, error } = useformulationDetailQuery({
    variables: {
      formulationId: experiment.id,
    },
  });
  const {
    data: benchmarkFromQuery,
    error: benchmarkError,
  } = useformulationDetailQuery({
    variables: {
      formulationId: projectBenchmarkFormulation?.id,
    },
  });
  if (benchmarkFromQuery?.formulation)
    benchmark = benchmarkFromQuery.formulation;

  const isSuggestedExperiment =
    experiment.source === FormulationSource.SUGGESTED;

  return (
    <div>
      <Row gutter={[0, 40]}>
        <Col id="ingredient-composition" span={24}>
          <Card
            title={
              <>
                Ingredients{' '}
                <span
                  style={{
                    color: '#A7ADB2',
                  }}
                >
                  ({experiment.items.length})
                </span>
              </>
            }
          >
            <List
              dataSource={experiment.items.filter(
                item => item.type === FormulationItemType.INPUT
              )}
              renderItem={item => {
                const ingredient = ingredientByName.get(item.variable.name);
                const unit = formatSuffix(ingredient?.unit);
                return (
                  <List.Item key={item.variable.name}>
                    <List.Item.Meta
                      title={
                        <span style={{ fontWeight: 500 }}>
                          {item.variable.name}
                        </span>
                      }
                    />
                    <div>
                      {item.value}
                      {!Number.isNaN(Number(item.value)) && !unit ? '%' : unit}
                    </div>
                  </List.Item>
                );
              }}
            />
          </Card>
        </Col>
        <Col id="ingredient-composition-comp" span={24}>
          <Row gutter={[30, 0]}>
            <Col
              span={formulationWithDesign?.formulation?.description ? 12 : 24}
            >
              {formulationWithDesign?.formulation
                ?.ingredientCompositionTotals &&
                formulationWithDesign?.formulation?.ingredientCompositionTotals
                  .length > 0 && (
                  <div style={{ paddingLeft: '-15px', paddingRight: '-15px' }}>
                    <IngredientCompositionCard
                      experiment={formulationWithDesign?.formulation}
                    />
                  </div>
                )}
            </Col>
            {formulationWithDesign?.formulation?.description && (
              <Col span={12}>
                <Card title="Formulation Explanation">
                  <p>{formulationWithDesign?.formulation?.description}</p>
                </Card>
              </Col>
            )}
          </Row>
        </Col>
        <Col id="outcomes" span={24}>
          {formulationWithDesign && (
            <OutcomesTable
              formulationWithDesign={formulationWithDesign}
              benchmark={benchmark}
            />
          )}
        </Col>
        {isSuggestedExperiment && (
          <Col id="scenario-details" span={24}>
            {formulationWithDesign &&
              formulationWithDesign.formulation?.source ===
                FormulationSource.SUGGESTED && (
                <ScenarioDetailsSection
                  formulationWithDesign={formulationWithDesign}
                />
              )}
          </Col>
        )}
      </Row>
    </div>
  );
};
