/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ConstraintInfoProp } from './design-constraints.component';
import {
  BulbFilled,
  BulbOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {
  constraintExampleContainer,
  designConstraintInfo,
  designConstraintInfoText,
} from './design-constraints.styles';
import { Collapse, Divider, Image, List } from 'antd';
import { ConstraintType } from '../../../../../../__generated__/globalTypes';
import { useState } from 'react';
import { Colors } from '../../../../_shared/style';
import React from 'react';

const { Panel } = Collapse;

const CompositionExample = () => (
  <Image
    src={'https://assets.turingsaas.com/composition-constraint-example.png'}
  />
);

const TargetExample = () => (
  <Image src={'https://assets.turingsaas.com/constraintExample-Target.png'} />
);

const GroupSelectionExampleA = () => (
  <Image
    src={'https://assets.turingsaas.com/constraintExample-GroupSelectionA.png'}
  />
);

const GroupSelectionExampleB = () => (
  <Image
    src={'https://assets.turingsaas.com/constraintExample-GroupSelectionB.png'}
  />
);

const RangeExample = () => (
  <Image src={'https://assets.turingsaas.com/constraintExample-Range.png'} />
);

export const DesignConstraintInfo = ({
  title,
  description,
  type,
}: ConstraintInfoProp) => {
  const ConstraintExample = () => {
    const [panelHeader, setPanelHeader] = useState<string>('Click for example');
    let example;

    switch (type) {
      case ConstraintType.COUNT:
        example = (
          <div css={constraintExampleContainer}>
            <h4>Group A, B, C, D</h4>
            <p>
              Select only 1 from this group <br />
              Lower Value: 1 | Upper Value: 1
            </p>
            <GroupSelectionExampleA />
            <Divider />
            <p>
              Select 2 to 3 from this group
              <br />
              Lower Value: 2 | Upper Value: 3
            </p>
            <GroupSelectionExampleB />
          </div>
        );
        break;
      case ConstraintType.EQUALITY:
        example = (
          <div css={constraintExampleContainer}>
            Ingredient A = 1.5% <br /> Ingredient B = 0% (Exclude)
            <TargetExample />
            Select as many ingredients you want to Include or Exclude
          </div>
        );
        break;
      case ConstraintType.RANGE:
        example = (
          <div css={constraintExampleContainer}>
            Ingredients will be between the two given values.
            <RangeExample />
            Select ingredients you want to limit to a defined range.
          </div>
        );
        break;
      case ConstraintType.AMOUNT:
        example = (
          <div css={constraintExampleContainer}>
            Ingredients A, B, C have different amounts of Moisture. Total
            Moisture content in formulations must be between 10-15% for the sum
            of all amounts of ingredients that contain Moisture
            <CompositionExample />
          </div>
        );
        break;
      default:
        example = undefined;
    }

    return (
      <Collapse
        ghost
        expandIcon={({ isActive }) => {
          setPanelHeader(
            isActive
              ? 'Click here to hide example'
              : 'Click here to show example'
          );
          return isActive ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />;
        }}
        css={css`
          background-color: ${Colors.WHISPER};
          margin-top: 10px;

          .ant-collapse-header-text {
            font-weight: 600;
          }
        `}
      >
        <Panel header={panelHeader} key="1">
          {example}
        </Panel>
      </Collapse>
    );
  };

  return (
    <div css={designConstraintInfo}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          css={css`
            margin-right: 10px;
          `}
        >
          <InfoCircleOutlined style={{ fontSize: '20px' }} />
        </div>
        <div css={designConstraintInfoText}>
          <h4>{title}</h4>
          <div>{description}</div>
        </div>
      </div>

      <div>
        <ConstraintExample />
      </div>
    </div>
  );
};
