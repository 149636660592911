/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Popconfirm } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../../../_shared/context/session-context';
import {
  useduplicateIterationMutation,
  useupdateOneIterationMutation,
  WorkspaceType,
} from '../../../../../__generated__/globalTypes';
import { iterationTableStyle, linkStyle } from './interation-table.styles';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { IterationType } from '@prisma/client';
//TODO: Make generic Graohql table display

export const IterationTable = ({
  onChange,
  filters,
  showArchived,
  setTableCount,
}: {
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, string[] | null>,
    sorter: any,
    extra: {
      currentDataSource: any[];
    }
  ) => void;
  setTableCount: (count: number) => void;
  filters?: Record<string, string[] | null>;
  showArchived: boolean;
}) => {
  const navigate = useNavigate();
  const { currentProject, user, useFetchProject } = useSession();
  const isSuperAdmin = user?.role === 'SUPER_ADMIN';

  const [updateOneIterationMutation] = useupdateOneIterationMutation();
  const [duplicateIterationMutation] = useduplicateIterationMutation();
  const [fetchProjectById] = useFetchProject();

  const handleWorkspaceDuplicate = async (iterationId: string) => {
    const duplicatedWorkspace = await duplicateIterationMutation({
      variables: {
        iterationId,
      },
    });
    const response = duplicatedWorkspace?.data?.duplicateIteration;
    await fetchProjectById({
      variables: {
        projectId: `${currentProject?.id}`,
      },
    });
    navigate(`/project/${response?.projectId}/iteration/${response?.id}`);
  };

  type RecordType = {
    id: string;
    key: string;
    name: string;
    description: string;
    createdBy: string;
    createdById: string;
    iterationKey: string;
    archived: boolean;
    type: WorkspaceType;
    workspaceId: string;
  };

  const handleArchive = async (record: RecordType) => {
    await updateOneIterationMutation({
      variables: {
        data: {
          projectId: `${currentProject?.id}`,
          id: record.key,
          archived: !record.archived,
        },
      },
    });
    // Refresh the project after iteration Archive
    await fetchProjectById({
      variables: {
        projectId: `${currentProject?.id}`,
      },
    });
  };

  const datasource: RecordType[] | undefined = currentProject
    ? currentProject.iterations
        .filter(
          i =>
            i.archived === showArchived && i.type !== WorkspaceType.EXPLORATION
        )
        .map(i => {
          return {
            id: i.id,
            key: i.id,
            name: i.name,
            type: WorkspaceType[i.type],
            workspaceId: `${currentProject?.key}-${i?.key}`,
            iterationKey: i?.key,
            createdBy: i.creator,
            createdById: i.createdById,
            archived: i.archived,
            description: i.description || '',
          };
        })
    : undefined;

  useEffect(() => {
    setTableCount(datasource?.length || 0);
  }, [datasource?.length]);

  const columns: ColumnsType<RecordType> = [
    {
      title: 'ID',
      dataIndex: 'workspaceId',
      key: 'workspaceId',
      ellipsis: true,
      width: '7vw',
      sorter: (a, b) => Number(a.iterationKey) - Number(b.iterationKey),
      onFilter: (value: string, record) =>
        record.workspaceId.indexOf(value) === 0,
      filters:
        datasource?.map(d => ({ text: d.workspaceId, value: d.workspaceId })) ||
        [],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      defaultSortOrder: 'descend',
      width: '30vw',
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value: string, record) => record.name.indexOf(value) === 0,
      filters: datasource?.map(d => ({ text: d.name, value: d.name })) || [],
      render: (name: string, record: RecordType) => (
        <Link
          to={`/project/${currentProject?.id || ''}/iteration/${record.key}`}
          css={linkStyle}
          onClick={() => {
            logEvent(TrackableEvent.ITERATION_TABLE_LINK_CLICKED, {
              projectId: currentProject?.id,
              iterationId: record.key,
            });
          }}
        >
          {name}
        </Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '10vw',
      defaultFilteredValue: filters?.['type'],
      sorter: (a, b) => a.type.localeCompare(b.type),
      onFilter: (value: string, record) => record.type.indexOf(value) === 0,
      filters: datasource?.reduce((acc: any, item) => {
        if (!acc.some((filter: any) => filter.value === item.type)) {
          acc.push({
            text:
              item.type === WorkspaceType.ADAPTIVE_LEARNING
                ? 'Adaptive Learning'
                : 'Lab Bench',
            value: item.type,
          });
        }
        return acc;
      }, []),
      render: (v, r) =>
        r.type === WorkspaceType.ADAPTIVE_LEARNING
          ? 'Adaptive Learning'
          : 'Lab Bench',
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '7vw',
      defaultFilteredValue: filters?.['createdBy'],
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
      onFilter: (value: string, record) =>
        record.createdBy.indexOf(value) === 0,
      filters: datasource?.reduce((acc: any, item) => {
        if (!acc.some((filter: any) => filter.value === item.createdBy)) {
          acc.push({ text: item.createdBy, value: item.createdBy });
        }
        return acc;
      }, []),
    },
    ...(user !== undefined
      ? [
          {
            title: 'Action',
            key: 'action',

            render: (_: any, record: any) => (
              <>
                {(record.createdById === user.id || isSuperAdmin) && (
                  <Popconfirm
                    title={`Are you sure you want to ${
                      record.archived ? 'unarchive' : 'archive'
                    } this workspace?`}
                    onConfirm={() => handleArchive(record)}
                    okText={
                      record.archived
                        ? 'Unarchive Workspace'
                        : 'Archive Workspace'
                    }
                    cancelText="Cancel"
                  >
                    <Button type="dashed">
                      {record.archived ? 'Unarchive' : 'Archive'}
                    </Button>
                  </Popconfirm>
                )}
                <Popconfirm
                  title={`Are you sure you want to duplicate this workspace?`}
                  onConfirm={() => handleWorkspaceDuplicate(record.id)}
                  okText={`Duplicate`}
                  cancelText="Cancel"
                >
                  <Button type="dashed">Duplicate</Button>
                </Popconfirm>
              </>
            ),
          },
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      dataSource={datasource}
      css={iterationTableStyle}
      pagination={{
        position: ['bottomCenter'],
      }}
      onChange={onChange}
    />
  );
};
