import { FormulationType } from '../../../_shared/context/formulations-context';
import {
  IterationTask,
  useupdateOneIterationTaskMutation,
  FormulationMetricType,
} from '../../../../../__generated__/globalTypes';
import { Button, Typography, notification } from 'antd';
import React, { useState } from 'react';
import { ExplorationResultTable } from '../exploration-scenario-results-table/exploration-results-table';
import { useDesign } from '../../../_shared/context/design-context';
import { maxBy } from 'lodash';
import { DesignStates } from '../../../components/workspaces/adaptive-learning/types';
import { IterationTaskType } from '../../../_shared/context/exploration-context';
const { Paragraph } = Typography;
export const ExperimentSuggestionMessage = ({
  formulations,
  task,
  handleChangeFormulationToView,
}: {
  task: IterationTaskType;
  handleChangeFormulationToView: (formulationToView: FormulationType) => void;
  formulations?: FormulationType[];
}) => {
  const { runDesignJob, designState } = useDesign();
  const [taskToView, setTaskToView] = useState<IterationTaskType>(task);
  const [updateOneIterationTask] = useupdateOneIterationTaskMutation();

  const handleSuggestionOnClick = async (suggestionAccepted: boolean) => {
    if (suggestionAccepted) {
      if (!task.aiSuggestedDesignRecordRequest) {
        notification.error({
          message: 'Error accepting suggestion',
          description: 'Suggested scenario not returned',
        });
        return;
      } else {
        await runDesignJob(task.aiSuggestedDesignRecordRequest.id, true);
      }
    }

    await updateOneIterationTask({
      variables: {
        data: {
          id: task.id,
          aiSuggestionAccepted: suggestionAccepted,
        },
      },
    });

    setTaskToView({
      ...task,
      aiSuggestionAccepted: suggestionAccepted,
    });
  };

  const topFormulation = maxBy(formulations, formulation => {
    const desirabilityMetric = formulation.metrics.find(
      metric => metric.type === FormulationMetricType.TOTAL_DESIRABILITY
    );

    return desirabilityMetric
      ? Number(desirabilityMetric.value)
      : Number.NEGATIVE_INFINITY;
  });

  return (
    <div
      style={{
        alignSelf: 'flex-start',

        display: 'flex',
      }}
    >
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <div
          style={{
            marginLeft: 5,
            marginBottom: 5,
            background: '#f6f6f6',
            padding: 10,
            borderRadius: 5,
          }}
        >
          <Paragraph style={{ marginBottom: 0 }}>
            {taskToView.result ||
              `⚠️ Your request resulted in formulations that were not better at reaching your goal from your closest formulation. Here's the best one we found 👇🏼`}
          </Paragraph>
        </div>
        <ExplorationResultTable
          handleChangeFormulationToView={handleChangeFormulationToView}
          formulations={[...(topFormulation ? [topFormulation] : [])]}
        />
        <div
          style={{
            marginLeft: 5,
            marginTop: 5,
            marginBottom: 5,
            background: '#f6f6f6',
            padding: 10,
            borderRadius: 5,
          }}
        >
          <Paragraph
            style={{
              marginBottom: 0,
              whiteSpace: 'break-spaces',
            }}
          >
            {taskToView.aiSuggestionMessage}
          </Paragraph>
        </div>
        {taskToView.aiSuggestionAccepted === null ? (
          <div
            style={{ display: 'flex', marginLeft: 5, gap: 10, maxWidth: 115 }}
          >
            <Button
              onClick={() => handleSuggestionOnClick(true)}
              size="small"
              block
              disabled={designState === DesignStates.RUNNING}
            >
              Yes
            </Button>
            <Button
              onClick={() => handleSuggestionOnClick(false)}
              size="small"
              block
              disabled={designState === DesignStates.RUNNING}
            >
              No
            </Button>
          </div>
        ) : taskToView.aiSuggestionAccepted ? (
          <>
            <div
              style={{
                marginLeft: 5,
                marginTop: 5,
                marginBottom: 5,
                background: '#f6f6f6',
                padding: 10,
                borderRadius: 5,
              }}
            >
              <Paragraph style={{ marginBottom: 0 }}>
                {`Great. We started the process of generating formulations using the recommended constraints. We will display a message once it's done.`}
              </Paragraph>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                marginLeft: 5,
                marginTop: 5,
                marginBottom: 5,
                background: '#f6f6f6',
                padding: 10,
                borderRadius: 5,
              }}
            >
              <Paragraph style={{ marginBottom: 0 }}>
                {
                  'Understood. Please make the desired changes in the right panel to generate new recommendations.'
                }
              </Paragraph>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
