/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  concat,
  ApolloLink,
} from '@apollo/client';

import { DashboardLayout } from '../dashboard/dashboard-layout.component';
import { useSession } from '../../_shared/context/session-context';
import { OnboardingUser } from '../onboarding/onboarding-user.component';
import {
  User,
  UserStatus,
  usegetCurrentUserQuery,
} from '../../../../__generated__/globalTypes';
import {
  centeredHorizontallyFlexStyle,
  centerVerticallyFlexStyle,
  fullScreenStyle,
} from '../../_shared/style';
import { useApi } from '../../_shared/api-hook';
import { FullscreenSpin } from '../../_shared/components/loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useQueryParams } from '../../_shared/hooks/use-query.hook';
import { NotFoundComponent } from '../../_shared/components/error-pages';
import { BaseClient } from '../../network/ApiClient';
const httpLink = new HttpLink({ uri: '/graphql' });

const AuthStateAppComponent = () => {
  const { accessToken } = useSession();
  const [apollo, setApollo] = useState<ApolloClient<any>>();
  const [apolloHasAuth, setApolloHasAuth] = useState(false);
  const { getAuthHeader } = useApi();
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const updateTokenAndUser = async () => {
    const token = await getAccessTokenSilently();
    BaseClient.setAuthToken(token);
  };

  useEffect(() => {
    const authHeader = getAuthHeader();
    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext({
        headers: { ...authHeader },
      });

      return forward(operation);
    });
    updateTokenAndUser()

    //Using the state of the accessToken alone causes one render
    //Where the app is authenticated but Apollo does not actually have the
    //Access token
    setApolloHasAuth(!!authHeader);

    if (!apollo) {
      setApollo(
        new ApolloClient({
          link: concat(authMiddleware, httpLink),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'network-only',
            },
            query: {
              fetchPolicy: 'network-only',
            },
          },
          cache: new InMemoryCache({
            typePolicies: {
              ProjectUser: {
                fields: {
                  user: {
                    merge: true,
                  },
                },
              },
              Project: {
                fields: {
                  users: {
                    // Incoming users are the most up to date
                    merge(existing: any[], incoming: any[]) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                      return incoming;
                    },
                  },
                },
              },
              /*Query: {
                fields: {
                  iterationTasks: {
                    keyArgs: ['iterationId'],
                    merge(existing: any[] = [], incoming: any[], { args }) {
                      const merged = existing ? existing.slice(0) : [];

                      const end =
                        args?.skip + Math.min(args?.take, incoming.length);

                      for (let i = args?.skip; i < end; ++i) {
                        merged[i] = incoming[i - args?.skip];
                      }

                      return merged;
                    },
                  },
                },
              },*/
            },
          }),
          //Set to false to force all queries to server
          queryDeduplication: true,
        })
      );
    } else {
      apollo.setLink(concat(authMiddleware, httpLink));
    }
  }, [accessToken]);

  if (apollo && apolloHasAuth) {
    return (
      <ApolloProvider client={apollo}>
        <EnforcedAuthenticatedRoutedApp />
      </ApolloProvider>
    );
  }
  return <FullscreenSpin />;
};
export const AuthStateApp = withAuthenticationRequired(AuthStateAppComponent);
const AuthenticatedRoutedApp = () => {
  const query = useQueryParams();
  const shouldLogout = query.get('logout') === 'true';
  const { logout } = useSession();
  const {
    loading: loadingUser,
    data: userData,
    error: userError,
  } = usegetCurrentUserQuery();
  const { setUser } = useSession();

  React.useEffect(() => {
    if (userData && userData.me && !shouldLogout) {
      setUser(userData.me as User);
    }
    if (shouldLogout) {
      logout();
    }
  }, [userData]);

  let NextComponent: React.FC = () => (
    //TODO: Emotion not working for
    <div
      css={[
        centeredHorizontallyFlexStyle,
        fullScreenStyle,
        centerVerticallyFlexStyle,
      ]}
    >
      <Spin size="large" />
    </div>
  );

  if (userError) {
    // This is a specific status code for when users are in Auth0 but not in the database
    //@ts-ignore
    if (userError.networkError?.statusCode === 403) {
      return (
        <NotFoundComponent
          message="You have been authenticated but are not currently part of a project."
          showLogoutLink
        />
      );
    }
  } else if (!loadingUser) {
    if (userData && userData.me) {
      const { me: user } = userData;

      if (user.status === UserStatus.UNCONFIRMED) {
        NextComponent = OnboardingUser;
      } else {
        // Everything looks good, send them to the dashboard
        NextComponent = DashboardLayout;
      }
    }
  }

  return <NextComponent />;
};
// Wraps the Authenticated Route Component in a HoC that enforces authentication.
const EnforcedAuthenticatedRoutedApp = withAuthenticationRequired(
  AuthenticatedRoutedApp
);
