import { SimulationProductVersion } from '../../../../../../__generated__/globalTypes';
import {
  AEROSOLS_CAN_LIFE_SPRAY_RATE,
  AEROSOLS_FLAMMABILITY,
} from '../../../../../iso/model-ids';
import { Outcome, OutcomeInfo, SimulationFromIterationType } from './types';

enum DataType {
  ColumnWise,
  RowWise,
}
/**
 * This function parses the simulation.visualizationOutput into a "standard"
 * Outcome[] format.  If some products format the visualizationOutput differently
 * that transformation can take place here without affecting the downstream UI.
 */
export const calculateOutcomes = (
  simulation: SimulationFromIterationType,
  modelId?: string
) => {
  //Data from ML API
  if (
    simulation.simulationProductVersion.find(
      (spv: SimulationProductVersion) => {
        return spv.outcomes.length > 0;
      }
    )
  ) {
    return getApiOutcomes(simulation);
  }

  //Data from Jar models

  let typeOfData = DataType.ColumnWise;
  const { visualizationOutput, simulationProductVersion: spvs } = simulation;

  const data = visualizationOutput as string[][];

  const headerSet = new Set(data[0]);

  const productNames = spvs.map(spv => spv.productVersion.name);

  for (let i = 0; i < spvs.length; i++) {
    if (!headerSet.has(spvs[i].productVersion.name)) {
      typeOfData = DataType.RowWise;
      break;
    }
  }

  const outcomes =
    typeOfData === DataType.ColumnWise
      ? getResultColumnwise(data, productNames, modelId)
      : getResultRowise(data);

  return outcomes;
};

const getApiOutcomes = (simulation: SimulationFromIterationType): Outcome[] => {
  type TargetVariable = string;
  type ProductName = string;
  const targets = new Map<TargetVariable, Map<ProductName, OutcomeInfo>>();
  const outcomesMap = new Map();
  simulation.simulationProductVersion.forEach(
    (spv: SimulationProductVersion) => {
      spv.outcomes.forEach(o => {
        const {
          outcomeType,
          distribution,
          applicability,
          reliability,
          value,
          outcomeName,
        } = o;
        outcomesMap.set(outcomeName, o);
        const productName = spv.productVersion.name;

        const dist: Map<string, number> = new Map();
        [...distribution]
          .sort((c, b) => b.probability - c.probability)
          .forEach(d => {
            dist.set(d.state, d.probability);
          });

        const outcomeInfo: OutcomeInfo = {
          accuracy: applicability || undefined,
          reliability,
          distribution: dist,
          value,
        };

        if (targets.has(o.outcomeName)) {
          //TODO this doesn't do anything?
          const products = targets.get(o.outcomeName)!;
          products.set(productName, outcomeInfo);
        } else {
          const products: Map<ProductName, OutcomeInfo> = new Map();
          products.set(productName, outcomeInfo);
          targets.set(o.outcomeName, products);
        }
      });
    }
  );

  const outcomes: Outcome[] = [];
  for (const [target, products] of targets.entries()) {
    outcomes.push({
      products,
      outcomeType: outcomesMap.get(target).outcomeType,
      targetVariable: target,
    });
  }
  return outcomes;
};

/**
 * Product names are column headers and the formulations go down the y axis
 */
export const getResultColumnwise = (
  data: string[][],
  productNames: string[],
  modelId: string | undefined
): Outcome[] | undefined => {
  const [headerRow, ...records] = data;
  //Finds the first column name not associated with a product
  //assumes its the outcome, should probably map to the model target
  const targetVariableColumnIdx = headerRow.findIndex(
    productOrVariableName =>
      !productNames.find(name => name === productOrVariableName)
  );

  if (targetVariableColumnIdx < 0) return undefined;

  //TODO: On^3 need to remove some loops
  return records.map(record => {
    let targetVarName = '';
    /**
     * TODO: Rip out eventually
     */
    if (modelId === AEROSOLS_FLAMMABILITY) {
      targetVarName = `${record[targetVariableColumnIdx]} ${
        record[targetVariableColumnIdx + 1]
      } ${record[targetVariableColumnIdx + 2]}`;
    } else if (modelId === AEROSOLS_CAN_LIFE_SPRAY_RATE) {
      targetVarName = `${record[targetVariableColumnIdx]} ${
        record[targetVariableColumnIdx + 1]
      }`;
    } else {
      targetVarName = record[targetVariableColumnIdx];
    }

    // Only numeric for column/row-wise outcomes?
    const outcome: Outcome = {
      targetVariable: targetVarName,
      outcomeType: 'NUMERIC',
      products: new Map<string, OutcomeInfo>(),
    };

    headerRow.forEach((productOrVariableName, idx) => {
      const spv = productNames.find(name => name === productOrVariableName);

      //Found a matching product name to the header row
      if (spv) {
        outcome.products.set(productOrVariableName, {
          value: record[idx],
        });
      }
    });

    return outcome;
  });
};

/**
 * Product names are the first item in a row and formulations go across the x axis
 */
export const getResultRowise = (data: string[][]): Outcome[] | undefined => {
  const [headerRow, ...records] = data;

  const [, ...targetVariables] = headerRow;

  //Output variable name to the outcome
  const outcomeMap = new Map<string, Outcome>();

  targetVariables.forEach(t => {
    outcomeMap.set(t, {
      targetVariable: t,
      outcomeType: 'NUMERIC',
      products: new Map<string, OutcomeInfo>(),
    });
  });

  for (let i = 0; i < records.length; i++) {
    const productName = records[i][0];

    for (let y = 1; y < records[i].length; y++) {
      //Add the amount to each outcome variable
      const outcome = outcomeMap.get(targetVariables[y - 1])!;
      outcome.products.set(productName, { value: records[i][y]! });
      outcomeMap.set(targetVariables[y - 1], outcome);
    }
  }

  const result = Array.from(outcomeMap.values());
  return result;
};
