/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Select } from 'antd';

import { Colors } from '../../../../iso/colors';

const { Option } = Select;

export const IngredientSelect = ({
  value,
  setValue,
  ingredientList,
}: {
  value?: string;
  setValue: (value: string) => void;
  ingredientList: string[];
}) => (
  <div
    css={css`
      margin-left: 8px;

      .ant-select-selector {
        color: ${Colors.BRANDEIS_BLUE};
        border: none !important;
        background-color: transparent !important;
        position: relative;
        top: -3px;
      }

      .ant-select-arrow {
        color: ${Colors.BRANDEIS_BLUE};
        margin-top: -10px;
      }
    `}
  >
    <Select
      value={value}
      onChange={setValue}
      style={{
        fontSize: 16,
        width: 200,
        display: 'inline-block',
      }}
    >
      {ingredientList.map(i => (
        <Option key={i} value={i}>
          {i}
        </Option>
      ))}
    </Select>
  </div>
);
