/** @jsxImportSource @emotion/react */
import { ArrowRightOutlined } from '@ant-design/icons';
import { css, jsx } from '@emotion/react';
import { HistoryChange } from '@root/components/workspaces/lab-bench/context';

import { FormulationChangeSliderWithValue } from '../formulation-change-slider.component';
import { DisplayInfo } from '../types';

export type AppliedChangesDetailsProps = {
  ingredientChanges: DisplayInfo[];
  baselineName: string;
  currentChange: HistoryChange;
};
export const AppliedChangesDetails = ({
  baselineName,
  currentChange,
  ingredientChanges,
}: AppliedChangesDetailsProps) => {
  return (
    <div
      css={css`
        display: block;
        padding: 20px 0px;
        border-top: 2px dotted rgba(0, 0, 0, 0.2);
        margin: 22px 95px 0px 43px;
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 0px 11px;
          color: rgba(0, 0, 0, 0.4);
        `}
      >
        <div
          css={css`
            font-weight: 600;
            margin-right: 10px;
          `}
        >
          APPLIED CHANGES
        </div>
        <div>
          {`${baselineName} `}
          <ArrowRightOutlined /> {currentChange.changeName}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {ingredientChanges.map(i => (
          <div
            key={i.ingredientName + 'ImpactSlider'}
            css={css`
              margin-right: 26px;
              margin-left: 11px;
            `}
          >
            <FormulationChangeSliderWithValue
              ingredientName={i.ingredientName}
              benchmarkValue={i.benchmarkValue}
              difference={i.difference!}
              textColor="#000000"
              ingredientValue={i.value}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
