/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { Avatar, Card } from 'antd';

const { Meta } = Card;
export const QuickActionsButton = ({
  icon,
  title,
  description,
  action,
  isDisabled,
}: {
  icon: React.ReactElement;
  title: string;
  description: string;
  action?: () => void;
  isDisabled?: boolean;
}) => {
  return (
    <>
      {!isDisabled && (
        <Card
          style={{
            width: 600,
            cursor: 'pointer',
            border: 'solid .75px #1a353f',
            borderRadius: 5,
          }}
          onClick={action}
          bordered
          hoverable
        >
          <Meta
            avatar={<Avatar size="large" icon={icon} />}
            title={title}
            description={description}
          />
        </Card>
      )}
    </>
  );
};
