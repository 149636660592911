export enum DebugLevelsEnum {
  ENABLE_FULL_DEBUG = 'ENABLE_FULL_DEBUG',
  LOG_OPTIMIZATION_CONTEXT = 'LOG_OPTIMIZATION_CONTEXT',
  LOG_DESIGN_CONTEXT = 'LOG_DESIGN_CONTEXT',
  LOG_SCENARIO_DETAILS_CONTEXT = 'LOG_SCENARIO_DETAILS_CONTEXT',
  LOG_SESSION_CONTEXT = 'LOG_SESSION_CONTEXT',
  LOG_WORKSPACE_CONTEXT = 'LOG_WORKSPACE_CONTEXT',
}

export const ENABLE_FULL_DEBUG =
  localStorage.getItem(DebugLevelsEnum.ENABLE_FULL_DEBUG) === 'true'
    ? true
    : false;

if (ENABLE_FULL_DEBUG) {
  for (let item in DebugLevelsEnum) {
    localStorage.setItem(item, 'true');
  }
}

export const LOG_OPTIMIZATION_CONTEXT =
  localStorage.getItem(DebugLevelsEnum.LOG_OPTIMIZATION_CONTEXT) === 'true'
    ? true
    : false;
export const LOG_DESIGN_CONTEXT =
  localStorage.getItem(DebugLevelsEnum.LOG_DESIGN_CONTEXT) === 'true'
    ? true
    : false;

export const LOG_SCENARIO_DETAILS_CONTEXT =
  localStorage.getItem(DebugLevelsEnum.LOG_SCENARIO_DETAILS_CONTEXT) === 'true'
    ? true
    : false;
export const LOG_SESSION_CONTEXT =
  localStorage.getItem(DebugLevelsEnum.LOG_SESSION_CONTEXT) === 'true'
    ? true
    : false;
export const LOG_WORKSPACE_CONTEXT =
  localStorage.getItem(DebugLevelsEnum.LOG_WORKSPACE_CONTEXT) === 'true'
    ? true
    : false;
