/** @jsxImportSource @emotion/react */

import { Spin } from 'antd';
import {
  centeredHorizontallyFlexStyle,
  centerVerticallyFlexStyle,
  fullScreenStyle,
} from '../../../_shared/style';

export const FullscreenSpin = () => {
  return (
    <div
      css={[
        centeredHorizontallyFlexStyle,
        fullScreenStyle,
        centerVerticallyFlexStyle,
      ]}
    >
      <Spin size="large" />
    </div>
  );
};
