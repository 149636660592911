import {
  Objective,
  Constraint,
  ConstraintInputType,
} from '../../../../../../../../__generated__/globalTypes';
import {
  IterationWithLatestSimulation,
  SimulationProductVersionResultType,
} from '../../../context';

export enum Priorities {
  HIGH_PRIORITY = 'High Priority',
  MEDIUM_PRIORITY = 'Medium Priority',
  LOW_PRIORITY = 'Low Priority',
}

export type PriorityLabel =
  | Priorities.HIGH_PRIORITY
  | Priorities.MEDIUM_PRIORITY
  | Priorities.LOW_PRIORITY;

export enum OptimizationStates {
  FINISHED = 'FINISHED',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  EDITING = 'EDITING',
  NO_RESULTS = 'NO_RESULTS',
}

export type OptimizationContextProviderProps = {
  children?: React.ReactNode;
  iteration?: IterationWithLatestSimulation;
};

export interface OptimizationContextProps {
  isSimulationRunning: boolean;
  objectivesByTarget: Map<string, Objective>;
  cancelOptimization: (optimziationId: string) => Promise<void>;
  optimizationState: OptimizationStates;
  initiateOptimizationJob: () => void;
  constraints: (Constraint | ConstraintInputType)[];
  //Does this really need to be exposed on the optimization context
  // its already in the workspace context
  optimizedProducts: Array<SimulationProductVersionResultType>;
  fillerIngredient?: string;
  objectivesAreInEditMode: boolean;
  resetOptimizationState: () => void;
  setObjectivesAreInEditMode: (o: boolean) => void;
  errorDescription?: string;
}
