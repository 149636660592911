/** @jsxImportSource @emotion/react */
import { Progress } from 'antd';
import { css, jsx } from '@emotion/react';

export const ResultBar = ({
  percent,
  color,
}: {
  percent: number;
  color: string;
}) => {
  return (
    <div
      css={css`
        width: ${percent}%;
        background-color: ${color};

        height: 6px;
        border-radius: 4px;
        margin-top: 3px;
      `}
    />
  );
};
