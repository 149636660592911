import React from 'react';
import { Row, Tag, Typography } from 'antd';
import { css } from '@emotion/react';

const { Text } = Typography;

export const Data = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <React.Fragment>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
      }}
    >
      <Row>
        <Text
          style={{
            color: '#a7adb2',
            fontWeight: 500,
          }}
        >
          {title}
        </Text>
      </Row>
      <Row>
        <Text
          type="secondary"
          style={{
            color: '#161f26',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px',
          }}
        >
          {children}
        </Text>
      </Row>
    </div>
  </React.Fragment>
);
