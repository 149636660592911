/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { SimulationProductVersionResultType } from '../../../../../_shared/hooks';
import { WorkspaceProjectColors } from '../../../../../_shared/style';
import {
  getDisplayDifference,
  isSingleProductOutcome,
  parseNumber,
} from '../../../../../_shared/utils/util';
import { Outcome, SingleProductOutcome, useWorkspace } from '../../context';
import { ResultBar } from '../simulation-result-table/result-bar.component';

export const NumericOutcomeResult = ({
  outcome,
  sim,
  benchmark,
  barColor,
}: {
  outcome: Outcome | SingleProductOutcome;
  sim: SimulationProductVersionResultType;
  benchmark?: SimulationProductVersionResultType;
  barColor?: string;
}) => {
  const { productColors, outcomes } = useWorkspace();
  const productColor = barColor ?? productColors[sim.columnNumber];

  const getNumericOutcomeVal = (
    o: Outcome,
    s: SimulationProductVersionResultType
  ): string => {
    let returnVal;
    const productOutcome = o.products.get(s.productVersion.name);
    if (productOutcome !== undefined) {
      returnVal = parseNumber(productOutcome.value)?.toFixed(2);
    }

    return returnVal || '';
  };

  const getPercentage = (
    o: Outcome | SingleProductOutcome,
    s: SimulationProductVersionResultType
  ): { product: number; benchmark: number; displayDifference?: string } => {
    const percentages = {
      product: 0,
      benchmark: 0,
    } as { product: number; benchmark: number; displayDifference?: string };

    //Nothing to compare to
    if (!benchmark) {
      percentages.product = 100;
      return percentages;
    }

    let benchVal = 0;
    if (!isSingleProductOutcome(o)) {
      benchVal = Number(o.products.get(benchmark.productVersion.name)?.value);
    } else {
      const benchOutcome = outcomes?.find(
        out => o.targetVariable === out.targetVariable
      );
      if (benchOutcome)
        benchVal = Number(getNumericOutcomeVal(benchOutcome, benchmark));
    }

    let productVal = !isSingleProductOutcome(o)
      ? Number(o.products.get(s.productVersion.name)?.value)
      : Number(o.outcomeInfo.value);

    if (isNaN(benchVal) || isNaN(productVal)) {
      return percentages;
    }

    let [minVal, denominator] =
      productVal > benchVal ? [benchVal, productVal] : [productVal, benchVal];

    if (minVal <= 0) {
      // The lower value is <=-0: Means the percentages will be greater than 100 (or 0)
      //   Make benchmark & product values +ve by adding the minVal and an offset of 10
      minVal = -minVal + 10;
      benchVal += minVal;
      productVal += minVal;
      denominator += minVal;
    }

    percentages.product = (productVal / denominator) * 100;
    percentages.benchmark = (benchVal / denominator) * 100;
    percentages.displayDifference = getDisplayDifference(productVal, benchVal);
    return percentages;
  };

  const {
    product: productPercent,
    benchmark: benchPercent,
    displayDifference,
  } = getPercentage(outcome, sim);
  const displayDiffStr = displayDifference
    ? '(' + displayDifference + ')'
    : '(+/-0)';

  const getSingleProdNumericVal = (o: SingleProductOutcome) => {
    return (
      <span>
        {o.targetVariable + ': '}
        <span
          css={css`
            font-weight: 700;
          `}
        >
          {o.outcomeInfo.value}
        </span>
      </span>
    );
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div>
        {isSingleProductOutcome(outcome)
          ? getSingleProdNumericVal(outcome)
          : getNumericOutcomeVal(outcome, sim)}
        {sim.productVersion.isBenchmark === false && benchmark && (
          <span
            css={css`
              color: '#03191e';
              opacity: 0.6;
              padding-left: 5px;
            `}
          >
            {displayDiffStr}
          </span>
        )}
      </div>
      {!sim.productVersion.isBenchmark && (
        <div>
          <ResultBar percent={productPercent} color={productColor} />

          <ResultBar
            percent={benchPercent}
            color={
              sim.productVersion.isBenchmark
                ? '#FFFFFF'
                : WorkspaceProjectColors.benchmark
            }
          />
        </div>
      )}
    </div>
  );
};
