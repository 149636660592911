import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

export interface Auth0ProviderProps {
  children: any;
}

export const Auth0ProviderWithHistory = (props: Auth0ProviderProps) => {
  const { children } = props;
  return (
    <Auth0Provider
      domain={__AUTH0_DOMAIN__}
      clientId={__AUTH0_CLIENT_ID__}
      redirectUri={window.location.origin}
      audience={__AUTH0_AUDIENCE__}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
