/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { Divider, Collapse } from 'antd';

import { projectById_project_ingredientList } from '../../../../_shared/hooks/__generated__/projectById';
import { IngredientType } from '../../../../_shared/hooks';
import { PanelIngredient } from '../ingredient';
import { getIngredientCategories_project_ingredientCategory as CategoryType } from '../../../../_shared/hooks/__generated__/getIngredientCategories';
import { useWorkspace } from '../../../../_shared/context/workspace-context';
import camelCase from 'lodash/camelCase';
import { SimulationProductVersionResultType } from '../context/types';

const { Panel } = Collapse;

export const IngredientCollapse = ({
  categories,
  ingredientsByCategories,
  benchmarkProduct,
  productVersion,
}: {
  categories: CategoryType[];
  ingredientsByCategories: Map<string, projectById_project_ingredientList[]>;
  productVersion: SimulationProductVersionResultType['productVersion'];
  benchmarkProduct: SimulationProductVersionResultType | undefined;
}) => {
  const { updateProductFormulation } = useWorkspace();

  const displayedCategories = categories?.filter(
    c => !!ingredientsByCategories.get(c.name)?.length
  );

  const createCategoryPanelKey = (category: CategoryType) => {
    return camelCase(category.name + 'IngrPanel');
  };

  return (
    <Collapse
      activeKey={displayedCategories.map(c => createCategoryPanelKey(c))}
      expandIcon={() => undefined}
    >
      {displayedCategories.map(c => {
        const ingredientArray = ingredientsByCategories.get(c.name) ?? [];

        return (
          <Panel
            header={
              <span>
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  {`${c.name} `}
                </span>
                <span className="pill-ingredient-len">
                  ({ingredientArray.length ?? 0})
                </span>
              </span>
            }
            key={createCategoryPanelKey(c)}
          >
            {ingredientArray.map(ing => {
              return (
                <React.Fragment
                  key={ing.ingredient.name + 'IngredientCollapse'}
                >
                  <PanelIngredient
                    ingredient={ing as IngredientType}
                    isProductBenchmark={!!productVersion.isBenchmark}
                    benchmarkValue={
                      benchmarkProduct?.productVersion.formulation?.quantities[
                        ing.ingredient.id
                      ]
                    }
                    initialValue={
                      productVersion.formulation?.quantities[ing.ingredient.id]
                    }
                    setValue={value => {
                      updateProductFormulation({
                        value,
                        productName: productVersion.name,
                        ingredientId: ing.ingredient.id,
                      });
                    }}
                  />
                  <Divider dashed />
                </React.Fragment>
              );
            })}
          </Panel>
        );
      })}
    </Collapse>
  );
};
