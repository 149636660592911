/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ChangeEvent,
  useEffect,
  useState,
  HTMLInputTypeAttribute,
} from 'react';
import { Input } from 'antd';
import { EditPencilButton } from '../button/pencil-edit.button';
import { formatSuffix } from '../../../_shared/utils/util';
const acceptablePercentageChars = new Set([
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '0',
  '.',
  '',
]);

const acceptableNumericChars = new Set([...acceptablePercentageChars, '-']);

export enum InputType {
  PERCENT,
  STRING,
  CURRENCY,
  NUMERIC,
}

export const EditableInput = ({
  value,
  setValue,
  onEdit,
  onChange: externalOnChange,
  showDisplayState = false,
  type = InputType.PERCENT,
  autofocus = false,
  className,
  isDisabled,
  currencyUnit = '',
  status,
  suffix,
  inputType,
  min,
  max,
}: {
  value?: string | number;
  setValue: (value?: string | number | undefined) => void;
  onEdit?: () => void;
  onChange?: (value: string | number | undefined) => void;
  type?: InputType;
  autofocus?: boolean;
  className?: string;
  /**
   * defaults to false, if true the component displays the text value with an edit icon
   * when the user clicks the component it transitions to an editable state.
   *
   * if false, the inherited input component is always shown.
   */
  showDisplayState?: boolean;
  isDisabled?: boolean;
  currencyUnit?: string;
  status?: string | null;
  suffix?: string | null;
  inputType?: HTMLInputTypeAttribute;
  min?: number;
  max?: number;
}) => {
  const [internalValue, setInternalValue] = useState<string | undefined>();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => setInternalValue(value?.toString()), [value]);

  const onDone = () => {
    setIsEditing(false);
    setValue(internalValue);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    onEdit?.();
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;

    if (type === InputType.STRING) {
      setInternalValue(v);

      externalOnChange?.(v);
      return;
    }
    /**
     * For InputType.Percent or InputType.Numeric
     * Only allow number type chars in, regex was difficult to figure out
     */
    const inputChar = v.slice(-1);

    let isAcceptableChar = acceptableNumericChars.has(inputChar);

    if (type === InputType.PERCENT) {
      isAcceptableChar = acceptablePercentageChars.has(inputChar);
    }

    let updatedVal = v;

    if (!isAcceptableChar) {
      updatedVal = v.slice(0, -1);
    }

    setInternalValue(updatedVal);
    externalOnChange?.(updatedVal);
  };

  const formatDisplayValue = () => {
    if (internalValue === undefined) {
      return '';
    }
    if (type === InputType.STRING) {
      return internalValue;
    }

    //Format the percent
    return `${internalValue}%`;
  };

  const errorStyles = { border: '1px solid red' };

  const input = (
    <Input
      className={className ?? ''}
      css={css`
        height: 32px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        -moz-appearance: textfield;
        .ant-input-suffix {
          max-width: 65%;
          display: inline-block;
          overflow: hidden;
        }
      `}
      style={status === 'error' ? errorStyles : {}}
      autoFocus={autofocus}
      value={internalValue}
      onChange={handleOnChange}
      type={inputType}
      min={min}
      max={max}
      //TODO: Onblur is calling the onDone onRender
      //@ts-ignore
      onBlur={onDone}
      onPressEnter={onDone}
      suffix={formatSuffix(suffix)}
      prefix={type === InputType.CURRENCY ? currencyUnit : ''}
      disabled={isDisabled}
    />
  );

  return (
    <EditPencilButton
      displayedValue={formatDisplayValue()}
      show={showDisplayState && !isEditing}
      onClick={handleEditClick}
    >
      {input}
    </EditPencilButton>
  );
};
