import { css } from '@emotion/react';
import { Colors } from '../../../../_shared/style';

export const hintStyle = css`
  display: flex;
  justify-content: center;
`;

export const constraintContainerStyle = css`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const designConstraintIngInfo = css`
  display: flex;
  width: 93%;
  justify-content: space-between;
  float: left;
`;

export const designConstraintIngredient = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${Colors.ALTO};
  background: ${Colors.ALABASTER};
  padding: 8px;
`;

export const amountConstraintIngredient = css`
  align-items: center;
  margin-bottom: 8px;
`;

export const amountConstraintIngName = css`
  font-size: 10px;
  color: ${Colors.STORM_GREY};
  vertical-align: top;
  width: 50%;
`;

export const amountConstraintValue = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const equalityConstraintIngredient = css`
  margin-top: 15px;
`;

export const equalityConstraintIngName = css`
  width: 60%;
  font-weight: 500;
  align-self: center;
`;

export const equalityConstraintErrorMessage = css`
  font-weight: 700;
  color: ${Colors.LUST};
  font-size: 11px;
  align-self: center;
  margin: 5px 5px 0px auto;
`;

export const equalityConstraintIngValue = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 125px;
`;

export const designConstraintInfo = css`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.CONCRETE};
  border-radius: 4px;
  padding: 16px;
`;

export const designConstraintInfoCircle = css`
  margin-right: 10px;
`;

export const designConstraintInfoText = css`
  background-color: ${Colors.WHITE};

  & h4 {
    margin-bottom: 3px;
  }

  & div {
    margin-bottom: 6px;
  }
`;

export const constraintExampleContainer = css`
  text-align: center;
`;

export const constraintCloseButton = css`
  float: right;
  display: flex;
  align-self: center;
  margin: auto;
`;

export const constraintIngredientSearch = css`
  display: grid;
  margin-bottom: 24px;

  h3 {
    margin: 0px;
  }
`;

export const addValueStyle = css`
  margin-top: 24px;

  h3 {
    margin-bottom: 12px;
  }
`;

export const addValueBody = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  div {
    margin: 0px 16px 0px 0px;

    & p {
      margin-bottom: 0px;
    }
  }
`;
