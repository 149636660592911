import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const userTileStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
  marginRight: '10px',
  color: '#FFFFFF',
});

export const dropdownAvatarStyle = css({
  cursor: 'pointer',
});

export const askTuringStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 13,
  color: Colors.STORM_GREY,
  cursor: 'pointer',
});

export const askTuringIconContainerStyle = css({
  padding: '8px 10px 0 10px',
  borderRadius: '6px',
  marginRight: 10,
  height: '40px',
  display: 'grid',
  backgroundColor: Colors.WHITE,

  svg: {
    fontSize: 32,
  },
});
