/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect } from 'react';

import { useGraph } from '../../_shared/context';

export interface GraphContainerProps {}

export const GraphContainer: React.FC<GraphContainerProps> = () => {
  const { graph, isInExpertKnowledgeContext } = useGraph();

  const { graphContainerId } = graph;

  useEffect(() => {
    /*
      The Expert Knowledge context provider will handle first draw() to prevent flickering
    */
    if (!isInExpertKnowledgeContext) {
      graph?.draw();
    }
  }, [graph, isInExpertKnowledgeContext]);

  return (
    <div
      css={css`
        height: 100%;
      `}
      id={graphContainerId}
    />
  );
};
