import { Typography } from 'antd';
import { IngredientType } from '../../../_shared/hooks';
import { formatCostScore } from '../../../_shared/utils/component';
import React from 'react';
import { css } from '@emotion/react';
const { Text } = Typography;
export const Price = ({
  ingredient,
  monetaryUnit,
  costMeasurementUnit,
  prefixText,
}: {
  ingredient: IngredientType;
  monetaryUnit?: string;
  costMeasurementUnit?: string;
  prefixText?: string;
}) => {
  return (
    <>
      {ingredient?.price !== undefined &&
        ingredient?.price !== null &&
        ingredient?.price > -1 && (
          <>
            <Text type="secondary" style={{ fontSize: '12px' }}>
              <span> {` ${prefixText ?? ''} `}</span>
              {formatCostScore(
                ingredient?.price,
                costMeasurementUnit,
                monetaryUnit
              )}
            </Text>
          </>
        )}
    </>
  );
};
