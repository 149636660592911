import { Button, Modal } from "antd"
import React, { useState } from "react"

interface DuplicateProjectModalProps {
  openModal: boolean,
  projectId: string,
  orgId: string
  copyProjectMutation: (
    orgId: string,
    projectId: string,
    duplicateWithModel: boolean) => void,
  onClose: () => void
}

export const DuplicateProjectModal: React.FC<DuplicateProjectModalProps> = ({ openModal, projectId, orgId, copyProjectMutation, onClose }) => {

  const [isModalOpen, setIsModalOpen] = useState(openModal);

  const handleCreateAsCopy = () => {
    setIsModalOpen(false);
    copyProjectMutation(orgId, projectId, true)
  };

  const handleCreateAsDraft = () => {
    setIsModalOpen(false);
    copyProjectMutation(orgId, projectId, false)
  };

  const handleClose = () => {
    onClose()
  }

  return (
    <div>
      <Modal title="You are about to create a copy of a READY project."
        open={isModalOpen}
        onCancel={handleClose}
        okText="Use the same model"
        footer={[
          <Button key="back" onClick={handleCreateAsDraft}>
            Copy as DRAFT
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateAsCopy}>
            Use the same SETTINGS
          </Button>,
        ]}>
        <p>You can either choose to to use the same settings or go back to setup page with all the data</p>
      </Modal>
    </div>
  )
}