import React, { useState } from 'react';
import { Button, Divider, Popover, Progress, Spin, Typography } from 'antd';
import { limitDecimals } from '../../../_shared/utils/util';
import {
  Formulation,
  IngredientList,
  projectByIdQuery,
  useformulationWithDesignLazyQuery,
  useformulationWithDesignQuery,
} from '../../../../../__generated__/globalTypes';
import { formatObjectiveText } from '../experiment-detail/experiment-detail-util';
import { formatConstraintText } from '../../../components/exploration/new-experiment/constraint-row-component';
import { useIngredients } from '../../../_shared/hooks';
import { useSession } from '../../../_shared/context';
import { ArrowRightOutlined } from '@ant-design/icons';

type SourcePopover = {
  formulation: Formulation;
  children: JSX.Element | JSX.Element[];
  onClick: (designId: string) => void;
  selectedScenarios: string[];
  setSelectedScenarios: (selectedScenarios: string[]) => void;
};
const { Text } = Typography;
export const SourcePopover = ({
  children,
  formulation,
  onClick,
}: SourcePopover) => {
  // TODO: Fetch formulation's design job here

  const [
    fetchFormulation,
    { loading: formulationIsFetching, data: formulationWithDesignResponse },
  ] = useformulationWithDesignLazyQuery({
    variables: {
      formulationId: formulation.id,
    },
  });
  const design = formulationWithDesignResponse?.formulation?.design;
  const { currentProject } = useSession();
  const { ingredientByName } = useIngredients();
  const [collapsed, setCollapsed] = useState(true);
  const handleOpenChange = async () => {
    if (!design) {
      // Only fetch this when popover opens, not when each table row is loaded
      await fetchFormulation();
    }
  };

  const content = (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr', // This line will create two equal columns
        columnGap: 15,
        minWidth: 500,
        minHeight: '200',
        maxHeight: '40vh',
        overflowY: 'auto',
        padding: 15,
      }}
    >
      <Spin spinning={formulationIsFetching}>
        <div
          id="source-objective-section"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text type="secondary" style={{ marginBottom: 15 }}>
            Objectives
          </Text>

          {design?.objectives
            ?.slice(0, collapsed ? 3 : design.objectives.length)
            ?.map(objective => (
              <>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ fontWeight: 500 }}>
                    {objective?.targetVariable}
                  </div>
                  <div>{formatObjectiveText(objective)}</div>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              </>
            ))}
        </div>
      </Spin>
      <Spin spinning={formulationIsFetching}>
        <div
          id="source-constraint-section"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text type="secondary" style={{ marginBottom: 15 }}>
            Constraints
          </Text>
          {design?.constraints
            ?.slice(0, collapsed ? 3 : design.constraints.length)
            ?.map(constraint => {
              const formattedConstraint = formatConstraintText(
                constraint,
                ingredientByName as Map<string, IngredientList>,
                currentProject?.ingredientComposition
              );
              return (
                <>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ fontWeight: 500 }}>
                      {formattedConstraint?.text}
                    </div>
                    <div>{formattedConstraint?.description}</div>
                  </div>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </>
              );
            })}
        </div>
      </Spin>
      <Button
        size="small"
        type="text"
        icon={<ArrowRightOutlined />}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? 'View Full Details' : 'Hide Details'}
      </Button>
    </div>
  );

  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>Scenario Detail</div>
      <div>
        {design && design?.id && (
          <Button
            size="small"
            onClick={() => design?.id && onClick(design?.id)}
          >
            Filter By This Scenario
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      title={title}
      placement="right"
      zIndex={999}
      onOpenChange={handleOpenChange}
      mouseLeaveDelay={1}
    >
      {children}
    </Popover>
  );
};
