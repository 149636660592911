/** @jsxImportSource @emotion/react */

import { Button } from 'antd';

import { DownloadFile } from '../../../_shared/components/button/download-file';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { useSession } from '../../../_shared/context/session-context';
import { DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { TableDataType } from '../ingredient-composition-table.component';

export const IngredientCompositionTemplateDownload = ({
  tableData,
}: {
  tableData: TableDataType[];
}) => {
  const { currentProject } = useSession();

  const cleanedTabledData = tableData.map(
    ({ ingredientId, key, ingredientName, ...other }) => ({
      Component: ingredientName,
      ...other,
    })
  );
  const csv = Papa.unparse(cleanedTabledData);

  const trackEvent = () => {
    logEvent(TrackableEvent.DOWNLOADED_INGREDIENT_COMPOSITION_CSV_TEMPLATE, {
      projectId: currentProject?.id,
    });
  };

  return (
    <DownloadFile
      options={{ type: 'text/csv;charset=utf-8' }}
      content={csv}
      filename={`${currentProject?.key}-IngredientCompositionSample.csv`}
    >
      <Button type="default" icon={<DownloadOutlined />} onClick={trackEvent}>
        Download Sample CSV
      </Button>
    </DownloadFile>
  );
};
