import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const siderStyle = css`
  background: ${Colors['#FAFAFC']};
  border-right: 1px solid ${Colors.SOLITUDE};
`;

export const projectListContainerStyle = css`
  padding: 10px;
  overflow-y: scroll;
`;

export const contentStyle = css`
  background: ${Colors.WHISPER};
  padding: 0;
`;
