/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import {
  Tooltip,
  Scatter,
  XAxis,
  YAxis,
  Dot,
  Rectangle,
  ScatterChart,
  TooltipProps,
  Customized,
  CustomizedProps,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { AxisDomain, CartesianViewBox } from 'recharts/types/util/types';
import {
  Maybe,
  VariableType,
} from '../../../../../../../__generated__/globalTypes';
import {
  AccuracyTextValues,
  AccuracyTooltipContent,
  getAccuracyProps,
} from '../../../../../_shared/components/icon';
import { YAxisArrow } from '../../../../../_shared/style';
import {
  TargetVariableWitColorType,
  TargetVariableProductOutcomes,
} from './types';

type DataProps = {
  targetVariable: string;
  targetVariableType: VariableType;
  label: string;
  accuracyLevel: number; // Y-Axis
  xAxisValue: number; // Outcome for numeric, Reliablity of highest state for ordinal
  outcome: number | string;
  applicability: number;
  reliability: number;
  accuracyText: string;
  accuracyDescText: string;
  probabilityOfHighestState?: number; // Only set for ordinal outcomes
};

/**
 * A customized Dot for the scatter chart
 *
 * @param props passed by recharts
 * @returns
 */
const CustomizedDot = (props: {
  payload?: any;
  cx?: any;
  cy?: any;
  fill?: any;
}): JSX.Element => {
  const { cx, cy, fill } = props;
  const { label } = props.payload;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={15} fill={fill} stroke="white" />
      <text
        x={cx}
        y={cy}
        fill="white"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {label}
      </text>
    </g>
  );
};

/**
 * Sets the background in chart for y-axis with certainty level and it's rectangular stacked area
 *   75-100% => Good
 *    50-75% => Okay
 *    25-50% => Low
 *     0-25% => Uncertain
 *
 * @param props passed by recharts
 * @returns
 */
const ChartBackground = (
  props: CustomizedProps<
    { offset?: { height: number; left: number; width: number } },
    any
  >
): JSX.Element => {
  const { height, left, width } = props.offset ?? {
    height: 0,
    left: 0,
    width: 0,
  };
  const boxHeight = height / 4;
  const boxes = ['High', 'Medium', 'Low', 'Uncertain'].map((name, index) => (
    <svg y={boxHeight * index} height={boxHeight} key={name}>
      <Rectangle
        width={width}
        height={boxHeight}
        fill={index % 2 === 0 ? 'none' : '#C4C4C430'}
      />
      <text x={10} y="50%" dominantBaseline="middle" fill="#999696">
        {name}
      </text>
    </svg>
  ));
  return <svg x={left}>{boxes}</svg>;
};

/**
 * A customized tooltip to show accuracy details when hovering on the dot
 *
 * @param props passed by recharts
 * @returns
 */
const CustomTooltip = (
  props: TooltipProps<string, string> & {
    payload?: Array<Payload<string, string> & { payload: DataProps }>;
  }
): JSX.Element | null => {
  const { active, payload } = props;
  if (active && payload && payload.length > 0 && payload[0].payload) {
    const data = payload[0].payload;
    return (
      <div
        css={css`
          margin: 0px;
          padding: 10px;
          background-color: rgb(255, 255, 255);
          border: 1px solid rgb(204, 204, 204);
          white-space: nowrap;
        `}
      >
        <AccuracyTooltipContent
          applicability={data.applicability}
          reliablity={data.reliability}
          targetVariable={data.targetVariable}
          accuracyText={data.accuracyText}
          accuracyDescText={data.accuracyDescText}
        />
        {data.probabilityOfHighestState && (
          <div>
            Probability of highest state:{' '}
            {data.probabilityOfHighestState.toFixed(2)}%
          </div>
        )}
        <div>
          Predicted Outcome:{' '}
          {data.targetVariableType === VariableType.NUMERIC &&
          // Below code mainly for typecheck since data.outcome is string | number
          typeof data.outcome === 'number'
            ? data.outcome.toFixed(2)
            : data.outcome}
        </div>
      </div>
    );
  }

  return null;
};

/**
 * A customized Y-Axis label to show label and vertical arrow
 *
 * @param props  passed by recharts
 * @returns
 */
const CustomizedYAxisLabel = (props: { viewBox: CartesianViewBox }) => {
  const { height } = props.viewBox;
  return (
    <g>
      <text
        x={-(height ?? 0) + 80}
        y={34}
        fill="#434343"
        transform="rotate(270)"
        textAnchor="middle"
      >
        Certainty Level
      </text>
      <YAxisArrow x={23} y={33} />
    </g>
  );
};

export const CertaintyVsOutcomeChart = ({
  targetVariable,
  outcomes,
  chartWidth,
  chartHeight,
}: {
  targetVariable: TargetVariableWitColorType;
  outcomes: Array<TargetVariableProductOutcomes>;
  chartWidth: number;
  chartHeight: number;
}) => {
  const targetIsOrdinal = targetVariable.type === VariableType.ORDINAL;
  const data: Array<DataProps> = outcomes
    .map(({ label, outcomeInfo }) => {
      const applicability = outcomeInfo.accuracy ?? 0;
      const reliability = outcomeInfo.reliability ?? 0;
      let xAxisValue: number;
      let probabilityOfHighestState: number | undefined;
      if (targetIsOrdinal && targetVariable.levels) {
        const highestState =
          targetVariable.levels[targetVariable.levels.length - 1] || '';

        probabilityOfHighestState =
          (outcomeInfo.distribution?.get(highestState) ?? 0) * 100;
        xAxisValue = probabilityOfHighestState;
      } else {
        xAxisValue = Number(outcomeInfo.value);
      }
      const { accuracyText, accuracyDescText } = getAccuracyProps({
        applicability,
        reliability,
      });
      let accuracyLevel = 0;
      switch (accuracyText) {
        case AccuracyTextValues.UNCERTAIN:
          accuracyLevel = 12.5;
          break;
        case AccuracyTextValues.LOW:
          accuracyLevel = 37.5;
          break;
        case AccuracyTextValues.OKAY:
          accuracyLevel = 62.5;
          break;
        case AccuracyTextValues.GOOD:
          accuracyLevel = 87.5;
          break;
        default:
          break;
      }
      console.log('LABEL', label);
      return {
        targetVariable: targetVariable.name,
        targetVariableType: targetVariable.type as VariableType,
        label,
        accuracyLevel, // Y-Axis
        xAxisValue, // X-Axis
        outcome: outcomeInfo.value, // The actual outcome
        applicability,
        reliability,
        accuracyText,
        accuracyDescText,
        probabilityOfHighestState,
      };
    })
    .sort((a, b) => Number(b.label) - Number(a.label));

  let domain: AxisDomain;
  if (targetIsOrdinal) {
    domain = [0, 100];
  } else {
    domain = [
      (dataMin: number) => Math.floor(dataMin * (dataMin > 0 ? 0.8 : 1.2)),
      (dataMax: number) => Math.ceil(dataMax * (dataMax > 0 ? 1.2 : 0.8)),
    ];
  }

  return (
    <ScatterChart
      width={chartWidth}
      height={chartHeight}
      data={data}
      margin={{
        top: 0,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <Customized key="chartBackground" component={ChartBackground} />
      <XAxis
        padding={{ left: 20, right: 20 }}
        dataKey="xAxisValue"
        type="number"
        allowDuplicatedCategory={false}
        tick={{ fill: '#7B7B7B' }}
        domain={domain}
      />
      <YAxis domain={[0, 100]} tick={false} label={CustomizedYAxisLabel} />
      <Tooltip cursor={false} content={<CustomTooltip />} />
      <Scatter
        className="scatterChart"
        name="outcome_certainty"
        dataKey="accuracyLevel"
        fill={targetVariable.color}
        isAnimationActive={false}
        shape={<CustomizedDot />}
      />
    </ScatterChart>
  );
};
