import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ContainerStyle = css`
  display: flex;
  flex-direction: column;

  .ant-select-selector {
    background-color: #434343 !important;
    min-width: 160px;
  }

  .ant-select-arrow svg {
    fill: #fff;
  }

  .ant-select-selection-item {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
  }

  .ant-input {
    background: #434343;
    border-radius: 4px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
  }
`;

export const PrimaryTextStyle = css`
  color: #9d9d9d;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const FormContainerStyle = css`
  border: 1px solid #434343;
  border-radius: 8px 8px 8px 8px;
  background-color: #272727;
`;

export const TopFormContainerStyle = css`
  display: flex;
  border-bottom: 1px solid #434343;
  width: 100%;
  padding: 16px;
`;

export const OutcomeNameContainerStyle = css`
  width: 160px;
  background: #434343;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
`;

export const TopFormSvgContainerStyle = css`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteSvgContainerStyle = css`
  path {
    stroke: #fff;
  }
`;

export const LowerFormContainerStyle = css`
  padding: 16px;
`;

export const FooterFormContainerStyle = css`
  display: flex;
  padding: 16px;
  border-top: 1px solid #434343;
  justify-content: space-between;
`;

export const LabelStyle = css`
  font-weight: 400;
  font-size: 12px;
  color: #9d9d9d;
  line-height: 22px;
  margin-bottom: 8px;
`;

export const InputColumnStyle = css`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

export const RelationshipTypeButton = styled.div<{
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 60px;
  background: ${({ selected }) => (selected ? '#10447d' : '#434343')};
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  border: ${({ selected }) => (selected ? '1px solid #017aff' : undefined)};

  &:hover {
    border: 1px solid #10447d;
  }
`;

export const DropdownLabelStyle = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;
