/** @jsxImportSource @emotion/react */
import Icon from '@ant-design/icons';
import { css, jsx, SerializedStyles } from '@emotion/react';
import { EditIcon } from '../../style/svg-assets';

const defaultCSS = css`
  color: #006eff;
  display: inline-block;
  margin-right: 8px;
`;

export const EditPencilButton = ({
  displayedValue,
  children,
  show,
  onClick,
  cssOverride,
}: {
  onClick?: () => void;
  displayedValue: string | number | undefined;
  children: JSX.Element;
  cssOverride?: SerializedStyles;
  show: boolean;
}) => {
  return show ? (
    <button
      type="button"
      css={css`
        border: none;
        background: transparent;
        margin: 5px 0;
        min-width: 60px;
        text-align: left;
      `}
      onClick={onClick}
    >
      {displayedValue !== undefined && (
        <span css={cssOverride || defaultCSS}>{displayedValue}</span>
      )}
      <Icon component={EditIcon} />
    </button>
  ) : (
    children
  );
};
