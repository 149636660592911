/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ThumbsUp, ThumbsDown } from '../../../_shared/style/svg-assets';
import React from 'react';

const driverLabelBaseCss = css`
  padding: 8px 14px 8px 14px;
  border-radius: 32px;
  background: #272727;
  position: absolute;
  color: #fff;
  z-index: 999;
  top: 10%;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;

  svg {
    padding-right: 8px;
  }
`;

export const PositiveDriverLabel: React.FC = () => (
  <div
    css={css`
      ${driverLabelBaseCss};
      right: 32%;
    `}
  >
    <ThumbsUp />
    Positive Driver
  </div>
);

export const NegativeDriverLabel: React.FC = () => (
  <div
    css={css`
      ${driverLabelBaseCss};
      left: 16%;
    `}
  >
    <ThumbsDown />
    Negative Driver
  </div>
);
