import {
  FormulationReducerActionType,
  useFormulationsDispatch,
} from '../../../_shared/context/formulations-context';
import { usesetFormulationNameMutation } from '../../../../../__generated__/globalTypes';
import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface PassedFormulation {
  id: string;
  [key: string]: any;
}
const { Search } = Input;
export const FormulationName = ({
  formulation,
}: {
  formulation: PassedFormulation;
}) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(formulation.name ?? formulation?.key);
  const dispatch = useFormulationsDispatch();

  const [
    updateFormulationName,
    { data, loading, error },
  ] = usesetFormulationNameMutation();

  const handleUpdateFormulationName = async (value: string, id: string) => {
    let updatedFormulation = await updateFormulationName({
      variables: {
        formulationId: id, // value for 'formulationId'
        name: value, // value for 'status'
      },
    });
    if (updatedFormulation.data?.setFormulationName) {
      dispatch({
        type: FormulationReducerActionType.CHANGED,
        formulation: updatedFormulation.data?.setFormulationName,
      });
      setName(value);
    }

    setEditMode(!editMode);
  };

  return (
    <div>
      {!editMode ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {name}
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => setEditMode(!editMode)}
          />
        </div>
      ) : (
        <Search
          enterButton="Save"
          defaultValue={name}
          allowClear
          //@ts-ignore
          onSearch={value =>
            value
              ? handleUpdateFormulationName(value, formulation.id)
              : setEditMode(false)
          }
        />
      )}
    </div>
  );
};
