import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { API_V2_URL } from './Config';
import { BaseResponseInterface } from './interfaces/base.interface';
import { notification } from 'antd';

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Control-Allow-Origin': '*',
};

class ApiClient {
  ApiInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.ApiInstance = axios.create({
      baseURL,
      timeout: 100000,
      headers: HEADERS,
    });

    this.ApiInstance.interceptors.response.use(
      (response: any) => {
        if (!response.data.status && response.data.message) {
          notification.error({ message: response.data.message });
        }
        return response;
      },
      (error: AxiosError) => {

      }
    );

  }

  setAuthToken(token: string) {
    this.ApiInstance.interceptors.request.use(
      (config: any) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }


  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaseResponseInterface<R>>> {
    return this.ApiInstance.get<T, AxiosResponse<BaseResponseInterface<R>>>(
      url,
      config
    );
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaseResponseInterface<R>>> {
    return this.ApiInstance.post<T, AxiosResponse<BaseResponseInterface<R>>>(
      url,
      data,
      config
    );
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaseResponseInterface<R>>> {
    return this.ApiInstance.put<T, AxiosResponse<BaseResponseInterface<R>>>(
      url,
      data,
      config
    );
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaseResponseInterface<R>>> {
    return this.ApiInstance.delete<T, AxiosResponse<BaseResponseInterface<R>>>(
      url,
      config
    );
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<BaseResponseInterface<R>>> {
    return this.ApiInstance.patch<T, AxiosResponse<BaseResponseInterface<R>>>(
      url,
      data,
      config
    );
  }
}

const BaseClient = new ApiClient(API_V2_URL);

export { BaseClient };
