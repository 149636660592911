/** @jsxImportSource @emotion/react */

import { Button } from 'antd';

import { DownloadFile } from '../../../_shared/components/button/download-file';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { useSession } from '../../../_shared/context/session-context';
export type TemplateDownloadProps = { rowInfo: string[]; workspaceId: string };

// TODO: Change this, make it take the project name to make a better filename
export const FormulationTemplateDownload = (props: TemplateDownloadProps) => {
  const { workspaceId } = props;
  const { currentProject } = useSession();
  const getTemplateData = () => {
    let template = addArrayToString(['Product Name', 'Product 1', 'Product 2']);
    template += addArrayToString(['Benchmark Select', 'true', '']);

    props.rowInfo.forEach(rowName => {
      const row = [rowName, '', ''];
      template += addArrayToString(row);
    });
    return template;
  };

  const getDownloadOptions = () => {
    return {
      type: 'text/csv;charset=utf-8',
    };
  };

  const addArrayToString = (array: any[]) => array.join(',') + '\n';
  const trackEvent = () => {
    logEvent(TrackableEvent.DOWNLOADED_FORMULATION_CSV_TEMPLATE, {
      projectId: currentProject?.id,
    });
  };
  return (
    <DownloadFile
      options={getDownloadOptions()}
      content={getTemplateData()}
      filename={`${workspaceId}-FormulationSample.csv`}
    >
      <Button onClick={trackEvent}>Download Sample CSV</Button>
    </DownloadFile>
  );
};
