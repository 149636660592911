/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Modal, Form, Input, Spin, Button, Select, Alert } from 'antd';
import { centeredHorizontallyFlexStyle } from '../../../_shared/style';
import { useSession } from '../../../_shared/context';
import {
  ProjectFeature,
  WorkspaceType,
  createIterationMutation,
  usecreateIterationMutation,
} from '../../../../../__generated__/globalTypes';

export const CreateIterationModal = (props: {
  close: () => void;
  workspaceType?: WorkspaceType;
}) => {
  const { close, workspaceType } = props;

  const navigate = useNavigate();
  const {
    currentProject: project,
    useFetchProject,
    iterationCount,
  } = useSession();
  const [newIteration, setNewIteration] = React.useState<
    createIterationMutation['createOneIteration']
  >();

  const [
    fetchProjectById,
    { data: projectUpdatedData, loading: projectFetchLoading },
  ] = useFetchProject();

  const [createIteration, { loading, error }] = usecreateIterationMutation({
    onCompleted: data => {
      onAfterSave(data.createOneIteration);
    },
  });

  const hasAdaptiveLearning = project?.features.find(
    f => f.feature === ProjectFeature.ADAPTIVE_LEARNING
  );

  const onAfterSave = async (
    iteration: createIterationMutation['createOneIteration']
  ) => {
    if (iteration) {
      setNewIteration(iteration);
    }
  };

  const onFinish = async (values: {
    name: string;
    description: string;
    objective: string;
    type: WorkspaceType;
  }) => {
    try {
      if (!values.type) {
        values.type = WorkspaceType.LAB_BENCH;
      }
      let { data } = await createIteration({
        variables: {
          data: {
            projectId: project!.id,
            ...values,
          },
        },
      });

      await fetchProjectById({
        variables: {
          projectId: `${project?.id}`,
        },
      });
      navigate(
        `/project/${project?.id}/iteration/${data?.createOneIteration?.id}`
      );
      close();
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const featureSet = new Set();
  project?.features.forEach(f => featureSet.add(f.feature.toString()));

  const labBenchEnabled =
    featureSet.has('LAB_BENCH') &&
    featureSet.has('OPTIMIZATION') &&
    featureSet.has('SIMULATION');

  const adaptiveLearningEnabled =
    featureSet.has('ADAPTIVE_LEARNING') && featureSet.has('SIMULATION');

  const form = (
    <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item label="Description (optional)" name="description">
        <Input.TextArea placeholder="Add notes about this workspace" />
      </Form.Item>
      {hasAdaptiveLearning && (
        <Form.Item
          label="Workspace Type"
          name="type"
          rules={[{ required: true }]}
          initialValue={workspaceType}
        >
          <Select value={workspaceType} placeholder="Select a Workspace type">
            {adaptiveLearningEnabled && (
              <Select.Option
                key={WorkspaceType.ADAPTIVE_LEARNING}
                value={WorkspaceType.ADAPTIVE_LEARNING}
              >
                Adaptive Learning
              </Select.Option>
            )}
            {labBenchEnabled && (
              <Select.Option
                key={WorkspaceType.LAB_BENCH}
                value={WorkspaceType.LAB_BENCH}
              >
                Lab Bench
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button type="primary" htmlType="submit">
            Create Workspace
          </Button>
        </div>
      </Form.Item>
    </Form>
  );

  let view = form;

  if (loading || projectFetchLoading) {
    view = <Spin css={centeredHorizontallyFlexStyle} />;
  }

  return (
    <Modal closable onCancel={close} title="New Workspace" open footer={null}>
      {error && (
        <Alert
          message="Error"
          description="Failed to save workspace"
          type="error"
          showIcon
          closable
        />
      )}
      {view /* TODO: Why is there so much space below the end of the form?*/}
    </Modal>
  );
};
