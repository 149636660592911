import React, { FC, useEffect, useState } from 'react';
import './edit-data.less';
import { Button, Drawer, message, Radio, Space, Table, TableProps, Tabs, UploadProps } from 'antd';
import { ConstraintType } from '../../../../../../__generated__/globalTypes';
import { useInputOutpuNamesContext, useSession } from '../../../../_shared/context';
import { RadioChangeEvent } from 'antd/lib';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { parse } from 'papaparse';
import { useDeleteFormulationRaw, useGetProjectFormulationsRaw } from '../../../../network/services/project.service';
import { IBaseFormulationRaw, IFormulationRaw } from '../../../../network/interfaces/formulationRaw.interfaces';

export const emptyConstraint = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const ProjectEditDataStep = ({
  projectId,
  enableNextStep,
  setNoData,
  handleNextStep
}: {
  projectId: string,
  enableNextStep: (value: boolean) => void,
  setNoData: (value: boolean) => void,
  handleNextStep: () => void
}) => {
  const { user, setCurrentProject } = useSession();
  const [formulationRaw, setFormulationRaw] = useState([]);
  const [columnsTable, setColumnsTable] = useState<TableProps<any>['columns']>([]);
  const [formulationsDataTable, setformulationsDataTable] = useState<any>([]);
  const deleteFormulationRaw = useDeleteFormulationRaw();
  const { data, isLoading, isSuccess, refetch, isError } = useGetProjectFormulationsRaw({
    projectId: projectId,
    organizationId: user?.organizationId,
  });

  useEffect(() => {
    if (data && data.status) {
      const formulations = data.data;
      setFormulationRaw(formulations);
      if (formulations?.length > 0) {
        const formulation = formulations[0];

        const columns: TableProps<any>['columns'] = [
          {
            title: 'FormulationID',
            dataIndex: 'FormulationID',
            key: 'FormulationID',
          },
          {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
          }
        ]

        formulation.formulationItemsRaw.map((fi: any) => {

          columns.push(
            {
              title: fi.name,
              dataIndex: fi.name,
              key: fi.name,
            }
          )

        })
        columns.push({
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a onClick={() => { handleDelete(record.id) }}>Delete {record.name}</a>
            </Space>
          ),
        },)
        setColumnsTable(columns);
        const dataTable: any[] = []
        formulations.map((f: any) => {
          let row: any = {
            'id': f.id,
            'FormulationID': f.name,
            'Type': f.type
          }
          f.formulationItemsRaw.map((fi: any) => {

            row[fi.name] = fi.value;

          })
          dataTable.push(row);
        })
        setformulationsDataTable(dataTable);

      }
    }
  }, [data]);

  const handleDelete = (formulationId: string) => {
    const formulationsRaw: IFormulationRaw = {
      orgId: user?.organizationId!,
      projectId: projectId,
      formulationId: formulationId
    }
    deleteFormulationRaw.mutate(formulationsRaw, {
      onSuccess: async response => {
        refetch()
      },
      onError: async (response: any) => {
        message.error(response.data.message)
      },
    });
  }

  return (
    <div id="project-edit-data-box">
      {columnsTable && formulationsDataTable &&
        <Table columns={columnsTable} dataSource={formulationsDataTable} />
      }
    </div>
  );
};
