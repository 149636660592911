import { css } from '@emotion/react';
import { Colors } from '../../../_shared/style';

export const iterationSidebarStyle = css`
  .ant-list-item {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-top-color: transparent;
    border-bottom-color: transparent;
    height: 32px;
    padding: 8px 25px;

    a {
      color: ${Colors.ARSENIC};

      width: 100%;

      &:hover {
        color: ${Colors.BRANDEIS_BLUE};
      }
    }

    &.selected {
      width: calc(100% + 1px);
      border-left: 2px solid red;
      padding-left: 23px;
      border-right: 1px solid ${Colors.WHISPER};

      background-color: ${Colors.WHISPER};
      border-top-color: ${Colors['#EBEAF0']};
      border-bottom-color: ${Colors['#EBEAF0']};

      a {
        color: ${Colors.BRANDEIS_BLUE};
      }
    }

    &.add-button {
      margin-top: 11px;
      margin-bottom: 9px;
    }

    &:last-child {
      border-bottom: 1px solid transparent;
      &.selected {
        border-bottom-color: ${Colors['#EBEAF0']};
      }
    }
  }
`;

export const newIterationButtonStyle = css`
  font-size: 11px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
