import { useSession } from '.';
import message from 'antd/lib/message';
import React, { useState, useContext, useEffect } from 'react';
import {
  CostOptimizationOption,
  JobStatus,
  Objective,
  createOneOptimizationMutationVariables,
  usecancelProjectJobMutation,
  usecreateOneOptimizationMutation,
  userequestOptimizationJobMutation,
} from '../../../../__generated__/globalTypes';
import {
  OptimizationContextProps,
  OptimizationContextProviderProps,
  OptimizationStates,
} from '../../components/workspaces/lab-bench/optimization/v2/context/types';
import {
  userunSimulationv2Mutation,
  usegetOptimizationLazyQuery,
} from '../../../../__generated__/globalTypes';
import { useDefaultObjectives } from '../../components/workspaces/lab-bench/optimization/v2/context/utils';
import { safeJsonParse, validateObjective } from '../utils/util';
import {
  LatestOptimizationType,
  SimulationProductOutcome,
  SimulationProductVersionResultType,
  useWorkspace,
} from '../../components/workspaces/lab-bench/context';

import { useScenarioDetail } from './scenario-detail-context';
import { LOG_OPTIMIZATION_CONTEXT } from '../debug/flags';

const OptimizationContext = React.createContext<OptimizationContextProps>(
  {} as any
);

export const OptimizationContextProvider = ({
  children,
  iteration,
}: OptimizationContextProviderProps) => {
  const {
    setLatestOptimization,
    latestOptimization,
    latestFinishedOptimization,
    simulationProductVersions: formulationsInWorkspace,
  } = useWorkspace();
  const { currentProject, selectedIterationId } = useSession();
  const [cancelOptimizationRequest] = usecancelProjectJobMutation();
  const {
    constraints,
    setConstraints,
    setObjectivesByTarget,
    objectivesByTarget,
    objectivesAreInEditMode,
    setObjectivesAreInEditMode,
    fillerIngredient,
    setFillerIngredient,
    enforceStrictly,
    setEnforceStrictly,
    maxNumberOfResults,
    setMaxNumberOfResults,
    costOptimizationOption,
    setCostOptimizationOption,
    enforceNteCost,
    setEnforceNteCost,
    nteCost,
    setNteCost,
    setDisableGoalScenario,
  } = useScenarioDetail();
  const outcomes = currentProject?.activeModel?.outcomes;
  const [errorDescription, setErrorDescription] = useState<
    string | undefined
  >();
  const [optimizationState, setOptimizationState] = useState<
    OptimizationStates
  >(OptimizationStates.EDITING);
  const [optimizedProducts, setOptimizedProducts] = useState<
    Array<SimulationProductVersionResultType>
  >([]);

  const [createOptimization] = usecreateOneOptimizationMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [initOptimizationJob] = userequestOptimizationJobMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [
    runSimulation,
    {
      //TODO: Handle simulation error
      error: simulationError,
      loading: isSimulationRunning,
    },
  ] = userunSimulationv2Mutation({
    notifyOnNetworkStatusChange: true,
  });

  const [
    getOptimizationResults,
    { data: optimizationFormulations, error: getOptimizationResultsError },
  ] = usegetOptimizationLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (latestOptimization) {
      setConstraints(
        latestOptimization?.constraints.map(({ __typename, ...c }) => c) ?? []
      );
    } else if (latestFinishedOptimization) {
      setConstraints(
        latestFinishedOptimization?.constraints.map(
          ({ __typename, ...c }) => c
        ) ?? []
      );
    } else if (iteration?.constraints.length) {
      setConstraints(
        iteration?.constraints.map(({ __typename, ...c }) => c) ?? []
      );
    } else {
      setConstraints(
        currentProject?.constraints.map(({ __typename, ...c }) => c) ?? []
      );
    }
  }, [latestOptimization, latestFinishedOptimization, iteration?.constraints]);

  /**
   * Use the iteration's outcomes first
   * if none are specified use the project's template objectives
   * if those aren't specified (new project) shove them all in Maximize
   */
  useEffect(() => {
    if (
      optimizationState === OptimizationStates.FINISHED ||
      optimizationState === OptimizationStates.RUNNING
    ) {
      setDisableGoalScenario(true);
    } else {
      setDisableGoalScenario(false);
    }
  }, [optimizationState, latestOptimization]);

  useEffect(() => {
    let objMap = new Map<string, Objective>();
    const currentModel = currentProject?.activeModel;
    //Strip the __typename
    const addObjectiveFromDb = (o: Objective) => {
      //Annoying field from graphql
      const { __typename, ...cleaned } = o;

      objMap.set(o.targetVariable, cleaned);
    };
    const hasIterationObjectives = iteration?.objectives?.length;
    const hasPreviousOptimizationObjectives =
      latestOptimization?.objectives?.length;
    const hasPreviousFinishedOptimizationObjectives =
      latestFinishedOptimization?.objectives?.length;
    const hasProjectObjectives = currentProject?.objectives?.length;

    if (hasIterationObjectives) {
      // Objectives are tied to the iteration after duplicating a workspace
      iteration?.objectives.forEach(addObjectiveFromDb);
    }

    if (
      hasPreviousOptimizationObjectives &&
      objMap.size !== currentModel?.outcomes.length
    ) {
      // Use the Objectives related to the most recent optimization
      latestOptimization.objectives.forEach(o => {
        if (!objMap.get(o.targetVariable)) {
          addObjectiveFromDb(o);
        }
      });
    }

    if (
      hasPreviousFinishedOptimizationObjectives &&
      objMap.size !== currentModel?.outcomes.length
    ) {
      // Use the Objectives related to the most recent finished optimization
      latestFinishedOptimization.objectives.forEach(o => {
        if (!objMap.get(o.targetVariable)) {
          addObjectiveFromDb(o);
        }
      });
    }

    if (hasProjectObjectives && objMap.size !== currentModel?.outcomes.length) {
      // Default to the Objectives on the project set in admin panel
      currentProject.objectives.forEach(addObjectiveFromDb);
    }

    if (objMap.size !== currentModel?.outcomes.length) {
      // Fall back to outcomes on the model
      if (currentModel) {
        objMap = useDefaultObjectives(currentModel, currentModel?.outcomes);
      }
    }
    const DEFAULT_NUMBER_OF_RETURNED_OPTIMIZATIONS = 3;

    setObjectivesByTarget(objMap);
    setEnforceNteCost(latestOptimization?.enforceNteCost ?? false);
    setMaxNumberOfResults(
      latestOptimization?.maxNumberOfResults ??
        DEFAULT_NUMBER_OF_RETURNED_OPTIMIZATIONS
    );
    setCostOptimizationOption(
      latestOptimization?.costOptimizationOption ??
        CostOptimizationOption.DO_NOT_OPTIMIZE
    );
    setNteCost(latestOptimization?.nteCost ?? 0);
  }, [iteration, latestOptimization, latestFinishedOptimization]);

  useEffect(() => {
    if (optimizationFormulations) {
      const { optimization } = optimizationFormulations;
      const optConstraints = optimization?.constraints;

      if (optConstraints?.[0]) {
        setConstraints(optConstraints.map(({ __typename, ...c }) => c));
      }

      // Validate the optimization's job has reached some sort of conclusion
      if (
        optimization?.projectJob &&
        optimization.projectJob.status !== JobStatus.IN_PROGRESS &&
        optimization.projectJob.status !== JobStatus.PENDING
      ) {
        if (optimization?.product) {
          //Optimization results have been retrieved
          simulateOptimizationResults();
        } else {
          setOptimizationState(OptimizationStates.NO_RESULTS);
        }
      }

      if (optimization?.projectJob?.debugInput) {
        const input = safeJsonParse(optimization?.projectJob?.debugInput);
        if (input.filler_ingredient)
          setFillerIngredient(input.filler_ingredient);
      }
    }
  }, [optimizationFormulations]);
  /**
   * If the latest optimization on the workspace
   * is the one in the modal, run the results
   *
   * This check is needed because we don't want to
   * simulate the results for a previous optimization
   * when a user is trying to make a new one
   */
  useEffect(() => {
    if (latestOptimization) {
      //Handle showing the different states of our object here.
      switch (latestOptimization.projectJob?.status) {
        case JobStatus.SUCCESS:
          if (!latestOptimization?.id) {
            throw new Error(
              'Optimization was labeled as a success but no id provided'
            );
          }
          getOptimizationResults({
            variables: { optimizationId: latestOptimization.id },
          });
          setOptimizationState(OptimizationStates.FINISHED);
          break;
        case JobStatus.ERROR:
          setOptimizationState(OptimizationStates.ERROR);
          break;
        case JobStatus.IN_PROGRESS:
          setOptimizationState(OptimizationStates.RUNNING);
          //optimization has actually started
          break;
      }
      setEnforceStrictly(latestOptimization?.enforceStrictly ?? true);
    }
  }, [latestOptimization?.projectJob]);

  const fetchLatestOptimization = async (optimizationId: string) => {
    const optimization = await getOptimizationResults({
      variables: {
        optimizationId,
      },
    });

    if (optimization.data?.optimization) {
      setLatestOptimization(
        optimization.data?.optimization as LatestOptimizationType
      );
    }
  };

  const initiateOptimizationJob = async () => {
    if (!iteration) return;
    const errors: string[] = [];
    Array.from(objectivesByTarget.values()).forEach(o => {
      const outcome = outcomes?.find(
        outc => outc.targetVariable === o.targetVariable
      );
      const error = validateObjective(o, outcome);
      if (error) {
        errors.push(error?.message);
        void message.error({
          content: error?.message,

          style: {
            marginTop: '20vh',
          },
        });
      }
    });

    if (errors.length > 0) {
      return;
    }

    if (errors.length > 0) {
      return;
    }

    const optVars: createOneOptimizationMutationVariables = {
      data: {
        constraintIds: constraints.map(constraint => constraint.id!),
        iterationId: iteration.id,
        objectiveIds: Array.from(objectivesByTarget.values()).map(o => o.id),
        enforceStrictly,
        maxNumberOfResults,
        costOptimizationOption,
        enforceNteCost,
        nteCost,
      },
    };

    let optimizationId: string | undefined;

    try {
      const createOptimizationResponse = await createOptimization({
        variables: optVars,
      });

      optimizationId =
        createOptimizationResponse.data?.createOneOptimization.id;
    } catch (e) {
      setOptimizationState(OptimizationStates.ERROR);
      const err = e as Error;
      throw e;
    }

    try {
      const response = await initOptimizationJob({
        variables: {
          optimizationId: optimizationId!,
          fillerIngredient,
          debug: LOG_OPTIMIZATION_CONTEXT,
        },
      });
      if (response.errors) {
        throw new Error(JSON.stringify(response.errors));
      }
      const startedOptimization = response?.data?.requestOptimizationJob;

      if (!startedOptimization) {
        throw new Error(`No Job received for iteration: ${iteration.id}`);
      }

      setOptimizationState(OptimizationStates.RUNNING);
      fetchLatestOptimization(optimizationId!);
    } catch (e) {
      setOptimizationState(OptimizationStates.ERROR);
      const err = e as Error;
      //Report error somehow
      throw e;
    }
  };

  // Technically this could be handled all on the backend as well
  const simulateOptimizationResults = async () => {
    try {
      if (!iteration) return;

      //Format Results
      const results = [
        ...(optimizationFormulations?.optimization?.product?.productVersion ??
          []),
      ] //TODO: This will eventually be based off the explicit score of how
        // close each formulation is to the desired goal
        .sort((a, b) => a.name.localeCompare(b.name));

      const formattedFormulations = results.map((r, i) => {
        return {
          columnNumber: formulationsInWorkspace.length + i,
          isBenchmark: false,
          isOptimization: true,
          name: r.name,
          productId: r.productId,
          formulation: r.formulation.quantities,
          formulationId: r.formulation.id,
        };
      });

      // Run Simulation
      const simResults = await runSimulation({
        variables: {
          iterationId: iteration.id,
          projectId: currentProject!.id,
          formulationData: formattedFormulations,
        },
      });

      const simulationOutput: //Legacy typing just to make it work for now
      { [key: string]: Array<SimulationProductOutcome> } | undefined =
        simResults.data?.runSimulationv2?.data?.productOutcomes;

      if (!simulationOutput || !Object.keys(simulationOutput).length) {
        //We can also generate transaction IDs on the client side that cound trace to the ML API
        throw new Error(`Simulation did not return results`);
      }
      const formmatedIntoSPVs: SimulationProductVersionResultType[] = formattedFormulations.map(
        f => {
          const outcomes = simulationOutput[f.name];
          if (!outcomes || !outcomes.length) {
            throw new Error(
              `Could not find outcomes for formulation ${f.name}`
            );
          }

          const formatted: SimulationProductVersionResultType = {
            columnNumber: f.columnNumber,
            productVersion: {
              cost: null,
              isBenchmark: false,
              isOptimization: true,
              name: f.name,
              productId: f.productId,
              version: 0,
              formulation: { quantities: f.formulation, id: f.formulationId },
            },
            outcomes,
          };
          return formatted;
        }
      );

      setOptimizedProducts(formmatedIntoSPVs);
    } catch (err) {
      setOptimizationState(OptimizationStates.ERROR);
      setErrorDescription('Error simulating optimization results.');
    }
  };

  const cancelOptimization = async (projectJobId: string) => {
    await cancelOptimizationRequest({
      variables: {
        projectJobId,
      },
    });
    resetOptimizationState();
  };

  const resetOptimizationState = () => {
    setLatestOptimization(null);
    setOptimizationState(OptimizationStates.EDITING);
    setOptimizedProducts([]);
  };
  if (LOG_OPTIMIZATION_CONTEXT) {
    console.log('Optimization Context', {
      objectivesByTarget,
      constraints,
      isSimulationRunning,
      optimizationState,
      initiateOptimizationJob,
      optimizedProducts,
      fillerIngredient,
      objectivesAreInEditMode,
      cancelOptimization,
      resetOptimizationState,
    });
  }

  return (
    <OptimizationContext.Provider
      value={{
        objectivesByTarget,
        constraints,
        isSimulationRunning,
        optimizationState,
        initiateOptimizationJob,
        optimizedProducts,
        fillerIngredient,
        objectivesAreInEditMode,
        setObjectivesAreInEditMode,
        cancelOptimization,
        resetOptimizationState,
        errorDescription,
      }}
    >
      {children}
    </OptimizationContext.Provider>
  );
};

export const useOptimization = () => useContext(OptimizationContext);
