import { Col, Card, Row, List, Button } from 'antd';
import React, { useState } from 'react';
import {
  ConstraintInputType,
  ConstraintType,
  CostOptimizationOption,
  ProjectFeature,
  formulationDetailQuery,
} from '../../../../../__generated__/globalTypes';
import { CustomList } from '../../../pages/experiment-details/experiment-detail-page';
import { useSession } from '../../../_shared/context';
import { BaseProject } from '../../../_shared/hooks';
import { formatCostScore } from '../../../_shared/utils/component';
import { formatObjectiveText } from './experiment-detail-util';
type ConstraintItemDataPoint = {
  title?: React.ReactNode;
  value?: React.ReactNode;
  label?: React.ReactNode;
};
type ConstraintItem = ConstraintItemDataPoint & {
  children?: ConstraintItemDataPoint[];
};

const formatConstraintText = (
  constraints: ConstraintInputType[],
  currentProject?: BaseProject
) => {
  const resp: ConstraintItem[] = [];
  constraints.forEach(c => {
    let name = c.name;
    const dataPoint: ConstraintItem = {
      label: name ?? undefined,
    };
    switch (c.constraintType) {
      case ConstraintType.AMOUNT:
        if (currentProject && c.ingredientCompositionId) {
          const ingredientComposition = currentProject?.ingredientComposition.find(
            ing => ing.id === c.ingredientCompositionId
          );
          dataPoint.title = ingredientComposition?.name ?? '';
          dataPoint.value = `${c.lowerBounds} - ${c.upperBounds}`;
        }
        break;

      case ConstraintType.COUNT:
        dataPoint.title = c.variables?.join(', ') ?? '';
        dataPoint.value = `Pick at least ${c.lowerBounds}, at most ${c.upperBounds}`;
        break;

      case ConstraintType.EQUALITY:
        dataPoint.children = c.values?.map(v => ({
          title: v.name,
          value: v.value,
        }));
        break;

      case ConstraintType.RANGE:
        dataPoint.title = c.coefficients?.map(co => co.name).join(', ') ?? '';
        dataPoint.value = `${c.lowerBounds} - ${c.upperBounds}`;
        break;

      default:
        break;
    }
    resp.push(dataPoint);
  });
  return resp;
};
export const ScenarioDetailsSection = ({
  formulationWithDesign,
}: {
  formulationWithDesign: formulationDetailQuery;
}) => {
  const { currentProject } = useSession();
  const [showIgnoredOutcomes, setShowIgnoredOutcomes] = useState(false);
  const objectives = formulationWithDesign?.formulation?.design?.objectives;
  const constraints = formulationWithDesign?.formulation?.design?.constraints;
  const formattedConstraints: ConstraintItem[] = [];

  if (constraints) {
    formattedConstraints.push(
      ...formatConstraintText(constraints, currentProject)
    );
  }
  const pricingEnabled = currentProject?.features.some(
    f => f.feature === ProjectFeature.PRICING
  );
  if (pricingEnabled) {
    let costGoal;
    switch (
      formulationWithDesign?.formulation?.design?.costOptimizationOption
    ) {
      case CostOptimizationOption.DO_NOT_OPTIMIZE:
        costGoal = 'Do not optimize';
        break;
      case CostOptimizationOption.LIMIT:
        costGoal = formatCostScore(
          formulationWithDesign?.formulation?.design?.nteCost ?? 0,
          currentProject?.costMeasurementUnit,
          currentProject?.monetaryUnit
        );
        break;
      case CostOptimizationOption.MINIMIZE:
        costGoal = 'Minimize';
        break;
      default:
        costGoal = '-';
        break;
    }
    if (formulationWithDesign?.formulation?.design?.enforceNteCost) {
      costGoal = (
        <>
          {costGoal} <i style={{ opacity: 0.6 }}>(Enforced Strictly)</i>
        </>
      );
    }

    formattedConstraints.push({
      title: 'Cost',
      value: costGoal,
    });
  }
  return (
    <Card
      title="Scenario Details"
      extra={
        <Button
          type="link"
          onClick={() => setShowIgnoredOutcomes(!showIgnoredOutcomes)}
        >
          {showIgnoredOutcomes
            ? 'Hide Ignored Objectives'
            : 'Show Ignored Objectives'}
        </Button>
      }
    >
      <Row gutter={[30, 30]}>
        <Col span={12} style={{ gap: '15px' }}>
          <p
            style={{
              color: 'var(--neutrals-300, #7C858C)',
            }}
          >
            Outcome Targets
          </p>
          <List
            dataSource={objectives?.filter(obj =>
              showIgnoredOutcomes ? true : obj.importance !== 0
            )}
            renderItem={objective => (
              <List.Item key={objective.id}>
                <List.Item.Meta
                  title={
                    <div style={{ fontWeight: 500 }}>
                      {objective.targetVariable}
                    </div>
                  }
                />
                <div>{formatObjectiveText(objective)}</div>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12} style={{ gap: '15px' }}>
          <p
            style={{
              color: 'var(--neutrals-300, #7C858C)',
            }}
          >
            Constraints
          </p>
          {formattedConstraints.length > 0 ? (
            <CustomList dataSource={formattedConstraints} />
          ) : (
            <p style={{ paddingTop: '15px' }}>No constraints were set</p>
          )}
        </Col>
      </Row>
    </Card>
  );
};
