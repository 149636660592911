/** @jsxImportSource @emotion/react */

import { round } from 'lodash';

export const ConfidenceIntervalText = ({
  confidenceInterval,
  reliabilityPercentage,
}: {
  confidenceInterval: number[];
  reliabilityPercentage: number;
}) => {
  const formattedReliabilityPercentage =
    reliabilityPercentage > 0
      ? `${round(reliabilityPercentage, 2)}%`
      : undefined;

  if (confidenceInterval.length === 0 && !formattedReliabilityPercentage)
    return <></>;

  return (
    <span style={{ fontSize: 10, color: 'rgb(124, 133, 140)' }}>
      (
      {confidenceInterval.length > 0 ? (
        <>
          {confidenceInterval[0]} to {confidenceInterval[1]}
        </>
      ) : (
        <>{formattedReliabilityPercentage}</>
      )}
      )
    </span>
  );
};
