/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const ArrowButtons = ({
  onClick,
  elementKey,
}: {
  onClick?: (elementKey: string, direction: 'LEFT' | 'RIGHT') => void;
  elementKey: string;
}) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        width: 35px;
        margin: 0 auto;
      `}
    >
      <Button
        type="text"
        size="small"
        disabled={onClick === undefined}
        onClick={() => onClick && onClick(elementKey, 'LEFT')}
        icon={<LeftOutlined />}
      />
      <Button
        type="text"
        disabled={onClick === undefined}
        size="small"
        onClick={() => onClick && onClick(elementKey, 'RIGHT')}
        icon={<RightOutlined />}
      />
    </div>
  );
};
