/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Spin } from 'antd';

import { KnowledgeDiscoveryCore } from '../knowledge-discovery';
import { ExpertKnowledgeSidebar } from './expert-knowledge-sidebar.component';
import { useSession, GraphContextProvider } from '../../_shared/context';
import { ExpertKnowledgeContextProvider } from './expert-knowledge-context.component';
import {
  centeredHorizontallyFlexStyle,
  centerVerticallyFlexStyle,
} from '../../_shared/style/positioning.styles';
import {
  ExpertKnowledge,
  usegetExpertKnowledgeForProjectAndUserQuery,
} from '../../../../__generated__/globalTypes';

export const ExpertKnowledgeLayout = () => {
  const { currentProject } = useSession();

  const {
    data: expertKnowledgeFormulation,
    loading,
  } = usegetExpertKnowledgeForProjectAndUserQuery({
    variables: {
      projectId: currentProject!.id,
    },
  });

  if (loading)
    return (
      <Spin
        size="large"
        css={css`
          height: 100%;
          ${centerVerticallyFlexStyle};
          ${centeredHorizontallyFlexStyle};
        `}
      />
    );

  const expertKnowledge =
    (expertKnowledgeFormulation?.expertKnowledgeForProjectAndUser?.knowledges?.filter(
      r => r
    ) as ExpertKnowledge[] | null) ?? undefined;
  return (
    <>
      {currentProject?.activeModel?.formulationGraph && (
        <GraphContextProvider expertKnowledge={expertKnowledge}>
          <ExpertKnowledgeContextProvider>
            <div
              css={css`
                height: 100%;
                background-color: #272727;
              `}
            >
              <div
                css={css`
                  height: 100%;
                  float: right;
                  width: 36%;
                `}
              >
                <ExpertKnowledgeSidebar />
              </div>
              <div
                css={css`
                  height: 100%;
                  width: 64%;
                  overflow: hidden;
                `}
              >
                <KnowledgeDiscoveryCore modal={false} />
              </div>
            </div>
          </ExpertKnowledgeContextProvider>
        </GraphContextProvider>
      )}
    </>
  );
};
