/**
 * Colors listed from light to dark by major color group (oranges, greens, reds, blue-greys, blues, blacks)
 * based on the swatches from https://www.sketch.com/s/f5d93e61-7503-4ca7-b3a1-6185d9cbb337/a/wEPagl
 */

export enum Colors {
  WHITE = '#FFFFFF',
  BLACK = '#000000',

  GRADIENT_LIGHT = '#151B26',
  GRADIENT_DARK = '#737B81',

  //Oranges
  OLD_LACE = '#FCF4E4',
  GIN_FIZZ = '#F9E9C9',
  CHEROKEE = '#FBDC97',
  GOLDENROD = '#FDCF65',
  SUNGLOW = '#FFC233',
  SUPERNOVA = '#FDB234',
  LIGHTNING_YELLOW = '#FBA235',
  NEON_CARROT = '#F99236',
  //Greens
  CLEAR_DAY = '#DEF0EB',
  MINT_TULIP = '#C6EBDC',
  ALGAE_GREEN = '#95E0BD',
  MEDIUM_AQUAMARINE = '#6DCA9D',
  EMERALD = '#52BB87',
  OCEAN_GREEN = '#44B37C',
  MEDIUM_SEA_GREEN = '#37AB71',
  EUCALYPTUS = '#29A366',
  //Reds
  AMOUR = '#F6E5E8',
  CHERUB = '#FD54D6',
  BEAUTY_BUSH = '#F3B2B2',
  SWEET_PINK = '#F2918E',
  SUNSET_ORANGE = '#F15F57',
  CINNABAR = '#ED4238',
  DEEP_CARMINE_PINK = '#EC362C',
  LUST = '#EA291F',
  //Blue-Greys
  MYSTIC = '#E0EBF5',
  BOTTICELLI = '#CCD8E2',
  HEATHER = '#B7C5CF',
  BALI_HAI = '#8D9EA8',
  LYNCH = '#6A808C',
  SMALT_BLUE = '#455C69',
  CELLO = '#3A515E',
  PICKLED_BLUEWOOD = '#2E4552',
  //Greys
  GLITTER = '#E2EEFB',
  HAWKS_BLUE = '#CDE6FB',
  COLUMBIA_BLUE = '#A4D6FC',
  MALIBU = '#7BC7FD',
  PICTON_BLUE = '#52B7FD',
  DODGER_BLUE = '#0099FF',
  COOL_BLUE = '#0084FF',
  BRANDEIS_BLUE = '#006EFF',
  // Whites, Blacks Greys
  WHISPER = '#F6F5FA',
  SOLITUDE = '#EBEAEF',
  ZICRON = '#DFDFE4',
  FRENCH_GREY = '#BBBEC3',
  STORM_GREY = '#737B81',
  ARSENIC = '#37434A',
  TANGAROA = '#1D2E34',
  SWAMP = '#03191E',
  ALTO = '#E0E0E0',
  ALABASTER = '#FAFAFA',
  CONCRETE = '#F2F2F2',
  SILVER = '#CCCCCC',

  //These grey/blacks don't have names TBD
  '#010101' = '#010101',
  '#020709' = '#020709',
  '#020D10' = '#020D10',
  '#03191E' = '#03191E',
  '#102429' = '#102429',
  '#1D2E34' = '#1D2E34',
  '#256EFF' = '#266EFF',
  '#FAFAFC' = '#FAFAFC',
  '#BABDC1' = '#BABDC1',
  '#D2D2D2' = '#D2D2D2',
  '#E6E7E8' = '#E6E7E8',
  '#EBEAF0' = '#EBEAF0',
  '#EBEAEF' = '#EBEAEF',
  '#FBFAFD' = '#FBFAFD',

  // status colors
  '#EA291F' = '#EA291F',
  '#FBD4D2' = '#FBD4D2',
  '#F99600' = '#F99600',
  '#FFF0D6' = '#FFF0D6',
  '#29A366' = '#29A366',
  '#D4EDE0' = '#D4EDE0',

  // Other colors
  '#F0D2D1' = '#F0D2D1',
  DARK_CYAN = '#1A363F',
}

/* CSS HEX for Top 20 Categorical Colors */
/*The top 20 colors below are arranged the "convenient" order so that if you want six colors, for example, just choose the first six. The order of the colors is inspired by Sasha Trubetskoy's color study of the frequency of appearance of these colors on all the world's subway maps. The colors were also aligned with Turing's Brand Palette in order to create the most on-brand data visualizations and to avoid clashing with the rest of the interface. Additionally, this top 20 was reordered for maximum color blindness accessibility. The color purple-munsell, for example, is difficult for color blind individuals (5% of the population) to distinguish, so it was moved further down in the list of Top 20. */
export const ColorCategories = [
  '#3457d5',
  Colors.BALI_HAI,
  '#01cb2d',
  '#ffd000',
  Colors.LUST,
  '#f67a29',
  '#01e7f4',
  '#f032e6',
  '#fabed4',
  '#7cea96',
  '#3d8f85',
  '#dcbeff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#911eb4',
  '#000075',
  '#bfef45',
  Colors.WHITE,
  '#010101',
  '#603FC0',
  '#3457d5',
];

/**
 * Mostly intended for the simulation result graphs, these are a subset of the ColorCategories
 * but strong red/yellow/green have been removed as not to unintentially
 * indicate "Good/pass" "ok/warning", "bad/fail"
 *
 * I've also changed the order so it doesnt start off with bright neon or pastels
 */
export const NonIndicativeColors = [
  '#3457d5',
  '#f67a29',
  '#800000',
  '#7cea96',
  '#9a6324',
  '#dcbeff',
  '#3d8f85',
  '#bfef45',
  '#000075',
  '#603FC0',
  '#fffac8',
  '#01e7f4',
];

/**
 * Make a list of colors for the tabs here (the order will be reflected on the tabs and on the predicted outcomes panel)
 *
 */
export const WorkspaceProjectColors = {
  benchmark: '#CFCFCF',
  products: [
    '#FFB685',
    '#9BE1EC',
    '#D5B8F2',
    '#6A92C1',
    '#603FC0',
    '#C29C8B',
    '#338BFF',
  ],
};

/**
 * Make a list of colors for the outcome variables in Adaptive DOE chart
 *
 */
export const TargetVariableColors = [
  '#F17335',
  '#FFB685',
  '#9BE1EC',
  '#D5B8F2',
  '#6A92C1',
  '#603FC0',
  '#C29C8B',
  '#338BFF',
];

export const enabledSkeletonColor = (showSkeleton: boolean) =>
  showSkeleton ? Colors.FRENCH_GREY : Colors.BLACK;

export const IngredientCompositionColors = [
  '#EF4136',
  '#F2A541',
  '#F3CA40',
  '#4EA5FC',
  '#6EEB83',
  '#8338EC',
];
