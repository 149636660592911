//Fake projects
export const DUMMY_MODEL = 'AAAAAAAA-FF6C-4467-94E8-7B1DCA01206A';
export const DEMO_SAUCE_VISCOSITY = '23A888A8-9318-4E0C-97C0-8994DF381F99';
export const DEMO_SAUCE = '8EAC75EB-3F6C-41E6-86A9-B4903EC39B97';
export const MOCK_CEREAL_MODEL = 'EDF3FCCF-637E-403F-91E5-930C95B497A0';

/**
 * Another "demo" project but with a close to real dataset and model from ML API
 */
export const DEMO_SAUCE_MLAPI_MODEL = '586556c9-3a8d-45ad-aaba-bf64af9edaa0';
export const DEMO_ORDINAL_MLAPI_MODEL = 'dae0ed13-7551-4a7e-a866-7d27c3c818fd';

/**
 * Another fake project but with a real dataset and model
 */
export const DUMMY_SAUCE = '8EAC75EB-3F6C-41E6-86A9-B4903EC39B97';

/**
 * Unilever
 */
export const AEROSOLS_FLAMMABILITY = '0F19719D-C17D-47D0-886B-CE824109AEAD';
export const AEROSOLS_DIPTUBE_GROWTH = '874E08EF-EA2D-47EF-8126-04897BAA031E';
export const AEROSOLS_BASE_VISCOSITY = 'EF2E4099-F4FF-4EB0-8922-76048CEA68BF';
export const AEROSOLS_CAN_LIFE_PACKLIFE =
  'FFFE9E5A-2966-48F9-A24C-88FAE21FD91A';
export const AEROSOLS_CAN_LIFE_SPRAY_RATE =
  'CA25D8E9-0A9B-4675-9E42-948022CED613';
export const AEROSOLS_GLASS_HALFWAY = '1EA0EDF5-32C5-462A-A06C-DBF59A0715C6';
export const AEROSOLS_GLASS_NUMBER_OF_TURNS =
  '6D03B700-5EE1-4AD9-9325-D998447E11BE';
export const AEROSOLS_GASKET_SWELL_36550 =
  'DAE61366-B1D5-4D19-88B3-6B7A8014953F';
export const AEROSOLS_GASKET_SWELL_KA6712 =
  'B3BB46E3-E4FC-4B2E-8A3C-BFD9832C6054';
export const AEROSOLS_ENCAP_BREAKAGE_PERCENT =
  'FB8DA7DB-499F-4BC0-8DA9-1CF661F90536';
export const AEROSOLS_SPRAY_AND_WEIGH = 'E96C7A2A-944F-4A44-8BD3-320DF5DE6C9E';

//Laundry
export const MW_SRI = '44193B29-D5FF-4C38-816B-C4AD522AB380';
export const MW_GANZ = 'FE291F06-E82A-4CD0-BBCD-B3E4E9355DD5';
export const HW_GANZ = '29C1F34A-0BE2-44CE-B557-1B4125B867B0';
export const MW_R460 = 'D14E8F91-AE39-4271-B732-1AEB09675495';

export const HW_SRI = 'EB916D7A-5C20-4FA3-97D4-BB83A97F68CE';
export const HW_R460 = 'E7068AEE-A2EC-4323-802A-7E6B170954F6';

/**
 * Kraft
 */
export const KRAFT_BBQ_SAUCE = '9425ECF4-2D12-4A36-AC97-F5CD0A79084B';
export const KRAFT_BBQ_SAUCE_INGREDIENTS_ONLY =
  '1E35CD0D-CC17-4835-83EC-4D79068CEB62';

export const KRAFT_BBQ_SAUCE_PHASE_2_OVERALL_OPINION_INGREDIENTS =
  'E0709E25-BB59-47B5-B4C9-4432BB49B489';

/**
 * TESCO
 */
export const TESCO_TOMATO_CHILI_SAUCE = '414F8CA9-3DDB-4020-B992-4567A467D0B2';
export const TESCO_TOMATO_CHILI_SAUCE_INGREDIENTS_ONLY =
  'A7C6F760-D280-4338-9C00-39499FB34BC0';
export const TESCO_COTTAGE_PIE = '4C56E257-89E4-4842-B970-D02F422D59D7';
export const TESCO_COTTAGE_PIE_INGREDIENTS_ONLY =
  'AE31DADC-E4ED-4095-B9E4-CD6240FFB897';

export const TESCO_CHICKEN_BACON_PUFF_PASTRY =
  '4B29B930-5190-42FD-B737-5637F86A1D25';
export const TESCO_CHICKEN_KORMA_RICE = 'AFFE8B3D-437C-4E42-B1F6-C5515AC106AB';
export const TESCO_CHICKEN_TIKKA_MASALA_PILAU_RICE =
  '405A32DA-9E58-489C-89E8-5016A23E9548';
export const TESCO_COLESLAW = '464B7D9D-3E51-48B6-B79E-0FC63DF77F40';
export const TESCO_CHICKEN_CHORIZO_PAELLA =
  '3CECD3B7-E8E1-4CBF-93F6-546ECA6CA114';
export const TESCO_FROSTED_FLAKES = '3F03C734-12E5-4837-988D-DD0A65AB2BD1';
export const TESCO_FRUIT_NUT_GRANOLA = 'C984365F-CCA8-42BF-A194-7A03AFEEDC2F';
export const TESCO_PILLOWS_CHOCOLATE_CEREAL =
  'C6FE4D04-25C9-4FB4-A8F5-EB606A5FECF2';
export const TESCO_MACARONI_CHEESE = '64B9864B-B493-4BA2-AC96-8A3422B9BFE6';
export const TESCO_SPINACH_RICOTTA_CANNELLONI =
  'BE7BABCB-E343-478F-8D5B-84A569BE2826';
export const TESCO_STEAK_ALE_PUFF_PASTRY_PIE =
  '4045D1B1-5BE7-42B5-A763-CE2110E84BB7';
export const TESCO_STEAK_PUFF_PASTRY_PIE =
  '15D9E8F7-F4A6-4C4A-9C46-62DA87441E44';
export const TESCO_STEAK_RAGU = '2D52D969-FA35-4C35-86C5-83BB6253E8CA';
export const TESCO_SULTANA_BRAN_CEREAL = 'D62D83A4-8C9F-4B80-B798-D70F227D8BA2';
export const TESCO_SUPERBERRY_GRANOLA = '5DA6728C-C1C3-4648-A220-C34252AA87F8';

/**
 * Proctor and Gamble
 */
export const BLOOD_STAIN = 'AAD2358C-AB2D-4A30-9CFB-86A4213B926D';
export const TOMATO_PUREE = '7190E4CD-3F51-4B3D-AD75-8CFA0C36F6C8';
export const BURNT_BUTTER = 'A48E7AD9-0BCF-40F6-8F3F-F9E52F11EADB';
export const TEA_STAIN = '5758A3D5-E8E3-4E65-B59E-0ADE4D705133';
export const PCS132 = 'A5122D59-1DAD-4B16-B4C4-C1B5732529EF';

export const JAR_MODELS = [
  DUMMY_MODEL,
  DEMO_SAUCE_VISCOSITY,
  DEMO_SAUCE,
  DUMMY_SAUCE,
  AEROSOLS_FLAMMABILITY,
  AEROSOLS_DIPTUBE_GROWTH,
  AEROSOLS_BASE_VISCOSITY,
  AEROSOLS_CAN_LIFE_PACKLIFE,
  AEROSOLS_CAN_LIFE_SPRAY_RATE,
  AEROSOLS_GLASS_HALFWAY,
  AEROSOLS_GLASS_NUMBER_OF_TURNS,
  AEROSOLS_GASKET_SWELL_36550,
  AEROSOLS_GASKET_SWELL_KA6712,
  AEROSOLS_ENCAP_BREAKAGE_PERCENT,
  AEROSOLS_SPRAY_AND_WEIGH,
  MW_SRI,
  HW_GANZ,
  MW_GANZ,
  MW_R460,
  HW_R460,
  HW_SRI,
  KRAFT_BBQ_SAUCE,
  KRAFT_BBQ_SAUCE_INGREDIENTS_ONLY,
  KRAFT_BBQ_SAUCE_PHASE_2_OVERALL_OPINION_INGREDIENTS,
  TESCO_TOMATO_CHILI_SAUCE,
  TESCO_TOMATO_CHILI_SAUCE_INGREDIENTS_ONLY,
  TESCO_COTTAGE_PIE,
  TESCO_COTTAGE_PIE_INGREDIENTS_ONLY,
  TESCO_CHICKEN_BACON_PUFF_PASTRY,
  TESCO_CHICKEN_KORMA_RICE,
  TESCO_CHICKEN_TIKKA_MASALA_PILAU_RICE,
  TESCO_COLESLAW,
  TESCO_CHICKEN_CHORIZO_PAELLA,
  TESCO_FROSTED_FLAKES,
  TESCO_FRUIT_NUT_GRANOLA,
  TESCO_PILLOWS_CHOCOLATE_CEREAL,
  TESCO_MACARONI_CHEESE,
  TESCO_SPINACH_RICOTTA_CANNELLONI,
  TESCO_STEAK_ALE_PUFF_PASTRY_PIE,
  TESCO_STEAK_PUFF_PASTRY_PIE,
  TESCO_STEAK_RAGU,
  TESCO_SULTANA_BRAN_CEREAL,
  TESCO_SUPERBERRY_GRANOLA,
  BLOOD_STAIN,
  TOMATO_PUREE,
  BURNT_BUTTER,
  TEA_STAIN,
  PCS132,
];

export const isModelMLAPI = (modelId: string): boolean => {
  return JAR_MODELS.indexOf(modelId) === -1;
};
