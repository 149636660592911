import { useSession } from '../../../_shared/context/session-context';

import {
  Button,
  TreeDataNode,
  Typography,
  notification,
  Input,
  Flex,
  Checkbox,
} from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import './exploration-expanded-formulation-list.less';
import { useExploration } from '../../../_shared/context';
import {
  BackArrowButton,
  FilterIcon,
  FilterOutlined,
} from '../../../_shared/style';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { ExplorationExpandedFormulationItem } from './exploration-expanded-formulation-item/exploration-expanded-formulation-item';
import { FormulationItemType } from '../../../../../__generated__/globalTypes';
import { CheckboxProps } from 'antd/lib';
import { filter } from 'lodash';
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
export const ExplorationExpandedFormulationList = ({
  benchmark,
  formulations,
}: {
  benchmark?: FormulationType;
  formulations: FormulationType[];
}) => {
  const [displayFilter, setDisplayFilter] = useState(false);
  const { collapseFormulations } = useExploration();
  const [showIngredients, setShowIngredients] = useState(false);
  const [showOutcomes, setShowOutcomes] = useState(true);
  const [filteredBenchmark, setFilteredBenchmark] = useState<
    FormulationType | undefined
  >(benchmark);
  const [formulationsFiltered, setFormulationFiltered] = useState<
    Array<FormulationType>
  >([]);
  const [outcomesNames, setOutcomesNames] = useState<Array<string>>([]);
  const [ingredientsNames, setIngredientsNames] = useState<Array<string>>([]);

  const [outcomeCheckedList, setOutcomeCheckedList] = useState<string[]>([]);
  const [ingredientsCheckedList, setIngredientsCheckedList] = useState<
    string[]
  >([]);

  const checkAllOutcome = outcomesNames.length === outcomeCheckedList.length;
  const indeterminateOutcome =
    outcomeCheckedList.length > 0 &&
    outcomeCheckedList.length < outcomesNames.length;

  const checkAllIngredients =
    ingredientsNames.length === ingredientsCheckedList.length;
  const indeterminateIngredients =
    ingredientsCheckedList.length > 0 &&
    ingredientsCheckedList.length < ingredientsNames.length;

  const onChangeOutcome = (list: string[]) => {
    setOutcomeCheckedList(list);
  };

  const onCheckAllOutcomeChange: CheckboxProps['onChange'] = e => {
    setOutcomeCheckedList(e.target.checked ? outcomesNames : []);
  };

  const onChangeIngredient = (list: string[]) => {
    setIngredientsCheckedList(list);
  };

  const onCheckAllIngredientChange: CheckboxProps['onChange'] = e => {
    setIngredientsCheckedList(e.target.checked ? ingredientsNames : []);
  };

  const handleDisplayFilter = () => {
    setDisplayFilter(!displayFilter);
  };

  const handleFilter = () => {
    const handleFilterFormulation = (f: FormulationType) => {
      const newFormulation = { ...f };

      newFormulation.items = newFormulation.items.filter(i => {
        if (ingredientsCheckedList.includes(i.variable.name)) {
          return true;
        } else if (
          i.type === FormulationItemType.TARGET_PREDICTED ||
          i.type === FormulationItemType.TARGET_MEASURED
        ) {
          return true;
        }
        return false;
      });
      newFormulation.items = newFormulation.items.filter(i => {
        if (outcomeCheckedList.includes(i.variable.name)) {
          return true;
        } else if (i.type === FormulationItemType.INPUT) {
          return true;
        }
        return false;
      });

      return newFormulation;
    };

    const auxFormulations = formulations.map(handleFilterFormulation);
    if (benchmark) {
      const filteredBenchmark = handleFilterFormulation(benchmark);
      setFilteredBenchmark(filteredBenchmark);
    }

    setFormulationFiltered(auxFormulations);
    handleDisplayFilter();
  };

  useEffect(() => {
    if (formulations?.length > 0) {
      const f = formulations[0];
      const ingredients: Array<string> = [];
      const outcomes: Array<string> = [];
      f.items?.map(i => {
        if (i.type === FormulationItemType.INPUT) {
          ingredients.push(i.variable.name);
        } else if (i.type === FormulationItemType.TARGET_PREDICTED) {
          outcomes.push(i.variable.name);
        }
      });
      setIngredientsNames(ingredients);
      setOutcomesNames(outcomes);
      setIngredientsCheckedList(ingredients);
      setOutcomeCheckedList(outcomes);
      setFormulationFiltered(formulations.reverse());
    }
  }, [formulations]);

  return (
    <div id="expandend-formulation-list">
      <Button
        icon={<BackArrowButton />}
        className="back"
        size="small"
        type="primary"
        onClick={() => {
          collapseFormulations();
        }}
      >
        Back
      </Button>
      <div className="formulation-list-box">
        {filteredBenchmark && (
          <div className='benchmark-container'>
            <ExplorationExpandedFormulationItem formulation={filteredBenchmark} />
          </div>
        )}
        {formulationsFiltered.map(f => (
          <ExplorationExpandedFormulationItem formulation={f} />
        ))}
      </div>

      <div className="filter-box">
        <Button
          icon={<FilterOutlined />}
          className={`${displayFilter ? 'active' : ''} filter`}
          size="small"
          type="primary"
          onClick={() => {
            handleDisplayFilter();
          }}
        >
          Filter
        </Button>
        <div className={`${displayFilter ? 'active' : ''} filter-menu`}>
          <div className="filter-list">
            <div
              className={`list-option ${showOutcomes ? 'active' : ''}`}
              onClick={() => {
                setShowOutcomes(!showOutcomes);
              }}
            >
              Outcomes
            </div>
            <div
              className={`list-option ${showIngredients ? 'active' : ''}`}
              onClick={() => {
                setShowIngredients(!showIngredients);
              }}
            >
              Ingredients
            </div>
          </div>
          <div
            id="outcome-list"
            className={`ingredients-outcomes ${showOutcomes ? 'active' : ''}`}
          >
            <div className="title">
              <Checkbox
                indeterminate={indeterminateOutcome}
                onChange={onCheckAllOutcomeChange}
                checked={checkAllOutcome}
              >
                Outcomes
              </Checkbox>
            </div>
            <ul>
              <CheckboxGroup
                options={outcomesNames}
                value={outcomeCheckedList}
                onChange={onChangeOutcome}
              />
            </ul>
          </div>
          <div
            id="ingredient-list"
            className={`ingredients-outcomes ${showIngredients ? 'active' : ''
              }`}
          >
            <div className="title">
              <Checkbox
                indeterminate={indeterminateIngredients}
                onChange={onCheckAllIngredientChange}
                checked={checkAllIngredients}
              >
                Ingredients
              </Checkbox>
            </div>
            <ul>
              <CheckboxGroup
                options={ingredientsNames}
                value={ingredientsCheckedList}
                onChange={onChangeIngredient}
              />
            </ul>
          </div>
          <div className="footer-list">
            <Button
              className={` filter`}
              size="small"
              type="primary"
              onClick={() => {
                handleDisplayFilter();
              }}
            >
              Close
            </Button>
            <Button
              className={`filter-button`}
              size="small"
              type="primary"
              onClick={() => {
                handleFilter();
              }}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
