/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  SearchOutlined,
  ExperimentOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { QuickActionsButton } from './quick-actions-button';
import {
  Microscope,
  OrangeBeaker,
  FlowChart,
} from '../../../_shared/style/svg-assets';
import {
  ProjectFeature,
  WorkspaceType,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import {
  iconStyle,
  quickActionsBarContainerStyle,
  quickActionsBarStyle,
  quickActionsBarTitleStyle,
} from './quick-actions-styles';

export const QuickActionsBar = ({
  setShowWorkspaceModal,
  setWorkspaceType,
  setShowKnowledgeGraph,
}: {
  setShowWorkspaceModal: (show: boolean) => void;
  setShowKnowledgeGraph: (show: boolean) => void;
  setWorkspaceType: (workspaceType: WorkspaceType) => void;
}) => {
  const { currentProject } = useSession();

  const featureToggles = new Set(currentProject?.features.map(f => f.feature));
  const adaptiveLearningEnabled = featureToggles.has(
    ProjectFeature.ADAPTIVE_LEARNING
  );
  const labBenchFeatureEnabled = featureToggles.has(ProjectFeature.LAB_BENCH);
  const simulationFeatureEnabled = featureToggles.has(
    ProjectFeature.SIMULATION
  );
  const insightExplorerEnabled =
    featureToggles.has(ProjectFeature.INSIGHT_EXPLORER) &&
    currentProject?.activeModel?.formulationGraph;
  const showQuickActions =
    adaptiveLearningEnabled || labBenchFeatureEnabled || insightExplorerEnabled;

  const openWorkspaceModal = (workspaceType: WorkspaceType | undefined) => {
    setShowWorkspaceModal(true);
    if (workspaceType) setWorkspaceType(workspaceType);
  };

  return (
    <div>
      {showQuickActions && (
        <div css={quickActionsBarContainerStyle}>
          <div css={quickActionsBarTitleStyle}>Quick Actions</div>
          <div css={quickActionsBarStyle}>
            {adaptiveLearningEnabled && (
              <QuickActionsButton
                icon={<Microscope style={{ marginTop: '3px' }} />}
                title={'Start Adaptive Learning'}
                description={
                  'Explore new formulations where existing product data is minimal or limited.'
                }
                action={() =>
                  openWorkspaceModal(WorkspaceType.ADAPTIVE_LEARNING)
                }
              />
            )}
            {labBenchFeatureEnabled && (
              <QuickActionsButton
                icon={<OrangeBeaker style={{ marginTop: '3px' }} />}
                title={'Run Simulations'}
                description={
                  'Modify ingredient amounts to predict outcomes and optimize existing formulations.'
                }
                action={() => openWorkspaceModal(WorkspaceType.LAB_BENCH)}
                isDisabled={!simulationFeatureEnabled}
              />
            )}
            {insightExplorerEnabled && (
              <QuickActionsButton
                icon={<FlowChart style={{ marginTop: '5px' }} />}
                title={'View Insight Explorer'}
                description={
                  'Visualize ingredient and outcome relationships in product data.'
                }
                action={() => setShowKnowledgeGraph(true)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
