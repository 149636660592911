/** @jsxImportSource @emotion/react */
import {
  designResultsStyle,
  designSkeletonModalStyle,
} from '../styles/design-results.styles';

import { useDesign } from '../../../../_shared/context/design-context';
import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import { Alert } from 'antd';
import { DesignStates } from '../types';
import { DesignResultsOverview } from './design-results-overview.component';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

export const DesignResults = () => {
  const { designState } = useDesign();
  return (
    <div css={designResultsStyle}>
      <div>
        {designState === DesignStates.ERROR && <ErrorModal />}
        <DesignResultsOverview />
      </div>
    </div>
  );
};

const StatusModal = ({ children }: { children: EmotionJSX.Element }) => (
  <div css={designSkeletonModalStyle}>{children}</div>
);

const ErrorModal = () => {
  const { errorMessage } = useScenarioDetail();
  return (
    <StatusModal>
      <Alert
        message="Error"
        description={
          errorMessage ||
          'It looks like there was a technical issue. Please contact us for assistance.'
        }
        type="error"
        showIcon
      />
    </StatusModal>
  );
};
