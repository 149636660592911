/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { Button } from 'antd';
import * as Sentry from '@sentry/react';
import { OpenLink } from '../../../../../_shared/style';
import {
  usegetSignedUrlMutation,
  usegetFileLazyQuery,
  UrlType,
} from '../../../../../../../__generated__/globalTypes';
import { useSession } from '../../../../../_shared/context/session-context';

export const DownloadConstraints = () => {
  const [getUrl] = usegetSignedUrlMutation();
  const [getFile, { data }] = usegetFileLazyQuery();

  const { currentProject } = useSession();

  const downloadURI = (uri: string, name: string) => {
    let link = document.createElement('a');
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleFileFetch = async () => {
    try {
      if (!currentProject) return;
      await getFile({
        variables: {
          name: 'constraints',
          projectId: currentProject.id,
        },
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    handleFileFetch();
  }, [currentProject]);

  const handleURLFetch = async () => {
    if (!currentProject) return;
    let response = await getUrl({
      variables: {
        metadata: {
          name: 'constraints',
          projectId: currentProject.id,
          keyScope: 'project',
          urlType: UrlType.getObject,
        },
      },
    });
    if (response?.data?.signedUrl) {
      downloadURI(response.data.signedUrl, 'constraints');
    }
  };
  return (
    <div>
      {data && (
        <Button onClick={handleURLFetch}>
          <span style={{ marginRight: 10 }}>
            Download full list of constraints
          </span>{' '}
          <OpenLink />
        </Button>
      )}
    </div>
  );
};
