import React, { useMemo, useState } from 'react';
import { Button, Drawer, Radio, Space, Table, Tooltip } from 'antd';
import type { DrawerProps } from 'antd/es/drawer';
import type { RadioChangeEvent } from 'antd/es/radio';
import { DesignResultType } from '../workspaces/adaptive-learning/types';
import { useIngredients } from '../../_shared/hooks';
import { VariableType } from '../../../../__generated__/globalTypes';
import { CompositionBarChart } from './ingredient-composition-drawer-chart.component';
import { ColumnType } from 'antd/lib/table';
import { limitDecimals } from '../../_shared/utils/util';

type IngredientCompositionDrawerType = {
  formulation: DesignResultType;
  open: boolean;
  compositionId: string;
  onClose: () => void;
};

export const IngredientCompositionDrawer = (
  props: IngredientCompositionDrawerType
) => {
  const { formulation, open, compositionId, onClose } = props;
  const { ingredientByName } = useIngredients();
  const ingredientCompositionTotal = formulation?.formulation?.ingredientCompositionTotals?.find(
    ic => ic.compositionId === compositionId
  );
  const tableData = useMemo(
    () =>
      formulation?.formulation?.formulation
        .filter(({ name }) => {
          const i = ingredientByName.get(name);
          return i?.type === VariableType.NUMERIC;
        })
        .map(({ name, value }) => {
          const ingredient = ingredientByName.get(name);
          const ingredientCompositionContribution =
            ingredient?.ingredientCompositions?.find(
              ic => ic.ingredientCompositionId === compositionId
            )?.value ?? 0;
          return {
            key: name,
            name,
            ingredientAmount: value,
            ingredientCompositionContribution,
            compositionAmount:
              (Number(value ?? 0) * ingredientCompositionContribution) / 100,
          };
        })
        .sort((a, b) => b.compositionAmount - a.compositionAmount),
    [formulation, compositionId]
  );
  const tableColumns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Ingredient Contribution',
    },
    {
      key: 'compositionAmount',
      dataIndex: 'compositionAmount',
      title: '',
      render: (text: string, record: any) => (
        <Tooltip
          title={`(${record?.ingredientAmount ?? 0} * ${
            record?.ingredientCompositionContribution
          })/100 = ${record?.compositionAmount}`}
        >
          {text}%
        </Tooltip>
      ),
    },
  ];
  return (
    <Drawer
      title={`${ingredientCompositionTotal?.name}: ${
        formulation?.formulation?.name ?? formulation?.productName
      }`}
      placement={'right'}
      width={500}
      onClose={onClose}
      open={open}
    >
      <CompositionBarChart data={tableData} />

      <Table
        columns={[
          {
            key: 'name',
            dataIndex: 'name',
            title: 'Formulation Total',
          },
          {
            key: 'compositionAmount',
            dataIndex: 'compositionAmount',
            title: '',
            render: text => `${limitDecimals(text)}%`,
          },
        ]}
        dataSource={[
          {
            key: 'name',
            name: ingredientCompositionTotal?.name,
            compositionAmount: ingredientCompositionTotal?.total,
          },
        ]}
        size="small"
        pagination={false}
      />
      <Table
        columns={tableColumns}
        dataSource={tableData}
        size="small"
        pagination={{
          pageSize: 20,
        }}
      />
    </Drawer>
  );
};
