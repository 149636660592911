/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ObjectivesContainer } from '../../shared/goals/objectives-container.component';

import { Objective } from '../../../../../../__generated__/globalTypes';

import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import { Priorities } from '../types';
import {
  IMPORTANCE_MAX,
  IMPORTANCE_MEDIUM,
  IMPORTANCE_MIN,
  NO_IMPORTANCE,
} from '../../shared/goals/types';

export const DesignGoals = ({
  objectivesByTarget,
}: {
  objectivesByTarget: Map<string, Objective>;
}) => {
  return (
    <div className="optStepBox">
      <div
        css={css`
          padding: 13px;
        `}
      >
        {'Use arrows to change priority.'}
      </div>
      <ObjectiveContainers objectivesByTarget={objectivesByTarget} />
    </div>
  );
};

export const ObjectiveContainers = ({
  objectivesByTarget,
}: {
  objectivesByTarget: Map<string, Objective>;
}) => {
  const {
    updateObjective,
    changePriority,
    setObjectivesAreInEditMode,
  } = useScenarioDetail();
  const high: Objective[] = [];
  const med: Objective[] = [];
  const low: Objective[] = [];
  const noPriority: Objective[] = [];

  Array.from(objectivesByTarget?.values() || []).forEach(obj => {
    switch (obj.importance) {
      case IMPORTANCE_MAX:
        high.push(obj);
        break;
      case IMPORTANCE_MEDIUM:
        med.push(obj);
        break;
      case IMPORTANCE_MIN:
        low.push(obj);
        break;
      case NO_IMPORTANCE:
      default:
        noPriority.push(obj);
        break;
    }
  });

  return (
    <div>
      <ObjectivesContainer
        objectives={high}
        priorityLabel={Priorities.HIGH_PRIORITY}
        updateObjective={updateObjective}
        changePriority={changePriority}
        setObjectivesAreInEditMode={setObjectivesAreInEditMode}
      />
      <ObjectivesContainer
        objectives={med}
        priorityLabel={Priorities.MEDIUM_PRIORITY}
        updateObjective={updateObjective}
        changePriority={changePriority}
        setObjectivesAreInEditMode={setObjectivesAreInEditMode}
      />
      <ObjectivesContainer
        objectives={low}
        priorityLabel={Priorities.LOW_PRIORITY}
        updateObjective={updateObjective}
        changePriority={changePriority}
        setObjectivesAreInEditMode={setObjectivesAreInEditMode}
      />
      <ObjectivesContainer
        objectives={noPriority}
        priorityLabel={Priorities.NOT_PRIORITIZED}
        updateObjective={updateObjective}
        changePriority={changePriority}
        setObjectivesAreInEditMode={setObjectivesAreInEditMode}
      />
    </div>
  );
};
