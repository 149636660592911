/** @jsxImportSource @emotion/react */
import { Alert, Table, Modal, Tooltip, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
  expandableRowStyle,
  adaptiveLearningResultTableStyle,
} from './expandable-table.styles';
import { useDesign } from '../../../../_shared/context/design-context';
import { useMemo, useState } from 'react';
import { ProjectFeature } from '@prisma/client';
import { useSession } from '../../../../_shared/context';
import { useWorkspace } from '../../lab-bench/context';
import { css } from '@emotion/react';
import { EllipsisMiddle } from '../../../../_shared/utils/component';
import {
  BenchmarkIcon,
  GeneratedFormulationIcon,
  NonBenchmarkExistingIcon,
} from '../design-icons.component';
import { IngredientCompositionDrawer } from '../../../../components/ingredient-composition/ingredient-composition-drawer.component';
import { changeColumnBackground, normalizeClassName } from '../design-utils';
import { ExpandAltOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Colors } from '../../../../../iso/colors';
import { DesignResultType } from '../types';
import { formatDecimal } from '../../../../../iso/utils';
import { formatCostScore } from '../../../../_shared/utils/component';
import { useIngredients } from '../../../../_shared/hooks';
import { Price } from '../../../../_shared/components/text/price';

enum SubTableTypes {
  SUMMARY = 'SUMMARY',
  SCORING = 'SCORING',
  OUTCOMES = 'OUTCOMES',
  INPUTS = 'INPUTS',
  COMPOSITIONS = 'COMPOSITIONS',
}

type ExpandableTableDef = {
  key: SubTableTypes;
  name: string;
  expandable: boolean;
};

type DataSetType = Map<
  SubTableTypes,
  Array<{ key: string; name: string } & { [k: string]: number | string }>
>;

const { Text } = Typography;

const EmptyResultsModal = ({
  show,
  setShow,
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) => (
  <Modal
    open={show}
    onCancel={() => setShow(false)}
    onOk={() => setShow(false)}
    cancelButtonProps={{ hidden: true }}
  >
    <Alert
      css={css`
        margin-top: 15px;
      `}
      message="No Formulations Found"
      description="No results were found achieving a higher desirability than existing formulations; please modify your goals and constraints and try again."
      type="warning"
      showIcon
    />
  </Modal>
);

export const DesignFormulationResultsTable = () => {
  const { designResults, resultTableColumnRef, graphPointRef } = useDesign();
  const [openCompositionDrawer, setOpenCompositionDrawer] = useState<boolean>(
    false
  );
  const [drawerFormulation, setDrawerFormulation] = useState<
    DesignResultType
  >();
  const [drawerCompositionId, setDrawerCompositionId] = useState<string>();
  function getMap(): Map<string, HTMLElement> {
    if (!resultTableColumnRef?.current) {
      // Initialize the Map on first usage.
      resultTableColumnRef.current = new Map();
    }
    return resultTableColumnRef.current;
  }

  const { currentProject } = useSession();
  const { ingredientByName } = useIngredients();
  const ingredientCompositionIds = new Set([
    ...(currentProject?.ingredientComposition.map(i => i.id) ?? []),
  ]);
  const [showNoResutsModal, setShowNoResultsModal] = useState(
    !designResults.some(({ isGenerated }) => isGenerated)
  );

  const pricingEnabled = currentProject?.features.some(
    f => f.feature === ProjectFeature.PRICING
  );
  const benchmark = designResults.filter(d => d.isBenchmark);
  const formulationInputs = designResults[0].formulation.formulation.map(
    f => f.name
  );
  const [formulationsToShow, outcomesToShow] = useMemo(() => {
    const generatedFormulations = designResults.filter(d => d.isGenerated);
    const existingFormulations = designResults.filter(
      d => !d.isGenerated && !d.isBenchmark
    );
    const formulations = [
      ...benchmark,
      ...generatedFormulations,
      ...existingFormulations,
    ];
    /**
     * The model outcomes and tue design results outcomes can differ
     * We base the outcomes to show on the outcomes of the GENERATED RESULTS
     * If no results fall back to the  outcomes in the formulations the client uploaded
     * to build the model aka the observations
     */
    const outcomes = generatedFormulations.length
      ? generatedFormulations[0].outcomes?.map(o => o.outcomeName)
      : benchmark[0]?.outcomes?.map(o => o.outcomeName);

    const modelOutcomes = currentProject?.activeModel?.outcomes;
    if (modelOutcomes && outcomes)
      outcomes.sort((a, b) => {
        // Really bad: Need to associate the SimulationProductOutcome with the Outcome record
        // Source: https://github.com/Turing-Labs-AI/turing/blob/2f4a81a3f77e0aafbc98ef92f8cc7d5dcc43c18f/src/client/components/workspaces/lab-bench/predicted-outcomes/simulation-result-table/simulation-result-table.component.tsx#L371-L383
        const orderA =
          modelOutcomes.find(mo => mo.targetVariable === a)?.displayOrder ?? 0;

        const orderB =
          modelOutcomes.find(mo => mo.targetVariable === b)?.displayOrder ?? 0;
        return orderA - orderB;
      });

    return [formulations, outcomes ?? []];
  }, [designResults, currentProject]);

  /**
   * The table consists of many sub-tables,
   * This overall dataset maps the subtable type with the records that it contains
   * So when rendering the table you only have to pick the dataset of the subTableType
   * That has been selected
   */
  const dataset: DataSetType = useMemo(() => {
    const temp: DataSetType = new Map();

    /**
     * Set the rows of datapoints for each sub-table
     *
     * (This could be broken up/out)
     */
    Object.keys(SubTableTypes).forEach((key: SubTableTypes) => {
      //Add the fields for each table
      let rows: { key: string; name: string }[] = [];

      switch (key) {
        case SubTableTypes.COMPOSITIONS:
          currentProject?.ingredientComposition.forEach(ic => {
            rows.push({
              key: ic.id,
              name: ic.name,
            });
          });
          break;
        case SubTableTypes.INPUTS:
          formulationInputs.forEach(f => {
            rows.push({
              key: f,
              name: f,
            });
          });
          break;
        case SubTableTypes.OUTCOMES:
          outcomesToShow.forEach(o =>
            rows.push({
              key: o,
              name: o,
            })
          );
          break;
        case SubTableTypes.SCORING:
          //Need to be careful here,
          //I would prefer that the keys align to the type field names and change dynamically with them
          rows = [
            { key: 'turingScore', name: 'Overall Score' },
            { key: 'total_desirability', name: 'Desirability' },
            { key: 'penalty', name: 'Penalty' },
          ];
          if (pricingEnabled) {
            rows.push({ key: 'totalCostScore', name: 'Cost' });
          }
          break;
      }
      temp.set(key, rows);
    });

    /**
     * Then populate those rows with the formulation values
     */
    formulationsToShow.forEach(result => {
      const {
        formulation,
        outcomes,
        productName,
        scores,
        turingScore,
      } = result;

      //All the inputs for the formuation
      const formulationInputs = formulation.formulation.reduce((prev, curr) => {
        return { ...prev, [curr.name]: curr.value };
      }, {});

      const simpleOutcomes = outcomes?.reduce((prev, curr) => {
        return { ...prev, [curr.outcomeName]: curr.value };
      }, {});

      const simpleCompositions = formulation?.ingredientCompositionTotals?.reduce(
        (prev, curr) => {
          return { ...prev, [curr.compositionId]: curr.total };
        },
        {}
      );
      const easyAccess = {
        turingScore,
        total_desirability: scores.total_desirability,
        penalty: scores.penalty,
        totalCostScore: formatCostScore(
          formulation.totalCostScore ?? 0,
          currentProject?.costMeasurementUnit,
          currentProject?.monetaryUnit
        ),
        ...formulationInputs,
        ...simpleOutcomes,
        ...simpleCompositions,
      };

      temp.forEach((rows, table) => {
        rows.forEach(r => {
          //@ts-ignore
          const val = easyAccess[r.key];
          r[productName] = val;
        });
      });
    });

    return temp;
  }, [formulationsToShow, currentProject]);

  /**
   * This is the function that renders the individual sub table
   * @param record
   * @returns
   */
  const SubTableComponent = (record: ExpandableTableDef) => {
    if (record === undefined) {
      return <></>;
    }

    const tooltipMap = new Map<string, string>([
      [
        'Overall Score',
        "Desirability minus penalty for a formulation; provides a summarized picture of how a formulation meets your goal scenario's objectives and constraints",
      ],
      [
        'Desirability',
        'A measure of how well a formulation meets all of the prioritized objectives within your goal scenario',
      ],
      [
        'Penalty',
        "A measure of how far outside your goal scenario's constraints a formulation falls; a penalty of 0 means a formulation meets all constraints, or your goal scenario is unconstrained",
      ],
      [
        'Cost',
        'The sum of ingredient percentages multiplied by ingredient prices for each formulation',
      ],
    ]);

    const columns: ColumnsType = [
      //name field that shows what the measurement is being shown, such as ingredient or outcome name
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        ellipsis: true,
        className: 'sub-table-first-cell',
        render: (text: string, record) => {
          /*
            This render function controls what is displayed in the first column of this table.
            We have ingredients which need to be rendered with Price.
            Text with a tooltip for various scores.
            Defaults to truncated text
          */
          const ingredient = ingredientByName.get(text);
          const tooltipContent = tooltipMap.get(text);
          return tooltipContent ? (
            <Tooltip title={tooltipContent}>
              {text} <InfoCircleTwoTone />
            </Tooltip>
          ) : ingredient ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: '10px',
              }}
            >
              <EllipsisMiddle suffixCount={10}>{text}</EllipsisMiddle>
              <Price
                ingredient={ingredient}
                monetaryUnit={currentProject?.monetaryUnit}
                costMeasurementUnit={currentProject?.costMeasurementUnit}
              />
            </div>
          ) : (
            <EllipsisMiddle suffixCount={10}>{text}</EllipsisMiddle>
          );
        },
      },
    ];

    formulationsToShow.forEach(f => {
      columns.push({
        className: normalizeClassName(f.productName),
        title: '',
        dataIndex: f.productName,
        key: f.productName,
        width: 200,
        ellipsis: true,
        render: (
          text,
          record: {
            key: string;
            name: string;
          }
        ) => (
          <div
            onMouseEnter={() =>
              changeColumnBackground({
                name: f.productName,
                color: f.isGenerated ? Colors.SWEET_PINK : Colors.PICTON_BLUE,
                scrollToColumn: false,
                addShadow: true,
                columnRefMap: resultTableColumnRef,
                pointRefMap: graphPointRef,
              })
            }
            onMouseLeave={() =>
              changeColumnBackground({
                name: f.productName,
                color: 'white',
                scrollToColumn: false,
                addShadow: false,
                columnRefMap: resultTableColumnRef,
                pointRefMap: graphPointRef,
              })
            }
            ref={node => {
              const map: Map<string, HTMLElement> = getMap();
              if (map && node && !map.get(normalizeClassName(f.productName))) {
                map.set(normalizeClassName(f.productName), node);
              }
            }}
          >
            {ingredientCompositionIds.has(record.key) ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDrawerFormulation(f);
                  setDrawerCompositionId(record.key);
                  setOpenCompositionDrawer(true);
                }}
              >
                <span>
                  <Tooltip title="Click to see ingredient composition details">
                    <ExpandAltOutlined style={{ fontSize: '20px' }} />
                  </Tooltip>
                </span>{' '}
                <span>{formatDecimal(text) ?? 0}%</span>
              </div>
            ) : record.name === 'Cost' ? (
              // Text is actuall a React Element for the returned value of cost
              <span>{text}</span>
            ) : (
              <EllipsisMiddle suffixCount={10}>{text}</EllipsisMiddle>
            )}
          </div>
        ),
        align: 'right',
      });
    });

    const dataToShow = dataset.get(record.key);

    return (
      <Table
        columns={columns}
        dataSource={dataToShow}
        pagination={false}
        bordered
        showHeader={false}
      />
    );
  };

  const mainTableColumns: ColumnsType<ExpandableTableDef> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      ellipsis: true,
      render: (text, record, index) => {
        if (record.expandable) {
          return <p css={expandableRowStyle}>{text}</p>;
        }

        return text;
      },
    },
  ];

  formulationsToShow.forEach(f => {
    const icon = f.isGenerated ? (
      <GeneratedFormulationIcon />
    ) : f.isBenchmark ? (
      <BenchmarkIcon />
    ) : (
      <NonBenchmarkExistingIcon />
    );
    const isBenchmarkWithPassedName =
      f.isBenchmark && f.productName !== 'Benchmark Formulation';
    mainTableColumns.push({
      title: (
        <div
          css={
            isBenchmarkWithPassedName
              ? css`
                  margin-bottom: -15px;
                  margin-top: 10px;
                `
              : ''
          }
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={css`
                padding-right: 3px;
                display: flex;
                align-items: center;
              `}
            >
              {icon}
            </div>
            {f.isGenerated ? (
              <Tooltip
                title={
                  'Turing identifier includes project key, workspace ID, round of adaptive learning, and recommendation number.'
                }
              >
                {f.productName}
              </Tooltip>
            ) : (
              <EllipsisMiddle suffixCount={12}>{f?.productName}</EllipsisMiddle>
            )}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: left;
            `}
          >
            <Text type="secondary" style={{ marginLeft: '17px' }}>
              {isBenchmarkWithPassedName ? 'Benchmark' : ''}
            </Text>
          </div>
        </div>
      ),
      key: f.productName,
      dataIndex: f.productName,
      width: 212,
    });
  });

  /**
   * These are the sub-tables that will be rendered
   */
  const mainTableRows: ExpandableTableDef[] = [];

  mainTableRows.push({
    //Summary is the only row in the main table that is only a row
    //And not a sub-table
    key: SubTableTypes.SUMMARY,
    name: 'Summary Info',
    expandable: false,
    ...formulationsToShow.reduce((prev, curr) => {
      const text = curr.isBenchmark
        ? 'Best existing formulation for your goal scenario'
        : curr.isGenerated
        ? 'Turing-generated formulation based on your goal scenario'
        : 'An existing formulation in your project';

      return { ...prev, [curr.productName]: text };
    }, {}),
  });

  mainTableRows.push({
    key: SubTableTypes.SCORING,
    name: 'Turing Scoring',
    expandable: true,
  });

  mainTableRows.push({
    key: SubTableTypes.OUTCOMES,
    name: 'Outcomes',
    expandable: true,
  });

  mainTableRows.push({
    key: SubTableTypes.INPUTS,
    name: 'Ingredients',
    expandable: true,
  });

  mainTableRows.push({
    key: SubTableTypes.COMPOSITIONS,
    name: 'Compositions',
    expandable: true,
  });

  return (
    <div
      css={css`
        height: 75vh;
        overflow-x: scroll;
        max-height: calc(100vh - 225px);
      `}
    >
      <Table
        className={adaptiveLearningResultTableStyle}
        columns={mainTableColumns}
        expandable={{
          expandedRowRender: SubTableComponent,
          rowExpandable: record => record.expandable,
          defaultExpandAllRows: true,
          expandRowByClick: true,
          columnWidth: 1,
        }}
        dataSource={mainTableRows}
        pagination={false}
        rowClassName={record => {
          let additionalClass = '';
          if (record.key !== SubTableTypes.SUMMARY) {
            additionalClass = 'no-border-row sub-table-header-row ';
          } else {
            additionalClass = 'summary-row';
          }
          return `${additionalClass}`;
        }}
        scroll={{ x: 'max-content' }}
        bordered
      />
      <EmptyResultsModal
        show={showNoResutsModal}
        setShow={setShowNoResultsModal}
      />
      {drawerFormulation && drawerCompositionId && (
        <IngredientCompositionDrawer
          open={openCompositionDrawer}
          formulation={drawerFormulation}
          compositionId={drawerCompositionId}
          onClose={() => setOpenCompositionDrawer(false)}
        />
      )}
    </div>
  );
};
