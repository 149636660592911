/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { Fragment } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { Colors } from '../../../../iso/colors';

/*
 * Table components
 */

export const SummaryTable = styled.table`
  width: 100%;

  tr {
    height: 48px;
  }

  th {
    color: ${Colors.STORM_GREY};
    text-align: left;
    border-bottom: 2px solid ${Colors.SOLITUDE};
  }

  td {
    border-bottom: 1px solid ${Colors.SOLITUDE};
  }

  td:first-of-type {
    font-weight: bold;
  }
`;

/*
 * Table cell components
 */

export const Rank = ({ rank }: { rank: number | null | undefined }) =>
  rank !== undefined && rank !== null ? (
    <div
      css={css`
        display: inline-block;
        border-radius: 50%;
        background: ${Colors.SOLITUDE};
        width: 32px;
        height: 32px;
        vertical-align: middle;
        text-align: center;
        padding-top: 4px;
      `}
    >
      {rank}
    </div>
  ) : (
    <Fragment />
  );

export const PercentChange = ({
  value,
}: {
  value: number | null | undefined;
}) =>
  value !== undefined && value !== null ? (
    <div
      style={{
        // TODO:  what are the color breakdowns?
        color:
          value < -0.05
            ? Colors.LUST
            : value < 0.05
            ? Colors.NEON_CARROT
            : Colors.EUCALYPTUS,
      }}
    >
      {value > 0 ? (
        <ArrowUpOutlined />
      ) : value < 0 ? (
        <ArrowDownOutlined />
      ) : (
        <Fragment />
      )}{' '}
      {value}
    </div>
  ) : (
    <Fragment />
  );

export const Percent = ({ value }: { value: number | null | undefined }) =>
  value !== undefined && value !== null ? (
    <div
      style={{
        color: Colors.BLACK,
      }}
    >
      {value}
    </div>
  ) : (
    <Fragment />
  );
