/** @jsxImportSource @emotion/react */

import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { Button, Divider } from 'antd';
import { useGraph, useSession } from '../../../../../_shared/context';
import { useIngredients } from '../../../../../_shared/hooks';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';

import { ConstraintDriver } from './constraint-driver.component';
import { IngredientSearch } from '../../../../../_shared/components/input/ingredient-search.component';
import { DownloadConstraints } from './download-constraints.component';
import { NullableConstraintType } from '../types';
import { useOptimization } from '../v2/context';
import camelCase from 'lodash/camelCase';

export interface ConstraintProps {
  updateConstraint: (updateConstraint: NullableConstraintType) => void;
  constraints: Map<string, NullableConstraintType>;
  optimizationCalled: boolean;
}

export const OptimizationConstraints = ({
  updateConstraint,
  constraints,
  optimizationCalled,
}: ConstraintProps) => {
  const { graph } = useGraph();
  const { nodes } = graph;
  const { ingredientByName, ingredients } = useIngredients();
  const { simulationProductVersions } = useWorkspace();
  const { currentProject } = useSession();
  const { fillerIngredient } = useOptimization();

  const [showMoreDrivers, setShowMoreDrivers] = useState(false);
  const [benchmarkProduct] = useState(
    simulationProductVersions?.find(s => s.productVersion.isBenchmark)
  );

  const getBenchmarkValue = (ingredientName: string) => {
    let benchmarkVal: string | undefined;
    const id = ingredientByName?.get(ingredientName)?.ingredient.id;

    if (id && benchmarkProduct) {
      benchmarkVal =
        benchmarkProduct?.productVersion.formulation?.quantities[id] ?? '';
    }

    return benchmarkVal;
  };

  const topTenDrivers: string[] = useMemo(
    () =>
      Array.from(nodes.values())
        .filter(
          n =>
            !n.data.isTargetVariable &&
            ingredientByName.get(n.data.name)?.isActive
        )
        .sort((a, b) => b.data.weight - a.data.weight)
        .map(n => n.data.name)
        .slice(0, 10),
    []
  );

  const topThreeDrivers = topTenDrivers.slice(0, 3);
  const [userAddedDrivers, setUserAddedDrivers] = useState<string[]>([]);
  const [displayedDrivers, setDisplayedDrivers] = useState(
    new Set(topThreeDrivers)
  );

  useEffect(() => {
    let constraintDrivers = Array.from(
      new Set([...constraints.keys(), ...userAddedDrivers])
    );
    setUserAddedDrivers(constraintDrivers);
  }, [constraints]);

  useEffect(() => {
    const dispDrivers = new Set([...userAddedDrivers]);

    const topDrivers = showMoreDrivers
      ? [...topTenDrivers]
      : [...topThreeDrivers];

    topDrivers.forEach(d => {
      if (!dispDrivers.has(d)) {
        dispDrivers.add(d);
      }
    });

    setDisplayedDrivers(dispDrivers);
  }, [showMoreDrivers, userAddedDrivers]);

  const fillerIngredientDesc = () => {
    if (fillerIngredient) {
      const benchmarkValue = getBenchmarkValue(fillerIngredient);
      return (
        <div>
          {benchmarkValue && `Benchmark value is ${benchmarkValue}%.`} This
          ingredient will be used to fill the formulation to 100%
        </div>
      );
    }
  };

  const fillerIngredientBox = fillerIngredient && (
    <div className="stepContentGrey">
      <div
        css={css`
          padding-bottom: 9px;
        `}
      >
        <span
          css={css`
            font-weight: 700;
          `}
        >
          {fillerIngredient}
        </span>{' '}
        <span
          css={css`
            background: #acacac;
            border-radius: 17px;
            padding: 2px 12px;
            color: #ffffff;
          `}
        >
          Used as filler
        </span>
      </div>
      {fillerIngredientDesc()}
    </div>
  );

  const topDrivers = Array.from(displayedDrivers)
    .filter(driver => driver !== fillerIngredient) // Test how this effects sending the contraint list to ML-API, make sure that a blank/duplicate record isn't send
    .map((driver, idx) => {
      const constraintVal = constraints.get(driver) ?? {
        ingredientName: driver,
      };

      return (
        driver && (
          <div key={camelCase(constraintVal.ingredientName + 'Constraint')}>
            {idx !== 0 && <Divider />}

            <ConstraintDriver
              optimizationCalled={optimizationCalled}
              updateConstraint={updateConstraint}
              constraint={constraintVal}
              benchmarkValue={getBenchmarkValue(driver)}
              project={currentProject}
            />
          </div>
        )
      );
    });

  const searchableIngredients = useMemo(() => {
    return ingredients
      .filter(i => i.isActive)
      .sort((a, b) => a.ingredient.name.localeCompare(b.ingredient.name));
  }, [userAddedDrivers, topTenDrivers]);

  const addConstraint = (v: NullableConstraintType) => {
    updateConstraint(v);

    const updatedDriver = [v.ingredientName, ...userAddedDrivers];
    setUserAddedDrivers(updatedDriver);
  };

  return (
    <div className="optStepBox">
      <div className="steBoxTitle">Ingredient constraints and targets</div>
      <div style={{ margin: 10, marginLeft: 25 }}>
        <DownloadConstraints />
      </div>

      <div
        css={css`
          padding: 12px 12px 15px 12px;
        `}
      >
        <div className="stepContentGrey">
          The following ingredients have been identified as the top drivers for
          your outcomes.
        </div>
        <IngredientSearch
          ingredients={searchableIngredients}
          onSelect={val => {
            const selectedConstraint = constraints?.get(val) || {
              ingredientName: val,
            };
            addConstraint(selectedConstraint);
          }}
          isDisabled={optimizationCalled}
        />
        <Divider />
        {fillerIngredientBox}
        <Divider />
        {topDrivers}
        {!showMoreDrivers && !!topTenDrivers.length && (
          <div>
            <Button
              type="link"
              onClick={() => {
                setShowMoreDrivers(!showMoreDrivers);
              }}
            >
              View all 10 top drivers
            </Button>
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
};
