/** @jsxImportSource @emotion/react */

import { Form, Input, Button, Divider, Select, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { contentStyle } from '../../project-layout.styles';
import { useSession } from '../../../../_shared/context';
import { css } from '@emotion/react';
import {
  useaddNewProjectMutation,
  CreateProjectInput,
  TestType,
  ProjectStage,
} from '../../../../../../__generated__/globalTypes';
import { CSSProperties, useEffect, useState } from 'react';
import { projectKeyRules } from '../../../../_shared/utils/util';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { BaseProject } from '../../../../_shared/hooks/use-project.hook';
const { Option } = Select;
export const BasicInfoForm = ({
  submitHandler,
  title,
  existingProject,
  readOnly = false,
  style,
  hideSubmit = false,
}: {
  title?: ReactJSXElement;
  submitHandler?: (vals: CreateProjectInput) => void;
  existingProject?: BaseProject;
  readOnly?: boolean;
  style?: CSSProperties;
  hideSubmit?: boolean;
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addNewProject, { loading, error, data }] = useaddNewProjectMutation();
  const { user, useGetProjectKeys } = useSession();
  const [getProjectKeys] = useGetProjectKeys();

  const [current, setCurrent] = useState(0);

  const createProject = async (data: CreateProjectInput) => {
    try {
      await addNewProject({
        variables: {
          data: {
            ...data,
            name: data.name.trim(),
            testType: TestType.CONSUMER,
            stage: ProjectStage.FORMULATE,
          },
        },
      });
      navigate('/');
      //@ts-ignore
    } catch (e: { message: string }) {
      let message = 'Error creating project.';
      if (e?.message) {
        message = e?.message;
      }
      notification.error({ message });
    }
  };
  const defaultFormValues = existingProject
    ? {
        name: existingProject.name,
        key: existingProject.key,
        description: existingProject.description,
        productName: existingProject.productName,
        productItemNumber: existingProject.productItemNumber,
        brand: existingProject.brand,
        category: existingProject.category,
        subcategory: existingProject.subcategory,
        country: existingProject.country,
        typeOfDevelopment: existingProject.typeOfDevelopment,
        monetaryUnit: existingProject.monetaryUnit,
        costMeasurementUnit: existingProject.costMeasurementUnit,
        valuePrecision: existingProject.valuePrecision,
      }
    : {
        monetaryUnit: '$',
        costMeasurementUnit: 'g',
        typeOfdevelopment: 'newDevelopment',
      };
  return (
    <Form
      requiredMark
      disabled={readOnly}
      onFinish={submitHandler ?? createProject}
      form={form}
      style={style}
      initialValues={defaultFormValues}
      css={css`
        background-color: #ffffff;
        padding: 30px;
        width: 100%;
      `}
      layout="vertical"
    >
      {title ?? (
        <>
          <div
            css={css`
              font-size: 24px;
              font-weight: 600;
            `}
          >
            Create a New Project
          </div>
          <div
            css={css`
              font-size: 16px;
            `}
          >
            Enter general information about your project, including its name and
            a brief description, as well as optional pricing information and an
            image representing the product being explored.
          </div>
        </>
      )}
      <Divider />
      <div>
        <div
          css={css`
            font-size: 20px;
            font-weight: 600;
          `}
        >
          Project Info
        </div>
        <Form.Item
          required
          label="Project Name"
          name="name"
          rules={[{ required: true, whitespace: true }]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          required
          label="Project Key"
          name="key"
          rules={projectKeyRules}
          normalize={e => e.toUpperCase()}
        >
          <Input placeholder="Enter key" />
        </Form.Item>
        <Form.Item label="Project Goal" name="description">
          {/* Expandable text box with 500 char limit */}
          <TextArea
            rows={2}
            placeholder="Enter description"
            maxLength={500}
            disabled={readOnly}
          />
        </Form.Item>
        <Form.Item
          label="Outcome & Ingredient Rounding Amount"
          name="valuePrecision"
          css={css`
            width: 40%;
          `}
        >
          <Select
            placeholder="Select number of decimal places to round"
            onChange={e => form.setFieldValue('valuePrecision', e)}
          >
            <Option value={0}>0</Option>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
            <Option value={5}>5</Option>
          </Select>
        </Form.Item>
      </div>
      <Divider />
      <div>
        <div
          css={css`
            font-size: 20px;
            font-weight: 600;
          `}
        >
          About Product
        </div>
        <Form.Item label="Product Name" name="productName">
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item label="Product Item Number" name="productItemNumber">
          <Input placeholder="Enter number" />
        </Form.Item>
        <Form.Item label="Brand" name="brand">
          <Input placeholder="Enter brand" />
        </Form.Item>
        <Form.Item label="Category" name="category">
          <Input placeholder="Enter category" />
        </Form.Item>
        <Form.Item label="Subcategory" name="subcategory">
          <Input placeholder="Enter subcategory" />
        </Form.Item>
        <Form.Item label="Country/Region" name="country">
          <Input placeholder="Enter country or region" />
        </Form.Item>
        <Form.Item
          label="Type of Development"
          name="typeOfDevelopment"
          css={css`
            width: 40%;
          `}
        >
          <Select
            placeholder="Select Development Type"
            onChange={e => form.setFieldValue('typeOfdevelopment', e)}
          >
            <Option value="newDevelopment">New Development</Option>
            <Option value="reformulation">Reformulation</Option>
            <Option value="lineExtension">Line Extension</Option>
          </Select>
        </Form.Item>
      </div>
      <Divider />
      <div>
        <div
          css={css`
            font-size: 20px;
            font-weight: 600;
          `}
        >
          Pricing and Unit
        </div>
        <Form.Item
          label="Monetary Unit"
          name="monetaryUnit"
          css={css`
            width: 40%;
          `}
        >
          <Select
            placeholder="Select Monetary Unit"
            onChange={e => form.setFieldValue('monetaryUnit', e)}
          >
            <Option value="$">USD ($)</Option>
            <Option value="€">EUR (€)</Option>
            <Option value="£">GBP (£)</Option>
            <Option value="A$">AUD (A$)</Option>
            <Option value="C$">CAD (C$)</Option>
            <Option value="¥">JPY (¥)</Option>
            <Option value="CHF">CHF (CHF)</Option>
            <Option value="¥">CNY (¥)</Option>
            <Option value="kr">SEK (kr)</Option>
            <Option value="NZ$">NZD (NZ$)</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Ingredient Cost Measurement Unit"
          name="costMeasurementUnit"
          css={css`
            width: 40%;
          `}
        >
          <Select
            placeholder="Select Cost Measurement Unit"
            defaultValue={'g'}
            onChange={e => form.setFieldValue('g', e)}
          >
            <Option value="g">Grams (g)</Option>
            <Option value="kg">Kilograms (kg)</Option>
            <Option value="gal">Gallons (gal)</Option>
            <Option value="lb">Pounds (lb)</Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item>
        <div
          css={css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            background-color: #ffffff;
          `}
        >
          {hideSubmit ? null : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};
