import React, { useState, useContext, useMemo, useEffect } from 'react';
import { CytoscapeNodeSingular } from '../../../types/graph-data.types';
import { GraphV2 } from '../../components/knowledge-discovery/graph';
import { GraphV1 } from '../../components/knowledge-discovery/graph-v1';
import {
  NodeDef,
  EdgeData,
  InteractionState,
} from '../../components/knowledge-discovery/graph-v1/graph-v1-data.types';
import { useSession } from './session-context';
import {
  Outcome,
  ExpertKnowledge,
  GraphEdge,
  GraphNode,
  ProjectFeatureInsightExplorerVersion,
} from '../../../../__generated__/globalTypes';

const GraphContext = React.createContext<GraphContextProps>({} as any);

export type GraphContextProviderProps = {
  children?: React.ReactNode;
  /**
   * The ID of a Node to show as selected when opening the Graph
   */
  ingredientId?: string;
  expertKnowledge?: ExpertKnowledge[];
};

export interface GraphContextProps {
  graph: GraphV1 | GraphV2;
  fullscreen: boolean;
  setFullscreen: (value: boolean) => void;
  activeOutcome?: string;
  isInExpertKnowledgeContext: boolean;
  hoveredNode?: CytoscapeNodeSingular;
  setHoveredOrSelectedNode: (value?: CytoscapeNodeSingular) => void;
  /**
   * The minimum entropy value for a node to be visible, defaults to NEGATIVE_INFINITY aka shows all
   *
   * setting the `filterWeight` above 0 will darken/hide all nodes that fall below that threshold
   * leaving only the "top driver" nodes that are above that weight
   */
  filterWeight: number;

  contributionPercentage: number;
  /**
   * The slider on the UI shows a nodes percentage of the sum of all notes `absoluteValue`'s
   *
   * The Graph filters on the actual `absoluteValue` so this setter will take in the percentage but
   * save the `absoluteValue`.
   *
   * I.E. %6.5 input to 1.5532 filter value
   */
  setContributionPercentage: (contributionPercentage: number) => void;
  /**
   * The maximum entropy value in the set of nodes
   */
  maxNodeWeight: number;
  /**
   * The minimum entropy value in the set of nodes
   */
  minNodeWeight: number;
  maxNodeDirectContribution: number;
  minNodeDirectContribution: number;

  // BEGIN: For GraphV1
  setCurrentInteractionState: (value?: InteractionState) => void;
  setLabeledNode: (value?: CytoscapeNodeSingular) => void;
  setSelectedNode: (value?: CytoscapeNodeSingular) => void;
  labeledNode?: CytoscapeNodeSingular;
  // END: For GraphV1
}

export const GraphContextProvider = ({
  children,
  ingredientId,
  /**
   * Users can give expert knolwedge and add a node that we are not aware of
   * Additional nodes only exist for this user,
   * Not sure if we want the Graph to be holder of the expert knowledge tho
   */
  expertKnowledge,
}: GraphContextProviderProps) => {
  const { currentProject, insightExplorerVersion } = useSession();
  const [fullscreen, setFullscreen] = useState(false);
  const [filterWeight, setFilterWeight] = useState(Number.NEGATIVE_INFINITY);
  const [contributionPercentage, setContributionPercentage] = useState(0);
  const [sumOfAllNodeWeights, setSumOfAllNodeWeights] = useState(0);
  const [hoveredNode, setHoveredOrSelectedNode] = useState<
    CytoscapeNodeSingular | undefined
  >(undefined);
  const [isInExpertKnowledgeContext] = useState(Boolean(expertKnowledge));

  // BEGIN: For GraphV1
  const [labeledNode, setLabeledNode] = useState<
    CytoscapeNodeSingular | undefined
  >(undefined);
  const [selectedNode, setSelectedNode] = useState<
    CytoscapeNodeSingular | undefined
  >(undefined);
  const [currentInteractionState, setCurrentInteractionState] = useState<
    InteractionState | undefined
  >(undefined);
  // END: For GraphV1

  const outcomes: Outcome[] = currentProject?.activeModel?.outcomes
    ? [...currentProject?.activeModel?.outcomes]
    : [];

  const [activeOutcome, setActiveOutcome] = useState<string | undefined>(
    currentProject?.activeModel?.formulationGraph?.graphDefinitions?.[0]
      ?.outcomeName
  );

  const edges: GraphEdge[] = [];
  const nodes: GraphNode[] = [];
  /**
   * Only show the graph for the selected outcome
   */

  const currentGraphDataset = activeOutcome
    ? currentProject?.activeModel?.formulationGraph?.graphDefinitions?.find(
        g => g?.outcomeName === activeOutcome
      )
    : currentProject?.activeModel?.formulationGraph?.graphDefinitions?.[0];

  currentGraphDataset?.nodes.forEach(n => {
    nodes.push(n);
  });
  currentGraphDataset?.edges.forEach(e => {
    edges.push(e);
  });

  const getGraph = () => {
    if (insightExplorerVersion == ProjectFeatureInsightExplorerVersion.V1) {
      // Generate Nodes and edges that are compliant to how V1 IE can handle!
      //   The format of current graphDefinitions is to have nodes and edges for each outcome
      //   Using Set & Map so that we end up with unique nodes and source-target edge data
      const nodeSet = new Set<string>();
      const edgeSet = new Map<string, { source: string; target: string }>();
      const ingredientCategoryMap = new Map<
        string,
        { id: string; color: string; name: string }
      >();
      currentProject?.ingredientList.forEach(il =>
        ingredientCategoryMap.set(il.ingredient.name, il.category)
      );
      currentProject?.activeModel?.formulationGraph?.graphDefinitions?.forEach(
        graphDef => {
          graphDef?.nodes.forEach(n => nodeSet.add(n.name));
          graphDef?.edges.forEach(e =>
            edgeSet.set(`${e.source}--${e.target}`, {
              source: e.source,
              target: e.target,
            })
          );
        }
      );

      const v1Nodes: NodeDef[] = [
        ...Array.from(nodeSet).map(nodeName => {
          const category = ingredientCategoryMap.get(nodeName);
          return {
            data: {
              absoluteWeight: 1,
              weight: 20,
              size: 20,
              label: nodeName,
              name: nodeName,
              id: nodeName,
              color: category?.color || '#ffffff',
              // This is important
              isDependent: undefined,
              categoryName: category?.name || 'Ingredients',
            },
          };
        }),
        // Override with outcome information from DB
        ...outcomes.map(outcome => ({
          data: {
            // This is important
            absoluteWeight: undefined,
            weight: 20,
            size: 20,
            label: outcome.targetVariable,
            name: outcome.targetVariable,
            id: outcome.targetVariable,
            color: 'none',
            // This is important for outcome nodes
            isDependent: true,
          },
        })),
      ];

      const v1Edges: EdgeData[] = Array.from(edgeSet.values()).map(edge => ({
        data: {
          color: '#ffffff',
          weight: 3,
          source: edge.source,
          target: edge.target,
          size: 3,
        },
        classes: 'bezier',
      }));

      return new GraphV1({
        setLabeledNode,
        setSelectedNode,
        setCurrentInteractionState,
        nodes: v1Nodes,
        edges: v1Edges,
        ingredientId,
        expertKnowledge,
        outcomes,
      });
    } else {
      return new GraphV2({
        setHoveredOrSelectedNode,
        nodes,
        edges,
        ingredientId,
        expertKnowledge,
        outcomes,
        onOutcomeClick: outcome => setActiveOutcome(outcome),
        activeOutcome,
      });
    }
  };

  const [graph, setGraph] = useState(getGraph());

  useEffect(() => {
    if (activeOutcome !== undefined) {
      setGraph(getGraph());
    }
  }, [activeOutcome]);

  const [
    minNodeWeight,
    maxNodeWeight,
    minNodeDirectContribution,
    maxNodeDirectContribution,
  ] = useMemo(() => {
    const allWeights = Array.from(graph.nodes.values())
      .filter(n => !n.data.layoutOnlyNode && !n.data.isTargetVariable)
      .map(v => v.data.weight ?? 0);
    // TODO: Don't need to filter out undefineds once we implement clean data set

    const min = Math.min(...allWeights);
    const max = Math.max(...allWeights);
    const sum = allWeights.reduce((w, node) => w + (node ?? 0), 0);

    const minDirectContr = ((min ?? 0) / sum) * 100;
    const maxDirectContr = ((max ?? 0) / sum) * 100;
    setSumOfAllNodeWeights(sum);

    return [
      min,
      max,
      Number(minDirectContr.toFixed(2)),
      Number(maxDirectContr.toFixed(2)),
    ];
  }, [graph]);

  return (
    <GraphContext.Provider
      value={{
        graph,
        fullscreen,
        setFullscreen,
        labeledNode,
        activeOutcome,
        isInExpertKnowledgeContext,
        hoveredNode,
        setHoveredOrSelectedNode,
        filterWeight,
        setContributionPercentage(contributionPercent: number) {
          const absoluteVal = contributionPercent * 0.01 * sumOfAllNodeWeights;

          setFilterWeight(absoluteVal);
          setContributionPercentage(contributionPercent);
          graph.filterByNodeWeight(absoluteVal);
        },
        contributionPercentage,
        maxNodeWeight,
        minNodeWeight,
        maxNodeDirectContribution,
        minNodeDirectContribution,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};

export const useGraph = () => useContext(GraphContext);
