/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { Button, Checkbox, Divider, Popover, Select } from 'antd';
import { SearchIcon } from '../../style';
import { EllipsisMiddle } from '../../../_shared/utils/component';
import { FilterOutlined } from '@ant-design/icons';

const { Option } = Select;

export interface IngredientCategorySearchProps {
  onChange: (val: string[]) => void;
  onDeselect?: (val: string) => void;
  categories: string[];
  isDisabled?: boolean;
}

export const IngredientCategorySearch = ({
  onChange,
  categories,
}: IngredientCategorySearchProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const categoryOptions = categories.map(c => ({
    label: c,
    value: c,
  }));

  const content = (
    <div>
      <Checkbox.Group
        css={css`
          display: flex;
          flex-direction: column;
        `}
        options={categoryOptions}
        onChange={(checkedCategories: string[]) => onChange(checkedCategories)}
      />
    </div>
  );
  return (
    <Popover content={content}>
      <Button icon={<FilterOutlined />} onClick={handleClick} shape="circle" />
    </Popover>
  );
};
