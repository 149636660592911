/** @jsxImportSource @emotion/react */

import { useParams } from 'react-router-dom';
import { DesignContextProvider } from './design-context';
import { ExplorationContextProvider } from './exploration-context';
import { ScenarioDetailContextProvider } from './scenario-detail-context';
import { useSession } from './session-context';

export const WithExplorationContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { iterationId: iterationIdFromParams } = useParams();
  const { getSelectedIteration, setSelectedIterationId } = useSession();
  if (iterationIdFromParams) setSelectedIterationId(iterationIdFromParams);
  const selectedIteration = getSelectedIteration();
  return (
    <ScenarioDetailContextProvider iteration={selectedIteration}>
      <DesignContextProvider
        iteration={selectedIteration}
        key={iterationIdFromParams}
      >
        <ExplorationContextProvider>{children}</ExplorationContextProvider>
      </DesignContextProvider>
    </ScenarioDetailContextProvider>
  );
};

export const WithDesignContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getSelectedIteration } = useSession();

  return (
    <ScenarioDetailContextProvider iteration={getSelectedIteration()}>
      <DesignContextProvider iteration={getSelectedIteration()}>
        {children}
      </DesignContextProvider>
    </ScenarioDetailContextProvider>
  );
};
