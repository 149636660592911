/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { useMemo } from 'react';
import { parse } from 'papaparse';
import { Upload, message, Button, Divider } from 'antd';
import { OptimizationRunningMessage } from './optimization/v2/optimization-running-message.component';
import {
  ArrowRightOutlined,
  ExperimentFilled,
  InboxOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { DraggerProps } from 'antd/lib/upload';
import { useWorkspace } from '../../../_shared/context/workspace-context';
import { useSession } from '../../../_shared/context';
import { FormulationTemplateDownload } from './formulation-template-download.component';
import {
  optimizationColStyles,
  uploadColStyles,
} from './styles/workspace-setup.styles';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';

import { Colors } from '../../../../iso/colors';
import { ProjectFeature } from '.prisma/client';
import { JobStatus } from '@prisma/client';
import { Link } from 'react-router-dom';
import {
  createPlaceholderBenchmark,
  enforceSingleBenchmark,
  escapeQuotes,
} from '../../../_shared/utils/util';
import { SimulationProductVersionResultType } from './context/types';

const { Dragger } = Upload;

export const WorkspaceSetup = () => {
  const {
    setSimulationProductVersions,
    setShowOptimization,
    latestOptimization,
    iteration,
    usingLegacyApi,
    isWorkspaceOwner,
  } = useWorkspace();

  const { currentProject } = useSession();

  const useOptimization = currentProject?.features.some(
    f => f.feature === ProjectFeature.OPTIMIZATION
  );

  const formulationsCsvFileDraggerProps: DraggerProps = {
    accept: '.csv',
    name: 'file',
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status === 'error') {
        void message.error(`${info.file.name} file upload failed.`);
        throw new Error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: file => {
      logEvent(TrackableEvent.WORKSPACE_CSV_UPLOADED, {
        projectId: currentProject?.id,
      });
      if (file.size) {
        parse<Array<string>>(file, {
          complete: results => {
            setCsvUploadData(results.data);
          },
        });
      } else {
        void message.error(`${file.name} file upload failed.`);
      }
      return false;
    },
  };

  /**
   * On showing this component, make a lookup of ingredients id to name.
   * Does not need to happen on every render.
   *
   * Might be best in a context somewhere if re-used
   */
  const ingredientNameToId = useMemo(() => {
    return new Map(
      currentProject!.ingredientList
        .filter(i => i.isActive)
        .map(ing => [ing.ingredient.name, ing.ingredient.id])
    );
  }, [currentProject?.ingredientList]);

  const setCsvUploadData = (fileData: string[][]) => {
    if (!fileData.length) {
      throw new Error('Error uploading CSV data');
    }

    //produtRow contains the ordered names of the products`
    const [, ...productRow] = fileData[0];
    //benchmarkRow indexes mirror productRow's and has 'true' set on the index that is a benchmark

    let benchMarkRow: boolean[] = [];

    //If a benchmark row is present
    if (fileData[1][0]?.toLowerCase().includes('benchmark')) {
      const [, ...markers] = fileData[1];
      benchMarkRow = markers.map(
        str => str.toLowerCase() === 'true' || str.toLowerCase() === 'benchmark'
      );
    }

    const productMap = new Map<string, SimulationProductVersionResultType>();
    for (let i = 0; i < productRow.length; i++) {
      productMap.set(productRow[i], {
        // zero based
        columnNumber: i,
        productVersion: {
          isBenchmark: !!benchMarkRow && !!benchMarkRow[i],
          isOptimization: false,
          name: productRow[i],
          formulation: { quantities: {} },
        },
      } as SimulationProductVersionResultType);
    }

    // For each ingredient (aka row) find each products value for it.
    // The products will be the columns
    //First two rows are not products
    //Neither is last row

    //Starting at row 1, might be a benchmark selector, but we ignore non-model inputs anyway
    for (let i = 1; i < fileData.length; i++) {
      const ingredientId = ingredientNameToId.get(fileData[i][0]);
      if (!ingredientId) {
        console.error(
          `Uploaded CSV contained ${fileData[i][0]} which could not be found`
        );
        continue;
      }

      let amount = '';
      for (let j = 1; j < fileData[i].length; j++) {
        amount = fileData[i][j];

        productMap.get(
          productRow[j - 1]
        )!.productVersion.formulation!.quantities[ingredientId] = amount;
      }
    }

    setInitialFormulationsForWorkspace(Array.from(productMap.values()));
  };

  /**
   * When initializing a workspace we are enforcing that only a single benchmark exists
   * @param spvs
   */
  const setInitialFormulationsForWorkspace = (
    spvs: SimulationProductVersionResultType[]
  ) => {
    setSimulationProductVersions(enforceSingleBenchmark(spvs));
  };

  const optimizationInProgress =
    latestOptimization?.projectJob?.status === JobStatus.IN_PROGRESS ||
    latestOptimization?.projectJob?.status === JobStatus.PENDING;

  const canRunSimulation = currentProject?.features.some(
    f => f.feature === ProjectFeature.SIMULATION
  );

  const uploadColumn = (
    <div css={uploadColStyles}>
      <div>Have predefined formulations in a CSV file?</div>

      <Dragger {...formulationsCsvFileDraggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Accepts Single CSV files</p>
      </Dragger>

      <div className="templateDownload">
        <FormulationTemplateDownload
          rowInfo={Array.from(ingredientNameToId.keys()).map(key =>
            escapeQuotes(key)
          )}
          workspaceId={`${currentProject?.key}${iteration?.key}`}
        />
      </div>
    </div>
  );

  const optimizationColumn = (
    <div css={optimizationColStyles}>
      {optimizationInProgress ? (
        <React.Fragment>
          <OptimizationRunningMessage
            spinnerSize="small"
            showEmailNotification
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="createOptimizationTitle">
            Create Turing Optimization
            <span
              css={css`
                padding-left: 40px;
              `}
            >
              <PlusOutlined
                style={{
                  fontSize: '9px',
                  strokeWidth: 45,
                  stroke: 'black',
                  color: 'black',
                }}
              />
              <ExperimentFilled
                style={{ height: '13px', width: '14.62', paddingLeft: '3px' }}
              />
            </span>
          </div>
          <p>Let Turing find you the optimal formulation</p>

          <Button
            type="default"
            onClick={() => {
              setShowOptimization(true);
            }}
            disabled={!canRunSimulation || !isWorkspaceOwner}
            css={css`
              background: ${Colors.ARSENIC};
              color: white;
              width: 209px;
              height: 33px;
              border-radius: 6px;
              font-size: 13px;
            `}
          >
            {usingLegacyApi ? (
              <span>
                Create Turing Optimization <ArrowRightOutlined />
              </span>
            ) : (
              <Link
                to={`/project/${currentProject?.id}/iteration/${iteration?.id}/optimization`}
              >
                Create Turing Optimization <ArrowRightOutlined />
              </Link>
            )}
          </Button>
        </React.Fragment>
      )}
    </div>
  );

  return (
    <div>
      <div
        css={css`
          font-size: 16px;
          font-weight: bold;
          padding-bottom: 15px;
        `}
      >
        Workspace Setup
      </div>
      <div
        css={css`
          display: flex;
        `}
      >
        {uploadColumn}
        {useOptimization && (
          <div
            css={css`
              font-size: 20px;
              width: 98px;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0px 35px 0px 35px;
            `}
          >
            <Divider type="vertical" style={{ height: '100%' }} />
            <div
              css={css`
                position: absolute;
                background-color: #fafafc;
                top: 25%;
              `}
            >
              {!optimizationInProgress && <span>OR</span>}
            </div>
          </div>
        )}

        {useOptimization && optimizationColumn}
      </div>
      <div
        css={css`
          float: right;
        `}
      >
        <Button
          type="default"
          onClick={() => {
            logEvent(TrackableEvent.WORKSPACE_INTITIAl_FORMULATION_SKIPPED, {
              projectId: currentProject?.id,
            });
            setSimulationProductVersions(createPlaceholderBenchmark());
          }}
        >
          Skip this step <ArrowRightOutlined />
        </Button>
      </div>
    </div>
  );
};
