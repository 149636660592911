import { Tooltip, Typography } from 'antd';
import * as React from 'react';
import { limitDecimals } from './util';

export const EllipsisMiddle = (props: {
  suffixCount: number;
  style?: React.CSSProperties;
  children: React.ReactElement | string | number | null | undefined;
}): React.ReactElement => {
  const { suffixCount, style, children: initialVal } = props;
  let text = '';

  if (typeof initialVal === 'string' || typeof initialVal === 'number') {
    text = `${initialVal}`;
  }
  const length = suffixCount * 2;
  if (text.length <= length) {
    return <span>{text}</span>;
  }

  const prefixLen: number = Math.floor(length / 2);
  const suffixLen: number = length - prefixLen;
  const truncatedText: string =
    text.slice(0, prefixLen) + '...' + text.slice(text.length - suffixLen);

  return <Tooltip title={text}>{truncatedText}</Tooltip>;
};

export const Ellipsis = (props: {
  charCount: number;
  style?: React.CSSProperties;
  children: React.ReactElement | string | number | null | undefined;
}): React.ReactElement => {
  const { charCount, style, children: initialVal } = props;
  let text = '';

  if (typeof initialVal === 'string' || typeof initialVal === 'number') {
    text = `${initialVal}`;
  }
  if (text.length <= charCount) {
    return <span>{text}</span>;
  }

  const truncatedText: string = text.slice(0, charCount - 2) + '...';

  return <Tooltip title={text}>{truncatedText}</Tooltip>;
};

export const formatCostScore = (
  costValue: number | string,
  costMeasurementUnit?: string,
  monetaryUnit?: string
) => {
  const roundedValue = limitDecimals(costValue, 2);
  const displayValue = `${monetaryUnit ?? ''}${costValue}${
    costMeasurementUnit ? '/' + costMeasurementUnit : ''
  }`;
  const roundedDisplayValue = `${monetaryUnit ?? ''}${roundedValue}${
    costMeasurementUnit ? '/' + costMeasurementUnit : ''
  }`;
  return <Tooltip title={displayValue}>{roundedDisplayValue}</Tooltip>;
};
