import React from 'react';
import { Button, Divider, Popover, Progress } from 'antd';
import { limitDecimals } from '../../../_shared/utils/util';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { FormulationItemType } from '../../../../../__generated__/globalTypes';

type ExperimentPopover = {
  formulation: FormulationType;
  children: JSX.Element | JSX.Element[];
};
export const ExperimentPopover = ({
  children,
  formulation,
}: ExperimentPopover) => {
  const content = (
    <>
      {formulation?.items
        ?.filter(f => f.type === FormulationItemType.INPUT)
        .sort((a, b) => b.value - a.value)
        .filter(f => Number(f.value) > 1)
        .map(
          (f, index) =>
            index < 10 && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: 5,
                    paddingBottom: 5,
                    width: 300,
                  }}
                >
                  <div style={{ marginRight: 5 }}>{f.variable.name}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {Number(limitDecimals(f.value, 2))}%
                    <Progress
                      style={{ marginLeft: 5 }}
                      type="circle"
                      percent={Number(limitDecimals(f.value, 2))}
                      width={20}
                      strokeColor={'#ef4036'}
                      format={() => <p>{Number(limitDecimals(f.value, 2))}%</p>}
                      strokeWidth={15}
                    />
                  </div>
                </div>
                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
              </>
            )
        )}
    </>
  );

  return (
    <Popover
      content={content}
      style={{ maxWidth: 300 }}
      title="Top Ingredients"
      placement="right"
    >
      {children}
    </Popover>
  );
};
