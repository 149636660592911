import { css } from '@emotion/react';

export const downloadToggleStyle = css`
  display: flex;
  align-items: center;
`;

export const modalStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;
  pointer-events: none;

  label {
    /* I give up */
    color: #ccc !important;
  }

  .ant-btn-set {
    border-radius: 8px;
    border: none;
    background-color: var(--dark-btn-background);
    color: #fff;
    pointer-events: auto;
    user-select: none;

    label {
      padding-left: 8px;
      display: inline-flex;
      align-items: center;
    }

    .label-icon {
      padding-left: 8px;
      display: flex;
      /* position: relative;
      top: 8px;
      left: 8px;
      margin-right: 8px; */
      color: var(--dark-btn-foreground);
    }

    svg {
      display: flex;
    }

    .ant-divider-vertical {
      border-left: 1px solid rgb(255, 255, 255, 0.2);
      height: 20px;
      margin: 0 12px;
    }

    .settings-dropdown-trigger .ant-btn {
      margin-left: -12px;
    }

    .ant-dropdown-trigger > .anticon.anticon-down {
      vertical-align: middle;
    }

    &:not(:first-of-type) {
      margin-left: 8px;
    }

    &.turing-logo {
      background-color: #000;
      font-size: 16px;

      .ant-btn {
        background-color: var(--turing-red);
        margin-right: 8px;

        &:hover {
          background-color: #fff;
        }
      }
    }

    input,
    .ant-btn {
      background-color: var(--dark-btn-background);
      border: none;
      border-radius: 8px;

      &.vertical {
        display: block;
      }

      &:not(:first-of-type) {
        margin-left: 8px;

        &.vertical {
          margin-left: 0;
          margin-top: 2px;
        }
      }

      .anticon,
      svg {
        color: var(--dark-btn-foreground);
        font-size: 16px;
      }
    }

    input[type='text'] {
      margin-right: 8px;
      color: #fff;

      &:focus {
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        border-bottom: 1px solid var(--dark-btn-foreground) !important;
      }
    }

    .ant-dropdown-trigger {
      color: #fff;
    }

    .ant-radio-group {
      margin-left: 8px;
    }

    .ant-radio-button-wrapper {
      margin-left: 0;
      border: none;
      background-color: var(--dark-btn-background);
      border-radius: 8px;
      padding: 8px;
      line-height: normal;

      ::before {
        width: 0;
      }

      & > span:last-child .radio-button-label {
        position: relative;
        top: -6px;
      }

      &.ant-radio-button-wrapper-checked {
        background-color: var(--dark-btn-active-background);

        svg {
          color: #ccc;
        }

        & > last-child .radio-button-label {
          color: #ccc;
        }
      }
    }

    .ant-btn,
    .ant-radio-button-wrapper,
    .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
    .ant-dropdown-trigger {
      &:hover {
        background: var(--dark-btn-hover-background);
        color: #fff;

        span {
          color: #fff;
        }

        svg {
          color: #fff;
        }
      }
    }
  }

  .dropdown-option {
    pointer-events: auto;
    margin-bottom: 16px;

    label {
      text-transform: uppercase;
    }

    .ant-select {
      display: block;

      .ant-select-selector {
        background: var(--dark-btn-background);
        border-radius: 8px;
        border: 1px solid var(--dark-border-color);
        color: #fff;
      }
    }

    .ant-collapse {
      border-radius: 8px;
      border: 1px solid var(--dark-border-color);
      background: #020d10;
      color: #fff;

      .ant-collapse-item {
        border: none;

        .ant-collapse-header {
          background: var(--dark-btn-background);
          color: #fff;
          border-radius: 8px;
          padding: 6px 16px 7px 36px;
          border: 1px solid var(--dark-border-color);
        }

        .ant-collapse-content {
          color: #fff;
          background: #020d10;
          border: none;
        }
      }
    }
  }

  .ant-dropdown-menu {
    background: #1f1f1f;
    padding: 12px;
    min-width: 350px;

    .ant-dropdown-menu-submenu,
    .ant-dropdown-menu-sub {
      background: var(--dark-btn-background);
      color: #fff;
      border-radius: 8px;
      padding: 4px 8px 4px 16px;
      border: 1px solid var(--dark-border-color);

      &:hover {
        border-color: var(--ant-slider-primary-color);

        .ant-dropdown-menu-submenu-title {
          background: var(--dark-btn-background);
        }

        .ant-dropdown-menu-submenu-arrow-icon {
          color: var(--ant-slider-primary-color);
          transform: none;
        }
      }

      .ant-dropdown-menu-title-content,
      .ant-dropdown-menu-submenu-arrow-icon {
        color: #fff;
      }

      .ant-dropdown-menu-submenu-arrow-icon {
        transform: rotate(90deg);
        transition: transform 0.25s;
      }
    }
  }
`;
