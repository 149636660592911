import * as Mixpanel from 'mixpanel-browser';
import { captureMessage } from '@sentry/react';

export const initMixpanel = (email: string, userId: string) => {
  if (!email || !userId) {
    captureMessage(
      `Failure to gather user info for user analytics init, Email: ${email} userId ${userId}`
    );
  }

  if (__MIXPANEL_KEY__) {
    try {
      Mixpanel.init(__MIXPANEL_KEY__, { debug: true, api_method: 'POST' });
      Mixpanel.identify(userId);
      Mixpanel.people.set({
        USER_ID: userId,
        $email: email,
      });
      Mixpanel.track('Initialize');
    } catch (e: any) {
      console.log('Error initializing Mixpanel', e);
      captureMessage(e);
    }
  } else {
    console.warn('Mixpanel not configured--- no api key!');
  }
};
