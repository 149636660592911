/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { Button, TablePaginationConfig } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { IterationTable } from '../../components/iteration';
import { DashboardPanel } from '../../components/dashboard/dashboard-panel.component';
import { QuickActionsBar } from '../../components/project/quick-actions-bar/quick-actions-bar';
import {
  ProjectFeature,
  WorkspaceType,
} from '../../../../__generated__/globalTypes';
import { useSession } from '../../_shared/context';
import { CreateIterationModal } from '../../components/iteration/iteration-modal';
import { KnowledgeDiscovery } from '../../components/knowledge-discovery';

export const ProjectWorkspaceListPage = () => {
  const {
    currentProject,
    setIterationTableFilters,
    iterationTableFilters,
    user,
  } = useSession();

  const [showModal, setShowModal] = useState(false);
  const [tableCount, setTableCount] = useState<number>(0);
  const [showDisabledWorkspaces, setShowDisabledWorkspaces] = useState(false);
  const [workspaceType, setWorkspaceType] = useState<WorkspaceType>();
  const [showKnowledgeGraph, setShowKnowledgeGraph] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setWorkspaceType(undefined);
  };

  const isSuperAdmin = user?.role === 'SUPER_ADMIN';
  const featureToggles = new Set(
    currentProject?.features?.map(f => f.feature) || []
  );
  const canCreateWorkspace =
    featureToggles.has(ProjectFeature.SIMULATION) ||
    featureToggles.has(ProjectFeature.ADAPTIVE_LEARNING);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, string[] | null>,
    sorter: any,
    extra: {
      currentDataSource: any[];
    }
  ) => {
    const filtersByProject = new Map<string, Record<string, string[] | null>>(
      iterationTableFilters
    );
    filtersByProject.set(String(currentProject?.id), filters);
    setIterationTableFilters(filtersByProject);
  };

  return (
    <>
      <QuickActionsBar
        setShowWorkspaceModal={setShowModal}
        setWorkspaceType={setWorkspaceType}
        setShowKnowledgeGraph={setShowKnowledgeGraph}
      />
      <DashboardPanel
        header={[
          <div key="workspaceIterations">
            {`${tableCount} Workspace${
              tableCount === 0 || tableCount > 1 ? 's' : ''
            } `}
            {isSuperAdmin && (
              <Button
                onClick={() =>
                  setShowDisabledWorkspaces(!showDisabledWorkspaces)
                }
              >
                {showDisabledWorkspaces ? 'Hide Archived' : 'Show Archived'}
              </Button>
            )}
          </div>,
          <div key="newWorkspaceButton">
            <Button
              type="primary"
              disabled={!canCreateWorkspace}
              onClick={() => setShowModal(true)}
              css={css`
                margin-top: -10px;
              `}
            >
              New Workspace
              <ArrowRightOutlined />
            </Button>
          </div>,
        ]}
        headerDirection="row"
      >
        <IterationTable
          onChange={handleTableChange}
          filters={iterationTableFilters.get(String(currentProject?.id))}
          showArchived={showDisabledWorkspaces}
          setTableCount={setTableCount}
        />
      </DashboardPanel>
      {showModal && (
        <CreateIterationModal
          close={closeModal}
          workspaceType={workspaceType}
        />
      )}
      <KnowledgeDiscovery
        visible={showKnowledgeGraph}
        setVisible={() => {
          setShowKnowledgeGraph(false);
        }}
      />
    </>
  );
};
