/** @jsxImportSource @emotion/react */
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button, Collapse } from 'antd';
import { ConstraintInputProp } from './design-constraint-view-container.component';
import { TextContainerComponent } from './text-container.component';
import { DesignConstraintCollapse } from './design-constraint-collapse.component';
const { Panel } = Collapse;
export const DesignCountConstraintView = ({
  constraint,
  removeConstraint,
  editConstraint,
  disabled,
}: ConstraintInputProp) => {
  const constraintIngredients =
    constraint.variables?.map(v => {
      return <TextContainerComponent text={`${v}`} />;
    }) ?? [];

  return (
    <DesignConstraintCollapse name={constraint.name}>
      <Panel
        header={
          <TextContainerComponent
            text={constraint.name || 'Category Constraint'}
          />
        }
        key="1"
        extra={
          <div>
            <span
              css={css`
                margin-right: 10px;
              `}
            >
              <Button
                onClick={() => editConstraint(constraint)}
                icon={<EditTwoTone />}
                disabled={disabled}
              />
            </span>
            <span>
              <Button
                onClick={() => removeConstraint(constraint)}
                icon={<DeleteTwoTone />}
                disabled={disabled}
              />
            </span>
          </div>
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          `}
        >
          Pick No Less Than{' '}
          <TextContainerComponent text={`${constraint.lowerBounds}`} />
          {' And '}
          Pick No More Than{' '}
          <TextContainerComponent text={`${constraint.upperBounds}`} /> {` `}
          From the list of ingredients:
          <div>{...constraintIngredients}</div>
        </div>
      </Panel>
    </DesignConstraintCollapse>
  );
};
