/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export const CompositionBarChart = ({
  data,
}: {
  data: {
    key: string;
    name: string;
    ingredientAmount: string | number;
    ingredientCompositionContribution: number;
    compositionAmount: number;
  }[];
}) => {
  const filteredData = data.filter(d => d.compositionAmount !== 0);
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      css={css`
        max-height: 200px;
      `}
    >
      <BarChart
        width={500}
        height={200}
        data={filteredData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={false} />
        <YAxis />
        <Tooltip formatter={label => label + '%'} />
        <Bar
          dataKey="compositionAmount"
          name={'Composition Amount'}
          fill="#006EFF"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
