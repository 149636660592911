/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect } from 'react';
import { Modal } from 'antd';
import {
  GraphContextProvider,
  useGraph,
  useSession,
} from '../../_shared/context';
import { GraphContainer } from './graph-container.component';
import { Hud } from './hud/hud.component';
import { logEvent, TrackableEvent } from '../../_shared/tracking/usage-tracker';
import { LabelControl } from './graph-v1/label-control.component';
import { ProjectFeatureInsightExplorerVersion } from '../../../../__generated__/globalTypes';

export interface KnowledgeDiscoveryProps {
  visible?: boolean;
  modal?: boolean;
  hud?: boolean;
  setVisible?: (value: boolean) => void;
  ingredientId?: string;
}

/**
 * I believe this is only used for the modal, may want to rename
 * Tracked events are configured with the above assumption
 */
export const KnowledgeDiscovery: React.FC<KnowledgeDiscoveryProps> = (
  props: KnowledgeDiscoveryProps
) => {
  const { currentProject } = useSession();

  useEffect(() => {
    logEvent(TrackableEvent.KNOWLEDGE_GRAPH_MODAL_OPENED);
    return () => logEvent(TrackableEvent.KNOWLEDGE_GRAPH_MODAL_CLOSED);
  }, []);

  return (
    <>
      {currentProject?.activeModel?.formulationGraph && (
        <GraphContextProvider ingredientId={props.ingredientId}>
          <KnowledgeDiscoveryCore {...props} />
        </GraphContextProvider>
      )}
    </>
  );
};

export const KnowledgeDiscoveryCore: React.FC<KnowledgeDiscoveryProps> = (
  props: KnowledgeDiscoveryProps
) => {
  const { visible, setVisible, modal = true, hud = true } = props;
  const onClose = setVisible && (() => setVisible(false));
  const { insightExplorerVersion } = useSession();

  const insightExplorerIsV1 =
    insightExplorerVersion == ProjectFeatureInsightExplorerVersion.V1;

  return modal ? (
    <Modal
      className="dark knowledge-discovery-modal"
      css={css`
        height: 80vh;
        width: calc(100% - 40px) !important;
        padding: 0;
        left: 15px;

        .ant-modal-content {
          border-radius: 16px;
          height: 80vh !important;
        }

        &.fullscreen .ant-modal-content {
          height: calc(100vh) !important;
        }

        .ant-modal-body {
          height: 100%;
          padding: 0;

          #cy {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }

        .ant-modal-header,
        .ant-modal-footer,
        .ant-modal-close {
          display: none;
        }

        // modals launched by the KD component have a slightly different appearance
        .ant-modal {
          --modal-background-color: #eaeaec;
          --modal-label-color: #737b81;

          .ant-modal-content {
            background-color: var(--modal-background-color);

            .ant-modal-header {
              background-color: var(--modal-background-color);
            }

            .ant-modal-body {
              background-color: var(--modal-background-color);

              & > label {
                color: var(--modal-label-color);
                font-weight: bold;
                text-transform: uppercase;
              }

              textarea {
                border: none;
                border-radius: 4px;
              }
            }
          }
        }
      `}
      title="Knowledge Discovery"
      open={visible}
      onCancel={onClose}
      footer={[]}
    >
      <GraphContainer />
      {hud && <Hud onClose={onClose} modal={modal} />}
      {insightExplorerIsV1 && <LabelControl />}
    </Modal>
  ) : (
    <div
      css={css`
        background: #000;
        color: #fff;
        height: 100%;
        position: relative;

        .ant-modal-content {
          background-color: #000;
          color: #fff;
        }
      `}
    >
      <GraphContainer />
      {hud && <Hud onClose={onClose} modal={modal} />}
      {insightExplorerIsV1 && <LabelControl />}
    </div>
  );
};
