import {
  gql,
  useQuery,
  useMutation,
  LazyQueryHookOptions,
  useLazyQuery,
} from '@apollo/client';

import * as GetProjectsTypes from './__generated__/getProjects';
import * as GetProjectByIdTypes from './__generated__/projectById';
import * as GetProjectUsersTypes from './__generated__/getProjectUsers';
import * as AddProjectUsersTypes from './__generated__/addProjectUsers';
import * as RemoveProjectUserTypes from './__generated__/removeProjectUser';
import * as AddProjectFeaturesTypes from './__generated__/addProjectFeatures';
import * as RemoveProjectFeatureTypes from './__generated__/removeProjectFeature';
import * as GetProjectFiles from './__generated__/getProjectFiles';
import {
  getExpertKnowledge,
  getExpertKnowledgeVariables,
} from './__generated__/getExpertKnowledge';
import {
  addIngredientToProject,
  addIngredientToProjectVariables,
} from './__generated__/addIngredientToProject';
import { USER_DATA } from './use-user.hook';
import {
  getExpertKnowledgeSummary,
  getExpertKnowledgeSummaryVariables,
  getExpertKnowledgeSummary_project_expertKnowledgeTeamSubmission_expertKnowledgeSummary,
} from './__generated__/getExpertKnowledgeSummary';
import {
  getExpertKnowledgeAdmin,
  getExpertKnowledgeAdminVariables,
} from './__generated__/getExpertKnowledgeAdmin';
import {
  getIngredients,
  getIngredientsVariables,
} from './__generated__/getIngredients';
import {
  getIngredientCategories,
  getIngredientCategoriesVariables,
  getIngredientCategories_project_ingredientCategory,
} from './__generated__/getIngredientCategories';
import {
  getProjectMLAPIConfigs,
  getProjectMLAPIConfigsVariables,
  getProjectMLAPIConfigs_project_mlapiConfigs,
} from './__generated__/getProjectMLAPIConfigs';
import {
  updateProjectMLAPIConfigs,
  updateProjectMLAPIConfigsVariables,
} from './__generated__/updateProjectMLAPIConfigs';
import * as Queries from './use-project.queries';
import { projectByIdQuery } from '../../../../__generated__/globalTypes';

//TODO: This is a stopgap measure to have the new type definition without refactoring
// the entire app
export type BaseProject = Omit<
  NonNullable<projectByIdQuery['project']>,
  '__typename'
>;

//Move to a types file
export type BaseModel = Omit<
  NonNullable<NonNullable<projectByIdQuery['project']>['activeModel']>,
  '__typename'
>;

export type IngredientListItem = Omit<
  GetProjectByIdTypes.projectById_project_ingredientList,
  '__typename'
>;

export type OrdinalOutcome = Omit<
  GetProjectByIdTypes.projectById_project_models_ordinalOutcomes,
  '__typename'
>;

export interface ProductVersionInfo {
  column: number;
  isBenchmark: boolean;
  isOptimization: boolean;
  name: string;
  productId?: string;
  formulation?: { quantities: { [key: string]: string } };
}
export type IngredientCategory = Omit<
  getIngredientCategories_project_ingredientCategory,
  '__typename'
>;

export type ProjectMLAPIConfigs = Omit<
  getProjectMLAPIConfigs_project_mlapiConfigs,
  '__typename'
>;

export type Project = GetProjectsTypes.getProjects_organization_projects;

export const PROJECT_DATA = gql`
  fragment Project on Project {
    id
    name
    organizationId
    brief
    category
    country
    department
    dependentVariable
    description
    division
    testType
    stage
  }
`;

export const useProjects = () =>
  useQuery<GetProjectsTypes.getProjects>(Queries.getProjectsQuery);

const projectUsersByIdQuery = gql`
  query getProjectUsers($projectId: String!) {
    project(id: $projectId) {
      id
      users {
        projectId
        user {
          ...User
        }
      }
    }
  }
  ${USER_DATA}
`;

const projectFiles = gql`
  query getProjectFiles($projectId: String!) {
    project(id: $projectId) {
      files {
        id
        name
      }
    }
  }
`;

export const useGetProjectUsers = (
  options?: LazyQueryHookOptions<
    GetProjectUsersTypes.getProjectUsers,
    GetProjectUsersTypes.getProjectUsersVariables
  >
) =>
  useLazyQuery<
    GetProjectUsersTypes.getProjectUsers,
    GetProjectUsersTypes.getProjectUsersVariables
  >(projectUsersByIdQuery, options);

export const useGetProjectFiles = (
  options?: LazyQueryHookOptions<
    GetProjectFiles.getProjectFiles,
    GetProjectFiles.getProjectFilesVariables
  >
) =>
  useLazyQuery<
    GetProjectFiles.getProjectFiles,
    GetProjectFiles.getProjectFilesVariables
  >(projectFiles, options);

export const useIngredientCategories = (projectId: string) =>
  useQuery<getIngredientCategories, getIngredientCategoriesVariables>(
    gql`
      query getIngredientCategories($projectId: String!) {
        project(id: $projectId) {
          id
          ingredientCategory {
            id
            name
            color
          }
        }
      }
    `,
    {
      variables: {
        projectId,
      },
    }
  );

export const useProjectExpertKnowledge = (projectId: string) =>
  useQuery<getExpertKnowledge, getExpertKnowledgeVariables>(
    gql`
      query getExpertKnowledge($projectId: String!) {
        project(id: $projectId) {
          name
          users {
            user {
              firstName
              lastName
              email
              expertKnowledgeFormulation(orderBy: { createdAt: desc }) {
                status
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        projectId,
      },
    }
  );

export type ExpertKnowledgeSummary = getExpertKnowledgeSummary_project_expertKnowledgeTeamSubmission_expertKnowledgeSummary;

export const useProjectExpertKnowledgeSummary = (projectId: string) =>
  useQuery<getExpertKnowledgeSummary, getExpertKnowledgeSummaryVariables>(
    gql`
      query getExpertKnowledgeSummary($projectId: String!) {
        project(id: $projectId) {
          name
          expertKnowledgeTeamSubmission(
            orderBy: { createdAt: desc }
            take: 1
            where: { resultUploadedDate: { not: null } }
          ) {
            topInsights
            expertsContributedOverride
            piecesOfKnowledgeOverride
            userExpertKnowledge {
              user {
                firstName
                lastName
              }
              knowledges {
                id
                parentName
                childName
                comment
                createdAt
              }
            }
            expertKnowledgeSummary(orderBy: { rank: asc }) {
              parentName
              childName
              changeInRelationship
              rank
              previousRank
              conflict
              needsMoreInfo
            }
          }
        }
      }
    `,
    {
      variables: {
        projectId,
      },
    }
  );

export const useProjectExpertKnowledgeAdmin = (projectId: string) =>
  useQuery<getExpertKnowledgeAdmin, getExpertKnowledgeAdminVariables>(
    gql`
      query getExpertKnowledgeAdmin($projectId: String!) {
        project(id: $projectId) {
          name
          expertKnowledgeTeamSubmission(
            orderBy: { createdAt: desc }
            skip: 0
            take: 1
          ) {
            topInsights
            expertsContributedOverride
            piecesOfKnowledgeOverride
          }
        }
      }
    `,
    {
      variables: {
        projectId,
      },
    }
  );

export const useAddProjectUsers = () =>
  useMutation<
    AddProjectUsersTypes.addProjectUsers,
    AddProjectUsersTypes.addProjectUsersVariables
  >(
    gql`
      mutation addProjectUsers($projectId: String!, $invites: [String!]!) {
        addProjectUsers(projectId: $projectId, invites: $invites) {
          user {
            ...User
          }
        }
      }
      ${USER_DATA}
    `
  );

export const useRemoveProjectUser = () =>
  useMutation<
    RemoveProjectUserTypes.removeProjectUser,
    RemoveProjectUserTypes.removeProjectUserVariables
  >(
    gql`
      mutation removeProjectUser($projectId: String!, $userId: String!) {
        removeProjectUser(projectId: $projectId, userId: $userId) {
          user {
            ...User
          }
        }
      }
      ${USER_DATA}
    `
  );

export const useAddProjectFeatures = () =>
  useMutation<
    AddProjectFeaturesTypes.addProjectFeatures,
    AddProjectFeaturesTypes.addProjectFeaturesVariables
  >(
    gql`
      mutation addProjectFeatures(
        $projectId: String!
        $features: [ProjectFeature!]!
      ) {
        addProjectFeatures(projectId: $projectId, features: $features)
      }
    `
  );

export const useRemoveProjectFeatures = () =>
  useMutation<
    RemoveProjectFeatureTypes.removeProjectFeature,
    RemoveProjectFeatureTypes.removeProjectFeatureVariables
  >(
    gql`
      mutation removeProjectFeatures(
        $projectId: String!
        $features: [ProjectFeature!]!
      ) {
        removeProjectFeatures(projectId: $projectId, features: $features)
      }
    `
  );

export const useAddIngredientToProject = () =>
  useMutation<addIngredientToProject, addIngredientToProjectVariables>(gql`
    mutation addIngredientToProject(
      $projectId: String!
      $ingredient: IngredientListCreateOneInput!
    ) {
      addIngredient(projectId: $projectId, ingredient: $ingredient) {
        id
      }
    }
  `);

export const useSetProjectVariablesFromMLAPI = () =>
  useMutation(
    gql`
      mutation setProjectVariablesFromMLAPI($projectId: String!) {
        setIngredientsFromMlApi(projectId: $projectId) {
          id
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

export const useGetIngredients = (projectId: string, showInactive: boolean) =>
  useQuery<getIngredients, getIngredientsVariables>(
    gql`
      query getIngredients($projectId: String!, $showInactive: Boolean!) {
        project(id: $projectId) {
          id
          ingredientList(
            showInactive: $showInactive
            orderBy: { ingredientId: asc }
          ) {
            isRequired
            isActive
            isTestCondition
            upperLimit
            lowerLimit
            values
            ingredient {
              id
              name
            }
            price
            upperLimit
            lowerLimit
            values
            recommendation
            category {
              id
              color
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        projectId,
        showInactive,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

export const useGetProjectMLAPIConfigs = (projectId: string) =>
  useQuery<getProjectMLAPIConfigs, getProjectMLAPIConfigsVariables>(
    gql`
      query getProjectMLAPIConfigs($projectId: String!) {
        project(id: $projectId) {
          id
          mlapiConfigs {
            name
            value
          }
        }
      }
    `,
    {
      variables: {
        projectId,
      },
      fetchPolicy: 'network-only',
    }
  );

export const useUpdateProjectMLAPIConfigs = () =>
  useMutation<
    updateProjectMLAPIConfigs,
    updateProjectMLAPIConfigsVariables
  >(gql`
    mutation updateProjectMLAPIConfigs(
      $projectId: String!
      $configs: [ProjectMLAPIConfigUpsertInput!]!
    ) {
      updateProjectMLAPIConfigs(projectId: $projectId, configs: $configs)
    }
  `);
