/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpertKnowledgeStatus } from '@prisma/client';
import { Button } from 'antd';

import { useSession, useGraph } from '../../_shared/context';
import { ExpertKnowledgeTable } from './expert-knowledge-table.component';
import { useExpertKnowledge } from './expert-knowledge-context.component';
import { logEvent, TrackableEvent } from '../../_shared/tracking/usage-tracker';
import { ExpertKnowledgeRelationshipInputs } from './expert-knowledge-relationship-inputs';
import { usesubmitExpertKnowledgeFormulationMutation } from '../../../../__generated__/globalTypes';

const ContainerStyle = css`
  height: 100%;
  overflow-y: auto;
  // just needs to be higher than the label control that floats over the graph
  z-index: 1000;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #434343;

  .ant-btn-primary:disabled {
    background: #017aff;
    border: 1px solid #017aff;
    color: ##434343;
  }
`;

const HeaderStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 24px;
  font-weight: 500;
  border-bottom: 1px solid #434343;
  gap: 16px;
`;

const HeaderMainTextStyle = css`
  line-height: 32px;
  font-size: 24px;
`;

const HeaderSubTextStyle = css`
  line-height: 24px;
  font-size: 16px;
`;

const MainContentContainerStyle = css`
  background-color: #212121;
  padding: 24px;
  flex-grow: 1;
`;

const FooterStyle = css`
  ${HeaderStyle};
  border-top: 1px solid #434343;
  border-bottom: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .ant-btn {
    width: 156px;
    height: 36px;
  }
`;

const FooterTextStyle = css`
  color: #fff;
  font-size: 12px;
`;

export const ExpertKnowledgeSidebar = () => {
  const { currentProject } = useSession();
  const {
    expertKnowledgeFormulation,
    showEditRelationshipForm,
    isExpertKnowledgeSubmitted,
  } = useExpertKnowledge();

  const [
    submitExpertKnowledgeFormulation,
  ] = usesubmitExpertKnowledgeFormulationMutation();

  const { graph } = useGraph();

  const navigate = useNavigate();

  const submitExpertKnowledge = async () => {
    if (expertKnowledgeFormulation && currentProject) {
      await submitExpertKnowledgeFormulation({
        variables: {
          expertKnowledgeFormulationId: expertKnowledgeFormulation.id,
        },
      });
      navigate(`/project/${currentProject.id}`);
    }
  };

  useEffect(() => {
    logEvent(TrackableEvent.EXPERT_KNOWLEDGE_INPUT_VIEW_OPENED);
    return () => logEvent(TrackableEvent.EXPERT_KNOWLEDGE_INPUT_VIEW_CLOSED);
  }, []);

  if (!currentProject || !expertKnowledgeFormulation || !graph) {
    return <div>An error occurred</div>;
  }

  return (
    <div css={ContainerStyle}>
      <div css={HeaderStyle}>
        <div css={HeaderMainTextStyle}>Expert Integration</div>
        <div css={HeaderSubTextStyle}>
          Add your expert opinion between two ingredients or suboutcomes by
          choosing from the list.
        </div>
      </div>
      {!showEditRelationshipForm && (
        <div css={MainContentContainerStyle}>
          <ExpertKnowledgeTable />
        </div>
      )}
      {showEditRelationshipForm && (
        <div css={MainContentContainerStyle}>
          <ExpertKnowledgeRelationshipInputs />
        </div>
      )}
      {!showEditRelationshipForm && (
        <div css={FooterStyle}>
          <div css={FooterTextStyle}>
            {!isExpertKnowledgeSubmitted && (
              <span>Are you done with your Expert Integration?</span>
            )}
          </div>
          <div
            css={css`
              margin-right: 65px;
            `}
          >
            <Button type="primary" onClick={() => submitExpertKnowledge()}>
              {expertKnowledgeFormulation.status ===
              ExpertKnowledgeStatus.SUBMITTED
                ? 'Marked as Ready'
                : 'Mark as Ready'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
