/** @jsxImportSource @emotion/react */
import { ReactElement, useEffect, useState } from 'react';

import Icon, {
  ArrowRightOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { css, jsx } from '@emotion/react';

import { Alert, Button, Spin, Tooltip, Badge, Modal } from 'antd';
import { SimulationResultTable } from '../../predicted-outcomes/simulation-result-table';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { ContentContainer } from '../content-container/content-container.component';
import { useIngredients } from '../../../../../_shared/hooks';
import {
  AddIcon,
  AlertIcon,
  centerVerticallyFlexStyle,
  Colors,
  rightHorizontallyFlexStyle,
} from '../../../../../_shared/style';
import {
  ProjectFeature,
  SimulationFormulationInputType,
  VariableType,
  useupdateOneOptimizationMutation,
} from '../../../../../../../__generated__/globalTypes';
import { createFormulationDataFromProductVersions } from '../../../../../_shared/utils/util';
import { CertaintyVsOutcome } from '../adaptive-doe';
import { FormulationSider } from '../formulation-sider/formulation-sider.component';
import { useSession } from '../../../../../_shared/context';
import { useOptimization } from '../../../../../_shared/context/optimization-context';
import { OptimizationStates } from './context';
import {
  Outcome,
  OutcomeInfo,
  SimulationProductVersionResultType,
} from '../../context';
import { useNavigate } from 'react-router-dom';
import { OptimizationRunningMessage } from './optimization-running-message.component';

export const CalculateOptimizationsV2 = ({
  isSimulationRunning,
  saveWorkspaceByRunningSimulation,
}: {
  isSimulationRunning: boolean;
  saveWorkspaceByRunningSimulation: (
    simulationPackage: Array<SimulationFormulationInputType>
  ) => void;
}) => {
  const {
    simulationProductVersions,
    setSimulationProductVersions,
    outcomes: workspaceOutcomes,
    latestOptimization,
    setLatestOptimization,
    latestFinishedOptimization,
    setLatestFinishedOptimization,
    iteration,
  } = useWorkspace();
  const { currentProject, user } = useSession();
  const { ingredientById } = useIngredients();
  const {
    optimizationState,
    constraints,
    optimizedProducts,
    isSimulationRunning: isSimulationRunningOnOptimizationContext,
    resetOptimizationState,
    errorDescription,
  } = useOptimization();
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [benchmarkProduct] = useState(
    simulationProductVersions?.find(s => s.productVersion.isBenchmark)
  );
  const [removedProducts, setRemovedProducts] = useState<Array<string>>([]);
  const isGoToworkspaceDisabled = user?.id !== iteration?.createdById;

  useEffect(() => {
    // All products are set to be removed by default
    setRemovedProducts(optimizedProducts.map(p => p.productVersion.name));
  }, [optimizedProducts]);
  const [updateOptimizationAsFinished] = useupdateOneOptimizationMutation();

  const removeProductFromWorkspace = (
    productToRemove: SimulationProductVersionResultType
  ) => {
    if (!removedProducts.includes(productToRemove.productVersion.name))
      setRemovedProducts([
        ...removedProducts,
        productToRemove.productVersion.name,
      ]);
  };

  const productsToKeep = optimizedProducts.filter(
    v => !removedProducts.includes(v.productVersion.name)
  );

  const addProductToWorkspace = (
    productToRemove: SimulationProductVersionResultType
  ) => {
    const productIndex = removedProducts.indexOf(
      productToRemove.productVersion.name
    );
    if (productIndex !== -1) {
      const newRemovedProducts = [...removedProducts];
      newRemovedProducts.splice(productIndex, 1);
      setRemovedProducts(newRemovedProducts);
    }
  };

  const checkForProductsToKeep = () => {
    if (productsToKeep.length === 0) {
      setShowWarningModal(true);
      return;
    } else {
      onGoToWorkspace();
    }
  };

  const navigate = useNavigate();

  const onGoToWorkspace = async () => {
    // Remove any placeholder benchmarks before processing
    let combinedProducts = [
      ...simulationProductVersions,
      ...productsToKeep,
    ].filter(
      p =>
        p.productVersion.productId !== undefined &&
        p.productVersion.formulation?.quantities &&
        Object.keys(p.productVersion.formulation.quantities).length > 0
    );

    if (productsToKeep.length > 0 && combinedProducts.length > 0) {
      if (combinedProducts.every(p => p.productVersion.isBenchmark === false)) {
        combinedProducts[0].productVersion.isBenchmark = true;
      }
      const simulationPackage = createFormulationDataFromProductVersions(
        combinedProducts
      );
      setSimulationProductVersions(combinedProducts);
      saveWorkspaceByRunningSimulation(simulationPackage);
    }

    if (latestOptimization?.id) {
      await updateOptimizationAsFinished({
        variables: {
          optimizationId: latestOptimization.id,
          data: {
            isFinished: true,
          },
        },
      });
      const finishedOpt = { ...latestOptimization, isFinished: true };
      setLatestFinishedOptimization(finishedOpt);
    }
    resetOptimizationState();
    navigate(`/project/${currentProject?.id}/iteration/${iteration?.id}`);
  };

  let View: () => JSX.Element = PlaceholderMessage;

  const numOfOptimizations = optimizedProducts?.length ?? 0;
  if (
    optimizationState === OptimizationStates.ERROR ||
    optimizationState === OptimizationStates.NO_RESULTS
  ) {
    View = ErrorMessage(
      optimizationState === OptimizationStates.NO_RESULTS
        ? 'NO_RESULTS'
        : 'ERROR',
      errorDescription
    );
  } else if (
    optimizationState === OptimizationStates.RUNNING ||
    isSimulationRunningOnOptimizationContext
  ) {
    View = () => (
      <div css={{ paddingTop: '15px', paddingLeft: '65px' }}>
        <OptimizationRunningMessage
          spinnerSize={'large'}
          showEmailNotification
        />
      </div>
    );
  } else if (
    optimizedProducts &&
    numOfOptimizations > 0 &&
    latestOptimization
  ) {
    /**
     * TODO: Make an actual solution for this
     * quick and dirty implementation
     * of mapping newer concepts to legacy datatypes
     *
     * This reformatting of similar data types all the time is silly
     */

    const outcomesData: Outcome[] = [...(workspaceOutcomes ?? [])];
    if (!outcomesData.length) {
      //cant piggy back off existing sim results
      optimizedProducts[0]?.outcomes?.forEach(p => {
        outcomesData.push({
          targetVariable: p.outcomeName,
          outcomeType: p.outcomeType as VariableType,
          products: new Map<string, OutcomeInfo>(),
        });
      });
    }

    outcomesData.forEach(od => {
      optimizedProducts.forEach(p => {
        p.outcomes?.forEach(o => {
          if (o.outcomeName === od.targetVariable) {
            const dist: Map<string, number> = new Map();
            [...(o.distribution ?? [])]
              .sort((c, b) => b.probability - c.probability)
              .forEach(d => {
                dist.set(d.state, d.probability);
              });
            od.products.set(p.productVersion.name, {
              value: o.value,
              accuracy: o.applicability,
              distribution: dist,
              reliability: o.reliability,
            });
          }
        });
      });
    });

    const adaptiveDoeFeatureEnabled = currentProject!.features.some(
      f => f.feature === ProjectFeature.ADAPTIVE_DOE
    );

    const optimizationBoxes: Array<ReactElement> = [];
    const optimizedProductLabelMap = new Map<string, string>();

    optimizedProducts.forEach((optimizedProduct, index) => {
      optimizedProductLabelMap.set(
        optimizedProduct.productVersion.name,
        optimizedProduct.productVersion.name.split(' ')[1] // Get the number only for graph
      );
      const productIsRemoved = removedProducts.includes(
        optimizedProduct.productVersion.name
      );
      // 1. Can remove all new optimization in an existing workspace
      // 2. Must have at least 1 optimization added to new/blank workspace
      const disableRemoveProduct =
        simulationProductVersions.length - optimizedProducts.length === 0 &&
        removedProducts.length === optimizedProducts.length - 1;

      optimizationBoxes.push(
        <div
          key={optimizedProduct.productVersion.name}
          css={css`
            display: flex;
          `}
        >
          <ContentContainer
            title={
              <div
                css={css`
                  font-weight: normal;
                  display: flex;
                `}
              >
                {optimizedProduct.productVersion.name}
              </div>
            }
          >
            <div
              css={css`
                padding: 20px 0;
              `}
            >
              <SimulationResultTable
                outcomes={outcomesData}
                product={optimizedProduct}
                benchmark={benchmarkProduct}
                hideExplainImpactLink
              />
              <div
                css={[
                  rightHorizontallyFlexStyle,
                  css`
                    text-align: right;
                    margin-top: 25px;
                  `,
                ]}
              >
                {productIsRemoved ? (
                  <Button
                    css={centerVerticallyFlexStyle}
                    type="default"
                    onMouseUp={() => addProductToWorkspace(optimizedProduct)}
                    disabled={isGoToworkspaceDisabled}
                  >
                    <Icon component={AddIcon} /> Add to workspace
                  </Button>
                ) : (
                  <Tooltip
                    title="Must have at least 1 optimization in workspace"
                    trigger={disableRemoveProduct ? 'hover' : ''}
                  >
                    <Button
                      css={centerVerticallyFlexStyle}
                      type="default"
                      onMouseUp={() =>
                        removeProductFromWorkspace(optimizedProduct)
                      }
                      disabled={disableRemoveProduct}
                    >
                      Remove from workspace x
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
          </ContentContainer>
          {optimizedProduct.productVersion.formulation?.quantities && (
            <FormulationSider
              formulation={
                optimizedProduct.productVersion.formulation?.quantities
              }
              benchmarkFormulation={
                benchmarkProduct?.productVersion.formulation?.quantities
              }
            />
          )}
        </div>
      );
    });

    View = () => (
      <div
        css={css`
          min-width: 50%;
          margin: 19px 0px;
        `}
      >
        {adaptiveDoeFeatureEnabled && (
          <div
            css={css`
              justify-content: center;
              display: flex;
            `}
          >
            <CertaintyVsOutcome
              currentProject={currentProject}
              outcomes={outcomesData}
              optimizedProducts={optimizedProducts}
              optimizedProductLabelMap={optimizedProductLabelMap}
            />
          </div>
        )}
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #fcfcfc;
            margin: 0 -25px;
            padding: 25px;
            border: 1px solid #cccccc20;
            border-left: none;
          `}
        >
          <Button
            type="primary"
            onClick={checkForProductsToKeep}
            loading={isSimulationRunning}
            disabled={isGoToworkspaceDisabled}
          >
            <Badge
              style={{
                marginRight: 5,
                backgroundColor: '#fff',
                color: '#006EFF',
              }}
              count={productsToKeep.length}
            />
            Go to workspace with selected <ArrowRightOutlined />
          </Button>
        </div>
        {optimizationBoxes}
        <Modal
          title="Did you select the optimizations you want to keep?"
          open={showWarningModal}
          closable
          okText={
            <p>
              Continue to Workspace <ArrowRightOutlined />
            </p>
          }
          onOk={onGoToWorkspace}
          onCancel={() => setShowWarningModal(false)}
        >
          <p>
            Unselected optimizations will not be available once you leave this
            page.
          </p>
        </Modal>
      </div>
    );
  }

  return (
    <div
      className="optModalColumn"
      css={css`
        width: 66%;
        padding-right: 24px;
      `}
    >
      <span className="optimizationStepNumber">2</span>
      <span className="headerText">Recommended formulations</span>
      <View />
    </div>
  );
};
/**
 * timebased, will change as the duration lengthens
 *
 * @param createdAt timestamp the optimization job started
 * @returns
 */

const ErrorMessage = (
  state: 'ERROR' | 'NO_RESULTS',
  customDescription?: string
) => {
  let message = '';
  let description = '';

  if (state === 'ERROR') {
    message = 'Error';
    description =
      customDescription ??
      'It looks like there was a technical issue. Please contact us for assistance.';
  } else {
    message =
      'No formulations found meeting this goal. Please relax constraints.';
  }

  return () => (
    <div
      css={css`
        margin-top: 25px;
      `}
    >
      <Alert
        message={message}
        description={description}
        type="error"
        showIcon
      />
    </div>
  );
};

const PlaceholderMessage = () => (
  <div
    css={css`
      width: 95%;
      margin: 19px 0px;
    `}
  >
    <p>Your optimized formulations would appear here.</p>
    <p>
      Update outcome targets, constraints, and limitations on the left to create
      optimiztations
    </p>
  </div>
);
