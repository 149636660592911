import cytoscape, {
  Core,
  SearchDijkstraResult,
  ElementDefinition,
} from 'cytoscape';
import dagre from 'cytoscape-dagre';
import { v4 as uuid } from 'uuid';
import { Colors } from '../../../../iso/colors';
import {
  ExpertKnowledge,
  GraphEdge,
  GraphNode,
  Outcome,
} from '__generated__/globalTypes';
import {
  EdgeData,
  NodeDef,
  NodeData,
  EdgeRank,
  SharedDefinitionData,
  NodeDefinitionData,
  CytoscapeNodeSingular,
  CytoscapeEdgeSingular,
  InteractionState,
} from './graph-v1-data.types';
import { translateModelToRendered } from './cytoscape-utils';

// cytoscape-node-html-label has an insufficient d.ts file
// eslint-disable-next-line
// require('cytoscape-node-html-label')(cytoscape);
cytoscape.use(dagre);

export interface GraphSettings {
  layoutName: 'circle' | 'concentric' | 'cose' | 'dagre';
  labels: boolean;
}

export interface GraphProps {
  nodes: NodeDef[];
  edges: EdgeData[];
  ingredientId?: string;
  expertKnowledge?: ExpertKnowledge[];
  outcomes: Outcome[];
  setLabeledNode: (node?: CytoscapeNodeSingular) => void;
  setSelectedNode: (node?: CytoscapeNodeSingular) => void;
  setCurrentInteractionState: (node?: InteractionState) => void;
}

export class GraphV1 {
  props: GraphProps;

  cy!: Core;

  labelControl!: HTMLElement;

  edges: EdgeDef[];

  nodes = new Map<string, NodeDef>();

  get graphContainerId() {
    return this.elementId;
  }

  get ingredients() {
    return Array.from(this.nodes.values()).map(n => n.data);
  }

  dijkstra?: SearchDijkstraResult;

  filterWeight: number = 0;

  /**
   * A map of all the highlighted nodes that have an entropy value
   * "absoluteWeight" above the "filterWeight"
   */
  topDriversMap = new Map<string, CytoscapeNodeSingular>();

  edgeRanks: EdgeRank[] = [];

  interactionStateMap = new Map<string, InteractionState>();

  labeledNode?: CytoscapeNodeSingular & { data(): NodeData };

  dependentVariableData!: NodeData;

  loadedAt: number;

  categoryColors = new Map<string, string>();

  /**
   * The html element ID of the component to draw the graph inside.
   */
  elementId: string;

  constructor(props: GraphProps) {
    this.loadedAt = Date.now();

    this.props = props;
    this.edges = props.edges;
    this.elementId = uuid();

    this.setEdgeRank();

    const nodes = (this.nodes = new Map<string, NodeDef>());

    const { categoryColors } = this;

    this.props.nodes.forEach(n => {
      const { data } = n;

      nodes.set(data.label, n);

      if (data.isDependent) {
        this.dependentVariableData = data;
      }

      const { categoryName, color } = data;
      if (categoryName && color) {
        categoryColors.set(categoryName, color);
      }
    });

    props.expertKnowledge?.forEach(en => this.addExpertKnowledge(en));
  }

  setEdgeRank() {
    const edgeArr = Array.from(this.edges.values());
    const totalWeight = edgeArr.reduce((w, edge) => w + edge.data.weight, 0);

    this.edgeRanks = edgeArr
      .sort((a, b) => b.data.weight - a.data.weight)
      .map((edge, index) => {
        const { weight, source, target } = edge.data;
        const percentage = (weight / totalWeight) * 100;
        return {
          rank: index + 1,
          sourceEdge: source,
          targetEdge: target,
          weight: percentage.toFixed(2),
        };
      });
  }

  addNode(node: {
    data: Omit<SharedDefinitionData, 'color'> & {
      color?: string;
    } & NodeDefinitionData;
  }): NodeDef {
    const { data } = node;
    if (!data.color)
      data.color =
        (data.categoryName && this.categoryColors.get(data.categoryName)) ??
        Colors.WHITE;
    this.nodes.set(node.data.name, node as NodeDef);
    return node as NodeDef;
  }

  /**
   * @returns if a node is disconnected when its last edge is removed, the node is
   *          removed from the graph and returned here.
   */
  removeEdge(childName: string, parentName: string): NodeDef | undefined {
    this.edges = this.edges.filter(
      e => e.data.source !== parentName || e.data.target !== childName
    );

    if (!this.edges.find(e => e.data.target === childName)) {
      const node = this.nodes.get(childName);
      this.nodes.delete(childName);
      return node;
    }

    return undefined;
  }

  addEdge(edge: GraphEdge) {
    this.edges.push(edge);
  }

  addExpertKnowledge(en: {
    parentName: string;
    childName: string;
    category: string | null;
  }): NodeDef {
    const { childName, parentName } = en;
    let childNode = this.nodes.get(childName);

    if (!childNode) {
      const categoryName = en.category ?? undefined;

      // TODO:  can we infer these node and edge weights from the expert knowledge?
      childNode = this.addNode({
        data: {
          id: childName,
          name: childName,
          label: childName,
          categoryName,
          weight: 27.5,
          absoluteWeight: 1,
          size: 27.5,
          isExpertKnowledge: true,
        },
      });

      this.addEdge({
        data: {
          weight: 3,
          size: 3,
          color: Colors.LUST,
          source: parentName,
          target: childName,
        },
        classes: 'bezier',
      });
    }

    return childNode;
  }

  settings: GraphSettings = {
    layoutName: 'dagre',
    labels: true,
  };

  set(settings: Partial<GraphSettings>) {
    Object.assign(this.settings, settings);
    this.draw();
  }

  /**
   * If an ingredientId is specified to the knowledge discovery graph we want to by default hover
   * that node.  However, this hover can be lost immediately if the mouse happens to move slightly off
   * of the node due to clicking on the button that brought up the knowledge discovery graph in the first
   * place.  Therefore, do not hover/unhover nodes for the first second or so.
   */
  ignoreEarlyMouseHovering() {
    return this.props.ingredientId && this.loadedAt > Date.now() - 1250;
  }

  draw() {
    const { layoutName, labels } = this.settings;
    this.labelControl = document.getElementById('labelControl')!;
    const highlightNode: cytoscape.Css.Node = {
      'border-width': 3,
      'border-color': '#FFFFFF',
      'border-opacity': 1,
      'text-margin-y': -5,
      color: '#000',
      'font-weight': 'bold',
      'text-background-color': '#FFF',
      'text-background-opacity': 1,
      'text-background-padding': '5px',
      'text-background-shape': 'roundrectangle',
    };
    const opts: cytoscape.CytoscapeOptions & {
      layout: { transform?: any };
      minDist?: number;
      padding?: number;
      animate?: boolean;
    } = {
      container: document.getElementById(this.elementId),
      zoom: 1.2,
      elements: [...this.nodes.values(), ...this.edges],

      boxSelectionEnabled: false,
      autounselectify: true,
      minDist: 25,
      // maxExpandIterations: 9,
      padding: 10,
      animate: false,

      layout: {
        name: layoutName,
        fit: false,

        ...(layoutName === 'concentric' && {
          concentric: (
            n: ElementDefinition & {
              data: () => { isDependent?: boolean; size: number };
            }
          ) => {
            return n.data().isDependent ? 100 : Math.floor(n.data().size / 4);
          },
          levelWidth: (/*nodes*/) => 4,
          minNodeSpacing: 10,
        }),

        /*
      transform(node: any, position: Position) {
        if (node.data().name.toLowerCase() === 'stain removal index') {
          return { x: 500, y: 500 };
        }
        if (node.data().name === 'Polyethyleneimine ethoxylate') {
          position.y += 90;
        }
        return position;
      },
      */
        // minDist: 100,
        // nodeSeparation: 100,
        // idealEdgeLength: 50,
      },

      style: [
        {
          selector: 'node[!isDependent]',
          style: {
            height: 'data(size)',
            width: 'data(size)',
            'background-color': 'data(color)',
            color: '#FFF',
          },
        },
        {
          //selector: 'node.no-comments',
          selector: 'node',
          style: {
            color: '#fff',
            label: 'data(name)',
            'font-family': 'Inter',
            'font-size': labels ? '10px' : 0,
            'overlay-opacity': 0,
            'text-background-padding': '10px',
          },
        },
        {
          selector: 'node[isDependent]',
          style: {
            height: 30,
            width: 30,

            'background-opacity': 0,
            'background-fit': 'none',
            'background-width': '150px',
            'background-height': '150px',
            'background-image': '/images/center-target.png',
            'border-width': '10px',
            'border-color': '#EA291F',
            'border-opacity': 0.2,
            'font-family': 'Inter',
            'font-size': labels ? '14px' : 0,
            'font-weight': 'bold',
          },
        },
        // {
        //   selector: 'node.labeled-node',
        //   style: highlightNode,
        // },
        {
          selector: 'node.search-highlight',
          style: highlightNode,
        },
        {
          selector: 'node.not-top-driver',
          style: {
            'background-blacken': 0.8,
            opacity: 0.3,
          },
        },
        {
          selector: 'node[label]',
          style: {
            'z-index': Number.MAX_VALUE,
            'text-wrap': 'ellipsis',
            'text-max-width': '120px',
          },
        },
        {
          selector: 'node.full-text',
          style: {
            'z-index': Number.MAX_VALUE,
            'text-wrap': 'none',
            'text-max-width': '1000px',
          },
        },
        {
          selector: 'edge',
          style: {
            'line-color': 'data(color)',
            'curve-style': 'bezier',
            width: 'data(size)',
            'target-arrow-color': '#fff',
            'target-arrow-shape': 'triangle',
            'arrow-scale': 1.5,
            opacity: 0.8,
          },
        },
        {
          selector: 'edge.not-hover',
          style: {
            'line-color': 'data(color)',
          },
        },
        {
          selector: 'edge.not-top-driver',
          style: {
            opacity: 0.1,
          },
        },
        // {
        //   selector: 'edge.strongest-relationship',
        //   style: {
        //     'line-color': '#FFF',
        //     'curve-style': 'haystack',
        //     'line-style': 'solid',
        //     color: '#FFF',
        //     width: 3,
        //     opacity: 1,
        //   },
        // },
      ],

      wheelSensitivity: 0.05,
    };

    const cy = (this.cy = cytoscape(opts));
    cy.center();
    cy.edges().addClass('not-hover');

    //TODO: Set back to a max weight
    //OR calculate what first ring would have

    cy.on('mouseover', 'node', ev => {
      if (!this.ignoreEarlyMouseHovering()) this.hoverNode(ev.target);
    });

    cy.on('mouseout', 'node', (/*e*/) => {
      if (!this.ignoreEarlyMouseHovering()) this.unhoverNode();
    });

    cy.on('tap', 'node', ev => {
      const target = ev.target as CytoscapeNodeSingular;
      const selected = target.hasClass('selected');
      cy.nodes().removeClass('selected');

      if (selected) {
        this.props.setSelectedNode(undefined);
        this.unHighlightInteractionState('selected', true);
      } else {
        this.props.setSelectedNode(target);
        target.addClass('selected');
        this.selectHoveredNode();
      }
    });
    cy.on('tapdrag', () => this.updateLabelControl());

    cy.on('zoom', () => this.updateLabelControl());
    cy.on('pan', () => this.updateLabelControl());

    // for debugging
    // eslint-disable-next-line
    (window as any).cy = this.cy;

    const { ingredientId } = this.props;
    if (ingredientId) {
      const filteredNodes = cy
        .nodes()
        .filter((n: CytoscapeNodeSingular) => n.data().id === ingredientId);
      if (filteredNodes.length > 0) {
        this.unhoverNode();
        this.hoverNode(filteredNodes);
      }
    }
  }

  search(text: string) {
    const { cy } = this;

    cy.nodes().removeClass('search-highlight');

    if (text) {
      this.unHighlightInteractionState('selected');
      const re = new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
      cy.nodes()
        .filter((n: CytoscapeNodeSingular) => re.test(n.data().name))
        .addClass('search-highlight');
    } else {
      this.highlightInteractionState('selected');
    }
  }

  highlightInteractionState(
    stateName: string,
    interactionState?: InteractionState
  ) {
    if (interactionState) {
      this.interactionStateMap.set(stateName, interactionState);
    } else {
      interactionState = this.interactionStateMap.get(stateName);
    }
    if (interactionState) {
      const { strongestEdge } = interactionState;
      if (strongestEdge) {
        strongestEdge
          .source()
          .addClass('labeled-node')
          .addClass('full-text')
          .removeClass('not-top-driver');
        strongestEdge
          .target()
          .addClass('labeled-node')
          .addClass('full-text')
          .removeClass('not-top-driver');
        strongestEdge.addClass('strongest-relationship');
      }
      this.props.setCurrentInteractionState(interactionState);
    }
  }

  unHighlightInteractionState(stateName: string, deleteItem?: boolean) {
    const interactionState = this.interactionStateMap.get(stateName);
    if (interactionState) {
      const { strongestEdge } = interactionState;
      if (strongestEdge) {
        strongestEdge.source().removeClass('labeled-node');
        strongestEdge.target().removeClass('labeled-node');
        strongestEdge.source().removeClass('full-text');
        strongestEdge.target().removeClass('full-text');
        strongestEdge.removeClass('strongest-relationship');
      }
      this.props.setCurrentInteractionState(undefined);
      if (deleteItem === true) this.interactionStateMap.delete(stateName);
    }
  }

  hoverNode(node: CytoscapeNodeSingular) {
    const { cy } = this;

    const { id } = node.data();
    //TODO: Hiding other nodes on hover now
    //Rename 'top driver' css
    cy.elements().addClass('not-top-driver');
    cy.$('[isDependent]').removeClass('not-top-driver');
    node.removeClass('not-top-driver');
    if (id) {
      this.labeledNode = node;
      node.addClass('labeled-node');
      this.props.setLabeledNode(node);
      this.updateLabelControl();

      const strongestEdgeRank = this.edgeRanks.find(
        x => x.sourceEdge === id || x.targetEdge === id
      );

      let strongestEdge;
      cy.edges().each((e: CytoscapeEdgeSingular) => {
        const source = e.source();
        const target = e.target();
        const sourceId = source.data().id;
        const targetId = target.data().id;
        if (sourceId === id || targetId === id) {
          e.removeClass('not-top-driver');
          if (
            (strongestEdgeRank?.sourceEdge === sourceId &&
              strongestEdgeRank?.targetEdge === targetId) ||
            (strongestEdgeRank?.sourceEdge === targetId &&
              strongestEdgeRank?.targetEdge === sourceId)
          ) {
            strongestEdge = e;
            e.addClass('strongest-relationship');
            source.addClass('labeled-node');
            target.addClass('labeled-node');
          }
          source.removeClass('not-top-driver');
          target.removeClass('not-top-driver');
        }
      });

      this.unHighlightInteractionState('selected');
      this.highlightInteractionState('hover', {
        selectedNode: node,
        strongestEdge,
        strongestEdgeRank,
      });
    }
  }

  unhoverNode() {
    this.filterByNodeWeight(this.filterWeight);

    const { labeledNode } = this;

    if (labeledNode) {
      labeledNode.removeClass('labeled-node');
      this.labeledNode = undefined;
      this.props.setLabeledNode(undefined);
      this.updateLabelControl();
    }

    this.unHighlightInteractionState('hover', true);
    this.highlightInteractionState('selected');
  }

  selectHoveredNode() {
    const hoverState = this.interactionStateMap.get('hover');
    if (hoverState) {
      this.unHighlightInteractionState('selected', true);
      this.highlightInteractionState('selected', { ...hoverState });
    }
  }

  hoverNodeById(nodeId: string) {
    this.hoverNode(this.cy.getElementById(nodeId));
  }

  /**
   * This is an easy helper function mainly used to call from expert-knowledge-table.component.ts
   *
   * @param node The node to select from Expoert Knowledge table
   */
  hoverNodeFromExpertKnowledgeTable(nodeId: string) {
    this.unHighlightInteractionState('selected', true);
    this.unhoverNode();
    this.hoverNode(this.cy.getElementById(nodeId));
    this.selectHoveredNode();
  }

  hoverStrongestEdge(edgeRank: number) {
    const { cy } = this;

    cy.elements().addClass('not-top-driver');

    const strongestEdgeRank = this.edgeRanks.find(x => x.rank === edgeRank);
    const strongestEdge = cy
      .edges()
      .toArray()
      .find((e: CytoscapeEdgeSingular) => {
        const sourceId = e.source().data().id;
        const targetId = e.target().data().id;
        return (
          sourceId === strongestEdgeRank?.sourceEdge &&
          targetId === strongestEdgeRank?.targetEdge
        );
      });
    this.unHighlightInteractionState('selected');
    this.highlightInteractionState('hover', {
      strongestEdge,
      strongestEdgeRank,
    });
  }

  updateLabelControl() {
    const { cy, labeledNode, labelControl } = this;

    if (labeledNode) {
      let boundingBox = labeledNode.boundingBox({
        includeEdges: false,
        includeLabels: true,
        includeNodes: false,
      });

      if (!boundingBox.w) {
        // no width means this is a html label that has a note count on it
        const data = labeledNode.data();
        const label = data.id;
        const width =
          label.length * 3.5 + 10 /* note icon */ + 10; /* note count */

        boundingBox = labeledNode.boundingBox({});
        boundingBox.x1 = (boundingBox.x1 + boundingBox.x2 - width) / 2;
        boundingBox.x2 = boundingBox.x1 + width;
        boundingBox.y1 -= 14;
        boundingBox.y2 = boundingBox.y1 + 15;
      }

      const topLeft = translateModelToRendered(cy, {
        x: boundingBox.x1,
        y: boundingBox.y1,
      });

      const bottomRight = translateModelToRendered(cy, {
        x: boundingBox.x2,
        y: boundingBox.y2,
      });

      const width = bottomRight.x - topLeft.x;
      const height = bottomRight.y - topLeft.y;
      const zoom = cy.zoom();

      labelControl.style.left = `${topLeft.x - 6 * zoom}px`;
      labelControl.style.top = `${topLeft.y - 5 * zoom ** 0.8}px`;
      labelControl.style.width = `${width + 26 * zoom}px`;
      labelControl.style.height = `${height + 6 * zoom}px`;
    }
  }

  filterByNodeWeight(absoluteWeight: number) {
    const { topDriversMap, cy } = this;

    //Does this actually matter?
    this.filterWeight = absoluteWeight;

    // DEPTH Selector, keep for now
    // if (dijkstra) {
    //   cy.elements().each(e => {
    //     let depth = dijkstra.distanceTo(e);
    //     if (depth === undefined) {
    //       depth = Math.max(
    //         dijkstra.distanceTo(e.source()),
    //         dijkstra.distanceTo(e.target())
    //       );
    //     }

    //     if (depth > topDrivers) {
    //       e.addClass('not-top-driver');
    //     } else {
    //       e.removeClass('not-top-driver');
    //     }
    //   });
    // }

    topDriversMap.clear();
    cy.nodes().each((n: CytoscapeNodeSingular) => {
      const data = n.data();

      if ((data?.absoluteWeight ?? 0) < absoluteWeight && !data.isDependent) {
        n.addClass('not-top-driver');
      } else {
        n.removeClass('not-top-driver');
        topDriversMap.set(data.id, n);
      }
    });

    cy.edges().each((e: CytoscapeEdgeSingular) => {
      if (
        !topDriversMap.has(e.source().data().id) ||
        !topDriversMap.has(e.target().data().id)
      ) {
        e.addClass('not-top-driver');
      } else {
        e.removeClass('not-top-driver');
      }
    });
  }
}
