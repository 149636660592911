/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Tooltip,
  Select,
  Checkbox,
  InputNumber,
  Typography,
} from 'antd';
import { DesignConstraints } from './design-constraints/design-constraints.component';
import { DesignGoals } from './design-goals/design-goals.component';
import { useDesign } from '../../../_shared/context/design-context';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { useSession } from '../../../_shared/context/session-context';
import { DesignStates } from './types';
import { TargetGraph } from './target-graph-component';
import { CostGraph } from './cost-target-graph-component';

import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  CostOptimizationOption,
  Objective,
  ObjectiveType,
  ProjectFeature,
} from '../../../../../__generated__/globalTypes';
import { useWorkspace } from '../lab-bench/context';
import { limitDecimals } from '../../../_shared/utils/util';
import { formatCostScore } from '../../../_shared/utils/component';
import { NO_IMPORTANCE } from '../shared/goals/types';
import { InfoCircleTwoTone } from '@ant-design/icons';

import { IngredientSearch } from '../../../_shared/components/input/ingredient-search.component';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { formatObjectiveText } from '../../../components/experiments/experiment-detail/experiment-detail-util';
const { Panel } = Collapse;
const { Text } = Typography;
const DRAWER_WIDTH = window.innerWidth > 1200 ? 450 : window.innerWidth - 200;
/**
 * Not putting into the svg assets because
 * 1. We don't really need an SVG for this, but I'm lazy
 * 2. I don't think it will be permanent
 */
const Number1 = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#006EFF" />
    <path
      d="M12.8026 7.54545V17H11.6577V8.74574H11.6023L9.29403 10.2784V9.11506L11.6577 7.54545H12.8026Z"
      fill="white"
    />
  </svg>
);

const Number2 = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#006EFF" />
    <path
      d="M8.97869 17V16.169L12.0994 12.7528C12.4657 12.3527 12.7673 12.005 13.0043 11.7095C13.2412 11.411 13.4167 11.1309 13.5305 10.8693C13.6475 10.6046 13.706 10.3277 13.706 10.0384C13.706 9.70597 13.6259 9.41821 13.4659 9.17507C13.3089 8.93194 13.0935 8.7442 12.8196 8.61186C12.5457 8.47952 12.2379 8.41335 11.8963 8.41335C11.5331 8.41335 11.2161 8.48875 10.9453 8.63956C10.6776 8.78729 10.4698 8.99503 10.3221 9.26278C10.1774 9.53054 10.1051 9.84446 10.1051 10.2045H9.01562C9.01562 9.65057 9.14335 9.1643 9.39879 8.74574C9.65424 8.32718 10.002 8.00095 10.4421 7.76704C10.8853 7.53314 11.3823 7.41619 11.9332 7.41619C12.4872 7.41619 12.9781 7.53314 13.4059 7.76704C13.8337 8.00095 14.1692 8.31641 14.4123 8.71342C14.6554 9.11044 14.777 9.55208 14.777 10.0384C14.777 10.3861 14.7139 10.7262 14.5877 11.0586C14.4646 11.3879 14.2492 11.7557 13.9414 12.1619C13.6367 12.5651 13.2135 13.0575 12.6719 13.6392L10.5483 15.9105V15.9844H14.9432V17H8.97869Z"
      fill="white"
    />
  </svg>
);

const PanelHeader = ({ stage, text }: { stage: number; text: string }) => {
  let Icon = Number1;

  switch (stage) {
    case 1:
      Icon = Number1;
      break;
    case 2:
      Icon = Number2;
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-start;
      `}
    >
      {Icon}{' '}
      <span
        css={css`
          font-size: 15px;
          font-weight: 500;
          padding-left: 8px;
        `}
      >
        {text}
      </span>
    </div>
  );
};

const formatConstraintText = (constraint: ConstraintInputType) => {
  let { constraintType } = constraint;
  let constraintText;
  switch (constraintType) {
    case ConstraintType.AMOUNT:
      constraintText = `${
        constraint.name ? constraint.name : 'Unnamed'
      }, Composition Constraint`;
      break;
    case ConstraintType.RANGE:
      constraintText = `${
        constraint.name ? constraint.name : 'Unnamed'
      }, Range Constraint`;
      break;
    case ConstraintType.EQUALITY:
      constraintText = `${
        constraint.name ? constraint.name : 'Unnamed'
      }, Target Constraint`;
      break;
    case ConstraintType.COUNT:
      constraintText = `${
        constraint.name ? constraint.name : 'Unnamed'
      }, Category Constraint`;
      break;
  }
  return constraintText;
};

export const GoalScenario = ({
  submitButton,
}: {
  submitButton?: React.ReactNode;
}) => {
  const {
    requestAndRunDesignJob,
    designState,
    minCostScore,
    maxCostScore,
    designResults,
    runDesignIsLoading,
  } = useDesign();
  const {
    fillerIngredient,
    setFillerIngredient,
    enforceStrictly,
    setEnforceStrictly,
    disableGoalScenario,
    objectivesAreInEditMode,
    objectivesByTarget,
    constraints,
    setMaxNumberOfResults,
    maxNumberOfResults,
    costOptimizationOption,
    setCostOptimizationOption,
    enforceNteCost,
    setEnforceNteCost,
    nteCost,
    setNteCost,
  } = useScenarioDetail();
  const { currentProject, hasFeature } = useSession();
  const hasAdaptiveLearning = currentProject?.features.find(
    f => f.feature === ProjectFeature.ADAPTIVE_LEARNING
  );
  const { isWorkspaceOwner } = useWorkspace();

  let noPriorityObjectiveCount = 0;
  objectivesByTarget.forEach(value => {
    if (value.importance === NO_IMPORTANCE) noPriorityObjectiveCount++;
  });
  const adaptiveLearningDisabled =
    !isWorkspaceOwner ||
    !hasAdaptiveLearning ||
    runDesignIsLoading ||
    objectivesAreInEditMode ||
    designState === DesignStates.RUNNING ||
    noPriorityObjectiveCount === objectivesByTarget.size;

  const defaultSubmitButton = !isWorkspaceOwner ? (
    <Tooltip
      title="Only the owner of the workspace can run Adaptive Learning."
      placement="bottomLeft"
      destroyTooltipOnHide
    >
      <span>
        <Button
          disabled={!isWorkspaceOwner}
          type="primary"
          css={css`
            width: 90%;
            pointer-events: none;
          `}
        >
          Run Adaptive Learning
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button
      onClick={() => requestAndRunDesignJob(false)}
      disabled={adaptiveLearningDisabled}
      type="primary"
      style={{
        width: DRAWER_WIDTH * 0.9,
        position: 'fixed',
        bottom: '20px',
        right: '20px',
      }}
    >
      {designState === DesignStates.RUNNING
        ? 'Processing...'
        : 'Run Adaptive Learning'}
    </Button>
  );
  return (
    <div>
      <div
        css={css`
          margin: 0px 0px 0px 0px;
        `}
      >
        <Collapse
          defaultActiveKey={['objectives', 'constraints']}
          expandIcon={() => <div></div>}
        >
          <Panel
            header={<PanelHeader stage={1} text="Outcomes" />}
            key="objectives"
          >
            <DesignGoals objectivesByTarget={objectivesByTarget} />
          </Panel>
          <Panel
            style={{ marginBottom: 20 }}
            header={<PanelHeader stage={2} text="Constraints" />}
            key="constraints"
          >
            <DesignConstraints />
            {constraints.length > 0 && (
              <div
                css={css`
                  padding-bottom: 0px;
                  padding-top: 10px;
                `}
              >
                <Tooltip
                  placement="bottomLeft"
                  mouseEnterDelay={0.3}
                  title="Turing will not return recommendations outside of constraints when strict enforcement is enabled"
                >
                  <label style={{ marginRight: 8 }}>
                    Enforce Constraints Strictly
                  </label>
                  <Checkbox
                    disabled={disableGoalScenario}
                    checked={enforceStrictly}
                    onChange={(e: CheckboxChangeEvent) =>
                      setEnforceStrictly(e.target.checked)
                    }
                  />
                </Tooltip>
              </div>
            )}
          </Panel>
        </Collapse>
        {hasFeature(ProjectFeature.PRICING) && (
          <>
            <div
              css={css`
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 0px;
              `}
            >
              <div>
                <h4>Cost Optimization</h4>
                <Select
                  disabled={disableGoalScenario}
                  style={{ width: '100%', margin: 'unset' }}
                  onChange={(value: CostOptimizationOption) => {
                    if (value === CostOptimizationOption.LIMIT) {
                      setEnforceNteCost(true);
                    }
                    setCostOptimizationOption(value);
                    setNteCost(0);
                  }}
                  value={costOptimizationOption}
                  options={[
                    {
                      value: CostOptimizationOption.DO_NOT_OPTIMIZE,
                      label: 'Do not optimize for cost',
                    },
                    {
                      value: CostOptimizationOption.LIMIT,
                      label: 'Cost not to exceed',
                    },
                    {
                      value: CostOptimizationOption.MINIMIZE,
                      label: 'Minimize cost',
                    },
                  ]}
                />
              </div>
              {costOptimizationOption === CostOptimizationOption.LIMIT && (
                <>
                  <div
                    css={css`
                      margin-top: 10px;
                    `}
                  >
                    <h5>Not to Exceed Cost Score</h5>
                    <InputNumber
                      value={nteCost}
                      onChange={(value: number) => setNteCost(value)}
                      min={0}
                    />
                    <br />
                    <Text
                      type="secondary"
                      style={{
                        fontSize: '12px',
                        width: '75%',
                        display: 'block',
                        marginTop: '5px',
                      }}
                    >
                      {designResults?.length > 0
                        ? `Existing formulations in this project range from ${limitDecimals(
                            minCostScore,
                            2
                          )} to ${limitDecimals(maxCostScore, 2)} cost score. `
                        : ''}
                    </Text>
                  </div>
                  <Tooltip title="Turing will not return recommendations above Not To Exceed value when strict enforcement is enabled.">
                    <div
                      css={css`
                        margin-top: 10px;
                      `}
                    >
                      <span>Enforce Cost Strictly</span>{' '}
                      <span>
                        <Checkbox
                          disabled={disableGoalScenario}
                          checked={enforceNteCost}
                          value={enforceNteCost}
                          onChange={() => setEnforceNteCost(!enforceNteCost)}
                        />
                      </span>
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
            <Divider />
          </>
        )}
        <div
          css={css`
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 30px;
          `}
        >
          <Tooltip
            placement="topLeft"
            mouseEnterDelay={0.3}
            title="The name of the filler ingredient"
          >
            <h4>
              Filler Ingredient <InfoCircleTwoTone />
            </h4>
          </Tooltip>
          {currentProject?.ingredientList && (
            <IngredientSearch
              isDisabled={disableGoalScenario}
              additionalCss={css`
                margin: unset;
              `}
              ingredients={currentProject?.ingredientList}
              defaultValue={fillerIngredient}
              allowClear
              onClear={() => setFillerIngredient(undefined)}
              onSelect={setFillerIngredient}
            />
          )}
        </div>
        <Divider />
        <div
          css={css`
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 0px;
          `}
        >
          <Tooltip
            placement="bottomLeft"
            mouseEnterDelay={0.3}
            title="The maximum number of recommended formulations Turing will return for testing."
          >
            <h4>
              Testable Recommendations <InfoCircleTwoTone />
            </h4>
            <Select
              disabled={disableGoalScenario}
              style={{ width: 120 }}
              onChange={(value: string) => setMaxNumberOfResults(Number(value))}
              value={String(maxNumberOfResults)}
              options={Array(10)
                .fill(null)
                .map((n, i) => ({ value: i + 1, label: i + 1 }))}
            />
          </Tooltip>
        </div>
        <Divider style={{ marginBottom: submitButton ? 0 : 70 }} />
      </div>
      <div
        css={css`
          text-align: center;
          margin-top: 10px;
          margin-bottom: 10px;
        `}
      >
        {submitButton ?? defaultSubmitButton}
      </div>
    </div>
  );
};

export const DesignProjectSettings = () => {
  const { currentProject } = useSession();
  const { designState, latestDesign } = useDesign();
  const {
    objectivesByTarget,
    constraints,
    openDrawer,
    setOpenDrawer,
    costOptimizationOption,
    enforceNteCost,
    nteCost,
    enforceStrictly,
  } = useScenarioDetail();
  const { isWorkspaceOwner } = useWorkspace();
  return (
    <div>
      {designState !== DesignStates.INITIAL && (
        <>
          <div
            css={css`
              font-size: 18px;
              line-height: 24px;
              margin: 0px 15px 15px 0px;
            `}
          >
            {latestDesign?.costOptimizationOption ===
            CostOptimizationOption.DO_NOT_OPTIMIZE
              ? 'Target Map'
              : 'Cost map'}
          </div>
          {latestDesign?.costOptimizationOption ===
          CostOptimizationOption.DO_NOT_OPTIMIZE ? (
            <div>
              {' '}
              <TargetGraph />
            </div>
          ) : (
            <div>
              {' '}
              <CostGraph />
            </div>
          )}
        </>
      )}
      <div
        css={css`
          font-size: 18px;
          line-height: 24px;
          margin: 15px 15px 15px 0px;
        `}
      >
        Goal Scenario
      </div>

      <div
        css={css`
          width: 15%;
          min-height: 350px;
          min-width: 350px;
          margin-right: 25px;
          padding: 15px;
          border-radius: 6px 6px 0px 0px;
          border: 0.5px solid #dfdfe4;
          background-color: #ffffff;
        `}
      >
        <h4
          css={css`
            margin-bottom: 0px;
          `}
        >
          Outcomes
        </h4>
        <Divider
          css={css`
            margin-top: 0px;
            margin-bottom: 10px;
          `}
        />
        {Array.from(objectivesByTarget).map(([key, obj], idx) => (
          <div key={idx}>
            {obj.targetVariable + ', '}
            {formatObjectiveText(obj)}
          </div>
        ))}

        <h4
          css={css`
            margin-bottom: 0px;
            margin-top: 15px;
          `}
        >
          Constraints
        </h4>
        <Divider
          css={css`
            margin-top: 0px;
            margin-bottom: 10px;
          `}
        />
        <div>Enforce Strictly: {enforceStrictly ? 'Yes' : 'No'}</div>

        {constraints.map((constraint, idx) => (
          <div key={idx}>{formatConstraintText(constraint)}</div>
        ))}
        {costOptimizationOption !== CostOptimizationOption.DO_NOT_OPTIMIZE && (
          <>
            <h4
              css={css`
                margin-bottom: 0px;
                margin-top: 15px;
              `}
            >
              Cost
            </h4>
            <Divider
              css={css`
                margin-top: 0px;
                margin-bottom: 10px;
              `}
            />
          </>
        )}
        {costOptimizationOption === CostOptimizationOption.LIMIT && (
          <>
            <div>
              Cost Not to Exceed:{' '}
              {formatCostScore(
                nteCost ?? 0,
                currentProject?.costMeasurementUnit,
                currentProject?.monetaryUnit
              )}{' '}
            </div>
            <div>Enforce Strictly: {enforceNteCost ? 'Yes' : 'No'} </div>
          </>
        )}
        {costOptimizationOption === CostOptimizationOption.MINIMIZE && (
          <div>Minimize Cost: Yes </div>
        )}
        {!isWorkspaceOwner ? (
          <Tooltip
            title="Only the owner of the workspace can run Adaptive Learning."
            placement="bottomLeft"
            destroyTooltipOnHide
          >
            <span>
              <Button
                type="primary"
                css={css`
                  margin-top: 15px;
                  width: 100%;
                  pointer-events: none;
                `}
                disabled={!isWorkspaceOwner}
              >
                Edit Scenario
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            css={css`
              margin-top: 15px;
              width: 100%;
            `}
            disabled={!isWorkspaceOwner}
            onClick={() => setOpenDrawer(true)}
          >
            Edit Scenario
          </Button>
        )}
      </div>
      <Drawer
        title="Goal Scenario"
        placement={'right'}
        closable={true}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        css={css`
          padding: 0px;
        `}
        width={DRAWER_WIDTH}
        bodyStyle={{
          padding: '0px',
        }}
      >
        <GoalScenario />
      </Drawer>
    </div>
  );
};
