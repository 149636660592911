/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useState, useEffect } from 'react';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { centerInContainer } from '../../../../../_shared/style/positioning.styles';
import { optimizationModalStyles } from '../../styles/workspace-setup.styles';
import { useSession } from '../../../../../_shared/context';

import {
  useRunOptimization,
  useRunSimulation,
} from '../../../../../_shared/hooks';
import {
  createFormulationDataFromProductVersions,
  parseNumber,
} from '../../../../../_shared/utils/util';

import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { CalculateOptimizations } from './calculated-optimizations-v1.component';
import { OptimizationConstraints } from '../constraints';
import { LegacyOptimizationOutcomeGoals } from './outcome-goals/legacy-optimization-outcome-goals.component';
import { NullableConstraintType } from '../types';
import {
  Constraint,
  ConstraintInputType,
  ConstraintType,
  OptimizationOptimizationType,
  SimulationFormulationInputType,
  findIterationWithHistDocument,
  findIterationWithLatestSimulationDocument,
  usefindIterationWithLatestSimulationQuery,
} from '../../../../../../../__generated__/globalTypes';
import { optimizeVariables as OptimizeVariablesType } from '../../../../../_shared/hooks/__generated__/optimize';
import {
  logEvent,
  TrackableEvent,
} from '../../../../../_shared/tracking/usage-tracker';
import {
  IterationWithLatestSimulation,
  SimulationProductVersionResultType,
} from '../../context/types';

export const OptimizationModalV1 = ({
  iteration,
}: {
  iteration: IterationWithLatestSimulation;
}) => {
  const { currentProject } = useSession();
  const [optimizedProducts, setOptimizedProducts] = useState<
    Array<SimulationProductVersionResultType>
  >();

  const {
    simulationProductVersions,
    setShowOptimization,
    getNextSimulationNumber,
  } = useWorkspace();

  const [nextSimulationNumber] = useState<number>(
    getNextSimulationNumber('Optimization')
  );

  const [
    runSimulation,
    { loading: isSimulationRunning, data: simData, error: simError },
  ] = useRunSimulation({
    refetchQueries: [
      {
        query: findIterationWithLatestSimulationDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
      {
        query: findIterationWithHistDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [
    optimize,
    {
      loading: isOptimizationRunning,
      data: optimizationResult,
      called: optimizationCalled,
      error: optimizationError,
    },
  ] = useRunOptimization({});

  useEffect(() => {
    if (optimizationResult) {
      const resultProductIds = optimizationResult.optimize.map(
        pv => pv.productId
      );
      const results = simulationProductVersions.filter(p =>
        resultProductIds.includes(p.productVersion.productId)
      );
      setOptimizedProducts(results);
    }
  }, [simulationProductVersions]);

  const [constraints, setConstraints] = useState(
    new Map<string, NullableConstraintType>()
  );
  const [costConstraints, setCostConstraints] = useState<{
    lowerBounds?: number;
    upperBounds?: number;
  }>({});
  const [targetValue, setTargetValue] = useState<string>();

  const updateCostConstraintLowerBound = (
    value: string | number | undefined
  ) => {
    if (value)
      setCostConstraints({
        lowerBounds: Number(value),
        upperBounds: costConstraints.upperBounds,
      });
  };

  const updateCostConstraintUpperBound = (
    value: string | number | undefined
  ) => {
    if (value)
      setCostConstraints({
        lowerBounds: costConstraints.lowerBounds,
        upperBounds: Number(value),
      });
  };

  const [outcomeGoal, setOutcomeGoal] = useState(
    OptimizationOptimizationType.MAXIMIZE
  );

  if (!currentProject) return <div className="no-project" />;

  const updateConstraints = (c: NullableConstraintType) => {
    const copyOfConstraints = new Map(constraints);
    copyOfConstraints.set(c.ingredientName, c);
    setConstraints(copyOfConstraints);
  };

  const [invalidConstraints, setInvalidConstraints] = useState<string[]>([]);

  useEffect(() => {
    const currInvConstraints: string[] = [];
    constraints.forEach(ct => {
      //Cant use 'continue' in forEach loop
      if (ct.bounds && ct.value === undefined) {
        const upperBounds = parseNumber(ct.bounds?.upperBounds);
        const lowerBounds = parseNumber(ct.bounds?.lowerBounds);

        if (upperBounds === undefined || lowerBounds === undefined) {
          currInvConstraints.push(ct.ingredientName);
        } else if (
          (isNaN(upperBounds) && isNaN(lowerBounds)) ||
          upperBounds < lowerBounds
        ) {
          currInvConstraints.push(ct.ingredientName);
        }
      }
    });
    setInvalidConstraints(currInvConstraints);
  }, [constraints]);

  const isValid = () => {
    let hasValidConstraints = true;

    if (invalidConstraints.length > 0) {
      void message.error({
        content:
          'Constraint values out of range, please update: ' +
          invalidConstraints.join(', '),
        style: {
          marginTop: '20vh',
        },
      });
      hasValidConstraints = false;
    }

    if (
      outcomeGoal === OptimizationOptimizationType.TARGET_VALUE &&
      (targetValue === undefined || targetValue === '')
    ) {
      void message.error({
        content: 'A target value must be specified',
      });
      hasValidConstraints = false;
    }

    return hasValidConstraints;
  };

  const runSimulationWithPackage = async (
    simulationPackage: Array<SimulationFormulationInputType>
  ) => {
    await runSimulation({
      variables: {
        projectId: currentProject.id,
        iterationId: iteration?.id ?? '',
        formulationData: simulationPackage,
      },
    });
  };

  const handleCreateOptimization = async () => {
    if (!isValid()) return;

    const formattedConstraints: ConstraintInputType[] = [];
    constraints.forEach(c => {
      formattedConstraints.push({
        coefficients: [{ name: c.ingredientName, value: 1 }],
        constraintType: ConstraintType.RANGE,
        lowerBounds: c?.bounds?.lowerBounds,
        name: '',
        upperBounds: c?.bounds?.upperBounds,
        values: [],
        variables: [],
      });
    });
    const optimizeVariables: OptimizeVariablesType = {
      nextSimulationNumber,
      iterationId: iteration.id,
      constraints: formattedConstraints,
      outcomeGoal,
    };
    if (
      outcomeGoal === OptimizationOptimizationType.TARGET_VALUE &&
      targetValue &&
      targetValue !== ''
    ) {
      optimizeVariables.targetValue = Number(targetValue);
    }
    const optResult = await optimize({
      variables: optimizeVariables,
    });

    if (!optResult.data || optResult.errors) {
      throw new Error(
        optResult.errors ? JSON.stringify(optResult.errors) : 'Something broke'
      );
    }

    const numberOfSpvs = simulationProductVersions?.length ?? 0;

    const simulationPackage: Array<SimulationFormulationInputType> = [
      ...createFormulationDataFromProductVersions(simulationProductVersions),
      ...optResult.data.optimize.map((pv, index) => ({
        columnNumber: numberOfSpvs + index,
        isBenchmark: numberOfSpvs === 0 && index === 0,
        name: pv.name,
        isOptimization: true,
        productId: pv.productId,
        formulation: pv.formulation.quantities,
        formulationId: pv.formulation.id,
      })),
    ];

    await runSimulationWithPackage(simulationPackage);
  };

  return (
    <Modal
      maskClosable={false}
      mask={false}
      width="100%"
      title={
        <span
          css={css`
            padding-left: 57px;
            font-weight: bold;
          `}
        >
          Create Turing Optimization
        </span>
      }
      open
      onCancel={() => {
        logEvent(TrackableEvent.OPTIMIZATION_CANCELED, {
          iterationId: iteration.id,
        });
        setShowOptimization(false);
      }}
      cancelButtonProps={{ style: { display: 'none' } }}
      closeIcon={
        <ArrowLeftOutlined
          color="##373737"
          css={centerInContainer}
          style={{ fontSize: '19px' }}
        />
      }
      css={optimizationModalStyles}
      footer={null}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          className="optModalColumn"
          css={css`
            width: 34%;
            border-right: 3px dotted #eaeaea;
          `}
        >
          <div
            css={css`
              padding-bottom: 28px;
            `}
          >
            <span className="optimizationStepNumber">1</span>
            <span className="headerText">Define goals and constraints</span>
          </div>
          <LegacyOptimizationOutcomeGoals
            optimizationCalled={optimizationCalled}
            setOutcomeGoal={setOutcomeGoal}
            costConstraints={costConstraints}
            updateCostConstraintLowerBound={updateCostConstraintLowerBound}
            updateCostConstraintUpperBound={updateCostConstraintUpperBound}
            targetValue={targetValue}
            setTargetValue={setTargetValue}
            outcomeGoal={outcomeGoal}
          />
          {/* <div className="optStepBox">
            <div className="steBoxTitle">Base Ingredient</div>
          </div> */}
          <OptimizationConstraints
            optimizationCalled={optimizationCalled}
            constraints={constraints}
            updateConstraint={updateConstraints}
          />
          <Button
            type="primary"
            css={css`
              float: right;
              margin-right: 30px;
            `}
            loading={isOptimizationRunning || isSimulationRunning}
            disabled={optimizationCalled}
            onClick={handleCreateOptimization}
          >
            <span
              css={css`
                font-weight: bold;
              `}
            >
              Create optimization <ArrowRightOutlined />
            </span>
          </Button>
        </div>
        <CalculateOptimizations
          currentProject={currentProject}
          constraints={constraints}
          optimizedProducts={optimizedProducts}
          runSimulationWithPackage={runSimulationWithPackage}
          isSimulationRunning={isSimulationRunning}
          error={optimizationError || simError}
          isRunning={
            isOptimizationRunning || (optimizationCalled && !optimizedProducts)
          }
          showModal={setShowOptimization}
        />
      </div>
    </Modal>
  );
};
