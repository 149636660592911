/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Modal, Steps, Typography, Tabs, notification } from 'antd';
import { css } from '@emotion/react';
import { useIngredients } from '../../_shared/hooks';
import {
  projectByIdQuery,
  usecreateCompositionsMutation,
  usedeleteIngredientCompositionMutation,
  useupdateIngredientListCompositionsMutation,
  useupdateCompositionMutation,
  IngredientInput,
} from '../../../../__generated__/globalTypes';
import { IngredientCompositionTable } from './ingredient-composition-table.component';
import { useSession } from '../../_shared/context';
import { TableDataType } from './ingredient-composition-table.component';
import { IngredientComponents } from './ingredient-composition-inputs.component';
import { IngredientCompositionFileUpload } from './file-upload/ingredient-composition-file-upload.component';
const { Step } = Steps;
const { Paragraph, Text } = Typography;

const Instructions = (
  <Paragraph
    css={css`
      margin-top: 15px;
    `}
  >
    Create at least one component (e.g. protein, moisture, salt) that applies to
    multiple ingredients. You will configure percents of each component for each
    ingredient on the next step.
  </Paragraph>
);

const Instructions2 = (
  <Paragraph
    css={css`
      margin-top: 15px;
    `}
  >
    Specify how much of each ingredient contains the components from the
    previous step.
  </Paragraph>
);

type modalProps = {
  onClose: () => void;
  refeshProject?: boolean;
};

export type IngredientComposition =
  | NonNullable<
      NonNullable<projectByIdQuery['project']>['ingredientComposition'][0]
    >
  | { id?: string; name: string };

export const IngredientCompositionSetupModal = ({
  onClose,
  refeshProject = true,
}: modalProps) => {
  const [currentStep, setCurrentStep] = useState(0);

  const { activeNumericIngredients } = useIngredients();
  const {
    currentProject,
    useFetchProject,
    setIngredientCompositionList,
    ingredientCompositionList,
  } = useSession();
  const existingCompositions = ingredientCompositionList; //currentProject?.ingredientComposition;
  const [fetchProjectById] = useFetchProject();
  const [ingredientCompositions, setIngredientCompositions] = useState<
    Array<IngredientComposition>
  >(existingCompositions || []);
  const [
    updateIngredientListCompositions,
    { loading },
  ] = useupdateIngredientListCompositionsMutation();
  const updateIngredientList = async (ingredients: IngredientInput[]) => {
    await updateIngredientListCompositions({
      variables: {
        projectId: currentProject!.id,
        ingredients,
      },
    });
  };
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  useEffect(
    () => {
      const initialData = activeNumericIngredients.map(i => {
        const data: TableDataType = {
          key: i.ingredient.name,
          ingredientName: i.ingredient.name,
          ingredientId: i.ingredient.id,
        };
        for (const comp of ingredientCompositions) {
          const storedCompositionValues = i?.ingredientCompositions?.find(
            ic => ic.ingredientCompositionId === comp.id
          );
          data[comp.name] = storedCompositionValues?.value ?? 0;
        }
        return data;
      });
      setTableData(initialData);
    },
    [ingredientCompositions, ingredientCompositionList]
    //[ingredientCompositions, currentProject?.ingredientComposition]
  );

  const [ingredientsToUpdate, setIngredientsToUpdate] = useState<
    IngredientInput[]
  >([]);
  const [createCompositions] = usecreateCompositionsMutation();
  const [deleteComposition] = usedeleteIngredientCompositionMutation();
  const [updateCompositionName] = useupdateCompositionMutation();
  const refreshProject = async () => {
    if (refeshProject) {
      await fetchProjectById({
        variables: {
          projectId: `${currentProject?.id}`,
        },
      });
    }
  };

  const refreshAndClose = async () => {
    await refreshProject();
    onClose();
  };

  const saveNewCompositions = async () => {
    if (ingredientCompositions.length > 0) {
      const newCompositions = ingredientCompositions
        .filter(
          ingredientComposition =>
            !ingredientComposition.id && ingredientComposition.name
        )
        .map(ingredientComposition => ingredientComposition.name);

      try {
        const createdCompositionsResult = await createCompositions({
          variables: {
            projectId: currentProject!.id,
            compositionNames: newCompositions,
          },
        });

        const updatedIngredientCompositions = [...ingredientCompositions];
        createdCompositionsResult.data?.createCompositions.forEach(
          createdIngredientComposition => {
            const existingComposition = updatedIngredientCompositions.findIndex(
              existingIngredientComposition =>
                existingIngredientComposition.name ===
                createdIngredientComposition?.name
            );

            if (existingComposition !== -1 && createdIngredientComposition) {
              updatedIngredientCompositions[
                existingComposition
              ] = createdIngredientComposition;
            }
          }
        );

        setIngredientCompositions(updatedIngredientCompositions);
        setIngredientCompositionList(updatedIngredientCompositions);
        await refreshProject();
      } catch (error: any) {
        Sentry.captureMessage(error);
        notification.error({
          description: 'There was an error creating your composition.',
          message: 'Error Creating Ingredient Composition',
        });
      }
    }
  };

  const saveCompositionIngredientValues = async () => {
    try {
      if (ingredientsToUpdate.length > 0) {
        await updateIngredientList(ingredientsToUpdate);
        notification.success({
          description: `Ingredient composition values have been saved successfully.`,
          message: 'Ingredient Composition Saved',
          maxCount: 2,
        });
      }
    } catch (e) {
      notification.error({
        description:
          'There was an error updating your values. Please ensure the values are numerical and between 1-100.',
        message: 'Error Updating Ingredient Composition',
      });
    }
  };

  const removeIngredientComposition = async (index: number) => {
    try {
      const toBeRemoved = ingredientCompositions[index];
      let compositionsAfterRemoval = ingredientCompositions.filter(
        (_, i) => index !== i
      );
      if (toBeRemoved?.id) {
        await deleteComposition({
          variables: {
            compositionId: toBeRemoved?.id,
            projectId: currentProject!.id,
          },
        });
        setIngredientCompositions(compositionsAfterRemoval);
        setIngredientCompositionList(compositionsAfterRemoval);
      } else {
        setIngredientCompositions(compositionsAfterRemoval);
        setIngredientCompositionList(compositionsAfterRemoval);
      }
    } catch (e: any) {
      Sentry.captureMessage(e);
      notification.error({
        description: 'There was an error removing your composition.',
        message: 'Error Updating Ingredient Composition',
      });
    }
  };

  const updateIngredientComposition = async (name: string, index: number) => {
    ingredientCompositions[index] = {
      ...ingredientCompositions[index],
      name,
    };
    if (ingredientCompositions[index]?.id) {
      await updateCompositionName({
        variables: {
          projectId: currentProject!.id,
          compositionId: ingredientCompositions[index].id || '',
          compositionName: name,
        },
      });
    }
    setIngredientCompositions([...ingredientCompositions]);
    setIngredientCompositionList([...ingredientCompositions]);
  };
  const createIngredientComposition = () => {
    setIngredientCompositions([...ingredientCompositions, { name: '' }]);
  };

  const CreateComponentsContent = (
    <div>
      {Instructions}
      <IngredientComponents
        createIngredientComposition={createIngredientComposition}
        updateIngredientComposition={updateIngredientComposition}
        removeIngredientComposition={removeIngredientComposition}
        ingredientCompositions={ingredientCompositions}
        tableData={tableData}
      />
    </div>
  );

  const ConfigureIngredientsContent = (
    <div
      css={css`
        min-height: 360px;
      `}
    >
      {Instructions2}
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Table Entry',
            key: '1',
            children: (
              <IngredientCompositionTable
                tableData={tableData || []}
                setTableData={setTableData}
                ingredientCompositions={ingredientCompositions}
                ingredientsToUpdate={ingredientsToUpdate}
                setIngredientsToUpdate={setIngredientsToUpdate}
              />
            ),
          },
          {
            label: 'File Upload',
            key: '2',
            children: (
              <IngredientCompositionFileUpload
                compositions={ingredientCompositions}
                refreshProject={refreshProject}
                tableData={tableData}
              />
            ),
          },
        ]}
      />
    </div>
  );

  const steps = [
    {
      title: 'Create Components',
      content: CreateComponentsContent,
    },
    {
      title: 'Configure Ingredients',
      content: ConfigureIngredientsContent,
    },
  ];

  const previousBtn = (
    <Button
      key="previous"
      type="primary"
      disabled={currentStep === 0}
      onClick={() => (currentStep > 0 ? setCurrentStep(currentStep - 1) : null)}
    >
      Previous Step
    </Button>
  );

  const cancelBtn = (
    <Button key="back" onClick={onClose}>
      Cancel
    </Button>
  );

  const saveCompositionsBtn = (
    <Button
      key="saveCompositions"
      type="primary"
      disabled={ingredientCompositions.length === 0}
      onClick={() => {
        steps.length >= currentStep ? setCurrentStep(currentStep + 1) : null;
        saveNewCompositions();
      }}
    >
      Next Step
    </Button>
  );

  const submitIngredientList = (
    <Button
      key="submit"
      type="primary"
      onClick={async () => {
        await saveCompositionIngredientValues();
        refreshAndClose();
      }}
      loading={loading}
    >
      Submit
    </Button>
  );

  const footer = [
    cancelBtn,
    previousBtn,
    ...(currentStep === 0 ? [saveCompositionsBtn] : []),
    ...(currentStep + 1 === steps.length ? [submitIngredientList] : []),
  ];
  return (
    <Modal
      title="Ingredient Composition Setup"
      open={true}
      onCancel={onClose}
      footer={footer}
      centered
      css={css`
        min-width: 800px;
      `}
    >
      <Steps current={currentStep} direction="horizontal">
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[currentStep]?.content}</div>
    </Modal>
  );
};
