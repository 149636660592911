/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { IngredientType } from '../../../../_shared/hooks';
import { useSession } from '../../../../_shared/context/session-context';
import {
  InputType,
  FormulationInput,
} from '../../../../_shared/components/input/formulation-input.component';
import { ProjectFeature } from '../../../../../../__generated__/globalTypes';
import { Bounds } from '../../../../_shared/components/text/bounds';
import { Price } from '../../../../_shared/components/text/price';
import { KnowledgeGraphLaunchBtn } from '../../../../_shared/components/icon/knowledge-graph-icon.component';
import { EllipsisMiddle } from '../../../../_shared/utils/component';
import { isWithinBounds } from '../../adaptive-learning/design-validation';
import { ErrorMessage } from '../../adaptive-learning/design-constraints/equality-constraint.component';
import { hasValue } from '../../../../_shared/utils/util';
export type PanelIngredientProps = {
  ingredient: IngredientType;
  initialValue?: string | number;
  setValue: (value?: string | number) => void;
  isProductBenchmark: boolean;
  benchmarkValue?: string;
};

const getIngredientType = (ingredient: IngredientType) => {
  return !ingredient?.isTestCondition
    ? InputType.PERCENT
    : ingredient.values.length
    ? InputType.CHOICE
    : InputType.STRING;
};

export const PanelIngredient = ({
  ingredient,
  initialValue,
  setValue: persistValue,
}: PanelIngredientProps) => {
  const [inputValue, setInputValue] = useState(initialValue);
  const inputType = getIngredientType(ingredient);
  const { isJarModel, currentProject } = useSession();

  const featureToggles = new Set(
    currentProject?.features?.map(f => f.feature) || []
  );
  const status = isWithinBounds(
    Number(inputValue),
    ingredient?.lowerLimit,
    ingredient?.upperLimit
  )
    ? ''
    : 'error';
  return (
    <div className="flex items-center ingredient-form-container">
      <div className="w-50 pr2">
        <EllipsisMiddle suffixCount={20}>
          {ingredient.ingredient.name}
        </EllipsisMiddle>
        <br />
        <Bounds ingredient={ingredient} />

        <Price
          ingredient={ingredient}
          monetaryUnit={currentProject?.monetaryUnit}
          costMeasurementUnit={currentProject?.costMeasurementUnit}
          prefixText={hasValue(ingredient?.lowerLimit) ? '| Price' : 'Price'}
        />
      </div>
      <div className="w-50 flex">
        {!isJarModel && featureToggles.has(ProjectFeature.INSIGHT_EXPLORER) && (
          <span className="mr2">
            <KnowledgeGraphLaunchBtn
              ingredientId={ingredient.ingredient.name}
            />
          </span>
        )}
        <div style={{ width: '100%' }}>
          <FormulationInput
            value={inputValue}
            options={
              inputType === InputType.CHOICE ? ingredient.values : undefined
            }
            type={inputType}
            onChange={setInputValue}
            setValue={v => {
              persistValue(v);
            }}
            status={status}
          />
          {status === 'error' && (
            <div style={{ display: 'flex', color: 'red' }}>
              <ErrorMessage
                upperLimit={ingredient.upperLimit}
                lowerLimit={ingredient.lowerLimit}
                unit={ingredient.unit ?? ''}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
