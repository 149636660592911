import { Button, Layout, Tooltip } from 'antd';
import React from 'react';
import './project-setup-header-styles.less';
import { SaveOutlined } from '@ant-design/icons';
import { NewBackArrow } from '../../../../_shared/style';

export interface IHeaderInfo {
  title: string;
  description: string;
  activeStep?: string;
  nextStepEnable: boolean;
  backEnable: boolean;
  finishSetup: boolean;
  onClickNext?: (value: any) => void;
  onClickBack?: (value: any) => void;
  onClickSave?: (value: any) => void;
  onClickFinish?: (value: any) => void;
}

export const ProjectSetupHeader: React.FC<IHeaderInfo> = ({
  title,
  description,
  activeStep,
  nextStepEnable,
  backEnable,
  finishSetup,
  onClickNext,
  onClickBack,
  onClickSave,
  onClickFinish,
}) => {
  return (
    <div id="project-header">
      <div id="project-setup-header-box">
        <div>
          <span className="header-title">{title}</span>
          <span className="header-description">{description}</span>
        </div>
        <div className="action-button-header">
          {!finishSetup && (
            <>
              <Tooltip title={(activeStep === "EDIT_RAW_INPUT_OUTCOMES" && !nextStepEnable) ? "At least 1 ingredient, 1 outcome, and 1 filler ingredient are required." : undefined}>
                <Button
                  type="primary"
                  className="base-button"
                  disabled={!nextStepEnable}
                  style={{ marginRight: '10px' }}
                  onClick={onClickNext}
                >
                  Next →
                </Button>
              </Tooltip>
              <Button
                type="primary"
                className="base-button"
                icon={<SaveOutlined />}
                onClick={onClickSave}
              >
                Save
              </Button>
            </>
          )}
          {finishSetup && (
            <Button
              type="primary"
              className="base-button"
              onClick={onClickFinish}
            >
              Finish Project Setup
            </Button>
          )}
        </div>
      </div>
      {backEnable && (
        <div id="back-step">
          <div className="button-back" onClick={onClickBack}>
            <NewBackArrow />
          </div>
        </div>
      )}
    </div>
  );
};
