/** @jsxImportSource @emotion/react */
import { useEffect, lazy, Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Layout, Row, Spin } from 'antd';

import { contentStyle } from './project-layout.styles';
import { useSession } from '../../_shared/context/session-context';
import AskTuringPanel from '../dashboard/ask-turing-panel.component';
import { NotFoundComponent } from '../../_shared/components/error-pages';
import { centerInContainer } from '../../_shared/style';
import { FormulationsContextProvider } from '../../_shared/context/formulations-context';
const { Content } = Layout;

const AdminLayout = lazy(() => import('./admin/project-admin-layout'));

type ProjectRouteParams = {
  projectId: string;
};

export const ProjectLayout = () => {
  const { projectId } = useParams<ProjectRouteParams>();

  const {
    currentProject,
    setCurrentProject,
    showAskTuring,
    useFetchProject,
    setSelectedIterationId,
    projectFetchError,
    setProjectFetchError,
  } = useSession();

  const [projectById, { loading }] = useFetchProject();

  useEffect(() => {
    if (projectId) {
      setProjectFetchError(false);
      projectById({
        variables: {
          projectId,
        },
      });
      setSelectedIterationId('');
    }

    //Clear out project
    return () => setCurrentProject(undefined);
  }, [projectId]);

  const rowColDefaultStyle = { height: '100%', margin: 0, padding: 0 };

  if (projectFetchError === true) {
    return (
      <NotFoundComponent
        message={
          <>
            You do not have access to this project. Please contact{' '}
            <a href="mailto:customer-success-team@turingsaas.com">support</a>.
          </>
        }
        showAllProjectsLink
      />
    );
  }

  return (
    <Spin
      spinning={loading || !currentProject}
      wrapperClassName="fullSpinnerClass"
    >
      <Layout>
        <Content css={contentStyle}>
          <Row gutter={[20, 0]} style={rowColDefaultStyle}>
            <Col
              flex="auto"
              style={
                showAskTuring
                  ? { ...rowColDefaultStyle, maxWidth: 'calc(100% - 375px)' }
                  : rowColDefaultStyle
              }
            >
              {currentProject && (
                <Suspense
                  fallback={
                    <Spin css={centerInContainer} size="large" spinning></Spin>
                  }
                >
                  <FormulationsContextProvider currentProject={currentProject}>
                    <Outlet />
                  </FormulationsContextProvider>
                </Suspense>
              )}
            </Col>
            {showAskTuring && (
              <Col flex="300px">
                <AskTuringPanel />
              </Col>
            )}
          </Row>
        </Content>
      </Layout>
    </Spin>
  );
};
