import React, { FC, useEffect, useState } from 'react';
import './project-info-form-styles.less';
import {
  MandatoryFieldInput,
  ProjectInfoFormData,
  ProjectInfoFormMandatoryField,
  ProjectInfoObligatoryFields,
  TypeOfDevelopment,
} from '../../project-setup-constants';
import { ProjectInputForm } from './project-input-form/project-input-form';
import Input from 'antd/es/input/Input';
import TextArea from 'antd/es/input/TextArea';
import { Collapse, CollapseProps, DatePicker, Select } from 'antd';
import {
  IProjectInfo,
  IProjectMandatoryInfo,
  IProjectOptionalInfo,
} from '../../project-setup-interfaces';
import { Rule } from 'antd/es/form';

import dayjs from 'dayjs';

export interface IProjectInfoFormProps {
  project?: IProjectInfo;
  onInputChange: (formData: IProjectMandatoryInfo) => void;
}

export const ProjectInfoForm: FC<IProjectInfoFormProps> = ({
  project,
  onInputChange,
}) => {
  const InputFormInformation = ProjectInfoFormMandatoryField;
  const OptionalFields = ProjectInfoObligatoryFields;

  const [mandatoryFormData, setMandatoryFormData] = useState<IProjectInfo>(
    project
      ? project
      : {
          productCategory: '',
          projectObjective: '',
          productName: '',
          projectName: '',
          typeOfDevelopment: '',
          productSubCategory: '',
          launchDate: '',
          overallProjectDescription: '',
          ownerOfTheProject: '',
          productBrand: '',
          lastSetupStep: '',
        }
  );

  const onInputValueListener = (prop: keyof IProjectInfo) => (value: any) => {
    // const words: string[] = prop.split(' ');
    // const propCase: string = words[0].toLowerCase() + words.slice(1).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join('');
    setMandatoryFormData(prevData => ({
      ...prevData,
      [prop as keyof IProjectInfo]: value,
    }));
  };

  const InputFactory = (
    data: MandatoryFieldInput,
    key: string,
    placeholder?: string
  ) => {
    switch (data.inputType) {
      case 'textarea':
        return (
          <TextArea
            placeholder={placeholder}
            style={{
              width: '352px',
              marginRight: '21px',
            }}
            onChange={e =>
              onInputValueListener(key as keyof IProjectInfo)(e.target.value)
            }
            maxLength={data.maxLength || 250}
            value={mandatoryFormData[key as keyof IProjectInfo]}
          />
        );

      case 'select':
        return (
          <Select
            value={mandatoryFormData[key as keyof IProjectInfo]}
            style={{
              width: '276px',
              marginRight: '94px',
            }}
            onChange={e => onInputValueListener(key as keyof IProjectInfo)(e)}
          >
            {data.selectOptions &&
              data.selectOptions.map(option => (
                <Select.Option value={option}>{option}</Select.Option>
              ))}
          </Select>
        );

      case 'date':
        let today;
        if (mandatoryFormData[key as keyof IProjectInfo]) {
          today = dayjs(
            mandatoryFormData[key as keyof IProjectInfo],
            'YYYY-MM-DD'
          );
        }
        return (
          <DatePicker
            placeholder="MM/DD/YY"
            style={{
              width: '276px',
              marginRight: '94px',
            }}
            inputReadOnly={true}
            format="MM-DD-YY"
            value={today}
            onChange={e =>
              onInputValueListener(key as keyof IProjectInfo)(
                e?.toDate().toISOString()
              )
            }
          />
        );

      default:
        return (
          <Input
            placeholder={placeholder}
            style={{
              width: '276px',
              marginRight: '94px',
            }}
            value={mandatoryFormData[key as keyof IProjectInfo]}
            onChange={e =>
              onInputValueListener(key as keyof IProjectInfo)(e.target.value)
            }
            maxLength={data.maxLength || 250}
          />
        );
    }
  };

  const FormFieldFactory = (
    data: ProjectInfoFormData,
    project: any,
    mandatory?: boolean
  ) => {
    return (
      <>
        {Object.keys(data).map((key: string) => (
          <ProjectInputForm
            title={data[key].placeHolder}
            description={data[key].description}
            divider={true}
            customComponent={InputFactory(
              data[key],
              key,
              data[key].placeHolder
            )}
            onInputChange={onInputValueListener}
            mandatory={mandatory}
          />
        ))}
      </>
    );
  };

  const optionalFieldsItems: CollapseProps['items'] = [
    {
      key: 1,
      label: 'Optional Fields',
      children: FormFieldFactory(OptionalFields, project),
    },
  ];

  const [isOptionalOpened, setIsOptionalOpened] = useState<boolean>(false);
  const onCollapseEventListener = (key: string | string[]) => {
    setIsOptionalOpened(isOptionalOpened ? false : true);
  };

  useEffect(() => {
    onInputChange(mandatoryFormData);
  }, [mandatoryFormData]);

  useEffect(() => {
    //InputFormInformation
  }, [project]);

  return (
    <div id="project-info-form-box">
      <div>{FormFieldFactory(InputFormInformation, project, true)}</div>
      <Collapse
        onChange={onCollapseEventListener}
        items={optionalFieldsItems}
        bordered={false}
        className="accordion-optional"
        style={{ padding: '0px' }}
        ghost
      />
    </div>
  );
};
