import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const projectInfoModalBodyStyles = css`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const projectInfoSectionStyles = css`
  background-color: ${Colors.WHITE};
  border-radius: 6px;
  padding: 20px;
`;

export const projectInfoModalTitleContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const buttonStyle = css`
  padding: 0 10px;
  height: 24px;
  border: none;
  margin-left: 5px;
`;

export const grayButtonStyle = css`
  ${buttonStyle}
  background-color: ${Colors.SOLITUDE};
  color: ${Colors.STORM_GREY};

  &:hover {
    color: ${Colors['#010101']};
    background-color: ${Colors.SOLITUDE};
  }

  &:active,
  &:focus {
    color: ${Colors.STORM_GREY};
    background-color: ${Colors.SOLITUDE};
  }
`;

export const projectInfoModalTitleStyles = css`
  color: ${Colors.SWAMP};
`;

export const userListStyles = css`
  height: 400px;
  min-height: 250px;
  max-height: 300px;
  overflow: auto;

  .ant-list-item {
    border-bottom: none;
    padding: 4px 0;
  }
`;

export const projectCategorizationDetailsStyles = css`
  margin-top: 10px;
  font-size: 13px;
  color: ${Colors.ARSENIC};
`;

export const categorizationRowLabelStyles = css``;

export const categorizationRowDividerStyles = css`
  .ant-divider {
    background: ${Colors.GRADIENT_DARK};
    margin: 0.72em 0 0 0;
  }
`;

export const categorizationRowValueStyles = css`
  color: ${Colors.GRADIENT_DARK};
`;

export const userMetaStyles = css`
  .ant-list-item-meta-content {
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .ant-list-item-meta-avatar {
    margin-right: 10px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
    font-size: 13px;
    color: ${Colors['#010101']};
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    color: ${Colors.GRADIENT_DARK};
  }
`;

export const addUserActionsContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-btn {
    margin-left: 5px;
  }
`;
