/** @jsxImportSource @emotion/react */

import {
  dashboardPanelStyle,
  dashboardPanelHeaderStyle,
  dashboardPanelContentStyle,
} from './dashboard-panel.styles';

type Props = {
  children: React.ReactNode;
  compact?: boolean;
  sideBySide?: boolean;
  header: React.ReactNode;
  headerDirection?: 'row';
};

export const DashboardPanel: React.FC<Props> = ({
  compact,
  sideBySide,
  header,
  headerDirection,
  children,
}) => {
  return (
    <div
      className={`${sideBySide ? 'side-by-side' : ''} ${
        compact ? 'compact' : ''
      }`}
      css={dashboardPanelStyle}
    >
      <div
        className={headerDirection ? 'row-header' : ''}
        css={dashboardPanelHeaderStyle}
      >
        {header}
      </div>
      <div className="dashboard-panel-content" css={dashboardPanelContentStyle}>
        {children}
      </div>
    </div>
  );
};
