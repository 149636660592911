import { useIngredients } from '../../../_shared/hooks';
import {
  FormulationStatus,
  IngredientList,
  JobStatus,
  projectScenariosQuery,
  useprojectScenariosQuery,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import { Button, Card, Divider, Modal, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatObjectiveText } from '../experiment-detail/experiment-detail-util';
import { formatConstraintText } from '../../../components/exploration/new-experiment/constraint-row-component';
import { ArrowRightOutlined, CheckOutlined } from '@ant-design/icons';
const { Text } = Typography;

type ScenarioFromQuery = NonNullable<
  projectScenariosQuery['project']
>['designs'][0];

const ScenarioCard = ({
  design,
  selectedScenarios,
  setSelectedScenarios,
  isSelected,
}: {
  design: ScenarioFromQuery;
  selectedScenarios: string[];
  setSelectedScenarios: (selectedScenarios: string[]) => void;
  isSelected: boolean;
}) => {
  const { currentProject } = useSession();
  const [collapsed, setCollapsed] = useState(true);

  if (!currentProject) {
    return null;
  }
  const { ingredientByName } = useIngredients();
  const handleScenarioSelection = () => {
    let uniqueSelectedScenarios = new Set([...selectedScenarios]);
    if (uniqueSelectedScenarios.has(design.id)) {
      uniqueSelectedScenarios.delete(design.id);
      setSelectedScenarios(Array.from(uniqueSelectedScenarios));
    } else {
      setSelectedScenarios([...selectedScenarios, design.id]);
    }
  };
  return (
    <Card
      actions={[
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button
            type="text"
            icon={<ArrowRightOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? 'View Full Details' : 'Hide Details'}
          </Button>
        </div>,
      ]}
      key={design.id} // Assuming each design has a unique id
      extra={
        <Button
          size="small"
          onClick={handleScenarioSelection}
          icon={isSelected && <CheckOutlined />}
          style={
            isSelected
              ? {
                  color: '#4096ff',
                  borderColor: '#4096ff',
                }
              : {}
          }
        >
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      }
      size="small"
      title={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{design.iteration.name}</span>
          <Text type="secondary">
            {`${new Date(design.createdAt).toDateString()} - ${
              design.createdBy.email
            }`}
          </Text>
        </div>
      }
      bordered={true}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr', // This line will create two equal columns
          columnGap: 15,
          minWidth: 500,
        }}
      >
        <div
          id="source-objective-section"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text type="secondary" style={{ marginBottom: 15 }}>
            Objectives
          </Text>

          {design?.objectives
            ?.slice(0, collapsed ? 3 : design.objectives.length)
            .map((objective, index) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ fontWeight: 500 }}>
                    {objective?.targetVariable}
                  </div>
                  <div>{formatObjectiveText(objective)}</div>
                </div>
                <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              </>
            ))}
        </div>
        <div
          id="source-constraint-section"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Text type="secondary" style={{ marginBottom: 15 }}>
            Constraints
          </Text>
          {design?.constraints
            ?.slice(0, collapsed ? 3 : design.constraints.length)
            .map((constraint, index) => {
              const formattedConstraint = formatConstraintText(
                constraint,
                ingredientByName as Map<string, IngredientList>,
                currentProject?.ingredientComposition
              );
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ fontWeight: 500 }}>
                      {formattedConstraint?.text}
                    </div>
                    <div>{formattedConstraint?.description}</div>
                  </div>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </>
              );
            })}
        </div>
      </div>
    </Card>
  );
};

export const ScenarioFilterModal = ({
  open,
  onClose,
  onOk,
  selectedScenarios,
  setSelectedScenarios,
}: {
  open: boolean;
  onClose: () => void;
  onOk: () => void;
  selectedScenarios: string[];
  setSelectedScenarios: (selectedScenarios: string[]) => void;
}) => {
  const { currentProject } = useSession();

  if (!currentProject) {
    return null;
  }
  const selectedScenario = selectedScenarios[0];
  const { data: scenariosByProject, loading, error } = useprojectScenariosQuery(
    {
      variables: {
        projectId: currentProject.id, // value for 'projectId'
      },
    }
  );

  return (
    <Modal
      open={open}
      title="Select Scenarios"
      onCancel={onClose}
      onOk={onOk}
      okText={`Select ${selectedScenarios?.length} Scenarios`}
      style={{ minWidth: 'fit-content' }}
    >
      <Spin spinning={loading}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 400px)',
          }}
        >
          {scenariosByProject?.project?.designs
            ?.sort((a, b) => {
              const aIsSelected = selectedScenario === a.id ? 1 : 0;
              const bIsSelected = selectedScenario === b.id ? 1 : 0;
              return bIsSelected - aIsSelected;
            })
            ?.filter(
              design =>
                design?.projectJob?.status === JobStatus.SUCCESS &&
                design.formulations.some(
                  f => f.status === FormulationStatus.PINNED
                )
            )
            .map(design => (
              <ScenarioCard
                design={design}
                key={design.id}
                selectedScenarios={selectedScenarios}
                setSelectedScenarios={setSelectedScenarios}
                isSelected={selectedScenarios.some(
                  selectedScenario => selectedScenario === design.id
                )}
              />
            ))}
        </div>
      </Spin>
    </Modal>
  );
};
