/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Select } from 'antd';
import { SearchIcon } from '../../style';
import { EllipsisMiddle } from '../../../_shared/utils/component';
import groupBy from 'lodash/groupBy';
import { IngredientType } from '@root/_shared/hooks/use-ingredient.hook';
import { projectByIdQuery } from '../../../../../__generated__/globalTypes';

const { Option } = Select;

export interface IngredientSearchProps {
  onSelect: (val: string) => void;
  onDeselect?: (val: string) => void;
  onClear?: () => void;
  ingredients: IngredientType[];
  isDisabled?: boolean;
  mode?: 'multiple' | 'tags';
  additionalCss?: any;
  defaultValue?:
    | {
        label: string;
        value: string;
      }[]
    | string
    | null
    | undefined;
  allowClear?: boolean;
  ingredientCompositions?: NonNullable<
    NonNullable<projectByIdQuery['project']>['ingredientComposition']
  >;
  setValueAsJson?: boolean;
  className?: string;
}
export type IngredientAndCompositionSelectValue = {
  type: 'ingredient' | 'composition';
  name: string;
};
export const IngredientSearch = ({
  onSelect,
  onDeselect,
  ingredients,
  isDisabled = false,
  mode,
  additionalCss,
  defaultValue,
  allowClear = false,
  onClear,
  ingredientCompositions = [],
  setValueAsJson = false,
  className = '',
}: IngredientSearchProps) => {
  const ingredientsByCategory = groupBy(ingredients, 'category.name');
  ingredientsByCategory.ingredientCompositions;
  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      disabled={isDisabled}
      allowClear={allowClear}
      onClear={onClear}
      placeholder="Search inputs"
      optionFilterProp="value"
      autoClearSearchValue
      onSelect={onSelect}
      css={[
        css`
          width: 100%;
          margin-bottom: 8px;
          margin: 12px 0px;
          .ant-select-selection-item,
          .ant-select-selection-item-content {
            white-space: break-spaces;
            word-break: break-word;
          }
        `,
        additionalCss,
      ]}
      mode={mode}
      onDeselect={onDeselect}
      className={className}
    >
      {Object.entries(ingredientsByCategory).map(([category, ings], idx) => (
        <Select.OptGroup key={idx} label={category}>
          {ings.map(ing => (
            <Option
              value={
                setValueAsJson
                  ? JSON.stringify({
                      type: 'ingredient',
                      name: ing.ingredient.name,
                    })
                  : ing.ingredient.name
              }
              key={ing.ingredient.name}
            >
              <EllipsisMiddle style={{ width: '90%' }} suffixCount={30}>
                {ing.ingredient.name}
              </EllipsisMiddle>
            </Option>
          ))}
        </Select.OptGroup>
      ))}
      {ingredientCompositions?.length > 0 && (
        <Select.OptGroup
          key={'ingredient-compositions'}
          label="Ingredient Compositions"
        >
          {ingredientCompositions.map(composition => {
            return (
              <Option
                value={
                  setValueAsJson
                    ? JSON.stringify({
                        type: 'composition',
                        name: composition.name,
                      })
                    : composition.name
                }
                key={composition.name}
              >
                <EllipsisMiddle style={{ width: '90%' }} suffixCount={30}>
                  {composition.name}
                </EllipsisMiddle>
              </Option>
            );
          })}
        </Select.OptGroup>
      )}
    </Select>
  );
};
