/** @jsxImportSource @emotion/react */
import { VariableType } from '@prisma/client';
import React, { useState, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { ExpandAltOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { AccuracyIconVert } from '../../../../../_shared/components/icon';
import { SimulationProductVersionResultType } from '../../../../../_shared/hooks/use-simulation.hook';
import {
  accuracyBorderedStyle,
  borderedStyle,
  outcomesBorderedStyle,
  StyledTable,
} from './simulation-result-table.styles';
import {
  logEvent,
  TrackableEvent,
} from '../../../../../_shared/tracking/usage-tracker';
import { CategoryDot } from '../../../../../_shared/style';
import { useSession } from '../../../../../_shared/context';
import { Outcome } from '../../context';

import { EllipsisMiddle } from '../../../../../_shared/utils/component';

import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { ExplainImpactModal } from './simulation-impact-modal.component';
import { NumericOutcomeResult, OrdinalOutcomeResult } from '../shared';
import { ProjectFeature } from '../../../../../../../__generated__/globalTypes';
import { formatCostScore } from '../../../../../_shared/utils/component';
import {
  calculateCompositionTotals,
  calculateFormulationCost,
  limitDecimals,
} from '../../../../../_shared/utils/util';
import { IngredientCompositionDrawer } from '../../../../../components/ingredient-composition/ingredient-composition-drawer.component';
import { DesignResultType } from '@root/components/workspaces/adaptive-learning/types';
import camelCase from 'lodash/camelCase';

export const SimulationResultTable = ({
  product: currentProduct,
  benchmark,
  outcomes,
  hideExplainImpactLink = false,
}: {
  product: SimulationProductVersionResultType;
  outcomes: Outcome[];
  benchmark?: SimulationProductVersionResultType;
  hideExplainImpactLink?: boolean;
}) => {
  const { currentProject } = useSession();
  const {
    iteration,
    simulationProductVersions,
    productColors,
    performanceHistory,
  } = useWorkspace();
  const [showImpactModal, setShowImpactModal] = useState(false);
  const [
    showIngredientCompositionDrawer,
    setShowIngredientCompositionDrawer,
  ] = useState<boolean>(false);
  const [selectedCompositionId, setSelectedCompositionId] = useState<string>();
  const [selectedProduct, setSelectedProduct] = useState<DesignResultType>();
  const productIsBenchmark: boolean =
    currentProduct?.productVersion.isBenchmark ?? false;

  const handleOpenDrawer = (
    ingredientCompositionId: string,
    product: SimulationProductVersionResultType
  ) => {
    // The way we structure DesignResults is different than productVersions
    // We need to reformat to match the DesignResultType
    const formattedFormulation = {
      productName: product?.productVersion?.name,
      isBenchmark: false,
      isGenerated: false,
      turingScore: 1,
      outcomes: [],
      formulation: {
        name: product?.productVersion?.name,
        productName: product?.productVersion?.name,
        ingredientCompositionTotals:
          product?.productVersion?.formulation?.ingredientCompositionTotals,
        formulation: Object.entries(
          product?.productVersion.formulation?.quantities ?? {}
        ).map(([key, value]) => {
          // Find the ingredient from the ingredient list
          let ingredient = currentProject?.ingredientList?.find(
            i => String(i.ingredient.id) === String(key)
          );
          return {
            name: ingredient?.ingredient.name,
            value,
          };
        }),
      },
      scores: {
        score: 0,
        total_desirability: 0,
        penalty: 0,
        desirability_components: [],
      },
    };
    setSelectedCompositionId(ingredientCompositionId);
    setSelectedProduct(formattedFormulation as DesignResultType);
    setShowIngredientCompositionDrawer(true);
  };
  // use the td instead of header for column width
  const defaultColWidth = '150px';

  const projectHasPricing = currentProject?.features.some(
    f => f.feature === ProjectFeature.PRICING
  );

  const hasMLApiOutcomes =
    currentProduct?.outcomes && currentProduct.outcomes.length > 0;

  const showMLApiOutcomes: boolean | undefined =
    productIsBenchmark === false && hasMLApiOutcomes;

  /**
   * Performance History is enabled if the product has multiple simulations to compare against
   * Or if there is a benchmark to compare against
   */
  const isPerformanceHistoryDisabled = useMemo(() => {
    let isDisabled = !benchmark;

    //This is O(n^2) so only running if necessary
    if (isDisabled && performanceHistory) {
      //See if this product has multiple simulations
      const numberOfSims =
        performanceHistory.iteration?.simulations.reduce((count, sim) => {
          if (
            sim.simulationProductVersion.find(
              pv =>
                pv.productVersion.name === currentProduct?.productVersion.name
            )
          ) {
            return count + 1;
          }
          return count;
        }, 0) ?? 0;

      //breaking this out for readability
      //Keep disabled if there are not more than 1 simulations
      isDisabled = numberOfSims <= 1;
    }
    return isDisabled;
  }, [
    benchmark,
    performanceHistory,
    currentProduct,
    simulationProductVersions,
  ]);

  const historyFeatureEnabled = currentProject?.features.some(
    f => f.feature === ProjectFeature.PERFORMANCE_HISTORY
  );

  const showModalButton =
    !currentProduct?.productVersion.isBenchmark &&
    historyFeatureEnabled &&
    !hideExplainImpactLink;

  const getOutcomeDetails = (o: Outcome) => {
    const { outcomeType } = o;

    return productsToShow.map(sim => {
      let view;
      switch (outcomeType) {
        case VariableType.NUMERIC: {
          view = (
            <td
              css={borderedStyle}
              key={sim.productVersion.name + outcomeType + 'ResTable'}
            >
              <NumericOutcomeResult
                outcome={o}
                sim={sim}
                benchmark={benchmark}
              />
            </td>
          );

          break;
        }
        case VariableType.ORDINAL: {
          view = (
            <td
              css={outcomesBorderedStyle}
              key={sim.productVersion.name + outcomeType + 'ResTable'}
            >
              <OrdinalOutcomeResult outcome={o} sim={sim} />
            </td>
          );
          break;
        }
        case VariableType.CATEGORICAL:
          view = (
            <td
              css={outcomesBorderedStyle}
              key={sim.productVersion.name + outcomeType + 'ResTable'}
            >
              <OrdinalOutcomeResult outcome={o} sim={sim} />
            </td>
          );
          break;
        default:
          view = <div />;
          break;
      }

      return view;
    });
  };

  const getOutcomeAccuracy = (o: Outcome) => {
    const { targetVariable, products } = o;

    return (
      <td css={accuracyBorderedStyle}>
        <AccuracyIconVert
          targetVariable={targetVariable}
          reliability={
            products.get(currentProduct?.productVersion.name ?? '')?.reliability
          }
          applicability={
            products.get(currentProduct?.productVersion.name ?? '')?.accuracy
          }
        />
      </td>
    );
  };

  //This component is being used in too many very specific ways
  //It needs to be broken apart and made more generic
  const productsToShow =
    currentProduct && !productIsBenchmark
      ? [currentProduct]
      : simulationProductVersions.filter(
          sim =>
            // Show benchmark in all tabs
            sim.productVersion.isBenchmark ||
            // Show all products in benchmark tab
            productIsBenchmark ||
            // Show only selected product in non-benchmark tab
            sim.productVersion.name === currentProduct?.productVersion.name
        );

  useMemo(
    () =>
      productsToShow.forEach(spv => {
        let compTotals = calculateCompositionTotals(
          spv?.productVersion?.formulation?.quantities ?? {},
          currentProject?.ingredientList ?? [],
          currentProject?.ingredientComposition ?? []
        );
        if (spv.productVersion.formulation) {
          spv.productVersion.formulation.ingredientCompositionTotals = compTotals;
        }
        if (
          spv.productVersion.cost === null ||
          spv.productVersion.cost === undefined
        ) {
          let cost = calculateFormulationCost(
            spv?.productVersion?.formulation?.quantities ?? {},
            currentProject?.ingredientList ?? []
          );
          spv.productVersion.cost = cost;
        }
      }),
    [
      productsToShow,
      currentProject?.ingredientComposition,
      currentProject?.ingredientList,
    ]
  );

  const hasIngredientCompositionValues = productsToShow.some(
    p =>
      p?.productVersion?.formulation?.ingredientCompositionTotals?.length ??
      0 > 0
  );

  return (
    <div
      style={{
        overflowX: 'auto',
      }}
    >
      <StyledTable
        style={{
          borderSpacing: '0px',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            {/* NOTE: Extra column in beginning and end of table so that it fills and centers the table */}
            <th>&nbsp;</th>
            <th
              style={{
                width: defaultColWidth,
              }}
            >
              <div
                style={{
                  width: defaultColWidth,
                }}
              >
                &nbsp;
              </div>
            </th>

            {productsToShow.map(sim => {
              return (
                <th
                  css={borderedStyle}
                  style={{
                    width: defaultColWidth,
                  }}
                  key={sim.productVersion.name + 'PredictedTableHeader'}
                >
                  <div>
                    <CategoryDot color={productColors[sim.columnNumber]} />
                    <EllipsisMiddle
                      style={{
                        width: defaultColWidth,
                        textAlign: 'left',
                      }}
                      suffixCount={5}
                    >
                      {sim.productVersion.name}
                    </EllipsisMiddle>
                  </div>
                </th>
              );
            })}

            {showMLApiOutcomes && (
              <th
                css={borderedStyle}
                style={{
                  width: defaultColWidth,
                }}
              >
                <div>Confidence</div>
              </th>
            )}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {currentProduct?.outcomes?.length === 0 &&
            outcomes.map(outcome => {
              /**
               * Jar models do not pass back outcomes on the simulationProduct.
               * Therefore we need to get it from the workspace which caluclates them from the simualtion itself.
               */
              const { targetVariable, outcomeType } = outcome;
              return (
                <tr key={camelCase(targetVariable + 'ResultTable')}>
                  <td>&nbsp;</td>
                  <td className="targetVarCell">{targetVariable}</td>
                  <React.Fragment key={outcomeType + targetVariable}>
                    {getOutcomeDetails(outcome)}
                  </React.Fragment>
                  {showMLApiOutcomes && getOutcomeAccuracy(outcome)}
                  <td>&nbsp;</td>
                </tr>
              );
            })}
          {currentProduct?.outcomes
            ?.sort((a, b) => {
              //Really bad: Need to associate the SimulationProductOutcome with the Outcome record
              const orderA =
                currentProject?.activeModel?.outcomes?.find(
                  mo => mo.targetVariable === a.outcomeName
                )?.displayOrder ?? 0;

              const orderB =
                currentProject?.activeModel?.outcomes?.find(
                  mo => mo.targetVariable === b.outcomeName
                )?.displayOrder ?? 0;
              return orderA - orderB;
            })
            .map(outcome => {
              const { outcomeName, outcomeType } = outcome;
              let workspaceOutcome = outcomes.find(
                o => o.targetVariable === outcomeName
              );
              if (!workspaceOutcome) {
                /* If an updated model removes an outcome from the project,
               we still want to be able to display it the simulation history.
               Display components expect an Outcome data structure so we convert below.
               */
                const dist: Map<string, number> = new Map();
                [...(outcome.distribution ?? [])].forEach(d => {
                  dist.set(d.state, d.probability);
                });

                const products = new Map();
                products.set(currentProduct?.productVersion.name, {
                  value: outcome.value,
                  accuracy: outcome.applicability,
                  distribution: dist,
                  reliability: outcome.reliability,
                });

                workspaceOutcome = {
                  targetVariable: outcomeName,
                  outcomeType,
                  products,
                };
              }
              return (
                <tr key={camelCase(outcomeName + 'ResultTable')}>
                  <td>&nbsp;</td>
                  <td className="targetVarCell">{outcomeName}</td>
                  <React.Fragment key={outcomeType + outcomeName}>
                    {workspaceOutcome && getOutcomeDetails(workspaceOutcome)}
                  </React.Fragment>
                  {showMLApiOutcomes &&
                    workspaceOutcome &&
                    getOutcomeAccuracy(workspaceOutcome)}
                  <td>&nbsp;</td>
                </tr>
              );
            })}
          {projectHasPricing && (
            <tr key="costResultTable">
              <td>&nbsp;</td>
              <td className="targetVarCell">Cost</td>
              {productsToShow.map(sim => (
                <td key={sim.productVersion.name + 'Cost Score'}>
                  {sim.productVersion.cost
                    ? formatCostScore(
                        sim.productVersion.cost ?? 0,
                        currentProject?.costMeasurementUnit,
                        currentProject?.monetaryUnit
                      )
                    : ''}
                </td>
              ))}
              <td>&nbsp;</td>
            </tr>
          )}
          {hasIngredientCompositionValues &&
            currentProject?.ingredientComposition?.[0] && (
              <tr>
                <td>&nbsp;</td>
                <td
                  className="targetVarCell"
                  style={{ marginLeft: -20, whiteSpace: 'nowrap' }}
                >
                  <strong>Ingredient Composition</strong>
                </td>
              </tr>
            )}
          {hasIngredientCompositionValues &&
            currentProject?.ingredientComposition?.map((ic, icIndex) => (
              <tr key={icIndex}>
                <td>&nbsp;</td>
                <td className="targetVarCell">{ic.name}</td>
                {productsToShow.map((p, pIndex) => {
                  const total = p?.productVersion?.formulation?.ingredientCompositionTotals?.find(
                    ict => ict.name === ic.name
                  )?.total;
                  const formattedTotal = limitDecimals(Number(total));
                  return (
                    <td
                      style={{ cursor: 'pointer' }}
                      key={pIndex}
                      onClick={() => handleOpenDrawer(ic.id, p)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                      >
                        {formattedTotal}%{' '}
                        <Tooltip title="Click to see ingredient composition details">
                          <ExpandAltOutlined style={{ fontSize: '20px' }} />
                        </Tooltip>
                      </div>
                    </td>
                  );
                })}
                <td>&nbsp;</td>
              </tr>
            ))}
        </tbody>
      </StyledTable>
      {showModalButton && (
        <Tooltip
          title={
            isPerformanceHistoryDisabled
              ? 'Please run simulations to start tracking virtual formulation changes'
              : 'Shows you the formulation changes for each of your virtual prototypes'
          }
        >
          <Button
            type="link"
            disabled={isPerformanceHistoryDisabled}
            onClick={() => {
              logEvent(TrackableEvent.PERFORMANCE_HISTORY_OPENED, {
                simulationId: iteration?.simulations[0].id,
              });
              setShowImpactModal(true);
            }}
          >
            <QuestionCircleFilled /> Track Formulation Changes
          </Button>
        </Tooltip>
      )}
      {showImpactModal && (
        <ExplainImpactModal
          onClose={() => {
            logEvent(TrackableEvent.PERFORMANCE_HISTORY_CLOSED, {
              simulationId: iteration?.simulations[0].id,
            });
            setShowImpactModal(false);
          }}
          benchmark={benchmark}
          simHistory={performanceHistory?.iteration?.simulations || []}
        />
      )}
      {selectedProduct && (
        <IngredientCompositionDrawer
          formulation={selectedProduct}
          open={showIngredientCompositionDrawer}
          compositionId={selectedCompositionId ?? ''}
          onClose={() => setShowIngredientCompositionDrawer(false)}
        />
      )}
    </div>
  );
};
