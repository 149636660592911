import { regexCaseInsensitiveGlobalMatch } from '../../_shared/utils/util';
import cytoscape, {
  CollectionReturnValue,
  Core,
  EdgeSingular,
} from 'cytoscape';
import { v4 as uuid } from 'uuid';
import {
  ExpertKnowledge,
  GraphEdge,
  GraphNode,
  Outcome,
} from '__generated__/globalTypes';
import {
  EdgeDef,
  NodeDef,
  NodeData,
  EdgeRank,
  CytoscapeNodeSingular,
  CytoscapeEdgeSingular,
  NodePolarity,
} from '../../../types/graph-data.types';
import cloneDeep from 'lodash/cloneDeep';

// cytoscape-node-html-label has an insufficient d.ts file
// eslint-disable-next-line
require('cytoscape-node-html-label')(cytoscape);

const NODE_SIZE = 24;
const EDGE_MAX_SIZE = 22;
const EDGE_MIN_SIZE = 1;

const POSITIVE_DRIVER_CENTER_NODE_ID = 'positive-driver-center-node';
const NEGATIVE_DRIVER_CENTER_NODE_ID = 'negative-driver-center-node';

const INGREDIENT_TO_INGREDIENT_CLASS = 'ingredient-to-ingredient-edge';

const CenterOutcomeSpacingFactorMap = new Map([
  [1, 0.12],
  [2, 0.19],
  [3, 0.23],
  [4, 0.26],
  [5, 0.32],
]);
export interface GraphProps {
  nodes: GraphNode[];
  edges: GraphEdge[];
  activeOutcome: string | undefined;
  ingredientId?: string;
  expertKnowledge?: ExpertKnowledge[];
  setHoveredOrSelectedNode: (value?: CytoscapeNodeSingular) => void;
  outcomes: Outcome[];
  onOutcomeClick: (outcomeName: string) => void;
}

export class GraphV2 {
  props: GraphProps;

  centerNode?: CytoscapeNodeSingular;

  cy!: Core;

  edges: EdgeDef[];

  nodes = new Map<string, NodeDef>();

  useExpandedCenterNodeLayout: boolean = false;

  /*
   * Allows the Expert Integration to block graph hover & click events
   */
  graphStateImmutable: boolean = false;

  get graphContainerId() {
    return this.elementId;
  }

  get ingredients() {
    return Array.from(this.nodes.values()).map(n => n.data);
  }

  filterWeight: number = Number.NEGATIVE_INFINITY;

  /**
   * A map of all the highlighted nodes that have an entropy value
   * "absoluteWeight" above the "filterWeight"
   */
  topDriversMap = new Map<string, CytoscapeNodeSingular>();

  edgeRanks: EdgeRank[] = [];

  hoveredNode?: CytoscapeNodeSingular & { data(): NodeData };

  loadedAt: number;

  /**
   * The html element ID of the component to draw the graph inside.
   */
  elementId: string;

  constructor(props: GraphProps) {
    this.loadedAt = Date.now();

    this.props = props;
    this.elementId = uuid();

    const {
      processedEdges,
      nodeIdsWithDirectConnectionToOutcome,
    } = this.processEdges();
    this.edges = processedEdges;
    this.nodes = this.processNodes(nodeIdsWithDirectConnectionToOutcome);

    this.updateRelativeEdgeSizes();
    this.setEdgeRank();

    this.useExpandedCenterNodeLayout = this.props.outcomes.length > 5;
  }

  calculateRelativeEdgeWeight(data: EdgeDef[]) {
    const relativeWeights = new Map<string, number>();
    const allValuesAsArray: number[] = data.map(d => Math.abs(d.data.weight));

    const maxRelativeSize = Math.max(...allValuesAsArray);
    const minRelativeSize = Math.min(...allValuesAsArray);
    const sizeDiff = maxRelativeSize - minRelativeSize;

    // Adjust relative values so they're always on a scale of 1-100
    const scaleAdjustment = 100 / maxRelativeSize;

    data.forEach(d => {
      const relativeWeight =
        (Math.abs(d.data.weight) - minRelativeSize / sizeDiff) *
        scaleAdjustment;
      const relativeWeightPercentage = relativeWeight / 100;

      const size = Math.max(
        Math.floor(relativeWeightPercentage * EDGE_MAX_SIZE),
        EDGE_MIN_SIZE
      );

      const key = `${(d as EdgeDef).data.target}_${(d as EdgeDef).data.source}`;

      relativeWeights.set(key, size);
    });

    return relativeWeights;
  }

  processEdges(): {
    processedEdges: EdgeDef[];
    nodeIdsWithDirectConnectionToOutcome: string[];
  } {
    const processedEdges: EdgeDef[] = [];
    const nodeIdsWithDirectConnectionToOutcome: string[] = [];

    this.props.edges.forEach(edge => {
      const edgeSourceIsOutcome = edge.source === this.props.activeOutcome;
      const edgeTargetIsOutcome = edge.target === this.props.activeOutcome;

      const isIngredientToIngredientEdge =
        !edgeSourceIsOutcome && !edgeTargetIsOutcome;

      const edgeClasses = ['bezier'];

      if (isIngredientToIngredientEdge) {
        edgeClasses.push(INGREDIENT_TO_INGREDIENT_CLASS);
      } else {
        const ingredientId = edgeSourceIsOutcome ? edge.target : edge.source;
        nodeIdsWithDirectConnectionToOutcome.push(ingredientId);
      }

      processedEdges.push({
        data: {
          source: edge.source,
          target: edge.target,
          weight: edge.weight,
          size: 0, // Real size gets set later
        },
        classes: edgeClasses.join(' '),
      });
    });

    return { processedEdges, nodeIdsWithDirectConnectionToOutcome };
  }

  processNodes(nodeIdsWithDirectConnectionToOutcome: string[]) {
    const processedNodes = new Map<string, NodeDef>();

    this.props.nodes.forEach(node => {
      processedNodes.set(node.name, {
        data: {
          id: node.name,
          name: node.name,
          weight: Math.abs(node.weight),
          polarity: node.weight < 0 ? 'negative' : 'positive',
          size: 0, // Real size gets set later,
          isDirectlyConnectedToOutcome: nodeIdsWithDirectConnectionToOutcome.includes(
            node.name
          ),
        },
      });
    });

    return processedNodes;
  }

  /*
    Recalculates the relative node/edge weights and determines if the slim layout should be used
    This should be run any time a node or edge is added to the graph
  */
  updateRelativeEdgeSizes() {
    const updatedEdges: EdgeDef[] = [];

    const relativeEdgeWeights = this.calculateRelativeEdgeWeight(
      Array.from(this.edges)
    );

    this.edges.forEach(edge => {
      const size = relativeEdgeWeights.get(
        `${edge.data.target}_${edge.data.source}`
      );

      if (size !== undefined) {
        const updatedEdge = { ...edge };

        updatedEdge.data.size = size;
        updatedEdges.push(updatedEdge);
      }
    });

    this.edges = updatedEdges;
  }

  setEdgeRank() {
    const edgeArr = Array.from(this.edges.values());
    const totalWeight = edgeArr.reduce((w, edge) => w + edge.data.weight, 0);

    this.edgeRanks = edgeArr
      .sort((a, b) => b.data.weight - a.data.weight)
      .map((edge, index) => {
        const { weight, source, target } = edge.data;
        const percentage = (weight / totalWeight) * 100;
        return {
          rank: index + 1,
          sourceEdge: source,
          targetEdge: target,
          weight: percentage.toFixed(2),
        };
      });
  }

  upsertNode(node: { data: NodeData; classes?: string }): NodeDef {
    const nodeKey = node.data.name !== '' ? node.data.name : node.data.id;

    this.nodes.set(nodeKey, node);
    return node;
  }

  removeEdge(edgeTarget: string, edgeSource: string) {
    this.edges = this.edges.filter(
      edge =>
        !(edge.data.target === edgeTarget && edge.data.source === edgeSource) &&
        !(edge.data.target === edgeSource && edge.data.source === edgeTarget) // Check both edge directions
    );
  }

  upsertEdge(edge: EdgeDef) {
    this.removeEdge(edge.data.target, edge.data.source);
    this.edges = [...cloneDeep(this.edges), edge];
  }

  upsertExpertKnowledge(expertKnowledge: {
    childName: string;
    polarity: NodePolarity;
  }) {
    const { childName, polarity } = expertKnowledge;
    let childNode = this.nodes.get(childName);

    childNode = this.upsertNode({
      data: {
        id: childName,
        name: childName,
        weight:
          childNode?.data.weight !== undefined ? childNode.data.weight : 27.5,
        isExpertKnowledge: true,
        size: childNode?.data.size !== undefined ? childNode.data.size : 27.5,
        polarity: polarity,
      },
    });

    this.upsertEdge({
      data: {
        weight: 3,
        source: this.props.activeOutcome!,
        target: childName,
        size: 3,
      },
      classes: 'bezier',
    });

    this.updateRelativeEdgeSizes();
  }

  /**
   * If an ingredientId is specified to the knowledge discovery graph we want to by default hover
   * that node.  However, this hover can be lost immediately if the mouse happens to move slightly off
   * of the node due to clicking on the button that brought up the knowledge discovery graph in the first
   * place.  Therefore, do not hover/unhover nodes for the first second or so.
   */
  ignoreEarlyMouseHovering() {
    return this.props.ingredientId && this.loadedAt > Date.now() - 1250;
  }

  createCircularCenterNode(centerOutcomeNodes: CollectionReturnValue): number {
    const { cy } = this;

    //scale the preset spacing factor to a viewport height of 1200
    let additionalPaddingFactor = this.props.expertKnowledge ? 1.1 : 1;

    const centerOutcomesSpacingFactor =
      CenterOutcomeSpacingFactorMap.get(centerOutcomeNodes.length)! *
      (1200 / cy.height()) *
      additionalPaddingFactor;

    const centerOutcomeNodesLayout = centerOutcomeNodes.layout({
      name: 'circle',
      spacingFactor: centerOutcomesSpacingFactor,
    });
    centerOutcomeNodesLayout.run();

    const centerOutcomeNodesBoundingBox = centerOutcomeNodes.boundingBox({});

    let maxBoundingBoxDimension = Math.max(
      centerOutcomeNodesBoundingBox.h,
      centerOutcomeNodesBoundingBox.w
    );

    if (centerOutcomeNodes.length === 1) {
      maxBoundingBoxDimension = Math.max(
        centerOutcomeNodes.first().height(),
        centerOutcomeNodes.first().width()
      );
    }

    const centerNodePadding = centerOutcomeNodes.length < 4 ? 1.2 : 1.15;

    this.centerNode = cy.getElementById('center-parent-node');

    this.centerNode.position({
      x: cy.width() / 2,
      y: cy.height() / 2,
    });

    this.centerNode.lock();

    const centerNodeSize = maxBoundingBoxDimension * centerNodePadding;

    this.centerNode.style('width', centerNodeSize);
    this.centerNode.style('height', centerNodeSize);

    return centerNodeSize;
  }

  createVerticalCenterNode(centerOutcomeNodes: CollectionReturnValue): number {
    const { cy } = this;

    const spacingFactor = 0.055;
    // scale the spacing factor to a viewport height of 1200
    const scaledSpacingFactor = spacingFactor * (1200 / cy.height());

    const centerOutcomeNodesLayout = centerOutcomeNodes.layout({
      name: 'grid',
      cols: 1,
      spacingFactor: scaledSpacingFactor * centerOutcomeNodes.length,
    });
    centerOutcomeNodesLayout.run();

    const centerOutcomeNodesBoundingBox = centerOutcomeNodes.boundingBox({});
    const centerNodePadding = 1.1;

    this.centerNode = cy.getElementById('center-parent-node');

    this.centerNode.position({
      x: cy.width() / 2,
      y: cy.height() / 2,
    });

    this.centerNode.lock();

    const centerNodeWidth = centerOutcomeNodesBoundingBox.w * centerNodePadding;

    this.centerNode.style('width', centerNodeWidth);
    this.centerNode.style(
      'height',
      centerOutcomeNodesBoundingBox.h * centerNodePadding
    );

    return centerNodeWidth;
  }

  draw() {
    /*
      Don't render if we haven't loaded any outcomes yet
    */
    if (this.props.outcomes.length === 0) {
      return;
    }

    this.upsertNode({
      data: {
        id: 'center-parent-node',
        name: '',
        weight: 0,
        size: 1,
        layoutOnlyNode: true,
      },
      classes: 'not-hover',
    });

    this.upsertNode({
      data: {
        id: POSITIVE_DRIVER_CENTER_NODE_ID,
        weight: 0,
        name: '',
        size: 1,
        layoutOnlyNode: true,
      },
      classes: 'transparent-source-node',
    });

    this.upsertNode({
      data: {
        id: NEGATIVE_DRIVER_CENTER_NODE_ID,
        weight: 0,
        name: '',
        size: 1,
        layoutOnlyNode: true,
      },
      classes: 'transparent-source-node',
    });

    this.props.outcomes.forEach(outcome => {
      this.upsertNode({
        data: {
          id: outcome.targetVariable,
          weight: 0,
          name: outcome.targetVariable,
          isTargetVariable: true,
          size: 1,
        },
        classes: `center-outcome-node ${
          outcome.targetVariable === this.props.activeOutcome
            ? 'center-outcome-node-selected'
            : ''
        }`,
      });
    });

    const opts: cytoscape.CytoscapeOptions & {
      layout: { transform?: any };
      minDist?: number;
      padding?: number;
      animate?: boolean;
    } = {
      container: document.getElementById(this.elementId),
      zoom: 1.2,
      elements: [...this.nodes.values(), ...cloneDeep(this.edges)],

      boxSelectionEnabled: false,
      autounselectify: true,
      minDist: 25,
      // maxExpandIterations: 9,
      padding: 10,
      animate: false,

      layout: {
        name: 'preset',
        fit: false,
      },

      style: [
        {
          selector: 'node',
          style: {
            color: '#FFF',
            label: 'data(name)',
            'font-family': 'Inter',
            'font-size': '10px',
            'overlay-opacity': 0,
            'background-color': '#fff',
          },
        },
        {
          selector: 'node[!isTargetVariable][!layoutOnlyNode]',
          style: {
            'background-color': '#FFF',
            shape: 'round-rectangle',
            'text-halign': 'center',
            'text-valign': 'center',
            color: '#202020',
            height: `${NODE_SIZE}px`,
            width: (node: any) => node.data('name').length * 8,
            'text-margin-y': 0,
          },
        },
        {
          selector: 'node[layoutOnlyNode]',
          style: {
            width: 0.1,
            height: 0.1,
          },
        },
        {
          selector: 'node.hover-in-background',
          style: {
            'background-blacken': 0.8,
            opacity: 0.3,
          },
        },
        {
          selector: 'node[label]',
          style: {
            'z-index': Number.MAX_VALUE,
            'text-wrap': 'ellipsis',
            'text-max-width': '120px',
            'font-weight': 700,
          },
        },
        {
          selector: 'node.transparent-source-node',
          style: {
            'background-opacity': 0,
          },
        },
        {
          selector: '#center-parent-node',
          style: {
            'z-index': 1,
            'background-color': '#272727',
            'background-opacity': 1,
            shape: this.useExpandedCenterNodeLayout
              ? 'round-rectangle'
              : 'ellipse',
          },
        },
        {
          selector: 'node.center-outcome-node',
          style: {
            'background-opacity': 1,
            'background-color': '#3B3B3B',
            'background-image': 'none',
            width: this.useExpandedCenterNodeLayout ? '141px' : 125,
            height: this.useExpandedCenterNodeLayout ? '47px' : 125,
            'text-halign': 'center',
            'text-valign': 'center',
            'z-index': Number.MAX_VALUE,
            'font-size': this.useExpandedCenterNodeLayout ? '12px' : '14px',
            'text-wrap': 'ellipsis',
            'text-max-width': '110px',
            'font-weight': 700,
            shape: this.useExpandedCenterNodeLayout
              ? 'round-rectangle'
              : 'ellipse',
          },
        },
        {
          selector: 'node.focused-node[isTargetVariable]',
          style: {
            'text-wrap': 'wrap',
            'text-max-width': '325px',
            'text-background-color': '#3B3B3B',
            'text-background-padding': '10px',
            'text-background-opacity': 1,
          },
        },
        {
          selector: 'node.center-outcome-node-selected',
          style: {
            'border-color': '#017AFF',
            'border-width': '8',
          },
        },
        {
          selector: 'node.full-text',
          style: {
            'z-index': Number.MAX_VALUE,
            'text-wrap': 'none',
            'text-max-width': '1000px',
          },
        },
        {
          selector: 'node.focused-node, node.search-highlight',
          style: {
            'font-weight': 'bold',
          },
        },
        {
          selector: 'node.search-highlight',
          style: {
            color: '#fff',
            'text-background-padding': '4px',
            'text-background-shape': 'roundrectangle',
            'text-background-color': '#6C47FF',
            'text-background-opacity': 1,
          },
        },
        {
          selector: 'node.focused-node', // Visible node when other nodes are in a hidden state (selected/hovered node)
          style: {
            'text-margin-y': -1,
          },
        },
        {
          selector: 'edge.positive-driver',
          style: {
            'line-fill': 'linear-gradient',
            'line-gradient-stop-colors': ['#0085FF', '#00F69E'],
            'line-gradient-stop-positions': [0, 100],
            'curve-style': 'unbundled-bezier',
            'line-cap': 'round',
            'target-endpoint': '270deg',
            width: 'data(size)',
            opacity: 0.5,
          },
        },
        {
          selector: 'edge.negative-driver',
          style: {
            'line-fill': 'linear-gradient',
            'line-gradient-stop-colors': ['#FF6B00', '#FF004D'],
            'line-gradient-stop-positions': [0, 100],
            'curve-style': 'unbundled-bezier',
            'line-cap': 'round',
            'target-endpoint': '90deg',
            width: 'data(size)',
            opacity: 0.5,
          },
        },
        {
          selector: `edge.${INGREDIENT_TO_INGREDIENT_CLASS}`,
          style: {
            'curve-style': 'straight',
            opacity: 0.25,
          },
        },
        {
          selector: 'edge.hover-in-background',
          style: {
            opacity: 0.1,
          },
        },
        {
          selector: 'edge.focused-edge', // Visible edge when other edges are in a hidden state
          style: {
            'line-color': '#FFF',
            'curve-style': 'unbundled-bezier',
            'line-style': 'solid',
            opacity: 1,
          },
        },
        {
          selector: `edge.focused-edge.${INGREDIENT_TO_INGREDIENT_CLASS}`,
          style: {
            'curve-style': 'straight',
          },
        },
      ],

      wheelSensitivity: 0.05,
    };

    const cy = (this.cy = cytoscape(opts));
    cy.center();
    cy.edges().addClass('not-hover');

    const centerOutcomeNodes = cy.$('node.center-outcome-node');

    let centerNodeWidth: number | undefined;

    if (this.useExpandedCenterNodeLayout) {
      centerNodeWidth = this.createVerticalCenterNode(centerOutcomeNodes);
    } else {
      centerNodeWidth = this.createCircularCenterNode(centerOutcomeNodes);
    }

    const positiveDriverCenterNode = cy.getElementById(
      POSITIVE_DRIVER_CENTER_NODE_ID
    );

    positiveDriverCenterNode.position({
      x: cy.width() / 2 + centerNodeWidth / 2,
      y: cy.height() / 2,
    });

    const negativeDriverCenterNode = cy.getElementById(
      NEGATIVE_DRIVER_CENTER_NODE_ID
    );

    negativeDriverCenterNode.position({
      x: cy.width() / 2 - centerNodeWidth / 2,
      y: cy.height() / 2,
    });

    const positiveDriverNodes = cy.$('[polarity = "positive"]');
    const negativeDriverNodes = cy.$('[polarity = "negative"]');

    cy.nodes().forEach(node => {
      if (!node.data().layoutOnlyNode && !node.data().isTargetVariable) {
        let nodeHasConnectionToOutcome = false;

        const downstreamNodeEdges = node.successors(
          '[isTargetVariable],[layoutOnlyNode]'
        );
        const upstreamNodeEdges = node.predecessors(
          '[isTargetVariable],[layoutOnlyNode]'
        );
        nodeHasConnectionToOutcome =
          downstreamNodeEdges.length > 0 || upstreamNodeEdges.length > 0;

        node.data().isConnectedToOutcome = nodeHasConnectionToOutcome;

        if (!nodeHasConnectionToOutcome) {
          cy.remove(node.connectedEdges());
          cy.remove(node);
        }
      }
    });

    const updateNodeEdges = (
      edge: EdgeSingular,
      type: 'positive' | 'negative'
    ) => {
      if (!edge.hasClass(INGREDIENT_TO_INGREDIENT_CLASS)) {
        const newNodeId =
          type === 'positive'
            ? positiveDriverCenterNode.id()
            : negativeDriverCenterNode.id();

        const edgeSourceIsOutcome =
          edge.data().source === this.props.activeOutcome;
        const edgeTargetIsOutcome =
          edge.data().target === this.props.activeOutcome;

        const edgeSourceIsNewNodeId = edge.data().source === newNodeId;
        const edgeTargetIsNewNodeId = edge.data().target === newNodeId;

        let keyToUpdate: string | undefined;

        // Check if we need to move the edge source/target
        if (edgeSourceIsOutcome || edgeTargetIsOutcome) {
          keyToUpdate =
            edge.data().source === this.props.activeOutcome
              ? 'source'
              : 'target';
        } else if (!edgeSourceIsNewNodeId && !edgeTargetIsNewNodeId) {
          keyToUpdate = !edgeSourceIsNewNodeId ? 'source' : 'target';
        }

        if (keyToUpdate) {
          edge.move({
            [keyToUpdate]: newNodeId,
          });
        }
        edge.addClass(`${type}-driver`);
      }
    };

    positiveDriverNodes
      .connectedEdges()
      .forEach(edge => updateNodeEdges(edge, 'positive'));
    negativeDriverNodes
      .connectedEdges()
      .forEach(edge => updateNodeEdges(edge, 'negative'));

    cy.getElementById('center-');

    if (!this.useExpandedCenterNodeLayout) {
      /*
        The centerDriverNodes boundingBox is off when centerDriverNodes.length > 1 && length is odd
        Shift all of the center nodes a preset amount when that happens
      */

      const centerOutcomeNodesBoundingBox = centerOutcomeNodes.boundingBox({});
      if (
        centerOutcomeNodes.length > 1 &&
        centerOutcomeNodes.length % 2 !== 0
      ) {
        let yShiftBuffer = 16;

        if (centerOutcomeNodes.length === 5) {
          yShiftBuffer = 11;
        }

        const yCenter =
          (centerOutcomeNodesBoundingBox.y1 +
            centerOutcomeNodesBoundingBox.y2) /
          2;
        centerOutcomeNodes.shift(
          'y',
          this.centerNode!.point('y') - yCenter - yShiftBuffer
        );
      }
    }

    centerOutcomeNodes.lock();

    const driverLayoutSettings: cytoscape.LayoutOptions = {
      name: 'grid',
      cols: 1,
      avoidOverlap: true,
      avoidOverlapPadding: 20,
      sort: (a, b) => {
        // any type casts are required here because Cytoscape's types are incorrect
        if (
          (a.data as any)('isDirectlyConnectedToOutcome') &&
          !(b.data as any)('isDirectlyConnectedToOutcome')
        ) {
          return -1;
        }

        if (
          !(a.data as any)('isDirectlyConnectedToOutcome') &&
          (b.data as any)('isDirectlyConnectedToOutcome')
        ) {
          return 1;
        }

        return (b.data as any)('weight') - (a.data as any)('weight');
      },
    };

    const positiveDriverLayout = positiveDriverNodes.layout(
      driverLayoutSettings
    );
    const negativeDriverLayout = negativeDriverNodes.layout(
      driverLayoutSettings
    );

    positiveDriverLayout.run();
    negativeDriverLayout.run();

    const halfOfViewportWidth = cy.width() / 2;

    const xDistanceFromCenter = halfOfViewportWidth * 0.55;
    const centerNodePosition: number = this.centerNode?.point('x') ?? 0;

    positiveDriverNodes.forEach(node =>
      this.shiftNode(node, centerNodePosition, xDistanceFromCenter, 'right')
    );
    negativeDriverNodes.forEach(node =>
      this.shiftNode(node, centerNodePosition, xDistanceFromCenter, 'left')
    );

    /*
        Updates the edges to have an additional curve
        Taken from a codepen here: https://github.com/cytoscape/cytoscape.js/issues/2579
      */
    const adjustEdgeCurve = function (edge: CytoscapeEdgeSingular) {
      const x0 = edge.source().position('x');
      const x1 = edge.target().position('x');
      const y0 = edge.source().position('y');
      const y1 = edge.target().position('y');
      const x = x1 - x0;
      const y = y1 - y0;
      const z = Math.sqrt(x * x + y * y);

      /*
        Prevent division by 0 and graph crash in instances where something goes wrong
        and the edge.target() === edge.source()
      */
      if (z === 0) {
        return;
      }

      const costheta = x / z;
      const alpha = 0.25;
      const controlPointDistances = [
        -alpha * y * costheta,
        alpha * y * costheta,
      ];
      edge.style('control-point-distances', controlPointDistances);
      edge.style('control-point-weights', [alpha, 1 - alpha]);
    };

    cy.edges().forEach(edge => adjustEdgeCurve(edge));

    const cytoscapeFitPadding = this.props.expertKnowledge ? 70 : 170;
    cy.fit(undefined, cytoscapeFitPadding);
    cy.center();

    //TODO: Set back to a max weight
    //OR calculate what first ring would have

    cy.on('mouseover', 'node', ev => {
      if (!this.ignoreEarlyMouseHovering())
        !this.graphStateImmutable && this.hoverNode(ev.target);
    });

    cy.on('mouseout', 'node', (/*e*/) => {
      if (!this.ignoreEarlyMouseHovering())
        !this.graphStateImmutable && this.unhoverNode();
    });

    cy.on('tap', 'node', ev => {
      const target = ev.target as CytoscapeNodeSingular;
      const selected = target.hasClass('selected');
      cy.nodes().removeClass('selected');

      if (target.data().isTargetVariable && !this.graphStateImmutable) {
        cy.nodes().removeClass('center-outcome-node-selected');
        target.addClass('center-outcome-node-selected');
        this.props.onOutcomeClick(target.id());
      }
    });

    // for debugging
    // eslint-disable-next-line
    (window as any).cy = this.cy;

    const { ingredientId } = this.props;
    if (ingredientId) {
      const filteredNodes = cy
        .nodes()
        .filter((n: CytoscapeNodeSingular) => n.data().id === ingredientId);
      if (filteredNodes.length > 0) {
        this.unhoverNode();
        this.hoverNode(filteredNodes);
      }
    }
  }

  search(text: string) {
    const { cy } = this;

    cy.nodes().removeClass('search-highlight');

    if (text) {
      cy.nodes()
        .filter(
          (n: CytoscapeNodeSingular) =>
            regexCaseInsensitiveGlobalMatch(text, n.data().name) &&
            !n.data().isTargetVariable
        )
        .addClass('search-highlight');
    }
  }

  hoverNode(node: CytoscapeNodeSingular) {
    const { cy } = this;

    /*
      Ignore calls to this function when a node has been excluded from the hover state
    */
    if (node.hasClass('not-hover')) {
      return;
    }

    this.hoveredNode = node;
    this.props.setHoveredOrSelectedNode(node);

    // Don't hide the entire graph if the hovered node is an outcome node
    if (node.data().isTargetVariable) {
      node.addClass('focused-node');
    } else {
      // Hide all nodes except for the center parent node
      cy.elements('[id != "center-parent-node"]').addClass(
        'hover-in-background'
      );
      cy.$('[isTargetVariable]').removeClass('hover-in-background');

      node.removeClass('hover-in-background');

      node.connectedEdges().forEach(edge => {
        const edgeTarget = edge.target();
        const edgeSource = edge.source();

        edgeTarget.addClass('focused-node');
        edgeTarget.removeClass('hover-in-background');
        edgeSource.addClass('focused-node');
        edgeSource.removeClass('hover-in-background');
        edge.addClass('focused-edge');
      });
    }
  }

  unhoverNode() {
    const { hoveredNode, cy } = this;

    // Make sure the graph has been initialized first
    if (cy === undefined || !hoveredNode) {
      return;
    }

    this.filterByNodeWeight(this.filterWeight);
    hoveredNode.removeClass('focused-node');

    if (!hoveredNode.data().isTargetVariable) {
      hoveredNode.connectedEdges().forEach(edge => {
        edge.target().removeClass('focused-node');
        edge.source().removeClass('focused-node');
        edge.removeClass('focused-edge');
      });
    }
    this.hoveredNode = undefined;
    this.props.setHoveredOrSelectedNode(undefined);
  }

  hoverNodeById(nodeId: string) {
    const node = this.cy.getElementById(nodeId);

    if (node.length > 0) {
      this.hoverNode(node);
    }
  }

  shiftNode(
    node: CytoscapeNodeSingular,
    centerPosition: number,
    xDistanceFromCenter: number,
    direction: 'left' | 'right'
  ) {
    const nodeWidth =
      direction === 'left' ? node.width() / 2 : -(node.width() / 2);
    const nodeEdgePosition = node.point('x') + nodeWidth;
    const edgeDistanceFromCenter = centerPosition - nodeEdgePosition;
    const amountToShift =
      direction === 'left'
        ? edgeDistanceFromCenter - xDistanceFromCenter
        : edgeDistanceFromCenter + xDistanceFromCenter;

    node.shift('x', amountToShift);
  }

  filterByNodeWeight(weight: number) {
    const { topDriversMap, cy } = this;

    //Does this actually matter?
    this.filterWeight = weight;

    topDriversMap.clear();
    cy.nodes().each((n: CytoscapeNodeSingular) => {
      const data = n.data();

      if (
        (data?.weight ?? 0) < weight &&
        !data.isTargetVariable &&
        !data.layoutOnlyNode
      ) {
        n.addClass('hover-in-background');
      } else {
        n.removeClass('hover-in-background');
        topDriversMap.set(data.id, n);
      }
    });

    cy.edges().each((e: CytoscapeEdgeSingular) => {
      if (
        !topDriversMap.has(e.source().data().id) ||
        !topDriversMap.has(e.target().data().id)
      ) {
        e.addClass('hover-in-background');
      } else {
        e.removeClass('hover-in-background');
      }
    });
  }
}
