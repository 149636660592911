import { Divider, Form, Input } from 'antd';
import { CompoundedComponent } from 'antd/es/float-button/interface';
import React from 'react';
import './project-input-form.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Rule } from 'antd/es/form';

export interface IProjectFormInputInfo {
  title: string;
  description: string;
  divider: boolean;
  customComponent: React.ReactNode;
  onInputChange: (value: any) => void;
  mandatory?: boolean;
}

export const ProjectInputForm: React.FC<IProjectFormInputInfo> = ({
  title,
  description,
  divider,
  customComponent,
  onInputChange,
  mandatory,
}) => {
  return (
    <div id="ProjectInputForm-box">
      <div className="input-section">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              margin: '5px',
            }}
          >
            {title} {mandatory && <span className="mandatory">*</span>}
          </span>
          <div onChange={onInputChange}>{customComponent}</div>
        </div>
        <p className="information">
          <InfoCircleOutlined
            style={{
              color: '#B3B3B3',
            }}
          />
          {description}
        </p>
      </div>
      {divider && <Divider></Divider>}
    </div>
  );
};
