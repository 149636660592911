import { css } from '@emotion/react';

export const centeredHorizontallyFlexStyle = css({
  display: 'flex',
  justifyContent: 'center',
});

export const rightHorizontallyFlexStyle = css({
  display: 'flex',
  justifyContent: 'end',
});

export const centerVerticallyFlexStyle = css({
  display: 'flex',
  alignItems: 'center',
});

export const spaceEvenlyContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
});

export const fullScreenStyle = css({
  height: '100vh',
  width: '100vw',
});

export const centerInContainer = css`
  ${centerVerticallyFlexStyle};
  ${centeredHorizontallyFlexStyle};
  height: 100%;
  width: 100%;
`;
