/** @jsxImportSource @emotion/react */
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Legend,
  Label,
  Cell,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { useDesign } from '../../../_shared/context/design-context';
import {
  GeneratedFormulationIcon,
  NonBenchmarkExistingIcon,
} from './design-icons.component';
import { css } from '@emotion/react';
import round from 'lodash/round';
import { changeColumnBackground, normalizeClassName } from './design-utils';
import { Colors } from '../../../../iso/colors';
import { useMemo, useState } from 'react';
import { DesignTableSkeleton } from './table-skeleton.component';
const CustomTooltip = (props: {
  payload?: {
    name: string;
    value: string | number;
    payload: {
      name: string;
      value: string | number;
      isBenchmark?: boolean;
    };
  }[];
}) => {
  if (!props.payload || props.payload?.length === 0) {
    return null;
  }
  return (
    <div
      style={{
        border: '#bbb 1.5px solid',
      }}
    >
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        <strong>{props.payload && props.payload[0]?.payload?.name} </strong>
        <br />
        <span
          style={{
            backgroundColor: 'white',
            color: 'grey',
          }}
        >
          {' '}
          {props.payload &&
          props.payload[0]?.payload?.isBenchmark &&
          props.payload[0]?.payload?.name !== 'Benchmark Formulation'
            ? 'Benchmark'
            : ''}
        </span>
      </p>
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {props.payload[0].name}: {props.payload[0].value}
      </p>
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {props.payload[1].name}: {props.payload[1].value}
      </p>
    </div>
  );
};

export const TargetGraph = () => {
  const { designResults, resultTableColumnRef, graphPointRef } = useDesign();

  function getMap(): Map<string, SVGSVGElement> {
    if (!graphPointRef?.current) {
      graphPointRef.current = new Map();
    }
    return graphPointRef.current;
  }

  const [highestDesirability, setHighestDesirability] = useState(1);
  const [lowestDesirability, setLowestDesirability] = useState(0);
  const [highestPenalty, setHighestPenalty] = useState(0);
  const [lowestPenalty, setLowestPenalty] = useState(0);

  const updateRangeValues = (total_desirability: number, penalty: number) => {
    if (highestDesirability < total_desirability) {
      setHighestDesirability(total_desirability);
    }

    if (lowestDesirability > total_desirability) {
      setLowestDesirability(total_desirability);
    }

    if (highestPenalty < penalty) {
      setHighestPenalty(penalty);
    }

    if (lowestPenalty > penalty) {
      setLowestPenalty(penalty);
    }
  };

  const generatedData = useMemo(
    () =>
      designResults
        .filter(r => r.isGenerated)
        .map(result => {
          const { total_desirability, penalty } = result.scores;
          updateRangeValues(total_desirability, penalty);
          return {
            x: total_desirability,
            y: penalty,
            name: result.productName,
          };
        }),
    [designResults]
  );

  const hasGeneratedFormulations = !!generatedData.length;

  const existingData = useMemo(
    () =>
      designResults
        .filter(r => !r.isGenerated)
        .map(result => {
          const { total_desirability, penalty } = result.scores;
          updateRangeValues(total_desirability, penalty);
          return {
            x: total_desirability,
            y: penalty,
            name: result.productName,
            isBenchmark: result.isBenchmark,
          };
        }),
    [designResults]
  );

  const roundedHighestDesirability = round(highestDesirability, 2);
  const roundedHighestPenalty = round(highestPenalty + 1, 2);
  return (
    <ScatterChart width={350} height={350} data={existingData}>
      <CartesianGrid fill="white" />
      <XAxis
        type="number"
        dataKey="x"
        name="Desirability"
        domain={[lowestDesirability, roundedHighestDesirability]}
      >
        <Label
          value="Desirability"
          position="bottom"
          offset={-7}
          style={{ marginBottom: 10 }}
        />
      </XAxis>
      <YAxis
        type="number"
        dataKey="y"
        name="Penalty"
        domain={[lowestPenalty, roundedHighestPenalty]}
      >
        <Label
          value="Penalty"
          offset={15}
          dy={20}
          position="insideLeft"
          angle={-90}
        />
      </YAxis>
      <Legend wrapperStyle={{ paddingTop: 15 }} content={<CustomLegend />} />
      <Tooltip content={<CustomTooltip />} />
      <ReferenceLine y={0.5} strokeDasharray="3 3" strokeOpacity={1} />
      <ReferenceLine x={0.5} strokeDasharray="3 3" strokeOpacity={1} />
      <ReferenceArea
        x1={roundedHighestDesirability / 2}
        x2={roundedHighestDesirability}
        y1={roundedHighestPenalty / 2}
        y2={0}
        strokeOpacity={0.8}
      />
      {/* Weird bug, you need to not render the graph at all if no data points exist otherwise it will render the shape identified here underneath all other points on the other scatter graph */}
      {hasGeneratedFormulations && (
        <Scatter
          name="Recommended"
          data={generatedData}
          shape={props => {
            const { x, y, width, height } = props;
            return (
              <svg
                x={x}
                y={y}
                width={width}
                height={height}
                id={`${normalizeClassName(props.name)}-point`}
                className="scatter-point"
                ref={node => {
                  const map: Map<string, SVGSVGElement> = getMap();
                  const normalizedName = normalizeClassName(props.name);
                  if (map && node && !map.get(normalizedName)) {
                    map.set(normalizedName, node);
                  }
                }}
                onMouseEnter={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: Colors.SWEET_PINK,
                    scrollToColumn: true,
                    addShadow: true,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                onMouseLeave={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: 'white',
                    scrollToColumn: true,
                    addShadow: false,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.35937 1.10729C6.71054 0.580528 7.48458 0.580529 7.83575 1.10729L13.2756 9.26702C13.6686 9.85661 13.246 10.6463 12.5374 10.6463H1.65774C0.94914 10.6463 0.526488 9.85661 0.919548 9.26702L6.35937 1.10729Z"
                  fill="#FF2459"
                />
              </svg>
            );
          }}
        >
          {generatedData.map((entry, index) => (
            <Cell key={`cell-${index}`} className="scatter-point" />
          ))}
        </Scatter>
      )}
      <Scatter
        name="Existing"
        data={existingData}
        shape={props => {
          const { x, y, width, height } = props;
          if (props.payload.isBenchmark) {
            return (
              <svg
                ref={node => {
                  const map: Map<string, SVGSVGElement> = getMap();
                  const normalizedName = normalizeClassName(props.name);
                  if (map && node && !map.get(normalizedName)) {
                    map.set(normalizedName, node);
                  }
                }}
                className="scatter-point"
                onMouseEnter={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: Colors.PICTON_BLUE,
                    scrollToColumn: true,
                    addShadow: true,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                onMouseLeave={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: 'white',
                    scrollToColumn: true,
                    addShadow: false,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                x={x}
                y={y}
                width={width}
                height={height}
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12.3308C9.94412 12.3308 12.3308 9.94412 12.3308 7C12.3308 4.05588 9.94412 1.66921 7 1.66921C4.05588 1.66921 1.66921 4.05588 1.66921 7C1.66921 9.94412 4.05588 12.3308 7 12.3308Z"
                  fill="white"
                  stroke="#0075FF"
                  strokeWidth="2.66159"
                />
              </svg>
            );
          }
          //Else render a basic blue dot
          return (
            <svg
              x={x}
              y={y}
              ref={node => {
                const map: Map<string, SVGSVGElement> = getMap();
                const normalizedName = normalizeClassName(props.name);
                if (map && node && !map.get(normalizedName)) {
                  map.set(normalizedName, node);
                }
              }}
              className="scatter-point"
              onMouseEnter={() =>
                changeColumnBackground({
                  name: props.name,
                  color: Colors.PICTON_BLUE,
                  scrollToColumn: true,
                  addShadow: true,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              onMouseLeave={() =>
                changeColumnBackground({
                  name: props.name,
                  color: 'white',
                  scrollToColumn: true,
                  addShadow: false,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              id={`${normalizeClassName(props.name)}-point`}
              width={width}
              height={height}
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5.71966" cy="5.94207" r="5.32317" fill="#0075FF" />
            </svg>
          );
        }}
      >
        {existingData.map((entry, index) => (
          <Cell key={`cell-${index}`} className="scatter-point" />
        ))}
      </Scatter>
    </ScatterChart>
  );
};

const CustomLegend = () => {
  const center = css`
    display: flex;
    align-items: center;
  `;
  return (
    <div
      css={css`
        padding-left: 64px;
        font-size: 10px;
        display: flex;
      `}
    >
      <div>Formulations:</div>

      <div
        css={css`
          display: flex;
          width: 100%;
          justify-content: space-evenly;
        `}
      >
        <div css={center}>
          <NonBenchmarkExistingIcon
            css={css`
              margin-right: 3px;
            `}
          />
          Existing
        </div>
        <div css={center}>
          <GeneratedFormulationIcon
            css={css`
              margin-right: 3px;
            `}
          />
          Recommended
        </div>
      </div>
    </div>
  );
};
