/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { DisplaySlider } from '../../shared';

export const FormulationChange = ({
  ingredientName,
  value,
  benchmarkValue,
  difference,
  symbol,
}: {
  ingredientName: string;
  value: string;
  benchmarkValue?: string;
  difference: string;
  symbol: string;
}) => {
  return (
    <div
      css={css`
        border-bottom: 1px solid rgba(204, 204, 204, 0.2);
        color: #515151;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0px;
          color: #03191e;
        `}
      >
        <div>{ingredientName}</div>

        <div
          css={css`
            font-weight: 700;
            font-size: 11px;
          `}
        >
          {difference}%
        </div>
      </div>

      <div
        css={css`
          font-size: 11px;
          color: #515151;
          margin: 5px 0px;
        `}
      >
        {benchmarkValue !== undefined ? (
          <div
            css={css`
              width: 100%;
              justify-content: space-between;
              display: flex;
            `}
          >
            {benchmarkValue}
            {symbol} <ArrowRightOutlined /> {value}
            {symbol}
          </div>
        ) : (
          `${value}${symbol}`
        )}
      </div>
      <DisplaySlider benchmarkVal={benchmarkValue} difference={difference} />
    </div>
  );
};
