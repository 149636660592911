/** @jsxImportSource @emotion/react */
import { Alert, Button, Input, Modal, Segmented } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { CountConstraint } from './count-constraint-component';
import {
  ConstraintInputType,
  ConstraintType,
} from '../../../../../../__generated__/globalTypes';
import { useIngredients } from '../../../../_shared/hooks';
import { useScenarioDetail } from '../../../../_shared/context/scenario-detail-context';
import { constraintContainerStyle } from './design-constraints.styles';
import { DesignConstraintViewContainer } from './desgin-constraint-view/design-constraint-view-container.component';
import { EqualityConstraint } from './equality-constraint.component';
import { AmountConstraint } from './amount-constraint.component';
import {
  validateConstraint,
  validateRequiredFieldsHaveValues,
} from '../design-validation';
import { css } from '@emotion/react';
import { emptyConstraint } from '../design-utils';
import { RangeConstraint } from './range-constraint.component';

export type ConstraintProp = {
  updateConstraint: (c: ConstraintInputType) => void;
  currentConstraint: ConstraintInputType | undefined;
  setValidConstraint?: (value: boolean) => void;
};

export type ConstraintInfoProp = {
  title: string;
  description: React.ReactNode;
  type: ConstraintType;
};

export type ErrorMessageProp = {
  message: string;
  description: string;
};

export type ConstraintTypeProps = {
  setConstraint: (c: ConstraintInputType) => void;
  constraint: ConstraintInputType | undefined;
  constraintType: ConstraintType;
  constraintIsValid: boolean;
  setValidConstraint: (value: boolean) => void;
};

const ConstraintComponent = ({
  constraintType,
  constraint,
  setConstraint,
  setValidConstraint,
}: ConstraintTypeProps) => {
  switch (constraintType) {
    case ConstraintType.COUNT:
      return (
        <CountConstraint
          currentConstraint={constraint}
          updateConstraint={(c: ConstraintInputType) => {
            setConstraint(c);
          }}
        />
      );
    case ConstraintType.EQUALITY:
      return (
        <EqualityConstraint
          currentConstraint={constraint}
          updateConstraint={(c: ConstraintInputType) => {
            setConstraint(c);
          }}
          setValidConstraint={setValidConstraint}
        />
      );
    case ConstraintType.RANGE:
      return (
        <RangeConstraint
          currentConstraint={constraint}
          updateConstraint={(c: ConstraintInputType) => {
            setConstraint(c);
          }}
        />
      );
    case ConstraintType.AMOUNT:
      return (
        <AmountConstraint
          currentConstraint={constraint}
          updateConstraint={(c: ConstraintInputType) => {
            setConstraint(c);
          }}
          setValidConstraint={setValidConstraint}
        />
      );
    default:
      return <React.Fragment />;
  }
};

export const DesignConstraints = () => {
  const {
    saveConstraints,
    constraints,
    removeConstraint,
    editConstraint,
    showConstraintModal,
    setShowConstraintModal,
    constraint,
    setConstraint,
    isExistingConstraint,
    constraintIndex,
    setConstraintIndex,
    disableGoalScenario,
  } = useScenarioDetail();
  const { ingredients } = useIngredients();
  const [modalKey, setModalKey] = useState<string | number>('constraintModal');
  const [validConstraint, setValidConstraint] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageProp>({
    message: '',
    description: '',
  });
  const [constraintType, setConstraintType] = useState<ConstraintType>(
    ConstraintType.COUNT
  );

  useEffect(() => {
    if (constraint?.constraintType) {
      setConstraintType(constraint?.constraintType);
    }
  }, [constraint?.constraintType]);

  useEffect(() => {
    if (constraint && validateRequiredFieldsHaveValues(constraint)) {
      const { isValid, message, description } = validateConstraint(
        constraint,
        ingredients
      );
      setValidConstraint(isValid);

      setErrorMessage({
        message,
        description,
      });
    }
  }, [constraint]);

  const onAddConstraint = (closeModal: boolean) => {
    if (constraint) {
      const { isValid, message, description } = validateConstraint(
        constraint,
        ingredients
      );

      if (isValid) {
        saveConstraints([...constraints, constraint]);
        setConstraint(emptyConstraint);
        if (closeModal) {
          closeAndResetModal();
        } else {
          resetModal();
        }
      } else {
        setValidConstraint(isValid);
        setErrorMessage({
          message,
          description,
        });
      }
    }
  };

  const saveEditedConstraint = () => {
    if (constraintIndex !== undefined && constraint && validConstraint) {
      const clonedConstraints = [...constraints];
      clonedConstraints[constraintIndex] = constraint;
      saveConstraints(clonedConstraints);

      // Clear the modal and close it.
      closeAndResetModal();
    }
  };

  const ValidationAlert = ({
    description,
    message,
  }: {
    description: string;
    message: string;
  }) => {
    return message ? (
      <Alert
        message={message}
        description={description}
        type="error"
        showIcon
      />
    ) : (
      <div />
    );
  };

  const resetModal = () => {
    setConstraintIndex(undefined);
    setConstraint(emptyConstraint);
    setValidConstraint(true);
    const newKey = Math.floor(Math.random() * 1000) + 1;
    setModalKey(newKey);
  };
  const closeAndResetModal = () => {
    resetModal();
    setShowConstraintModal(false);
  };
  return (
    <div>
      <div>
        {constraints.map((c, i) => (
          <DesignConstraintViewContainer
            key={`${constraint?.constraintType}-${i}`}
            constraint={c}
            removeConstraint={removeConstraint}
            editConstraint={editConstraint}
            disabled={disableGoalScenario}
          />
        ))}
      </div>
      <Button
        disabled={disableGoalScenario}
        onClick={() => {
          setConstraintIndex(undefined);
          setShowConstraintModal(true);
          setValidConstraint(true);
        }}
      >
        Add a new constraint
      </Button>
      <Modal
        key={modalKey}
        open={showConstraintModal}
        onCancel={closeAndResetModal}
        destroyOnClose
        title={
          <div
            css={css`
              margin-bottom: 0px;
            `}
          >
            Add new constraints
          </div>
        }
        width={600}
        footer={[
          <Button key="cancel" onClick={closeAndResetModal}>
            Cancel
          </Button>,
          ...(!isExistingConstraint
            ? [
                <Button
                  key="submitAndAddAnotherAL"
                  type="primary"
                  disabled={!constraint || !validConstraint}
                  onClick={() => onAddConstraint(false)}
                >
                  Submit and Add Another
                </Button>,
              ]
            : []),
          <Button
            key="submitAL"
            type="primary"
            disabled={!constraint || !validConstraint}
            onClick={
              isExistingConstraint
                ? saveEditedConstraint
                : () => onAddConstraint(true)
            }
          >
            Submit
          </Button>,
        ]}
      >
        <h3>Name</h3>
        <Input
          maxLength={25}
          css={css`
            margin-bottom: 0.5em;
          `}
          placeholder="Enter your custom label"
          defaultValue={constraint?.name || ''}
          value={constraint?.name || ''}
          onChange={e => {
            setConstraint({
              constraintType, // Typescript doesn't like setting this state without this required value
              ...constraint,
              name: e?.target?.value,
            });
          }}
        />
        <h3>Select type of constraint</h3>
        <Segmented
          block
          value={constraintType}
          options={[
            { value: ConstraintType.EQUALITY, label: 'Target' },
            { value: ConstraintType.RANGE, label: 'Range' },
            { value: ConstraintType.COUNT, label: 'Category' },
            { value: ConstraintType.AMOUNT, label: 'Composition' },
          ]}
          onChange={(value: ConstraintType) => {
            // Clear out the existing constraint before switching types
            setConstraint({
              ...emptyConstraint,
              name: constraint?.name,
              constraintType: value,
            });
            setValidConstraint(true);
            setConstraintType(value);
          }}
          css={css`
            font-size: 13px;
          `}
        />
        <div css={constraintContainerStyle}>
          <ConstraintComponent
            constraintType={constraintType}
            constraint={constraint}
            setConstraint={setConstraint}
            constraintIsValid={validConstraint}
            setValidConstraint={setValidConstraint}
          />
        </div>
        {constraint && !validConstraint && (
          <ValidationAlert
            message={errorMessage.message}
            description={errorMessage.description}
          />
        )}
      </Modal>
    </div>
  );
};
