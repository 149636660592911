import { useContext, useEffect, useState } from 'react';
import React from 'react';
import {
  FormulationItemType,
  FormulationStatus,
  WorkspaceType,
  fetchIterationTasksQuery,
  useformulationWithDesignLazyQuery,
  usesetFormulationStatusMutation,
  usecreateOneFormulationFeedbackMutation,
  FormulationFeedbackType,
  FormulationFeedbackSubjectType
} from '../../../../__generated__/globalTypes';
import { useSession } from './session-context';
import { notification } from 'antd';
import { useIngredients } from '../hooks/use-ingredient.hook';
import { calculateFormulationCostV2 } from '../utils/util';
import {
  FormulationReducerActionType,
  FormulationType,
  FormulationWithDesignType,
  useFormulations,
  useFormulationsDispatch,
} from './formulations-context';
import { TrackableEvent, logEvent } from '../tracking/usage-tracker';
import { ExplorationStep, SidebarTabs } from '../../components/exploration/constants';

export type IterationTaskType = fetchIterationTasksQuery['iterationTasks'][0];
interface ExplorationContextProps {
  formulations: FormulationType[];
  latestFormulationWithDesign?: FormulationWithDesignType;
  setFormulations(formulations: FormulationType[]): void;
  setFormulationStatus: (
    formulation: FormulationType,
    status: FormulationStatus
  ) => Promise<void>;
  minCostScore: number | undefined;
  maxCostScore: number | undefined;
  currentStep: string;
  setCurrentStep(step: string): void;
  currentSidebarTab: SidebarTabs,
  setCurrentSidebarTab(tab: SidebarTabs): void
  setCollapsed(collapsed: boolean): void,
  collapsed: boolean,
  expandedFormulations: FormulationType[],
  expandFormulations(formulations: FormulationType[]): void,
  collapseFormulations(): void
  setFormulationFeedback: (
    formulation: FormulationType,
    type: FormulationFeedbackType,
    subjectType: FormulationFeedbackSubjectType,
    isActive?: boolean) => Promise<any>;

}

const ExplorationContext = React.createContext<ExplorationContextProps>(
  {} as any
);
export const ExplorationContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const {
    selectedIterationId,
    setSelectedIterationId,
    currentProject,
  } = useSession();
  const { projectFormulations, refreshFeedbackFormulations } = useFormulations();
  const dispatchFormulation = useFormulationsDispatch();
  const { ingredients } = useIngredients();
  const [minCostScore, setMinCostScore] = useState<number>();
  const [collapsed, setCollapsed] = useState(false);
  const [maxCostScore, setMaxCostScore] = useState<number>();
  const filterIterationFormulations = (formulations: FormulationType[] = []) =>
    formulations.filter(f => f.iterationId === selectedIterationId);
  const [doSetFormulationStatus] = usesetFormulationStatusMutation();

  const [getFormulationWithDesign] = useformulationWithDesignLazyQuery();
  const [formulations, setFormulations] = useState<FormulationType[]>([]);
  const [expandedFormulations, setExpandedFormulations] = useState<FormulationType[]>([]);
  const [
    latestFormulationWithDesign,
    setLatestFormulationWithDesign,
  ] = useState<FormulationWithDesignType | undefined>();

  const [createFeedbackNote] = usecreateOneFormulationFeedbackMutation();

  const setFormulationStatus = async (
    formulation: FormulationType,
    status: FormulationStatus
  ) => {
    if (status === FormulationStatus.PINNED) {
      logEvent(TrackableEvent.DISCOVER_SOLUTION_FORMULATION_TOGGLE_PINNED);
    }
    await doSetFormulationStatus({
      variables: { formulationId: formulation.id, status },
    }).then(
      () => {
        dispatchFormulation({
          type: FormulationReducerActionType.CHANGED,
          formulation: { ...formulation, status },
        });
      },
      err => {
        notification.error({
          message: 'Unable to set formulation status',
        });
      }
    );
  };

  const setFormulationFeedback = async (
    formulation: FormulationType,
    type: FormulationFeedbackType,
    subjectType: FormulationFeedbackSubjectType,
    isActive?: boolean
  ) => {
    try {
      const feedback = await createFeedbackNote({
        variables: {
          formulationId: formulation.id,
          type,
          subjectType,
          isActive
        },
      });

      refreshFeedbackFormulations();

      if (feedback.data?.createOneFormulationFeedback.isActive) {
        notification.success({ message: 'Feedback saved successfully' });
      }

      if (!feedback.data?.createOneFormulationFeedback.isActive) {
        notification.success({ message: 'Feedback deselected successfully' });
      }

      return feedback
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Error submitting feedback' });
    }
  };

  const [currentStep, setCurrentStep] = useState(
    currentProject?.discoverySolutionStep
      ? currentProject?.discoverySolutionStep
      : ExplorationStep.START
  );

  const [currentSidebarTab, setCurrentSidebarTab] = useState(
    SidebarTabs.FORMULATIONS
  );
  useEffect(() => {
    setFormulations(filterIterationFormulations(projectFormulations));
  }, [projectFormulations, selectedIterationId]);

  useEffect(() => {
    if (!selectedIterationId) {
      const latestIteration = currentProject?.iterations.filter(
        iteration => iteration.type === WorkspaceType.EXPLORATION
      )[0];

      if (latestIteration) {
        setSelectedIterationId(latestIteration.id);
      }
    }
  }, [currentProject?.iterations]);

  const getLatestFormulationWithDesign = async () => {
    const latestFormulationWithDesignId = formulations.find(
      formulation => formulation.designId
    );

    if (latestFormulationWithDesignId) {
      const formulationWithDesignResponse = await getFormulationWithDesign({
        variables: {
          formulationId: latestFormulationWithDesignId.id,
        },
      });

      setLatestFormulationWithDesign({
        ...latestFormulationWithDesignId,
        design: formulationWithDesignResponse.data?.formulation?.design,
      });
    }
  };


  const expandFormulations = async (formulations: FormulationType[]) => {
    setCollapsed(true);
    setExpandedFormulations(formulations);
  }

  const collapseFormulations = () => {
    setCollapsed(false);
    setExpandedFormulations([]);
  }


  useEffect(() => {
    getLatestFormulationWithDesign();
    if (projectFormulations.length && !minCostScore && !maxCostScore) {
      let highestScore = 0;
      let lowestScore = 0;
      for (const result of projectFormulations) {
        let formulationIngredientItems = result.items
          .filter(item => item.type === FormulationItemType.INPUT)
          .map(item => ({
            value: Number(item.value),
            name: item.variable.name,
          }));
        const costScore = Number(
          calculateFormulationCostV2(formulationIngredientItems, ingredients)
        );
        if (costScore > highestScore) {
          highestScore = Number(costScore);
        }
        if (!lowestScore || costScore < lowestScore) {
          lowestScore = Number(costScore);
        }
      }
      setMinCostScore(lowestScore);
      setMaxCostScore(highestScore);
    }
  }, [formulations]);

  return (
    <ExplorationContext.Provider
      value={{
        formulations,
        latestFormulationWithDesign,
        setFormulations,
        setFormulationStatus,
        minCostScore,
        maxCostScore,
        currentStep,
        setCurrentStep,
        currentSidebarTab,
        setCurrentSidebarTab,
        setCollapsed,
        collapsed,
        expandedFormulations,
        expandFormulations,
        collapseFormulations,
        setFormulationFeedback
      }}
    >
      {children}
    </ExplorationContext.Provider>
  );

};

export const useExploration = () => useContext(ExplorationContext);
