import React, { useState } from 'react';
import { Button, Dropdown, Menu, Radio } from 'antd';
// import './adaptive-learning-layout-styles.css';
// import '../../../../pages/execute-solutions/experiment-list-style.css';
import { CustomRecordType } from '../experiment-list-layout';
import capitalize from 'lodash/capitalize';
type SortByDropdownProps = {
  onChange: (value: string) => void;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
};
export const SortByDropdown = ({
  onChange,
  tableColumns,
  setTableColumns,
}: SortByDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const handleMenuClick = (e: any) => {
    setSelectedValue(e.key);
    onChange(e.key);
  };

  const premadeFilters = ['cost', 'actions', 'satisfaction', 'experiment'];

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: 200, overflow: 'auto' }}
    >
      <Menu.Item key="experiment">
        <Radio checked={selectedValue === 'experiment'} />
        Date Added
      </Menu.Item>
      <Menu.Item key="cost">
        <Radio checked={selectedValue === 'cost'} />
        Unit Cost (Lowest)
      </Menu.Item>
      <Menu.Item key="satisfaction">
        <Radio checked={selectedValue === 'satisfaction'} />
        Customer Satisfaction
      </Menu.Item>
      {tableColumns
        ?.filter(tc => tc.visible && !premadeFilters.includes(tc.key))
        .map(tc => (
          <Menu.Item key={tc.key}>
            <Radio checked={selectedValue === tc.key} />
            {tc.columnType === 'composition'
              ? capitalize(tc.sourceRecord.name)
              : capitalize(tc.key)}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>Sort By</Button>
    </Dropdown>
  );
};
