import { css } from '@emotion/react';

export const uploadColStyles = css`
  display: flex;
  flex-direction: column;
  width: auto;

  div.templateDownload {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 0px;
    gap: 10px;
  }

  div.ant-upload-drag-container {
    width: 436px;
  }
`;

export const optimizationColStyles = css`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 0px 11px;
  border-top: 5px solid #ffb685;
  border-radius: 4px 4px 0px 0px;

  div.createOptimizationTitle {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 2px;
    padding-top: 12px;
    width: 436px;
  }
`;

export const optimizationModalStyles = css`
  top: 94px;

  button.ant-modal-close {
    left: 0px;
    background: #ebeaef;
  }

  div {
    .optModalColumn {
      padding-top: 24px;
      padding-left: 21px;

      span.headerText {
        padding-left: 15px;
      }

      .optStepBox {
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
        border-radius: 17px;
        width: 95%;
        margin: 19px 0px;
        display: inline-block;

        .steBoxTitle {
          background: transparent;
          border-bottom: 1px solid #f3f3f3;
          padding: 14px 0px 10px 12px;
          font-weight: 700;
          font-size: 16px;
        }
      }

      .stepContentGrey {
        background: #f3f3f3;
        color: #373737;
        padding: 12px;
        margin-top: 10px;
      }
    }

    .ant-modal-header {
      background: #ffffff;
    }
  }

  div.optModalHeader {
    display: flex;
    height: 58px;
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    background: #ffffff;
    border-bottom: 1px solid #ebeaef;

    div.optModalBackButton {
      width: 59px;
      height: 58px;
      background: #ebeaef;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  span.optimizationStepNumber {
    font-size: 19px;
    font-weight: bold;
    border-radius: 100%;
    border: 1px solid black;
    padding: 10px 14px 9px 14px;
  }

  span.rangeInput,
  input.rangeInput {
    width: 35%;
    height: 30px;
    margin-top: 12px;
    margin-bottom: 18px;
    border-radius: 0px;
    border: 0.5px solid rgba(19, 100, 174, 0.9);
    box-sizing: border-box;
  }
`;
