import React from 'react';
import { Button, Flex, Row, notification } from 'antd';
import { FormulationStatus } from '../../../../../__generated__/globalTypes';
import { useExploration } from '../../../_shared/context';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { ExperimentDetail } from '../../../components/experiments/experiment-detail/experiment-detail-component';
import { FormulationName } from '../../../components/experiments/experiment-detail/change-name-component';

export const ExplorationTimelineFormulationDetail = ({
  formulation,
  handleChangeFormulationToView,
}: {
  formulation: FormulationType;
  handleChangeFormulationToView: (formulation?: FormulationType) => void;
}) => {
  const { setFormulationStatus } = useExploration();

  return (
    <div
      style={{
        overflowY: 'scroll',
        padding: 15,
      }}
    >
      <Flex justify="center" vertical>
        <Flex justify="space-between" align="center">
          <h2>
            <FormulationName formulation={formulation} />
            {formulation.isBenchmark && <span>(Benchmark)</span>}
          </h2>
          <Flex
            gap="small"
            justify="flex-end"
            align="center"
            style={{ padding: '15px 0' }}
          >
            {/* <Button
              onClick={() =>
                notification.warning({
                  message: 'Not implemented (TLP-2151)',
                })
              }
              style={{
                minWidth: 100,
                color: '#FF4D4F',
                borderColor: '#FF4D4F',
              }}
            >
              Reject
            </Button> */}
            <Button
              onClick={async () =>
                await setFormulationStatus(
                  formulation,
                  FormulationStatus.PINNED
                )
              }
              disabled={formulation.status === FormulationStatus.PINNED}
              style={{ minWidth: 100 }}
            >
              Save
            </Button>
            <Button
              onClick={() => handleChangeFormulationToView()}
              style={{ minWidth: 100 }}
            >
              Close
            </Button>
          </Flex>
        </Flex>
        <Row gutter={[0, 0]}>
          <ExperimentDetail experiment={formulation} />
        </Row>
      </Flex>
    </div>
  );
};
