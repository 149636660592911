/** @jsxImportSource @emotion/react */

import React from 'react';

import { EditableChoice } from './editable-choice.component';
import { formatSuffix } from '../../../_shared/utils/util';
import {
  EditableInput,
  InputType as EditableInputType,
} from './editable-input.component';
export enum InputType {
  CHOICE,
  PERCENT,
  STRING,
}

export const FormulationInput = ({
  type,
  options,
  suffix,
  setValue,
  value,
  onChange,
  className,
  isDisabled,
  min,
  max,
  status,
}: {
  type: InputType;
  options?: Array<string>;
  suffix?: string | null;
  /**
   * Displayed value in the read-only state
   * Initial value for going into the editable state
   */
  value?: string | number;
  /**
   * Called after the user exits the scope of the input or presses enter
   */
  setValue: (value: string | number | undefined) => void;

  /**
   * For applicable elements that have a continual edit state (sliders/text input)
   * Sets the value as it chages, but not at the final state when the user is finished.
   */
  onChange?: (value: string | number | undefined) => void;
  className?: string;
  isDisabled?: boolean;
  min?: number;
  max?: number;
  status?: string;
}) => {
  let inputElm: JSX.Element;
  const clsName = `formulationInputText ${className ?? ''}`;
  if (type === InputType.CHOICE && options) {
    inputElm = (
      <EditableChoice
        options={options}
        setValue={setValue}
        selectedValue={value?.toString()}
      />
    );
  } else if (type === InputType.PERCENT) {
    inputElm = (
      <EditableInput
        className={clsName}
        setValue={setValue}
        inputType="number"
        min={min ?? 0}
        max={max}
        value={value}
        onChange={onChange}
        suffix={formatSuffix(suffix, type)}
        isDisabled={isDisabled}
        status={status}
      />
    );
  } else {
    //plain text input
    inputElm = (
      <EditableInput
        className={clsName}
        setValue={setValue}
        onChange={onChange}
        value={value}
        suffix={formatSuffix(suffix, type)}
        type={EditableInputType.STRING}
        isDisabled={isDisabled}
        status={status}
      />
    );
  }

  return inputElm;
};
