/** @jsxImportSource @emotion/react */
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button, Collapse } from 'antd';
import { ConstraintInputProp } from './design-constraint-view-container.component';
import { TextContainerComponent } from './text-container.component';
import { DesignConstraintCollapse } from './design-constraint-collapse.component';
const { Panel } = Collapse;
export const DesignRangeConstraintView = ({
  constraint,
  removeConstraint,
  editConstraint,
  disabled,
}: ConstraintInputProp) => {
  const constraintIngredients =
    constraint.coefficients?.map((v, idx) => {
      return <TextContainerComponent key={idx} text={`${v.name}`} />;
    }) ?? [];

  return (
    <DesignConstraintCollapse name={constraint.name}>
      <Panel
        header={
          <TextContainerComponent
            text={constraint.name || 'Range Constraint'}
          />
        }
        key="1"
        extra={
          <div>
            <span
              css={css`
                margin-right: 10px;
              `}
            >
              <Button
                onClick={() => editConstraint(constraint)}
                icon={<EditTwoTone />}
                disabled={disabled}
              />
            </span>
            <span>
              <Button
                onClick={() => removeConstraint(constraint)}
                icon={<DeleteTwoTone />}
                disabled={disabled}
              />
            </span>
          </div>
        }
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          `}
        >
          <div>{...constraintIngredients}</div>
          Between
          <TextContainerComponent text={`${constraint.lowerBounds}`} />
          {' And '}
          <TextContainerComponent text={`${constraint.upperBounds}`} />
        </div>
      </Panel>
    </DesignConstraintCollapse>
  );
};
