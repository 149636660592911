/* eslint-disable no-bitwise */
/** @jsxImportSource @emotion/react */ import {
  css,
  jsx,
} from '@emotion/react';
import React from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
  BarChart,
  ErrorBar,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { NonIndicativeColors } from '../../../../../../iso/colors';
import { SimulationFromIterationType } from '../../context/types';

export const AerosolsFlammabilityBarChart = ({
  simulation: sim,
}: {
  simulation: SimulationFromIterationType;
}) => {
  /**
   * Aerosols Flammability:
   *  1 bar per product per Invterted/Upright
   *  Group records by Temp + Discharge
   *  ex.{name: '10C 10%', "Product_2 Upright": 10, "Product_2 Inverted" : 20,"Product_1 Upright": 40, "Product_1 Inverted": 24},
   */
  const bars: JSX.Element[] = [];
  const [headerRow, ...records] = sim.visualizationOutput as string[][];
  const [, ...rawData] = sim.output as string[][];
  const [, , , ...productNames] = headerRow;

  /**
   * In the future we could use just the raw values to then create the formatted output
   * For now the existng java code puts out a formatted display output (visualization output)
   * and also the raw data.
   *
   * Here we are manipulating both to get the data for the graph as javascript does not do floating point calculations well
   *
   * TLDR; in the future probably get rid of `visulaizationOutput` from the models, have a single dataset, and generate the view from the raw values
   */

  //For speedier lookup
  const groupedRawData = new Map<
    string,
    { Upright: Map<string, number[]>; Inverted: Map<string, number[]> }
  >();

  rawData.forEach(rawValRow => {
    const [productName, positionAndTemp, value] = rawValRow;

    const position: 'Upright' | 'Inverted' =
      positionAndTemp.toLowerCase().indexOf(' upright ') > -1
        ? 'Upright'
        : 'Inverted';
    const temp = positionAndTemp.split(` ${position} `)[1];

    let data = groupedRawData.get(productName);
    if (!data) {
      data = {
        Upright: new Map<string, number[]>(),
        Inverted: new Map<string, number[]>(),
      };
    }

    let valArray = data[position].get(temp);

    //Add the flame height measurement to the list of values for this temp and position
    if (!valArray) {
      valArray = [Number(value)];
    } else {
      valArray.push(Number(value));
    }

    data[position].set(temp, valArray);

    groupedRawData.set(productName, data);
  });

  //GroupBy Temperature + Discharge
  const groupedData = new Map<
    string,
    { Upright: Map<string, string>; Inverted: Map<string, string> }
  >();
  records.forEach(r => {
    const [position, temp, dishcarge, ...productMeasurements] = r;
    //creating key like '10C 10%'
    const groupName = `${temp} ${dishcarge}`;
    let data = groupedData.get(groupName);
    if (!data)
      data = {
        Upright: new Map<string, string>(),
        Inverted: new Map<string, string>(),
      };

    // eslint-disable-next-line
    productMeasurements.forEach((value, index) => {
      const productName = productNames[index];
      data![position as 'Inverted' | 'Upright'].set(productName, value);
    });
    groupedData.set(groupName, data);
  });

  const formattedData = [];
  const uprightBarVals = new Set<string>();
  const invertedBarVals = new Set<string>();
  for (const [temp, values] of groupedData.entries()) {
    const rec: { group: string; [key: string]: string | number | number[] } = {
      group: temp,
    };

    for (const [uprightProductName, uprightVal] of values.Upright.entries()) {
      const val = Number(uprightVal);
      rec[`Upright ${uprightProductName}`] = val;
      const allRawValues = groupedRawData.get(uprightProductName);
      if (allRawValues) {
        const rv = allRawValues.Upright.get(temp)!;
        const min = Math.round((val - Math.min(...rv)) * 10) / 10;
        const max = Math.round((Math.max(...rv) - val) * 10) / 10;

        rec[`error Upright ${uprightProductName}`] = [min, max];
      }

      uprightBarVals.add(`Upright ${uprightProductName}`);
    }

    for (const [
      invertedProductName,
      invertedVal,
    ] of values.Inverted.entries()) {
      const val = Number(invertedVal);
      rec[`Inverted ${invertedProductName}`] = val;
      const allRawValues = groupedRawData.get(invertedProductName);
      if (allRawValues) {
        const rv = allRawValues.Inverted.get(temp)!;
        const min = Math.round((val - Math.min(...rv)) * 10) / 10;
        const max = Math.round((Math.max(...rv) - val) * 10) / 10;

        rec[`error Inverted ${invertedProductName}`] = [min, max];
      }
      invertedBarVals.add(`Inverted ${invertedProductName}`);
    }
    formattedData.push(rec);
  }

  const uprightBarValsArray = Array.from(uprightBarVals);
  const invertedBarValsArray = Array.from(invertedBarVals);
  uprightBarValsArray.forEach((v, i) => {
    const color = NonIndicativeColors[i];
    bars.push(
      <Bar dataKey={invertedBarValsArray[i]} fill={`${color}CC`}>
        <ErrorBar
          dataKey={`error ${invertedBarValsArray[i]}`}
          direction="y"
          layout="vertical"
        />
      </Bar>
    );
    bars.push(
      <Bar dataKey={v} fill={`${lightenDarkenColor(color, -10)}DD`}>
        <ErrorBar dataKey={`error ${v}`} direction="y" layout="vertical" />
      </Bar>
    );
  });

  return (
    <ResponsiveContainer
      width="100%"
      height="85%"
      minHeight={25}
      minWidth={25}
      css={css`
        .recharts-tooltip-wrapper {
          // enable following to make it a fixed height with optional scroll
          //pointer-events: auto !important;
          //overflow-y: auto;
          //max-height: 400px;
          /* z-index: 1000; */
        }
      `}
    >
      <BarChart
        data={formattedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 4" />
        <XAxis tickLine={false} axisLine={false} dataKey="group" />
        <YAxis
          tickLine={false}
          axisLine={false}
          domain={[
            0,
            (datamax: number) =>
              datamax < 75 ? 80 : Math.ceil(datamax / 10) * 10,
          ]}
        />
        <Tooltip />
        <Legend />
        <ReferenceLine y={75} stroke="red" strokeDasharray="2 2" />
        {...bars}
      </BarChart>
    </ResponsiveContainer>
  );
};

const lightenDarkenColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};
