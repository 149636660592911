import { validateObjective } from '../../../_shared/utils/util';
import {
  Objective,
  Outcome,
  ObjectiveType,
  VariableType,
} from '../../../../../__generated__/globalTypes';
import {
  NO_IMPORTANCE,
  IMPORTANCE_MAX,
  IMPORTANCE_MEDIUM,
} from '../../workspaces/shared/goals/types';
import {
  Button,
  Checkbox,
  Divider,
  InputNumber,
  Radio,
  Select,
  Slider,
  Space
} from 'antd';
import React, { useState, useEffect } from 'react';
import {
  DoubleRightOutlined,
  LoadingOutlined,
  MinusOutlined,
  PauseOutlined
} from '@ant-design/icons';
import { useDesign } from '../../../_shared/context/design-context';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { importanceNumberToString } from '../exploration-goal-setting/exploration-goal-setting';
import { ArrowDown } from '../../../_shared/style';
import './objective-row-component.css';
import { ExplorationStep } from '../constants';
import { useExploration } from '../../../_shared/context';
const { Option } = Select;

const getObjectiveChangeText = (
  objectiveToSave: Objective,
  originalObjective: Objective
) => {
  let runQuickAlReasonMessage: string | undefined;

  const objectiveTypeWasChanged =
    objectiveToSave.objectiveType !== originalObjective.objectiveType;

  if (
    objectiveTypeWasChanged &&
    (objectiveToSave.objectiveType === ObjectiveType.MAXIMIZE ||
      objectiveToSave.objectiveType === ObjectiveType.MINIMIZE)
  ) {
    runQuickAlReasonMessage = `Outcome "${objectiveToSave.targetVariable
      }" set to ${objectiveToSave.objectiveType === ObjectiveType.MAXIMIZE
        ? 'Maximize'
        : 'Minimize'
      }`;
  }

  if (
    objectiveToSave.objectiveType === ObjectiveType.TARGET_VALUE &&
    (objectiveTypeWasChanged ||
      objectiveToSave.value !== originalObjective.value)
  ) {
    runQuickAlReasonMessage = `Outcome "${objectiveToSave.targetVariable}" value set to ${objectiveToSave.value}`;
  }

  if (
    objectiveToSave.objectiveType === ObjectiveType.IN_RANGE &&
    (objectiveTypeWasChanged ||
      objectiveToSave.lower !== originalObjective.lower ||
      objectiveToSave.upper !== originalObjective.upper)
  ) {
    runQuickAlReasonMessage = `Outcome "${objectiveToSave.targetVariable}" range set from ${objectiveToSave.lower} to ${objectiveToSave.upper}`;
  }

  if (objectiveToSave.importance !== originalObjective.importance) {
    const priorityMessage = `priority set to ${importanceNumberToString[objectiveToSave.importance] ?? 'None'
      }`;

    runQuickAlReasonMessage = runQuickAlReasonMessage
      ? runQuickAlReasonMessage + ` and ${priorityMessage}`
      : `Outcome "${objectiveToSave.targetVariable}" ${priorityMessage}`;
  }

  return (
    runQuickAlReasonMessage ??
    `Outcome "${objectiveToSave.targetVariable} was changed`
  );
};

const getDefaultObjectiveType = (
  objective: Objective,
  outcome: { type: VariableType | undefined }
): ObjectiveType => {
  if (outcome.type !== VariableType.CATEGORICAL) {
    return objective.objectiveType;
  }
  return ObjectiveType.TARGET_VALUE;
};

const ObjectiveSlider = ({
  objective,
  outcome,
}: {
  objective: Objective;
  outcome: Outcome;
}) => {
  let upperBound = Number(outcome?.upper);
  let lowerBound = Number(outcome?.lower);
  let upperValue = Number(objective?.upper ?? objective?.value);
  let lowerValue = Number(objective?.lower ?? objective?.value);

  if (objective?.objectiveType === ObjectiveType?.MAXIMIZE) {
    upperValue = upperBound;
    lowerValue = lowerBound;
  }

  if (objective?.objectiveType === ObjectiveType?.MINIMIZE) {
    upperValue = lowerBound;
    lowerValue = lowerBound;
  }
  return (
    <Slider
      className="objective-row-slider"
      range
      min={lowerBound}
      max={upperBound}
      value={[lowerValue, upperValue]}
      tooltip={{ open: false }}
    />
  );
};

export const ObjectiveRow = ({
  objective,
  outcome,
  saveObjective,
  handlePriority,
}: {
  objective: Objective;
  outcome: Outcome;
  saveObjective: (
    updatedObjective: Objective
  ) => Promise<Map<string, Objective>>;
  handlePriority: (objective: Objective) => Promise<void>;
}) => {
  const { quickDesignIsRunning, runQuickDesign } = useDesign();
  const { currentStep } = useExploration();
  const { objectivesByTarget } = useScenarioDetail();
  const [priorityUpdating, setPriorityUpdating] = useState(false);
  const [showBody, setShowBody] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>();

  const [objectiveType, setObjectiveType] = useState<ObjectiveType>(
    getDefaultObjectiveType(objective, outcome)
  );
  const [objectiveToEdit, setObjectiveToEdit] = useState<Objective>({
    ...objective,
  });

  useEffect(() => {
    setObjectiveToEdit({ ...objective });
    setObjectiveType(objective.objectiveType);
  }, [objective]);
  let displayText: string = '';
  const formattedUnit = outcome.unit === 'Not Applicable' ? '' : outcome.unit;
  switch (objective.objectiveType) {
    case ObjectiveType.MINIMIZE:
      displayText = 'Minimize';
      break;
    case ObjectiveType.MAXIMIZE:
      displayText = 'Maximize';
      break;
    case ObjectiveType.IN_RANGE:
      displayText = `Between ${objective?.lower} ${formattedUnit} and ${objective?.upper} ${formattedUnit}`;
      break;
    case ObjectiveType.TARGET_VALUE:
      displayText = String(objective.value) + ' ' + formattedUnit;
      break;
  }

  const handleObjectiveSave = async (objectiveToSave: Objective) => {
    setIsSaving(true);
    let validationMessage = validateObjective(objectiveToSave, outcome);
    if (objectiveToSave.importance === 0) {
      const otherObjectivesHasImportance = new Array(
        ...objectivesByTarget.values()
      )
        .filter(o => o.targetVariable !== objectiveToSave.targetVariable)
        .some(o => o.importance > 0);

      if (!otherObjectivesHasImportance) {
        validationMessage = {
          message: 'At least one objective must not be ignored.',
        };
      }
    }

    if (!validationMessage) {
      const runQuickAlReasonMessage = getObjectiveChangeText(
        { ...objectiveToSave, objectiveType },
        objective
      );

      let returnedObjectivesByTarget = await saveObjective({
        ...objectiveToSave,
        objectiveType,
      });
      if (!quickDesignIsRunning && currentStep == ExplorationStep.FORMULATION_DISCOVERY) {
        await runQuickDesign({
          passedObjectivesByTarget: returnedObjectivesByTarget,
          reasonMessage: runQuickAlReasonMessage,
        });
      }
      setIsSaving(false);
      setShowBody(false);
      setValidationMessage('');
    } else {
      setIsSaving(false);
      setValidationMessage(validationMessage?.message);
    }
  };

  const handleCancel = () => {
    setShowBody(false);
    setObjectiveToEdit({ ...objective });
    setObjectiveType(objective.objectiveType);
  };

  const handleCheckbox = async () => {
    try {
      setPriorityUpdating(true);
      await handlePriority(objective);
      setPriorityUpdating(false);
    } catch (e) {
      setPriorityUpdating(false);
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '5% 95%',
      }}
    >
      <div
        className="objective-checkbox-column"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',
          gap: 5,
          marginRight: 10,
          cursor: 'pointer',
        }}
      >
        {priorityUpdating && <LoadingOutlined style={{ fontSize: 12 }} spin />}
        <Checkbox
          style={{ display: priorityUpdating ? 'none' : '' }}
          checked={objective.importance > 0}
          onChange={handleCheckbox}
          disabled={priorityUpdating}
        />
        <div onClick={() => setShowBody(!showBody)}>
          {showBody ? (
            <ArrowDown
              style={{
                transition: 'all ease 0.3s',
                rotate: '-180deg',
              }}
            />
          ) : (
            <ArrowDown
              style={{
                transition: 'all ease 0.3s',
              }}
            />
          )}
        </div>
      </div>
      <div className="objective-info-column">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 5,
            paddingRight: 5,
            cursor: 'pointer',
            color: objective.importance === 0 ? '#7C858C' : 'inherit',
          }}
          onClick={() => setShowBody(!showBody)}
        >
          <div onClick={() => setShowBody(!showBody)}>
            {objective?.targetVariable}
            <span></span>
            {outcome?.type === VariableType.NUMERIC && (
              <span
                style={{ marginLeft: 5, color: '#7C858C', fontSize: 12 }}
              >{`${outcome?.lower}${formattedUnit} - ${outcome?.upper}${formattedUnit}`}</span>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <div onClick={() => setShowBody(!showBody)}>
              {' '}
              {objective.importance === 0 ? 'Ignored' : displayText}{' '}
            </div>
          </div>
        </div>
        {outcome?.type === VariableType.NUMERIC && (
          <ObjectiveSlider outcome={outcome} objective={objective} />
        )}
        {showBody ? (
          <div style={{ marginTop: 10 }}>
            <Space direction="vertical">
              <Radio
                value={ObjectiveType.TARGET_VALUE}
                onChange={value => {
                  setObjectiveType(value.target.value);
                }}
                checked={objectiveType === ObjectiveType.TARGET_VALUE}
              >
                Specific Value
              </Radio>
              {objectiveType === ObjectiveType.TARGET_VALUE && (
                <div>
                  <span
                    style={{
                      display: 'block',
                      color: '#7C858C',
                      paddingBottom: 5,
                    }}
                  >
                    Value
                  </span>
                  {outcome.type !== VariableType.CATEGORICAL &&
                    outcome.type !== VariableType.ORDINAL ? (
                    <InputNumber
                      id="valueInput"
                      style={{ width: '100%' }}
                      placeholder={`${outcome?.lower}${formattedUnit} - ${outcome?.upper}${formattedUnit}`}
                      value={objectiveToEdit?.value}
                      onChange={e =>
                        setObjectiveToEdit({
                          ...objectiveToEdit,
                          value: String(e),
                        })
                      }
                    />
                  ) : (
                    <Select
                      style={{ minWidth: 150 }}
                      value={objectiveToEdit.value}
                      onChange={e =>
                        setObjectiveToEdit({
                          ...objectiveToEdit,
                          value: String(e),
                        })
                      }
                      options={outcome.values?.map(value => ({
                        label: value,
                        value: value,
                      }))}
                    />
                  )}
                </div>
              )}
              {outcome.type !== VariableType?.CATEGORICAL && (
                <>
                  <Radio
                    value={ObjectiveType.IN_RANGE}
                    onChange={value => setObjectiveType(value.target.value)}
                    checked={objectiveType === ObjectiveType.IN_RANGE}
                  >
                    Range
                  </Radio>
                  {objectiveType === ObjectiveType.IN_RANGE && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 15,
                      }}
                    >
                      <div>
                        <span
                          style={{
                            display: 'block',
                            color: '#7C858C',
                            paddingBottom: 5,
                          }}
                        >
                          From
                        </span>
                        {outcome.type !== VariableType.ORDINAL ? (
                          <InputNumber
                            id="fromInput"
                            style={{ width: '100%' }}
                            placeholder={outcome?.lower ?? ''}
                            value={objectiveToEdit?.lower}
                            onChange={e =>
                              e !== null &&
                              setObjectiveToEdit({
                                ...objectiveToEdit,
                                lower: String(e),
                              })
                            }
                          />
                        ) : (
                          <Select
                            style={{ minWidth: 150 }}
                            value={objectiveToEdit.lower}
                            onChange={e =>
                              setObjectiveToEdit({
                                ...objectiveToEdit,
                                lower: String(e),
                              })
                            }
                            options={outcome.values?.map(value => ({
                              label: value,
                              value: value,
                            }))}
                          />
                        )}
                      </div>

                      <div>
                        <span
                          style={{
                            display: 'block',
                            color: '#7C858C',
                            paddingBottom: 5,
                          }}
                        >
                          To
                        </span>

                        {outcome.type !== VariableType.ORDINAL ? (
                          <InputNumber
                            id="toInput"
                            style={{ width: '100%' }}
                            value={objectiveToEdit?.upper}
                            placeholder={outcome?.upper ?? ''}
                            onChange={e =>
                              e !== null &&
                              setObjectiveToEdit({
                                ...objectiveToEdit,
                                upper: String(e),
                              })
                            }
                          />
                        ) : (
                          <Select
                            style={{ minWidth: 150 }}
                            value={objectiveToEdit.upper}
                            onChange={e =>
                              setObjectiveToEdit({
                                ...objectiveToEdit,
                                upper: String(e),
                              })
                            }
                            options={outcome.values?.map(value => ({
                              label: value,
                              value: value,
                            }))}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <Radio
                    value={ObjectiveType.MAXIMIZE}
                    onChange={value => setObjectiveType(value.target.value)}
                    checked={objectiveType === ObjectiveType.MAXIMIZE}
                  >
                    Maximize
                  </Radio>
                  <Radio
                    value={ObjectiveType.MINIMIZE}
                    onChange={value => setObjectiveType(value.target.value)}
                    checked={objectiveType === ObjectiveType.MINIMIZE}
                  >
                    Minimize
                  </Radio>
                </>
              )}
            </Space>
            <div style={{ fontWeight: 500, marginTop: 10 }}>
              Objective Priority
            </div>
            <Select
              defaultValue={objective?.importance}
              style={{ width: 120 }}
              onChange={value =>
                setObjectiveToEdit({ ...objectiveToEdit, importance: value })
              }
            >
              <Option value={3}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(-90deg)',
                    marginRight: 5,
                    color: 'red',
                  }}
                />
                High
              </Option>
              <Option value={2}>
                <PauseOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'green',
                  }}
                />
                Medium
              </Option>
              <Option value={1}>
                <DoubleRightOutlined
                  style={{
                    transform: 'rotate(90deg)',
                    marginRight: 5,
                    color: 'blue',
                  }}
                />
                Low
              </Option>
              <Option value={0}>
                <MinusOutlined style={{ marginRight: 5, color: 'grey' }} />
                None
              </Option>
            </Select>
            <p style={{ color: 'red' }}> {validationMessage}</p>
            <div className="objective-info-column-buttons">
              <Button
                size="small"
                onClick={handleCancel}
                className="objective-cancel-button"
              >
                Cancel
              </Button>
              <Button
                className="objective-save-button"
                size="small"
                type="primary"
                loading={isSaving}
                onClick={() => handleObjectiveSave(objectiveToEdit)}
              >
                Save Changes
              </Button>
            </div>

            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
          </div>
        ) : (
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        )}
      </div>
    </div>
  );
};
