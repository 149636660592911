import { css } from '@emotion/react';

export const ConstraintRangeContainerStyles = css`
  display: grid;
  background: #f3f3f3;
  grid-template-columns: min-content auto;
  margin-bottom: 6px;
`;

export const ConstraintRangeInnerContainerStyles = css`
  background: #f3f3f3;
  grid-column-start: 2;
  color: #373737;
  width: 100%;
  overflow: hidden;
  padding: 0 20px;
`;

export const ConstraintRangeSpaceStyles = css`
  margin-top: 12px;
  text-align: center;
  color: #a1a3a6;
  padding: 0px 13px;
`;

export const ConstraintErrorHeaderMarkStyles = css`
  grid-column-start: 1;
  grid-column-end: 1;
  padding-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const ContraintErrorMessageStyles = css`
  width: 100%;
  margin-top: 5px;
`;
