import React, { useState } from 'react';
import { Alert, Button, Divider, Drawer, Typography, notification } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import './upload-results-drawer-styles.css';
import { useSession } from '../../../_shared/context';
import { useDesign } from '../../../_shared/context/design-context';
import { useIngredients } from '../../../_shared/hooks';
import { FileUpload } from '../../../_shared/components/data/file-upload.component';
import { DownloadFile } from '../../../_shared/components/button/download-file';
import { RcFile } from 'antd/es/upload';
import { getFormulationsExport } from '../../workspaces/adaptive-learning/design-utils';
import { useFormulations } from '../../../_shared/context/formulations-context';
import { Outcome } from '../../../../../__generated__/globalTypes';
export const UploadResultsDrawer = ({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) => {
  const [initUpload, setInitUpload] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileHasErrors, setFileHasErrors] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileSuccessfullylUploaded, setFileSuccessfullylUploaded] = useState(
    false
  );
  const { currentProject, getSelectedIteration } = useSession();
  const { projectFormulations } = useFormulations();
  const { designResults, latestDesign } = useDesign();
  const { ingredients } = useIngredients();
  const { Paragraph } = Typography;
  const filename = `${currentProject?.key}-${getSelectedIteration()?.key
    }-AL-Formulations.csv`;
  const handleFileUploadSuccess = () => {
    setFileUploading(false);
    setFileSuccessfullylUploaded(true);
  };

  const resetUploader = () => {
    setFileUploading(false);
    setFileSuccessfullylUploaded(false);
    setFileSelected(false);
    setFileHasErrors(false);
  };

  const handleFileSelect = (file: RcFile) => {
    if (file.type === 'text/csv') {
      setFileSelected(true);
    } else {
      setFileHasErrors(true);
    }
  };
  return (
    <Drawer
      title="Upload Results"
      placement="right"
      onClose={onClose}
      open={open}
    >
      <div className="upload-results-drawer-container">
        <div>
          <div>
            <Paragraph>
              Import new data by first downloading the CSV file template. After
              you fill out the data values for each formulation, save it and
              upload the CSV back to the platform.
            </Paragraph>

            <FileUpload
              accept="text/csv"
              uploadType={'dragger'}
              keyScope="adaptive_learning"
              listType="picture"
              doUpload={initUpload}
              onFileSelected={handleFileSelect}
              onUploadSuccess={handleFileUploadSuccess}
              onError={() => setFileUploading(false)}
              onRemove={resetUploader}
              style={
                fileSelected || fileHasErrors
                  ? { visibility: 'hidden', height: 10 }
                  : undefined
              }
              showUploadList={{
                showRemoveIcon: true,
                removeIcon: <DeleteOutlined />,
              }}
            // {...draggerProps}
            >
              {!fileSelected && !fileHasErrors && (
                <React.Fragment>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-p">
                    Drag a file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Accepts Single CSV files</p>
                </React.Fragment>
              )}
            </FileUpload>

            {fileSelected && !fileSuccessfullylUploaded && (
              <Alert
                style={{ marginTop: 10 }}
                message={`File uploaded successfully. Click "Import" below to finish.`}
                type="success"
                showIcon
              />
            )}
            {fileSuccessfullylUploaded && (
              <Alert
                style={{ marginTop: 10 }}
                message="Results Uploaded!"
                description="The model will be retrained with the new data. Once the process is complete you will see uploaded test in this list as marked as Manual and status as Existing"
                type="success"
                showIcon
              />
            )}
            {fileHasErrors && (
              <Alert
                style={{ marginTop: 10 }}
                description="Incorrect file format."
                type="error"
                showIcon
              />
            )}
          </div>
        </div>
        <div>
          <Divider />
          <div className="upload-results-drawer-bottom">
            <div>
              {currentProject && (
                <DownloadFile
                  options={{
                    type: 'data:attachment/text',
                  }}
                  content={getFormulationsExport(projectFormulations, {
                    removeOutcomeValues: true,
                    includeOutcomeBounds: true,
                    outcomes: currentProject?.activeModel
                      ?.outcomes as Outcome[],
                  })}
                  filename={filename}
                >
                  <Button>
                    {' '}
                    <span>{<DownloadOutlined />} Test Plan</span>
                  </Button>
                </DownloadFile>
              )}
            </div>
            <div>
              <Button onClick={onClose}>
                <span>Cancel</span>
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                disabled={!fileSelected || fileSuccessfullylUploaded}
                onClick={() => {
                  setInitUpload(true);
                  setFileUploading(true);
                }}
                loading={fileUploading}
              >
                <span>Import</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
