import { css } from '@emotion/css';
export const expandableRowStyle = css`
  margin-left: -12px;
`;

export const noBorderRow = css`
  border-inline-end: none !important;
`;

export const subTableFirstCellStyle = css`
  border-top: 0 !important;
  padding-left: 16px !important;
  //mmmm idk about this
  width: 217px;
`;

export const staticCellWidth = css`
  width: 200px;
`;

export const adaptiveLearningResultTableStyle = css`
  .sub-table-header-row {
    font-weight: 700;
    background: #edecec;
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .ant-table-thead > tr > th {
    background: WHITE;
  }

  .summary-row {
    background: white;
  }

  .sub-table-header-row p {
    margin-bottom: 0px;
  }
  .no-border-row {
    border-inline-end: none !important;
  }

  .ant-table-expanded-row .ant-table-cell:first-child {
    padding: 0;
    border-top: 0 !important;
  }

  .ant-table-expanded-row .ant-table-container {
    border-inline-start: none !important;
  }

  .sub-table-first-cell {
    border-top: 0 !important;
    padding-left: 16px !important;
  }

  .sub-table-first-cell,
  th:nth-child(1),
  th:nth-child(2),
  .summary-row td:nth-child(1),
  .summary-row td:nth-child(2),
  .sub-table-header-row td:nth-child(1),
  .sub-table-header-row td:nth-child(2) {
    position: sticky !important;
    z-index: 99;
  }

  .sub-table-first-cell,
  th:nth-child(1),
  th:nth-child(2),
  .summary-row td:nth-child(1),
  .summary-row td:nth-child(2) {
    background-color: white !important;
  }

  .sub-table-first-cell,
  th:nth-child(1),
  .summary-row td:nth-child(1),
  .sub-table-header-row td:nth-child(1) {
    left: 0;
  }

  th:nth-child(2),
  .summary-row td:nth-child(2),
  .sub-table-header-row td:nth-child(2) {
    left: 17px;
  }

  .ant-table-expanded-row .ant-table {
    margin: 0 !important;
  }

  .ant-table-expanded-row table {
    border-top: none !important;
  }

  .ant-table-row-expand-icon-cell {
    border-inline-end: none !important;
  }

  .ant-table-row-expand-icon {
    background: #edecec;
  }

  .no-border-row .ant-table-cell {
    border-inline-end: none !important;
  }

  .ant-table-row-expand-icon-cell {
    padding: 0 !important;
  }

  .expandable-row-title {
    margin-left: -12px;
  }
  .ant-table-content {
    /*
      We can't use their sticky prop which does this for us with scrollIntoView
      so we need to unset it ourselves.
    */
    overflow: unset !important;
  }

  .ant-table-container::after {
    box-shadow: unset !important;
  }
`;
