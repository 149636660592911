import { MlApiJobStatus } from 'src/types/mlapi.types';
import {
  Objective,
  ConstraintInputType,
  findDesignsByIterationQuery,
  ObjectiveType,
  projectByIdQuery,
} from '../../../../../../__generated__/globalTypes';
import { IterationWithLatestSimulation } from '../../lab-bench/context';

export const IMPORTANCE_MAX = 3;
export const IMPORTANCE_MEDIUM = 2;
export const IMPORTANCE_MIN = 1;
export const NO_IMPORTANCE = 0;

export type OutOfBoundAlert = {
  ingredientName: string;
  message: string;
};

export enum Priorities {
  HIGH_PRIORITY = 'High Priority',
  MEDIUM_PRIORITY = 'Medium Priority',
  LOW_PRIORITY = 'Low Priority',
}

export type PriorityLabel =
  | Priorities.HIGH_PRIORITY
  | Priorities.MEDIUM_PRIORITY
  | Priorities.LOW_PRIORITY;

export enum DesignStates {
  FINISHED = 'FINISHED',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  EDITING = 'EDITING',
  NO_RESULTS = 'NO_RESULTS',
}

export type DesignContextProviderProps = {
  children?: React.ReactNode;
  iteration?: IterationWithLatestSimulation;
};

export type PolledDesignsType = NonNullable<
  NonNullable<findDesignsByIterationQuery['iteration']>['latestDesign']
>;
export type PolledDesignObjective = Omit<
  NonNullable<PolledDesignsType>['objectives'][0],
  '__typename'
>;

/**
 * Sub-type/slimmed down version of productVersion record
 */
export type DesignProductRecordType = NonNullable<PolledDesignsType>['product'];

export interface DesignContextProps {
  objectivesByTarget: Map<string, Objective>;
  setObjectivesByTarget: (objectives: Map<string, Objective>) => void;
  updateObjective: (objective: Objective) => void;
  changePriority: (input: {
    targetVariable: string;
    action: 'INCREASE' | 'DECREASE';
  }) => void;
  runDesignJob: () => void;
  latestDesign: PolledDesignsType | undefined;
  designState: DesignStates;
  designResults: DesignResultType[];
  // initiateDesignJob: () => void;
  // constraints: Map<string, NullableConstraintType>;
  constraints: ConstraintInputType[];
  setConstraints: (constraints: ConstraintInputType[]) => void;
  removeConstraint: (c: ConstraintInputType) => void;
  editConstraint: (c: ConstraintInputType) => void;
  fillerIngredient?: string;
  setFillerIngredient: (fillerIngredient: string) => void;
  showConstraintModal: boolean;
  setShowConstraintModal: (showConstraintModal: boolean) => void;
  constraint: ConstraintInputType | undefined;
  setConstraint: (constraint: ConstraintInputType | undefined) => void;
  isExistingConstraint: boolean;
  setConstraintIndex: (constraintIndex: number | undefined) => void;
  constraintIndex: number | undefined;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  objectivesAreInEditMode: boolean;
  setObjectivesAreInEditMode: (o: boolean) => void;
}

export type DesignResultType = DesignJobResponse['results']['conditions'][0] & {
  productName: string;
  isBenchmark: boolean;
  color: string;
  label: string;
  isGenerated: boolean;
};

export type DesignJobResponse = {
  job_id: string;
  status: MlApiJobStatus;
  started_at: string;
  ended_at?: string;
  error_message?: string;
  completed: boolean;
  request: {
    objectives: Objective[];
    constraints: [];
    n: number;
    penalty_factor: number;
    algorithm: number;
    enforce_constraints: boolean;
    filler_ingredient: string;
    optim_exclude_filler: boolean;
    optim_sum_100: boolean;
    n_observations: number;
  };
  aws_batch_job_id?: string;
  results: {
    time_taken: number;
    model_id: string;
    algorithm_id: number;
    app_version: string;
    observations?: {
      formulation: {
        name: string;
        formulation: {
          name: string;
          value: number | string;
        }[];
      };
      scores: {
        score: number;
        total_desirability: number;
        penalty: number;
        desirability_components: {
          objective: {
            importance: number;
            lower: number | null;
            objective_type: ObjectiveType;
            target_variable: 'Sweetness';
            upper: number | null;
            value: string | number | null;
          };
          desirability: number;
        }[];
      };
    }[];
    conditions: {
      formulation: {
        name: string;
        formulation: {
          name: string;
          value: number | string;
        }[];
      };
      scores: {
        score: number;
        total_desirability: number;
        penalty: number;
        desirability_components: {
          objective: {
            importance: number;
            lower: number | null;
            objective_type: ObjectiveType;
            target_variable: 'Sweetness';
            upper: number | null;
            value: string | number | null;
          };
          desirability: number;
        }[];
      };
    }[];
  };
  model_id: string;
};
