import { gql, useQuery, useMutation } from '@apollo/client';
import * as UserTypes from './__generated__/User';
import * as GetUserTypes from './__generated__/getUser';
import {
  updateUserDetailsVariables,
  updateUserDetails,
} from './__generated__/updateUserDetails';
import {
  alterUserRole,
  alterUserRoleVariables,
} from './__generated__/alterUserRole';
import {
  resendUserPassword,
  resendUserPasswordVariables,
} from './__generated__/resendUserPassword';

export type UserType = UserTypes.User;

export const USER_DATA = gql`
  fragment User on User {
    id
    firstName
    lastName
    position
    email
    status
    organizationId
    role
    enableIceCreamBetaFeatures
  }
`;

export const changeUserRoleQuery = gql`
  mutation alterUserRole($userId: String!, $role: Role!) {
    changeUserRole(role: $role, userId: $userId) {
      ...User
    }
  }
  ${USER_DATA}
`;

export const updateUser = () =>
  useMutation<updateUserDetails, updateUserDetailsVariables>(
    gql`
      mutation updateUserDetails(
        $data: UserDetailsUpdateInput!
        $userId: String!
      ) {
        updateUserDetails(data: $data, userId: $userId) {
          ...User
        }
      }
      ${USER_DATA}
    `
  );

export const useChangeUserRole = () =>
  useMutation<alterUserRole, alterUserRoleVariables>(changeUserRoleQuery);

export const useResendUserPassword = () =>
  useMutation<resendUserPassword, resendUserPasswordVariables>(
    gql`
      mutation resendUserPassword($userId: String!) {
        resendUserPassword(userId: $userId) {
          ...User
        }
      }
      ${USER_DATA}
    `
  );
