import { useMemo } from 'react';
import { useSession } from '../context';
import {
  projectByIdQuery,
  VariableType,
} from '../../../../__generated__/globalTypes';

export type IngredientType = NonNullable<
  projectByIdQuery['project']
>['ingredientList'][0];
/**
 * `ingredientById` is mapped by string to string even though ingredient IDs are numbers
 * this allows it to play nice with other things
 *
 */
export const useIngredients = () => {
  const { currentProject } = useSession();
  const {
    ingredientByName,
    ingredientById,
    categories,
    activeNumericIngredients,
    activeIngredients,
  } = useMemo(() => {
    const ingByNameMap = new Map<string, IngredientType>();
    const ingById = new Map<string, IngredientType>();
    const activeNumericIng = [];
    const activeIng = [];
    let categories = new Set<string>();
    if (currentProject) {
      for (const ing of currentProject.ingredientList) {
        ingByNameMap.set(ing.ingredient.name, ing);
        ingById.set(ing.ingredient.id.toString(), ing);
        categories.add(ing.category.name);
        if (ing.isActive && ing.type === VariableType.NUMERIC) {
          activeNumericIng.push(ing);
        }
        if (ing.isActive) {
          activeIng.push(ing);
        }
      }
    }

    return {
      ingredientByName: ingByNameMap,
      ingredientById: ingById,
      categories: Array.from(categories),
      activeNumericIngredients: activeNumericIng,
      activeIngredients: activeIng,
    };
  }, [currentProject]);

  return {
    ingredients: currentProject?.ingredientList ?? [],
    ingredientByName,
    ingredientById,
    categories,
    activeNumericIngredients,
    activeIngredients,
  };
};
