/** @jsxImportSource @emotion/react */

import { MouseEventHandler } from 'react';

export type DownloadFileButtonProps = {
  content: string;
  options: any;
  filename: string;
  onclick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  children: JSX.Element | string;
};

export const DownloadFile = (props: DownloadFileButtonProps) => {
  const getDownloadUrl = () => {
    const csv = new Blob([props.content], props.options);
    return URL.createObjectURL(csv);
  };

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={getDownloadUrl()}
      download={props.filename}
      onClick={props.onclick}
    >
      {props.children}
    </a>
  );
};
