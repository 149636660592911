/** @jsxImportSource @emotion/react */
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Button, Collapse, Typography } from 'antd';
import { ConstraintInputProp } from './design-constraint-view-container.component';
import { TextContainerComponent } from './text-container.component';
import { DesignConstraintCollapse } from './design-constraint-collapse.component';
import { css } from '@emotion/react';
import { useSession } from '../../../../../_shared/context';

const { Text } = Typography;
const { Panel } = Collapse;

export const DesignAmountConstraintView = ({
  constraint,
  removeConstraint,
  editConstraint,
  disabled,
}: ConstraintInputProp) => {
  const { currentProject } = useSession();
  const ingredientComposition = currentProject?.ingredientComposition.find(
    ing => ing.id === constraint.ingredientCompositionId
  );

  return (
    <DesignConstraintCollapse name={constraint.name}>
      <Panel
        header={
          <TextContainerComponent
            text={`${constraint.name || 'Composition Constraint'}`}
          />
        }
        key="0"
        extra={
          <div>
            <span
              css={css`
                margin-right: 10px;
              `}
            >
              <Button
                onClick={() => editConstraint(constraint)}
                icon={<EditTwoTone />}
                disabled={disabled}
              />
            </span>
            <span>
              <Button
                onClick={() => removeConstraint(constraint)}
                icon={<DeleteTwoTone />}
                disabled={disabled}
              />
            </span>
          </div>
        }
      >
        <Text>
          <div>
            Component{' '}
            <TextContainerComponent text={` ${ingredientComposition?.name}`} />
          </div>
          <div>
            Range{' '}
            <TextContainerComponent text={` ${constraint?.lowerBounds}`} /> to
            <TextContainerComponent text={` ${constraint?.upperBounds}`} />
          </div>
        </Text>
      </Panel>
    </DesignConstraintCollapse>
  );
};
