/** @jsxImportSource @emotion/react */
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import { calculateOutcomes } from '../../components/workspaces/lab-bench/context/workspace.utility';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import {
  WorkspaceContextProps,
  Outcome,
  IterationWithLatestSimulation,
  LatestOptimizationType,
  SimulationFromIterationType,
  SimulationProductVersionResultType,
} from '../../components/workspaces/lab-bench/context/types';
import { parseNumber } from '../utils/util';
import { WorkspaceProjectColors } from '../../../iso/colors';
import { JobStatus, Simulation } from '@prisma/client';
import { useInterval } from '../hooks';
import {
  usepollProjectJobStatusMutation,
  getOptimizationDocument,
  ProjectJobType,
  usefindIterationWithHistQuery,
} from '../../../../__generated__/globalTypes';
import { useSession } from './session-context';
import { LOG_WORKSPACE_CONTEXT } from '../debug/flags';

export const spvSorter = (
  a: SimulationProductVersionResultType,
  b: SimulationProductVersionResultType
) => a.columnNumber - b.columnNumber;

const WorkspaceContext = React.createContext<WorkspaceContextProps>({
  loaded: false,
  showOptimization: false,
  productColors: [],
  usingLegacyApi: false,
  setShowOptimization: () => {},
  setSimulationProductVersions: () => {
    //TODO: fInd better pattern, For easier use I made this a non-optional, but it now requires
    //an initial definition,
    alert('ERROR');
  },
  setSelectedSimulationProductVersion: () => {
    //TODO: fInd better pattern, For easier use I made this a non-optional, but it now requires
    //an initial definition,
    alert('ERROR');
  },
  simulationProductVersions: [],
  getNextSimulationNumber: () => {
    return 1;
  },
  latestOptimization: undefined,

  /**
   * Utility functions to manipulate the context specifically around formulations
   * It would be nice to find another way to initialize this instead of having to create
   * it as a placeholder first
   */

  updateProductFormulation: noop,
  duplicateProductVersion: noop,
  removeProductVersion: noop,
  updateProductVersionName: noop,
  setWorkspaceBenchmark: noop,
  setProductColors: noop,
  setLatestOptimization: noop,
  setUsingLegacyApi: () => {},
  performanceHistory: undefined,
  isWorkspaceOwner: false,
  latestFinishedOptimization: undefined,
  setLatestFinishedOptimization: noop,
});

export const WorkspaceContextProvider = ({
  iteration,
  children,
  modelId,
}: {
  iteration: IterationWithLatestSimulation;
  children?: React.ReactNode;
  modelId?: string;
}) => {
  const apolloClient = useApolloClient();
  const [pollOptimizationStatus] = usepollProjectJobStatusMutation({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  let finishedOptimization;
  let activeOptimization;

  if (iteration?.latestOptimization?.isFinished) {
    finishedOptimization = iteration?.latestOptimization;
  } else {
    activeOptimization = iteration?.latestOptimization;
  }
  const [
    selectedSimulationProductVersion,
    setSelectedSimulationProductVersion,
  ] = useState<SimulationProductVersionResultType>();
  const [simulation, setSimulation] = useState<SimulationFromIterationType>();
  const [loaded, setLoaded] = useState(false);
  const [showOptimization, setShowOptimization] = useState(false);
  const [outcomes, setOutcomes] = useState<Outcome[]>();
  const [latestOptimization, setLatestOptimization] = useState<
    LatestOptimizationType
  >(activeOptimization);
  const [latestFinishedOptimization, setLatestFinishedOptimization] = useState<
    LatestOptimizationType
  >(finishedOptimization);
  const [simulationProductVersions, setRawSimulationProductVersions] = useState<
    SimulationProductVersionResultType[]
  >([]);
  const [productColors, setProductColors] = useState<string[]>([]);
  const [usingLegacyApi, setUsingLegacyApi] = useState(false);
  const { user } = useSession();
  const { data: performanceHistory } = usefindIterationWithHistQuery({
    variables: {
      id: iteration!.id,
    },
  });

  const doSetProductColors = (spvs: SimulationProductVersionResultType[]) => {
    const newProductColors: string[] = [];
    let benchmarkCount = 0;
    //Not sure where or how many benchmarks there will be
    spvs?.forEach((s, i) => {
      if (s.productVersion.isBenchmark) {
        benchmarkCount += 1;
        newProductColors.push(WorkspaceProjectColors.benchmark);
      } else {
        newProductColors.push(
          WorkspaceProjectColors.products[i - benchmarkCount]
        );
      }
    });
    setProductColors(newProductColors);
  };

  const setSimulationProductVersions = (
    spvs: SimulationProductVersionResultType[]
  ) => {
    const sortedSPVs = spvs.sort(spvSorter).map(
      (svp, idx): SimulationProductVersionResultType => ({
        ...svp,
        columnNumber: idx,
      })
    );
    setRawSimulationProductVersions(sortedSPVs);
    doSetProductColors(sortedSPVs);
    setLoaded(true);
  };

  const updateOptimizationInContext = (optimizationId: string) => {
    apolloClient
      .query({
        query: getOptimizationDocument,
        variables: { optimizationId },
      })
      .then(res => {
        setLatestOptimization(res.data.optimization);
      });
  };

  /**
   * Polls for an optimization status if one is currently in progress.
   */
  useInterval(() => {
    if (
      latestOptimization?.projectJob &&
      (latestOptimization?.projectJob.status === JobStatus.PENDING ||
        latestOptimization?.projectJob.status === JobStatus.IN_PROGRESS)
    ) {
      pollOptimizationStatus({
        variables: {
          projectJobId: latestOptimization.projectJob.id,
          projectJobType: ProjectJobType.OPTIMIZATION,
        },
      }).then(res => {
        if (
          latestOptimization.projectJob?.status !==
          res.data?.pollProjectJobStatus.status
        ) {
          updateOptimizationInContext(latestOptimization.id);
        }
      });
    }
  }, 3000);

  /**
   * keep formulations and outcomes up to date when new data is fetched
   */
  useEffect(() => {
    const [newSimulation] = iteration?.simulations;
    setSimulation(newSimulation);

    // apollo/client is freezing results even though according to
    // https://github.com/apollographql/apollo-client/issues/1909 they got rid of it
    const newSimulationProductVersions =
      cloneDeep(newSimulation?.simulationProductVersion) || [];

    setSimulationProductVersions(newSimulationProductVersions);

    if (simulation || newSimulation) {
      const sim = simulation || newSimulation;
      const results = calculateOutcomes(sim, modelId);
      setOutcomes(results);
    }
  }, [iteration, simulation?.simulationProductVersion?.length]);

  /**
   * this needs to be separate I think, cant remember why
   */
  useEffect(() => {
    if (simulation) {
      const results = calculateOutcomes(simulation, modelId);
      setOutcomes(results);
    }
  }, [iteration, simulation]);

  const duplicateProductVersion = (
    { productVersion }: SimulationProductVersionResultType,
    newName: string
  ) => {
    const { isOptimization, formulation, productId, cost } = productVersion;

    const simCopy: any = {
      columnNumber: simulationProductVersions.length,
      productVersion: {
        isBenchmark: false,
        formulation: cloneDeep(formulation),
        isOptimization,
        name: newName,
        productId,
        cost,
        version: 1,
      },
    };

    setSimulationProductVersions([...simulationProductVersions, simCopy]);
  };

  //TODO: Make not name based
  const updateProductFormulation = ({
    productName,
    ingredientId,
    value,
  }: {
    productName: string;
    ingredientId: number;
    value: string | number | undefined;
  }) => {
    const spv = simulationProductVersions.find(
      p => p.productVersion.name === productName
    );
    if (!spv) {
      throw new Error(`Could not find product ${productName} to update`);
    }
    const formulation = cloneDeep(spv.productVersion.formulation);
    formulation.quantities[ingredientId] = (value as string) ?? '';

    //reset the updated formulation back on the simulation
    spv.productVersion.formulation = formulation;

    //TODO: products created on the UI don't have a productId yet
    //We could auto gen one on the client, but it would clash with the logic on the backed that
    //attempts to tie the product version with an existing product.

    //For now use the names as a unique key and we can have the removal of unique product names
    //as a full future feature
    const allOtherVersions = [
      ...simulationProductVersions.filter(
        s => s.productVersion.name !== productName
      ),
    ];
    setSimulationProductVersions([spv, ...allOtherVersions]);
  };

  const removeProductVersion = (name: string) => {
    const productsToKeep = simulationProductVersions.filter(
      s => s.productVersion.name !== name
    );

    //Reset column numbers
    //This assumes that the order of the array is the order of the tabs
    productsToKeep.forEach((p, i) => {
      p.columnNumber = i;
    });

    setSimulationProductVersions(productsToKeep);
  };

  const setWorkspaceBenchmark = (productName: string) => {
    const product = simulationProductVersions.find(
      s => s.productVersion.name === productName
    );

    const otherProducts = simulationProductVersions.filter(
      s => s.productVersion.name !== productName
    );

    if (!product) {
      const e = new Error(
        `Unable to find product ${productName} to set as Benchmark`
      );

      Sentry.captureException(e);
      throw e;
    }

    const updatedProduct = { ...product };
    updatedProduct.productVersion.isBenchmark = true;

    //Rejoin

    const allOthersSetToFalse = [...otherProducts].map(s => {
      s.productVersion.isBenchmark = false;
      return s;
    });

    setSimulationProductVersions(
      // Reset columnNumber such that new benchmark is first and everything else maintains previous order
      [updatedProduct, ...allOthersSetToFalse].map((s, i) => ({
        ...s,
        columnNumber: i,
      }))
    );
  };

  /**
   * Due to products created in the browser not having an ID the best method is to match on
   * unique product names
   * @param name
   * @param oldName
   */
  const updateProductVersionName = (
    newName: string,
    spv: SimulationProductVersionResultType
  ) => {
    const { name: oldName, ...restOfSpvProperties } = spv.productVersion;
    if (
      oldName !== newName &&
      simulationProductVersions?.some(s => s.productVersion.name === newName)
    ) {
      //TODO: Remove UI components from context
      void message.error('Products need unique names within the workspace');
      return;
    }

    //Persist Name Change
    const updatedSpv: SimulationProductVersionResultType = {
      ...spv,
      productVersion: { name: newName, ...restOfSpvProperties },
    };

    const restOfSpvs = simulationProductVersions?.filter(
      s => s.productVersion.name !== oldName
    );

    setSimulationProductVersions([updatedSpv, ...(restOfSpvs ?? [])]);
    // Update simulation & outcomes after name change - else the results carousel will have blank data
    if (simulation) {
      // Update new product name in visualizationOutput either rowise or colwise
      const [
        origVizOutHeader,
        ...origVizOutRecords
      ] = simulation.visualizationOutput as string[][];
      let productNameUpdatedinVizOut = false;
      let newVizOut: string[][];
      const updatedVizOutHeader = origVizOutHeader.map(headerRow => {
        if (headerRow === oldName) {
          headerRow = newName;
          productNameUpdatedinVizOut = true;
        }
        return headerRow;
      });
      if (productNameUpdatedinVizOut) {
        newVizOut = [updatedVizOutHeader, ...origVizOutRecords];
      } else {
        // product was not in the header row, looking for product in the first column of each row
        const updatedVizRecords = origVizOutRecords.map(row => {
          const [firstCol, ...restCol] = row;
          return [firstCol === newName ? newName : firstCol, ...restCol];
        });
        newVizOut = [origVizOutHeader, ...updatedVizRecords];
      }

      const updatedSimulation: SimulationFromIterationType = {
        ...simulation,
        simulationProductVersion: simulation.simulationProductVersion.map(
          _spv => {
            // Update product name in simulation so that we can re-calculate outcomes with new name
            const spvName = _spv.productVersion.name;
            return {
              ..._spv,
              productVersion: {
                ..._spv.productVersion,
                name: spvName === oldName ? newName : spvName,
              },
            };
          }
        ),
        visualizationOutput: newVizOut,
      };
      setSimulation(updatedSimulation);
      const results = calculateOutcomes(updatedSimulation, modelId);
      setOutcomes(results);
    }
  };

  const getNextSimulationNumber = (type: string) => {
    const nameNums: number[] = [];
    simulationProductVersions.forEach(spv => {
      if (spv.productVersion.name.includes(type + ' ')) {
        const prodNum = spv.productVersion.name.match(/\d/g);
        const name = parseNumber(prodNum?.join(''));
        if (name) {
          nameNums.push(name);
        }
      }
    });

    const baseReturn = type === 'Optimization' ? 1 : 2;
    return nameNums.length ? Math.max(...nameNums) + 1 : baseReturn;
  };
  const isWorkspaceOwner = user?.id === iteration?.createdById;
  if (LOG_WORKSPACE_CONTEXT) {
    console.log('Workspace Context', {
      loaded,
      iteration,
      simulation,
      selectedSimulationProductVersion,
      simulationProductVersions,
      productColors,
      showOptimization,
      outcomes,
      updateProductFormulation,
      duplicateProductVersion,
      removeProductVersion,
      updateProductVersionName,
      getNextSimulationNumber,
      latestOptimization,
      usingLegacyApi,
      setUsingLegacyApi,
      performanceHistory,
      isWorkspaceOwner,
      latestFinishedOptimization,
    });
  }
  return (
    <WorkspaceContext.Provider
      value={{
        loaded,
        iteration,
        setLoaded,
        simulation,
        selectedSimulationProductVersion,
        setSelectedSimulationProductVersion,
        simulationProductVersions,
        setSimulationProductVersions,
        productColors,
        setProductColors,
        showOptimization,
        setShowOptimization,
        outcomes,
        updateProductFormulation,
        duplicateProductVersion,
        removeProductVersion,
        updateProductVersionName,
        setWorkspaceBenchmark,
        getNextSimulationNumber,
        latestOptimization,
        setLatestOptimization,
        usingLegacyApi,
        setUsingLegacyApi,
        performanceHistory,
        isWorkspaceOwner,
        latestFinishedOptimization,
        setLatestFinishedOptimization,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);
