import {
  InfoCircleOutlined,
  InfoCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import {
  Button,
  Radio,
  Select,
  Space,
  Switch,
  InputNumber,
  Typography,
  Tooltip,
  Checkbox,
  Divider,
} from 'antd';
import { ConstraintRow } from '../new-experiment/constraint-row-component';
import { emptyConstraint } from '../../workspaces/adaptive-learning/design-utils';
import React, { useEffect, useRef, useState } from 'react';
import {
  Constraint,
  CostOptimizationOption,
  ProjectFeature,
} from '../../../../../__generated__/globalTypes';
import { useExploration, useSession } from '../../../_shared/context';
import { limitDecimals } from '../../../_shared/utils/util';
import { IngredientSearch } from '../../../_shared/components/input/ingredient-search.component';
import { css } from '@emotion/css';
import { useDesign } from '../../../_shared/context/design-context';
import './exploration-constraint-setting.less';
const { Text } = Typography;
export const ExplorationConstraintSetting = () => {
  const {
    constraints,
    enforceStrictly,
    setEnforceStrictly,
    maxNumberOfResults,
    setMaxNumberOfResults,
    nteCost,
    setNteCost,
    costOptimizationOption,
    setCostOptimizationOption,
    setEnforceNteCost,
    fillerIngredient,
    setFillerIngredient,
    disableGoalScenario,
    enforceNteCost,
  } = useScenarioDetail();
  const { currentProject, hasFeature } = useSession();
  const { minCostScore, maxCostScore, currentStep } = useExploration();
  const { runQuickDesign } = useDesign();
  const [showNewConstraint, setShowNewConstraint] = useState(false);
  const newConstraintRef = useRef<HTMLDivElement>(null);
  const handleAddNewConstraint = () => {
    setShowNewConstraint(true);
  };

  useEffect(() => {
    setShowNewConstraint(false);
  }, [constraints]);

  useEffect(() => {
    if (showNewConstraint) {
      newConstraintRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [showNewConstraint]);

  return (
    <div id="constraint-settings-container" style={{ maxWidth: '100%' }}>
      {hasFeature(ProjectFeature.PRICING) && (
        <Space direction="vertical">
          <div style={{ fontWeight: 500 }}>Cost Optimization</div>
          <Radio
            value={CostOptimizationOption.LIMIT}
            checked={costOptimizationOption === CostOptimizationOption.LIMIT}
            onChange={radioChangeEvent =>
              setCostOptimizationOption(radioChangeEvent.target.value)
            }
          >
            Cost not to exceed
          </Radio>
          {costOptimizationOption === CostOptimizationOption.LIMIT && (
            <>
              <div style={{ width: '100%' }}>
                <InputNumber
                  addonAfter={currentProject?.monetaryUnit}
                  style={{ width: '100%' }}
                  value={nteCost}
                  onChange={(value: number) => setNteCost(value)}
                  min={0}
                />
                <Text type="secondary">{`Existing formulations in this project range from $${limitDecimals(
                  minCostScore,
                  2
                )} to $${limitDecimals(maxCostScore, 2)} cost score. `}</Text>
              </div>
              <Tooltip title="Turing will not return recommendations above Not To Exceed value when strict enforcement is enabled.">
                <div style={{ marginTop: 10 }}>
                  <span>Enforce Cost Strictly</span>{' '}
                  <Checkbox
                    checked={enforceNteCost}
                    onChange={() => setEnforceNteCost(!enforceNteCost)}
                  />
                </div>
              </Tooltip>
            </>
          )}

          <Radio
            value={CostOptimizationOption.DO_NOT_OPTIMIZE}
            checked={
              costOptimizationOption === CostOptimizationOption.DO_NOT_OPTIMIZE
            }
            onChange={radioChangeEvent =>
              setCostOptimizationOption(radioChangeEvent.target.value)
            }
          >
            Do not optimize for cost
          </Radio>

          <Radio
            value={CostOptimizationOption.MINIMIZE}
            checked={costOptimizationOption === CostOptimizationOption.MINIMIZE}
            onChange={radioChangeEvent => {
              if (
                radioChangeEvent.target.value ===
                CostOptimizationOption.MINIMIZE
              ) {
                runQuickDesign({
                  passedCostOptimizationOption: CostOptimizationOption.MINIMIZE,
                  reasonMessage: `Cost Optimization set to minimize cost`,
                });
              }
              setCostOptimizationOption(radioChangeEvent.target.value);
            }}
          >
            Minimize cost
          </Radio>
        </Space>
      )}
      <div
        css={css`
          padding-right: 20px;
          padding-bottom: 30px;
        `}
      >
        <Tooltip
          placement="topLeft"
          mouseEnterDelay={0.3}
          title="The name of the filler ingredient"
        >
          <div style={{ marginBottom: 5, marginTop: 10 }}>
            Filler Ingredient <InfoCircleTwoTone />
          </div>
        </Tooltip>
        {currentProject?.ingredientList && (
          <IngredientSearch
            isDisabled={disableGoalScenario}
            additionalCss={css`
              margin: unset;
            `}
            className="constraint-select"
            ingredients={currentProject?.ingredientList}
            defaultValue={fillerIngredient}
            allowClear
            onClear={() => setFillerIngredient(undefined)}
            onSelect={setFillerIngredient}
          />
        )}
      </div>
      <Divider />
      <div style={{ fontWeight: 500 }}>Constraints</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 5,
          fontSize: 12,
        }}
      >
        <div>
          Enforce Constraints Strictly <InfoCircleOutlined />{' '}
        </div>
        <div>
          <Switch
            size={'small'}
            checked={enforceStrictly}
            onChange={e => setEnforceStrictly(e)}
          />
        </div>
      </div>
      {constraints.map((c, index) => (
        <ConstraintRow key={c.id} constraint={c as Constraint} index={index} />
      ))}

      {showNewConstraint && (
        <>
          <ConstraintRow
            constraint={emptyConstraint}
            index={constraints.length === 0 ? 0 : 1}
            defaultShowBody={true}
            onCancel={e => setShowNewConstraint(false)}
          />
        </>
      )}

      <Button icon={<PlusOutlined />} onClick={handleAddNewConstraint}>
        Add Constraint
      </Button>
      <span ref={newConstraintRef}></span>
    </div>
  );
};
