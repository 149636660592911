/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { ConstraintProp } from './design-constraints.component';
import { emptyConstraint } from '../design-utils';
import {
  ConstraintInputType,
  ConstraintType,
} from '../../../../../../__generated__/globalTypes';
import { Alert, InputNumber, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import {
  addValueBody,
  addValueStyle,
  constraintIngredientSearch,
} from './design-constraints.styles';
import { SearchIcon } from '../../../../_shared/style';
import { DesignConstraintInfo } from './design-constraint-info.component';
import { EllipsisMiddle } from '../../../../_shared/utils/component';
import { useSession } from '../../../../_shared/context';

const emptyAmountConstraint: ConstraintInputType = {
  ...emptyConstraint,
  constraintType: ConstraintType.AMOUNT,
};

export const AmountConstraint = ({
  updateConstraint,
  currentConstraint,
}: ConstraintProp) => {
  const { currentProject } = useSession();

  const templateConstraint = useMemo(
    () => cloneDeep(currentConstraint) ?? emptyAmountConstraint,
    [currentConstraint]
  );

  const updateLowerBounds = (val: number) => {
    const { lowerBounds, ...other } = templateConstraint;
    const newLowerBounds = val ?? 0;
    updateConstraint({ lowerBounds: Number(newLowerBounds), ...other });
  };

  const updateUppperBounds = (val: number) => {
    const { upperBounds, ...other } = templateConstraint;
    const newUpperBounds = val ?? 0;
    updateConstraint({ upperBounds: Number(newUpperBounds), ...other });
  };

  const addComposition = (composition: string) => {
    const compObj = currentProject?.ingredientComposition.find(
      ingComposition => ingComposition.name === composition
    );

    templateConstraint.ingredientCompositionId = compObj?.id;

    // Find the list of ingredients in the composition
    const compIngredients = currentProject?.ingredientList
      .map(ingList => {
        const ingredientComp = ingList.ingredientCompositions.find(ic => {
          return ic.ingredientCompositionId === compObj?.id && ic.value > 0;
        });

        if (ingredientComp) {
          return ingList;
        }
      })
      .filter(Boolean);

    //Update the contraints coefficients for each ingredient
    compIngredients?.forEach(ci => {
      const compositionVal = ci?.ingredientCompositions.find(
        ing => ing.ingredientCompositionId === compObj?.id
      )?.value;

      if (compositionVal)
        templateConstraint.coefficients?.push({
          name: ci?.ingredient.name,
          value: compositionVal / 100,
        });

      updateConstraint({
        ...templateConstraint,
      });
    });
  };

  const compositionsWithNonZeroValues = useMemo(() => {
    const setOfCompositions = new Set();
    currentProject?.ingredientList.forEach(ingredient => {
      ingredient.ingredientCompositions.forEach(comp => {
        if (comp.value > 0) {
          setOfCompositions.add(comp.ingredientCompositionId);
        }
      });
    });
    return setOfCompositions;
  }, [currentProject?.ingredientComposition, currentProject?.ingredientList]);

  const compositionSearch = (
    <Select
      placeholder={
        <div>
          <SearchIcon />
          {'  '}Search components
        </div>
      }
      onSelect={addComposition}
      defaultValue={
        currentProject?.ingredientComposition.find(
          ing => ing.id === templateConstraint.ingredientCompositionId
        )?.name
      }
    >
      {currentProject?.ingredientComposition
        .filter(composition =>
          compositionsWithNonZeroValues.has(composition.id)
        )
        .map(iC => (
          <Select.Option value={iC.name}>
            <EllipsisMiddle style={{ width: '90%' }} suffixCount={30}>
              {iC.name}
            </EllipsisMiddle>
          </Select.Option>
        ))}
    </Select>
  );

  const addValues = (
    <div css={addValueStyle}>
      <h3>Set range</h3>
      <div css={addValueBody}>
        <div>
          <p>Lower value</p>
          <InputNumber
            min={0}
            max={100}
            onChange={updateLowerBounds}
            value={templateConstraint.lowerBounds as Number}
            css={css`
              width: 100%;
            `}
          />
        </div>
        <div>
          <p>Higher value</p>
          <InputNumber
            type=""
            min={(templateConstraint.lowerBounds as number) || 0}
            max={100}
            onChange={updateUppperBounds}
            value={templateConstraint.upperBounds as number}
            css={css`
              width: 100%;
            `}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <DesignConstraintInfo
        title="What is a Composition constraint?"
        description="Select a pre-configured component that many ingredients contain, and specify the desired total formulation composition for that component."
        type={ConstraintType.AMOUNT}
      />
      <div css={constraintIngredientSearch}>
        <h3>Select Components </h3>
        {(currentProject?.ingredientComposition?.length || 0) > 0 ? (
          compositionSearch
        ) : (
          <Alert
            message="Please define at least one ingredient composition in order to create a composition constraint"
            type="error"
          />
        )}
      </div>
      {templateConstraint.coefficients?.length !== 0 && addValues}
    </div>
  );
};
