/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useMemo, useEffect } from 'react';

import { Tabs, Modal, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';

import { projectById_project_ingredientList } from '../../../../_shared/hooks/__generated__/projectById';
import { SimpleInputTextModal } from '../../../../_shared/components/modal';

import { Iteration, useIngredients } from '../../../../_shared/hooks';
import { getIngredientCategories_project_ingredientCategory as CategoryType } from '../../../../_shared/hooks/__generated__/getIngredientCategories';

import { FormulationMenu } from '../menu';
import { useWorkspace } from '../../../../_shared/context/workspace-context';
import { IngredientCollapse } from './ingredient-collapse.component';

const { TabPane } = Tabs;
export interface FormulationTabProps {
  iteration: Iteration;
  projectName: string;
  userId: any;
  modelId?: string;
  categories: CategoryType[] | undefined;
  tab: string;
  setTab: (val: string) => void;
}

export const FormulationTabs = ({ props }: { props: FormulationTabProps }) => {
  const { iteration, userId, categories, tab, setTab, modelId } = props;
  const tabClicked = (key: any, event: any) => {
    setTab(key);
  };
  const [showEditProductNameModal, setShowEditProductNameModal] = useState(
    false
  );
  const { ingredients } = useIngredients();
  const {
    simulation,
    simulationProductVersions,
    duplicateProductVersion,
    updateProductVersionName,
    removeProductVersion,
    setWorkspaceBenchmark,
    productColors,
  } = useWorkspace();

  const [benchmarkProduct, setBenchmarkProduct] = useState(
    simulationProductVersions?.find(s => s.productVersion.isBenchmark)
  );

  /**
   * Creating a mapping of every category and the ingredients inside it
   *
   * Using the categories explicity instead of just grouping on the ingreident.category
   * in case there is a category that doesn't have an ingredient in it
   *
   * NOTE: Ingredients are sorted alphabetically in this list
   *
   */
  const ingredientsByCategories = useMemo(() => {
    const ingToCatMap = new Map<string, projectById_project_ingredientList[]>();

    const sortedIngredients = [...(ingredients ?? [])]
      .sort((a, b) => a.ingredient.name.localeCompare(b.ingredient.name))
      .filter(i => i.isActive);

    categories?.forEach(c => {
      ingToCatMap.set(c.name, []);
    });

    sortedIngredients?.forEach(i => {
      const set = ingToCatMap.get(i.category.name);
      if (set) {
        set.push(i);
        ingToCatMap.set(i.category.name, set);
      } else {
        //For sone reason we don't have a category record??
        ingToCatMap.set(i.category.name, [i]);
      }
    });

    return ingToCatMap;
  }, [ingredients, categories]);

  /**
   * Dynamically get the benchmark if it changes on re-render
   */
  useEffect(() => {
    setBenchmarkProduct(
      simulationProductVersions?.find(s => s.productVersion.isBenchmark)
    );
  }, [simulationProductVersions]);

  const ingredientIdsInFormulation = useMemo(() => {
    //Only include ingredients that will have their amounts added for the total
    return new Set(
      ingredients
        ?.filter(i => !i.isTestCondition && i.isActive)
        .map(i => i.ingredient.id) ?? []
    );
  }, [ingredients]);

  const calculateTotal = (quantities?: { [ingredientId: string]: string }) => {
    let total = 0;
    if (quantities) {
      Object.keys(quantities).forEach(ingredientId => {
        if (ingredientIdsInFormulation.has(Number(ingredientId))) {
          total += Number(quantities[ingredientId]);
        }
      });
    }

    return Math.round(total * 100) / 100;
  };

  const simulationTabs = simulationProductVersions?.map(sim => {
    const total = calculateTotal(sim.productVersion.formulation?.quantities);

    const totalDiv = (
      <div
        css={css`
          font-weight: bold;
          margin-top: 10px;
        `}
      >
        Total:{' '}
        <span className={total !== 100 ? 'pastel-red' : 'near-black'}>
          {total.toFixed(2)}%
        </span>
      </div>
    );

    return (
      <TabPane
        tab={
          <span
            css={css`
              padding: 0px 11px;
              border-top: 5px solid ${productColors[sim.columnNumber]};
              border-radius: 4px 4px 0px 0px;
            `}
          >
            <CheckCircleFilled
              css={css`
                color: black;
              `}
              hidden={!sim.productVersion.isBenchmark}
            />
            <span
              css={css`
                border-bottom: ${sim.productVersion.isBenchmark
                  ? '1px dashed #C3C7C8'
                  : 'none'};
              `}
            >
              {sim.productVersion.name}
            </span>
          </span>
        }
        key={sim.productVersion.name}
        style={{ overflow: 'auto' }}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            padding: 10px 20px;
          `}
        >
          <div
            css={css`
              text-align: right;
            `}
          >
            <FormulationMenu
              simulation={simulation}
              iteration={iteration}
              userId={userId}
              modelId={modelId}
              editProductName={() => setShowEditProductNameModal(true)}
              copyCurrentProduct={() => {
                /**
                 * Creates a copy with names like:
                 *   Product > Product copy, Product copy 2, Product copy 3
                 * From here, if you make a copy of "Product copy", new product name is "Product copy 4"
                 */
                const copyAppendText = ' copy';
                let newName = sim.productVersion.name;
                const copyIdx = newName.indexOf(copyAppendText);
                if (copyIdx > 0) {
                  newName = newName.slice(0, copyIdx);
                }
                newName += copyAppendText;
                const existingCopyNames = simulationProductVersions.filter(
                  _spv => _spv.productVersion.name.startsWith(newName)
                );
                if (existingCopyNames.length > 0) {
                  newName += ` ${existingCopyNames.length + 1}`;
                }

                duplicateProductVersion(sim, newName);
                setTab(newName);
              }}
              removeProduct={() => {
                Modal.confirm({
                  title: `Do you want to remove "${sim.productVersion.name}" ?`,
                  content:
                    'Deletions are not persisted until a new simulation is ran',
                  onOk: () => {
                    removeProductVersion(sim.productVersion.name);
                    setTab(
                      simulationProductVersions.find(
                        spv =>
                          spv.productVersion.name !== sim.productVersion.name
                      )?.productVersion.name || ''
                    );
                  },
                  width: '600px',
                });
              }}
              setAsBenchmark={() => {
                try {
                  //Save the effort if already the benchmark
                  if (!sim.productVersion.isBenchmark)
                    setWorkspaceBenchmark(sim.productVersion.name);
                } catch (e) {
                  void message.error(
                    `Unable to set ${sim.productVersion.name} as benchmark`
                  );
                }
              }}
            />
            {totalDiv}
          </div>
        </div>

        {categories && (
          <IngredientCollapse
            productVersion={sim.productVersion}
            categories={categories}
            ingredientsByCategories={ingredientsByCategories}
            benchmarkProduct={benchmarkProduct}
          />
        )}

        {showEditProductNameModal && tab === sim.productVersion.name && (
          <SimpleInputTextModal
            visible
            title="Edit Product Name"
            initialValue={sim.productVersion.name}
            onCancel={() => {
              setShowEditProductNameModal(false);

              //brute forcing it a bit
              Modal.destroyAll();
            }}
            onSubmit={newName => {
              setShowEditProductNameModal(false);
              updateProductVersionName(newName, sim);
              // TODO: Don't change tab if rename was unsuccessful (i.e., when new name already esists)
              setTab(newName);
              //brute forcing it a bit
              Modal.destroyAll();
            }}
          />
        )}
      </TabPane>
    );
  });

  return (
    <Tabs
      css={css`
        &.ant-tabs .ant-tabs-nav {
          padding-right: 45px;
        }
      `}
      activeKey={tab ?? ''}
      onTabClick={tabClicked}
    >
      {simulationTabs}
    </Tabs>
  );
};
