/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Colors } from '../../../iso/colors';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Tag } from 'antd';

const modalTitleStyle = css({
  color: Colors.SWAMP,
  fontSize: '32px',
  textAlign: 'center',
  marginBottom: '0.2em',
});

const modalTitleSecondaryStyle = css({
  color: Colors.STORM_GREY,
  fontSize: '16px',
  textAlign: 'center',
  display: 'block',
  padding: '0 10px',
});

export const ONBOARDING_BACKGROUND_GRADIENT = `linear-gradient(116.82deg, ${Colors.GRADIENT_DARK} 0%, ${Colors.GRADIENT_LIGHT} 100%)`;

export const ModalTitle = ({ text }: { text: string }) => (
  <Title css={modalTitleStyle}>{text}</Title>
);

export const ModalSecondaryTitle = ({ text }: { text: string }) => (
  <Text type="secondary" css={modalTitleSecondaryStyle}>
    {text}
  </Text>
);

export const appModalStyles = css({
  minWidth: 800,

  '.ant-modal-content': {
    backgroundColor: Colors.BLACK,
    borderRadius: '16px',

    '.ant-modal-header': {
      background: Colors.BLACK,
      paddingTop: '24px',
      paddingBottom: '0',
      borderBottom: 'none',
      borderRadius: '16px 16px 0 0',

      '.ant-modal-title': {
        color: Colors.WHITE,
        fontSize: '20px',
        fontWeight: 800,
      },
    },
  },

  '&.view-only': {
    '.ant-modal-footer': {
      display: 'none',
    },
  },
});

type BrandTagProps = {
  title: string;
  isExternal: boolean;
};

export const BrandTag = ({ title, isExternal }: BrandTagProps) => (
  <Tag
    color={Colors[isExternal ? '#E6E7E8' : '#F0D2D1']}
    style={{ color: isExternal ? Colors.GRADIENT_DARK : Colors.LUST }}
  >
    {title}
  </Tag>
);
