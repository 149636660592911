import { DeleteOutlined, EditOutlined, ExportOutlined, FolderOpenOutlined, FolderOutlined } from "@ant-design/icons"
import { useExploration } from "../../../_shared/context";
import { FormulationStatus, useAddFolderToFormulationMutation, useRemoveFolderFromFormulationMutation } from '../../../../../__generated__/globalTypes';;
import { Button, Input } from "antd";
import React from "react"
import { DropTargetMonitor, useDrop } from "react-dnd"
const { Search } = Input;

interface ExperimentFolderProps {
  folder: {
    name: string,
    key: string
  },
  isEditMode?: boolean,
  openedFolder?: boolean
  toogleEditMode: (id: string) => void,
  editFolderName: (updateFolderData: {
    id: string,
    newName: string
  }) => void,
  deleteFolder: (id: string) => void
  handleExpandFolder: (folderKey?: string) => void
}

export const ExperimentFolder: React.FC<ExperimentFolderProps> = ({ folder, isEditMode, openedFolder, toogleEditMode, editFolderName, deleteFolder, handleExpandFolder }) => {

  const iconSyles = { color: '#b1b5b8', fontSize: 20 };
  const { setFormulationStatus } = useExploration();
  const [addFormulationToFolder] = useAddFolderToFormulationMutation()
  const [removeFormulationToFolder] = useRemoveFolderFromFormulationMutation()

  const [{ isOver }, drop] = useDrop({
    accept: 'formulationKey',
    drop: (item: { formulationKey: any }) => {
      handleOnFormulationDrop(item.formulationKey)

    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.getDropResult(),
    }),
  });

  const handleOnFormulationDrop = async (data: any) => {
    console.log(data, folder.name, folder.key)
    if (data.pinned) {
      const formulation = await removeFormulationToFolder({
        variables: {
          id: data.formulationRecord.id
        }
      })
      data.formulationRecord.folderId = null
      setFormulationStatus(data.formulationRecord, FormulationStatus.NEW);
    } else {

      const formulation = await addFormulationToFolder({
        variables: {
          folderId: folder.key,
          id: data.formulationRecord.id
        }
      })
      data.formulationRecord.folderId = folder.key
      setFormulationStatus(data.formulationRecord, FormulationStatus.PINNED);
    }
  }


  return (
    <div
      style={{
        display: 'flex',
      }}
      ref={drop}
    >
      {
        openedFolder ? (
          <FolderOpenOutlined style={iconSyles} />
        ) : (
          <FolderOutlined style={iconSyles} />
        )
      }
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {
          !isEditMode ? (
            <span
              style={{
                marginLeft: '1rem',
              }}
            >
              {folder.name ?? 'New Folder'}
            </span>
          ) : (
            <div style={{
              display: 'flex',
              width: '100%'
            }}>
              <Search
                style={{ width: '100%', padding: '0.5rem' }}
                placeholder={folder.name}
                allowClear
                enterButton="Save"
                size="large"
                onSearch={(value) => editFolderName({
                  id: folder.key,
                  newName: value
                })}
              />
            </div>
          )
        }

        <div
          style={{
            display: 'flex',
          }}
        >
          {
            !isEditMode && (
              <div>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => toogleEditMode(folder.key)}
                  style={iconSyles}
                />
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteFolder(folder.key)}
                  style={iconSyles}
                />
                <Button
                  type="text"
                  icon={<ExportOutlined />}
                  style={iconSyles}
                  onClick={handleExpandFolder}
                />
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}