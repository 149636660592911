/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable jsx-a11y/no-static-element-interactions
/** @jsxImportSource @emotion/react */ import {
  jsx,
  css,
} from '@emotion/react';
import * as React from 'react';
import { logEvent, TrackableEvent } from '../../tracking/usage-tracker';
import { PresetIcon, Colors } from '../../style';
import { useSession, useGraph } from '../../context';
import { KnowledgeDiscovery } from '../../../components/knowledge-discovery';

const containerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  width: '30px',
  // width: '60%',
  background: Colors.WHITE,
  boxShadow: `0px 1px 1px ${
    Colors.FRENCH_GREY
  }, 0px 0px 4px ${`${Colors.SWAMP}0D`}, 0px 1px 8px ${Colors.SWAMP}08`,
  borderRadius: '6px',
  padding: '2px 2px  ',
  cursor: 'pointer',
});

//Disabling count for now, not sure what to tie it to
export const KnowledgeGraphIconBtn = ({ onClick }: { onClick: () => void }) => (
  <div css={containerStyle} onClick={() => onClick()}>
    <PresetIcon />
  </div>
);

export const KnowledgeGraphLaunchBtn = ({
  ingredientId,
}: {
  ingredientId: string;
}) => {
  const [showGraph, setShowGraph] = React.useState(false);
  const { currentProject } = useSession();

  return (
    <React.Fragment>
      <KnowledgeGraphIconBtn
        onClick={() => {
          logEvent(TrackableEvent.INGREDIENT_INSIGHT_CLICKED);
          setShowGraph(true);
        }}
      />
      {showGraph && (
        <KnowledgeDiscovery
          ingredientId={ingredientId}
          visible={showGraph}
          setVisible={setShowGraph}
        />
      )}
    </React.Fragment>
  );
};

export const KnowledgeGraphBtn = ({
  ingredientName,
}: {
  ingredientName: string;
}) => {
  const { graph } = useGraph();

  return graph ? (
    <KnowledgeGraphIconBtn
      onClick={() => graph.hoverNode(graph.cy.getElementById(ingredientName))}
    />
  ) : (
    <KnowledgeGraphLaunchBtn ingredientId={ingredientName} />
  );
};
