/** @jsxImportSource @emotion/react */

import { CopyrightOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { dashboardFooterStyle } from './dashboard-layout.styles';

export const DashboardFooter = () => {
  return (
    <div css={dashboardFooterStyle}>
      <Text style={{ fontSize: 10 }}>
        Turing <CopyrightOutlined /> 2020
      </Text>
    </div>
  );
};
