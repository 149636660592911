/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useIngredients } from '../../../../_shared/hooks';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo, useState } from 'react';
import memoize from 'lodash/memoize';
import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { IngredientCategorySearch } from '../../../../_shared/components/input/ingredient-category-filter.component';
import { ConstraintProp } from './design-constraints.component';
import {
  ConstraintInputType,
  ConstraintType,
  Maybe,
  VariableType,
} from '../../../../../../__generated__/globalTypes';
import {
  FormulationInput,
  InputType,
} from '../../../../_shared/components/input';
import { formatSuffix, safeStrToNum } from '../../../../_shared/utils/util';
import { CloseOutlined } from '@ant-design/icons';
import {
  amountConstraintIngName,
  constraintCloseButton,
  constraintIngredientSearch,
  designConstraintIngInfo,
  designConstraintIngredient,
  equalityConstraintErrorMessage,
  equalityConstraintIngName,
  equalityConstraintIngredient,
  equalityConstraintIngValue,
} from './design-constraints.styles';
import { Colors } from '../../../../_shared/style';
import { DesignConstraintInfo } from './design-constraint-info.component';
import { isWithinBounds } from '../design-validation';
import { emptyConstraint } from '../design-utils';
import { EllipsisMiddle } from '../../../../_shared/utils/component';
import { Bounds } from '../../../../_shared/components/text/bounds';
const emptyEqualityConstraint: ConstraintInputType = {
  ...emptyConstraint,
  constraintType: ConstraintType.EQUALITY,
};

export const ErrorMessage = ({
  lowerLimit,
  upperLimit,
  unit,
}: {
  lowerLimit?: Maybe<number>;
  upperLimit?: Maybe<number>;
  unit?: string | null;
}) => (
  <div css={equalityConstraintErrorMessage}>
    {`Enter a value between ${lowerLimit}${unit} and ${upperLimit}${unit}`}
  </div>
);

export const EqualityConstraint = ({
  updateConstraint,
  currentConstraint,
}: ConstraintProp) => {
  const { activeIngredients, ingredients, categories } = useIngredients();
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);

  const baseConstraintData = {
    ...emptyEqualityConstraint,
    ...cloneDeep(currentConstraint),
  };

  const searchableIngredients = useMemo(() => {
    const unfiltered = activeIngredients
      .filter(i => {
        if (filteredCategories.length > 0) {
          return filteredCategories.includes(i.category.name);
        } else {
          return i;
        }
      })
      .sort((a, b) => a.ingredient.name.localeCompare(b.ingredient.name));

    if (currentConstraint && currentConstraint.values) {
      return unfiltered.filter(
        i =>
          !currentConstraint.values!.find(i2 => i.ingredient.name === i2.name)
      );
    }
    return unfiltered;
  }, [currentConstraint, filteredCategories]);

  const addIngredient = (ingredient: string) => {
    updateConstraint({
      ...baseConstraintData,
      values: [
        ...(baseConstraintData.values ?? []),
        {
          name: ingredient,
          value: '',
        },
      ],
    });
  };

  const removeIngredient = (ingredient: string) => {
    updateConstraint({
      ...baseConstraintData,
      values: baseConstraintData.values?.filter(val => val.name !== ingredient),
    });
  };

  const memoizedIngredientLookup = memoize(name =>
    ingredients.find(i => i.ingredient.name === name)
  );

  const updateInput = (index: number, val?: string | number) => {
    const updatedValues = [...(baseConstraintData.values ?? [])];
    const ingredient = memoizedIngredientLookup(updatedValues[index].name);

    if (ingredient?.type === VariableType.NUMERIC && val !== '') {
      updatedValues[index].value = safeStrToNum(val);
    } else {
      updatedValues[index].value = val;
    }
    updateConstraint({
      ...baseConstraintData,
      values: updatedValues,
    });
  };
  return (
    <div>
      <DesignConstraintInfo
        title="What is a Target constraint?"
        description={`Set one or more ingredients/inputs to a specific value. When you set a target greater than zero, you are explicitly marking that input to be included in recommended formulations. If you set a target value to 0, that input will be excluded from recommended formulations.`}
        type={ConstraintType.EQUALITY}
      />
      <div css={constraintIngredientSearch}>
        <h3>
          Add Inputs{' '}
          {categories.length > 1 && (
            <IngredientCategorySearch
              onChange={selectedCategories =>
                setFilteredCategories(selectedCategories)
              }
              categories={categories}
            />
          )}
        </h3>

        <IngredientSearch
          onSelect={addIngredient}
          ingredients={searchableIngredients}
        />
      </div>
      {currentConstraint?.values &&
        currentConstraint.values.map((constraint, i) => {
          const ingredientData = memoizedIngredientLookup(constraint.name);
          const boundsNotification = ingredientData && (
            <Bounds ingredient={ingredientData} />
          );
          const inputType =
            ingredientData?.type === VariableType.NUMERIC ||
            ingredientData?.values === undefined ||
            ingredientData?.values.length === 0
              ? InputType.PERCENT
              : InputType.CHOICE;

          if (ingredientData) {
            const suffix = formatSuffix(ingredientData.unit);
            return (
              <div
                key={i}
                css={[equalityConstraintIngredient, designConstraintIngredient]}
              >
                <div css={designConstraintIngInfo}>
                  <div css={equalityConstraintIngName}>
                    <EllipsisMiddle suffixCount={20}>
                      {constraint.name}
                    </EllipsisMiddle>
                    <div css={amountConstraintIngName}>
                      {boundsNotification}
                    </div>
                  </div>
                  <div css={equalityConstraintIngValue}>
                    <FormulationInput
                      setValue={value => updateInput(i, value)}
                      value={constraint.value}
                      type={inputType}
                      suffix={suffix}
                      options={ingredientData.values}
                      css={css`
                        width: 60%;
                        float: right;
                      `}
                    />
                    {inputType === InputType.PERCENT &&
                      !isWithinBounds(
                        constraint.value,
                        ingredientData.lowerLimit,
                        ingredientData.upperLimit
                      ) &&
                      constraint.value !== '' && (
                        <ErrorMessage
                          upperLimit={ingredientData.upperLimit}
                          lowerLimit={ingredientData.lowerLimit}
                          unit={suffix}
                        />
                      )}
                  </div>
                </div>
                <div css={constraintCloseButton}>
                  <CloseOutlined
                    onClick={() => {
                      removeIngredient(constraint.name);
                    }}
                    style={{ color: `${Colors.SILVER}` }}
                  />
                </div>
              </div>
            );
          }
        })}
    </div>
  );
};
