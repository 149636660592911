/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Button, Divider, Tabs } from 'antd';
import { DesignContextProvider } from '../../_shared/context/design-context';
import './experiment-list-style.css';
import { useSession } from '../../_shared/context';
import { useNavigate, useLocation } from 'react-router-dom';
import ExperimentListLayout from '../../components/experiments/experiment-list-layout';
import { ScenarioDetailContextProvider } from '../../_shared/context/scenario-detail-context';
import { css } from '@emotion/react';
import {
  EventHistory,
  WorkspaceType,
} from '../../../../__generated__/globalTypes';
import { ProjectHistory } from '../../components/project-history/project-history';
import { useExecuteSolutions } from '../../_shared/context/execute-solutions-context';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';

enum ExecuteSolutionsTabs {
  EXPERIMENTS = 'EXPERIMENTS',
  HISTORY = 'HISTORY',
}

export type EventHistoryRecordType = Omit<
  EventHistory,
  'projectId' | 'createdBy'
> & {
  createdBy: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
};

const ExecuteSolutionsLayout: React.FC = () => {
  const locations = useLocation();
  const { currentProject } = useSession();
  const { selectedIteration } = useExecuteSolutions();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<ExecuteSolutionsTabs>();

  useEffect(() => {
    const endOfPath = locations.pathname.split('/').pop();

    if (
      Object.keys(ExecuteSolutionsTabs).includes(endOfPath?.toUpperCase() ?? '')
    ) {
      setCurrentTab(endOfPath?.toUpperCase() as ExecuteSolutionsTabs);
    }
  }, [locations.pathname]);

  const updateCurrentTab = (newTab: ExecuteSolutionsTabs) => {
    navigate(`/project/${currentProject?.id}/${newTab.toLowerCase()}`);
  };
  const defaultExplorationIteration = currentProject?.iterations?.filter(
    i => i.type === WorkspaceType.EXPLORATION
  )[0].id;

  return (
    <ScenarioDetailContextProvider iteration={selectedIteration}>
      <DesignContextProvider iteration={selectedIteration}>
        <div
          className="container"
          css={css`
            .ant-tabs-nav-list {
              margin-left: 60px;
            }
          `}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '40px 60px 20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: '24px', fontWeight: 600 }}>
                {currentProject?.name}
              </div>
              <div style={{ display: 'flex', gap: 10 }}>
                <Button
                  onClick={() => {
                    logEvent(TrackableEvent.DISCOVER_SOLUTION_CLICKED);
                    navigate(
                      `/project/${currentProject?.id}/explore/${defaultExplorationIteration}`
                    );
                  }}
                >
                  Go To Discover Solutions
                </Button>
              </div>
            </div>
          </div>
          <Tabs
            onChange={activeKey =>
              updateCurrentTab(activeKey as ExecuteSolutionsTabs)
            }
            activeKey={currentTab}
            items={[
              {
                label: 'Experiments',
                key: ExecuteSolutionsTabs.EXPERIMENTS,
                children: <ExperimentListLayout />,
              },
              {
                label: 'History',
                key: ExecuteSolutionsTabs.HISTORY,
                children: <ProjectHistory />,
              },
            ]}
          />
          <Divider style={{ margin: 0 }} />
        </div>
      </DesignContextProvider>
    </ScenarioDetailContextProvider>
  );
};

export default ExecuteSolutionsLayout;
