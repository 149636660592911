/** @jsxImportSource @emotion/react */

import { OnboardingAssetSvg2 } from '../../_shared/style/svg-assets';

import {
  onboadingAssetStyle,
  assetLearnMoreLinkStyle,
} from './onboarding.styles';

const OnboardingAsset = () => (
  <div css={onboadingAssetStyle}>
    <OnboardingAssetSvg2 />
    <h1 style={{ marginTop: 20 }}>Projects allow you to rapidly iterate</h1>
    <h1>on multiple formulations</h1>
    <a
      css={assetLearnMoreLinkStyle}
      href="https://www.turingsaas.com/"
      target="_blank"
    >
      Learn more about the building blocks of Turing
    </a>
  </div>
);

export default OnboardingAsset;
