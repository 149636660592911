/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { Tooltip, Collapse } from 'antd';
import { Colors } from '../../../../../iso/colors';
import { useGraph } from '../../../../_shared/context/graph-context';
import { CategoryDot } from '../../../../_shared/style';
import {
  ingredientNameStyle,
  relativePercentageStyle,
  titleStyle,
} from './top-driver-list.styles';

const { Panel } = Collapse;
export const TopDriversList = () => {
  const { graph, contributionPercentage, hoveredNode } = useGraph();

  const nodes = useMemo(() => graph.nodes, [graph.nodes]);

  const topTenDrivers = useMemo(() => {
    const nodeArr = Array.from(nodes.values());
    // do not consider dependent variable or nodes not directly connected to the outcome
    const filteredNodeArr = nodeArr.filter(
      a => !a.data.isTargetVariable && a.data.isDirectlyConnectedToOutcome
    );

    //replacing weight with direct percentage
    const totalWeight = filteredNodeArr.reduce(
      (w, node) => w + (node.data.weight ?? 0),
      0
    );

    return filteredNodeArr
      .sort((a, b) => b.data.weight - a.data.weight)
      .map(n => {
        //use the actual entropy value aka weight for this
        //replacing the "weight" val, probably should use a new attribute but this works
        const { weight } = n.data;
        const percentage = ((weight ?? 0) / totalWeight) * 100;
        return { ...n.data, weight: Number(percentage.toFixed(2)) };
      })
      .slice(0, 10);
  }, [nodes]);

  const panelItemDivCss = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  `;
  const panelItemNormalCss = css`
    color: ${Colors.WHITE};
  `;
  const panelItemHoveredCss = css`
    color: ${Colors.BLACK};
    background-color: ${Colors.WHITE};
    width: calc(100% + 10px);
    border-radius: 5px;
    padding: 0 5px;
    margin-left: -5px;
    & div,
    span {
      color: ${Colors.BLACK};
      text-shadow: none;
    }
  `;

  return (
    <Collapse accordion defaultActiveKey={['1']}>
      <Panel key="1" header="Top Drivers">
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <div css={titleStyle}>
            {`Above ${contributionPercentage}% Direct Contribution`}
          </div>
          {topTenDrivers
            .filter(n => n.weight >= contributionPercentage)
            .map((node, index) => {
              const longName = node.name.length > 15;
              const formattedName = longName
                ? `${node.name.substring(0, 15)}...`
                : node.name;
              const nameEl = longName ? (
                <Tooltip title={node.name}>
                  {`${index + 1}. ${formattedName}`}
                </Tooltip>
              ) : (
                `${index + 1}. ${formattedName}`
              );

              const divExtraCss =
                hoveredNode && hoveredNode.data().id === node.id
                  ? panelItemHoveredCss
                  : panelItemNormalCss;

              return (
                <div
                  css={[panelItemDivCss, divExtraCss]}
                  onMouseEnter={() => graph.hoverNodeById(node.id)}
                  onMouseLeave={() => graph.unhoverNode()}
                  aria-hidden="true"
                >
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    `}
                  >
                    <CategoryDot color="#fff" />
                    <div css={ingredientNameStyle}>{nameEl}</div>
                  </div>
                  <div css={relativePercentageStyle}>{`${node.weight}%`}</div>
                </div>
              );
            })}
        </div>
      </Panel>
    </Collapse>
  );
};
