import * as Mixpanel from 'mixpanel-browser';
import { captureMessage } from '@sentry/react';
export enum TrackableEvent {
  // User has opened the application and the trackers are initialized
  INITIALIZE = 'SESSION_INIT',

  // User has opened the knowledge graph from the left navigation pane
  KNOWLEDGE_GRAPH_MODAL_OPENED = 'KNOWLEDGE_GRAOH_MODAL_OPENED',

  // The Knowledge Graph Modal has been closed, used for timing how long the user had it opened
  KNOWLEDGE_GRAPH_MODAL_CLOSED = 'KNOWLEDGE_GRAPH_MODAL_CLOSED',

  // User clicked the link to open the knowledge graph
  KNOWLEDGE_GRAPH_LINK_CLICKED = 'KNOWLEDGE_GRAPH_LINK_CLICKED',

  // Ingredient insight comment count, it opens the knowledge graph
  INGREDIENT_INSIGHT_CLICKED = 'INGREDIENT_INSIGHT_CLICKED',

  // User downloaded Raw ML Output
  RAW_OUTPUT_DOWNLOADED = 'RAW_OUTPUT_DOWNLOADED',

  // User downloaded the Simulation output that is displayed in the chart
  DISPLAYED_OUTPUT_DOWNLOADED = 'DISPLAYED_OUTPUT_DOWNLOADED',

  // User downloaded a simulations input file
  SIMULATION_INPUT_FILE_DOWNLOADED = 'SIMULATION_INPUT_FILE_DOWNLOADED',

  // User selected specific products to show in the simulation output graph
  // This is done by clicking the hamburger/filter icon above the simulation result graph
  FILTER_SIMULATION_PRODUCTS = 'FILTER_SIMULATION_PRODUCTS',

  // User has clicked a button to open a specific project in the project list view
  PROJECT_BUTTON_CLICKED = 'PROJECT_BUTTON_CLICKED',

  // User clicked the Edit/View Simulation Button
  FORMULATION_WORKSPACE_OPENED = 'FORMULATION_WORKSPACE_OPENED',

  // User opened an iteration by clicking the name in the iteration table on the dashboard
  ITERATION_TABLE_LINK_CLICKED = 'ITERATION_TABLE_LINK_CLICKED',

  // User opened an iteration by clicking the left side nav link
  ITERATION_LEFT_NAV_CLICKED = 'ITERATION_LEFT_NAV_CLICKED',

  //User enters the lab bench layout
  WORKSPACE_OPENED = 'WORKSPACE_OPENED',

  //User has uploaded a CSV to their workspace
  WORKSPACE_CSV_UPLOADED = 'WORKSPACE_CSV_UPLOADED',

  //User has opted not to start the workspace with an existing formulation
  WORKSPACE_INTITIAl_FORMULATION_SKIPPED = 'WORKSPACE_INTITIAl_FORMULATION_SKIPPED',

  //User opened the Expert Knowledge input view
  EXPERT_KNOWLEDGE_INPUT_VIEW_OPENED = 'EXPERT_KNOWLEDGE_INPUT_VIEW_OPENED',

  //User exited the Expert Knowledge input View
  EXPERT_KNOWLEDGE_INPUT_VIEW_CLOSED = 'EXPERT_KNOWLEDGE_INPUT_VIEW_CLOSED',

  //User clicked the link in the left side nav to access the expert knowledge input screen
  EXPERT_KNOWLEDGE_INPUT_LINK_CLICKED = 'EXPERT_KNOWLEDGE_INPUT_LINK_CLICKED',

  //User closed out of the Optimization before running it
  OPTIMIZATION_CANCELED = 'OPTIMIZATION_CANCELED',

  //User creates an optimization in a workspace

  //User starts off the workspace with an optimizatione

  //User opens the Performance History in a Simulation
  PERFORMANCE_HISTORY_OPENED = 'PERFORMANCE_HISTORY_OPENED',

  //User closed the Performance History in a simulation
  PERFORMANCE_HISTORY_CLOSED = 'PERFORMANCE_HISTORY_CLOSED',

  //User clicked on one of the change tabs in performance history
  PERFORMANCE_HISTORY_CHANGE_CLICKED = 'PERFORMANCE_HISTORY_CHANGE_CLICKED',

  //User clicked 'Use as Filler' on an ingredient from the optimization panel
  USE_AS_FILLER_CLICKED = 'USE_AS_FILLER_CLICKED',

  //User downloaded CSV template for ingredient composition upload
  DOWNLOADED_INGREDIENT_COMPOSITION_CSV_TEMPLATE = 'DOWNLOADED_INGREDIENT_COMPOSITION_CSV_TEMPLATE',

  //User downloaded CSV template for data upload
  DOWNLOADED_FORMULATION_CSV_TEMPLATE = 'DOWNLOADED_FORMULATION_CSV_TEMPLATE',

  //User downloads Adaptive Learning Results
  DOWNLOADED_ADAPTIVE_LEARNING_RESULTS = 'DOWNLOADED_ADAPTIVE_LEARNING_RESULTS',

  // User uplaods experiment results
  ADAPTIVE_LEARNING_CSV_UPLOADED = 'ADAPTIVE_LEARNING_CSV_UPLOADED',

  // User downloads template from AL modal
  ADAPTIVE_LEARNING_EXPERIMENT_TEMPLATE_DOWNLOADED = 'ADAPTIVE_LEARNING_EXPERIMENT_TEMPLATE_DOWNLOADED',

  EXECUTE_SOLUTION_CLICKED = 'EXECUTE_SOLUTION_CLICKED',
  EXECUTE_SOLUTION_VIEW_FORMULATION = 'EXECUTE_SOLUTION_VIEW_FORMULATION',
  DISCOVER_SOLUTION_CLICKED = 'DISCOVER_SOLUTION_CLICKED',
  DISCOVER_SOLUTION_FULL_ADAPTIVE_LEARNING_RUN = 'DISCOVER_SOLUTION_ADAPTIVE_LEARNING_RUN',
  DISCOVER_SOLUTION_QUICK_ADAPTIVE_LEARNING_RUN = 'DISCOVER_SOLUTION_QUICK_ADAPTIVE_LEARNING_RUN',
  DISCOVER_SOLUTION_FORMULATION_TOGGLE_PINNED = 'DISCOVER_SOLUTION_FORMULATION_TOGGLE_PINNED',
  DISCOVER_SOLUTION_VIEW_FORMULATION = 'DISCOVER_SOLUTION_VIEW_FORMULATION',
}

export const logEvent = (
  event: TrackableEvent,
  /**
   * Params can be any additional data to track
   *
   *  ex. event = RAN_SIMULATION, params = {number_of_products: 4}
   */
  params?: object
) => {
  if (__ENV__ === 'development') {
    console.log(JSON.stringify(event));
  }
  if (__MIXPANEL_KEY__) {
    try {
      Mixpanel.track(event, params);
    } catch (e: any) {
      console.log('Error tracking Mixpanel event', e);
      captureMessage(e);
    }
  }
};
