/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Fragment, useState, useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';

import { Colors } from '../../../../iso/colors';
import { centerInContainer } from '../../../_shared/style/positioning.styles';

import {
  useSession,
  useGraph,
  GraphContextProvider,
} from '../../../_shared/context';
import {
  useIngredients,
  useProjectExpertKnowledgeSummary,
} from '../../../_shared/hooks';
import { getExpertKnowledgeSummary_project_expertKnowledgeTeamSubmission_userExpertKnowledge as Formulation } from '../../../_shared/hooks/__generated__/getExpertKnowledgeSummary';
import { DashboardPanel } from '../../dashboard/dashboard-panel.component';
import { EdgeComment } from '../../comment/comment-list.component';
import { IngredientSelect } from './ingredient-select.component';
import {
  SummaryTable,
  Rank,
  Percent,
  PercentChange,
} from './summary-view-table.component';
import { ViewNotes } from './view-notes.component';

const plural = (str: string, count: number) => str + (count === 1 ? '' : 's');

enum PanelType {
  FillKnowledgeGaps,
  TopContributors,
  TopDrivers,
  LeastImpact,
  ConflictingOpinions,
}

const nameFor = (type: PanelType) => {
  switch (type) {
    case PanelType.ConflictingOpinions:
      return 'areas of conflict';
    case PanelType.FillKnowledgeGaps:
      return 'knowledge gaps';
    case PanelType.LeastImpact:
    case PanelType.TopDrivers:
      return 'drivers';
    case PanelType.TopContributors:
    default:
      return 'contributors';
  }
};

function limitArr<T>(rows: T[], limit?: number) {
  return limit ? rows.slice(0, limit) : rows.slice();
}

/**
 * Need to wrap in graph context provider to give access to the network graph
 */
export const SummaryViewLayout = () => (
  <GraphContextProvider>
    <SummaryViewLayoutComponent />
  </GraphContextProvider>
);

const SummaryViewLayoutComponent = () => {
  const { currentProject, user } = useSession();
  const { graph } = useGraph();
  const { ingredients } = useIngredients();
  const [
    sortedIngredientsWithOutcomes,
    setSortedIngredientsWithOutcomes,
  ] = useState<string[]>(ingredients.map(i => i.ingredient.name).sort());
  const {
    data: expertKnowledgeSummary,
    loading: expertKnowledgeSummaryLoading,
  } = useProjectExpertKnowledgeSummary(currentProject!.id);

  const [topDriverIngredient, setTopDriverIngredient] = useState<
    string | undefined
  >();
  const [leastImpactIngredient, setLeastImpactIngredient] = useState<
    string | undefined
  >();
  const [popupPanel, setPopupPanel] = useState<PanelType | undefined>(
    undefined
  );

  useEffect(() => {
    //Set the initial outcome to look at
    if (currentProject) {
      const { activeModel } = currentProject;
      if (activeModel) {
        const { outcomes } = activeModel;
        const firstOutcome = outcomes[0]?.targetVariable;
        if (!firstOutcome) {
          console.log('NO ACTIVE OUTCOME');
          return;
        }
        if (topDriverIngredient === undefined) {
          setTopDriverIngredient(firstOutcome);
        }
        if (leastImpactIngredient === undefined) {
          console.log(`Setting least impact`);
          setLeastImpactIngredient(firstOutcome);
        }

        const list = [
          ...outcomes.map(o => o.targetVariable),
          ...sortedIngredientsWithOutcomes,
        ];
        setSortedIngredientsWithOutcomes(list);
      } else {
        console.log(`NO ACTIVE MODEL`);
      }
    }
  }, [currentProject]);

  if (
    !user ||
    expertKnowledgeSummaryLoading ||
    !currentProject ||
    !currentProject.activeModel?.formulationGraph ||
    !graph
  )
    return (
      <Spin
        css={css`
          ${centerInContainer}
        `}
      />
    );

  //Get the last submission
  const teamSubmission =
    expertKnowledgeSummary?.project?.expertKnowledgeTeamSubmission?.[0];

  const summaries = teamSubmission?.expertKnowledgeSummary ?? [];
  const topInsights = teamSubmission?.topInsights ?? [];

  // TODO:  memoize these calculations
  const formulations =
    expertKnowledgeSummary?.project?.expertKnowledgeTeamSubmission.reduce(
      (set, submission) => {
        set.push(...submission.userExpertKnowledge);
        return set;
      },
      [] as Formulation[]
    ) || [];

  const knowledgeCount = formulations.reduce(
    (count, val) => count + val.knowledges.length,
    0
  );

  const comments = formulations.reduce((arr, formulation) => {
    arr.push(
      ...formulation.knowledges.map(k => ({
        firstName: formulation.user.firstName ?? undefined,
        lastName: formulation.user.lastName ?? undefined,
        ...k,
      }))
    );
    return arr;
  }, [] as EdgeComment[]);

  let conflictingOpinions = 0;
  let knowledgeGapCount = 0;
  const relationshipKnowledgeCounts = new Map<string, number>();
  for (const summary of summaries) {
    if (summary.conflict) conflictingOpinions++;
    if (summary.needsMoreInfo) knowledgeGapCount++;
    relationshipKnowledgeCounts.set(
      `${summary.childName}|${summary.parentName}`,
      0
    );
  }

  for (const edge of graph.edges.values()) {
    relationshipKnowledgeCounts.set(
      `${edge.data.source}|${edge.data.target}`,
      0
    );
  }

  for (const formulation of formulations) {
    for (const knowledge of formulation.knowledges) {
      const key = `${knowledge.childName}|${knowledge.parentName}`;
      relationshipKnowledgeCounts.set(
        key,
        relationshipKnowledgeCounts.get(key)! + 1
      );
    }
  }

  const Panel = ({
    header,
    table: Table,
    headerDirection,
    type,
  }: {
    table: (props: { limit?: number }) => JSX.Element;
    header: string | JSX.Element;
    headerDirection?: 'row';
    type: PanelType;
  }) => (
    <DashboardPanel
      sideBySide
      header={header}
      headerDirection={headerDirection}
    >
      <Table limit={5} />
      {/*TODO:  do we have a link button style or class somewhere? */}
      <Button
        css={css`
          border: none;
          padding: 0;
          box-shadow: none;
          color: ${Colors.BRANDEIS_BLUE};
          margin-top: 12px;
        `}
        onClick={() => setPopupPanel(type)}
      >
        See all {nameFor(type)}
      </Button>
      {popupPanel === type && (
        <Modal
          title={header}
          open
          onCancel={() => setPopupPanel(undefined)}
          cancelText="Dismiss"
          okButtonProps={{ style: { display: 'none' } }}
        >
          <Table />
        </Modal>
      )}
    </DashboardPanel>
  );

  return (
    <div
      css={css`
        .dashboard-panel-content {
          padding: 16px;
          min-height: 350px;
        }

        .compact .dashboard-panel-content {
          min-height: 0;
        }
      `}
    >
      <DashboardPanel header="Expert Knowledge Summary" compact>
        <div
          css={css`
            display: flex;

            .summary-fact {
              margin: 16px;
              padding: 8px 32px;
              flex-grow: 1;

              div {
                font-size: 16px;
                font-weight: bold;
                color: ${Colors.SWAMP};
              }

              label {
                color: ${Colors.STORM_GREY};
                font-size: 12px;
              }
            }

            .summary-fact + .summary-fact {
              border-left: 1px solid ${Colors['#EBEAEF']};
            }
          `}
        >
          <div className="summary-fact">
            <div>
              {teamSubmission?.expertsContributedOverride ??
                formulations.length}
            </div>
            <label>{plural('Expert', formulations.length)} Contributed</label>
          </div>
          <div className="summary-fact">
            <div>
              {teamSubmission?.piecesOfKnowledgeOverride ?? knowledgeCount}
            </div>
            <label>{plural('Piece', knowledgeCount)} of Knowledge</label>
          </div>
          <div className="summary-fact">
            <div>{conflictingOpinions}</div>
            <label>Conflicting {plural('Opinion', conflictingOpinions)}</label>
          </div>
          <div className="summary-fact">
            <div>{knowledgeGapCount}</div>
            <label>Knowledge {plural('Gap', knowledgeGapCount)}</label>
          </div>
        </div>
      </DashboardPanel>

      <DashboardPanel sideBySide header="Top Insights">
        {topInsights.length ? (
          topInsights.map(insight => <div className="insight">{insight}</div>)
        ) : (
          <div>No insights created yet.</div>
        )}
      </DashboardPanel>

      <Panel
        type={PanelType.FillKnowledgeGaps}
        header="Fill Knowledge Gaps"
        table={({ limit }) => (
          <SummaryTable>
            <thead>
              <tr>
                <th>We need to learn more about the relationship between</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {limitArr(summaries, limit)
                .filter(s => s.needsMoreInfo)
                .map(s => (
                  <tr>
                    <td>
                      {s.parentName} & {s.childName}
                    </td>
                    <td style={{ width: 30 }}>
                      <ViewNotes
                        nodeId={s.childName}
                        edgeComments={comments.filter(
                          k =>
                            k.parentName === s.parentName &&
                            k.childName === s.childName &&
                            k.comment
                        )}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </SummaryTable>
        )}
      />

      <Panel
        type={PanelType.TopDrivers}
        header={
          <Fragment>
            <div>Top Drivers of </div>
            <IngredientSelect
              ingredientList={sortedIngredientsWithOutcomes}
              value={topDriverIngredient}
              setValue={setTopDriverIngredient}
            />
          </Fragment>
        }
        headerDirection="row"
        table={({ limit }) => (
          <SummaryTable>
            <thead>
              <th>Drivers</th>
              <th>Previous Rank</th>
              <th>Change in relationship</th>
            </thead>
            <tbody>
              {limitArr(
                summaries.filter(s => s.parentName === topDriverIngredient),
                limit
              ).map(s => (
                <tr>
                  <td>
                    <Rank rank={s.rank} /> {s.childName}
                  </td>
                  <td>{s.previousRank}</td>
                  <td>
                    <PercentChange value={s.changeInRelationship} />
                  </td>
                </tr>
              ))}
            </tbody>
          </SummaryTable>
        )}
      />

      <Panel
        type={PanelType.LeastImpact}
        header={
          <Fragment>
            <div>Least Impact on </div>
            <IngredientSelect
              ingredientList={sortedIngredientsWithOutcomes}
              value={leastImpactIngredient}
              setValue={setLeastImpactIngredient}
            />
          </Fragment>
        }
        headerDirection="row"
        table={({ limit }) => (
          <SummaryTable>
            <thead>
              <th>Drivers</th>
              <th>Previous Rank</th>
              <th>Change in relationship</th>
            </thead>
            <tbody>
              {limitArr(
                summaries
                  .filter(s => s.parentName === leastImpactIngredient)
                  .reverse(),
                limit
              ).map(s => (
                <tr>
                  <td>
                    <Rank rank={s.rank} /> {s.childName}
                  </td>
                  <td>{s.previousRank}</td>
                  <td>
                    <PercentChange value={s.changeInRelationship} />
                  </td>
                </tr>
              ))}
            </tbody>
          </SummaryTable>
        )}
      />

      <Panel
        type={PanelType.ConflictingOpinions}
        header="Conflicting Opinions"
        table={({ limit }) => (
          <SummaryTable>
            <thead>
              <th>Relationship between</th>
              <th>% conflict</th>
            </thead>
            <tbody>
              {limitArr(
                summaries
                  .filter(s => s.conflict)
                  .sort((a, b) => b.conflict - a.conflict),
                limit
              ).map(s => (
                <tr>
                  <td>
                    {s.childName} & {s.parentName}
                  </td>
                  <td>
                    <Percent value={s.conflict} />
                  </td>
                </tr>
              ))}
            </tbody>
          </SummaryTable>
        )}
      />

      <Panel
        type={PanelType.TopContributors}
        header="Top Contributors"
        table={({ limit }) => (
          <SummaryTable>
            <thead>
              <th>Name</th>
              <th>Knowledge Added</th>
            </thead>
            <tbody>
              {limitArr(formulations, limit).map(f => (
                <tr>
                  <td>{`${f.user.firstName!} ${f.user.lastName!}`}</td>
                  <td>{f.knowledges.length}</td>
                </tr>
              ))}
            </tbody>
          </SummaryTable>
        )}
      />
    </div>
  );
};
