import { Core } from 'cytoscape';

export interface Position {
  x: number;
  y: number;
}

export const translateRenderedToModel = (cy: Core, pos: Position) => {
  const pan = cy.pan();
  const zoom = cy.zoom();
  return {
    x: (pos.x - pan.x) / zoom,
    y: (pos.y - pan.y) / zoom,
  };
};

export const translateModelToRendered = (cy: Core, pos: Position) => {
  const pan = cy.pan();
  const zoom = cy.zoom();
  return {
    x: pos.x * zoom + pan.x,
    y: pos.y * zoom + pan.y,
  };
};
