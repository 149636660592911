/** @jsxImportSource @emotion/react */

import { useSession } from '../../../../../_shared/context';
import { PredictedPerformanceBarChart } from './predicted-bar-chart.component';
import { AerosolsFlammabilityBarChart } from './aerosols-flammability-chart.component';
import {
  AEROSOLS_CAN_LIFE_SPRAY_RATE,
  AEROSOLS_DIPTUBE_GROWTH,
  AEROSOLS_FLAMMABILITY,
  AEROSOLS_GASKET_SWELL_36550,
  AEROSOLS_GASKET_SWELL_KA6712,
  AEROSOLS_GLASS_HALFWAY,
  AEROSOLS_GLASS_NUMBER_OF_TURNS,
} from '../../../../../../iso/model-ids';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { DiptubeGrowthBarChart } from './aerosols-diptube-growth-chart.component';
import { GasketSwellBarChart } from './aerosols-gasket-swell-chart.component';
import { GlassBarChart } from './aerosols-glass-chart.component';
import { CanLifeSprayRate } from './aerosols-can-life-spray-rate-chart.component';
import { SimulationFromIterationType } from '../../context/types';
/**
 * Default bar chart to show simulation results
 * Used for all projects unless a customization overrides it
 * @returns
 */
export const PredictedPerformanceBarChartContainer = () => {
  const { currentProject } = useSession();
  const { simulation } = useWorkspace();

  let Chart: (props: {
    simulation: SimulationFromIterationType;
  }) => JSX.Element;

  switch (currentProject?.activeModel?.id) {
    case AEROSOLS_FLAMMABILITY:
      Chart = AerosolsFlammabilityBarChart;
      break;
    case AEROSOLS_DIPTUBE_GROWTH:
      Chart = DiptubeGrowthBarChart;
      break;
    case AEROSOLS_CAN_LIFE_SPRAY_RATE:
      Chart = CanLifeSprayRate;
      break;
    case AEROSOLS_GLASS_HALFWAY:
    case AEROSOLS_GLASS_NUMBER_OF_TURNS:
      Chart = GlassBarChart;
      break;
    case AEROSOLS_GASKET_SWELL_36550:
    case AEROSOLS_GASKET_SWELL_KA6712:
      Chart = GasketSwellBarChart;
      break;
    default:
      Chart = PredictedPerformanceBarChart;
  }

  /**
   * I tried abstracting the <ResponsiveContainer> out to this
   * level but it would render as blank
   */
  return <Chart simulation={simulation!} />;
};
