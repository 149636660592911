import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  runSimulation,
  runSimulationVariables,
} from './__generated__/runSimulation';

export const useRunSimulationQuery = gql`
  mutation runSimulation(
    $iterationId: String!
    $projectId: String!
    $formulationData: [SimulationFormulationInputType!]!
  ) {
    runSimulation(
      formulationData: $formulationData
      iterationId: $iterationId
      projectId: $projectId
    ) {
      id
      output
      visualizationOutput
      createdById
      simulationProductVersion {
        columnNumber
        productVersion {
          name
          productId
          isBenchmark
          isOptimization
          version
          cost
          formulation {
            id
            quantities
          }
        }
        outcomes {
          outcomeName
          outcomeType
          value
          applicability
          reliability
          distribution {
            probability
            state
          }
        }
      }
    }
  }
`;
export const useRunSimulation = (
  options: MutationHookOptions<runSimulation, runSimulationVariables>
) =>
  useMutation<runSimulation, runSimulationVariables>(
    useRunSimulationQuery,
    options
  );
