import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const organizationTileStyle = css`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 12px;

  img {
    height: 32px;
    margin-right: 5px;
  }

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const turingLogoStyle = css`
  display: flex;
  margin-right: 43px;
`;

export const headerMenuStyle = css`
  display: flex;
  margin-right: 23px;
  height: 30px;
  width: 30px;
  cursor: pointer;
`;

export const headerMenuButtonStyle = css`
  margin-left: 10px;
  background: ${Colors.DARK_CYAN};
  border: ${Colors.DARK_CYAN};
  color: ${Colors.WHITE};
`;
