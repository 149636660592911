import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postCreateCampaign: () =>
    `org/campaign`,
  getCampaign: (campaignId: string) =>
    `org/campaign/${campaignId}`,
  putCampaign: (campaignId: string) =>
    `org/campaign/${campaignId}`,
};

const postCreateCampaign = (data: any) => {
  return BaseClient.post(
    ENDPOINTS.postCreateCampaign(),
    data
  );
};
const getCampaign = (data: any) => {
  return BaseClient.get(
    ENDPOINTS.getCampaign(data.queryKey[1])
  );
};
const putCampaign = (data: any) => {
  return BaseClient.put(
    ENDPOINTS.putCampaign(data.campaignId), {
    numberIterations: data.numberIterations,
    numberFormulations: data.numberFormulations
  }
  );
};


export const CampaignRepository = {
  postCreateCampaign,
  getCampaign,
  putCampaign
};
