/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { useEffect, useState } from 'react';

import { message, Spin, notification } from 'antd';
import {
  ProjectFeature,
  VariableType,
  findIterationWithHistDocument,
  findIterationWithLatestSimulationDocument,
  usefindIterationWithLatestSimulationQuery,
} from '../../../../../__generated__/globalTypes';

import { useSession } from '../../../_shared/context';
import {
  useRunSimulation,
  useIngredients,
  useIngredientCategories,
  useGetProjectMLAPIConfigs,
} from '../../../_shared/hooks';
import { WorkspaceSetup } from './workspace-setup.component';

import {
  workspaceTabsStyle,
  addFormulationStyle,
} from './styles/workspace-panel.styles';

import { useWorkspace } from '../../../_shared/context/workspace-context';
import {
  AddProductVersionButton,
  ProductVersionButtonOptions,
} from '../../../_shared/components/button/product-version.button';
import { PredictedCarousel } from './predicted-outcomes';
import { FormulationTabProps, FormulationTabs } from './formulation-tabs';
import { OptimizationModalV1 } from './optimization/v1/optimization-modal-v1.component';
import { JobStatus, ProjectMLAPIConfigName } from '@prisma/client';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { OptimizationPanel } from './optimization/v2/optimization-panel.component';
import { isWithinBounds } from '../adaptive-learning/design-validation';
import { SimulationProductVersionResultType } from './context/types';

export const WorkspacePanelComponent = () => {
  const { currentProject, user } = useSession();
  const { isWorkspaceOwner } = useWorkspace();
  if (!currentProject) return <div className="no-project" />;

  const { ingredients, ingredientById } = useIngredients();
  const { data: allCategories } = useIngredientCategories(currentProject.id);
  const {
    loaded,
    iteration,
    simulationProductVersions,
    latestOptimization,
    showOptimization,
    setShowOptimization,
    setSimulationProductVersions,
    getNextSimulationNumber,
    setSelectedSimulationProductVersion,
    usingLegacyApi,
    setUsingLegacyApi,
  } = useWorkspace();
  const navigate = useNavigate();

  const [tab, setTab] = useState<string>(() => {
    return simulationProductVersions[0]?.productVersion?.name;
  });

  const [runSimulation, { loading: isSimulationRunning }] = useRunSimulation({
    onError: err => {
      notification.error({ description: err.extraInfo, message: err.message });
    },
    //After running simulation refetch the iteration,
    //technically this should not be needed but we have to figure out
    //the apollo cache
    refetchQueries: [
      {
        query: findIterationWithLatestSimulationDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
      {
        query: findIterationWithHistDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const [showUploadState, setShowUploadState] = useState(
    !simulationProductVersions?.length
  );

  useEffect(() => {
    setShowUploadState(!simulationProductVersions?.length);
    if (
      !tab ||
      (simulationProductVersions &&
        // the currently set simulation tab is missing from SPV
        simulationProductVersions.some(
          spv => tab === spv.productVersion.name
        ) === false)
    ) {
      setTab(simulationProductVersions[0]?.productVersion?.name);
    }
  }, [simulationProductVersions]);

  const validateBounds = (
    quantities: { [key: string]: string },
    name?: string
  ) => {
    const errors: string[] = [];
    Object.entries(quantities).forEach(([ingredientId, value]) => {
      let ingredient = ingredientById.get(ingredientId);
      if (
        ingredient?.type === VariableType.NUMERIC &&
        !isWithinBounds(value, ingredient?.lowerLimit, ingredient?.upperLimit)
      ) {
        errors.push(
          `${name} ingredient ${
            ingredient?.ingredient?.name
          } must have a value between ${ingredient?.lowerLimit}${
            ingredient?.unit ?? ''
          } and ${ingredient?.upperLimit}${ingredient?.unit ?? ''}`
        );
      }
    });
    return errors;
  };

  /**
   * Runs the simulation with the existing formulations in context
   */
  const runSim = () => {
    if (loaded) {
      let accumultedErrors: string[] = [];

      simulationProductVersions.forEach(spv => {
        const { productVersion: pv } = spv;
        const errors = validateBounds(
          pv.formulation?.quantities ?? {},
          spv.productVersion.name
        );
        accumultedErrors = [...accumultedErrors, ...errors];
      });
      if (accumultedErrors.length > 0) {
        message.error(accumultedErrors[0]);
        console.error(accumultedErrors.join(' '));
        return;
      }
      void runSimulation({
        variables: {
          projectId: currentProject.id,
          iterationId: iteration!.id,
          formulationData: simulationProductVersions.map(spv => {
            const { productVersion: pv } = spv;

            return {
              columnNumber: spv.columnNumber,
              formulation: pv.formulation?.quantities,
              formulationId: pv.formulation.id,
              isBenchmark: pv.isBenchmark,
              isOptimization: pv.isOptimization,
              name: pv.name,
              productId: pv.productId,
            };
          }),
        },
      });
    } else {
      //TODO fix flow so not needed
      void message.error('Not loaded yet');
    }
  };

  useEffect(() => {
    setSelectedSimulationProductVersion(
      simulationProductVersions.find(s => s.productVersion.name === tab)
    );
  }, [tab, runSim]);

  const { data: mlapiConfigs } = useGetProjectMLAPIConfigs(
    `${currentProject?.id}`
  );

  useEffect(() => {
    const version = mlapiConfigs?.project?.mlapiConfigs
      .find(c => c.name === ProjectMLAPIConfigName.OPTIMIZATION_API)
      ?.value.toLowerCase();
    setUsingLegacyApi(version !== 'v2');
  }, [mlapiConfigs]);

  /**
   * TODO: Right now these don't do anything, and its a pattern through the app
   * Need to have actual error handlers or something.
   */
  if (!iteration) return <div className="no-iteration" />;
  if (!ingredients) return <div className="no-ingredients" />;
  if (!loaded) return <div className="loading" />;
  if (!allCategories) return <div className="no-category" />;

  const categories = allCategories?.project?.ingredientCategory
    .slice()
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const addNewProduct = () => {
    const numOfProducts = simulationProductVersions.length;
    const newProductName = `Product ${getNextSimulationNumber('Product')}`;

    const newProductInfo = [
      ...simulationProductVersions,
      {
        columnNumber: numOfProducts,
        productVersion: {
          name: newProductName,
          isBenchmark: false,
          isOptimization: false,
          version: 1,
          formulation: { quantities: {} },
        },
      } as SimulationProductVersionResultType,
    ];

    setSimulationProductVersions(newProductInfo);
    setTab(newProductName);
  };

  const formulationTabInfo: FormulationTabProps = {
    iteration: iteration,
    projectName: currentProject.name,
    userId: user?.id,
    modelId: currentProject.activeModel?.id,
    categories,
    tab,
    setTab,
  };

  const addProductsOptions: ProductVersionButtonOptions[] = [
    {
      label: 'Add Manual Simulation',
      onClick: addNewProduct,
      disabled: false,
    },
  ];

  if (
    currentProject?.features.some(
      f => f.feature === ProjectFeature.OPTIMIZATION
    )
  ) {
    const isOptimizationInProgress =
      latestOptimization?.projectJob?.status === JobStatus.IN_PROGRESS ||
      latestOptimization?.projectJob?.status === JobStatus.PENDING;

    addProductsOptions.push({
      label: 'Turing Optimized Formulation',
      onClick: () => {
        if (usingLegacyApi) {
          setShowOptimization(true);
        } else {
          navigate(
            `/project/${currentProject?.id}/iteration/${iteration?.id}/optimization`
          );
        }
      },
      toolTipMessage: isOptimizationInProgress
        ? 'Please wait for the current optimization to finish before starting a new one'
        : undefined,
      /**
       * Only the owner should be allowed to run optimizations
       * Optimizations can only be ran one at a time
       */
      disabled: !isWorkspaceOwner || isOptimizationInProgress,
    });
  }

  const LeftSideContent = !showUploadState ? (
    <div className="ingredient-panel">
      <FormulationTabs props={formulationTabInfo} />
      <span css={addFormulationStyle}>
        <AddProductVersionButton
          options={addProductsOptions}
          key={'addProductVersionButton'}
        />
      </span>
    </div>
  ) : (
    <div
      css={css`
        width: auto;
      `}
    >
      <WorkspaceSetup />
    </div>
  );

  const RightSideContent = showUploadState ? (
    <div />
  ) : (
    <div className="w-60">
      <PredictedCarousel onRunSimulation={runSim} />
    </div>
  );

  const PanelComponent = (
    //TODO: put this into a dedicated component
    <div css={workspaceTabsStyle}>
      {LeftSideContent}
      {RightSideContent}

      {iteration && showOptimization && usingLegacyApi && (
        <OptimizationModalV1 iteration={iteration} />
      )}
    </div>
  );
  const path = '/project/:projectId/iteration/:iterationId';

  return (
    <Routes>
      <Route
        path=""
        element={
          <Spin size="large" tip="Running..." spinning={isSimulationRunning}>
            {PanelComponent}
          </Spin>
        }
      />
      <Route
        path="optimization/:optimizationId"
        element={<OptimizationPanel />}
      />
      <Route path="/optimization" element={<OptimizationPanel />} />
    </Routes>
  );
};
interface RouteParams {
  projectId: string;
  iterationId: string;
  optimizationId: string;
}
