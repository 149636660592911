/** @jsxImportSource @emotion/react */
import { Button, notification } from 'antd';
import { FileUpload } from './file-upload.component';
import { css } from '@emotion/react';

export const FileUploadButton = ({
  projectId,
  keyScope,
  buttonText,
  fileName,
}: {
  projectId: string;
  keyScope?: 'project' | 'adaptive_learning';
  buttonText: JSX.Element;
  fileName?: string;
}) => {
  return (
    <FileUpload
      uploadType="button"
      key={`${keyScope}${projectId}UploadButton`}
      keyScope={keyScope}
      name={fileName}
      onUploadSuccess={() =>
        notification.success({
          description: `Your dataset has been successfully uploaded.`,
          message: 'Dataset Uploaded',
          maxCount: 2,
        })
      }
    >
      <Button type="primary" css={actionButtonStyles}>
        {buttonText}
      </Button>
    </FileUpload>
  );
};

export const actionButtonStyles = css`
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
