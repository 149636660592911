/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { optimizationModalStyles } from '../../styles/workspace-setup.styles';
import { useSession } from '../../../../../_shared/context';
import { useRunSimulation } from '../../../../../_shared/hooks';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { CalculateOptimizationsV2 } from './calculated-optimizations-v2.component';
import { useOptimization } from '../../../../../_shared/context/optimization-context';
import { OptimizationStates } from './context';
import {
  SimulationFormulationInputType,
  findIterationWithHistDocument,
  findIterationWithLatestSimulationDocument,
  usefindIterationWithLatestSimulationQuery,
} from '../../../../../../../__generated__/globalTypes';
import { useNavigate } from 'react-router-dom';
import { GoalScenario } from '../../../../../components/workspaces/adaptive-learning/design-project-settings.component';
import { useScenarioDetail } from '../../../../../_shared/context/scenario-detail-context';

export const OptimizationPanel = () => {
  const { currentProject } = useSession();
  const { disableGoalScenario } = useScenarioDetail();
  const { initiateOptimizationJob, optimizationState } = useOptimization();

  const { iteration } = useWorkspace();
  const navigate = useNavigate();

  const iterationId = iteration!.id;
  //TODO: better typing/flow for non nullable project obj
  if (!currentProject) return <div className="no-project" />;
  const [
    runSimulationWithPackage,
    { loading: isSimulationRunning, data: simData, error: simError },
  ] = useRunSimulation({
    refetchQueries: [
      {
        query: findIterationWithLatestSimulationDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
      {
        query: findIterationWithHistDocument,
        variables: { id: iteration!.id },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const saveWorkspaceByRunningSimulation = async (
    simulationPackage: Array<SimulationFormulationInputType>
  ) => {
    await runSimulationWithPackage({
      variables: {
        projectId: currentProject.id,
        iterationId,
        formulationData: simulationPackage,
      },
    });
  };

  return (
    <div css={optimizationModalStyles}>
      <div className="optModalHeader">
        <div
          className="optModalBackButton"
          onClick={() => {
            navigate(
              `/project/${currentProject.id}/iteration/${iteration?.id}`
            );
          }}
        >
          <ArrowLeftOutlined />
        </div>
        <div
          css={css`
            padding-left: 20px;
          `}
        >
          Create Turing Optimization
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          background: #ffffff;
        `}
      >
        <div
          css={css`
            width: 34%;
            min-width: 460px;
            border-right: 3px dotted #eaeaea;
          `}
        >
          <GoalScenario
            submitButton={
              <Button
                type="primary"
                css={css`
                  width: 90%;
                  margin-bottom: 15px;
                `}
                loading={optimizationState === OptimizationStates.RUNNING}
                disabled={disableGoalScenario}
                onClick={initiateOptimizationJob}
              >
                <span
                  css={css`
                    font-weight: bold;
                  `}
                >
                  Create Optimization <ArrowRightOutlined />
                </span>
              </Button>
            }
          />
        </div>
        <CalculateOptimizationsV2
          isSimulationRunning={isSimulationRunning}
          saveWorkspaceByRunningSimulation={saveWorkspaceByRunningSimulation}
        />
      </div>
    </div>
  );
};
