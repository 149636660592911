import { gql } from '@apollo/client';

export const getProjectsQuery = gql`
  query getProjects {
    organization {
      id
      projects {
        name
        id
        brief
        category
        country
        department
        description
        division
        testType
        stage
        lastSetupStep
        mlAPImessage
      }
    }
  }
`;

export const updateProjectQuery = gql`
  mutation updateOneProject(
    $where: ProjectWhereUniqueInput!
    $data: ProjectUpdateInput!
  ) {
    updateOneProject(where: $where, data: $data) {
      id
    }
  }
`;
