/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';
import { BaseProject } from '@root/_shared/hooks';
import { Link } from 'react-router-dom';
import { TestType } from '@prisma/client';
import { projectTypeStyle } from './project-header-tile.styles';
import { HeaderBackIcon } from '../../_shared/style';
import { EllipsisMiddle } from '../../_shared/utils/component';
export const SelectedProjectSidebar = ({
  currentProject,
}: {
  currentProject: BaseProject;
}) => {
  return (
    <div
      css={css`
        display: block;
        margin-top: -15px;
        padding: 8px 25px;
        overflow-wrap: anywhere;
      `}
    >
      <span
        css={css`
          font-weight: 700;
        `}
      >
        {currentProject?.name}
        <span
          css={css`
            padding-left: 5px;
          `}
        >
          {`(${currentProject.key})`}
        </span>
      </span>
      <div
        css={css`
          margin-top: 10px;
        `}
      >
        <Link
          css={css`
            color: #303b40;
          `}
          to="/"
        >
          {currentProject && (
            <div
              css={css`
                height: 23px;
                width: 23px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                padding-bottom: 5px;
              `}
            >
              <HeaderBackIcon />
            </div>
          )}
          <span>View All Projects </span>
        </Link>
      </div>
    </div>
  );
};
