/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { Select } from 'antd';

export const EditableChoice = ({
  selectedValue,
  options,
  setValue,
}: {
  selectedValue?: string;
  options?: Array<string>;
  setValue: (valueuseState?: string) => void;
}) => {
  const [internalValue, setInternalValue] = useState<string | undefined>();
  useEffect(() => setInternalValue(selectedValue), [selectedValue]);

  return (
    <Select
      css={css`
        width: 100%;
      `}
      value={internalValue ? [internalValue] : undefined}
      onSelect={(v: string) => {
        setValue(v);
        setInternalValue(v);
      }}
      onChange={e => {
        //The on change handler types "e" as an array of strings,
        //but on single selection it is a single string
        //leaving in array handler just in case
        const typedE = e as unknown;

        if (Array.isArray(typedE)) {
          setValue(typedE[9]);
        } else {
          setValue(typedE as string);
        }
      }}
    >
      {options?.map(o => (
        <Select.Option value={o}>{o}</Select.Option>
      ))}
    </Select>
  );
};
