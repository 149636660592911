/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AreaChart, Area, XAxis, YAxis } from 'recharts';
import { Slider } from 'antd';
import { NodeDef } from '../../../../../types/graph-data.types';
import { Colors } from '../../../../../iso/colors';

import { useGraph } from '../../../../_shared/context';

export const TopDriversGraph = () => {
  const {
    setContributionPercentage,
    contributionPercentage,
    graph,
    minNodeWeight,
    maxNodeWeight,
    minNodeDirectContribution,
    maxNodeDirectContribution,
  } = useGraph();

  // Constant rendering causes slownes
  // However, on first load the graph does not size correctly
  // So entire chart can't be memoized
  const chart = generateAreaChart(graph.nodes, minNodeWeight, maxNodeWeight);

  return (
    <div
      css={css`
        position: relative;
        top: -4px;
        padding: 8px 16px 0;
      `}
    >
      <label
        css={css`
          //todo: styling is trickling down too much
          color: #fff !important;
        `}
      >
        Top Drivers
      </label>
      {chart}
      <Slider
        css={css`
          min-width: 120px;

          &.ant-slider-with-marks {
            margin-bottom: 10px;
          }

          .ant-slider-rail {
            background: #666;
          }
        `}
        trackStyle={{ backgroundColor: Colors.LUST }}
        handleStyle={{
          border: `2px solid ${Colors.LUST}`,
          borderRadius: '12px',
          marginTop: '-3px',
          height: '10px',
          width: '10px',
          marginLeft: '-2px',
        }}
        min={minNodeDirectContribution}
        max={maxNodeDirectContribution}
        onChange={setContributionPercentage}
        value={contributionPercentage}
        step={0.01}
        tooltip={{
          formatter: value =>
            `Contribution ${value === undefined ? 0 : value}%`,
        }}
      />
    </div>
  );
};

/**
 * Returns an array of weights with the cumulative total of nodes per weight
 *
 * @param nodes
 */
const weightsToAreaChart = (nodes: Map<string, NodeDef>) => {
  const results: { kl: number; count: number }[] = [];
  //cumulativeTotal of # of nodes so far

  //KL to Count, in case of duplicate KL values
  const weightToCount = new Map<number, number>();

  let c: number | undefined;
  Array.from(nodes.values()).forEach(n => {
    const { weight, isTargetVariable, layoutOnlyNode } = n.data;
    if (!isTargetVariable && !layoutOnlyNode) {
      c = weightToCount.get(weight);
      c = c ? (c += 1) : 1;

      weightToCount.set(weight, c);
    }
  });

  for (const [weight, count] of weightToCount.entries()) {
    results.push({ kl: weight, count });
  }
  // Weighted Count
  results.sort(({ kl: a }, { kl: b }) => b - a);

  let cc = 0;
  const cumulative = results.map(d => {
    cc += d.count;
    return { kl: d.kl, count: cc };
  });
  return cumulative;
};

/*
 * TODO: We could pre-compute these charts as SVG's
 */
const generateAreaChart = (
  nodes: Map<string, NodeDef>,
  minNodeWeight: number,
  maxNodeWeight: number
) => {
  const driversData = weightsToAreaChart(nodes);
  const chart = (
    <AreaChart width={200} height={40} data={driversData}>
      <XAxis
        dataKey="kl"
        type="number"
        domain={[minNodeWeight, maxNodeWeight]}
        hide
        // reversed
      />
      <YAxis type="number" hide />
      <defs>
        <linearGradient id="fillGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="5%"
            style={{
              stopColor: Colors.NEON_CARROT,
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: Colors.LUST,
              stopOpacity: 1,
            }}
          />
        </linearGradient>
        <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="5%"
            style={{
              stopColor: 'hsl(28, 94%, 74%)',
              stopOpacity: 1,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: 'hsl(3, 83%, 65%)',
              stopOpacity: 1,
            }}
          />
        </linearGradient>
      </defs>
      <Area
        isAnimationActive={false}
        dataKey="count"
        stroke="url(#strokeGradient)"
        strokeWidth={2}
        fill="url(#fillGradient)"
        fillOpacity={1}
      />
    </AreaChart>
  );

  return chart;
};
