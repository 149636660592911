import { css } from '@emotion/react';
import { Colors } from '../../../_shared/style';

export const iconStyle = {
  fontSize: '2.7em',
  marginRight: '24px',
  border: '13px solid #c6c6c6',
  borderRadius: '2em',
  background: '#c6c6c6',
};

export const quickActionsBarContainerStyle = css`
  margin: 32px 24px;
`;

export const quickActionsBarTitleStyle = css`
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 13px;
`;

export const quickActionsBarStyle = css`
  display: flex;
  gap: 7%;
`;

export const quickActionsButtonStyle = css`
  width: 31%;
  height: auto;
  display: flex;
  background: #ffffff;
  white-space: normal;
  border: 1px solid #8d8d8d;
  padding: 0px 23px 24px 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const quickActionsButtonTitleStyle = css`
  font-size: 16px;
  margin-top: 16px;
  font-weight: 600;
`;
