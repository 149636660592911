import { useMutation, useQuery } from 'react-query';
import { IngredientRepository } from '../repositories/ingredient.repository';
import {
  CreateIngredientOut,
  GetIngredientOut,
} from '../interfaces/ingredient.interfaces';

export const useCreateIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.postCreateIngredient(data);
  });
  return mutation;
};

export const useGetIngredients = (
  data: GetIngredientOut
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getIngredientQuey`, data.organizationId, data.projectId],
    IngredientRepository.getIngredients,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useUpdateIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.patchCreateIngredient(data);
  });
  return mutation;
};

export const useDeleteIngredient = () => {
  const mutation = useMutation((data: CreateIngredientOut) => {
    return IngredientRepository.deleteCreateIngredient(data);
  });
  return mutation;
};
