import { RawDataContextProvider } from '../../../../_shared/context/raw-data-context'
import React from 'react'
import { ProjectRawDataAggregatedTable } from './project-raw-data-aggregated-table/project-raw-data-aggregated-table'

export const ProjectRawDataAggregate = ({
  enableNextStep,
  projectId,
  projectHasData
}: {
  enableNextStep: (value: boolean) => void,
  projectId?: string
  projectHasData?: boolean
}) => {
  return (
    <RawDataContextProvider>
      <ProjectRawDataAggregatedTable projectHasData={projectHasData} enableNextStep={enableNextStep} proj_id={projectId} />
    </RawDataContextProvider>
  )
}