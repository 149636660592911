/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DownloadFile } from '../../../../_shared/components/button/download-file';
import { Button } from 'antd';
import { Colors, enabledSkeletonColor } from '../../../../../iso/colors';
import { useDesign } from '../../../../_shared/context/design-context';
import { DesignFormulationResultsTable } from './design-formulation-results-table.component';
import {
  logEvent,
  TrackableEvent,
} from '../../../../_shared/tracking/usage-tracker';
import { useSession } from '../../../../_shared/context';
import { useIngredients } from '../../../../_shared/hooks';
import { getDesignDownload } from '../design-utils';
import { DesignStates } from '../types';
import { DesignRunningModal } from './design-running-modal';
import { DesignTableSkeleton } from '../table-skeleton.component';
import { ProjectFeature } from '../../../../../../__generated__/globalTypes';

export const DesignResultsOverview = () => {
  const { designResults, designState, latestDesign } = useDesign();
  const {
    selectedIterationId,
    currentProject,
    getSelectedIteration,
  } = useSession();
  const { ingredients } = useIngredients();

  const filename = `${currentProject?.key}-${
    getSelectedIteration()?.key
  }-AL-Formulations.csv`;

  return (
    <div>
      <div
        css={css`
          display: flex;
          margin-bottom: 15px;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              margin-right: 15px;
              font-size: 18px;
              color: ${enabledSkeletonColor(!designResults.length)};
            `}
          >
            Formulations
          </div>
        </div>

        <Button disabled={!designResults.length}>
          {currentProject && (
            <DownloadFile
              options={{
                type: 'data:attachment/text',
              }}
              content={getDesignDownload({
                designResults,
                ingredients,
                hasPricing: !!currentProject?.features.some(
                  f => f.feature === ProjectFeature.PRICING
                ),
                latestDesign,
                currentProject,
              })}
              filename={filename}
              onclick={() => {
                logEvent(TrackableEvent.DOWNLOADED_ADAPTIVE_LEARNING_RESULTS, {
                  iterationID: selectedIterationId,
                });
              }}
            >
              Download Formulations
            </DownloadFile>
          )}
        </Button>
      </div>
      <div
        css={css`
          width: 100%;
          border: 1px solid ${Colors.WHISPER};
        `}
      >
        {DesignStates.INITIAL === designState && <DesignTableSkeleton />}
        {designState === DesignStates.RUNNING && (
          <DesignRunningModal open={true} />
        )}
        {!!designResults.length && <DesignFormulationResultsTable />}
      </div>
    </div>
  );
};
