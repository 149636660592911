/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button, Flex, Input, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
} from '../../../_shared/context';
import TextArea from 'antd/es/input/TextArea';
import {
  FormulationFeedbackSubjectType,
  FormulationFeedbackType,
  usecreateOneFormulationFeedbackMutation,
} from '../../../../../__generated__/globalTypes';

export const TestPlanNotesModal = ({
  ingredientId,
  ingredientName,
  newValue,
  originalValue,
  testPlan,
  formulationId,
  formulationKey,
}: {
  ingredientId: number;
  ingredientName: string;
  newValue: string;
  originalValue: string;
  testPlan: TestPlanWithFormulationsType;
  formulationId: string;
  formulationKey: string;
}) => {
  const {
    getTestPlansWithFormulations,
    selectedIteration,
  } = useExecuteSolutions();
  const [
    createFeedbackNote,
    { loading },
  ] = usecreateOneFormulationFeedbackMutation();
  const [comment, setComment] = useState('');

  const handleCreateNote = async () => {
    if (testPlan?.latestUpload) {
      try {
        const createNoteResponse = await createFeedbackNote({
          variables: {
            formulationId,
            ingredientId,
            testPlanUploadId: testPlan?.latestUpload.id,
            text: comment,
            type: FormulationFeedbackType.CHANGE_REASON,
            subjectType: FormulationFeedbackSubjectType.INGREDIENT,
          },
        });

        if (
          createNoteResponse.data?.createOneFormulationFeedback &&
          selectedIteration
        ) {
          await getTestPlansWithFormulations(selectedIteration.id);
        }
      } catch (error) {
        notification.error({
          message: 'There was an issue creating your note',
        });
      }
    }
  };

  return (
    <Flex style={{ width: 260 }} vertical>
      <div
        css={css`
          color: rgba(22, 31, 38, 0.88);
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          padding: 16px;
          border-bottom: 1px solid #e9ebec;
        `}
      >
        {formulationKey} | {ingredientName}
      </div>
      <Flex
        vertical
        css={css`
          border-bottom: 1px solid #e9ebec;
          padding: 16px;
        `}
      >
        <div>
          {' '}
          <InfoCircleOutlined />
        </div>
        <div
          css={css`
            color: #8e8e8e;
            font-size: 12px;
            font-weight: 400;
            margin-top: 6px;
            margin-bottom: 19px;
            line-height: 16px;
          `}
        >
          In the suggested formulation, the ingredient "{ingredientName}" was
          calculated to be {originalValue}. Please provide a reason for
          adjusting this value to {newValue}
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.55);
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 6px;
          `}
        >
          Comments:
        </div>
        <TextArea
          rows={4}
          value={comment}
          onChange={event => setComment(event.target.value)}
          placeholder="Type notes here..."
        />
      </Flex>
      <Flex gap={8} justify="flex-end" style={{ padding: 16 }}>
        <Button
          loading={loading}
          onClick={() => handleCreateNote()}
          type="primary"
          style={{ backgroundColor: '#EF4136', color: '#fff' }}
        >
          Add
        </Button>
      </Flex>
    </Flex>
  );
};
