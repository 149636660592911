/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';

import { Fragment as F, useState } from 'react';

import { Button } from 'antd';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';

import { useGraph } from '../../../_shared/context';

export interface LabelControlProps {}

export const LabelControl: React.FC<LabelControlProps> = () => {
  const { labeledNode, graph } = useGraph();

  const [expanded, setExpanded] = useState(false);

  const data = labeledNode?.data();

  const zoom = graph.cy?.zoom() ?? 1;
  const fontSize = 9.5 * zoom;
  const borderRadius = 4 * zoom;

  return (
    <div
      id="labelControl"
      css={css`
        display: ${/*data ? 'block' :*/ 'none'};
        position: absolute;

        // these four values get overwritten
        top: 0;
        left: 0;
        height: 30px;
        width: 100px;

        .ant-btn {
          font-size: ${fontSize}px;
          width: 100%;
          height: 100%;
          border-radius: ${borderRadius}px;
          border: 1px solid var(--dark-border-color);
          background: #020d10;
          padding: ${0.5 * zoom}px ${12 * zoom ** 0.8}px ${0.5 * zoom}px
            ${6 * zoom}px;
          color: #fff;

          .anticon svg {
            font-size: ${8 * zoom}px;
            position: relative;
            float: right;
          }
        }
      `}
    >
      {data && (
        <F>
          <Button onClick={() => setExpanded(!expanded)}>
            {data.id}
            {expanded ? <DownOutlined /> : <LeftOutlined />}
          </Button>
        </F>
      )}
    </div>
  );
};
