/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Card, Select, Timeline, Typography } from 'antd';
import { css } from '@emotion/react';
import { DefaultOptionType } from 'antd/es/select';
import { EventHistoryRecordType } from '../../pages/execute-solutions/execute-solutions-page';
import { useSession } from '../../_shared/context';

const EVENT_HISTORY_FILTER_ALL_USERS_KEY = 'ALL_USERS';

export const ProjectHistory = () => {
  const { currentProject } = useSession();

  const [eventHistoryGroupedByDay, setEventHistoryGroupedByDay] = useState(
    new Map<string, EventHistoryRecordType[]>()
  );
  const [eventHistoryUserFilter, setEventHistoryUserFilter] = useState(
    EVENT_HISTORY_FILTER_ALL_USERS_KEY
  );
  const [
    eventHistoryUserFilterOptions,
    setEventHistoryUserFilterOptions,
  ] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    const groupedEventHistory: Map<string, EventHistoryRecordType[]> = new Map<
      string,
      EventHistoryRecordType[]
    >();

    const eventHistoryUserFilterOptions: Map<
      string,
      DefaultOptionType
    > = new Map();

    currentProject?.eventHistory.forEach(eventHistory => {
      const eventHistoryCreatedBy = eventHistory.createdBy;

      const eventHistoryFilterOption: DefaultOptionType = {
        label: `${eventHistory.createdBy.firstName} ${eventHistoryCreatedBy.lastName}`,
        value: eventHistoryCreatedBy.id,
      };

      eventHistoryUserFilterOptions.set(
        eventHistoryCreatedBy.id,
        eventHistoryFilterOption
      );

      if (
        eventHistoryUserFilter === EVENT_HISTORY_FILTER_ALL_USERS_KEY ||
        eventHistoryUserFilter === eventHistoryCreatedBy.id
      ) {
        const eventDateKey = new Date(
          eventHistory.createdAt
        ).toLocaleDateString('en-US');

        const currentDateEvents = groupedEventHistory.get(eventDateKey) ?? [];
        const newEventsToSet: EventHistoryRecordType[] = [
          ...currentDateEvents,
          eventHistory as EventHistoryRecordType,
        ];

        groupedEventHistory.set(eventDateKey, newEventsToSet);
      }
    });

    setEventHistoryGroupedByDay(groupedEventHistory);

    setEventHistoryUserFilterOptions([
      {
        label: 'All Users',
        value: EVENT_HISTORY_FILTER_ALL_USERS_KEY,
      },
      ...eventHistoryUserFilterOptions.values(),
    ]);
  }, [currentProject?.eventHistory, eventHistoryUserFilter]);

  return (
    <div
      css={css`
        padding: 20px 60px 15px;
      `}
    >
      <div
        css={css`
          max-width: 40%;
          font-family: Inter;
        `}
      >
        <div
          css={css`
            font-size: 20px;
            margin-bottom: 24px;
          `}
        >
          Project Log
        </div>
        <Select
          style={{ minWidth: 200, margin: '10px 0' }}
          value={eventHistoryUserFilter}
          onChange={value => {
            setEventHistoryUserFilter(value);
          }}
          options={eventHistoryUserFilterOptions}
        />

        <div
          css={css`
            margin-top: 12px;
          `}
        >
          <Timeline
            items={[...eventHistoryGroupedByDay.entries()].map(
              ([eventHistoryDate, events]) => ({
                color: 'gray',
                children: (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 12px;
                    `}
                  >
                    <div>
                      <Typography.Text
                        style={{ fontWeight: 500 }}
                        type="secondary"
                      >
                        Changes on{' '}
                        {new Date(eventHistoryDate).toLocaleDateString(
                          'en-US',
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }
                        )}
                      </Typography.Text>
                    </div>
                    {events.map(event => (
                      <Card
                        type="inner"
                        headStyle={{
                          padding: '16px',
                        }}
                        bodyStyle={{
                          padding: '16px',
                        }}
                        title={
                          <div
                            css={css`
                              display: flex;
                              justify-content: space-between;
                              font-weight: 400;
                              background-color: var(--ant-color-fill-alter);
                            `}
                          >
                            <div>
                              <Typography.Text
                                css={css`
                                  font-weight: 500;
                                `}
                              >
                                {event.createdBy.firstName}{' '}
                                {event.createdBy.lastName} {event.name}
                              </Typography.Text>
                            </div>
                            <div>
                              <Typography.Text
                                style={{ fontWeight: 500 }}
                                type={'secondary'}
                              >
                                {new Date(eventHistoryDate).toLocaleDateString(
                                  'en-US',
                                  {
                                    month: 'long',
                                    day: 'numeric',
                                  }
                                )}
                              </Typography.Text>
                            </div>
                          </div>
                        }
                      >
                        <div
                          css={css`
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                          `}
                        >
                          {event.data?.map(data => (
                            <div>- {data}</div>
                          ))}
                        </div>
                      </Card>
                    ))}
                  </div>
                ),
              })
            )}
          />
        </div>
      </div>
    </div>
  );
};
