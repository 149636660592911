/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';
import { Role } from '@prisma/client';
import { projectSiderStyle } from './project-sider.styles';
import { AdminSideBar } from './admin/side-bar/admin-side-bar';
import { IterationSidebar, IterationRouteParams } from '../iteration';
import { ReportsSidebar } from '../reports';
import { useSession } from '../../_shared/context/session-context';
import { SelectedProjectSidebar } from './project-selected-project.component';

export enum ProjectSiderSectionType {
  REPORTS,
  ITERATIONS,
  ADMIN,
}

export const ProjectSider = ({ onSelect }: { onSelect: () => void }) => {
  const { user, setSelectedIterationId, currentProject } = useSession();
  const params = useParams<IterationRouteParams>();
  const [selectedSection, setSelectedSection] = useState<
    ProjectSiderSectionType
  >(
    params.iterationId
      ? ProjectSiderSectionType.ITERATIONS
      : ProjectSiderSectionType.REPORTS
  );

  const onSelectClicked = (section: ProjectSiderSectionType) => {
    setSelectedSection(section);
    onSelect();
  };

  return (
    <div css={projectSiderStyle}>
      {currentProject && (
        <SelectedProjectSidebar currentProject={currentProject} />
      )}
      <Divider style={{ marginTop: 5 }} />
      <ReportsSidebar
        selected={selectedSection === ProjectSiderSectionType.REPORTS}
        onSelect={() => {
          onSelectClicked(ProjectSiderSectionType.REPORTS);
          setSelectedIterationId('');
        }}
      />
      {!user?.enableIceCreamBetaFeatures && (
        <IterationSidebar
          selected={selectedSection === ProjectSiderSectionType.ITERATIONS}
          onSelect={() => onSelectClicked(ProjectSiderSectionType.ITERATIONS)}
        />
      )}
      {(user?.role === Role.SUPER_ADMIN || user?.role === Role.ADMIN) && (
        <AdminSideBar
          selected={selectedSection === ProjectSiderSectionType.ADMIN}
          onSelect={() => {
            onSelectClicked(ProjectSiderSectionType.ADMIN);
            setSelectedIterationId('');
          }}
        />
      )}
    </div>
  );
};
