/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { BasicInfoForm } from './project-setup/steps/basic-info-form.component';
import { Layout } from 'antd';
import { contentStyle } from './project-layout.styles';
import { useSession } from '../../_shared/context';
const { Content } = Layout;
export const ProjectBasicInfo = () => {
  const { currentProject } = useSession();
  const hasCreatedBy =
    currentProject?.createdBy &&
    currentProject?.createdBy?.firstName &&
    currentProject?.createdBy?.lastName;
  return (
    <Layout>
      <Content css={[contentStyle]}>
        <div
          css={css`
            display: flex;
            height: 90%;
            background-color: #f5f5fa;
            justify-content: center;
            height: max-content;
            padding-bottom: 20px;
            padding-top: 20px;
            padding-left: 20%;
            padding-right: 20%;

            @media (min-width: 768px) and (max-width: 1199px) {
              padding-left: 10%;
              padding-right: 10%;
            }
            @media (max-width: 767px) {
              padding-left: 1%;
              padding-right: 1%;
            }
          `}
        >
          <BasicInfoForm
            existingProject={currentProject}
            style={{ width: '80%' }}
            title={
              <>
                <div
                  css={css`
                    font-size: 24px;
                    font-weight: 600;
                  `}
                >
                  Project Details{' '}
                </div>
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {hasCreatedBy
                    ? `Created By: ${currentProject?.createdBy?.firstName} ${currentProject?.createdBy?.lastName} (${currentProject?.createdBy?.email})`
                    : ''}
                </p>
                <p
                  css={css`
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  {`Created At: ${new Date(
                    currentProject?.createdAt
                  ).toLocaleString()}`}
                </p>
              </>
            }
            readOnly={true}
            hideSubmit
          />
        </div>
      </Content>
    </Layout>
  );
};
