import React, { useState, useContext, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { useSession } from './session-context';

import {
  ConstraintInputType,
  Objective,
  CostOptimizationOption,
  usesaveProjectConstraintsMutation,
  saveProjectConstraintsMutation,
  useupsertManyObjectivesMutation,
  useupsertManyConstraintsMutation,
  usedeleteOneConstraintMutation,
  useformulationDetailLazyQuery,
  ObjectiveType,
} from '../../../../__generated__/globalTypes';

import {
  findConstraint,
  emptyConstraint,
} from '../../components/workspaces/adaptive-learning/design-utils';
import {
  IMPORTANCE_MAX,
  NO_IMPORTANCE,
} from '../../components/workspaces/shared/goals/types';
import { FetchResult } from '@apollo/client';
import { IterationWithLatestSimulation } from '../../components/workspaces/lab-bench/context';
import { LOG_SCENARIO_DETAILS_CONTEXT } from '../debug/flags';
import { notification } from 'antd';
import { useDesign } from './design-context';

type ScenarioDetailContextProviderProps = {
  children?: React.ReactNode;
  iteration?: IterationWithLatestSimulation;
};
interface ScenarioDetailContextProps {
  changePriority: (input: {
    targetVariable: string;
    action: 'INCREASE' | 'DECREASE';
  }) => void;
  constraint: ConstraintInputType | undefined;
  setConstraint: (constraint: ConstraintInputType | undefined) => void;
  constraintIndex: number | undefined;
  setConstraintIndex: (constraintIndex: number | undefined) => void;
  constraints: ConstraintInputType[];
  setConstraints: (constraints: ConstraintInputType[]) => void;
  saveConstraints: (
    constraints: ConstraintInputType[]
  ) => Promise<ConstraintInputType[]>;
  costOptimizationOption: CostOptimizationOption;
  setCostOptimizationOption: (option: CostOptimizationOption) => void;
  disableGoalScenario: boolean;
  setDisableGoalScenario: (disable: boolean) => void;
  editConstraint: (c: ConstraintInputType) => void;
  enforceNteCost: boolean;
  setEnforceNteCost: (enforce: boolean) => void;
  enforceStrictly: boolean;
  setEnforceStrictly: (enforce: boolean) => void;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  fillerIngredient?: string;
  setFillerIngredient: (fillerIngredient: string | undefined) => void;
  isExistingConstraint: boolean;
  maxNumberOfResults: number;
  setMaxNumberOfResults: (maxNumberOfResults: number) => void;
  nteCost: number;
  setNteCost: (cost: number) => void;
  objectivesAreInEditMode: boolean;
  setObjectivesAreInEditMode: (o: boolean) => void;
  objectivesByTarget: Map<string, Objective>;
  setObjectivesByTarget: (objectives: Map<string, Objective>) => void;
  openDrawer: boolean;
  setOpenDrawer: (showDrawer: boolean) => void;
  removeConstraint: (c: ConstraintInputType) => Promise<ConstraintInputType[]>;
  saveProjectConstraints: () => Promise<
    FetchResult<saveProjectConstraintsMutation>
  >;
  showConstraintModal: boolean;
  setShowConstraintModal: (showConstraintModal: boolean) => void;
  updateObjective: (objective: Objective) => Promise<Map<string, Objective>>;
  templateFormulation?: string;
  setTemplateFormulation: (formulationKey: string) => void;
}
const ScenarioDetailContext = React.createContext<ScenarioDetailContextProps>(
  {} as any
);

export const ScenarioDetailContextProvider = ({
  children,
}: ScenarioDetailContextProviderProps) => {
  const { currentProject, useFetchProject, selectedIterationId } = useSession();

  const [fetchProjectById] = useFetchProject();
  const [fillerIngredient, setFillerIngredient] = useState<
    string | undefined
  >();
  const [templateFormulation, setTemplateFormulation] = useState<string>();
  const [disableGoalScenario, setDisableGoalScenario] = useState<boolean>(
    false
  );

  const initialObjectives = new Map<string, Objective>();
  currentProject?.objectives?.forEach(obj => {
    initialObjectives.set(obj.targetVariable, obj);
  });
  const [objectivesByTarget, setObjectivesByTarget] = useState(
    initialObjectives
  );

  const [constraints, setConstraints] = useState<ConstraintInputType[]>([]);

  const [objectivesAreInEditMode, setObjectivesAreInEditMode] = useState<
    boolean
  >(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [maxNumberOfResults, setMaxNumberOfResults] = useState<number>(3);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showConstraintModal, setShowConstraintModal] = useState(false);
  const [constraint, setConstraint] = useState<ConstraintInputType | undefined>(
    emptyConstraint
  );
  const [constraintIndex, setConstraintIndex] = useState<number>();

  const isExistingConstraint =
    constraintIndex !== null && constraintIndex !== undefined;
  const [saveProjectConstraintsRequest] = usesaveProjectConstraintsMutation();
  const [upsertManyConstraintsRequest] = useupsertManyConstraintsMutation();
  const [upsertManyObjectivesRequest] = useupsertManyObjectivesMutation();

  const [costOptimizationOption, setCostOptimizationOption] = useState<
    CostOptimizationOption
  >(CostOptimizationOption.DO_NOT_OPTIMIZE);
  const [enforceNteCost, setEnforceNteCost] = useState<boolean>(false);
  const [enforceStrictly, setEnforceStrictly] = useState<boolean>(true);
  const [nteCost, setNteCost] = useState<number>(0);

  const [deleteOneConstraint] = usedeleteOneConstraintMutation();
  const [fetchFormulation] = useformulationDetailLazyQuery({
    variables: {
      formulationId: templateFormulation ?? '',
    },
  });
  useEffect(() => {
    const currentFiller = currentProject?.ingredientList.find(
      ing => ing.filler
    );

    if (!fillerIngredient && currentFiller) {
      setFillerIngredient(currentFiller.ingredient.name);
    }
  }, [currentProject]);

  const updateObjective = async (objective: Objective) => {
    const newMap = new Map([...objectivesByTarget]);
    newMap.set(objective.targetVariable, objective);

    try {
      let { __typename, ...cleanedObjective } = objective;
      if (
        cleanedObjective.objectiveType === ObjectiveType.MAXIMIZE ||
        cleanedObjective.objectiveType === ObjectiveType.MINIMIZE
      ) {
        // If the objective type is maximize or maximize, these must be null or Design will error
        cleanedObjective.lower = null;
        cleanedObjective.upper = null;
        cleanedObjective.value = null;
      }

      if (selectedIterationId) {
        // Perform update only when iterationId is set (this is not set for project level objectives)
        // From project admin, use "Save Objectives" button to persist data

        const updatedObjectives = await upsertManyObjectivesRequest({
          variables: {
            iterationId: selectedIterationId,
            objectives: cleanedObjective,
          },
        });

        const updatedObjectiveById = updatedObjectives.data?.upsertManyObjectives.find(
          updatedObjective => updatedObjective.id === objective.id
        );

        const updatedObjective =
          updatedObjectiveById ??
          updatedObjectives.data?.upsertManyObjectives.find(
            updatedObjective =>
              updatedObjective.targetVariable === objective.targetVariable
          );

        if (!updatedObjective) {
          throw new Error('Updated outcome not returned');
        }

        newMap.set(objective.targetVariable, updatedObjective);
      }
    } catch (error) {
      notification.error({ message: 'Error saving outcome' });
    }

    setObjectivesByTarget(newMap);
    return newMap;
  };

  const changePriority = ({
    targetVariable,
    action,
  }: {
    targetVariable: string;
    action: 'INCREASE' | 'DECREASE';
  }) => {
    const objective = objectivesByTarget.get(targetVariable);
    if (!objective) {
      //almost impossible, but makes the type engine happy
      throw new Error(
        `${targetVariable || 'UNDEFINED'} Not found in objectives`
      );
    }

    const { importance, ...otherProps } = objective;
    let newImportance: number | undefined = undefined;
    if (action === 'INCREASE' && objective.importance < IMPORTANCE_MAX) {
      newImportance = importance + 1;
    } else if (action === 'DECREASE' && objective.importance > NO_IMPORTANCE) {
      newImportance = importance - 1;
    }

    if (newImportance !== undefined) {
      updateObjective({ importance: newImportance, ...otherProps });
    }
  };

  const removeConstraint = async (constraintToRemove: ConstraintInputType) => {
    // Remove the selected constraint from the array
    let editedConstraints;
    if (constraintToRemove.id) {
      editedConstraints = constraints.filter(existingConstraint => {
        if (constraintToRemove.id !== existingConstraint.id) {
          return existingConstraint;
        }
      });
    } else {
      editedConstraints = constraints.filter(existingConstraint => {
        if (!isEqual(constraintToRemove, existingConstraint)) {
          return existingConstraint;
        }
      });
    }

    if (constraintToRemove.id) {
      await deleteOneConstraint({
        variables: {
          constraintId: constraintToRemove.id,
        },
      });
    }
    setConstraints(editedConstraints);
    return editedConstraints;
  };

  const editConstraint = (constraintToEdit: ConstraintInputType) => {
    const index = findConstraint(constraintToEdit, constraints);
    setConstraintIndex(index); // Save the index to state so we know which constraint to overwrite with the update
    setConstraint(constraintToEdit);
    setShowConstraintModal(true);
  };

  const saveProjectConstraints = async () => {
    const formattedConstraints = constraints.map(
      (
        constraint: ConstraintInputType & { id: string; __typename: string }
      ) => {
        const { __typename, id, ...rest } = constraint;
        return rest;
      }
    );
    const createdConstraints = await saveProjectConstraintsRequest({
      variables: {
        constraints: formattedConstraints,
        projectId: currentProject!.id,
      },
    });
    // Refetch the project to get the updated template constraints
    await fetchProjectById({
      variables: {
        projectId: `${currentProject?.id}`,
      },
    });
    return createdConstraints;
  };

  const saveConstraints = async (constraints: ConstraintInputType[]) => {
    try {
      if (selectedIterationId) {
        // Perform update only when iterationId is set (this is not set for project level constraints)
        // From project admin, use "Save Constraints" button to persist data
        const updatedConstraints = await upsertManyConstraintsRequest({
          variables: {
            iterationId: selectedIterationId,
            constraints,
          },
        });

        constraints =
          updatedConstraints.data?.upsertManyConstraints.map(c => {
            const { __typename, ...cleanedConstraint } = c;
            return cleanedConstraint;
          }) ?? [];
      }
      setConstraints(constraints);
      return constraints;
    } catch (error) {
      notification.error({ message: 'Error saving constraint' });
    }
  };

  const fetchAndApplyFormulation = async () => {
    let formulationResponse = await fetchFormulation();
    let design = formulationResponse.data?.formulation?.design;
    if (design) {
      const objectivesFromDesign = design?.objectives;
      const constraintsFromDesign =
        design?.constraints?.map(c => {
          const { __typename, ...constraintWithoutTypename } = c;
          return constraintWithoutTypename;
        }) ?? [];

      const objMap = new Map<string, Objective>();
      objectivesFromDesign?.forEach(obj => {
        objMap.set(obj.targetVariable, obj);
      });
      if (objectivesFromDesign?.length !== currentProject?.objectives.length) {
        for (const o of currentProject?.objectives ?? []) {
          if (!objMap.has(o.targetVariable)) {
            objMap.set(o.targetVariable, o);
          }
        }
      }
      setObjectivesByTarget(objMap);

      setConstraints(constraintsFromDesign);

      if (design.enforceNteCost) {
        setEnforceNteCost(design.enforceNteCost);
      }
      if (design?.maxNumberOfResults) {
        setMaxNumberOfResults(design?.maxNumberOfResults);
      }
      if (design.costOptimizationOption) {
        setCostOptimizationOption(design.costOptimizationOption);
      }
    } else {
      console.error(
        `Attempted to use ${templateFormulation} as a template formulation but no design was attatched. The request response:`,
        formulationResponse.data
      );
    }
  };

  useEffect(() => {
    if (templateFormulation) {
      fetchAndApplyFormulation();
    }
  }, [templateFormulation]);
  if (LOG_SCENARIO_DETAILS_CONTEXT) {
    console.log('Scenario Detail Context', {
      changePriority,
      constraint,
      constraintIndex,
      constraints,
      costOptimizationOption,
      disableGoalScenario,
      editConstraint,
      enforceNteCost,
      enforceStrictly,
      errorMessage,
      fillerIngredient,
      isExistingConstraint,
      maxNumberOfResults,
      nteCost,
      objectivesAreInEditMode,
      objectivesByTarget,
      openDrawer,
      removeConstraint,
      saveProjectConstraints,
      showConstraintModal,
      updateObjective,
    });
  }
  return (
    <ScenarioDetailContext.Provider
      value={{
        changePriority,
        constraint,
        setConstraint,
        constraintIndex,
        setConstraintIndex,
        constraints,
        setConstraints,
        saveConstraints,
        costOptimizationOption,
        setCostOptimizationOption,
        disableGoalScenario,
        setDisableGoalScenario,
        editConstraint,
        enforceNteCost,
        setEnforceNteCost,
        enforceStrictly,
        setEnforceStrictly,
        errorMessage,
        setErrorMessage,
        fillerIngredient,
        setFillerIngredient,
        isExistingConstraint,
        maxNumberOfResults,
        setMaxNumberOfResults,
        nteCost,
        setNteCost,
        objectivesAreInEditMode,
        setObjectivesAreInEditMode,
        objectivesByTarget,
        setObjectivesByTarget,
        openDrawer,
        setOpenDrawer,
        removeConstraint,
        saveProjectConstraints,
        showConstraintModal,
        setShowConstraintModal,
        updateObjective,
        templateFormulation,
        setTemplateFormulation,
      }}
    >
      {children}
    </ScenarioDetailContext.Provider>
  );
};

export const useScenarioDetail = () => useContext(ScenarioDetailContext);
