/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react';

import { Menu } from 'antd';

import { CategoryDot } from '../../../_shared/style';
import { useGraph } from '../../../_shared/context';

export const IngredientCategories = () => {
  const { graph } = useGraph();

  const categories = new Map<
    string,
    { name: string; color: string; count: number }
  >();

  const sortedCategories = Array.from(categories.values()).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Menu.SubMenu
      popupClassName="hud-submenu-popup"
      popupOffset={[45, -10]}
      title="Ingredient Category"
    >
      {sortedCategories.map(category => (
        <Menu.Item key={category.name}>
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                position: relative;
                left: -3px;
                top: 2px;
              `}
            >
              <CategoryDot color={category.color} />
            </div>
            <div
              css={css`
                width: 130px;
              `}
            >
              {category.name}
            </div>
            <div>{category.count}</div>
          </div>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
};
