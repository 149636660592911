/** @jsxImportSource @emotion/react */
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Level } from '../../../../../__generated__/globalTypes';
import { ConfidenceInput } from './confidence-input';

interface ExcludeRelationshipModalProps {
  confidence?: Level;
  onOk: (confidence: Level) => void;
  onClose: () => void;
  open: boolean;
  selectedIngredient: string;
  selectedOutcome: string;
}

export const ExcludeRelationshipModal = (
  props: ExcludeRelationshipModalProps
) => {
  const { onClose, onOk, open, selectedIngredient, selectedOutcome } = props;

  const [confidence, setConfidence] = useState<Level | undefined>(
    props.confidence
  );

  useEffect(() => {
    setConfidence(props.confidence);
  }, [props.confidence]);

  return (
    <Modal
      title="Mark Relationship for Removal"
      cancelButtonProps={{ type: 'text', color: '#fff !important' }}
      open={open}
      onOk={() => onOk(confidence!)}
      onCancel={onClose}
      className="expert-knowledge-modal"
    >
      <p>
        You are indicating that {selectedOutcome} to {selectedIngredient} should
        be removed from your project's machine learning model.
      </p>
      <p>Please select your confidence in this removal.</p>
      <ConfidenceInput confidence={confidence} onChange={setConfidence} />
    </Modal>
  );
};
