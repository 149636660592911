/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { IngredientComposition } from './ingredient-composition-modal.component';
import { Button, Input, Popconfirm, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { TableDataType } from './ingredient-composition-table.component';

const { Text } = Typography;

export const IngredientComponents = ({
  createIngredientComposition,
  removeIngredientComposition,
  updateIngredientComposition,
  ingredientCompositions,
  tableData,
}: {
  createIngredientComposition: () => void;
  removeIngredientComposition: (index: number) => void;
  updateIngredientComposition: (value: string, index: number) => void;
  ingredientCompositions: Array<IngredientComposition>;
  tableData: TableDataType[];
}) => {
  return (
    <div
      css={css`
        min-height: 300px;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <h3>Components</h3>
        </div>
        <div>
          <Button
            style={{ marginLeft: 5 }}
            icon={<PlusOutlined />}
            onClick={createIngredientComposition}
          >
            Add New
          </Button>
        </div>
      </div>
      {ingredientCompositions.length === 0 && (
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <div
            css={css`
              margin-top: 100px;
            `}
          >
            <Text type="secondary">No components yet</Text>
          </div>
        </div>
      )}
      <div
        css={css`
          width: 100%;
          margin-top: 15px;
        `}
      >
        {ingredientCompositions.map((ic, index) => (
          <div
            css={css`
              width: 100%;
              margin-top: 15px;
            `}
            key={index}
          >
            <Input
              key={`ic-${ic?.id ?? index}`}
              css={css`
                width: 40%;
                margin-right: 5px;
              `}
              autoFocus={ic.name ? false : true}
              defaultValue={ic.name}
              onChange={e => updateIngredientComposition(e.target.value, index)}
            />
            <Popconfirm
              title="Are you sure you want to remove this ingredient composition?"
              onConfirm={() => removeIngredientComposition(index)}
              okText="Remove"
              cancelText="Cancel"
            >
              <Button ghost icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        ))}
      </div>
    </div>
  );
};
