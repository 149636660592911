/** @jsxImportSource @emotion/react */

import { Form, Input, Button, Select, Spin } from 'antd';

import { GraphqlForm } from '../../_shared/components/form/graphql-form.component';
import { updateUser } from '../../_shared/hooks/use-user.hook';
import {
  UserStatus,
  usegetCurrentUserQuery,
} from '../../../../__generated__/globalTypes';
import { ModalTitle, ModalSecondaryTitle } from '../../_shared/style/snippets';
import OnboardingAsset from './onboardiing-asset.component';
import { countries } from '../../_shared/utils/geo';

import {
  onboardingContainer,
  onboardingForm,
  formItemsContainer,
  submitButtonStyle,
} from './onboarding.styles';
import { filterSelectOptionsByCurrentValue } from '../../_shared/utils/util';

const { Option } = Select;

const NewUserForm = () => {
  const { data: userData, loading } = usegetCurrentUserQuery();
  const [saveUser] = updateUser();

  const handleSave = async (user: any) => {
    await saveUser({
      variables: {
        data: {
          firstName: user.firstName,
          lastName: user.lastName,
          city: user.city || null,
          state: user.state || null,
          country: user.country || null,
          position: user.position || null,
          status: UserStatus.CONFIRMED,
        },
        userId: user.id!,
      },
    });
  };

  if (loading || !userData?.me) {
    return <Spin />;
  }

  const data = {
    data: {
      ...userData.me,
      country: 'United States of America (the)',
    },
    loading: false,
    formLayout: 'vertical' as 'vertical',
    layout: {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    },
    onFinishSuccess: handleSave,
  };

  return (
    <div css={onboardingForm}>
      <ModalTitle text="Enter Your Profile" />
      <ModalSecondaryTitle text="To provide more information about you" />

      <GraphqlForm {...data}>
        {
          (/*project, form*/) => (
            <div css={formItemsContainer}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name!',
                  },
                ]}
              >
                <Input autoFocus placeholder="Enter a first name" />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your last name!',
                  },
                ]}
              >
                <Input placeholder="Enter a last name" />
              </Form.Item>

              <Form.Item label="Position" name="position">
                <Input placeholder="Enter your position" />
              </Form.Item>

              <Form.Item label="City" name="city">
                <Input placeholder="Enter your city" />
              </Form.Item>

              <Form.Item label="State/Province" name="state">
                <Input placeholder="Enter your state/province" />
              </Form.Item>

              <Form.Item label="Country" name="country">
                <Select
                  showSearch
                  placeholder="Select a country"
                  filterOption={filterSelectOptionsByCurrentValue}
                >
                  {countries.map(country => (
                    <Option key={country} value={country}>
                      {country}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  css={submitButtonStyle}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          )
        }
      </GraphqlForm>
    </div>
  );
};

export const OnboardingUser = () => {
  return (
    <div css={onboardingContainer}>
      <NewUserForm />
      <OnboardingAsset />
    </div>
  );
};
