/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const containerStyle = css`
  padding: 0;
`;

const tableStyle = css`
  width: 100%;
  border-collapse: collapse;
`;

const tableHeaderStyle = css`
  background-color: #f2f2f2;
  text-align: left;
  padding: 0.5rem;
  font-weight: bold;
`;

const tableRowStyle = css`
  &:hover {
    background-color: #f9f9f9;
  }
`;

const tableCellStyle = css`
  padding: 0.3rem;
`;

export const DesignTableSkeleton = () => {
  return (
    <div css={containerStyle}>
      {[...Array(3)].map((_, rowIndex) => (
        <table key={rowIndex} css={tableStyle}>
          <thead>
            <tr>
              <th css={tableHeaderStyle}>
                <Skeleton height={30} enableAnimation={false} />
              </th>
              <th css={tableHeaderStyle}>
                <Skeleton height={30} enableAnimation={false} />
              </th>
              <th css={tableHeaderStyle}>
                <Skeleton height={30} enableAnimation={false} />
              </th>
              <th css={tableHeaderStyle}>
                <Skeleton height={30} enableAnimation={false} />
              </th>
              <th css={tableHeaderStyle}>
                <Skeleton height={30} enableAnimation={false} />
              </th>
            </tr>
          </thead>
          <tbody>
            {[...Array(4)].map((_, rowIndex) => (
              <tr key={rowIndex} css={tableRowStyle}>
                <td css={tableCellStyle}>
                  <Skeleton enableAnimation={false} />
                </td>
                <td css={tableCellStyle}>
                  <Skeleton enableAnimation={false} />
                </td>
                <td css={tableCellStyle}>
                  <Skeleton enableAnimation={false} />
                </td>
                <td css={tableCellStyle}>
                  <Skeleton enableAnimation={false} />
                </td>
                <td css={tableCellStyle}>
                  <Skeleton enableAnimation={false} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};
