/** @jsxImportSource @emotion/react */
import {
  CheckCircleOutlined,
  CheckOutlined,
  FilterFilled,
  FilterOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Design, VariableType } from '@prisma/client';
import {
  Ellipsis,
  EllipsisMiddle,
  formatCostScore,
} from '../../_shared/utils/component';
import {
  Button,
  Empty,
  Flex,
  Form,
  Input,
  Popover,
  Spin,
  Table,
  Tag,
  notification,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, {
  Dispatch,
  Key,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  NumericFilterValues,
  CustomRangeFilterDropdown,
} from './filter-dropdown/numeric-custom-filter-dropdown';
import { isWithinBounds } from '../workspaces/adaptive-learning/design-validation';
import { useDesign } from '../../_shared/context/design-context';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
  useSession,
} from '../../_shared/context';
import {
  calculateCompositionTotalsV2,
  calculateFormulationCostV2,
  confidenceIntervalsStringToArray,
  convertReliabilityPercentage,
  limitDecimals,
} from '../../_shared/utils/util';
import { useIngredients } from '../../_shared/hooks/use-ingredient.hook';
import '../../pages/execute-solutions/experiment-list-style.css';
import {
  FormulationItemMetric,
  FormulationItemMetricType,
  FormulationItemType,
  FormulationMetricType,
  FormulationSource,
  FormulationStatus,
  JobStatus,
  ProjectFeature,
  ProjectJobType,
  TestPlanUploadStatusType,
  projectByIdQuery,
  usepollProjectJobStatusMutation,
  usetriggerTestPlanModelRebuildMutation,
  useupdateOneTestPlanMutation,
} from '../../../../__generated__/globalTypes';
import { ExperimentPopover } from './experiment-list-table/experiment-popover';
import { ActionsDropdown } from './experiment-list-table/actions-dropdown';
import { FilterDropdownProps, FilterValue } from 'antd/lib/table/interface';
import { ArrowButtons } from './experiment-list-table/arrow-buttons';
import { lowerCase, startCase } from 'lodash';
import { useFormulations } from '../../_shared/context/formulations-context';
import { SourcePopover } from './experiment-list-table/source-popover';
import { ScenarioFilterModal } from './experiment-list-table/scenario-filter-modal';
import { ConfidenceIntervalText } from '../exploration/confidence-interval-text';
import { TrackableEvent, logEvent } from '../../_shared/tracking/usage-tracker';
import { css } from '@emotion/react';
import { TestPlanComponent } from './test-plan/test-plan-component';
import DraggableTableRow from './experiment-list-table/draggable-table-row';
import { TestPlanNotesModal } from './test-plan/notes-modal';
import { GreenIndicator, SummaryCloseIcon } from '../../_shared/style';

type CustomRecordType = Record<string, any>;

const handleRangeFilter = (
  filterValue: NumericFilterValues,
  record: CustomRecordType,
  fieldToFilter: string
) =>
  isWithinBounds(
    record[fieldToFilter],
    filterValue.greaterThan,
    filterValue.lessThan
  );
const handleTextFilter = (
  filterValue: string,
  record: CustomRecordType,
  fieldToFilter: string,
  exact?: boolean
) =>
  exact
    ? record[fieldToFilter] === filterValue || record.isBenchmark
    : record[fieldToFilter]?.startsWith(filterValue) || record.isBenchmark;

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatValue = (value: string) => {
  const numberValue = Number(value);
  const formattedValue = isNaN(numberValue)
    ? value
    : limitDecimals(numberValue, 4);

  return formattedValue;
};

const getCellColor = (value: number, children: React.ReactNode) => {
  let color = '';

  if (value > 0 && value < 0.25) {
    color = '#ef4136'; // red
  } else if (value >= 0.25 && value < 0.5) {
    color = '#eb5b37'; // orange
  } else if (value >= 0.5 && value < 0.75) {
    color = '#f7ec78'; // yellow
  } else if (value >= 0.75) {
    color = '#66CB34'; // green
  }

  return {
    props: {
      style: { backgroundColor: color },
    },
    children,
  };
};

type ResultTableProps = {
  activeView: 'default' | 'historical' | 'testPlan' | 'recentlyGenerated';
  tableKey: number;
  setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>;
  isolatedRows: Key[];
  setIsolatedRows: Dispatch<SetStateAction<Key[]>>;
  selectedRowKeys: Key[];
  tableData: CustomRecordType[];
  setTableData: (data: CustomRecordType[]) => void;
  tableColumns: CustomRecordType[];
  setTableColumns: (data: CustomRecordType[]) => void;
  sortBy: string | undefined;
  setTableFilters: (data: Record<string, FilterValue | null>) => void;
  resetAllTable: () => void;
  selectedScenarios: string[];
  setSelectedScenarios: (selectedScenarios: string[]) => void;
};

export const ExperimentListTableComponent = ({
  activeView,
  tableKey,
  setSelectedRowKeys,
  selectedRowKeys,
  setTableData,
  tableData,
  tableColumns,
  setTableColumns,
  sortBy,
  isolatedRows,
  setIsolatedRows,
  setTableFilters,
  resetAllTable,
  selectedScenarios,
  setSelectedScenarios,
}: ResultTableProps) => {
  const [openFilterByScenarioModal, setFilterByScenarioModal] = useState(false);
  const { latestDesign } = useDesign();
  const { currentProject, selectedIterationId } = useSession();
  const pricingFeature = currentProject?.features.find(
    f => f.feature === ProjectFeature.PRICING
  );
  const { ingredients } = useIngredients();
  const {
    testPlansWithFormulations,
    setTestPlansWithFormulations,
    getTestPlansWithFormulations,
    selectedIteration,
  } = useExecuteSolutions();
  const { projectFormulations, formulationsFetching } = useFormulations();
  const [updateTestPlan, { loading }] = useupdateOneTestPlanMutation();
  const [selectedTestPlanToReview, setSelectedTestPlanToReview] = useState<
    TestPlanWithFormulationsType | undefined
  >();
  const [
    showModelRebuildRunningMessage,
    setShowModelRebuildRunningMessage,
  ] = useState(false);
  const [
    showModelRebuildCompleteMessage,
    setShowModelRebuildCompleteMessage,
  ] = useState(false);
  const [
    triggerTestPlanModelRebuild,
    { loading: modelRebuildLoading },
  ] = usetriggerTestPlanModelRebuildMutation();
  const [pollProjectJobStatus] = usepollProjectJobStatusMutation();

  const onRowSelectChange = (newSelectedRowKeys: React.Key[], records: any) => {
    setSelectedRowKeys(records.map((r: any) => r.experiment));
  };
  const selectedScenarioSet = new Set(selectedScenarios);
  useEffect(() => {
    let tableData: Record<string, any>[] = [];

    if (selectedTestPlanToReview?.latestUpload) {
      tableData = selectedTestPlanToReview.latestUpload.uploadedFormulations.map(
        result => {
          let formulation = result.uploadedFormulation!;
          let useChild = false;

          let parentFormulationValues = new Map<string, string>();

          if (!result.matched && result.createdChildFormulation) {
            formulation = result.createdChildFormulation;
            useChild = true;

            result.uploadedFormulation?.items.forEach(item => {
              parentFormulationValues.set(
                item.variable.name,
                formatValue(item.value)
              );
            });
          }

          let formattedTableRow: Record<string, any> = {
            key: formulation.key,

            id: formulation.key,
            isGenerated: false,
            isBenchmark: false,
            experiment: formulation.key,
            measured: formulation.isMeasured ? 'Yes' : 'No',
            formulation,
            parentFormulationValues: useChild
              ? parentFormulationValues
              : undefined,
            status: result.matched ? 'Matched' : 'Unmatched',
          };

          formulation.items.forEach(f => {
            formattedTableRow[f.variable.name] = formatValue(f.value);
          });

          return formattedTableRow;
        }
      );
    } else {
      let formulations = projectFormulations;

      if (activeView === 'historical') {
        formulations = projectFormulations.filter(
          formulation => formulation.source === FormulationSource.EXISTING
        );
      }

      if (activeView === 'testPlan') {
        const formulationIds: string[] = [];

        for (const testPlan of testPlansWithFormulations) {
          if (
            testPlan?.latestUpload?.status === TestPlanUploadStatusType.COMPLETE
          ) {
            testPlan?.formulations.forEach(formulation => {
              formulationIds.push(formulation.id);
            });
          }
        }

        formulations = projectFormulations.filter(formulation =>
          formulationIds.includes(formulation.id)
        );
      }

      if (activeView === 'recentlyGenerated') {
        formulations = projectFormulations.filter(
          formulation => formulation.source === FormulationSource.SUGGESTED
        );
      }

      if (activeView === 'default') {
        formulations = projectFormulations?.filter(
          formulation =>
            formulation.status === FormulationStatus.PINNED &&
            (selectedScenarios.length === 0 ||
              (formulation?.designId &&
                selectedScenarioSet.has(formulation.designId)))
        );
      }

      tableData = formulations.map(result => {
        let formulationIngredientItems = result.items
          .filter(item => item.type === FormulationItemType.INPUT)
          .map(item => ({
            value: Number(item.value),
            name: item.variable.name,
          }));

        let expectedImprovementScore = '';
        let desirability = '';

        result.metrics.forEach(metric => {
          if (metric.type === FormulationMetricType.SCORE) {
            expectedImprovementScore = Number(metric.value).toPrecision(3);
          }

          if (metric.type === FormulationMetricType.TOTAL_DESIRABILITY) {
            desirability = Number(metric.value).toPrecision(3);
          }
        });
        console.log("-----///---", result)
        let formattedTableRow: Record<string, any> = {
          key: result.key,
          initiative: result.campaign?.name,
          id: result.key,
          isGenerated: false,
          isBenchmark: result.isBenchmark,
          experiment: result.key,
          source:
            FormulationSource[result.source as keyof typeof FormulationSource],
          measured: result.isMeasured ? 'Yes' : 'No',
          cost: calculateFormulationCostV2(
            formulationIngredientItems,
            ingredients
          ),
          expectedImprovementScore,
          desirability,
          formulation: result,
        };

        const ingredientCompositionTotals = calculateCompositionTotalsV2(
          formulationIngredientItems,
          ingredients,
          currentProject?.ingredientComposition
        );
        ingredientCompositionTotals?.forEach(composition => {
          formattedTableRow[composition.compositionId] = limitDecimals(
            composition.total,
            4
          );
        });

        result.items.forEach(f => {
          const numberValue = Number(f.value);
          const formattedValue = isNaN(numberValue)
            ? f.value
            : limitDecimals(numberValue, 4);

          formattedTableRow[f.variable.name] = formattedValue;
        });

        return formattedTableRow;
      });
    }

    tableData.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.isBenchmark && !b.isBenchmark) {
        return -1;
      }
      if (!a.isBenchmark && b.isBenchmark) {
        return 1;
      }

      if (
        a.isGenerated &&
        !b.isGenerated &&
        (sortBy === 'experiment' || !sortBy)
      ) {
        return -1;
      }
      if (
        !a.isGenerated &&
        b.isGenerated &&
        (sortBy === 'experiment' || !sortBy)
      ) {
        return 1;
      }
      // If sortBy field is a number, perform a numeric sort.
      if (
        sortBy &&
        typeof a[sortBy] === 'number' &&
        typeof b[sortBy] === 'number'
      ) {
        return a[sortBy] - b[sortBy];
      }

      // If sortBy field is a string, perform a string comparison.
      if (
        sortBy &&
        typeof a[sortBy] === 'string' &&
        typeof b[sortBy] === 'string'
      ) {
        return a[sortBy].localeCompare(b[sortBy]);
      }
    });
    console.log(tableData)
    setTableData(tableData);
  }, [
    projectFormulations,
    sortBy,
    tableKey,
    isolatedRows.length,
    selectedScenarios,
    selectedTestPlanToReview,
    activeView,
  ]);

  const outcomes = currentProject?.activeModel?.outcomes.sort(
    (a, b) => Number(a?.displayOrder) - Number(b?.displayOrder)
  );

  const shiftTableColumn = (
    elementKey: string,
    direction: 'LEFT' | 'RIGHT'
  ) => {
    setTableColumns((prevColumns: CustomRecordType[]) => {
      const shiftArrayIndex = (direction: 'LEFT' | 'RIGHT', index: number) => {
        return direction === 'LEFT' ? index - 1 : index + 1;
      };

      const index = prevColumns.findIndex(column => column.key === elementKey);
      let newIndex = shiftArrayIndex(direction, index);

      const currentElementAtNewIndex = prevColumns[newIndex];

      // Shift a second time if the column is hidden
      if (currentElementAtNewIndex && !currentElementAtNewIndex.visible) {
        newIndex = shiftArrayIndex(direction, newIndex);
      }

      const unmoveableColumnKeys = ['actions', 'cost', 'measured'];

      // Combined bounds and unmoveable column check
      if (
        newIndex < 0 ||
        newIndex >= prevColumns.length ||
        unmoveableColumnKeys.includes(prevColumns[newIndex].key)
      ) {
        return prevColumns;
      }

      const updatedTableColumns = [...prevColumns];
      const [movedElement] = updatedTableColumns.splice(index, 1);
      updatedTableColumns.splice(newIndex, 0, movedElement);

      return updatedTableColumns;
    });
  };

  const handleFilterByScenario = (designId: string) => {
    setFilterByScenarioModal(true);
    setSelectedScenarios([...selectedScenarios, designId]);
  };

  useEffect(() => {
    const outcomeColumns = outcomes?.map(outcome => ({
      title: () => (
        <div>
          <div className="result-table-header rotate">
            <Ellipsis charCount={15}>{outcome.targetVariable}</Ellipsis>
          </div>
          {activeView === 'default' && (
            <ArrowButtons
              onClick={(elementKey, direction) =>
                shiftTableColumn(elementKey, direction)
              }
              elementKey={outcome.targetVariable}
            />
          )}
        </div>
      ),
      sourceRecord: outcome,
      filters:
        outcome.type !== VariableType.NUMERIC
          ? outcome.values?.map(value => ({
            text: value,
            value: value,
          }))
          : undefined,
      onFilter: (
        value: string | NumericFilterValues,
        record: CustomRecordType
      ) =>
        outcome.type !== VariableType.NUMERIC
          ? handleTextFilter(String(value), record, outcome.targetVariable)
          : handleRangeFilter(
            value as NumericFilterValues,
            record,
            outcome.targetVariable
          ),
      filterIcon: <FilterOutlined />,
      filterDropdown:
        outcome.type === VariableType.NUMERIC
          ? (props: FilterDropdownProps) => (
            <CustomRangeFilterDropdown
              {...props}
              minValue={outcome.lower as number | null}
              maxValue={outcome.upper as number | null}
            />
          )
          : undefined,
      className: 'custom-column-class',
      unit: outcome.unit === 'Not Applicable' ? '' : outcome.unit,
      dataIndex: outcome.targetVariable,
      key: outcome.targetVariable,
      ellipsis: true,
      category: outcome.category?.name,
      searchableText: outcome.targetVariable,
      columnType: 'outcome',
      visible:
        tableColumns.length > 0
          ? tableColumns.find(tc => tc.dataIndex === outcome.targetVariable)
            ?.visible
          : true,
      render: (value: any, record: any) => {
        const formulation = record.formulation;
        const hasConfidenceIntervals = formulation.items.some((item: any) =>
          item.metrics.some(
            (metric: any) =>
              metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
          )
        );
        const formulationItem = record.formulation.items.find(
          (f: any) => f.variable.name === outcome.targetVariable
        );
        let confidenceIntervals: FormulationItemMetric | undefined;
        let reliabilityPercentage: FormulationItemMetric | undefined;
        let desirability: FormulationItemMetric | undefined;

        if (formulationItem) {
          if (formulationItem.type === FormulationItemType.TARGET_PREDICTED) {
            if (hasConfidenceIntervals) {
              confidenceIntervals = formulationItem.metrics.find(
                (metric: any) =>
                  metric.type === FormulationItemMetricType.CONFIDENCE_INTERVAL
              );
            }

            reliabilityPercentage = formulationItem.metrics.find(
              (metric: any) =>
                metric.type === FormulationItemMetricType.RELIABILITY
            );

            desirability = formulationItem.metrics.find(
              (metric: any) =>
                metric.type === FormulationItemMetricType.DESIRABILITY
            );
          }
        }

        const content = hasConfidenceIntervals ? (
          <>
            <div>{value}</div>
            {hasConfidenceIntervals && (
              <ConfidenceIntervalText
                confidenceInterval={confidenceIntervalsStringToArray(
                  confidenceIntervals?.value
                )}
                reliabilityPercentage={convertReliabilityPercentage(
                  reliabilityPercentage?.value
                )}
              />
            )}
          </>
        ) : (
          <span>{value}</span>
        );

        const contentToRender = hasConfidenceIntervals ? (
          <Popover content={content}>{content}</Popover>
        ) : (
          content
        );

        if (desirability) {
          return getCellColor(Number(desirability.value), contentToRender);
        }

        return contentToRender;
      },
    }));

    const ingredientCompositionColumns = currentProject?.ingredientComposition.map(
      composition => ({
        title: () => (
          <div>
            <div className="result-table-header rotate">
              <Ellipsis charCount={15}>{composition.name}</Ellipsis>
            </div>
            <ArrowButtons
              onClick={(elementKey, direction) =>
                shiftTableColumn(elementKey, direction)
              }
              elementKey={composition.id}
            />
          </div>
        ),
        filterIcon: <FilterOutlined />,
        filterDropdown: CustomRangeFilterDropdown,
        sourceRecord: composition,
        unit: '%',
        onFilter: (
          filterValue: NumericFilterValues,
          record: CustomRecordType
        ) => handleRangeFilter(filterValue, record, composition.id),
        className: 'custom-column-class',
        dataIndex: composition.id,
        key: composition.id,
        searchableText: composition.name,
        ellipsis: true,
        columnType: 'composition',
        category: 'compositions',
        visible:
          tableColumns.length > 0
            ? tableColumns.find(tc => tc.dataIndex === composition.id)?.visible
            : true,
        render: (value: string, record: CustomRecordType) => {
          return <span className="custom-column">{value}%</span>;
        },
      })
    );

    const ingredientColumns = ingredients
      .filter(i => i.isActive)
      .map(i => ({
        title: () => (
          <div>
            <div className="result-table-header rotate">
              <Ellipsis charCount={15}>{i.ingredient.name}</Ellipsis>
            </div>
            {activeView === 'default' && (
              <ArrowButtons
                onClick={(elementKey, direction) =>
                  shiftTableColumn(elementKey, direction)
                }
                elementKey={i.ingredient.name}
              />
            )}
          </div>
        ),
        sourceRecord: i,
        dataIndex: i.ingredient.name,
        key: i.ingredient.name,
        searchableText: i.ingredient.name,
        unit: i.unit === 'Not Applicable' ? '' : i.unit,
        ellipsis: true,
        className: !selectedTestPlanToReview
          ? 'custom-column-class'
          : undefined,
        filterIcon: <FilterOutlined />,
        filters:
          i.type !== VariableType.NUMERIC
            ? i.values.map(value => ({
              text: value,
              value: value,
            }))
            : undefined,
        filterSearch: true,
        onFilter: (
          value: string | NumericFilterValues,
          record: CustomRecordType
        ) =>
          i.type !== VariableType.NUMERIC
            ? handleTextFilter(String(value), record, i.ingredient.name)
            : handleRangeFilter(
              value as NumericFilterValues,
              record,
              i.ingredient.name
            ),
        filterDropdown:
          i.type === VariableType.NUMERIC
            ? (props: FilterDropdownProps) => (
              <CustomRangeFilterDropdown
                {...props}
                minValue={i.lowerLimit}
                maxValue={i.upperLimit}
              />
            )
            : undefined,
        category: i.category.name,
        columnType: 'ingredient',
        visible:
          tableColumns.length > 0
            ? tableColumns.find(tc => tc.dataIndex === i.ingredient.name)
              ?.visible
            : true,
        render: (value: string, record: CustomRecordType) => {
          const renderValue =
            i.type !== VariableType.NUMERIC ? (
              <span className="custom-column">
                <EllipsisMiddle suffixCount={6}>
                  {value + i.unit ?? '%'}
                </EllipsisMiddle>
              </span>
            ) : (
              <span className="custom-column">{value + i.unit}</span>
            );

          if (record.status === 'Unmatched' && record.parentFormulationValues) {
            const parentFormulationValue = record.parentFormulationValues.get(
              i.ingredient.name
            );

            if (
              parentFormulationValue !== undefined &&
              parentFormulationValue !== value
            ) {
              const updatedSelectedTestPlanToReview = testPlansWithFormulations.find(
                testPlan => testPlan?.id === selectedTestPlanToReview?.id
              );
              const hasNote = updatedSelectedTestPlanToReview?.latestUpload?.notes.find(
                note =>
                  note.formulationId === record.formulation.id &&
                  note.ingredientId === i.ingredient.id
              );

              if (hasNote) {
                return (
                  <Flex align="center" gap={6}>
                    {renderValue}
                    <div>
                      <CheckOutlined style={{ color: '#52C41A' }} />
                    </div>
                  </Flex>
                );
              }

              return {
                props: {
                  style: { backgroundColor: '#FEE0D5E6' },
                },
                children: (
                  <Flex align="center" style={{ color: '#EF4136' }}>
                    {renderValue}
                    <Popover
                      content={
                        <TestPlanNotesModal
                          ingredientId={i.ingredient.id}
                          ingredientName={i.ingredient.name}
                          newValue={value}
                          originalValue={parentFormulationValue}
                          testPlan={selectedTestPlanToReview!}
                          formulationId={record.formulation.id}
                          formulationKey={record.formulation.key}
                        />
                      }
                      trigger="click"
                    >
                      <Button
                        size="small"
                        type="link"
                        icon={<InfoCircleOutlined />}
                      />
                    </Popover>
                  </Flex>
                ),
              };
            }
          }

          return renderValue;
        },
      }));

    const columns = [
      {
        title: selectedTestPlanToReview?.latestUpload ? (
          <Flex vertical justify="space-between">
            <div>
              <Button
                type="link"
                size="small"
                icon={<SummaryCloseIcon />}
                onClick={() => setSelectedTestPlanToReview(undefined)}
              />
              <div
                css={css`
                  color: rgba(26, 54, 63, 0.45);
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 22px;
                `}
              >
                {selectedTestPlanToReview?.latestUpload?.file?.name}
              </div>
            </div>
            <div>Experiment</div>
          </Flex>
        ) : (
          'Experiment'
        ),
        dataIndex: 'experiment',
        key: 'experiment',
        className: 'custom-column-class-experiment',
        width: 300,
        visible: true,
        filters: projectFormulations
          .filter(result => result.status === FormulationStatus.PINNED)
          .map(result => ({
            text: result.key,
            value: result.key,
          })),
        filterIcon: <FilterOutlined />,
        filterSearch: true,
        filteredValue:
          isolatedRows.length > 0 ? isolatedRows.map(key => key) : undefined,
        onFilter: (value: string, record: CustomRecordType) =>
          handleTextFilter(String(value), record, 'experiment', true),
        fixed: 'left',
        render: (value: string, record: CustomRecordType) => (
          <ExperimentPopover formulation={record.formulation}>
            <strong>
              <Link
                onClick={() =>
                  logEvent(TrackableEvent.EXECUTE_SOLUTION_VIEW_FORMULATION)
                }
                to={`/project/${currentProject?.id
                  }/experiment/${encodeURIComponent(record.formulation.id)}`}
              >
                <Flex align="center" justify="space-between">
                  <div> {record?.formulation?.name ?? value} </div>
                  {record.source === FormulationSource.SUGGESTED && (
                    <GreenIndicator />
                  )}
                </Flex>
              </Link>
            </strong>
          </ExperimentPopover>
        ),
      },
      ...(!selectedTestPlanToReview
        ? [
          {
            title: () => 'Source',
            dataIndex: 'source',
            key: 'source',
            width: 75,
            visible: true,
            filters: Object.values(FormulationSource).map(v => ({
              text: startCase(lowerCase(v)),
              value: v,
            })),
            filterIcon: <FilterOutlined />,
            filterSearch: true,
            onFilter: (value: string, record: CustomRecordType) =>
              handleTextFilter(String(value), record, 'source'),
            render: (value: string, record: CustomRecordType) =>
              record.source === FormulationSource.SUGGESTED ? (
                // We only want to display the popover for suggested since it's the only type with a related design job
                <SourcePopover
                  formulation={record.formulation}
                  onClick={handleFilterByScenario}
                  setSelectedScenarios={setSelectedScenarios}
                  selectedScenarios={selectedScenarios}
                >
                  <Tag color="default">{value}</Tag>
                </SourcePopover>
              ) : (
                <Tag color="default">{value}</Tag>
              ),
          },
          ...(activeView === 'recentlyGenerated'
            ? [
              // {
              //   title: () => 'Turing Expected Improvement Score',
              //   dataIndex: 'expectedImprovementScore',
              //   key: 'expectedImprovementScore',
              //   width: 75,
              //   visible: true,
              //   sorter: (a: CustomRecordType, b: CustomRecordType) =>
              //     a.expectedImprovementScore - b.expectedImprovementScore,
              //   render: (value: number, record: CustomRecordType) => {
              //     let color = '';

              //     if (value > 0 && value < 0.25) {
              //       color = '#ef4136'; // red
              //     } else if (value >= 0.25 && value < 0.5) {
              //       color = '#eb5b37'; // orange
              //     } else if (value >= 0.5 && value < 0.75) {
              //       color = '#f7ec78'; // yellow
              //     } else if (value >= 0.75) {
              //       color = '#66CB34'; // green
              //     }

              //     return {
              //       props: {
              //         style: { backgroundColor: color },
              //       },
              //       children: <div>{value}</div>,
              //     };
              //   },
              // },
              {
                title: () => 'Turing Desirability',
                dataIndex: 'desirability',
                key: 'desirability',
                width: 75,
                visible: true,
                sorter: (a: CustomRecordType, b: CustomRecordType) =>
                  a.desirability - b.desirability,
                render: (value: number, record: CustomRecordType) => {
                  let color = '';

                  if (value > 0 && value < 0.25) {
                    color = '#ef4136'; // red
                  } else if (value >= 0.25 && value < 0.5) {
                    color = '#eb5b37'; // orange
                  } else if (value >= 0.5 && value < 0.75) {
                    color = '#f7ec78'; // yellow
                  } else if (value >= 0.75) {
                    color = '#66CB34'; // green
                  }

                  return {
                    props: {
                      style: { backgroundColor: color },
                    },
                    children: <div>{value}</div>,
                  };
                },
              },
              {
                title: () => <div className=''>Initiative</div>,
                dataIndex: 'initiative',
                key: 'initiative',
                visible: true,
                width: 700,
                filters: Array.from(new Set(projectFormulations.map(f => f.campaign?.name)))
                  .filter(name => name)
                  .map(name => ({
                    text: name,
                    value: name,
                  })),
                onFilter: (value: string, record: CustomRecordType) =>
                  handleTextFilter(String(value), record, 'initiative'),
                filterIcon: <FilterOutlined />,
                filterSearch: true,
                render: (value: string, record: CustomRecordType) => (
                  <Tag>{value} </Tag>
                ),
              },
              {
                title: () => (
                  <div className="">Measured</div>
                ),
                dataIndex: 'measured',
                key: 'measured',
                width: 700,
                visible: true,
                filters: [
                  {
                    text: 'Yes',
                    value: 'Yes',
                  },
                  {
                    text: 'No',
                    value: 'No',
                  },
                ],
                filterIcon: <FilterOutlined />,
                filterSearch: true,
                onFilter: (value: string, record: CustomRecordType) =>
                  handleTextFilter(String(value), record, 'measured'),
                render: (value: string, record: CustomRecordType) => (
                  <Tag color="green">{value}</Tag>
                ),
              },
            ]
            : []),

        ]
        : [
          {
            title: () => 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 75,
            visible: true,
            filters: ['Matched', 'Unmatched'].map(v => ({
              text: v,
              value: v,
            })),
            filterIcon: <FilterOutlined />,
            onFilter: (value: string, record: CustomRecordType) =>
              handleTextFilter(String(value), record, 'status'),
            render: (value: string, record: CustomRecordType) => (
              <Tag color={value === 'Matched' ? 'green' : 'default'}>
                {value}
              </Tag>
            ),
          },
        ]),
      ...(pricingFeature && !selectedTestPlanToReview
        ? [
          {
            title: () => (
              <div className="result-table-header rotate">Cost</div>
            ),
            dataIndex: 'cost',
            key: 'cost',
            width: 75,
            visible: true,
            filterDropdown: CustomRangeFilterDropdown,
            onFilter: (filterValue: NumericFilterValues, record: any) =>
              handleRangeFilter(filterValue, record, 'cost'),
            filterIcon: <FilterOutlined />,
            filterSearch: true,
            render: (value: number, record: CustomRecordType) => {
              return formatCostScore(
                value ?? 0,
                currentProject?.costMeasurementUnit,
                currentProject?.monetaryUnit
              );
            },
          },
        ]
        : []),
      ...((!selectedTestPlanToReview ? outcomeColumns : []) ?? []),
      ...(ingredientCompositionColumns ?? []),
      ...(ingredientColumns ?? []),
      // ...(!selectedTestPlanToReview
      //   ? [
      //     {
      //       title: 'Actions',
      //       key: 'actions',
      //       render: (_value: any, record: CustomRecordType) => (
      //         {/*<ActionsDropdown record={record} />*/ }
      //       ),
      //       visible: true,
      //     },
      //   ]
      //   : []),
    ];

    setTableColumns(columns);
  }, [
    projectFormulations,
    isolatedRows.length,
    latestDesign,
    selectedTestPlanToReview,
    testPlansWithFormulations,
    activeView,
  ]);

  const handleUpdateTestPlanFormulations = async (
    formulationKey: string,
    testPlanId: string,
    action: 'ADD' | 'REMOVE'
  ) => {
    const testPlan = testPlansWithFormulations.find(
      testPlan => testPlan?.id === testPlanId
    );

    const currentFormulationIds =
      testPlan?.formulations.map(formulation => formulation.id) ?? [];

    let updatedFormulationIds: string[] = [...currentFormulationIds];

    const formulationKeysToModify =
      selectedRowKeys.length !== 0 && action === "ADD" ? selectedRowKeys : [formulationKey];

    for (const formulationKey of formulationKeysToModify) {
      const formulationToModify = projectFormulations.find(
        formulation => formulation.key === formulationKey
      );

      if (!formulationToModify) {
        return;
      }

      if (action === 'ADD') {
        updatedFormulationIds.push(formulationToModify.id);
      } else {
        updatedFormulationIds = updatedFormulationIds.filter(
          formulationId => formulationId !== formulationToModify.id
        );
      }
    }

    if (testPlan) {
      const updatedTestPlan = await updateTestPlan({
        variables: {
          testPlanId: testPlan.id,
          name: testPlan.name,
          updatedFormulationIds,
        },
      });

      const testPlanIndexToUpdate = testPlansWithFormulations.findIndex(
        testPlan => testPlan?.id === testPlanId
      );

      if (
        updatedTestPlan.data?.updateOneTestPlan.formulations &&
        testPlanIndexToUpdate > -1
      ) {
        const updatedTestPlansWithFormulations = [...testPlansWithFormulations];
        updatedTestPlansWithFormulations[testPlanIndexToUpdate] =
          updatedTestPlan.data.updateOneTestPlan;

        setTestPlansWithFormulations(updatedTestPlansWithFormulations);
      }
    }
  };

  const handleOnReviewTestPlan = (
    selectedTestPlan: TestPlanWithFormulationsType
  ) => {
    setSelectedTestPlanToReview(selectedTestPlan);
  };

  const handleUpdateModel = async () => {
    if (selectedTestPlanToReview?.latestUpload) {
      try {
        await triggerTestPlanModelRebuild({
          variables: {
            testPlanUploadId: selectedTestPlanToReview.latestUpload.id,
          },
        });
        await getTestPlansWithFormulations(selectedIteration!.id);
        setShowModelRebuildRunningMessage(true);
        setSelectedTestPlanToReview(undefined);
      } catch (error: any) {
        notification.error({
          message: 'Error rebuilding model',
          description: error.message,
        });
      }
    } else {
      notification.error({
        message: 'There was an issue rebuilding the model',
      });
    }
  };

  const handleCheckJobStatus = async () => {
    const testPlanWithPendingRebuild = testPlansWithFormulations.find(
      testPlan => {
        const status = testPlan?.latestUpload?.modelRebuildProjectJob?.status;
        return status === JobStatus.IN_PROGRESS || status === JobStatus.PENDING;
      }
    );

    const latestJob =
      testPlanWithPendingRebuild?.latestUpload?.modelRebuildProjectJob;

    if (latestJob) {
      const projectJobStatusResponse = await pollProjectJobStatus({
        variables: {
          projectJobId: latestJob.id,
          projectJobType: ProjectJobType.CUSTOM_MODEL_BUILDING,
        },
      });

      if (
        projectJobStatusResponse.data?.pollProjectJobStatus.status ===
        JobStatus.SUCCESS
      ) {
        setShowModelRebuildCompleteMessage(true);
        setShowModelRebuildRunningMessage(false);
      }
    }
  };

  useEffect(() => {
    const hasModelRebuildLoading = testPlansWithFormulations.find(testPlan => {
      const status = testPlan?.latestUpload?.modelRebuildProjectJob?.status;
      return status === JobStatus.IN_PROGRESS || status === JobStatus.PENDING;
    });

    setShowModelRebuildRunningMessage(hasModelRebuildLoading !== undefined);
  }, [testPlansWithFormulations]);

  return (
    <Flex>
      <div
        css={css`
          border-top: 1px solid #f4f4f4;
          display: flex;
          flex-direction: column;
        `}
      >
        <TestPlanComponent
          handleOnFormulationDrop={handleUpdateTestPlanFormulations}
          handleOnReviewTestPlan={handleOnReviewTestPlan}
        />
      </div>
      <Flex vertical style={{ width: '100%', overflow: 'scroll' }}>
        {selectedTestPlanToReview && (
          <div
            css={css`
              background: rgba(22, 31, 38, 0.5);
              display: flex;
              gap: 8px;
              align-items: center;
              padding: 10px 20px;
            `}
          >
            <QuestionCircleOutlined style={{ color: '#fff' }} />
            <div
              css={css`
                color: #fff;
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              Great news! Test results are in. Click to power up your project
              with fresh data!
            </div>
            <Button
              style={{ color: '#FF4D4F', borderColor: '#FF4D4F', width: 84 }}
              onClick={() => handleUpdateModel()}
              loading={modelRebuildLoading}
              size="small"
            >
              Update
            </Button>
          </div>
        )}
        {showModelRebuildCompleteMessage && (
          <div
            css={css`
              background: rgba(22, 31, 38, 0.5);
              display: flex;
              gap: 8px;
              align-items: center;
              padding: 10px 20px;
            `}
          >
            <CheckOutlined style={{ color: '#90E965' }} />
            <div
              css={css`
                color: #fff;
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              Voila! Your project update is complete! 🎉 Take a look below to
              see the new, highlighted data added just for you. Dive in and
              discover the latest insights!
            </div>
            <Button
              style={{ backgroundColor: '#F6FFED' }}
              onClick={() => setShowModelRebuildCompleteMessage(false)}
              size="small"
            >
              Close
            </Button>
          </div>
        )}
        {showModelRebuildRunningMessage && (
          <div
            css={css`
              background: rgba(22, 31, 38, 0.5);
              display: flex;
              gap: 8px;
              align-items: center;
              padding: 10px 20px;
            `}
          >
            <SyncOutlined onClick={() => handleCheckJobStatus()} />
            <div
              css={css`
                color: #fff;
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
              `}
            >
              Get ready for a project upgrade! We're on it, updating as we
              speak. Keep an eye out for an email notification, and watch this
              space for the exciting update!
            </div>
            <Button
              style={{ backgroundColor: '#F6FFED' }}
              onClick={() => setShowModelRebuildRunningMessage(false)}
              size="small"
            >
              Close
            </Button>
          </div>
        )}
        <Table
          key={tableKey}
          components={{
            body: {
              row: DraggableTableRow,
            },
          }}
          loading={tableData.length === 0 && formulationsFetching}
          className="ice-cream-results-table"
          rowSelection={{
            selectedRowKeys,
            onChange: onRowSelectChange,
          }}
          columns={
            tableColumns.filter(c => c.visible) as ColumnType<
              Record<any, any>
            >[]
          }
          locale={{
            emptyText: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '112px',
                  gap: '12px',
                  margin: '0 auto',
                }}
              >
                <Empty />
                <Button onClick={resetAllTable}>Clear Filters</Button>
              </div>
            ),
          }}
          dataSource={tableData}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 30,
          }}
          scroll={{ x: 'max-content' }}
          tableLayout="auto"
          onChange={(e, filters) => {
            // Isolate rows makes the filter controlled outside the table. We want to move the control back to the table if they attempt to use the filter instead of the button

            if (filters.experiment?.length !== isolatedRows.length) {
              setIsolatedRows([]);
              setSelectedRowKeys([]);
            }

            setTableFilters(filters);
          }}
        />
      </Flex>
      <ScenarioFilterModal
        open={openFilterByScenarioModal}
        onClose={() => {
          setFilterByScenarioModal(false);
          setSelectedScenarios([]);
        }}
        onOk={() => {
          setFilterByScenarioModal(false);
        }}
        selectedScenarios={selectedScenarios}
        setSelectedScenarios={setSelectedScenarios}
      />
    </Flex>
  );
};
