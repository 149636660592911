/** @jsxImportSource @emotion/react */
import { Button, Empty, Input } from 'antd';
import { useEffect, useState } from 'react';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { hasValue } from '../../_shared/utils/util';
import { IterationNote } from '../../../../__generated__/globalTypes';

const noteTextStyles = css`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Notes = ({
  iterationNotes,
  setShowNewNotesForm,
}: {
  iterationNotes: IterationNote[];
  setShowNewNotesForm(showForm: boolean): void;
}) => {
  const [notesToDisplay, setNotesToDisplay] = useState(iterationNotes);
  const [searchValue, setSearchValue] = useState<string | undefined>();

  useEffect(() => {
    if (searchValue && notesToDisplay) {
      const lowercaseSearch = searchValue.toLowerCase();

      const filteredNotes = notesToDisplay.filter(note => {
        const lowercaseTitle = note.title.toLowerCase();
        const lowercaseText = note.text.toLowerCase();

        return (
          lowercaseTitle.includes(lowercaseSearch) ||
          lowercaseText.includes(lowercaseSearch)
        );
      });

      setNotesToDisplay(filteredNotes);
    }

    if (!searchValue && notesToDisplay?.length !== iterationNotes.length) {
      setNotesToDisplay(iterationNotes);
    }
  }, [searchValue]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          overflowY: 'scroll',
          height: 'calc(100vh - 395px)',
        }}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 5px;
          `}
        >
          <div>
            <SearchOutlined />
          </div>
          <div>
            <Input
              placeholder="Search"
              bordered={false}
              onChange={event => setSearchValue(event.target.value)}
            />
          </div>
        </div>
        {notesToDisplay?.map(note => (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 10px;
              border-radius: 6px;
              border: 1px solid var(--Neutrals-50, #e9ebec);
              background: #fff;
              padding: 18px;
            `}
          >
            <div css={noteTextStyles}>{note.title}</div>
            <div css={noteTextStyles}>{note.text}</div>
            <div
              css={css`
                display: flex;
                color: var(--Neutrals-300, #7c858c);
              `}
            >
              <div
                css={css`
                  display: flex;
                  color: var(--Neutrals-300, #7c858c);
                  font-size: 12px;
                `}
              >
                <UserOutlined />
                <div
                  css={css`
                    margin-left: 5px;
                  `}
                >
                  {note.createdBy.firstName} {note.createdBy.lastName}
                </div>
              </div>
              <div
                css={css`
                  font-size: 12px;
                  margin-left: 10px;
                `}
              >
                {new Date(note.createdAt).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </div>
            </div>
          </div>
        ))}
        {notesToDisplay.length === 0 && !hasValue(searchValue) && (
          <div
            css={css`
              display: flex;
              padding: 20px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border-radius: 10px;
              border: 1px solid var(--Neutrals-10, #f6f6f6);
            `}
          >
            <Empty description="You currently have no notes." />
            <Button onClick={() => setShowNewNotesForm(true)}>
              Create Note
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
