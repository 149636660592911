import { css } from '@emotion/react';

export const cssCircleWithText = css`
  margin-left: 8px;
  width: 1px;
  height: 1px;
  background: #0a2025;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 15px;
  font-size: smaller;
`;

//Not a fan of this pattern
export const editPanelContainerEditMode = (inEditMode: boolean) => css`
  ${inEditMode
    ? `
          padding: 8px 3px 11px 11px;
          box-shadow: 0px 0px 3px 3px rgba(82, 183, 253, 0.2);
          border: 1px solid #52b7fd;`
    : `
        border: 1px solid #e5e5e5;
        padding: 8px 3px 0px 11px;
    `}
`;

export const objectiveEditPanelStyle = css`
  display: flex;

  .editPanelContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 95%;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 3px;
    margin-bottom: 8px;
  }

  .headerMarkStyle {
    height: 16px;
    align-self: center;
  }

  .objectiveDetailsStyle {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .targetVariableStyle {
    margin-left: 7px;
    padding-top: 1px;
    align-self: center;
  }
`;

export const objectiveInputsContainerStyle = css`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;
