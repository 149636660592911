import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { optimize, optimizeVariables } from './__generated__/optimize';

export const runOptimizationQuery = gql`
  mutation optimize(
    $iterationId: String!
    $nextSimulationNumber: Int!
    $constraints: [ConstraintInputType!]!
    $outcomeGoal: OptimizationOptimizationType!
    $targetValue: Float
  ) {
    optimize(
      iterationId: $iterationId
      nextSimulationNumber: $nextSimulationNumber
      constraints: $constraints
      outcomeGoal: $outcomeGoal
      targetValue: $targetValue
    ) {
      productId
      name
      formulation {
        id
        quantities
      }
    }
  }
`;

export const useRunOptimization = (
  options: MutationHookOptions<optimize, optimizeVariables>
) => useMutation<optimize, optimizeVariables>(runOptimizationQuery, options);
