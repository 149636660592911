import { useGraph, useSession } from '../../_shared/context';
import React, { useState, useContext, useEffect, useMemo } from 'react';
import {
  ExpertKnowledge,
  ExpertKnowledgeFormulation,
  ExpertKnowledgeStatus,
  ExpertKnowledgeType,
  usegetExpertKnowledgeForProjectAndUserQuery,
} from '../../../../__generated__/globalTypes';

interface ExpertKnowledgeContextProps {
  relationshipsWithExpertKnowledge?: Map<string, Partial<ExpertKnowledge>>;
  expertKnowledgeFormulation?: ExpertKnowledgeFormulation | null;
  isExpertKnowledgeSubmitted?: boolean;
  selectedRelationshipKey?: string;
  showEditRelationshipForm?: boolean;
  ingredientsWithoutRelationship: string[];
  onAddRelationship(expertKnowledge: Partial<ExpertKnowledge>): void;
  setSelectedRelationshipKey(value: string): void;
  setShowEditRelationshipForm(value: boolean): void;
}

const expertKnowledge: ExpertKnowledgeContextProps = {
  ingredientsWithoutRelationship: [],
  onAddRelationship: () => {},
  setSelectedRelationshipKey: () => {},
  setShowEditRelationshipForm: () => {},
};

const ExpertKnowledgeContext = React.createContext<ExpertKnowledgeContextProps>(
  expertKnowledge
);

interface ExpertKnowledgeContextProviderProps {
  children?: React.ReactNode;
}

export const ExpertKnowledgeContextProvider = ({
  children,
}: ExpertKnowledgeContextProviderProps) => {
  const { graph, activeOutcome } = useGraph();
  const { currentProject } = useSession();

  const {
    data: expertKnowledgeFormulationData,
    refetch: refetchExpertKnowledge,
  } = usegetExpertKnowledgeForProjectAndUserQuery({
    variables: {
      projectId: currentProject!.id,
    },
  });

  const expertKnowledgeFormulation = expertKnowledgeFormulationData?.expertKnowledgeForProjectAndUser as ExpertKnowledgeFormulation;

  const isExpertKnowledgeSubmitted =
    expertKnowledgeFormulation?.status === ExpertKnowledgeStatus.SUBMITTED;

  const [
    relationshipsWithExpertKnowledge,
    setRelationshipsWithExpertKnowledge,
  ] = useState<Map<string, Partial<ExpertKnowledge>>>(new Map());
  const [selectedRelationshipKey, setSelectedRelationshipKey] = useState<
    string | undefined
  >();
  const [showEditRelationshipForm, setShowEditRelationshipForm] = useState(
    false
  );
  const [initialProcessingDone, setInitialProcessingDone] = useState(false);

  const allIngredients = useMemo(
    () =>
      currentProject?.ingredientList.map(({ ingredient }) => ingredient.name) ??
      [],
    [currentProject?.ingredientList]
  );

  const ingredientsWithoutRelationship = useMemo(
    () =>
      allIngredients.filter(
        ingredient =>
          !relationshipsWithExpertKnowledge.has(
            `${activeOutcome}_${ingredient}`
          )
      ),
    [relationshipsWithExpertKnowledge, allIngredients, activeOutcome]
  );

  const onAddRelationship = (expertKnowledge: Partial<ExpertKnowledge>) => {
    const updatedRelationships = new Map(relationshipsWithExpertKnowledge);
    updatedRelationships.set(
      `${expertKnowledge.parentName}_${expertKnowledge.childName}`,
      expertKnowledge
    );
    setRelationshipsWithExpertKnowledge(updatedRelationships);
  };

  useEffect(() => {
    if (!showEditRelationshipForm) {
      setSelectedRelationshipKey(undefined);
      graph.graphStateImmutable = false;
      graph.unhoverNode();
      refetchExpertKnowledge();
    } else {
      graph.graphStateImmutable = true;
    }
  }, [showEditRelationshipForm]);

  useEffect(() => {
    setInitialProcessingDone(false);
  }, [activeOutcome]);

  useEffect(() => {
    if (!expertKnowledgeFormulation || initialProcessingDone) {
      return;
    }

    const relationships = new Map<string, Partial<ExpertKnowledge>>();

    expertKnowledgeFormulation.knowledges.forEach(knowledge => {
      const { childName, parentName } = knowledge;

      if (parentName === activeOutcome) {
        const key = `${parentName}_${childName}`;
        relationships.set(key, knowledge);
      }
    });

    graph.edges.forEach(edge => {
      const edgeSourceIsIngredient = allIngredients.includes(edge.data.source);
      const edgeTargetIsIngredient = allIngredients.includes(edge.data.target);
      const isIngredientToIngredientRelationship =
        edgeSourceIsIngredient && edgeTargetIsIngredient;

      let ingredientId = edge.data.target;

      if (!isIngredientToIngredientRelationship && !edgeTargetIsIngredient) {
        ingredientId = edge.data.source;
      }

      const key = `${activeOutcome}_${ingredientId}`;

      //  Exclude ingredient -> ingredient relationships
      if (!isIngredientToIngredientRelationship && !relationships.has(key)) {
        const ingredientGraphNode = graph.nodes.get(ingredientId);

        relationships.set(key, {
          childName: ingredientId,
          parentName: activeOutcome,
          type:
            ingredientGraphNode?.data.polarity === 'positive'
              ? ExpertKnowledgeType.POSITIVE
              : ExpertKnowledgeType.NEGATIVE,
        });
      }
    });

    setRelationshipsWithExpertKnowledge(relationships);
    setInitialProcessingDone(true);
  }, [expertKnowledgeFormulation, activeOutcome, initialProcessingDone]);

  useEffect(() => {
    for (const edge of graph.edges) {
      const edgeSourceIsOutcome = edge.data.source === activeOutcome;
      const edgeTargetIsOutcome = edge.data.target === activeOutcome;
      const isIngredientToIngredientEdge =
        !edgeSourceIsOutcome && !edgeTargetIsOutcome;

      if (!isIngredientToIngredientEdge) {
        const edgeIngredient = edgeSourceIsOutcome
          ? edge.data.target
          : edge.data.source;

        const relationshipKey = `${activeOutcome}_${edgeIngredient}`;

        const relationship = relationshipsWithExpertKnowledge.get(
          relationshipKey
        );

        if (relationship?.include !== undefined && !relationship.include) {
          graph.removeEdge(edge.data.target, edge.data.source);
        }
      }
    }

    for (const [, value] of relationshipsWithExpertKnowledge) {
      const { confidence, childName, type, include } = value;

      const excludeRelationship = include !== undefined && !include;

      if (!excludeRelationship && confidence && childName) {
        graph.upsertExpertKnowledge({
          childName,
          polarity:
            type === ExpertKnowledgeType.POSITIVE ? 'positive' : 'negative',
        });
      }
    }

    graph.draw();
  }, [relationshipsWithExpertKnowledge]);

  return (
    <ExpertKnowledgeContext.Provider
      value={{
        expertKnowledgeFormulation,
        relationshipsWithExpertKnowledge,
        isExpertKnowledgeSubmitted,
        ingredientsWithoutRelationship,
        selectedRelationshipKey,
        onAddRelationship,
        setSelectedRelationshipKey,
        showEditRelationshipForm,
        setShowEditRelationshipForm,
      }}
    >
      {children}
    </ExpertKnowledgeContext.Provider>
  );
};

export const useExpertKnowledge = () => useContext(ExpertKnowledgeContext);
