/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';

import { Button } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

import { useGraph } from '../../../_shared/context';

export const FullscreenButton = () => {
  const { graph, fullscreen, setFullscreen } = useGraph();

  useEffect(() => {
    setTimeout(() => graph?.cy?.center(), 400);
    const modal = document.querySelector('.knowledge-discovery-modal')!;
    if (fullscreen) {
      modal.classList.add('fullscreen');
    } else {
      modal.classList.remove('fullscreen');
      if (document.fullscreenElement) document.exitFullscreen();
    }
  }, [fullscreen]);

  return (
    <Button
      onClick={() => {
        const modal = document.querySelector('.knowledge-discovery-modal')!;
        if (fullscreen) {
          setFullscreen(false);
        } else {
          modal
            .requestFullscreen()
            .then(() => {
              setFullscreen(true);
              const listener = () => {
                if (!document.fullscreenElement) {
                  setFullscreen(false);
                  document.removeEventListener('fullscreenchange', listener);
                }
              };

              document.addEventListener('fullscreenchange', listener);
            })
            .catch(error => console.log(error.message));
        }
      }}
    >
      {fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
    </Button>
  );
};
