/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

// Placeholder name, need to find out what to actually call this
export const SeverityBar = ({
  prob,
  severity,
  color,
}: {
  prob?: number;
  severity: string;
  color?: string;
}) => {
  const barWidth = Number((prob ?? 0) * 100);
  const barWidthStr = barWidth.toFixed(0) + '%';
  const barColor = color === undefined ? '#ffffff' : color;

  return (
    <div
      css={css`
        display: flex;
        padding: 2px 0px;
      `}
    >
      <div
        css={css`
          width: 35%;
        `}
      >
        {severity}
      </div>
      <div
        css={css`
          width: 45%;
          display: inline-table;
          margin: auto 5px auto auto;
          border: 1px ${barColor} solid;
          background-color: #ffffff;

          height: 10px;
        `}
      >
        <div
          css={css`
            background-color: ${barColor};
            height: 10px;
            width: ${barWidthStr};
          `}
        />
      </div>
      <div
        css={css`
          width: 20%;
        `}
      >
        {barWidthStr}
      </div>
    </div>
  );
};
