/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { useGraph } from '../../_shared/context';
import { Button, Input, Table } from 'antd';
import { useExpertKnowledge } from './expert-knowledge-context.component';
import Column from 'antd/lib/table/Column';
import { RightOutlined } from '@ant-design/icons';
import {
  ExpertKnowledge,
  ExpertKnowledgeType,
  Level,
} from '../../../../__generated__/globalTypes';
import {
  ExpertKnowledgeHighConfidenceIndicator,
  ExpertKnowledgeLowConfidenceIndicator,
  ExpertKnowledgeMediumConfidenceIndicator,
  DefaultRelationshipNegativeDriverArrow,
  DefaultRelationshipPositiveDriverArrow,
  ExpertKnowledgePositiveDriverArrow,
  ExpertKnowledgeNegativeDriverArrow,
  SearchIcon,
  ExpertKnowldgeExcludeRelationship,
} from '../../_shared/style';
import { regexCaseInsensitiveGlobalMatch } from '../../_shared/utils/util';
import { EllipsisMiddle } from '../../_shared/utils/component';

const HeaderContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const HeaderTitleStyle = css`
  font-size: 18px;
  font-weight: 500;
  color: #9d9d9d;
  width: 40%;
`;

const HeaderRightColStyle = css`
  display: flex;
  justify-content: flex-end;

  .ant-btn {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #017aff;
    background: #272727;
  }

  input,
  .ant-input-affix-wrapper {
    background-color: var(--dark-btn-background);
    color: #fff;
  }

  .ant-input-affix-wrapper {
    border-color: #555555;
    width: 45%;
    margin-right: 8px;
  }
`;

const RelationshipListContainerStyle = css`
  border: 1px solid #434343;
  background: #272727;
  border-radius: 8px;
  padding: 0 16px;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-container {
    background: #272727;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #434343;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: unset;
  }

  .ant-table-tbody > tr {
    &:last-child td {
      border-bottom: none;
    }
  }

  .ant-empty-description {
    color: #fff;
    font-weight: 500;
  }

  .outcomeIngredientColumn {
    width: 160px;
  }

  .smallColumn {
    width: 26px;
  }

  .smallColumn,
  .outcomeIngredientColumn {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NameContainerStyle = css`
  width: 160px;
  background: #434343;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 12px;
`;

const SvgContainerStyle = css`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExpertKnowledgeTable = () => {
  const { activeOutcome, graph } = useGraph();
  const {
    relationshipsWithExpertKnowledge,
    setSelectedRelationshipKey,
    setShowEditRelationshipForm,
  } = useExpertKnowledge();

  const [search, setSearch] = useState('');

  const onClickRelationship = (key: string) => {
    setSelectedRelationshipKey(key);
    setShowEditRelationshipForm(true);
  };

  const tableData = useMemo(() => {
    const tableData = [];

    if (relationshipsWithExpertKnowledge) {
      for (const [key, value] of relationshipsWithExpertKnowledge) {
        tableData.push({
          ...value,
          relationshipKey: key,
        });
      }

      return tableData;
    }
  }, [relationshipsWithExpertKnowledge]);

  const filteredTableData = useMemo(() => {
    return tableData?.filter(
      data =>
        regexCaseInsensitiveGlobalMatch(search, data.childName!) ||
        regexCaseInsensitiveGlobalMatch(search, data.parentName!)
    );
  }, [tableData, search]);

  return (
    <div>
      <div css={HeaderContainerStyle}>
        <div css={HeaderTitleStyle}>List of Relationships</div>
        <div css={HeaderRightColStyle}>
          <Input
            onChange={event => setSearch(event.target.value)}
            prefix={<SearchIcon />}
          />
          <Button
            css={css`
              width: 115px;
              height: 36px;
            `}
            ghost
            onClick={() => setShowEditRelationshipForm(true)}
            disabled={activeOutcome === undefined}
          >
            Add new
          </Button>
        </div>
      </div>
      <div css={RelationshipListContainerStyle}>
        <Table
          dataSource={filteredTableData}
          scroll={{
            x: true,
            y: 'calc(100vh - 400px)', //400px is roughly the size of all other containers + padding
          }}
          pagination={false}
          bordered={false}
          showHeader={false}
          onRow={({ relationshipKey }) => ({
            onClick: () => {
              graph.hoverNodeById(relationshipKey.split('_')[1]);
              onClickRelationship(relationshipKey);
            },
            onMouseEnter: () =>
              graph.hoverNodeById(relationshipKey.split('_')[1]),
            onMouseLeave: () => graph.unhoverNode(),
          })}
        >
          <Column
            dataIndex="parentName"
            key="parentName"
            className="outcomeIngredientColumn"
            render={parentName => (
              // TODO: Figure out if this needs ellipsisMiddle as well for long outcome names
              <div css={NameContainerStyle}>{parentName}</div>
            )}
          />
          <Column
            dataIndex="type"
            key="type"
            className="smallColumn"
            render={(type, relationship: ExpertKnowledge) => (
              <div css={SvgContainerStyle}>
                {relationship.id === undefined &&
                  type === ExpertKnowledgeType.POSITIVE && (
                    <DefaultRelationshipPositiveDriverArrow />
                  )}
                {relationship.id === undefined &&
                  type === ExpertKnowledgeType.NEGATIVE && (
                    <DefaultRelationshipNegativeDriverArrow />
                  )}
                {relationship.id !== undefined && !relationship.include && (
                  <ExpertKnowldgeExcludeRelationship />
                )}
                {relationship.id !== undefined &&
                  relationship.include &&
                  type === ExpertKnowledgeType.POSITIVE && (
                    <ExpertKnowledgePositiveDriverArrow />
                  )}
                {relationship.id !== undefined &&
                  relationship.include &&
                  type === ExpertKnowledgeType.NEGATIVE && (
                    <ExpertKnowledgeNegativeDriverArrow />
                  )}
              </div>
            )}
          />

          <Column
            dataIndex="childName"
            key="childName"
            className="outcomeIngredientColumn"
            render={childName => (
              <EllipsisMiddle
                style={{
                  color: '#FFFFFF',
                  width: '160px',
                  background: '#434343',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '4px 12px',
                }}
                suffixCount={10}
              >
                {childName}
              </EllipsisMiddle>
            )}
          />
          <Column
            dataIndex="confidence"
            key="confidence"
            className="smallColumn"
            render={confidence => (
              <div css={SvgContainerStyle}>
                {confidence === Level.LOW && (
                  <ExpertKnowledgeLowConfidenceIndicator />
                )}
                {confidence === Level.MEDIUM && (
                  <ExpertKnowledgeMediumConfidenceIndicator />
                )}
                {confidence === Level.HIGH && (
                  <ExpertKnowledgeHighConfidenceIndicator />
                )}
              </div>
            )}
          />
          <Column
            dataIndex="relationshipKey"
            align="right"
            render={() => <RightOutlined />}
          />
        </Table>
      </div>
    </div>
  );
};
