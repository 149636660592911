/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { StepArrow, StepFlag } from '../../../_shared/style';
import './exploration-steps.css';
import { ExplorationSteps } from '../exploration-steps/exploration-steps';
import { Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
const { Search } = Input;
export const ExplorationHeader = ({
  activeStep,
  showExplorationNameInput,
  explorationName,
  loading,
  setExplorationName,
  handleExplorationNameChange,
  setShowExplorationNameInput,
}: {
  activeStep?: string;
  showExplorationNameInput: boolean;
  explorationName: string | undefined;
  loading: boolean;
  setExplorationName: any;
  handleExplorationNameChange: any;
  setShowExplorationNameInput: any;
}) => {
  return (
    <div id="exploration-header">
      <div id="exploration-header-container">
        <div id="exploration-title">
          {showExplorationNameInput ? (
            <Search
              style={{ marginTop: 26, marginBottom: 10 }}
              enterButton="Save"
              value={explorationName}
              onChange={e => setExplorationName(e.target.value)}
              loading={loading}
              onSearch={e => {
                handleExplorationNameChange();
              }}
            />
          ) : (
            <span className="exploration-section-header-text">
              {explorationName}
            </span>
          )}
          {!showExplorationNameInput && (
            <Button
              style={{ marginTop: 15 }}
              icon={<EditOutlined style={{ color: '#b1b5b8' }} />}
              type="text"
              onClick={() =>
                setShowExplorationNameInput(!showExplorationNameInput)
              }
            />
          )}
        </div>
        <ExplorationSteps activeStep={activeStep} />
      </div>
      {/* <div style={{ display: 'flex', gap: 5 }}>
            <Button
              onClick={() => {
                logEvent(TrackableEvent.EXECUTE_SOLUTION_CLICKED);
                navigate(`/project/${currentProject?.id}/experiments`);
              }}
            >
              Go To Execute Solutions
            </Button>
          </div> */}
    </div>
  );
};
