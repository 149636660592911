import React, { useEffect, useState } from 'react';
import { Table, Tabs, InputNumber, Button, Divider, notification } from 'antd';
import './numeric-custom-filter-dropdown-styles.css';
import { ColumnFilterItem, FilterConfirmProps } from 'antd/lib/table/interface';
import { filter } from 'lodash';
import { isWithinBounds } from '../../workspaces/adaptive-learning/design-validation';
const { TabPane } = Tabs;

export type NumericFilterValues = {
  greaterThan?: number;
  lessThan?: number;
  type: 'RANGE' | 'EQUALITY';
};

export const CustomRangeFilterDropdown: React.FC<{
  prefixCls: string;
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  selectedKeys: React.Key[];
  confirm: (param?: FilterConfirmProps) => void;
  clearFilters?: () => void;
  filters?: ColumnFilterItem[];
  visible: boolean;
  minValue?: number | null;
  maxValue?: number | null;
}> = ({
  setSelectedKeys,
  minValue,
  maxValue,
  confirm,
  clearFilters,
  filters,
}) => {
  let [filterValues, setFilterValues] = useState<
    NumericFilterValues | undefined
  >();

  const applyFilter = () => {
    let filterValuesWithDefaults = {
      greaterThan: filterValues?.greaterThan ?? 0,
      lessThan: filterValues?.lessThan ?? Number.MAX_SAFE_INTEGER,
    };

    let isValid = true;
    let message: string | undefined;

    if (filterValues?.greaterThan !== undefined) {
      isValid = isWithinBounds(filterValues?.greaterThan, minValue, maxValue);
      message = `Greater Than value should be within bounds (Min: ${minValue} Max: ${maxValue})`;
    }

    if (isValid && filterValues?.lessThan !== undefined) {
      isValid = isWithinBounds(filterValues?.lessThan, minValue, maxValue);
      message = `Less Than value should be within bounds (Min: ${minValue} Max: ${maxValue})`;
    }

    if (
      isValid &&
      filterValuesWithDefaults.greaterThan > filterValuesWithDefaults.lessThan
    ) {
      isValid = false;
      message = `Less Than value should be less than Greater Than value`;
    }

    if (isValid) {
      // @ts-ignore // We need this ignore so we can pass an object value to the onFilterfunction {greaterThan: 3, lessThan: 1}
      setSelectedKeys([filterValuesWithDefaults]);
      confirm();
      return;
    }

    if (filterValues?.type === 'EQUALITY') {
      message = `Filter value must be between ${minValue} and ${maxValue}`;
    }

    notification.error({ message });
  };

  const clearFilter = () => {
    setFilterValues(undefined);
    if (clearFilters) {
      clearFilters();
      confirm();
    }
  };

  return (
    <div className="custom-dropdown-container">
      <Tabs defaultActiveKey="1" type="card" centered size="small">
        <TabPane tab="Range" key="1">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <div style={{ flex: 1 }}>
              <span
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  fontWeight: 500,
                }}
              >
                Greater than:{' '}
              </span>
              <InputNumber
                style={{ width: '100%' }}
                value={filterValues?.greaterThan}
                min={0}
                onChange={value =>
                  setFilterValues({
                    ...filterValues,
                    greaterThan: value as number,
                    type: 'RANGE',
                  })
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <span
                style={{
                  display: 'block',
                  marginBottom: '5px',
                  fontWeight: 500,
                }}
              >
                Less than:{' '}
              </span>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                value={filterValues?.lessThan}
                onChange={value =>
                  setFilterValues({
                    ...filterValues,
                    lessThan: value as number,
                    type: 'RANGE',
                  })
                }
              />
            </div>
          </div>
        </TabPane>
        <TabPane tab="Value" key="2">
          <div>
            <span
              style={{ display: 'block', marginBottom: '5px', fontWeight: 500 }}
            >
              Equals:{' '}
            </span>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              value={filterValues?.greaterThan}
              onChange={value =>
                setFilterValues({
                  lessThan: value as number,
                  greaterThan: value as number,
                  type: 'EQUALITY',
                })
              }
            />
          </div>
        </TabPane>
      </Tabs>
      <Divider
        style={{
          marginTop: 6,
          marginBottom: 6,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          gap: 10,
        }}
      >
        <Button
          size="small"
          type="default"
          style={{ marginLeft: '10px' }}
          onClick={clearFilter}
        >
          Clear
        </Button>
        <Button size="small" type="primary" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </div>
  );
};
