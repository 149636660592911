import { useMutation, useQuery } from 'react-query';
import { IngredientGroupRepository } from '../repositories/ingredient-group.repository';

export const useGetIngredientsGroup = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getIngredientGroup`, data.organizationId, data.projectId],
    IngredientGroupRepository.getIngredientsGroups,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useCreateIngredientgroup = () => {
  const mutation = useMutation((data: any) => {
    return IngredientGroupRepository.postIngredientGroup(data);
  });
  return mutation;
};

export const deleteIngredientGroup = () => {
  const mutation = useMutation((data: any) => {
    return IngredientGroupRepository.deleteIngredientGroup(data);
  });
  return mutation;
};
