/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { VariableType } from '@prisma/client';
import { findIterationWithHist_iteration_simulations } from '../../../../../_shared/hooks/__generated__/findIterationWithHist';
import { getDisplayDifference } from '../../../../../_shared/utils/util';
import { useSession } from '../../../../../_shared/context';
import { useWorkspace } from '../../../../../_shared/context/workspace-context';
import { SimulationImpactChange } from './simulation-impact-change.component';
import {
  HistoryChange,
  Outcome,
  SimulationFromIterationType,
  SimulationProductOutcome,
  SimulationProductVersionResultType,
} from '../../context';
import { calculateOutcomes } from '../../context/workspace.utility';
import { WorkspaceProjectColors } from '../../../../../_shared/style';
import {
  logEvent,
  TrackableEvent,
} from '../../../../../_shared/tracking/usage-tracker';
import { ModalToolTip } from './simulation-imact-modal-tooltip.component';
import camelCase from 'lodash/camelCase';

const NUMBER_TEXT = ['one', 'two', 'three', 'four', 'five'];

export const ExplainImpactModal = ({
  onClose,
  benchmark,
  simHistory,
}: {
  onClose: () => void;
  /**
   * Simulation sorted by oldest simulation first
   *
   * inidex 0 = oldest simulation
   * index array length + 1 = most recent simulation
   */
  simHistory: findIterationWithHist_iteration_simulations[];
  benchmark?: SimulationProductVersionResultType;
}) => {
  const { currentProject } = useSession();

  const {
    selectedSimulationProductVersion: currentProduct,
    productColors,
  } = useWorkspace();
  const currentProductColor = productColors[currentProduct?.columnNumber || 0];
  const [changeData, setChangeData] = useState<HistoryChange[]>([]);
  const [currentChange, setCurrentChange] = useState<HistoryChange>({
    changeName: 'No changes',
    outcomes: [],
  });

  const simHistoryReversed = useMemo(() => {
    return [...simHistory].reverse();
  }, [simHistory]);

  //Onload create all of the changes and set the first as the selected change
  useEffect(() => {
    const changes: HistoryChange[] = [];
    //If benchmark exists make that the starting point
    let hasBenchmark = false;
    if (benchmark) {
      changes.push(
        createSingleProductChange(
          benchmark,
          'Benchmark',
          simHistoryReversed[simHistoryReversed.length - 1]
        )
      );
      hasBenchmark = true;
    }

    if (hasBenchmark) {
      if (simHistoryReversed.length === 5) simHistoryReversed.shift();
    }

    const spvs = simHistoryReversed.map(sim => {
      const product = sim.simulationProductVersion.find(spv => {
        return currentProduct?.productVersion.name === spv.productVersion.name;
      });

      return product;
    });

    let changeNum = 1;

    spvs.forEach((change, idx) => {
      const currentSim = simHistoryReversed[idx];
      const changeName = 'Change ' + changeNum;

      const productChange = change
        ? createSingleProductChange(change, changeName, currentSim)
        : undefined;
      if (productChange) {
        changes.push(productChange);
        changeNum++;
      }
    });

    setCurrentChange(changes[changes.length - 1]);
    setChangeData(changes);
  }, []);

  const graphDataSource = useMemo(() => getGraphData(changeData), [changeData]);

  const getOutcomeValue = (
    outcome: Outcome,
    sim: SimulationProductVersionResultType
  ): string => {
    let returnVal = '';
    const productOutcome = outcome.products.get(sim.productVersion.name);

    if (productOutcome !== undefined) {
      returnVal = `${productOutcome.value ? productOutcome.value : 0}`;
    }

    return returnVal;
  };

  const createSingleProductChange = (
    prod: SimulationProductVersionResultType,
    changeName: string,
    sim: SimulationFromIterationType
  ) => {
    const singleProdChange = {
      changeName,
      productVersion: prod,
      outcomes: [] as SimulationProductOutcome[],
    } as HistoryChange;

    const { outcomes: productOutcomes } = prod;

    if (productOutcomes?.length) {
      productOutcomes?.forEach(pO => {
        const spc = { ...pO };
        singleProdChange.outcomes?.push(spc);
      });
    } else {
      const outcomes = calculateOutcomes(sim, currentProject?.activeModel?.id);
      outcomes?.forEach(o => {
        const { targetVariable, outcomeType } = o;
        const oc = {
          outcomeName: targetVariable,
          value: getOutcomeValue(o, prod),
          outcomeType,
        } as SimulationProductOutcome;

        singleProdChange.outcomes.push(oc);
      });
    }
    return singleProdChange;
  };

  //Recharts has mistyped mouse event
  const setChange = (changeName: string) => {
    const currChangeIdx = changeData.findIndex(
      cD => cD.changeName === changeName
    );
    setCurrentChange(changeData[currChangeIdx]);
  };

  const getOutcomeValueLines = () => {
    return currentChange.outcomes.map((o, idx) => {
      const productColor =
        currentChange.outcomes.length > 1
          ? WorkspaceProjectColors.products[idx]
          : currentProductColor;

      let dataLine;
      switch (o.outcomeType) {
        case VariableType.NUMERIC: {
          dataLine = (
            <Line
              type="linear"
              name={o.outcomeName}
              dataKey={'relativeOutcomes[' + idx + '].value'}
              stroke={productColor}
              strokeWidth={2}
              dot={{
                stroke: productColor,
                strokeWidth: 1,
                r: 8,
                strokeDasharray: '',
                fill: productColor,
              }}
              key={camelCase(o.outcomeName + 'ImpactLine')}
            />
          );

          break;
        }
        case VariableType.ORDINAL: {
          dataLine = (
            <Line
              type="linear"
              name={o.outcomeName}
              dataKey={'relativeOutcomes[' + idx + '].value'}
              stroke={productColor}
              strokeWidth={2}
              dot={{
                stroke: productColor,
                strokeWidth: 1,
                r: 8,
                strokeDasharray: '',
                fill: productColor,
              }}
              key={camelCase(o.outcomeName + 'ImpactLine')}
            />
          );
          break;
        }
        default:
          break;
      }

      return dataLine;
    });
  };

  const changeLengthText =
    changeData.length - 1 > 1 ? NUMBER_TEXT[changeData.length - 1] : '';
  const trailingS = changeLengthText ? 's' : '';
  const possesivePronoun = changeLengthText ? ' their ' : ' its ';

  const ImpactModal = (
    <Modal
      title={
        <span>
          <span
            css={css`
              font-weight: bold;
            `}
          >
            {currentProduct?.productVersion.name}
          </span>
          : Visualizing the impact of the changes
        </span>
      }
      open
      onOk={onClose}
      onCancel={onClose}
      width="90%"
      footer={null}
      destroyOnClose
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            height: 40%;
            width: 100%;
          `}
        >
          <div
            css={css`
              padding-left: 24px;
            `}
          >
            Listing the last{' '}
            <span
              css={css`
                font-weight: 700;
              `}
            >
              {changeLengthText}
            </span>{' '}
            change{trailingS} you&apos;ve simulated and {possesivePronoun}
            performance.
          </div>
          <ResponsiveContainer
            width="80%"
            height={300}
            css={css`
              margin: auto;
            `}
          >
            <LineChart
              margin={{ top: 20, right: 20, left: 10, bottom: 0 }}
              data={graphDataSource}
            >
              <XAxis
                dataKey="changeName"
                axisLine={false}
                dy={5}
                tick={false}
              />
              <YAxis
                tick={false}
                domain={['dataMin', 'dataMax + 1']}
                axisLine={false}
              />
              <Tooltip content={<ModalToolTip />} />
              <CartesianGrid stroke="#E7E7E7" />
              <ReferenceLine
                x={currentChange.changeName}
                stroke="black"
                strokeWidth={3}
              />
              <ReferenceLine
                y={0}
                stroke="black"
                strokeDasharray="3 3"
                strokeWidth={1}
              />
              {getOutcomeValueLines()}
            </LineChart>
          </ResponsiveContainer>
          <div
            css={css`
              display: flex;
              width: 100%;
              margin: auto;
              padding: 0px 30px 0px 70px;
            `}
          >
            {changeData.map(cd => {
              const { changeName } = cd;
              const isCurrentChange = changeName === currentChange.changeName;
              return (
                <div
                  css={css`
                    width: inherit;
                    text-align: center;
                    background-color: ${isCurrentChange
                      ? '#000000'
                      : '#EDEDED'};
                    color: ${isCurrentChange ? '#EDEDED' : '#000000'};
                    margin: 0px 2px;
                    padding: 5px;
                    text-decoration: underline dotted black;
                    font-size: larger;
                    font-weight: ${isCurrentChange ? 700 : 400};
                    text-underline-offset: 5px;
                    cursor: ${isCurrentChange ? 'auto' : 'pointer'};
                  `}
                  role="tab"
                  onClick={() => {
                    logEvent(
                      TrackableEvent.PERFORMANCE_HISTORY_CHANGE_CLICKED,
                      {
                        simulationId: simHistoryReversed[0],
                      }
                    );
                    setChange(changeName);
                  }}
                  tabIndex={0}
                >
                  {changeName}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <SimulationImpactChange
            currentChange={currentChange}
            currentSim={simHistory[0]}
            allChanges={changeData}
            benchmark={benchmark}
          />
        </div>
      </div>
    </Modal>
  );

  return (
    <div
      css={css`
        .ant-modal {
          margin: unset;
        }
      `}
    >
      {ImpactModal}
    </div>
  );
};

const getGraphData = (changeData: HistoryChange[]) => {
  const baselineChange = changeData[0];
  const baselineOutcomes = new Map<string, SimulationProductOutcome>();
  baselineChange?.outcomes.forEach(o => baselineOutcomes.set(o.outcomeName, o));

  return changeData.map((c: HistoryChange) => {
    const { changeName, outcomes } = c;
    const outcomeValues = outcomes.map((out: SimulationProductOutcome) => {
      const benchOutcome = baselineOutcomes.get(out.outcomeName);
      const benchVal = Number(benchOutcome?.value);
      const productVal = Number(out.value);

      const dispDiff = getDisplayDifference(productVal, benchVal);
      let value;

      switch (out.outcomeType) {
        case VariableType.NUMERIC:
          value = dispDiff !== undefined ? Number(dispDiff) : 0;
          break;
        case VariableType.ORDINAL:
          value = Number(getOrdinalNumberVal(out));
          break;
        default:
          break;
      }

      return { value };
    });

    return {
      changeName,
      outcomes,
      /**
       * Each outcomes' relative change to the baseline
       */
      relativeOutcomes: outcomeValues,
    };
  });
};

// TODO: Find a better way to do this
const getOrdinalNumberVal = (outcome: SimulationProductOutcome) => {
  const { value } = outcome;
  const keys = outcome.distribution?.map((d, idx) => {
    const { state } = d;
    return { state, index: idx };
  });

  const valueIndex = keys?.find(k => value === k.state)?.index;
  return valueIndex ? Number(valueIndex + 1) : 0;
};
