/** @jsxImportSource @emotion/react */
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button, Collapse } from 'antd';
import { ConstraintInputProp } from './design-constraint-view-container.component';
import { TextContainerComponent } from './text-container.component';
import { DesignConstraintCollapse } from './design-constraint-collapse.component';
const { Panel } = Collapse;
export const DesignEqualityConstraintView = ({
  constraint,
  removeConstraint,
  editConstraint,
  disabled,
}: ConstraintInputProp) => {
  return (
    <DesignConstraintCollapse name={constraint.name}>
      <Panel
        header={
          <TextContainerComponent
            text={constraint.name || 'Target Constraint'}
          />
        }
        key="1"
        extra={
          <div>
            <span
              css={css`
                margin-right: 10px;
              `}
            >
              <Button
                onClick={() => editConstraint(constraint)}
                icon={<EditTwoTone />}
                disabled={disabled}
              />
            </span>
            <span>
              <Button
                onClick={() => removeConstraint(constraint)}
                icon={<DeleteTwoTone />}
                disabled={disabled}
              />
            </span>
          </div>
        }
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          {constraint.values?.map((constraintValue, idx) => (
            <div key={idx}>
              <TextContainerComponent text={constraintValue.name} />
              <span>{`${' Equals'}`}</span>
              <TextContainerComponent text={constraintValue.value} />
            </div>
          ))}
        </div>
      </Panel>
    </DesignConstraintCollapse>
  );
};
