import message from 'antd/lib/message';
import {
  Objective,
  ObjectiveType,
  Outcome,
} from '../../../../../../../../__generated__/globalTypes';
import { BaseModel } from '../../../../../../_shared/hooks/use-project.hook';
export const useDefaultObjectives = (model: BaseModel, outcomes: Outcome[]) => {
  let objMap = new Map<string, Objective>();
  const categoricalOutcomes = outcomes.filter(o => o.type === 'CATEGORICAL');
  const numericalOutcomes = outcomes.filter(o => o.type === 'NUMERIC');
  const ordinalOutcomes = outcomes.filter(o => o.type === 'ORDINAL');
  const numericAndOrdinalOutcomes = [
    ...numericalOutcomes.map(o => o.targetVariable),
    ...ordinalOutcomes.map(o => o.targetVariable),
  ];

  numericAndOrdinalOutcomes.forEach(o => {
    objMap.set(o, {
      importance: 1,
      objectiveType: ObjectiveType.MAXIMIZE,
      targetVariable: o,
    } as Objective);
  });

  categoricalOutcomes.forEach(o => {
    objMap.set(o.targetVariable, {
      importance: 1,
      objectiveType: ObjectiveType.TARGET_VALUE,
      targetVariable: o.targetVariable,
    } as Objective);
  });

  return objMap;
};

export const areConstraintsValid = (invalidConstraints: string[]) => {
  let hasValidConstraints = true;

  if (invalidConstraints.length > 0) {
    void message.error({
      content:
        'Constraint values out of range, please update: ' +
        invalidConstraints.join(', '),
      style: {
        marginTop: '20vh',
      },
    });
    hasValidConstraints = false;
  }

  return hasValidConstraints;
};
