/** @jsxImportSource @emotion/react */

import { CloudUploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Button, Divider, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useWorkspace } from '../lab-bench/context';
import { useDesign } from '../../../_shared/context/design-context';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { DesignProjectSettings } from './design-project-settings.component';
import { DesignResults } from './design-results/design-results.component';
import { adaptiveLearningPanelStyles } from './styles/adaptive-learning-panel.styles';
import { UploadResultsModal } from './upload-results-modal.component';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../../_shared/context';
import moment from 'moment';
import { DesignRouteParams } from './types';
import { JobStatus } from '../../../../../__generated__/globalTypes';

export const AdaptiveLearningPanel = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const { isWorkspaceOwner, iteration } = useWorkspace();
  const { currentProject } = useSession();
  const params = useParams<DesignRouteParams>();
  const navigate = useNavigate();
  const [designId, setDesignId] = useState<string>(params.designId);

  const { setConstraints } = useScenarioDetail();
  const { hasSuccessfulDesign, previousDesigns, latestDesign } = useDesign();
  useEffect(() => {
    setDesignId(String(latestDesign?.id));
  }, [latestDesign]);

  const successfulDesigns = previousDesigns.filter(
    pd =>
      pd?.projectJob?.status !== JobStatus.ERROR &&
      pd?.projectJob?.status !== JobStatus.CANCELED
  );

  const handleDesignChange = (designId: string) => {
    setConstraints([]);
    navigate(
      `/project/${currentProject?.id}/iteration/${iteration?.id}/design/${designId}`
    );
  };

  if (!successfulDesigns.some(d => d?.id === designId)) {
    // We don't pull jobs with a status ERROR from the server so we need to add it to the dropwdown manually
    successfulDesigns.push({
      id: designId,
      createdAt: new Date(),
      // This status field isn't used in the Select but typescript requires it.
      status: latestDesign?.status || JobStatus.ERROR,
    });
  }
  return (
    <div css={adaptiveLearningPanelStyles}>
      <div className="alTitleBox">
        <div>
          <h2
            css={css`
              margin-right: auto;
            `}
          >
            {successfulDesigns.length > 0 ? (
              <>
                {`Adaptive Learning >`} Results:{' '}
                <Select
                  onChange={handleDesignChange}
                  style={{ width: 300 }}
                  value={designId}
                  options={successfulDesigns
                    .sort(
                      (a, b) =>
                        new Date(b?.createdAt).getTime() -
                        new Date(a?.createdAt).getTime()
                    )
                    .map((previousDesign, index) => ({
                      value: previousDesign?.id,
                      label: `Round ${
                        successfulDesigns.length - index
                      } - ${moment(previousDesign?.createdAt).format('LLL')}`,
                    }))}
                />
              </>
            ) : (
              'Adaptive Learning'
            )}
          </h2>
        </div>
        <div>
          {hasSuccessfulDesign && isWorkspaceOwner && (
            <React.Fragment>
              <Button
                key="adaptiveLearningDatasetUpload"
                onClick={() => setOpenUploadModal(true)}
                type="primary"
              >
                Add Experiment Results <CloudUploadOutlined />
              </Button>
            </React.Fragment>
          )}
        </div>

        {hasSuccessfulDesign && isWorkspaceOwner && (
          <UploadResultsModal
            open={openUploadModal}
            setOpenUploadModal={setOpenUploadModal}
          />
        )}
      </div>
      <Divider />

      <div
        css={css`
          width: 100%;
          display: flex;
        `}
      >
        <DesignProjectSettings />
        <DesignResults />
      </div>
    </div>
  );
};
