import { Layout } from 'antd';
import React, { ReactNode, createContext, useEffect, useState } from 'react';
import './project-setup-styles.css';
import { ProjectSetupStepper } from './components/project-setup-stepper/project-setup-stepper';
import { ProjectSetupHeader } from './components/project-setup-header/project-setup-header';
import { ProjectInfoForm } from './components/project-info-form/project-info-form';
import {
  IHeaderProjectSetup,
  IProjectInfo,
  IProjectMandatoryInfo,
} from './project-setup-interfaces';
import { ProjectSetupSteps } from '../../components/exploration/constants';
import {
  ProjecSetupSteps,
  ProjectSetupHeaders,
} from './project-setup-constants';
import {
  useCreateProject,
  useFinishProject,
  useGetProjectById,
} from '../../network/services/project.service';
import { IProjectCreateOut } from '@root/network/interfaces/project.interfaces';
import {
  InputOutcomeNamesProvider,
  useInputOutpuNamesContext,
  useSession,
} from '../../_shared/context';
import { ProjectNonNegotiableConstraints } from './components/project-non-negotiable-constraints/project-non-negotiable-constraints';
import { ProjectInputOutcomesStep } from './components/project-inputs-outcomes/project-inputs-outcomes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProjectUploadDataStep } from './components/upload-data/upload-data';
import { ProjectEditDataStep } from './components/edit-data/edit-data';
import { ProjectRawDataAggregate } from './components/project-raw-data-agreggated/project-raw-data-aggregated';
import {
  usefinishRawData,
  useFormulationItemToRawData,
} from '../../network/services/rawdata.service';

const getInitialStep = (
  hasProjectId: boolean,
  stepFromUrl?: 'inputs-outcomes' | 'constraints' | 'data'
) => {
  if (!stepFromUrl) {
    return hasProjectId
      ? ProjectSetupSteps.LOADING
      : ProjectSetupSteps.PROJECT_INFO;
  }

  if (stepFromUrl === 'inputs-outcomes') {
    return ProjectSetupSteps.INPUT_OUTCOMES;
  }

  if (stepFromUrl === 'constraints') {
    return ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS;
  }

  return ProjectSetupSteps.EDIT_RAW_DATA;
};

export const InputOutcomeNames = createContext<Array<string>>([]);
export const ProjectSetupPage = () => {
  const { user, setCurrentProjectToEdit, useFetchProject } = useSession();
  const [fetchProjectById] = useFetchProject();
  const headersTitle = ProjectSetupHeaders;
  const urlPath = useLocation();
  const isEditingExistingProject = urlPath.pathname.includes('/edit/');

  const params = useParams<{
    projectId: string;
    step?: 'inputs-outcomes' | 'constraints' | 'data';
  }>();

  const [enableNextStep, setEnableNextStep] = useState<boolean>(false);
  const [projectHasData, setProjectHasData] = useState<boolean | undefined>(
    undefined
  );
  const [activeStep, setActiveStep] = useState<ProjectSetupSteps>(
    getInitialStep(params.projectId !== undefined, params.step)
  );
  const [activeHeader, setActiveHeader] = useState<IHeaderProjectSetup>(
    headersTitle[ProjectSetupSteps.PROJECT_INFO]
  );
  const [projectInfoData, setProjectInfoData] = useState<IProjectInfo>();
  const [projId, setProjId] = useState<string>('');
  const addProjectSetupInfo = useCreateProject();
  const finishSetupProject = useFinishProject();
  const convertRawDataToIOraw = useFormulationItemToRawData();
  const finishEditingRawData = usefinishRawData();
  const [currentForm, setCurrentForm] = useState<ReactNode>();
  const navigatior = useNavigate();
  const [movingForward, setMovingForward] = useState(true);
  const [names, setNames] = useState<Array<string>>([]);
  const [noData, setNoData] = useState(false);
  const onFormChange = (formData: IProjectInfo) => {
    const {
      productName,
      productCategory,
      projectName,
      projectObjective,
    } = formData;
    const onlyMandatory: IProjectMandatoryInfo = {
      productCategory: productCategory,
      productName: productName,
      projectName: projectName,
      projectObjective: projectObjective,
      lastSetupStep: activeStep,
    };
    setProjectInfoData(formData);
    for (const v of Object.values(onlyMandatory)) {
      if (!v) {
        setEnableNextStep(false);
        return;
      }
    }
    setEnableNextStep(true);
  };

  const FormDataFormatter = () => {
    return {
      ...projectInfoData,
      organizationId: user?.organizationId,
      stage: 'INGEST',
      createdById: user?.id,
    } as IProjectCreateOut;
  };

  const mutationFormatter = (onlySave: boolean) => {
    const values = FormDataFormatter();
    values.proj_id = projId;
    if (!onlySave) {
      setMovingForward(true);
      values.lastSetupStep = getNextStep();
    }

    addProjectSetupInfo.mutate(values, {
      onSuccess: async response => {
        if (projectInfoData) {
          setProjectInfoData({ ...projectInfoData, id: response.data.data });
        }
        setProjId(response.data.data);
        if (!params.projectId) {
          navigatior(`${response.data.data}`);
        } else {
          refetch();
        }
      },
      onError: async () => {
        console.log('ERROR TRYING TO CREATE OR UPDATE');
      },
    });
  };

  const { data, isLoading, isSuccess, refetch, isError } = useGetProjectById({
    projectId: params.projectId,
    organizationId: user?.organizationId,
  });

  useEffect(() => {
    /**
     *  Needed to hydrate the full project with relations in session-context for the rest of the app
     */
    if (params.projectId) {
      fetchProjectById({
        variables: {
          projectId: params.projectId,
        },
      });
    }
  }, [params.projectId]);

  useEffect(() => {
    if (data && data.status) {
      const project = data.data;
      const projectInfo: IProjectInfo = {
        id: project.id,
        productCategory: project.category,
        productName: project.productName,
        projectName: project.name,
        productBrand: project.brand,
        projectObjective: project.projectObjective,
        lastSetupStep: project.lastSetupStep,
        launchDate: project.launch_date,
        typeOfDevelopment: project.typeOfDevelopment,
        productSubCategory: project.productSubCategory,
        constraints: project.constraints,
        ingredientList: project.ingredientList,
        outcomeList: project.outcomeList,
        uploadCSV: project.uploadCSV,
      };
      setProjectHasData(project.uploadCSV);
      const inputOutcomeNames: Array<string> = [];
      projectInfo.outcomeList?.map(o => {
        inputOutcomeNames.push(o.targetVariable);
      });
      projectInfo.ingredientList?.map(i => {
        inputOutcomeNames.push(i.ingredient.name);
      });
      setNames(inputOutcomeNames);
      setProjectInfoData(projectInfo);
      setCurrentProjectToEdit(data.data);

      if (movingForward && isEditingExistingProject) {
        setActiveStep(getInitialStep(true, params.step));
      }

      if (movingForward && !isEditingExistingProject) {
        setActiveStep(
          project.lastSetupStep == 'ERROR'
            ? ProjectSetupSteps.PROJECT_INFO
            : project.lastSetupStep
        );
      }
    }
  }, [data, params.step]);

  //get witch step should show
  useEffect(() => {
    switch (activeStep) {
      case ProjectSetupSteps.PROJECT_INFO:
        setActiveHeader(headersTitle[ProjectSetupSteps.PROJECT_INFO]);
        setCurrentForm(
          <ProjectInfoForm
            onInputChange={onFormChange}
            project={projectInfoData}
          />
        );
        break;
      case ProjectSetupSteps.UPLOAD_DATA:
        setEnableNextStep(Boolean(projectInfoData?.uploadCSV));
        setActiveHeader(headersTitle[ProjectSetupSteps.UPLOAD_DATA]);
        setCurrentForm(
          <ProjectUploadDataStep
            projectHasData={setProjectHasData}
            projectId={projectInfoData?.id!}
            enableNextStep={setEnableNextStep}
            setNoData={setNoData}
          />
        );
        break;
      case ProjectSetupSteps.EDIT_RAW_DATA:
        setEnableNextStep(true);
        setActiveHeader(headersTitle[ProjectSetupSteps.UPLOAD_DATA]);
        setCurrentForm(
          <ProjectEditDataStep
            projectId={projectInfoData?.id!}
            enableNextStep={setEnableNextStep}
            setNoData={setNoData}
            handleNextStep={onClickNextListener}
          />
        );
        break;
      case ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES:
        setEnableNextStep(false);
        setActiveHeader(headersTitle[ProjectSetupSteps.INPUT_OUTCOMES]);
        setCurrentForm(
          <ProjectRawDataAggregate
            enableNextStep={setEnableNextStep}
            projectHasData={projectHasData}
            projectId={projectInfoData?.id}
          />
        );
        break;
      case ProjectSetupSteps.INPUT_OUTCOMES:
        setActiveHeader(headersTitle[ProjectSetupSteps.INPUT_OUTCOMES]);
        setCurrentForm(
          // <InputOutcomeNames.Provider value={names}>
          <InputOutcomeNamesProvider>
            {/* <ProjectInputOutcomesStep
              inputs={projectInfoData?.ingredientList}
              outputs={projectInfoData?.outcomeList}
              project_id={projectInfoData?.id}
              io_names_arr={names}
            /> */}
            <ProjectRawDataAggregate
              enableNextStep={setEnableNextStep}
              projectId={projectInfoData?.id}
              projectHasData={projectHasData}
            />
          </InputOutcomeNamesProvider>
          // </InputOutcomeNames.Provider>
        );
        break;
      case ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS:
        setEnableNextStep(true);
        setActiveHeader(
          headersTitle[ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS]
        );
        setCurrentForm(
          <ProjectNonNegotiableConstraints
            constraints={projectInfoData?.constraints}
            ingredients={projectInfoData?.ingredientList}
            projId={projectInfoData?.id}
          />
        );
        break;
    }
  }, [activeStep, projectInfoData]);

  useEffect(() => {
    if (movingForward) {
      setProjectInfoData(prev => ({ ...prev!, lastSetupStep: activeStep }));
    }
  }, [activeStep, movingForward]);

  const getNextStep = (): ProjectSetupSteps => {
    switch (activeStep) {
      case ProjectSetupSteps.PROJECT_INFO:
        return ProjectSetupSteps.UPLOAD_DATA;
      case ProjectSetupSteps.UPLOAD_DATA:
        if (noData) return ProjectSetupSteps.INPUT_OUTCOMES;
        else return ProjectSetupSteps.EDIT_RAW_DATA;
      case ProjectSetupSteps.EDIT_RAW_DATA:
        return ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES;
      case ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES:
        return ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS;
      case ProjectSetupSteps.INPUT_OUTCOMES:
        return ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS;
      case ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS:
        return ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS;
    }
    return ProjectSetupSteps.PROJECT_INFO;
  };

  const onClickSaveListener = () => {
    mutationFormatter(true);
  };

  const onClickNextListener = async () => {
    if (activeStep === ProjectSetupSteps.EDIT_RAW_DATA) {
      await onConvertRawData();
    }

    if (
      activeStep === ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES ||
      activeStep === ProjectSetupSteps.INPUT_OUTCOMES
    ) {
      finishEditingRawData.mutate(
        {
          organizationId: user?.organizationId ?? '',
          projectId: projectInfoData?.id ?? '',
        },
        {
          onSuccess: () => {
            mutationFormatter(false);
          },
        }
      );
      return;
    }

    mutationFormatter(false);
  };

  //set witch step is before
  const onClickBackListener = () => {
    switch (activeStep) {
      case ProjectSetupSteps.PROJECT_INFO:
        break;
      case ProjectSetupSteps.UPLOAD_DATA:
        setActiveStep(ProjectSetupSteps.PROJECT_INFO);
        break;
      case ProjectSetupSteps.INPUT_OUTCOMES:
        setActiveStep(ProjectSetupSteps.UPLOAD_DATA);
        break;
      case ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS:
        setActiveStep(ProjectSetupSteps.INPUT_OUTCOMES);
        break;
    }
    setMovingForward(false);
    refetch();
  };

  const onFinishSetup = () => {
    const values = FormDataFormatter();
    finishSetupProject.mutate(values, {
      onSuccess: async response => {
        navigatior('/');
      },
      onError: async () => {
        console.log('ERROR TRYING TO FINISH PROJECT SETUP');
      },
    });
  };

  const onConvertRawData = async () => {
    await convertRawDataToIOraw.mutateAsync({
      organizationId: user?.organizationId ?? '',
      projectId: projectInfoData?.id ?? '',
    });
  };

  const onFinishIORaw = async () => {
    finishEditingRawData.mutate({
      organizationId: user?.organizationId ?? '',
      projectId: projectInfoData?.id ?? '',
    });
  };

  return (
    <Layout id="project-setup-page-box">
      <Layout id="project-setup-stepper-box">
        <p className="class-stepper-title">Project Setup</p>
        <ProjectSetupStepper activeStep={activeStep} />
      </Layout>
      <Layout id="project-setup-form-box">
        <ProjectSetupHeader
          title={activeHeader.title}
          description={activeHeader.description}
          activeStep={activeStep}
          nextStepEnable={enableNextStep || isEditingExistingProject}
          backEnable={
            activeStep !== ProjectSetupSteps.PROJECT_INFO &&
            activeStep !== ProjectSetupSteps.EDIT_RAW_INPUT_OUTCOMES &&
            activeStep !== ProjectSetupSteps.EDIT_RAW_DATA
          }
          finishSetup={
            activeStep === ProjectSetupSteps.NON_NEGOTIABLE_CONSTRAINS
          }
          onClickNext={onClickNextListener}
          onClickBack={onClickBackListener}
          onClickSave={onClickSaveListener}
          onClickFinish={onFinishSetup}
        />
        <div className="setup-section">{currentForm}</div>
      </Layout>
    </Layout>
  );
};
