/** @jsxImportSource @emotion/react */

import * as React from 'react';
import Modal from 'antd/lib/modal/Modal';

import { appModalStyles } from '../../_shared/style/snippets';
import { ModalCloseX } from '../../_shared/style/svg-assets';

type AppModalProps = {
  children: React.ReactNode;
  title: string;
  onCancel: () => void;
  visible: boolean;
  viewOnly?: boolean;
};

/**
 * TODO: Determine if this is still relevant
 * @param props
 * @returns
 */
export const AppModal: React.FC<AppModalProps> = props => {
  const { title, onCancel, visible, viewOnly, children } = props;

  return (
    <Modal
      className={viewOnly ? 'view-only' : ''}
      closeIcon={<ModalCloseX />}
      css={appModalStyles}
      title={title}
      visible={visible}
      //onOk={this.handleOk}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
};
