/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/core';
import { Radio, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import noop from 'lodash/noop';
import { NullableConstraintType } from '../types';
import {
  FormulationInput,
  InputType,
} from '../../../../../_shared/components/input';
import { BaseProject, useIngredients } from '../../../../../_shared/hooks';
import { Colors } from '../../../../../_shared/style';
import { parseNumber } from '../../../../../_shared/utils/util';
import { ErrorHeaderMark } from '../../../../../_shared/style';
import { InfoCircleFilled } from '@ant-design/icons';
import { useOptimization } from '../v2/context';
import { useWorkspace } from '../../context';
import {
  logEvent,
  TrackableEvent,
} from '../../../../../_shared/tracking/usage-tracker';
import {
  EditableInput,
  InputType as EditableInputType,
} from '../../../../../_shared/components/input/editable-input.component';
import {
  ConstraintErrorHeaderMarkStyles,
  ConstraintRangeContainerStyles,
  ConstraintRangeInnerContainerStyles,
  ConstraintRangeSpaceStyles,
  ContraintErrorMessageStyles,
} from '../../styles/constraint-driver.styles';
import { VariableType } from '@prisma/client';

export interface ConstraintDriverProps {
  constraint: NullableConstraintType;
  updateConstraint: (constraint: NullableConstraintType) => void;
  benchmarkValue?: string;
  optimizationCalled: boolean;
  project: BaseProject | undefined;
}

enum RadioOptions {
  BENCHMARK = 1,
  BOUNDS = 2,
}

export const ConstraintDriver = ({
  updateConstraint,
  constraint,
  benchmarkValue,
  optimizationCalled,
  project,
}: ConstraintDriverProps) => {
  const { ingredientByName } = useIngredients();
  const { usingLegacyApi, iteration } = useWorkspace();
  const [benchmarkSelectVal, setBenchmarkSelectVal] = useState<number>(
    RadioOptions.BOUNDS
  );

  const ingredientData = project?.ingredientList.find(
    i => constraint.ingredientName === i.ingredient.name
  );

  const isTestCondition = ingredientByName.get(constraint.ingredientName)
    ?.isTestCondition;

  const getTestCondition = () => {
    const ingredient = ingredientByName.get(constraint.ingredientName);

    const isContinuous =
      ingredient?.type === VariableType.NUMERIC ||
      ingredient?.values === undefined ||
      ingredient.values.length === 0;

    return (
      <div>
        {!isContinuous && (
          <div
            css={css`
              background: #f3f3f3;
              color: #373737;
              width: 50%;
              overflow: hidden;
              display: flex;
            `}
          >
            <FormulationInput
              isDisabled={optimizationCalled}
              setValue={noop}
              onChange={noop}
              type={InputType.CHOICE}
              value={constraint.value}
              options={ingredient?.values}
            />
          </div>
        )}
        {isContinuous && (
          <>
            <div>Constrain by range</div>
            <div css={ConstraintRangeContainerStyles}>
              {isNotWithinBounds() && (
                <div css={ConstraintErrorHeaderMarkStyles}>
                  <ErrorHeaderMark />
                </div>
              )}
              <div css={ConstraintRangeInnerContainerStyles}>
                {isNotWithinBounds() && (
                  <div css={ContraintErrorMessageStyles}>
                    <ErrorMessage />
                  </div>
                )}
                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <EditableInput
                    type={EditableInputType.STRING}
                    onChange={updateLowerBounds}
                    setValue={noop}
                    className="rangeInput"
                    value={constraint.bounds?.lowerBounds}
                    suffix={ingredient?.unit}
                    isDisabled={optimizationCalled}
                  />

                  <span css={ConstraintRangeSpaceStyles}>to</span>
                  <EditableInput
                    type={EditableInputType.STRING}
                    onChange={updateUpperBounds}
                    setValue={noop}
                    className="rangeInput"
                    value={constraint.bounds?.upperBounds}
                    suffix={ingredient?.unit}
                    isDisabled={optimizationCalled}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const updateLowerBounds = (v?: string | number) => {
    const previousUpperBounds = constraint.bounds?.upperBounds;
    updateConstraint({
      ingredientName: constraint.ingredientName,
      bounds: {
        upperBounds: previousUpperBounds,
        lowerBounds: parseNumber(v) ?? 0,
      },
    });
  };

  const updateUpperBounds = (v?: string | number) => {
    const previousLowerBounds = constraint.bounds?.lowerBounds;
    updateConstraint({
      ingredientName: constraint.ingredientName,
      bounds: {
        lowerBounds: previousLowerBounds,
        upperBounds: parseNumber(v) ?? 0,
      },
    });
  };

  const isNotWithinBounds = () => {
    if (
      ingredientData?.upperLimit === null ||
      ingredientData?.upperLimit === undefined ||
      ingredientData?.lowerLimit === null ||
      ingredientData?.lowerLimit === undefined
    )
      return;
    const lowerBounds = Number(constraint?.bounds?.lowerBounds);
    const upperBounds = Number(constraint?.bounds?.upperBounds);
    const metadataUpper = Number(ingredientData?.upperLimit);
    const metadataLower = Number(ingredientData?.lowerLimit);

    if (lowerBounds > metadataUpper || lowerBounds < metadataLower) {
      return true;
    }

    if (upperBounds > metadataUpper || upperBounds < metadataLower) {
      return true;
    }

    if (upperBounds && lowerBounds > upperBounds) {
      return true;
    }
  };

  /**
   * TODO Support single value
   */
  const useBenchmarkValue = () => {
    const val = parseNumber(benchmarkValue) ?? undefined;

    const benchConst = {
      ingredientName: constraint.ingredientName,
      bounds: {
        upperBounds: val,
        lowerBounds: val,
      },
    };
    updateConstraint(benchConst);
  };

  const ErrorMessage = () => (
    <div
      css={css`
        font-weight: 700;
        color: #ea291f;
      `}
    >
      {`Enter a value between ${ingredientData?.lowerLimit} ${
        ingredientData?.unit || ''
      } and ${ingredientData?.upperLimit} ${ingredientData?.unit || ''}`}
    </div>
  );

  const getDriverConstraints = () => {
    return (
      <div>
        <div css={ConstraintRangeContainerStyles}>
          {isNotWithinBounds() && (
            <div css={ConstraintErrorHeaderMarkStyles}>
              <ErrorHeaderMark />
            </div>
          )}
          <div css={ConstraintRangeInnerContainerStyles}>
            {isNotWithinBounds() && (
              <div css={ContraintErrorMessageStyles}>
                <ErrorMessage />
              </div>
            )}
            <div
              css={css`
                display: flex;
              `}
            >
              <FormulationInput
                type={InputType.PERCENT}
                value={constraint.bounds?.lowerBounds}
                onChange={updateLowerBounds}
                setValue={noop}
                className="rangeInput"
                isDisabled={benchmarkSelectVal === 1 || optimizationCalled}
              />
              <span css={ConstraintRangeSpaceStyles}>to</span>
              <FormulationInput
                type={InputType.PERCENT}
                value={constraint.bounds?.upperBounds}
                onChange={updateUpperBounds}
                setValue={noop}
                className="rangeInput"
                isDisabled={benchmarkSelectVal === 1 || optimizationCalled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const changeRadio = () => {
    setBenchmarkSelectVal(
      benchmarkSelectVal === RadioOptions.BENCHMARK
        ? RadioOptions.BOUNDS
        : RadioOptions.BENCHMARK
    );
  };

  const constraintTitle = benchmarkValue ? (
    <Radio.Group
      css={css`
        margin-bottom: 11px;
        font-size: 13;
      `}
      value={benchmarkSelectVal}
      onChange={changeRadio}
      disabled={optimizationCalled}
    >
      <Space direction="vertical">
        <Radio
          value={RadioOptions.BENCHMARK}
          css={css`
            color: ${Colors.ARSENIC};
          `}
          onClick={() => useBenchmarkValue()}
        >
          Keep at{' '}
          <span
            css={css`
              border-bottom: 1px dotted;
            `}
          >
            benchmark
          </span>{' '}
          value: {benchmarkValue}%
        </Radio>
        <Radio value={RadioOptions.BOUNDS}>Constrain by range</Radio>
      </Space>
    </Radio.Group>
  ) : (
    <div>Constrain by range</div>
  );

  return (
    <div
      css={css`
        padding: 0px 18px 0px 16px;
      `}
    >
      <div
        css={css`
          font-weight: 700;
          padding-bottom: 5px;
        `}
      >
        {constraint.ingredientName}
      </div>
      {isTestCondition ? (
        <>{getTestCondition()}</>
      ) : (
        <React.Fragment>
          {constraintTitle}
          <div>{getDriverConstraints()}</div>
        </React.Fragment>
      )}
    </div>
  );
};
