/** @jsxImportSource @emotion/react */
import {
  ExpertKnowledgeHighConfidenceIndicator,
  ExpertKnowledgeLowConfidenceIndicator,
  ExpertKnowledgeMediumConfidenceIndicator,
} from '../../../_shared/style';
import { Select } from 'antd';
import { Level } from '../../../../../__generated__/globalTypes';
import {
  DropdownLabelStyle,
  InputColumnStyle,
  LabelStyle,
} from './expert-knowledge-relationship-inputs.styles';

interface ConfidenceInputProps {
  confidence?: Level;
  onChange: (confidence: Level) => void;
}

export const ConfidenceInput = ({
  confidence,
  onChange,
}: ConfidenceInputProps) => {
  return (
    <div css={InputColumnStyle}>
      <span css={LabelStyle}>Your confidence</span>
      <Select
        bordered={false}
        placeholder="-"
        onChange={onChange}
        value={confidence}
        options={[
          {
            value: Level.LOW,
            label: (
              <div css={DropdownLabelStyle}>
                <ExpertKnowledgeLowConfidenceIndicator /> Low
              </div>
            ),
          },
          {
            value: Level.MEDIUM,
            label: (
              <div css={DropdownLabelStyle}>
                <ExpertKnowledgeMediumConfidenceIndicator /> Medium
              </div>
            ),
          },
          {
            value: Level.HIGH,
            label: (
              <div css={DropdownLabelStyle}>
                <ExpertKnowledgeHighConfidenceIndicator /> High
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
