import { css } from '@emotion/react';
import { commonTableCss } from '../../../_shared/style';

export const iterationTableStyle = css`
  padding: 10px 0;
  ${commonTableCss}
`;

export const linkStyle = css`
  color: inherit;
`;
