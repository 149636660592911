import { css } from '@emotion/react';
import { Colors } from '../../../_shared/style';

export const containerStyle = css`
  margin-bottom: 24px;
  border-radius: 6px;
  border: 0.5px solid #dfdfe4;
  box-shadow: 0px 1px 1px #dfdfe4, 0px 0px 4px -1px rgba(3, 25, 30, 0.05),
    0px 1px 8px rgba(3, 25, 30, 0.03);
`;

export const titleStyle = css`
  background-color: ${Colors.WHISPER};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 18px 17px 16px;
`;

export const contentStyle = css`
  display: flex;
  background-color: ${Colors.WHITE};
  padding: 35px;
`;
