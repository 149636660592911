/** @jsxImportSource @emotion/react */
import { InboxOutlined } from '@ant-design/icons';
import {
  FileUpload,
  FileUploadProps,
} from '../../../_shared/components/data/file-upload.component';
import { RcFile } from 'antd/lib/upload';
import { ParseResult, parse } from 'papaparse';
import { notification } from 'antd';
import {
  CompositionInput,
  IngredientInput,
  useupdateIngredientListCompositionsMutation,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import { useIngredients } from '../../../_shared/hooks';
import { IngredientComposition } from '../ingredient-composition-modal.component';
import {
  escapeQuotes,
  numberToString,
  parseNumber,
} from '../../../_shared/utils/util';
import { IngredientCompositionTemplateDownload } from './ingredient-composition-template-download.component';
import { useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { TableDataType } from '../ingredient-composition-table.component';

const csvParseErrorObject = {
  message: 'Error parsing uploaded CSV file.',
  description: 'Please check the format against the sample CSV file.',
};

interface IngredientCompositionFileUploadProps
  extends Omit<FileUploadProps, 'onError' | 'onUploadSuccess' | 'doUpload'> {
  compositions: IngredientComposition[];
  refreshProject(): Promise<void>;
  tableData: TableDataType[];
}

export const IngredientCompositionFileUpload = (
  props: IngredientCompositionFileUploadProps
) => {
  const { currentProject } = useSession();
  const [
    updateIngredientListCompositions,
  ] = useupdateIngredientListCompositionsMutation();
  const { activeNumericIngredients } = useIngredients();

  const { refreshProject } = props;

  const safeParseUploadedDataset = async (result: ParseResult<unknown>) => {
    try {
      await parseUploadedDataset(result);
    } catch (error) {
      notification.error(csvParseErrorObject);
      Sentry.captureException(error);
    }
  };

  const parseUploadedDataset = async (result: ParseResult<unknown>) => {
    const [firstField] = result.meta.fields ?? [];

    if (
      result.errors.length ||
      !result.data ||
      !result.meta.fields ||
      firstField !== 'Component'
    ) {
      notification.error(csvParseErrorObject);
      return;
    }

    const ingredientCompositions: IngredientInput[] = [];

    for (const data of result.data as { [key: string]: string | number }[]) {
      let error: string | undefined;
      let errorDescription: string | undefined;

      // Enforce the component name being a string type
      const componentName = numberToString(data.Component);

      const ingredientId = activeNumericIngredients.find(
        activeNumericIngredient =>
          activeNumericIngredient.ingredient.name.toLowerCase() ===
          componentName.toLowerCase()
      )?.ingredient.id;

      if (ingredientId === undefined) {
        error = `Ingredient ${data.Component} was not found in project`;
      } else {
        const newCompositionNames = Object.keys(data);

        // remove the header
        newCompositionNames.shift();

        const compositionsToCreate: CompositionInput[] = [];

        for (const newCompositionName of newCompositionNames) {
          const composition = props.compositions.find(
            composition =>
              composition.name.toLowerCase() ===
              newCompositionName.toLowerCase()
          );

          if (!composition || !composition.id) {
            error = `Component ${newCompositionName} was not found`;
            break;
          }

          let newValue = parseNumber(data[newCompositionName]);

          if (newValue === undefined) {
            newValue = 0;
          }

          if (newValue < 0 || newValue > 100) {
            error = `Invalid value provided for ${data.Component} and ${newCompositionName}.`;
            errorDescription = 'Values must be between 0-100';
            break;
          }

          compositionsToCreate.push({
            compositionId: composition.id,
            name: composition.name,
            value: newValue,
          });
        }

        ingredientCompositions.push({
          ingredientId: ingredientId,
          compositions: compositionsToCreate,
        });
      }

      if (error) {
        notification.error({ message: error, description: errorDescription });
        return;
      }
    }

    try {
      await updateIngredientListCompositions({
        variables: {
          projectId: currentProject!.id,
          ingredients: ingredientCompositions,
        },
      });

      await refreshProject();

      notification.success({
        description: `Ingredient composition values have been saved successfully.`,
        message: 'Ingredient Composition Saved',
      });
    } catch (error) {
      notification.error({
        message: 'Error saving composition values',
      });
    }
  };

  const handleOnError = (error: unknown) => {
    notification.error(csvParseErrorObject);
  };

  const compositionNames = useMemo(
    () => props.compositions.map(composition => escapeQuotes(composition.name)),
    [props.compositions]
  );

  return (
    <>
      <FileUpload
        uploadType={'dragger'}
        keyScope="project"
        onUploadSuccess={(res: any, file: RcFile | File) =>
          parse<unknown>(file, {
            header: true,
            skipEmptyLines: true,
            dynamicTyping: true,
            delimiter: ',',
            complete: safeParseUploadedDataset,
          })
        }
        doUpload={true}
        onError={handleOnError}
        {...props}
      >
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-p">Drag a file to this area to upload</p>
          <p className="ant-upload-hint">Accepts Single CSV files</p>
        </>
      </FileUpload>
      <div style={{ marginTop: 20 }}>
        <IngredientCompositionTemplateDownload tableData={props.tableData} />
      </div>
    </>
  );
};
