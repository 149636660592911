/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TestPlanWithFormulationsType } from '../../../_shared/context';
import { SummaryCloseIcon } from '../../../_shared/style';
import { Button, Flex } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

export const ResultsSummaryComponent = ({
  testPlan,
  handleOnReviewTestPlan,
  handleCloseSummary,
}: {
  testPlan: TestPlanWithFormulationsType;
  handleOnReviewTestPlan: (
    selectedTestPlan: TestPlanWithFormulationsType
  ) => void;
  handleCloseSummary: () => void;
}) => {
  let numFormulationsUploaded = 0;
  let numFormulationsMatched = 0;
  let numFormulationsUnmatched = 0;

  testPlan?.latestUpload?.uploadedFormulations.forEach(uploadedFormulation => {
    numFormulationsUploaded++;

    if (uploadedFormulation.matched) {
      numFormulationsMatched++;
    } else {
      numFormulationsUnmatched++;
    }
  });

  return (
    <Flex
      vertical
      style={{
        zIndex: 999,
        position: 'absolute',
        left: 290,
        backgroundColor: '#fff',
        padding: 34,
        width: 260,
        height: 350,
        border: '1px solid #F5F5F5',
      }}
      justify="space-between"
    >
      <Button
        type="link"
        icon={<SummaryCloseIcon />}
        onClick={() => handleCloseSummary()}
      />
      <div
        css={css`
          color: rgba(26, 54, 63, 0.45);
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        `}
      >
        {testPlan?.latestUpload?.file?.name}
      </div>
      <Flex
        justify="space-between"
        align="center"
        style={{ borderBottom: '1px solid #F5F5F5', padding: '19px 0' }}
      >
        <div
          css={css`
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            border-radius: 4px;
            background: #f0f0f0;
            svg {
              color: rgb(135, 135, 135);
            }
          `}
        >
          <DownloadOutlined />
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.4);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            width: 92px;
          `}
        >
          Formulations Uploaded
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.88);
            text-align: right;
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          `}
        >
          {numFormulationsUploaded}
        </div>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        style={{ borderBottom: '1px solid #F5F5F5', padding: '19px 0' }}
      >
        <div
          css={css`
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            border-radius: 4px;
            background: #dfc;
            svg {
              color: rgb(71, 188, 22);
            }
          `}
        >
          <CheckOutlined />
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.4);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            width: 92px;
          `}
        >
          Formulations Matched
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.88);
            text-align: right;
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          `}
        >
          {numFormulationsMatched}
        </div>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        style={{ padding: '19px 0' }}
      >
        <div
          css={css`
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            border-radius: 4px;
            background: #ffe8e8;
            svg {
              color: rgb(231, 41, 42);
            }
          `}
        >
          <CloseOutlined />
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.4);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            width: 92px;
          `}
        >
          Formulations Unmatched
        </div>
        <div
          css={css`
            color: rgba(26, 54, 63, 0.88);
            text-align: right;
            font-family: Inter;
            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          `}
        >
          {numFormulationsUnmatched}
        </div>
      </Flex>
      <div>
        <Button
          style={{ borderColor: '#000' }}
          onClick={() => handleOnReviewTestPlan(testPlan)}
        >
          Review
        </Button>
      </div>
    </Flex>
  );
};
