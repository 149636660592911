/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState } from 'react';
import { useIngredients } from '../../../../_shared/hooks';
import { useMemo } from 'react';
import { Bounds } from '../../../../_shared/components/text/bounds';
import { IngredientSearch } from '../../../../_shared/components/input/ingredient-search.component';
import { IngredientCategorySearch } from '../../../../_shared/components/input/ingredient-category-filter.component';
import { ConstraintProp } from './design-constraints.component';
import { emptyConstraint } from '../design-utils';
import {
  ConstraintInputType,
  ConstraintType,
} from '../../../../../../__generated__/globalTypes';
import { InputNumber } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { CloseOutlined } from '@ant-design/icons';
import {
  addValueBody,
  addValueStyle,
  amountConstraintIngName,
  amountConstraintIngredient,
  amountConstraintValue,
  constraintCloseButton,
  constraintIngredientSearch,
  designConstraintIngInfo,
  designConstraintIngredient,
} from './design-constraints.styles';
import { Colors } from '../../../../_shared/style';
import { DesignConstraintInfo } from './design-constraint-info.component';
import {
  FormulationInput,
  InputType,
} from '../../../../_shared/components/input/formulation-input.component';
import { EllipsisMiddle } from '../../../../_shared/utils/component';
import { VariableType } from '@prisma/client';

const emptyAmountConstraint: ConstraintInputType = {
  ...emptyConstraint,
  constraintType: ConstraintType.RANGE,
};

export const RangeConstraint = ({
  updateConstraint,
  currentConstraint,
}: ConstraintProp) => {
  const {
    activeNumericIngredients,
    ingredientByName,
    categories,
  } = useIngredients();
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);

  const templateConstraint = useMemo(
    () => cloneDeep(currentConstraint) ?? emptyAmountConstraint,
    [currentConstraint]
  );

  const searchableIngredients = activeNumericIngredients
    //Must be continuous ingredients only
    .filter(i => {
      if (filteredCategories.length > 0) {
        return filteredCategories.includes(i.category.name);
      } else {
        return i;
      }
    })
    .filter(
      i =>
        !templateConstraint?.coefficients?.find(
          i2 => i.ingredient.name === i2.name
        )
    )
    .sort((a, b) => a.ingredient.name.localeCompare(b.ingredient.name));

  const updateLowerBounds = (val: number) => {
    const { lowerBounds, ...other } = templateConstraint;
    const newLowerBounds = val ?? 0;
    updateConstraint({ lowerBounds: Number(newLowerBounds), ...other });
  };

  const updateUppperBounds = (val: number) => {
    const { upperBounds, ...other } = templateConstraint;
    const newUpperBounds = val ?? 0;
    updateConstraint({ upperBounds: Number(newUpperBounds), ...other });
  };

  const addIngredient = (ingredient: string) => {
    updateConstraint({
      ...templateConstraint,
      coefficients: [
        //    ...(templateConstraint.coefficients ?? []), // Uncomment this to allow multiple ingredients
        {
          name: ingredient,
          value: 1,
        },
      ],
    });
  };

  const removeIngredient = (ingredient: string) => {
    updateConstraint({
      ...templateConstraint,
      coefficients: templateConstraint.coefficients?.filter(
        val => val.name !== ingredient
      ),
    });
  };

  const addValues = (
    <div css={addValueStyle}>
      <h3>Set range</h3>
      <div css={addValueBody}>
        <div>
          <p>Lower value</p>
          <FormulationInput
            min={0}
            setValue={updateLowerBounds}
            onChange={updateLowerBounds}
            value={templateConstraint.lowerBounds as number}
            type={InputType.PERCENT}
            css={css`
              width: 50%;
            `}
          />
        </div>
        <div>
          <p>Higher value</p>
          <FormulationInput
            setValue={updateUppperBounds}
            onChange={updateUppperBounds}
            value={templateConstraint.upperBounds as number}
            type={InputType.PERCENT}
            min={
              templateConstraint.lowerBounds
                ? templateConstraint.lowerBounds
                : 0
            }
            css={css`
              width: 50%;
            `}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <DesignConstraintInfo
        title="What is a Range constraint?"
        description="Set an input to a specific range."
        type={ConstraintType.RANGE}
      />
      <div css={constraintIngredientSearch}>
        <h3>
          Add Input{' '}
          {categories.length > 1 && (
            <IngredientCategorySearch
              onChange={selectedCategories =>
                setFilteredCategories(selectedCategories)
              }
              categories={categories}
            />
          )}
        </h3>
        <IngredientSearch
          onSelect={addIngredient}
          ingredients={searchableIngredients}
        />
      </div>
      {templateConstraint?.coefficients?.map((constraint, i) => {
        //This could be simplified by holding the ingredient bounds with the constraint
        const ingredient = ingredientByName.get(constraint.name);
        const boundsNotification = ingredient && (
          <Bounds ingredient={ingredient} />
        );

        return (
          <div
            key={i}
            css={[designConstraintIngredient, amountConstraintIngredient]}
          >
            <div css={[designConstraintIngInfo]}>
              <div
                css={css`
                  width: 50%;
                `}
              >
                <EllipsisMiddle
                  style={{ fontWeight: 500, marginBottom: '1px' }}
                  suffixCount={20}
                >
                  {constraint.name}
                </EllipsisMiddle>
                <div css={amountConstraintIngName}>{boundsNotification}</div>
              </div>
            </div>
            <div css={constraintCloseButton}>
              <CloseOutlined
                onClick={() => {
                  removeIngredient(constraint.name);
                }}
                style={{ color: `${Colors.SILVER}` }}
              />
            </div>
          </div>
        );
      })}

      {templateConstraint.coefficients?.length !== 0 && addValues}
    </div>
  );
};
