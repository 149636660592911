/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { Route, Routes, useParams, matchPath } from 'react-router-dom';

import { Spin } from 'antd';

import { useSession } from '../../../_shared/context/session-context';
import {
  centeredHorizontallyFlexStyle,
  centerVerticallyFlexStyle,
} from '../../../_shared/style/positioning.styles';
import { WorkspacePanelComponent } from './workspace-panel.component';
import {
  WorkspaceContextProvider,
  useWorkspace,
} from '../../../_shared/context/workspace-context';
import {
  logEvent,
  TrackableEvent,
} from '../../../_shared/tracking/usage-tracker';
import { GraphContextProvider } from '../../../_shared/context';
import { DesignContextProvider } from '../../../_shared/context/design-context';
import { ScenarioDetailContextProvider } from '../../../_shared/context/scenario-detail-context';
import { AdaptiveLearningPanel } from '../adaptive-learning/adaptive-learning-panel.component';
import { OptimizationContextProvider } from './optimization/v2/context';
import {
  usefindIterationWithLatestSimulationQuery,
  WorkspaceType,
} from '../../../../../__generated__/globalTypes';
import { WithDesignContext } from '../../../_shared/context/context-helpers';

interface LabBenchRouteParams {
  projectId: string;
  iterationId: string;
}

const WorkspaceTypeComponent = () => {
  const { user } = useSession();
  const { iteration } = useWorkspace();
  const path = '/project/:projectId/iteration/:iterationId';
  if (iteration?.type === 'ADAPTIVE_LEARNING') {
    return (
      <Routes>
        {['', 'design', 'design/:designId'].map(path => (
          <Route
            key={`project-design-${path}`}
            path={path}
            element={
              <WithDesignContext>
                <AdaptiveLearningPanel />
              </WithDesignContext>
            }
          />
        ))}
      </Routes>
    );
  }
  if (iteration?.type === WorkspaceType.LAB_BENCH) {
    return (
      <ScenarioDetailContextProvider iteration={iteration}>
        <OptimizationContextProvider iteration={iteration}>
          <WorkspacePanelComponent />
        </OptimizationContextProvider>
      </ScenarioDetailContextProvider>
    );
  }
};

export const WorkspaceLayout = () => {
  const { currentProject, user, setSelectedIterationId } = useSession();
  const { iterationId } = useParams<LabBenchRouteParams>();

  useEffect(() => {
    if (iterationId) {
      logEvent(TrackableEvent.WORKSPACE_OPENED, {
        projectId: currentProject?.id,
      });
      setSelectedIterationId(iterationId);
    }
  }, [iterationId]);

  const { data, loading } = usefindIterationWithLatestSimulationQuery({
    variables: {
      id: iterationId,
    },
  });

  if (!data || loading)
    return (
      <Spin
        css={css`
          ${centerVerticallyFlexStyle};
          ${centeredHorizontallyFlexStyle};
          height: 100%;
        `}
      />
    );

  //TODO: Create Error Screen
  if (!data.iteration) return <div className="no-iteration" />;

  //TODO: Wouldn't happen but for type safety make error page when there isn't a user obj
  if (!user) return <div className="no-user" />;

  return (
    <div>
      <GraphContextProvider>
        <WorkspaceContextProvider
          iteration={data.iteration}
          modelId={currentProject?.activeModel?.id}
        >
          <WorkspaceTypeComponent />
        </WorkspaceContextProvider>
      </GraphContextProvider>
    </div>
  );
};
