import { css } from '@emotion/react';
import { Colors } from '../../_shared/style';

export const dashboardPanelStyle = css`
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: 15px 24px;
  vertical-align: top;
  flex-grow: 1;

  &.side-by-side {
    width: calc(50% - 36px);
    display: inline-block;
    margin: 15px 0 15px 24px;
  }
`;

export const dashboardPanelHeaderStyle = css`
  background: ${Colors.WHISPER} !important;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${Colors.WHISPER};
  border-bottom: 1px solid ${Colors.ZICRON};
  border-radius: 6px 6px 0 0;

  &.row-header {
    flex-direction: row;
    justify-content: space-between;
    padding-top: 14px;
  }
`;

export const dashboardPanelContentStyle = css`
  background-color: ${Colors.WHITE};
  border-radius: 0 0 6px 6px;
`;
