/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';

import { Divider } from 'antd';
import { VariableType } from '@prisma/client';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { findIterationWithHist_iteration_simulations } from '@root/_shared/hooks/__generated__/findIterationWithHist';
import { projectById_project_ingredientList } from '@root/_shared/hooks/__generated__/projectById';
import { useMemo } from 'react';
import {
  CategoryDot,
  WorkspaceProjectColors,
} from '../../../../../_shared/style';
import { AccuracyIconHoriz } from '../../../../../_shared/components/icon';
import {
  HistoryChange,
  OutcomeInfo,
  SimulationProductOutcome,
  SimulationProductVersionResultType,
  SingleProductOutcome,
  useWorkspace,
} from '../../context';
import { useIngredients } from '../../../../../_shared/hooks';

import {
  parseNumber,
  getDisplayDifference,
} from '../../../../../_shared/utils/util';
import { NumericOutcomeResult, OrdinalOutcomeResultFull } from '../shared';
import { AppliedChangesDetails } from './change-details/applied-changes-details.component';
import { DisplayInfo } from './types';
import camelCase from 'lodash/camelCase';

export const SimulationImpactChange = ({
  currentChange,
  benchmark,
  currentSim,
  allChanges,
}: {
  currentChange: HistoryChange;
  benchmark?: SimulationProductVersionResultType;
  currentSim?: findIterationWithHist_iteration_simulations;
  allChanges: HistoryChange[];
}) => {
  const {
    productColors,
    outcomes,
    selectedSimulationProductVersion: currentProduct,
  } = useWorkspace();
  const { ingredientById } = useIngredients();
  const impactChanges = getImpactOutcomes(currentChange.outcomes);

  const productColor = productColors[currentProduct?.columnNumber ?? 0];
  const formulation =
    currentChange.productVersion?.productVersion.formulation?.quantities;

  /**
   * The baseline for which the comparison is against
   * It is either the previous change, or the benchmark if no
   * previous change exists
   */
  //TODO: Fix this typing, HistoryChange has productVersion.productVersion
  const baseLine: HistoryChange & {
    formulation?: { [key: string]: string };
  } = useMemo(() => {
    let baseChange: HistoryChange & { formulation?: { [key: string]: string } };
    const currChangeIndex = allChanges.findIndex(
      c => currentChange.changeName === c.changeName
    );
    let previousChange =
      currChangeIndex > 0 ? allChanges[currChangeIndex - 1] : undefined;
    if (previousChange) {
      baseChange = {
        ...previousChange,
        formulation:
          previousChange.productVersion?.productVersion.formulation?.quantities,
      } as HistoryChange & { formulation?: { [key: string]: string } };
    } else {
      baseChange = {
        changeName: 'Benchmark',
        outcomes: benchmark?.outcomes,
        productVersion: benchmark?.productVersion,
        formulation: benchmark?.productVersion.formulation?.quantities,
      } as HistoryChange & { formulation?: { [key: string]: string } };
    }
    return baseChange;
  }, [allChanges, benchmark, currentChange]);

  const allIngredients: DisplayInfo[] = getChangesFromFormulation({
    formulation,
    baselineFormulation: baseLine.formulation,
    ingredientById,
  });
  const ingredientChanges = allIngredients.filter(i => i.difference !== '0');

  const getOutcomeView = (iC: SingleProductOutcome, idx: number) => {
    const { outcomeType } = iC;
    let view;
    const circleDimension = '1.5em';

    switch (outcomeType) {
      case VariableType.NUMERIC: {
        view = currentChange.productVersion && (
          <NumericOutcomeResult
            outcome={iC}
            sim={currentChange.productVersion}
            benchmark={benchmark}
            barColor={
              currentChange.outcomes.length === 1 ||
              (outcomes?.length && outcomes?.length === 1)
                ? productColor
                : WorkspaceProjectColors.products[idx]
            }
          />
        );
        break;
      }
      case VariableType.ORDINAL: {
        view = currentChange.productVersion && (
          <div
            css={css`
              display: inherit;
              width: 100%;
            `}
          >
            <OrdinalOutcomeResultFull
              outcome={iC}
              sim={currentChange.productVersion}
              color={
                currentChange.outcomes.length === 1 ||
                (outcomes?.length && outcomes?.length === 1)
                  ? productColor
                  : WorkspaceProjectColors.products[idx]
              }
            />
          </div>
        );
        break;
      }
      case VariableType.CATEGORICAL: {
        view = currentChange.productVersion && (
          <div
            css={css`
              display: inherit;
              width: 100%;
            `}
          >
            <OrdinalOutcomeResultFull
              outcome={iC}
              sim={currentChange.productVersion}
              color={
                currentChange.outcomes.length === 1 ||
                (outcomes?.length && outcomes?.length === 1)
                  ? productColor
                  : WorkspaceProjectColors.products[idx]
              }
            />
          </div>
        );
        break;
      }
      default:
        view = <div />;
        break;
    }

    return (
      <div
        css={css`
          display: flex;
          font-size: ${outcomeType === VariableType.ORDINAL
            ? 'larger'
            : 'inherit'};
        `}
        key={camelCase(iC.targetVariable + 'DispDiffImpact')}
      >
        <div
          css={css`
            svg {
              height: ${circleDimension};
              width: ${circleDimension};
            }
          `}
        >
          <CategoryDot
            color={
              impactChanges.length > 1
                ? WorkspaceProjectColors.products[idx]
                : productColor
            }
          />
        </div>
        {view}
      </div>
    );
  };

  const changeDataStr = moment(currentSim?.createdAt).format(
    '[Changes made at] HH:mm [on] DD MMMM YYYY'
  );

  return (
    <div
      css={css`
        width: 100%;
        border-top: 2px solid #000000;
      `}
    >
      <div
        css={css`
          margin: 25px 95px 25px 43px;
          display: flex;
        `}
      >
        <CalendarOutlined
          css={css`
            color: #b2b2b5;
            font-weight: bold;
            margin: auto 5px auto 0px;
          `}
        />
        {changeDataStr}
      </div>
      <div
        css={css`
          margin: 25px 95px 25px 43px;
          display: flex;
          overflow-x: auto;
        `}
      >
        {impactChanges &&
          impactChanges.map((ic, idx) => {
            return (
              <div
                css={css`
                  display: block;
                  width: 25%;
                  border-right: 2px dotted rgba(0, 0, 0, 0.2);
                  padding: 0px 25px;
                `}
                key={ic.targetVariable + 'SimImpactChange'}
              >
                {getOutcomeView(ic, idx)}
                {(ic.outcomeInfo.reliability || ic.outcomeInfo.accuracy) && (
                  <div
                    css={css`
                      background: rgba(0, 0, 0, 0.04);
                      margin-top: 25px;
                      margin-right: 25px;
                      border-radius: 9px;
                      display: block;
                      inline-size: fit-content;
                      flex-direction: row;
                      width: 100%;
                      padding: 10px;
                    `}
                    key={camelCase(ic.targetVariable + 'AccuracyImpact')}
                  >
                    <div
                      css={css`
                        width: 100%;
                        display: flex;

                        svg {
                          height: 1.5em;
                          width: 5em;
                        }
                      `}
                    >
                      Confidence: &nbsp;
                      <AccuracyIconHoriz
                        targetVariable={ic.targetVariable}
                        reliability={ic.outcomeInfo.reliability}
                        applicability={ic.outcomeInfo.accuracy}
                      />
                    </div>
                    <Divider
                      css={css`
                        margin: 5px;
                      `}
                    />
                    <div
                      css={css`
                        font-size: smaller;
                      `}
                    >
                      <div>
                        Model applicability:{' '}
                        <div
                          css={css`
                            float: right;
                          `}
                        >
                          {ic.outcomeInfo.accuracy &&
                            parseNumber(ic.outcomeInfo.accuracy * 100)?.toFixed(
                              0
                            ) + '%'}
                        </div>
                      </div>
                      <div>
                        Simulation reliability:{' '}
                        <div
                          css={css`
                            float: right;
                          `}
                        >
                          {' '}
                          {ic.outcomeInfo.reliability &&
                            parseNumber(
                              ic.outcomeInfo.reliability * 100
                            )?.toFixed(0) + '%'}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {!!ingredientChanges.length && baseLine && (
        <AppliedChangesDetails
          ingredientChanges={ingredientChanges}
          currentChange={currentChange}
          baselineName={baseLine.changeName}
        />
      )}
    </div>
  );
};

const getImpactOutcomes = (
  outcomes: SimulationProductOutcome[]
): SingleProductOutcome[] => {
  let icv: SingleProductOutcome[] = [];

  if (outcomes) {
    icv = outcomes.map(o => {
      let value;
      if (o.value) {
        value = parseNumber(o.value)?.toFixed(2) ?? o.value;
      }

      const dist: Map<string, number> = new Map();
      if (o.distribution)
        [...o.distribution]
          .sort((c, b) => b.probability - c.probability)
          .forEach(d => {
            dist.set(d.state, d.probability);
          });

      const outcomeInfo = {
        value,
        reliability: o.reliability,
        accuracy: o.applicability,
        distribution: dist,
      } as OutcomeInfo;

      const returnVal = {
        targetVariable: o.outcomeName,
        outcomeType: o.outcomeType,
        outcomeInfo,
      } as SingleProductOutcome;

      return returnVal;
    });
  }
  return icv;
};

const getChangesFromFormulation = ({
  formulation,
  baselineFormulation,
  ingredientById,
}: {
  formulation?: { [key: string]: string };
  baselineFormulation?: { [key: string]: string };
  ingredientById: Map<string, projectById_project_ingredientList>;
}) => {
  const result: DisplayInfo[] = [];
  if (formulation && baselineFormulation)
    Object.keys(formulation).forEach(id => {
      const ing = ingredientById.get(id);
      const symbol = !ing?.isTestCondition ? '%' : '';
      const value = formulation[id];
      const benchmarkValue = baselineFormulation?.[id] ?? '0';
      let difference = getDisplayDifference(value, benchmarkValue);
      if (difference === undefined) difference = '0';

      if (ing) {
        result.push({
          ingredientName: ing.ingredient.name,
          value,
          benchmarkValue:
            benchmarkValue === undefined && !ing?.isTestCondition
              ? '0'
              : benchmarkValue,
          difference,
          symbol,
        });
      }
    });
  return result;
};
