/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Button } from 'antd';
import { EnterOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { SidebarView } from '../../pages/exploration/exploration-page';

export const SidebarMenuHeader = ({
  title,
  handleOnClick,
}: {
  title: String;
  handleOnClick(): void;
}) => {
  return (
    <div
      css={css`
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid var(--Neutrals-50, #e9ebec);
      `}
    >
      <div
        css={css`
          margin-bottom: 15px;
        `}
      >
        <Button
          style={{ paddingLeft: 0 }}
          icon={<EnterOutlined />}
          onClick={() => handleOnClick()}
          type="link"
        >
          Back
        </Button>
      </div>
      <div
        css={css`
          color: var(--Neutrals-Text, #161f26);
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 20px;
        `}
      >
        {title}
      </div>
    </div>
  );
};
