/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import { Carousel, Divider, Button, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';

import React from 'react';
import { workspaceCarouselStyle } from '../styles/workspace-panel.styles';
import { useWorkspace } from '../../../../_shared/context/workspace-context';
import { useSession } from '../../../../_shared/context';
import { PredictedPerformanceBarChartContainer } from './charts/predicted-bar-chart-container.component';
import { SimulationResultTable } from './simulation-result-table';
import { BellIcon, LeftArrow, RightArrow } from '../../../../_shared/style';
import {
  ProjectFeature,
  VariableType,
} from '../../../../../../__generated__/globalTypes';
import Link from 'antd/lib/typography/Link';
import { JobStatus } from '@prisma/client';
import { OptimizationRunningMessage } from '../optimization/v2/optimization-running-message.component';
import { useNavigate } from 'react-router-dom';

export const PredictedCarousel = ({
  onRunSimulation,
}: {
  /**
   * onRunSimulation Doesn't have to be passed in, it can exist wherever
   * the button is but for now keeping it in a central location and passing it around
   */
  onRunSimulation: () => void;
}) => {
  const {
    outcomes,
    iteration,
    simulation,
    simulationProductVersions,
    selectedSimulationProductVersion: currentProduct,
    latestOptimization,
    isWorkspaceOwner,
  } = useWorkspace();
  const { user, currentProject, isJarModel } = useSession();
  const navigate = useNavigate();

  const canRunSimulation = currentProject?.features.some(
    f => f.feature === ProjectFeature.SIMULATION
  );

  const benchmarkProduct = simulationProductVersions?.find(
    s => s.productVersion.isBenchmark
  );

  const carouselRef = React.useRef<CarouselRef>(null);
  const carouselState = {
    pageCount: 3, // TODO: A way to make this dynamic?
    currentPage: 0,
    isPrevDisabled: () => carouselState.currentPage === 0,
    isNextDisabled: () =>
      carouselState.currentPage === carouselState.pageCount - 1,
    prev: () => {
      carouselRef.current?.prev();
    },
    next: () => {
      carouselRef.current?.next();
    },
    setCurrentPage: (page: number) => {
      carouselState.currentPage = page;
    },
  };

  const isOptimizationInProgress =
    latestOptimization?.projectJob?.status === JobStatus.IN_PROGRESS ||
    latestOptimization?.projectJob?.status === JobStatus.PENDING;
  const RunSimulationBtn = () => {
    const btn = (
      <Button
        onMouseUp={onRunSimulation}
        disabled={!isWorkspaceOwner || !canRunSimulation}
        css={css`
          border-radius: 99px;
          background: #f0f0f1;
          font-weight: 500;
          font-size: 13px;
        `}
      >
        Run Simulation & Save
        <ArrowRightOutlined />
      </Button>
    );
    return isWorkspaceOwner ? (
      btn
    ) : (
      <Tooltip
        title="Only the owner of the workspace can run simulations"
        placement="topLeft"
      >
        <div>{btn}</div>
      </Tooltip>
    );
  };

  const getStatusDescription = () => {
    let description = 'A simulation has not been run yet.';

    if (currentProduct) {
      const { outcomes: currentOutcomes } = currentProduct;
      if (currentOutcomes?.length === 0)
        description =
          'Update ingredients and run a simulation to see the predicted results on your outcome here.';
    }

    return description;
  };

  if (
    (!isJarModel && currentProduct?.outcomes?.length === 0) ||
    (isJarModel && !currentProduct?.productVersion)
  ) {
    return (
      <span css={workspaceCarouselStyle}>
        <PredictedOutcomeHeader
          alertOptimization={
            latestOptimization?.projectJob?.status === JobStatus.SUCCESS
          }
          onClick={() =>
            navigate(
              `/project/${currentProject?.id}/iteration/${iteration?.id}/optimization`
            )
          }
          isOptimizationInProgress={isOptimizationInProgress}
        />
        <Divider />
        <p>{getStatusDescription()}</p>
        <RunSimulationBtn />
      </span>
    );
  }

  const titleDivider = (
    <div>
      <Divider>
        <RunSimulationBtn />
      </Divider>
    </div>
  );

  return (
    <span css={workspaceCarouselStyle}>
      <Carousel
        infinite={false}
        ref={carouselRef}
        beforeChange={(current, next) => {
          carouselState.setCurrentPage(next);
        }}
        appendDots={dots => (
          <div>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                backgroundColor: '#FFFFFF',
                boxShadow:
                  '0px 1px 1px #DFDFE4, 0px 0px 4px rgba(3, 25, 30, 0.05), 0px 1px 8px rgba(3, 25, 30, 0.03)',
                borderRadius: '6px',
                padding: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 5px 0 10px',
                  cursor: carouselState.isPrevDisabled() ? 'auto' : 'pointer',
                }}
                role="presentation"
                onClick={carouselState.prev}
              >
                {LeftArrow(carouselState.isPrevDisabled())}
              </div>
              <ul
                style={{
                  padding: '12px 5px 0px 5px',
                  margin: '0px',
                }}
              >
                {dots}
              </ul>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 10px 0 5px',
                  cursor: carouselState.isNextDisabled() ? 'auto' : 'pointer',
                }}
                role="presentation"
                onClick={carouselState.next}
              >
                {RightArrow(carouselState.isNextDisabled())}
              </div>
            </div>
          </div>
        )}
      >
        <div>
          {/** TODO: WHAT ABOUT ERRORS? */}
          <PredictedOutcomeHeader
            alertOptimization={
              latestOptimization?.projectJob?.status === JobStatus.SUCCESS
            }
            onClick={() => {
              navigate(
                `/project/${currentProject?.id}/iteration/${iteration?.id}/optimization`
              );
            }}
            isOptimizationInProgress={isOptimizationInProgress}
          />
          {titleDivider}
          <div>
            Comparision between your edited formulation and chosen benchmark.
          </div>
          {outcomes && currentProduct && (
            <SimulationResultTable
              outcomes={outcomes}
              benchmark={benchmarkProduct}
              product={currentProduct}
            />
          )}
        </div>
        <div
          css={css`
            height: calc(100vh - 218px);
          `}
        >
          <div
            css={css`
              font-size: 20px;
              font-weight: normal;
              padding-bottom: 10px;
              text-align: left;
            `}
          >
            Predicted outcomes comparison
          </div>
          {titleDivider}
          <PredictedPerformanceBarChartContainer />
        </div>
      </Carousel>
    </span>
  );
};

const PredictedOutcomeHeader = ({
  alertOptimization,
  onClick,
  isOptimizationInProgress,
}: {
  alertOptimization: boolean;
  onClick: () => void;
  isOptimizationInProgress: boolean;
}) => {
  const { usingLegacyApi } = useWorkspace();

  return (
    <div>
      {isOptimizationInProgress && !usingLegacyApi && (
        <React.Fragment>
          <Divider />
          <OptimizationRunningMessage
            spinnerSize="small"
            showEmailNotification
          />
          <Divider />
        </React.Fragment>
      )}

      {alertOptimization && !usingLegacyApi && (
        <React.Fragment>
          <Divider />
          <div>
            <span
              css={css`
                vertical-align: middle;
                display: inline-block;
              `}
            >
              <BellIcon />{' '}
            </span>
            <span
              css={css`
                vertical-align: middle;
                display: inline-block;
                margin-left: 5px;
              `}
            >
              Good news! Your optimizations are ready.{' '}
              <Link onClick={onClick}>View them here.</Link>
            </span>
          </div>
          <Divider />
        </React.Fragment>
      )}
      <div
        css={css`
          font-size: 20px;
          font-weight: normal;
          padding-bottom: 10px;
          text-align: left;
        `}
      >
        Predicted outcomes
      </div>
    </div>
  );
};
