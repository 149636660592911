/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { useIngredients } from '../../_shared/hooks';
import { InputNumber, Table, notification } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useSession } from '../../_shared/context';
import {
  IngredientInput,
  useupdateIngredientListCompositionsMutation,
} from '../../../../__generated__/globalTypes';
import { IngredientComposition } from './ingredient-composition-modal.component';
import { EllipsisMiddle } from '../../_shared/utils/component';
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export interface TableDataType {
  key: string;
  ingredientName: string;
  ingredientId: string | number;
  [key: string]: string | number;
}

export const IngredientCompositionTable = ({
  ingredientCompositions,
  tableData,
  setTableData,
  ingredientsToUpdate,
  setIngredientsToUpdate,
}: {
  tableData: TableDataType[];
  setTableData: (data: TableDataType[]) => void;
  ingredientCompositions: IngredientComposition[];
  ingredientsToUpdate: IngredientInput[];
  setIngredientsToUpdate: (data: IngredientInput[]) => void;
}) => {
  const handleIngredientCompositionUpdate = async (
    value: number,
    tableValue: number,
    record: TableDataType,
    index: number,
    compositionName: string
  ) => {
    const newData = cloneDeep(tableData);
    let row = newData?.find(td => td.ingredientName === record.key);
    if (row && (value !== null || value !== undefined)) {
      row[compositionName] = value;
      let existingIngredientUpdateIndex = ingredientsToUpdate.findIndex(
        i => i.ingredientId === row?.ingredientId
      );

      const updatedIngredient = {
        ingredientId: Number(row.ingredientId),
        compositions: ingredientCompositions.map(ic => ({
          compositionId: String(ic.id),
          name: ic.name,
          value: Number(row?.[ic.name]),
        })),
      };

      if (
        existingIngredientUpdateIndex !== null &&
        existingIngredientUpdateIndex !== -1
      ) {
        ingredientsToUpdate[existingIngredientUpdateIndex] = updatedIngredient;
      } else {
        ingredientsToUpdate.push(updatedIngredient);
      }
      setTableData(newData);
      setIngredientsToUpdate(ingredientsToUpdate);
    }
  };
  const columns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: 'Ingredient',
      dataIndex: 'ingredientName',
      key: 'ingredientName',
      ellipsis: true,
      width: 200,
      render: (txt, record) => (
        <EllipsisMiddle suffixCount={12}>{txt}</EllipsisMiddle>
      ),
    },
  ];
  ingredientCompositions.forEach(ic => {
    columns.push({
      title: `${ic.name} (%)`,
      dataIndex: ic.name,
      key: ic.name,
      editable: true,
      render: (value, record: TableDataType, index) => {
        const currentValue =
          tableData?.find(td => td.ingredientName === record.key)?.[ic.name] ??
          0;

        return (
          <InputNumber
            defaultValue={currentValue}
            value={currentValue}
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value?.replace('%', '') || ''}
            onChange={e =>
              handleIngredientCompositionUpdate(
                Number(e),
                value,
                record,
                index,
                ic.name
              )
            }
          />
        );
      },
    });
  });
  return (
    <Table
      rowClassName={() => 'editable-row'}
      dataSource={tableData}
      columns={columns}
      pagination={{ pageSize: 5 }}
      scroll={{ x: 'max-content' }}
    />
  );
};
