/** @jsxImportSource @emotion/react */

import { Fragment, useState } from 'react';

import { Badge, Button, Modal } from 'antd';

import { AddNoteButton } from '../../../_shared/components';
import {
  NodeCommentList,
  EdgeCommentList,
  EdgeComment,
} from '../../comment/comment-list.component';

export const ViewNotes = ({
  nodeId,
  edgeComments,
}: {
  nodeId: string;
  edgeComments: EdgeComment[];
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <Badge count={edgeComments.length ? 1 : 0} dot offset={[-2, 6]}>
        <AddNoteButton onClick={() => setVisible(true)} />
      </Badge>
      {visible && (
        <ViewNotesModal
          edgeComments={edgeComments}
          onClose={() => setVisible(false)}
        />
      )}
    </Fragment>
  );
};

export const ViewNotesModal = ({
  edgeComments,
  onClose,
}: {
  edgeComments: EdgeComment[];
  onClose: () => void;
}) => {
  return (
    <Modal
      className="view-note"
      title="View Notes"
      open
      okText="Save"
      footer={<Button onClick={onClose}>Close</Button>}
    >
      {/* {!(edgeComments.length + nodeComments.length) && (
        <span>No notes yet.</span>
      )}
      {!!nodeComments.length && <NodeCommentList comments={nodeComments} />}
      {!!edgeComments.length && <EdgeCommentList comments={edgeComments} />} */}
    </Modal>
  );
};
