import { MlApiJobStatus } from 'src/types/mlapi.types';
import {
  Objective,
  ConstraintInputType,
  findDesignsByIterationQuery,
  ObjectiveType,
  CostOptimizationOption,
  saveProjectConstraintsMutation,
} from '../../../../../__generated__/globalTypes';
import { FetchResult } from '@apollo/client';
import { MutableRefObject } from 'react';
import { IterationWithLatestSimulation } from '../lab-bench/context';
import { DesignJobRequest } from 'src/server/simulation/model-runner/types';
import { DesignJobResponse } from 'src/types/shared.types';

export type OutOfBoundAlert = {
  ingredientName: string;
  message: string;
};

export enum Priorities {
  HIGH_PRIORITY = 'High Priority',
  MEDIUM_PRIORITY = 'Medium Priority',
  LOW_PRIORITY = 'Low Priority',
  NOT_PRIORITIZED = 'Not Prioritized',
}

export type PriorityLabel =
  | Priorities.HIGH_PRIORITY
  | Priorities.MEDIUM_PRIORITY
  | Priorities.LOW_PRIORITY
  | Priorities.NOT_PRIORITIZED;

export enum DesignStates {
  FINISHED = 'FINISHED',
  RUNNING = 'RUNNING',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
  EDITING = 'EDITING',
  NO_RESULTS = 'NO_RESULTS',
  INITIAL = 'INITIAL',
}

export interface DesignRouteParams {
  projectId: string;
  iterationId: string;
  designId: string;
}

export type DesignExperimentDetailRouteParams = DesignRouteParams & {
  experimentId: string;
};

export type DesignContextProviderProps = {
  children?: React.ReactNode;
  iteration?: IterationWithLatestSimulation;
};

export type PolledDesignsType = NonNullable<
  NonNullable<findDesignsByIterationQuery['iteration']>['latestDesign']
>;
export type PolledDesignObjective = Omit<
  NonNullable<PolledDesignsType>['objectives'][0],
  '__typename'
>;

/**
 * Sub-type/slimmed down version of productVersion record
 */
export type DesignProductRecordType = NonNullable<PolledDesignsType>['product'];
export interface DesignContextProps {
  objectivesByTarget: Map<string, Objective>;
  setObjectivesByTarget: (objectives: Map<string, Objective>) => void;
  updateObjective: (objective: Objective) => void;
  changePriority: (input: {
    targetVariable: string;
    action: 'INCREASE' | 'DECREASE';
  }) => void;
  runDesignJob: () => void;
  latestDesign: PolledDesignsType | undefined;
  previousDesigns: NonNullable<
    NonNullable<findDesignsByIterationQuery['iteration']>['designs']
  >;
  designState: DesignStates;
  designResults: DesignResultType[];
  // initiateDesignJob: () => void;
  // constraints: Map<string, NullableConstraintType>;
  constraints: ConstraintInputType[];
  setConstraints: (constraints: ConstraintInputType[]) => void;
  removeConstraint: (c: ConstraintInputType) => void;
  editConstraint: (c: ConstraintInputType) => void;
  fillerIngredient?: string;
  setFillerIngredient: (fillerIngredient: string | undefined) => void;
  showConstraintModal: boolean;
  setShowConstraintModal: (showConstraintModal: boolean) => void;
  constraint: ConstraintInputType | undefined;
  setConstraint: (constraint: ConstraintInputType | undefined) => void;
  isExistingConstraint: boolean;
  setConstraintIndex: (constraintIndex: number | undefined) => void;
  constraintIndex: number | undefined;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  objectivesAreInEditMode: boolean;
  setObjectivesAreInEditMode: (o: boolean) => void;
  hasSuccessfulDesign: boolean;
  cancelRunningDesign: () => void;
  runDesignIsLoading: boolean;
  maxNumberOfResults: number;
  setMaxNumberOfResults: (maxNumberOfResults: number) => void;
  openDrawer: boolean;
  setOpenDrawer: (showDrawer: boolean) => void;
  costOptimizationOption: CostOptimizationOption;
  setCostOptimizationOption: (option: CostOptimizationOption) => void;
  enforceNteCost: boolean;
  setEnforceNteCost: (enforce: boolean) => void;
  nteCost: number;
  setNteCost: (cost: number) => void;
  minCostScore: number;
  maxCostScore: number;
  saveProjectConstraints: () => Promise<
    FetchResult<saveProjectConstraintsMutation>
  >;
  resultTableColumnRef: MutableRefObject<null | Map<string, HTMLElement>>;
  graphPointRef: MutableRefObject<null | Map<string, SVGSVGElement>>;
  enforceStrictly: boolean;
  setEnforceStrictly: (enforce: boolean) => void;
  setDisableGoalScenario: (disable: boolean) => void;
  disableGoalScenario: boolean;
}

export type DesignResultType = DesignJobResponse['results']['conditions'][0] & {
  productName: string;
  isBenchmark: boolean;
  isGenerated: boolean;
  /**
   * The singular score unit used to rank formulations
   * total_desirability minus the penalty
   *
   */
  turingScore: number;
  formulation: {
    totalCostScore?: number | string;
    ingredientCompositionTotals?: {
      name: string;
      compositionId: string;
      total: number;
    }[];
  };
  outcomes:
    | {
        outcomeName: string;
        value: string;
        applicability: number;
        reliability: number;
      }[]
    | undefined;
};
