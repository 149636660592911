/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css, jsx } from '@emotion/react';
import { ObjectiveType, VariableType } from '@prisma/client';
import {
  parseNumber,
  hasValue,
  isBetween,
} from '../../../../_shared/utils/util';
import { Radio, Row, Col, RadioChangeEvent, Select } from 'antd';
import noop from 'lodash/noop';
import {
  InputMaybe,
  Maybe,
  Objective,
} from '../../../../../../__generated__/globalTypes';
import {
  EditableInput,
  InputType,
} from '../../../../_shared/components/input/editable-input.component';
import { ErrorHeaderMark } from '../../../../_shared/style';
import { useSession } from '../../../../_shared/context';
import { objectiveInputsContainerStyle } from '../../adaptive-learning/design-goals/styles';

export type OutcomeRadioOptionProps = {
  projectHasOptimizeOnTargetValue: boolean;
  objective: Objective;
  handleRadioSelect: (e: RadioChangeEvent) => void;
  handleRangeValue: (isLower: boolean, value: string) => void;
  handleTargetValue: (value: string) => void;
};

const optimizationTypeLabel = (optType: ObjectiveType): string => {
  switch (optType) {
    case ObjectiveType.MINIMIZE:
      return 'Minimize';
    case ObjectiveType.MAXIMIZE:
      return 'Maximize';
    case ObjectiveType.TARGET_VALUE:
      return 'Target';
    case ObjectiveType.IN_RANGE:
      return 'Range';
    default:
      return '';
  }
};

/*
  Convert string to a number if possible, otherwise return the original input and assume it will become a number
  Handles cases such as typing . or -. before any numbers
*/
const parseStringToNumber = (
  value?: InputMaybe<string>
): string | number | undefined => {
  const valueToParse = value === null ? undefined : value;
  const parsedString = parseNumber(valueToParse);
  return parsedString === undefined ? valueToParse : parsedString;
};

export const OutcomeRadioOptions = ({
  projectHasOptimizeOnTargetValue,
  objective,
  handleRadioSelect,
  handleRangeValue,
  handleTargetValue,
}: OutcomeRadioOptionProps) => {
  const [boundsAreValid, setBoundsAreValid] = useState(true);
  const { currentProject } = useSession();
  const { objectiveType, targetVariable, value, lower, upper } = objective;

  const currentModel = currentProject?.activeModel;
  const outcome = currentModel?.outcomes.find(
    o => o.targetVariable === targetVariable
  );
  const outcomeUnit =
    outcome?.unit &&
    outcome?.unit !== 'Not Applicable' &&
    outcome?.unit !== 'null'
      ? outcome?.unit
      : '';
  const isNumeric = outcome?.type === VariableType.NUMERIC;
  const isCategorical = outcome?.type === VariableType.CATEGORICAL;

  let ordinalSelectOptions: Maybe<Maybe<string>[]> | undefined =
    outcome?.values;

  let goalsMetadata;

  const changeLower = (e: string | number) => {
    if (e && isNumeric) {
      // Validate bounds on typing, not on submit
      const boundsResult = isBetween(
        Number(e),
        Number(outcome?.lower),
        Number(outcome?.upper)
      );
      setBoundsAreValid(boundsResult);
    }

    handleRangeValue(true, String(e));
  };
  const changeUpper = (e: string | number) => {
    if (e && isNumeric) {
      const boundsResult = isBetween(
        Number(e),
        Number(outcome?.lower),
        Number(outcome?.upper)
      );
      setBoundsAreValid(boundsResult);
    }
    handleRangeValue(false, String(e));
  };

  const changeTarget = (e: string) => {
    if (
      hasValue(e) &&
      isNumeric &&
      hasValue(outcome?.lower) &&
      hasValue(outcome?.upper)
    ) {
      if (
        !isBetween(Number(e), Number(outcome?.lower), Number(outcome?.upper))
      ) {
        setBoundsAreValid(false);
      } else if (!boundsAreValid) {
        // Reset the error if the value is valid
        setBoundsAreValid(true);
      }
    }
    handleTargetValue(e);
  };

  const ErrorMessage = () => (
    <div
      css={css`
        font-weight: 700;
        color: #ea291f;
      `}
    >
      {`Enter a value between ${outcome?.lower} ${outcomeUnit} and ${outcome?.upper} ${outcomeUnit}`}
    </div>
  );

  if (
    projectHasOptimizeOnTargetValue &&
    objectiveType === ObjectiveType.TARGET_VALUE
  ) {
    goalsMetadata = (
      <div>
        {isNumeric ? (
          <div
            css={css`
              display: grid;
              grid-template-columns: min-content auto;
            `}
          >
            {!boundsAreValid && (
              <div
                css={css`
                  grid-column-start: 1;
                  grid-column-end: 1;
                  padding-right: 10px;
                `}
              >
                <ErrorHeaderMark />
              </div>
            )}
            <div
              css={css`
                grid-column-start: 2;
              `}
            >
              <div
                css={css`
                  color: #373737;
                  opacity: 0.6;
                `}
              >
                Target set to:
              </div>
              {!boundsAreValid && <ErrorMessage />}
              <EditableInput
                type={InputType.NUMERIC}
                onChange={changeTarget}
                setValue={noop}
                inputType={'number'}
                min={0}
                value={parseStringToNumber(value)}
                suffix={outcomeUnit}
              />
            </div>
          </div>
        ) : isCategorical ? (
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: 120 }}
            onChange={handleTargetValue}
            defaultValue={value}
          >
            {outcome?.values?.map(oso => {
              return <Select.Option value={oso}>{oso}</Select.Option>;
            })}
          </Select>
        ) : (
          <Select
            style={{ width: 120 }}
            onChange={handleTargetValue}
            defaultValue={value}
          >
            {ordinalSelectOptions?.map(oso => {
              return <Select.Option value={oso}>{oso}</Select.Option>;
            })}
          </Select>
        )}
      </div>
    );
  } else if (objectiveType === ObjectiveType.IN_RANGE) {
    goalsMetadata = (
      <div
        css={css`
          display: flex;
        `}
      >
        {!boundsAreValid && (
          <div
            css={css`
              margin-right: 10px;
            `}
          >
            <ErrorHeaderMark />
          </div>
        )}

        <div
          css={css`
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              color: #373737;
              opacity: 0.6;
            `}
          >
            Range set between:
          </div>
          {!boundsAreValid && <ErrorMessage />}
          {isNumeric ? (
            <div css={objectiveInputsContainerStyle}>
              <EditableInput
                type={InputType.NUMERIC}
                onChange={changeLower}
                setValue={noop}
                className="rangeInput"
                inputType={'number'}
                min={0}
                value={parseStringToNumber(lower)}
                currencyUnit={currentProject?.monetaryUnit}
                suffix={outcomeUnit}
                status={!boundsAreValid ? 'error' : ''}
              />
              <span
                css={css`
                  text-align: center;
                  margin: auto 10px;
                `}
              >
                to
              </span>
              <EditableInput
                type={InputType.NUMERIC}
                onChange={changeUpper}
                value={parseStringToNumber(upper)}
                setValue={noop}
                className="rangeInput"
                inputType={'number'}
                min={0}
                currencyUnit={currentProject?.monetaryUnit}
                suffix={outcomeUnit}
                status={!boundsAreValid ? 'error' : ''}
              />
            </div>
          ) : (
            <div css={objectiveInputsContainerStyle}>
              <Select
                style={{ width: 120 }}
                onChange={changeLower}
                defaultValue={lower}
              >
                {ordinalSelectOptions?.map(oso => {
                  return <Select.Option value={oso}>{oso}</Select.Option>;
                })}
              </Select>
              <span
                css={css`
                  text-align: center;
                  margin: auto 10px;
                `}
              >
                to
              </span>
              <Select
                style={{ width: 120 }}
                onChange={changeUpper}
                defaultValue={upper}
              >
                {ordinalSelectOptions?.map(oso => {
                  return <Select.Option value={oso}>{oso}</Select.Option>;
                })}
              </Select>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <Radio.Group
        style={{ width: '100%' }}
        onChange={handleRadioSelect}
        value={objectiveType}
        id={'OutcomeRadio'}
      >
        <Row gutter={[16, 16]}>
          {Object.keys(ObjectiveType)
            .filter(
              _type =>
                // Only show TARGET_VALUE if feature is enabled
                _type !== ObjectiveType.TARGET_VALUE ||
                projectHasOptimizeOnTargetValue
            )
            .filter(
              _type =>
                // Categorical types cannot use MINIMIZE or MAXIMIZE
                outcome?.type !== 'CATEGORICAL' ||
                (_type !== ObjectiveType.MAXIMIZE &&
                  _type !== ObjectiveType.MINIMIZE &&
                  _type !== ObjectiveType.IN_RANGE)
            )
            .map(_type => (
              <Col span={12} key={_type}>
                <Radio value={_type}>
                  {optimizationTypeLabel(_type as ObjectiveType)}
                </Radio>
              </Col>
            ))}
        </Row>
      </Radio.Group>

      {goalsMetadata && (
        <div
          css={css`
            background-color: #f2f2f2;
            padding: 12px;
            margin-top: 10px;
          `}
        >
          {goalsMetadata}
        </div>
      )}
    </div>
  );
};
