/** @jsxImportSource @emotion/react */

import { Layout, Steps } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { contentStyle } from '../project-layout.styles';
import { useSession } from '../../../_shared/context';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { BasicInfoForm } from './steps/basic-info-form.component';

export const ProjectSetup = () => {
  const { user, useGetProjectKeys } = useSession();
  const [getProjectKeys] = useGetProjectKeys();

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    getProjectKeys();
  }, [user]);

  return (
    <div
      css={css`
        display: flex;
        background-color: #f5f5fa;
        justify-content: center;
        padding-bottom: 20px;
        padding-left: 20%;
        padding-right: 20%;
        @media (min-width: 768px) and (max-width: 1199px) {
          padding-left: 10%;
          padding-right: 10%;
        }
        @media (max-width: 767px) {
          padding-left: 5%;
          padding-right: 5%;
        }
      `}
    >
      <BasicInfoForm />
    </div>
  );
};
