/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ConstraintInputType,
  ConstraintType,
} from '../../../../../../../__generated__/globalTypes';
import { DesignAmountConstraintView } from './design-amount-constraint-view.component';
import { DesignCountConstraintView } from './design-count-constraint-view.component';
import { DesignEqualityConstraintView } from './design-equality-constraint.component';
import { DesignRangeConstraintView } from './design-range-constraint-view.component';
import React from 'react';

export type ConstraintInputProp = {
  constraint: ConstraintInputType;
  removeConstraint: (c: ConstraintInputType) => void;
  editConstraint: (c: ConstraintInputType) => void;
  disabled: boolean;
};

export const DesignConstraintViewContainer = ({
  constraint,
  removeConstraint,
  editConstraint,
  disabled,
}: ConstraintInputProp) => {
  let ConstraintView = <div></div>;

  switch (constraint.constraintType) {
    case ConstraintType.COUNT:
      ConstraintView = (
        <DesignCountConstraintView
          constraint={constraint}
          removeConstraint={removeConstraint}
          editConstraint={editConstraint}
          disabled={disabled}
        />
      );
      break;
    case ConstraintType.RANGE:
      ConstraintView = (
        <DesignRangeConstraintView
          constraint={constraint}
          removeConstraint={removeConstraint}
          editConstraint={editConstraint}
          disabled={disabled}
        />
      );
      break;
    case ConstraintType.EQUALITY:
      ConstraintView = (
        <DesignEqualityConstraintView
          constraint={constraint}
          removeConstraint={removeConstraint}
          editConstraint={editConstraint}
          disabled={disabled}
        />
      );
      break;
    case ConstraintType.AMOUNT:
      ConstraintView = (
        <DesignAmountConstraintView
          constraint={constraint}
          removeConstraint={removeConstraint}
          editConstraint={editConstraint}
          disabled={disabled}
        />
      );
      break;
  }

  return <React.Fragment>{ConstraintView}</React.Fragment>;
};
