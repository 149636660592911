/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { HourglassSpinner } from '../../../../../_shared/style/svg-assets';
import { useWorkspace } from '../../context';
import { CancelOptimizationButton } from '../../../../../components/lab-bench/cancel-optimization-button';

export const OptimizationRunningMessage = ({
  showEmailNotification,
  spinnerSize,
}: {
  spinnerSize: 'large' | 'small';
  showEmailNotification: boolean;
}) => {
  const { latestOptimization } = useWorkspace();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        padding-top: 15px;
      `}
    >
      <div>
        <span
          css={css`
            vertical-align: middle;
            display: inline-block;
          `}
        >
          <HourglassSpinner size={spinnerSize} />
        </span>
        <span
          css={css`
            vertical-align: middle;
            display: inline-block;
            margin-left: 8px;
            width: 400px;
          `}
        >
          Our platform is checking more than a billion ingredient permutations,
          so this might take awhile. We'll let you know when it's ready.
        </span>
      </div>
      {showEmailNotification && (
        <div
          css={css`
            vertical-align: middle;
            display: inline-block;
            margin-left: 8px;
            margin-top: 30px;
            max-width: 460px;
          `}
        >
          <strong>Did you know?</strong> We send email and in-app notifications
          when optimizations are ready so you don't have to stick around here.
        </div>
      )}
      <div>
        {latestOptimization?.projectJob?.id && (
          <CancelOptimizationButton
            projectJobId={latestOptimization.projectJob.id}
          />
        )}
      </div>
    </div>
  );
};
