/** @jsxImportSource @emotion/react */
import { Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import {
  FileState,
  UrlType,
  usegetSignedUrlMutation,
  useupdateFileStatusMutation,
} from '../../../../../__generated__/globalTypes';
import { useSession } from '../../../_shared/context';
import { useWorkspace } from '../../../components/workspaces/lab-bench/context';
import {
  getSignedS3Url,
  KeyScopeType,
  UploadRequestOption,
  uploadToS3,
} from '../../../_shared/utils/file';

export type FileUploadProps = {
  keyScope?: KeyScopeType;
  buttonText?: JSX.Element;
  uploadType?: 'button' | 'dragger';
  children?: ReactNode;
  onUploadSuccess?: (res: any, file: RcFile) => void;
  onError?: (e: unknown) => void;
  onFileSelected?: (file: RcFile) => void;
  doUpload?: boolean;
  onRemove?: (e: unknown) => void;
  style?: CSSProperties | undefined;
} & UploadProps;

export const FileUpload = (props: FileUploadProps) => {
  const {
    keyScope,
    uploadType = 'button',
    children,
    onUploadSuccess = () => null,
    onFileSelected = () => null,
    onError,
    doUpload,
    style,
    onRemove,
    ...passedProps // Assume any extra props belong to the uploader component itself
  } = props;

  const [getSignedUrl] = usegetSignedUrlMutation();
  const [updateFileState] = useupdateFileStatusMutation();
  const [file, setFile] = useState<RcFile>();
  const { currentProject, selectedIterationId } = useSession();
  const projectId = currentProject!.id;

  const handleUpload = async () => {
    if (file) {
      const url = await getSignedS3Url({
        file,
        projectId,
        iterationId: selectedIterationId,
        keyScope,
        hookFn: getSignedUrl,
        onError,
        urlType: UrlType.putObject,
      });
      await customUploadRequest({
        file,
        action: url,
        onError,
        onProgress: () => null,
      });
    }
  };
  useEffect(() => {
    if (doUpload && file) {
      handleUpload();
    }
  }, [doUpload, file]);

  const customUploadRequest = async (options: unknown) => {
    const { file } = options as UploadRequestOption;
    let success = true;

    try {
      await uploadToS3({
        ...(options as UploadRequestOption),
        onUploadSuccess,
      });
    } catch (e) {
      console.log('Upload Error', e);
      // uploadToS3 handles the error and calls onError
      success = false;
    }

    await updateFileState({
      variables: {
        name: file!.name, // File should always be present because the uploader component will not call this function without one
        projectId: projectId,
        newStatus: success ? FileState.UPLOADED : FileState.UPLOAD_FAILED,
      },
    });
  };

  const uploadButtonProps = {
    action: (file: RcFile) =>
      getSignedS3Url({
        file,
        projectId,
        iterationId: selectedIterationId,
        keyScope,
        hookFn: getSignedUrl,
        onError,
        urlType: UrlType.putObject,
      }),
    key: `${keyScope}${projectId}UploadButton`,
    customRequest: customUploadRequest,
    showUploadList: {
      downloadIcon: false,
      removeIcon: false,
      showRemoveIcon: false,
      showDownloadIcon: false,
      showPreviewIcon: false,
    },
  };

  const draggerProps = {
    beforeUpload: (fileData: RcFile) => {
      setFile(fileData);
      onFileSelected(fileData);
      return false;
    },
    showUploadList: true,
  };

  const sharedProps = {
    multiple: false,
    onRemove,
    ...passedProps,
  };

  const UploaderComponent =
    uploadType === 'button' ? (
      <Upload style={style} {...uploadButtonProps} {...sharedProps}>
        {children}
      </Upload>
    ) : (
      <Dragger style={style} {...draggerProps} {...sharedProps}>
        {children}
      </Dragger>
    );

  return UploaderComponent;
};
