/** @jsxImportSource @emotion/react */
import { Button, Popconfirm } from 'antd';
import { useOptimization } from '../workspaces/lab-bench/optimization/v2/context';
import { CloseOutlined } from '@ant-design/icons';

export const CancelOptimizationButton = ({
  projectJobId: projectJobId,
}: {
  projectJobId: string;
}) => {
  const { cancelOptimization, ...others } = useOptimization();
  const handleOptimizationCancelation = async () => {
    await cancelOptimization(projectJobId);
  };

  return (
    <Popconfirm
      title="Are you sure you want to cancel this Optimization?"
      onConfirm={handleOptimizationCancelation}
      okText="Yes"
      cancelText="No"
    >
      <Button
        icon={<CloseOutlined />}
        style={{ color: 'red', marginTop: 10 }}
        type="default"
      >
        Cancel Optimization
      </Button>
    </Popconfirm>
  );
};
