import {
  Objective,
  ObjectiveType,
} from '../../../../../__generated__/globalTypes';
import { NO_IMPORTANCE } from '../../workspaces/shared/goals/types';
import React from 'react';

export const formatObjectiveText = (obj: Objective) => {
  const { objectiveType, value, upper, lower } = obj;
  if (NO_IMPORTANCE === obj.importance) {
    return <i style={{ opacity: 0.6 }}>Ignored</i>;
  }
  let objectiveText;
  switch (objectiveType) {
    case ObjectiveType.MAXIMIZE:
      objectiveText = 'Maximize';
      break;
    case ObjectiveType.MINIMIZE:
      objectiveText = 'Minimize';
      break;
    case ObjectiveType.TARGET_VALUE:
      objectiveText = `${value}`;
      break;
    case ObjectiveType.IN_RANGE:
      objectiveText = `${lower} - ${upper}`;
      break;
    default:
      objectiveText = '';
  }
  return objectiveText;
};
