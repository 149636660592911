/** @jsxImportSource @emotion/react */
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Legend,
  Label,
  Cell,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { useDesign } from '../../../_shared/context/design-context';
import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import { useSession } from '../../../_shared/context';
import {
  GeneratedFormulationIcon,
  NonBenchmarkExistingIcon,
} from './design-icons.component';
import { Colors } from '../../../../iso/colors';

import { Tooltip as AntdToolTip } from 'antd';
import { css } from '@emotion/react';
import round from 'lodash/round';
import { changeColumnBackground, normalizeClassName } from './design-utils';
import { useMemo } from 'react';
import { formatCostScore } from '../../../_shared/utils/component';
const CustomTooltip = (props: {
  payload?: {
    name: string;
    value: string | number;
    payload: {
      name: string;
      value: string | number;
      isBenchmark?: boolean;
    };
  }[];
}) => {
  const { currentProject } = useSession();
  if (!props.payload || props.payload?.length === 0) {
    return <div></div>;
  }
  return (
    <div
      style={{
        border: '#bbb 1.5px solid',
      }}
    >
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        <strong>{props.payload && props.payload[0]?.payload?.name} </strong>
        <br />
        <span
          style={{
            backgroundColor: 'white',
            color: 'grey',
          }}
        >
          {' '}
          {props.payload &&
          props.payload[0]?.payload?.isBenchmark &&
          props.payload[0]?.payload?.name !== 'Benchmark Formulation'
            ? 'Benchmark'
            : ''}
        </span>
      </p>

      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {props.payload[0].name}: {props.payload[0].value}
      </p>
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {props.payload[1].name}:{' '}
        {formatCostScore(
          props.payload[1].value ?? 0,
          currentProject?.costMeasurementUnit,
          currentProject?.monetaryUnit
        )}
      </p>
    </div>
  );
};

export const CostGraph = () => {
  const {
    designResults,
    latestDesign,
    minCostScore,
    resultTableColumnRef,
    graphPointRef,
  } = useDesign();

  const generatedData = useMemo(
    () =>
      designResults
        .filter(r => r.isGenerated)
        .map(result => {
          const { total_desirability } = result.scores;
          const costScore = Number(result.formulation.totalCostScore);
          return {
            x: total_desirability,
            y: round(costScore, 2),
            name: result.productName,
          };
        }),
    [designResults]
  );

  const existingData = useMemo(
    () =>
      designResults
        .filter(r => !r.isGenerated)
        .map(result => {
          const { total_desirability } = result.scores;
          const costScore = Number(result.formulation.totalCostScore);
          return {
            x: total_desirability,
            y: round(costScore, 2),
            name: result.productName,
            isBenchmark: result.isBenchmark,
          };
        }),
    [designResults]
  );

  function getMap(): Map<string, SVGSVGElement> {
    if (!graphPointRef?.current) {
      graphPointRef.current = new Map();
    }
    return graphPointRef.current;
  }

  return (
    <ScatterChart width={350} height={350} data={existingData}>
      <CartesianGrid fill="white" />
      <XAxis type="number" dataKey="x" name="Desirability" domain={[0, 1]}>
        <Label
          value="Desirability"
          position="bottom"
          offset={-7}
          style={{ marginBottom: 10 }}
        />
      </XAxis>
      <YAxis type="number" dataKey="y" name="Cost">
        <Label
          value="Cost"
          offset={15}
          dy={20}
          position="insideLeft"
          angle={-90}
        />
      </YAxis>
      <Legend wrapperStyle={{ paddingTop: 15 }} content={<CustomLegend />} />
      <Tooltip content={<CustomTooltip />} />
      {latestDesign?.nteCost && (
        <ReferenceLine
          position="end"
          stroke="blue"
          y={latestDesign?.nteCost}
          strokeOpacity={1}
        />
      )}
      <ReferenceLine
        position="end"
        y={minCostScore}
        strokeDasharray="3 3"
        strokeOpacity={1}
      />
      {/* Weird bug, you need to not render the graph at all if no data points exist otherwise it will render the shape identified here underneath all other points on the other scatter graph */}

      <Scatter
        name="Recommended"
        data={generatedData}
        shape={props => {
          const { x, y, width, height } = props;
          return (
            <svg
              id={`${normalizeClassName(props.name)}-point`}
              ref={node => {
                const map: Map<string, SVGSVGElement> = getMap();
                const normalizedName = normalizeClassName(props.name);
                if (map && node && !map.get(normalizedName)) {
                  map.set(normalizedName, node);
                }
              }}
              className="scatter-point"
              onMouseEnter={() =>
                changeColumnBackground({
                  name: props.name,
                  color: Colors.SWEET_PINK,
                  scrollToColumn: true,
                  addShadow: true,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              onMouseLeave={() =>
                changeColumnBackground({
                  name: props.name,
                  color: 'white',
                  scrollToColumn: true,
                  addShadow: false,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              x={x}
              y={y}
              width={width}
              height={height}
              viewBox="0 0 14 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.35937 1.10729C6.71054 0.580528 7.48458 0.580529 7.83575 1.10729L13.2756 9.26702C13.6686 9.85661 13.246 10.6463 12.5374 10.6463H1.65774C0.94914 10.6463 0.526488 9.85661 0.919548 9.26702L6.35937 1.10729Z"
                fill="#FF2459"
              />
            </svg>
          );
        }}
      >
        {generatedData.map((entry, index) => (
          <Cell key={`cell-${index}`} className="scatter-point" />
        ))}
      </Scatter>

      <Scatter
        name="Existing"
        data={existingData}
        shape={props => {
          const { x, y, width, height } = props;
          if (props.payload.isBenchmark) {
            return (
              <svg
                id={`${normalizeClassName(props.name)}-point`}
                ref={node => {
                  const map: Map<string, SVGSVGElement> = getMap();
                  if (map && node && !map.get(normalizeClassName(props.name))) {
                    map.set(normalizeClassName(props.name), node);
                  }
                }}
                className="scatter-point"
                onMouseEnter={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: Colors.PICTON_BLUE,
                    scrollToColumn: true,
                    addShadow: true,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                onMouseLeave={() =>
                  changeColumnBackground({
                    name: props.name,
                    color: 'white',
                    scrollToColumn: true,
                    addShadow: false,
                    columnRefMap: resultTableColumnRef,
                    pointRefMap: graphPointRef,
                  })
                }
                x={x}
                y={y}
                width={width}
                height={height}
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12.3308C9.94412 12.3308 12.3308 9.94412 12.3308 7C12.3308 4.05588 9.94412 1.66921 7 1.66921C4.05588 1.66921 1.66921 4.05588 1.66921 7C1.66921 9.94412 4.05588 12.3308 7 12.3308Z"
                  fill="white"
                  stroke="#0075FF"
                  strokeWidth="2.66159"
                />
              </svg>
            );
          }
          //Else render a basic blue dot
          return (
            <svg
              id={`${normalizeClassName(props.name)}-point`}
              ref={node => {
                const map: Map<string, SVGSVGElement> = getMap();
                const normalizedName = normalizeClassName(props.name);
                if (map && node && !map.get(normalizedName)) {
                  map.set(normalizedName, node);
                }
              }}
              className="scatter-point"
              onMouseEnter={() =>
                changeColumnBackground({
                  name: props.name,
                  color: Colors.PICTON_BLUE,
                  scrollToColumn: true,
                  addShadow: true,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              onMouseLeave={() =>
                changeColumnBackground({
                  name: props.name,
                  color: 'white',
                  scrollToColumn: true,
                  addShadow: false,
                  columnRefMap: resultTableColumnRef,
                  pointRefMap: graphPointRef,
                })
              }
              x={x}
              y={y}
              width={width}
              height={height}
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5.71966" cy="5.94207" r="5.32317" fill="#0075FF" />
            </svg>
          );
        }}
      >
        {existingData.map((entry, index) => (
          <Cell key={`cell-${index}`} className="scatter-point" />
        ))}
      </Scatter>
    </ScatterChart>
  );
};

const CustomLegend = () => {
  const center = css`
    display: flex;
    align-items: center;
  `;
  const { nteCost } = useScenarioDetail();
  const { minCostScore } = useDesign();
  return (
    <div>
      <div
        css={css`
          padding-left: 64px;
          font-size: 10px;
          display: flex;
        `}
      >
        <div>Formulations:</div>

        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-evenly;
          `}
        >
          <div css={center}>
            <NonBenchmarkExistingIcon
              css={css`
                margin-right: 3px;
              `}
            />
            Existing
          </div>
          <div css={center}>
            <GeneratedFormulationIcon
              css={css`
                margin-right: 3px;
              `}
            />
            Recommended
          </div>
        </div>
      </div>
      <div
        css={css`
          padding-left: 64px;
          font-size: 10px;
          display: flex;
        `}
      >
        <div></div>

        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-evenly;
          `}
        >
          <AntdToolTip title={`Minimum Cost: ${minCostScore}`}>
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-left: 50px;
              `}
            >
              <hr
                css={css`
                  border: medium dashed grey;
                  border-dasharray: 10px 10px;
                  border-width: 1px;
                  margin-right: 3px;
                  width: 10px;
                `}
              />
              Min. Cost
            </div>
          </AntdToolTip>
          {nteCost > 0 ? (
            <AntdToolTip title={`Not to Exceed Cost: ${nteCost}`}>
              <div css={center}>
                <hr
                  css={css`
                    margin-right: 3px;
                    width: 10px;
                  `}
                  color="blue"
                />
                Not to Exceed
              </div>
            </AntdToolTip>
          ) : (
            <div
              css={css`
                padding-left: 40px;
              `}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
