/** @jsxImportSource @emotion/react */
import { Typography } from 'antd';
import { IngredientType } from '../../../_shared/hooks';
import { formatSuffix, hasValue } from '../../../_shared/utils/util';
import React from 'react';
import { css } from '@emotion/react';
const { Text } = Typography;
export const Bounds = ({ ingredient }: { ingredient: IngredientType }) => {
  const unit = formatSuffix(ingredient.unit);
  const hasValidBounds =
    hasValue(ingredient.upperLimit) &&
    hasValue(ingredient.lowerLimit) &&
    ingredient.type === 'NUMERIC';
  return (
    <React.Fragment>
      {hasValidBounds && (
        <Text
          type="secondary"
          css={css`
            font-size: 12px;
          `}
        >
          Bounds {ingredient.lowerLimit} - {ingredient.upperLimit}
          {unit}
        </Text>
      )}
    </React.Fragment>
  );
};
