import { css } from '@emotion/react';

export const boxStyle = css`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 17px;
  width: 95%;
  margin: 19px 0px;
  display: inline-block;
`;

export const titleStyle = css`
  border-bottom: 1px solid #f3f3f3;
  padding: 14px 12px 10px 12px;
  font-weight: 700;
  font-size: 16px;
`;
