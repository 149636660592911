import { css } from '@emotion/react';
import {
  centerVerticallyFlexStyle as centerV,
  centeredHorizontallyFlexStyle as centerH,
  Colors,
} from '../../../../_shared/style';

export const topDriversContainerStyle = css`
  background: ${Colors['#010101']};
  border: 2px solid #191d1f;
  box-sizing: border-box;
  border-radius: 10px;
  pointer-events: all;
`;

export const ingredientNameStyle = [
  css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.5715;
    /* or 150% */
    letter-spacing: -0.2px;
    margin-left: '8px';
    color: #ffffff;

    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25),
      0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.25),
      0px 2px 2px rgba(0, 0, 0, 0.25);
  `,
  centerH,
  centerV,
];

export const titleStyle = css`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  color: ${Colors.WHITE};
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.25),
    0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

export const relativePercentageStyle = css`
  font-style: normal;
  font-weight: normal;
  line-height: 1.5715;
  letter-spacing: -0.2px;
  color: #979797;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.25),
    0px 2px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.25);
`;
